dhtmlXGridObject.prototype.setDynState = function(id, state) {

  const item = this._h2.get[id];
  item.update = true;

  item.state = state ? 'plus' : 'blank';
  item._xml_await = state;

  this._updateTGRState(item);

};

(function($) {

  // ********************* treegrid ******************
  // copy of treegrid -> treegrid is old version and should be replaces lateron
  $.extend({
    /**
     * SwatTreeGrid Control
     * @class ak_treegrid
     * @tutorial treegrid-desc
     * @param {Object} options Repository attributes for SwatTreeGrid.
     * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     * @param {string} options.contextMenu the id of a menuStructure which will be used as a context-menu
     * @param {string} options.titleHeader specifies which panelHeader to use. when empty, then uses the header of its own panel. if "none" then no header at all. if "parent" it uses the header of the parent panel
     * @param {string} options.floatingActionButton the id of a menustructure, which will be rendered as a FAB
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {string} options.panelMenu comma separated list of menu-structures which will be shown as panelHeader-Buttons </br>
     * Can also contain the flag #NoDropDown that specifies that the menu should not be loaded as a dropdown but each menu item should be added in the panel-Header. </br>
     * For example: </br>
     * <code>menuStructSave,menuSettings#NoDropDown,menuLookup</code> </br>
     * The buttons support font icons with the following attributes: </br>
     * 1. Css attributes, defined like this: fa fa-user#color:red </br>
             * 2. Css classes, defined like this: fa fa-user#_style:module_prod
             * 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod </br>
     * @param {string} options.zappingContainer container used for displaying in browsing/zapping
     * @param {string} options.SUBTYPE
     * @param {string} options.EntityName The Name of the Business Entity used by the component
     * @param {number} options.maxColumnCharacters limits the maximum number of displayed characters for grid-columns. Usefull if there are columns that could contain large strings, which would cause the rows to be too high in multiline-mode
     * @param {string} options.updateRecordContainer name of repository object to be called for updating records
     * @param {string} options.showGridFilter controls which filter-controls are shown for a grid.&#10;all,none,column-only
     * @param {boolean} options.batchingMode if true, then the grid will use batching mode
     * @param {boolean} options.ENABLED WidgetAttributes Enabled
     * @param {string} options.addRecordContainer container for adding new records
     * @param {string} options.copyRecordContainer optional name of repository object to use when copying a record. If not specified, updateContainer will be used
     * @param {string} options.EventRowChosen client side code executed when a row is chosen (selected by the user to perform an action on it), e.g. by double click
     * @param {boolean} options.filterOnLeave if true, then filter will automatically applied when leaving a filter-field
     * @param {string} options.EventBeforeFetch code to be executed before fetching data from the backend
     * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
     * @param {string} options.EventRowSelected client side code executed when a row is selected (becomes current row in a set of rows), e.g. by single click
     * @param {string} options.resourceName
     * @param {string} options.FolderWindowToLaunch If Dynamics is running, this property specifies a window to launch upon the occurence of toolbar events "View", "Copy", "Modify" or "Add".
     * @param {string} options.RecordCreate Axilon: Neuanlage-Dialog für komplexe Daten mit mehreren eindeutigen Schlüsselfeldern
     * @param {string} options.ToolBarKey Key of the used toolbar
     * @param {string} options.treeColDescriptions descriptions for treeGrid columns
     * @param {boolean} options.ShowContainer Show Container to Tree
     * @param {string} options.ServerProp Pass any kind of property to the SDO at runtime.&#10;&#10;Format:&#10;&#10;PropName#PropValue|PropName#PropValue&#10;&#10;...
     * @param {string} options.delConfirm extended delete confirmation
     * @param {string} options.typeRange The type range which makes up a combobox in a Toolbar
     * @param {boolean} options.keepSelection Auto select previously selected grid row if the row is found, searched in the ak_businessEntity, by the identifier attribute. Default is true.
     * @param {integer} options.RowsToBatch The number of rows to batch, default is 2000
     * @fires ak_treegrid#EventOnContextMenuOpen
     */

    ak_treegrid: function(options) {
      akioma.BaseDataGrid.call(this, options);
      const oSelf = this,
        defaults = {
          colLabel: '',
          colFilter: '',
          colWidth: '',
          colAlign: '',
          colType: '',
          colFormat: ''
        };

      this.typeKey = '';
      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;
      this.registerVuexModule = true;
      this.mode = {};
      this.state = '';
      this.observers = [];
      this.bLoadedUISettings = false;
      this.serverProp = { srvrProp: [] };

      if (!this.opt.treeColumn)
        this.opt.treeColumn = 'DataRecDesc';

      this.oKeepSelectionData = null;

      // get parent
      let oGrid;
      const oParent = this.parent.dhx;
      if (oParent) {
        // -> bind grid to layout
        if (oParent.getAttachedObject())
          oParent.showView('alternative');

        oGrid = oParent.attachGrid();
      } else
        oGrid = new dhtmlXGridObject(this.opt.name);

      oGrid.enableColumnMove(true);

      if (oGrid) {
        // changed for dhx4
        oGrid.setImagePath(oDhx.imagePath); // images for skin, from dhx suite
        oGrid.setIconsPath(this.opt.iconPath); // custom images for rows
        oGrid.setSkin(oDhx.skin);
        oGrid.i18n.decimal_separator = ',';
        oGrid.i18n.group_separator = '.';
        oGrid.setDateFormat(window.dhx.dateFormat[window.dhx.dateLang]);
        oGrid.setHeader(this.opt.colLabel);

        if (this.opt.iconset == 'fontawesome')
          oGrid.setIconset('awesome');

        // set akId
        this.akId = ((this.opt.akId) ? this.opt.akId : this.opt.name);

        if (!this.opt.customStyle)
          this.opt.customStyle = this.view;

        // set floatingActionButton in panel
        if (this.opt.floatingActionButton) {
          const oFloatingActionParent = this;
          this.parent.setOption('floatingActionButton', this.opt.floatingActionButton, oFloatingActionParent);
        }
        // set panelPopupObject in panel
        if (this.opt.panelPopupObject)
          this.parent.setOption('panelPopupObject', this.opt.panelPopupObject);

        // get column definitions
        const aCells = [],
          aHead = [],
          aHeadAlign = [],
          aFilter = [],
          aWidth = [],
          aAlign = [],
          aDataTypes = [],
          aColTypes = [],
          aColFormats = [],
          aColSort = [],
          aNumFormat = [],
          aFolder = [];

        let oCol;
        for (const i in options.sub) {
          oCol = options.sub[i];

          if (oCol.view == 'datagridcol' || oCol.view == 'datagridcol2') {

            if (oCol.att.dataField.toLowerCase() == this.opt.treeColumn.toLowerCase())
              oCol.att.colType = 'tree';

            oCol.att.label = akioma.tran(`${oCol.att.gridName}.${oCol.att.dataField}`, { defaultValue: oCol.att.label });

            if (oCol.att.align == 'default') {
              switch (oCol.att.dataType) {
                case 'date':
                case 'integer':
                case 'decimal':
                  oCol.att.align = 'right';
                  break;
                case 'logical':
                  oCol.att.align = 'center';
                  break;
                default:
                  oCol.att.align = 'left';
                  break;
              }
            }

            if (!oCol.att.colType) {
              switch (oCol.att.dataType) {
                case 'integer':
                case 'decimal':
                  oCol.att.colType = 'ron';
                  break;
                case 'character':
                  oCol.att.colType = '';
                  break;
              }
            }

            switch (oCol.att.format) {
              case 'integer':
              case 'decimal':
                aNumFormat.push({ 'num': Number(i), 'format': '0,000.00 €' });
                break;
              case 'decimal2':
                aNumFormat.push({ 'num': Number(i), 'format': '€ 0,000.00' });
                break;
              default:
                aNumFormat.push();
            }

            aHead.push((oCol.att.label) ? oCol.att.label : '');

            // set columns header align
            if (oCol.att.align == undefined) {
              switch (oCol.att.colType) {
                case 'tree':
                  aHeadAlign.push('text-align: left;');
                  break;
                case 'edn':
                  aHeadAlign.push('text-align: right');
                  break;
                case 'img':
                  aHeadAlign.push('text-align: center');
                  break;
                default:
                  aHeadAlign.push('text-align: center');
              }

            } else
              aHeadAlign.push(`text-align: ${oCol.att.align};`);


            aFilter.push((oCol.att.filter) ? oCol.att.filter : '');
            aWidth.push((oCol.att.width) ? parseInt(oCol.att.width) : '');
            aAlign.push((oCol.att.align) ? oCol.att.align : '');
            aColTypes.push((oCol.att.colType) ? oCol.att.colType : '');
            aColSort.push((oCol.att.sortable) ? 'server' : 'na');
            aCells.push((oCol.att.dataField) ? oCol.att.dataField : '');
            aDataTypes.push((oCol.att.dataType) ? oCol.att.dataType : 'character');
            aFolder.push(oCol.att.folderWindow);
            aColFormats.push(oCol.att.columnFormat);

            switch (oCol.att.dataType) {
              case 'date':
                oGrid.calFormats[oCol.att.dataField] = window.dhx.dateFormat[window.dhx.dateLang];
                break;
              case 'datetime':
              case 'datetime-tz':
                oGrid.calFormats[oCol.att.dataField] = `${window.dhx.dateFormat[window.dhx.dateLang]} %H:%i`;
                break;
            }

            if (oCol.att.colType == 'clist') {
              oGrid.registerCList(i, {
                text: oCol.att.comboText,
                value: oCol.att.comboValues
              });
            }
          }
        }

        oGrid.setHeader(aHead, null, aHeadAlign);
        oGrid.setInitWidths(aWidth.join(','));
        oGrid.setColAlign(aAlign.join(','));
        oGrid.setColTypes(aColTypes.join(','));
        oGrid.setColSorting(aColSort.join(','));
        oGrid.setColumnIds(aCells.join(','));
        oGrid._initColIds = aCells; /*  save initial column ordering (used when converting businessEntity data into grid format) */

        // oSelf.aSubItemsChain = [];
        oSelf.aItemsNextLvl = [];
        oSelf.aColFormats = aColFormats;
        oSelf._initColIds = aCells;

        this.aRowsAddState = [];

        /* new column settings end here*/
        // map CTRL + no to open nodes on levels
        const aOpenNodeLevelsKeys = [ 'ctrl+0', 'ctrl+1', 'ctrl+2', 'ctrl+3', 'ctrl+4', 'ctrl+5', 'ctrl+6', 'ctrl+7', 'ctrl+8', 'ctrl+9' ];
        oSelf.aOpenNodeLevelsKeys = aOpenNodeLevelsKeys;

        // add keyevents events on window level
        const oWindowMousetrap = this.getAncestor('window').oMouseTrap;

        // open up n levels of nodes
        oWindowMousetrap.bind(aOpenNodeLevelsKeys, e => {

          const iLevels = String.fromCharCode(e.keyCode);
          const cSelected = oGrid.getSelectedRowId();
          if (cSelected) {
            // open first level
            if (iLevels == 0) {
              oGrid.closeItem(cSelected);
              return false;
            }
            oGrid.closeItem(cSelected);
            oGrid.openItem(cSelected);
            oSelf.iOpenLvl = iLevels;
            oSelf.iCurrentLvl = 1;
          }


          // return false to prevent default browser behavior
          // and stop event from bubbling
          return false;
        });

        // delete node
        oWindowMousetrap.bind('del', e => {

          const element = e.target;
          if (!(element.tagName == 'INPUT' || element.tagName == 'SELECT' || element.tagName == 'TEXTAREA' || element.isContentEditable))
            oSelf.tbNodeDelete();
        });

        // add child node
        oWindowMousetrap.bind('ctrl+ins', () => {
          oSelf.tbNodeChild();
        });

        // add sibling node
        oWindowMousetrap.bind('ins', () => {
          oSelf.tbNodeSibling();
        });

        // record shift pressed
        oWindowMousetrap.bind('shift', () => {
          oSelf.shiftKey = true;
        }, 'keydown');

        oWindowMousetrap.bind('shift', () => {
          oSelf.shiftKey = false;
        }, 'keyup');

        // record ctrl pressed
        oWindowMousetrap.bind('ctrl', () => {
          oSelf.ctrlKey = true;
        }, 'keydown');
        oWindowMousetrap.bind('ctrl', () => {
          oSelf.ctrlKey = false;
        }, 'keyup');

        // registers save action of treegrid posframe node on ENTER keypress
        this._registerPosFrameEnterKeypress();

        // registers focus action of form field in posframe node on TAB keypress
        this._registerPosFrameTabFocusKeypress();

        oSelf.bExpandRdy = true;

        oGrid.attachEvent('onAfterCMove', () => {
          if (oSelf.bLoadedUISettings)
            UserProfile.saveGridLocalProfileData(oSelf, akioma.getGridColumnSettings(oSelf));
        });
        oGrid.attachEvent('onResizeEnd', () => {
          if (oSelf.bLoadedUISettings)
            UserProfile.saveGridLocalProfileData(oSelf, akioma.getGridColumnSettings(oSelf));
        });
        oGrid.attachEvent('onBeforeSelect', (oldId, newId) => oSelf.beforeRowSelect(oldId, newId));
        oGrid.attachEvent('onRowSelect', (id, ind) => oSelf.rowSelect(id, ind));
        oGrid.attachEvent('onBeforeDrag', srcId => oSelf.beforeDrag(srcId));
        oGrid.attachEvent('onDrag', (srcId, trgId, srcObj, trgObj, srcCol, trgCol) => oSelf.drag(srcId, trgId, srcObj, trgObj, srcCol, trgCol));
        oGrid.attachEvent('onOpenStart', (rowId, iState) => oSelf.openStart(rowId, iState));
        oGrid.attachEvent('onOpenEnd', (rowId, iState) => oSelf.openEnd(rowId, iState));
        oGrid.attachEvent('onEditCell', (iStage, rId, cIndex, nValue, oValue) => oSelf.editCell(iStage, rId, cIndex, nValue, oValue));
        oGrid.attachEvent('onXLE', (oGrid, iCount) => oSelf.checkPosition(oGrid, iCount));
        oGrid.attachEvent('onDynXLS', cId => { // rowId - expanding row


          if (oSelf.bExpandRdy != false) {
            oSelf.bExpandRdy = false;
            if (!oSelf.opt.useBusinessEntity) {
              oSelf.bExpandRdy = true;
              return true;
            }

            oSelf.businessEntity.query.clearAll();
            oSelf.businessEntity.query.addCondition('parenthdl', 'eq', cId);
            oSelf.businessEntity.foreignKey = 'ownerhdl';

            try {
              oSelf.parent.dhx.progressOn();
              oSelf.businessEntity.serverProp.sortField = 'seqNum';
              oSelf.businessEntity.openQuery({ 'extKey': cId }, (data, oResult) => {
                if (oResult)
                  oResult.parse(data);

                const oQuery = oSelf.businessEntity.getQuery();
                const oData = oSelf.convertToTreenodes(data, oQuery.getFilterValue('parenthdl'));

                oGrid.parse(oData, 'json');
                // allow next expand
                oSelf.bExpandRdy = true;


                // this is for the nodes on levels opening, CTRL + NO(0-9)
                oSelf.openNodesOnLevels();

                // open remaining chain to select node
                if (oSelf.openChain)
                  oSelf.openChain();


                // select node after creating it
                if (oSelf.cNewNodeToSelect != undefined) {
                  oSelf.dhx.selectRowById(oSelf.cNewNodeToSelect, false, true, true);
                  delete oSelf.cNewNodeToSelect;

                }
              });
            } catch (oErr) {
              akioma.log.error('error calling BE', oErr);
            } finally {
              oSelf.parent.dhx.progressOff();
            }

            return false; // block the defaiult data loading precess for the branch
          }
        });

        oGrid.attachEvent('onDragIn', (dId, tId) => {

          // restrict drop if potential drop landing is root node
          if (oSelf.cGridRootNodeId && tId == oSelf.cGridRootNodeId)
            oGrid.setDragBehavior('child');
          else
            oGrid.setDragBehavior('complex');

          return true;
        });

        oGrid.enableEditTabOnly(false);
        // added empty TAB keypress event otherwise tab edit cell is not getting disabled
        oGrid.attachEvent('onKeyPress', (code, cFlag, sFlag) => {
          if (code == 83 && cFlag && sFlag) {
            akioma.message({
              type: 'confirm',
              title: 'Set column-order/width',
              text: 'Are you sure you want to Change the repository-settings for this grid? This will affect Default for ALL users!',
              buttonText: 'Yes',
              callback: function(result) {
                if (result)
                  oSelf.saveColumnsOrder();

              }
            });

            return false;
          }
          if (code == 9)
            return false;
          else
            return true;
        });


        oGrid.enableMultiselect(true);
        oGrid.enableDragAndDrop(true);
        oGrid.enableAutoWidth(false);
        oGrid.enableAutoHeight(false);

        // enable grid cells editing
        oGrid.enableEditEvents(false, this.opt.ENABLED, this.opt.ENABLED);
        oGrid.enableTreeCellEdit(this.opt.ENABLED);

        oGrid.enableKeyboardSupport(true);
        oGrid.enableMercyDrag(true);
        oGrid.setDragBehavior('complex-next');
        if (this.opt.resourceName)
          this.opt.useBusinessEntity = true;

        if (this.opt.useBusinessEntity) {
          try {
            let cResourceName = '';
            if (this.opt.resourceName)
              cResourceName = this.opt.resourceName;
            else
              cResourceName = (this.opt.typeRange == 'pos.ofr.' ? 'Akioma.Crm.Offer.OfferStructEntity' : 'Akioma.Crm.Product.ProductStructEntity');

            const oBEoptions = {
              'att': {
                cacheLimit: 50,
                catalogURI: '',
                dataSource: '',
                entityName: (this.opt.entityName || 'eStruct'),
                fieldlist: this.opt.fieldlist,
                extKey: 'selfhdl',
                foreignKey: 'ownerhdl',
                id: 'offerw45613645_businessEntity',
                identifier: 'selfhdl',
                name: 'businessEntity',
                resourceName: cResourceName,
                initialFetch: '#none',
                serviceURI: ''
              }
            };

            this.businessEntity = new $['ak_businessEntity'](oBEoptions);
            this.businessEntity.finishConstruct();
            this.businessEntity.endConstruct();
            this.businessEntity.setBatch(0, (this.opt.rowsToBatch || 1000));

            // attach events to data source
            this.businessEntity.opt.onBeforeFetch = this.opt.EventBeforeFetch;
            this.businessEntity.opt.refreshScheme = this.opt.refreshScheme;
          } catch (oErr) {
            akioma.log.error('Error initializing BE', oErr);
          }
        }

        if (!this.opt.useBusinessEntity)
          oGrid.kidsXmlFile = `/akioma/gettree.xml?Name=${this.opt.name}`;
        else
          oGrid.kidsXmlFile = `/akioma/gettree.xml?Name=${this.opt.name}`;

        oGrid.init();

        $.extend(this, {
          dhx: oGrid,
          grid: oGrid,
          security: {},
          drop: {}
        });

      } else
        !_isIE && console.error(`No valid parent for treegrid ${this.opt.name}`);

    }
  });
  Object.assign($.ak_treegrid.prototype, akioma.BaseDataGrid.prototype, {
    // finish
    finishConstruct: function() {
      const oSelf = this,
        oGrid = this.dhx;

      // set akstyle in treegrid
      $(oSelf.dhx.entBox).attr('akstyle', oSelf.opt.customStyle);


      // add contextmenu
      if (oSelf.opt.contextMenu !== undefined && oSelf.opt.contextMenu !== '') {
        const cPointer = `pointer-menu-${oSelf.opt.id}`;
        oSelf.contextMenuObject = new akioma.GridContextMenu(oSelf, cPointer);
      }

      if (oSelf.opt.panelMenu == undefined)
        oSelf.attachTreeContextMenu(oSelf.parent);

      // add custom class to header table
      const $treeGridHDR = $(oSelf.dhx.hdr);
      $treeGridHDR.addClass('ak-treegrid-hdr');
      $(oGrid.entBox).addClass('treeGrid');

      // load column settings
      const oColSettings = UserProfile.loadGridLocalProfileData(oSelf);
      if (oColSettings)
        akioma.applyGridColumnSettings(oSelf, oColSettings);

      oSelf.bLoadedUISettings = true;

      if (this.opt.serverProp) {
        const aProp = this.opt.serverProp.split('|');
        for (const i in aProp) {
          const aVal = aProp[i].split('#');
          this.setSrvProp(aVal[0], aVal[1]);
        }
        const oPar = $.extend({ Name: this.opt.name }, this.serverProp);
        if (!this.opt.useBusinessEntity)
          oGrid.kidsXmlFile = `/akioma/gettree.xml?${$.param(oPar)}`;
      }

      const oPar = $.extend({
        datatype: 'store',
        Start: 'true'
      }, this.serverProp);
      if (!this.opt.useBusinessEntity)
        oGrid.dataFeed(`/akioma/gettree.xml?${$.param(oPar)}`);


      this._addTreeSocketBindings();

      // set container option in target panel
      // check if container should be handled
      if (this.opt.posFrameHandling) {

        const oCont = this.getContainer();
        oCont.setOption('treeCont', this);
      }
      if (oSelf.opt.typeRange)
        this.opt.title = akioma.tran(`${oSelf.opt.name}_${akioma.entry(2, oSelf.opt.typeRange, '.')}._title`, { defaultValue: 'Struktur' });

      // set title and panelMenu buttons in panel header
      akioma.setPanelHeader(oSelf);


      this.hasChangesWindowWatcher = this.dynObject.container.controller.createVuexWatcher('state.attributes.hasChanges',
        newKey => {
          if (newKey === true)
            oSelf.parent.dhx.disableInput();
          else
            oSelf.parent.dhx.enableInput();

        }
      );

    },
    /** Method for adding socket tree room notification DataStore binding
     * @instance
     * @private
     * @memberof ak_treegrid
     */
    _addTreeSocketBindings: function() {
      if (this.opt.useBusinessEntity) {
        const idXLE = this.dhx.attachEvent('onXLE', () => {
          this.dhx.detachEvent(idXLE);
          const selfhdl = this.dataSource.getIndex();
          if (akioma.socketConnection.exists()) {
            if (this.lastOwner)
              akioma.SocketTreeGridRoom.leave(this.lastOwner);

            akioma.SocketTreeGridRoom.bindJoinedListener();
            akioma.SocketTreeGridRoom.join(selfhdl);
          }
          this.lastOwner = selfhdl;
        });
      } else {
        const oSource = this.dataSource;
        this.dhx.bind(oSource, (data, filter) => {
          filter.id = data.selfhdl;
          if (this.lastOwner)
            akioma.SocketTreeGridRoom.leave(this.lastOwner);
          akioma.SocketTreeGridRoom.bindJoinedListener();
          akioma.SocketTreeGridRoom.join(data.selfhdl);

          this.lastOwner = data.selfhdl;
          this.deleteContainer();
        });
      }

      if (akioma.socketConnection.exists()) {
        akioma.socketConnection.on('treeRefresh', data => {
          try {
            akioma.log.info([ 'TreeRefresh', this.dataSource.dynObject.getValue('selfhdl'), data.ownerHdl ]);
            if (this.dataSource.dynObject.getValue('selfhdl') == data.ownerHdl)
              this.externalRefresh(data);
            else
              akioma.log.info([ 'not my job:', data.ownerHdl ]);
          } catch (err) {
            akioma.log.info([ 'error in treeRefresh:', err ]);
          }

        });
      } else
        akioma.log.info([ 'no socket.io, tree will not register', akioma ]);
    },

    /**
     * Method for registering ENTER keypress action in posframe
     * @memberof ak_treegrid
     * @instance
     * @private
     */
    _registerPosFrameEnterKeypress() {
      // ENTER KEY event, save posframe if in form focus and not in open state fields:lookup2, combobox
      Mousetrap.bindGlobal(`enter.${this.opt.id}`, e => {
        const bActiveWindow = this.dynObject.container.controller.dhx.isOnTop();
        if (bActiveWindow) {
          try {
            if (this.opt.posFrameHandling) {

              const oCont = this.getContainer();
              let akForm;
              let oDhxForm;

              if (oCont) {
                akForm = oCont.getDescendant('form');
                if (akForm)
                  oDhxForm = akForm.dhx;
                  // check if input from posframe is in focus
                if (oDhxForm && $.contains(oDhxForm.cont, document.activeElement)) {
                  const bIsDynselectFocus = ($(e.target).hasClass('select2-selection') || $(e.target).hasClass('select2-search__field'));

                  // check if lookup not opened
                  const $activeField = $(document.activeElement);
                  if ($('.dhxcombolist_material:visible').length == 0 && bIsDynselectFocus) {
                    const oDynSelect = $activeField.parent().parent().parent().find('.dynSelect')[0];
                    const selectObj = $(oDynSelect).data('select2');
                    if (!selectObj._wasOpenedFromDynselect) {
                      $activeField.blur();
                      $activeField.focus();
                      if (this.triggerTreeEnterKey == true || this.triggerTreeEnterKey == undefined) {
                        $(oDynSelect).data('bClose', true);
                        $(oDynSelect).select2('close');
                        this.tbNodeSave();
                      }
                    }

                  } else {
                    $activeField.blur();
                    $activeField.focus();
                    this.tbNodeSave();
                  }
                }

              }

            }
          } catch (e) {
            akioma.log.warn('Error saving posframe form values', e);
          }
        }
      });
    },

    /**
     * Method for registering TAB keypress action in posframe
     * @memberof ak_treegrid
     * @instance
     * @private
     */
    _registerPosFrameTabFocusKeypress() {
      // focus in posFrame form, first enabled field
      Mousetrap.bind(`tab.${this.opt.id}`, e => {
        const currentIndex = +this.dynObject.container.controller.dhx.cell.parentNode.style.zIndex;
        let maxIndex = -1;

        $('.dhxwin_active').each((index, el) => {
          maxIndex = Math.max(maxIndex, +el.style.zIndex);
        });

        const bActiveWindow = currentIndex >= maxIndex;
        if (bActiveWindow) {
          try {
            if (this.opt.posFrameHandling) {
              const oCont = this.getContainer();
              if (oCont) {
                // check if no field is already in focus
                const oForm = oCont.getDescendant('form').dhx.cont;
                const oComputedProp = window.getComputedStyle(oForm);
                const bHiddenForm = (oComputedProp.getPropertyValue('visibility') == 'hidden');
                const isHiddenTree = (window.getComputedStyle(this.dhx.entBox).getPropertyValue('visibility') == 'hidden');
                const stateModal = swalOriginal.getState();
                const isOpened = stateModal.isOpen;
                if (!$.contains(oForm, document.activeElement) && !isHiddenTree && !bHiddenForm && !isOpened) {
                  e.preventDefault();
                  oCont.getDescendant('form').dhx.setFocusOnFirstActive();
                  $(document.activeElement).closest('.akFormDynSelect').find('select').select2('open');
                }
              }
            }
          } catch (e) {
            akioma.log.warn('Error focusing posframe form field', e);
            return true;
          }
        }
      });
    },

    activateSearch: function() {
      const oSelf = this;
      if (oSelf.bSearchAlreadyOpened) {
        // show search input again
        const oGrid = oSelf.dhx;
        oSelf.bSearchAlreadyOpened = false;
        oGrid.heightDiff = 0;
        oGrid.setSizes();
      } else {
        oSelf.bSearchAlreadyOpened = true;
        if (oSelf.bAlreadyCreatedLookup) {
          const oGrid = this.dhx;
          oGrid.heightDiff = 40;
          oGrid.setSizes();
        } else {
          oSelf.bAlreadyCreatedLookup = true;
          oSelf.createSearchInput();
        }
      }
    },

    createSearchInput: function() {
      // attach a form to bottom of treegrid with an input field
      const oSelf = this;

      const oGrid = this.dhx;
      oGrid.heightDiff = 40;
      oGrid.setSizes();

      const cID = `search-input-${this.opt.name}${dhtmlx.uid()}`;

      if ($(oGrid.entBox).parent().find('.gooeyMenu_container').length > 0)
        $(oGrid.entBox).parent().find('.gooeyMenu_container').before(`<div id="${cID}" objtype="searchinput-tree"></div>`);
      else
        $(oGrid.entBox).parent().append(`<div id="${cID}" objtype="searchinput-tree"></div>`);

      const oForm = new dhtmlXForm(cID, [{ type: 'input', name: 'searchInput', label: '', value: '' }]);

      // run autocompletesearch namedquery on that input
      let cResourceName = '';
      if (this.opt.resourceName)
        cResourceName = this.opt.resourceName;
      else
        cResourceName = (this.opt.typeRange == 'pos.ofr.' ? 'Akioma.Crm.Offer.OfferStructEntity' : 'Akioma.Crm.Product.ProductStructEntity');

      const oBEoptions = {
        'att': {
          cacheLimit: 50,
          catalogURI: '',
          dataSource: '',
          entityName: (this.opt.entityName || 'eStruct'),
          extKey: 'selfhdl',
          foreignKey: 'ownerhdl',
          id: 'offerw45613645_businessEntity',
          identifier: 'selfhdl',
          name: 'businessEntity',
          rowsToBatch: 1000,
          resourceName: cResourceName,
          serviceURI: ''
        }
      };

      const oDataSource = new $['ak_businessEntity'](oBEoptions);
      oDataSource.finishConstruct();
      oDataSource.endConstruct();

      let inputTimeout = null;


      myPop = new dhtmlXPopup({ form: oForm, id: 'searchInput' });


      const autoCompleteSearch = function(text) {
        if (oDataSource.jsdo.fillxhr)
          oDataSource.jsdo.fillxhr.abort();
        oDataSource.query.addUniqueCondition('ownerhdl', 'eq', oSelf.dataSource.getFieldValue('selfhdl'));
        oDataSource.setNamedQueryParam('AutoCompleteSearch', 'SearchKey', text, 'character');
        oDataSource.aCallback['afterFill'] = function(oOutput) {
          Handlebars.compile(akioma.handlebarsTemplates.autocompleteSearchBT.templateResults);

          const oData = { results: oOutput };

          const aGenericKeys = {
            id: 'selfhdl',
            img: '',
            key: 'datarecdesc',
            desc: 'typedesc'
          };
          const templateF = Handlebars.compile(akioma.handlebarsTemplates.GenericAutocompleteSearchTemplate(aGenericKeys));
          const result = templateF(oData);

          myPop.attachHTML(result);
        };
        oDataSource.openQuery({ applyFilters: true });
        myPop.show('searchInput');
      };

      oForm.attachEvent('onInputChange', () => {
        if (inputTimeout)
          clearTimeout(inputTimeout);

        if (!myPop.isVisible()) myPop.show('searchInput');
        const text = oForm.getItemValue('searchInput');

        if ($(myPop.p).find('.results-container').length > 0)
          $(myPop.p).find('.results-container').remove();


        if (text.length > 0) {
          inputTimeout = setTimeout(() => {
            autoCompleteSearch(text);
          }, 400);
        }
      });

      oForm.attachEvent('onFocus', () => {
        if (inputTimeout)
          clearTimeout(inputTimeout);
        const text = oForm.getItemValue('searchInput');
        if (!myPop.isVisible() && text.length > 0) myPop.show('searchInput');
      });


      $(myPop.p).on('click', '.searchItem', function() {
        const val = $(this).attr('dynrowid');

        oSelf.reposition2Hdl(val);

        myPop.hide();
        return true;
      });

      let $current;

      $(myPop.p).on('mouseover', '.searchItem', function() {
        $(this).parent().find('.selected').removeClass('selected');
        $current = $(this);
        $current.addClass('selected');
      });


    },

    refreshNodes: function(cHdl, cType) {
      const oSelf = this,
        oTree = oSelf.dhx;

      cHdl = (cType == 'child') ? cHdl : oTree.getParentId(cHdl);
      const bAsChild = (cType == 'child');

      let fCallback;
      if (!bAsChild) { /* when copy/move as sibling, we can only refresh by reopening our parent */
        oTree.closeItem(cHdl);
        oTree.setDynState(cHdl, true);
        fCallback = function() {
          const newRow = oTree._h2.get[cHdl];
          newRow._xml_await = true;
          oTree.openItem(cHdl);
        };
      } else { /* when as child, we can simply patch the target node to tell it that it now has childs*/
        const bIsOpened = oTree.getOpenState(cHdl);
        oTree.closeItem(cHdl);
        oTree.setDynState(cHdl, true);
        if (bIsOpened) {
          fCallback = function() {
            const newRow = oTree._h2.get[cHdl];
            newRow._xml_await = true;
            oTree.openItem(cHdl);
          };
        }
      }

      oSelf.refreshAllVisibileNodes(fCallback);
    },

    applyNumericFormats: function(cCurrSymbol) {
      const oSelf = this;
      if (!cCurrSymbol)
        cCurrSymbol = '€';

      for (let iCol = 0; iCol < oSelf.aColFormats.length; iCol++) {
        if (oSelf.aColFormats[iCol]) {
          // At this stage it is impossible to fetch the currency
          // this.parent.parent.parent.parent.dynObject.container.getLink("PRIMARYSDO:TARGET").getValue("currencykey")

          // _C_ = the currency of the offer
          // _CM_ = the Main Currency (Hauswährung)
          const column = oSelf.childs[iCol];

          let cFormat = akioma.stringReplace(column.numeric.cFormat, '_C_', cCurrSymbol);
          if (app.sessionData.mainCurrencySymbol)
            cFormat = akioma.stringReplace(cFormat, '_CM_', app.sessionData.mainCurrencySymbol);
          column.setNumberFormat(cFormat, column.numeric.groupSep, column.numeric.decSep);
        }
      }
    },

    endConstruct: function() {
      const oSelf = this;

      const oNewKey = akioma.aLoadedTGF[`ak_${oSelf.view}_${oSelf.opt.name}`];
      if (oNewKey == undefined)
        akioma.aLoadedTGF[`ak_${oSelf.view}_${oSelf.opt.name}`] = [];
      akioma.aLoadedTGF[`ak_${oSelf.view}_${oSelf.opt.name}`].push(oSelf);

      this.applyNumericFormats('$');
      /*
for(var iCol = 0; iCol < oSelf.aColFormats.length; iCol++){
if (oSelf.aColFormats[iCol]){
//At this stage it is impossible to fetch the currency
//this.parent.parent.parent.parent.dynObject.container.getLink("PRIMARYSDO:TARGET").getValue("currencykey")
oGrid.setNumberFormat(akioma.stringReplace(oSelf.aColFormats[iCol], "_C_", "€"), oGrid.getColIndexById(oGrid._initColIds[iCol]), ",", ".");
}
}*/

    },

    // calls businessTask to save column order
    saveColumnsOrder: function() {

      const oSelf = this;
      const aColIds = oSelf._initColIds;

      const oParam = {
        plcParameter: { Value: oSelf.opt._ObjectName },
        dsGridColumnOrderMaintenance: { eGridColumnOrderMaintenance: [] }
      };

      const aGridColumns = oParam.dsGridColumnOrderMaintenance.eGridColumnOrderMaintenance;

      let iExtraRow = 0;

      if (aColIds.indexOf('rowmod') > -1)
        iExtraRow = 1;

      for (const i in aColIds) {
        if (aColIds[i].toLowerCase() != 'rowmod') {
          aGridColumns.push({
            ColumnName: aColIds[i],
            ColumnOrder: oSelf.dhx.getColIndexById(aColIds[i]) + 1 - iExtraRow,
            ColumnWidth: oSelf.dhx.getColWidth(oSelf.dhx.getColIndexById(aColIds[i]))
          });
        }
      }


      akioma.invokeServerTask({
        name: 'Akioma.Swat.Repository.RuntimeMaintenance',
        methodName: 'SetGridColumnProperties',
        paramObj: oParam
      }).done(() => {
        akioma.notification({ type: 'info', text: `New columns order for "${oSelf.opt.name}" has been saved successfully!` });
      });
    },

    /* start*/
    attachTreeContextMenu: function(oPanel) {
      const oHeader = oPanel.dhx;
      const oSelf = this;

      const btnConf = { className: 'class-headerGrid-btn', width: 16, height: 16 };
      const popup = oHeader.attachHeaderButton(btnConf, '');
      const layout = popup.attachLayout(385, 140, '1C');

      /* !!!Test*/
      const ribbon = layout.attachRibbon({
        items: [
          {
            id: 'options', type: 'block', text: 'Settings',
            list: [
              { id: 'hideViewColumns', type: 'button', text: 'Show/hide columns', img: 'imgs/akioma/visible-26.png' },
              { id: 'loadColumnSettings', type: 'button', text: 'Load column settings', img: 'imgs/akioma/upload-26.png' },
              { id: 'clearColumnSettings', type: 'button', text: 'Clear column settings', img: 'imgs/akioma/undo-26.png' },
              { id: 'activateSearchLookup', type: 'button', text: 'Search', img: 'img/akioma/visible-26.png' },
              { id: 'refreshNodes', type: 'button', text: 'Refresh', img: 'imgs/akioma/refresh-26.png' }
            ]
          }
        ]
      });
      const r = layout.base.getElementsByClassName('dhx_cell_layout');
      const h = layout.base.getElementsByClassName('dhx_cell_hdr');
      $(h).remove();

      if (r != null && r[0] != null)
        r[0].style.width = '';


      if (layout.dataNodes.ribbonObj != null) layout.dataNodes.ribbonObj.style.width = '';

      /* !!!Test*/
      (function(oSelf) {
        ribbon.attachEvent('onClick', cId => {

          if (cId == 'hideViewColumns')
            akioma.gridHideViewColumns(oSelf);
          else if (cId == 'saveColumnSettings')
            UserProfile.saveGridLocalProfileData(oSelf, akioma.getGridColumnSettings(oSelf));
          else if (cId == 'loadColumnSettings') {
            const oColSettings = UserProfile.loadGridLocalProfileData(oSelf);
            if (oColSettings)
              akioma.applyGridColumnSettings (oSelf, oColSettings);
          } else if (cId == 'refreshNodes')
            oSelf.refreshAllVisibileNodes();
          else if (cId == 'clearColumnSettings')
            UserProfile.clearLocalProfileData(oSelf);
          else if (cId == 'activateSearchLookup')
            oSelf.activateSearch();


          popup.hide();
        });

      })(oSelf);
      return;
    },


    /* end */
    refreshTreenodes: function(beData, cParentHdl) {
      const oGrid = this.dhx;
      const oTreeNodes = { parent: cParentHdl, rows: [] };
      const iColNum = oGrid.getColumnsNum();

      for (let iRow = 0; iRow < beData.length; iRow++) {
        const f = beData[iRow];
        const cId = f.selfhdl ? f.selfhdl : f._id;
        for (let iCol = 0; iCol < iColNum; iCol++) {
          const cCurrCol = oGrid.getColumnId(iCol);
          if (!cCurrCol)
            akioma.log.warn('Invalid column: ', iCol);
          else {
            try {
              oGrid.cells(cId, iCol).setValue(f[cCurrCol]);
            } catch (e) {
              akioma.log.error(e);
            }
          }
        }
      }

      return oTreeNodes;
    },


    convertToTreenodes: function(beData, cParentHdl) {
      const oSelf = this;
      const oGrid = this.dhx;
      const oTreeNodes = { parent: cParentHdl, rows: [] };
      let oColData = [];
      const columnList = [];
      const iColNum = oGrid.getColumnsNum();
      let cId;
      let cIdd;
      let userData = {};
      let f;
      let cCurrCol;

      for (let iRow = 0; iRow < beData.length; iRow++) {
        f = beData[iRow];
        oColData = [];
        cId = f.selfhdl ? f.selfhdl : f._id;
        cIdd = f._id;

        // force icon style from setting
        f.typeimage = akioma.icons.forceIconStyle(f.typeimage);

        for (let iCol = 0; iCol < iColNum; iCol++) {
          cCurrCol = oGrid._initColIds[iCol];
          if (!cCurrCol)
            akioma.log.warn('Invalid column: ', iCol, columnList);
          else if (cCurrCol.toLowerCase() == this.opt.treeColumn.toLowerCase()) {
            if (oSelf.opt.iconset == 'fontawesome')
              oColData.push({ icon: f.typeimage, value: f[cCurrCol] });
            else
              oColData.push({ image: f.typeimage, value: f[cCurrCol] });
          } else
            oColData.push(f[cCurrCol]);
        }
        if (oSelf.opt.iconset == 'fontawesome') {
          userData = { progname: f.typescreen, typescreen: f.typescreen, typekey: f.typekey, typehdl: f.typehdl, icon: f.typeimage };
          oTreeNodes.rows.push({ id: cId, idd: cIdd, xmlkids: f.haschilds, icon: f.typeimage, data: oColData, userdata: userData });
        } else {
          userData = { progname: f.typescreen, typescreen: f.typescreen, typekey: f.typekey, typehdl: f.typehdl, image: f.typeimage, datarechdl: f.datarechdl };
          oTreeNodes.rows.push({ id: cId, idd: cIdd, xmlkids: f.haschilds, image: f.typeimage, data: oColData, userdata: userData });
        }
      }

      return oTreeNodes;
    },


    treeRoomNotification: function(data) {
      akioma.log.info('room notification:', data);
      if (data.action == 'join') {
        akioma.notification({
          type: 'info',
          text: `${data.user} joined!`,
          lifetime: 10000,
          expire: 10000
        });
      } else if (data.action == 'leave') {
        akioma.notification({
          type: 'info',
          text: `${data.user} left!`,
          lifetime: 10000,
          expire: 10000
        });
      }
    },

    externalRefresh: function(data) {
      const oGrid = this.dhx,
        oSelf = this;
      try {
        if (data.nodeList) {
          for (const i in data.nodeList) {
            oGrid.forEachCell(i, (cell, ind) => {
              cell.setValue(data.nodeList[i][ind]);
            });
          }
        }
      } catch (e) {
        akioma.log.error(`Error when refreshing tree ${oSelf.opt.name}: ${e.message}`);
      }
    },

    setTypeOfAdd: function(cType) {
      this.typeOfAdd = cType;
    },


    // check position
    checkPosition: function(oGrid) {
      if (this.opt.positionHdl) {
        this.reposition2Hdl(this.opt.positionHdl);
        this.opt.positionHdl = null;
      }

      // Workaround for Treegrid
      if (!this.opt.useBusinessEntity && !oGrid.getSelectedRowId())
        oGrid.selectRow(0, true, false, true);
      $(oGrid.objBox).find('a').attr('tabIndex', -1);
    },

    // add observer
    addObserver: function(observer) {
      // just add observer to list
      if ($.inArray(observer, this.observers) == -1) {
        this.observers.push(observer);

        // and set datasource
        observer.dataSource = this;
      }
    },

    // data available
    dataAvailable: function(oElm) {
      const oSelf = this;
      // check for clear
      if (oElm.clear == true)
        this.dhx.clearAll();
      else {

        // treegrid
        const oSource = this.dataSource;
        if (oSource) {
          const oGrid = this.dhx;
          let cUrl = oGrid.kidsXmlFile;
          const cId = oSource.getIndex();

          cUrl += `&id=${oSource.getIndex()}&Start=true`;

          cUrl = `/akioma/gettree.xml?id=${oSource.getIndex()}&dataAvail=yes&Start=false`;

          if (oSelf.opt.typeRange == 'pos.ofr.') {
            let cCurrencySymbol = oSelf.dynObject.container.getLink('PRIMARYSDO:TARGET').getValue('currencysc');
            if (cCurrencySymbol == 'USD')
              cCurrencySymbol = '$';
            else if (cCurrencySymbol == 'EUR')
              cCurrencySymbol = '€';

            oSelf.applyNumericFormats(cCurrencySymbol);
          }

          if (!oSelf.opt.useBusinessEntity)
            oGrid.clearAndLoad(cUrl);
          else {
            oGrid.clearAll();
            oSelf.businessEntity.query.clearAll();
            if (oSelf.opt.foreignKey && oSelf.opt.extKey)
              oSelf.businessEntity.query.addCondition(oSelf.opt.extKey, 'eq', oSource.getFieldValue(oSelf.opt.foreignKey));
            else
              oSelf.businessEntity.query.addCondition('parenthdl', 'eq', cId);

            try {

              oSelf.businessEntity.openQuery({ 'extKey': cId }, (data, oResult) => {
                if (oResult)
                  oResult.parse(data);

                const oData = oSelf.convertToTreenodes(data, oSelf.businessEntity.urlQuery.parenthdl);
                oData.parent = '';
                oGrid.clearAll();
                oGrid.parse(oData, 'json');


                // look for existing root node and select it
                for (let i = 0; i < oData.rows.length; i++) {
                  if (oData.rows[i].userdata.typekey.indexOf('pos.own.') > -1) {
                    oSelf.cGridRootNodeId = oData.rows[i].id;
                    oGrid.selectRow(i, true, false, true);
                    break;
                  }
                }

              });
            } catch (oErr) {
              akioma.log.error('error calling BE', oErr);
            }
          }

          if (akioma.socket)
            if (app.trace) akioma.log.info([ 'tree register for update messages', oSelf, akioma.socket, 'joining:', oSource.dynObject.getValue('selfhdl') ]);


        }
      }

      // delete container
      this.deleteContainer();
    },

    // delete container **************
    deleteContainer: function() {
      // delete container
      // check if container should be handled
      if (!this.opt.posFrameHandling)
        return;

      const oPanel = this.getContainer();
      if (oPanel) {
        // destroy container content
        oPanel.execOpt('deleteCont');
      }
    },

    getSrvProp: function(cName) {
      if (this.serverProp[cName])
        return this.serverProp[cName];
      else
        return '';
    },

    setSrvProp: function(cName, cValue) {

      if ($.inArray(cName, this.serverProp.srvrProp) == -1)
        this.serverProp.srvrProp.push(cName);

      if (cValue.substr(0, 1) == '$') {
        let oSelf, self, cCode;
        try {
          // get variable for dynObject
          oSelf = this;
          // required for eval context
          // eslint-disable-next-line no-unused-vars
          self = oSelf.dynObject;
          cCode = cValue.substr(1);
          this.serverProp[cName] = eval(cCode);
        } catch (e) {
          !_isIE && app.trace && console.error([ 'Error executing akioma code', oSelf, cCode, e ]);
          akioma.message({ type: 'alert-error', title: 'Error executing akioma code', text: `${cCode}<br />${e.message}` });
        }
      } else
        this.serverProp[cName] = cValue;

      const oPar = $.extend({ Name: this.opt.name }, this.serverProp);
      this.dhx.kidsXmlFile = `/akioma/gettree.xml?${$.param(oPar)}`;
    },

    delSrvProp: function(cName) {
      if (this.serverProp[cName]) {
        delete this.serverProp[cName];
        $.removeEntry(this.serverProp.srvrProp, cName);
        const oPar = $.extend({ Name: this.opt.name }, this.serverProp);
        this.dhx.kidsXmlFile = `/akioma/gettree.xml?${$.param(oPar)}`;
      }
    },

    // get index ********************
    getIndex: function(lReset) {
      const oTree = this.dhx;

      // reset index
      if (lReset) {
        oTree.clearSelection();
        return null;
      } else
        return oTree.getSelectedRowId();
    },

    // get field value
    getFieldValue: function(cFieldName, cIndex) {
      // selfhdl is our own key
      if (cFieldName == 'selfhdl')
        return this.getIndex();

      // if no index -> take actual one
      if (!cIndex)
        cIndex = this.getIndex();

      // try to get value of tree
      return this.dhx.getUserData(cIndex, cFieldName);
    },

    // get value
    getValue: function(cFieldName) {
      return this.getFieldValue(cFieldName);
    },

    // before row select ************
    beforeRowSelect: function(cNewId, cOldId) {

      // console.log('selected row id', cNewId);

      if (this.isExpanding)
        return false;

      // no change
      if (cNewId == cOldId)
        return true;


      if (this.opt.posFrameHandling) {
        const oContainer = this.getContainer();

        if (oContainer.childs.length > 0) {
          const oDataSrc = oContainer.childs[0].getDataSource();
          if (oDataSrc)
            oDataSrc.controller.cleanAfterFillOnce();
        }
      }


      // cancel select
      if (this.mode[cOldId]) {
        this.mode[cOldId] = null;
        delete this.mode[cOldId];
      }
      return true;
    },
    /**
 * Creates new record in posframe
 * @memberof ak_treegrid
 * @instance
 * @returns {void}
 */
    fnCreateNewNode: function(cRowId) {

      const oC = this.getContainer();
      const BE = oC.childs[0].getDataSource().controller;

      if (BE.jsdo != undefined) {

        // create default object from businessentity schema

        const newRecordObj = {};

        newRecordObj.RefHdl = cRowId;
        newRecordObj.akStructTypeHdl = this.iAddRecType;
        newRecordObj.akStructParentHdl = this.iAddParent;
        newRecordObj.akStructRelationType = this.iAddTypeHdl;

        // add new record
        BE.bTreeRefresh = false;

        BE.dc.updatedRows = [];

        BE.setForeignKeys([
          {
            name: 'RefHdl,akStructTypeHdl,akStructParentHdl,akStructRelationType',
            value: `${cRowId},${this.iAddRecType},${this.iAddParent},${this.iAddTypeHdl}`
          }
        ]);

        const iIndex = BE.dhx.add(newRecordObj);
        BE.dhx.setCursor(iIndex);

        // save forms in batch mode
        BE.dhx.saveBatch(() => {
          const cObjectID = BE.opt.name + BE.opt.linkid;
          // tell all observers to update data in source
          app.messenger.publish({
            link: {
              LinkName: 'DISPLAY',
              LinkType: 'TRG',
              ObjName: cObjectID
            },
            method: 'submitRow',
            caller: BE
          });

        });

        // automatically set focus in the PosFrame DISPLAY target in the first form field
        try {
          const oDisplayTarget = BE.dynObject.getLink('DISPLAY:TARGET');
          if (oDisplayTarget && oDisplayTarget.controller) {
            if (oDisplayTarget.controller.dhx) {

              const oComputedProp = window.getComputedStyle(oDisplayTarget.controller.dhx.cont);
              const bVisible = (oComputedProp.getPropertyValue('visibility') !== 'hidden');
              if (bVisible) {
                oDisplayTarget.controller.dhx.setFocusOnFirstActive();
                $(document.activeElement).closest('.akFormDynSelect').find('select').select2('open');
              }

            }

          }

        } catch (e) {
          console.warn('Could not focus PosFrame', e);
        }

      }
    },

    // row select ****************
    rowSelect: function(cRowId) {
      const oParentPanel = this.getAncestor('panel');
      if (oParentPanel != null) {
        $(oParentPanel.dhx.cell).focus();
        oParentPanel.setActivePanelState();
      }

      // get grid and values
      const oGrid = this.dhx,
        oSelf = this,
        cTypeKey = oGrid.getUserData(cRowId, 'typekey');

      this.typeKey = cTypeKey;

      try {
        const oWindowParent = oSelf.dynObject.container.controller;
        $(oWindowParent.dhx.cell).focus();
      } catch (e) {
        akioma.log.error('focus window for keyevents to work error', e);
      }

      // save Selection if required
      if (oSelf.dataSource && oSelf.opt.keepSelection) {
        const cRow = this.dhx.getSelectedRowId();
        if (cRow)
          this.setKeepSelection({ identifier: oSelf.dataSource.opt.identifier, value: cRow });

      }

      const detailFrameNamePattern = this.opt.detailFrameNamePattern.toLowerCase();
      const template = Handlebars.compile(detailFrameNamePattern);
      const rowUserData = oSelf.dhx.UserData[oGrid.getSelectedRowId()];
      const data = {};
      rowUserData.keys.forEach(key => {
        data[key] = oSelf.dhx.getUserData(oGrid.getSelectedRowId(), key);
      });

      const screenName = template(data);

      // activate program -> only when 1 record is selected (avoid when multiselect)
      const cSelected = oGrid.getSelectedRowId();
      if (screenName && cSelected.split(',').length == 1) {
        try {
          const oRibbon = this.dynObject.container.controller.getDescendant('ribbon');
          const oTabbar = this.getAncestor('tabbar');

          let cPageIndex, cPageKey;
          if (oTabbar) {
            cPageIndex = this.getAncestor('tabbar').currentPageNum();
            cPageKey = this.getAncestor('tabbar').currentPageKey();
          }

          this.dynObject.topScreen.controller._visibilityRules = [];
          if (oRibbon)
            VisibilityRules.checkRibbonVisibilityRules(oRibbon, cPageIndex, cPageKey);
        } catch (oErr) {
          akioma.log.error(oErr);
        }

        // check if container should be handled
        if (this.opt.posFrameHandling) {
          const oPanel = this.getContainer();
          if (!oPanel)
            return;

          // set posframe for saving panel on window layout save
          const oWindow = this.getAncestor('window');
          oWindow.oPosFrame = oPanel;


          // open container
          const promiseOpenedContainer = oPanel.execOpt('openCont', {
            screenName,
            data,
            typeKey: cTypeKey,
            dataSource: this.opt.name,
            rowId: cRowId,
            self: this
          });

          promiseOpenedContainer.done(() => {
            const oContainer = oSelf.getContainer();
            try {
              let oDataSrc, bBE;

              if (oContainer.childs.length > 0) {
                oDataSrc = oContainer.childs[0].getDataSource();
                if (oDataSrc)
                  bBE = oDataSrc;

              }

              if (oSelf.aRowsAddState.indexOf(oSelf.dhx.getSelectedRowId()) > -1) {
                oDataSrc = oContainer.childs[0].getDataSource();
                oDataSrc.controller.bInitialFetchedReq = false;
                oDataSrc.controller.opt.initialFetch = '#none';
              }

              if (oSelf.state == 'add' && oSelf.aRowsAddState.indexOf(oSelf.dhx.getSelectedRowId()) > -1) {
                if (bBE) {
                  // after catalog add create new record
                  oDataSrc.controller.addAfterCatalogAdd(oSelf.fnCreateNewNode.bind(oSelf, cRowId));
                  // in case user reselects the record
                  const createNewNode = oSelf.fnCreateNewNode.bind(oSelf, cRowId);
                  oDataSrc.controller.addAfterFillOnceCallback(createNewNode);
                  // after save changes remove create new node, user can't reselect in create mode is removed
                  oDataSrc.controller.addAfterSaveChangesOnceCallback(() => {
                    oDataSrc.controller.cleanAfterFillOnceCallback(createNewNode);
                  });
                }
              }
            } catch (e) {
              akioma.log.error('Error create new struct node', e);
            }
          }).fail(() => {
            akioma.notification({ type: 'error', text: 'Error loading posFrame for selected tree node.' });
          });

        } else {
          const oSource = this.dynObject.getLink('DATA:TARGET');

          if (oSource) {
            // clear datasource only if data is new
            let bClear = true;
            if (oSource.controller.lastId != null && oSource.controller.lastId == oSource.controller.dhx.first())
              bClear = false;
            oSource.controller.bClear = bClear;

            oSource.openQuery({
              foreignKey: cRowId,
              caller: oGrid,
              mode: ''
            });
          }
        }

      }
    },

    /**
     * Method for setting the selection details
     * @private
     * @instance
     * @memberOf ak_treegrid
     * @param {object} oObj
     */
    setKeepSelection: function(oObj) {
      this.oKeepSelectionData = oObj;
    },
    /**
     * Method used for setting the keepSelection data after a refresh of data
     * @private
     * @instance
     * @memberOf ak_treegrid
     */
    keepSelection: function() {
      if (this.oKeepSelectionData) {
        const oSettings = this.oKeepSelectionData;
        const value = oSettings.value;
        if (value)
          this.reposition2Hdl(value);
      }
    },

    // save container ****************
    saveContainer: function(cRowId) {

      // enable tree
      this.parent.dhx.progressOff();

      // get container
      const oPanel = this.getContainer();

      // get frame in container
      const oFrame = oPanel.childs[0];
      if (!oFrame || oFrame.view != 'frame')
        return true;

      // save record
      const cObjName = oFrame.getObjName('PRIMARYSDO', 'SRC');
      const aSource = app.messenger.getObjects(this.dynObject, `PRIMARYSDO:TRG:${cObjName}`);

      // save rec
      // check if posFrame has business entity
      const oC = this.getContainer();
      const oSelf = this;

      // for business entity jsdo nodes
      if (oC.childs[0].getDataSource() && oC.childs[0].getDataSource().controller.jsdo != null) {
        const BE = oC.childs[0].getDataSource().controller;

        BE.bTreeRefresh = true;

        BE.addAfterSaveChangesOnceCallback((success, jsdo, record) => {
          if (success) {
            this.state = '';
            const index = this.aRowsAddState.indexOf(record['refhdl']);
            if (index > -1)
              this.aRowsAddState.splice(index, 1);

          }
        });

        BE.updateRecord({});
        BE.dc.updatedRows = [];

        const oTree = oSelf;
        const cSelected = oTree.dhx.getSelectedRowId();
        oTree.cNewNodeToSelect = cSelected;

        // delete recinfos for actual record
        if (this.mode[cRowId]) {
          this.mode[cRowId] = null;
          delete this.mode[cRowId];
        }
        // for SDO
      } else if (aSource && aSource.length == 1) {
        let oFocusField;
        try {
          oFocusField = aSource[0].parent.getObject(akioma.lastFocus.form);
          if (oFocusField)
            oFocusField = oFocusField.getObject(akioma.lastFocus.field);
          if (oFocusField) app.controller.callAkiomaCode(oFocusField.controller, oFocusField.controller.opt.leaveEvent);
        } catch (oErr) {
          akioma.log.error([ 'error applying leave to field with focus:', oErr, oFocusField, aSource[0], akioma.lastFocus ]);
        }

        // tell datasource of container to update record
        let oMode = null;
        if (this.mode[cRowId])
          oMode = this.mode[cRowId];

        // update record
        aSource[0].controller.updateRecord({
          method: 'updateRecord',
          caller: this,
          mode: oMode
        });
        if (aSource[0].controller.dc != undefined)
          aSource[0].controller.dc.sendData();
        // delete recinfos for actual record
        if (this.mode[cRowId]) {
          this.mode[cRowId] = null;
          delete this.mode[cRowId];
        }
      }

      return true;
    },

    // finish update ****************
    finishUpdate: function() {
      this.refreshAllVisibileNodes(null, true);
    },

    // save profile ********************
    saveProfile: function(cType) {
      const oSelf = this,
        aField = [],
        aWidth = [],
        iCols = this.dhx.getColumnsNum();

      if (cType == 'clear') {
        // clear profile
      } else {
        for (let i = 0; i < iCols; i++) {
          aField.push(this.dhx.getColumnId(i));
          aWidth.push(this.dhx.getColWidth(i));
        }
      }

      $.ajax({
        type: 'POST',
        url: '/akioma/saveGridProfile.p',
        dataType: 'json',
        data: `Proc=${this.opt.objname}&Fields=${aField.join(',')}&Width=${aWidth.join(',')}`,
        success: function() {},
        error: function(xhr, textStatus, errorThrown) {
          akioma.log.error(`Error getting data from ${oSelf.opt.SDO}: ${textStatus} -> ${errorThrown}`);
        }
      });
    },

    getAllParentIDs: function(cRowID) {
      const aElms = [];
      const oSelf = this;
      const addElem = cId => {
        const cNewId = oSelf.dhx.getParentId(cId);
        // if there is a parent add it and check for more
        if (cNewId != '') {
          aElms.push(cNewId);
          addElem(cNewId);
        }
      };
      addElem(cRowID);
      return aElms;
    },
    // refresh all visible tree nodes descriptions
    refreshAllVisibileNodes: function(fCallback, bRefreshOnlyParentAndSelected) {
      const oSelf = this,
        oGrid = this.dhx,
        aNodes = [];


      let cSelfHdlList = '';
      // gets all visible node records
      if (bRefreshOnlyParentAndSelected == undefined) {
        oGrid.forEachRow(id => {
          aNodes.push(id);
        });
        cSelfHdlList = aNodes.join(',');

      } else {

        // this gets all parents and current record
        const cCurrentRec = oSelf.dhx.getSelectedRowId();
        const aAllItemIds = oSelf.getAllParentIDs(oSelf.dhx.getSelectedRowId());

        aAllItemIds.push(cCurrentRec);
        cSelfHdlList = aAllItemIds.join(',');
      }


      try {
        oSelf.parent.dhx.progressOn();
        oSelf.businessEntity.query.clearAll();
        // oSelf.businessEntity.query.addUniqueCondition('SelfHdlList', 'eq', cSelfHdlList);
        oSelf.businessEntity.setNamedQueryParam('RecordList', 'Handles', cSelfHdlList, 'character');

        oSelf.businessEntity.addAfterFillOnceCallback(() => {
          oSelf.businessEntity.removeNamedQueryParam('RecordList');
        });

        oSelf.businessEntity.openQuery({}).then(data => {

          try {
            delete oSelf.businessEntity.oNamedQuery;
            oSelf.refreshTreenodes(data);
            if (fCallback)
              fCallback();
            oSelf.parent.dhx.progressOff();
          } catch (e) {
            akioma.log.error('Error in treeRefresh callback method', e);
          }

        }).fail(() => {
          delete oSelf.businessEntity.oNamedQuery;
          oSelf.parent.dhx.progressOff();
        });
      } catch (oErr) {
        akioma.log.error('error calling BE', oErr);
      } finally {
        delete oSelf.businessEntity.urlQuery.SelfHdlList;
      }
    },
    // refresh tree descriptions
    refreshDesc: function(cHdl, fCallback) {

      // now update tree
      const oGrid = this.dhx,
        oSelf = this;


      if (oSelf.opt.useBusinessEntity) {

        try {
          oSelf.parent.dhx.progressOn();
          oSelf.businessEntity.urlQuery.filterFields = `TreeRefresh|${cHdl}`;
          oSelf.businessEntity.urlQuery.parenthdl = '';

          oSelf.businessEntity.openQuery({ 'extKey': cHdl }, data => {
            oSelf.refreshTreenodes(data);
            if (fCallback)
              fCallback();
          });
        } catch (oErr) {
          akioma.log.error('error calling BE', oErr);
        } finally {
          oSelf.businessEntity.urlQuery.filterFields = '';
          oSelf.parent.dhx.progressOff();
        }

        return;
      }

      $.ajax({
        type: 'GET',
        async: false,
        url: `/akioma/getdata.xml?Action=getTreeDesc&id=${cHdl}`,
        dataType: 'json',
        success: function(data) {
          try {
            if (data.desc) {
              for (const i in data.desc) {
                oGrid.forEachCell(i, (cell, ind) => {
                  cell.setValue(data.desc[i][ind]);
                });
              }
            }
          } catch (e) {
            akioma.log.error(`Error when updating tree ${oSelf.opt.name}: ${e.message}`);
          }
        },
        error: function(xhr, textStatus, errorThrown) {
          akioma.log.error(`Error updating tree ${oSelf.opt.name}: ${textStatus}->${errorThrown}`);
        }
      });
    },

    // get container ****************
    getContainer: function() {

      // check if container should be handled
      if (!this.opt.posFrameHandling)
        return null;

      const oCont = this.parent.parent.childs[this.parent.parent.childs.length - 1];

      // check if menu for profile already attached
      if (!this.contMenu) {
        oCont.attachContextMenu();
        this.contMenu = true;
      }

      return oCont;
    },

    // is child object
    isChildObject: function(cProgName) {

      const oPanel = this.getContainer();
      if (!oPanel)
        return null;

      const oFrame = oPanel.childs[0];
      if (!oFrame)
        return null;

      const cChildObject = oFrame.opt.name;
      const lIsObject = (cProgName == cChildObject);

      return ((lIsObject) ? oFrame : null);
    },
    //  open nodes on leveles using CTRL + no(0-9)
    openNodesOnLevels: function() {
      const oSelf = this,
        oGrid = this.dhx;

      try {
        if (oSelf.iCurrentLvl == 1 && oSelf.aSubItemsChain && oSelf.aSubItemsChain.length == 0)
          oSelf.iCurrentLvl = 2;

        if (oSelf.aSubItemsChain && oSelf.aSubItemsChain.length == 0 && oSelf.iCurrentLvl < oSelf.iOpenLvl) {
          const aSubItm = [];

          for (let i = 0; i < oSelf.aItemsNextLvl.length; i++) {
            const cSubItems = oGrid.getAllSubItems(oSelf.aItemsNextLvl[i]),
              aSubItems = cSubItems.split(',');
            aSubItm.push(...aSubItems);
          }

          oSelf.aSubItemsChain = aSubItm.slice(0);
          oSelf.aItemsNextLvl = [];
          oSelf.iCurrentLvl++;

        } else if (oSelf.iCurrentLvl >= oSelf.iOpenLvl && oSelf.aSubItemsChain && oSelf.aSubItemsChain.length == 0) {
          delete oSelf.aSubItemsChain;
          oSelf.aItemsNextLvl = [];
          oSelf.iOpenLvl = 1;
          return;
        }


        // action on ctrl key + level (current level open all nodes)
        // set open chain for this level
        if (oSelf.iOpenLvl > 1 && oSelf.aSubItemsChain == undefined) {
          const cSelected = oGrid.getSelectedRowId(),
            cSubItems = oGrid.getAllSubItems(cSelected),
            aSubItems = cSubItems.split(',');

          oSelf.aSubItemsChain = aSubItems.slice(0);

          for (let i = 0; i < aSubItems.length; i++) {

            if (oGrid._h2.get[aSubItems[i]].has_kids) {

              oGrid.openItem(aSubItems[i]);
              oSelf.aItemsNextLvl.push(oSelf.aSubItemsChain[i]);
              oSelf.aSubItemsChain.splice(0, oSelf.aSubItemsChain.indexOf(oSelf.aSubItemsChain[i]) + 1);
              break;
            } else if (i + 1 == oSelf.aSubItemsChain.length) {
              oSelf.aSubItemsChain = [];

              oSelf.openNodesOnLevels();
              break;
            }
          }
          // else if chain is already defined, open chain
        } else if (oSelf.aSubItemsChain && oSelf.aSubItemsChain.length > 0) {
          const aTempSubItems = oSelf.aSubItemsChain.slice(0);
          for (let i = 0; i < aTempSubItems.length; i++) {

            if (oSelf.dhx._h2.get[oSelf.aSubItemsChain[i]].has_kids) {
              oSelf.dhx.openItem(oSelf.aSubItemsChain[i]);

              oSelf.aItemsNextLvl.push(oSelf.aSubItemsChain[i]);
              oSelf.aSubItemsChain.splice(0, oSelf.aSubItemsChain.indexOf(aTempSubItems[i]) + 1);

              // oSelf.iOpenLvl--;
              break;
            } else if (i + 1 == aTempSubItems.length) {
              oSelf.aSubItemsChain = [];
              oSelf.openNodesOnLevels();
              break;
            }
          }
          // in case of level too big - no nodes to open before last level
        } else if (oSelf.aSubItemsChain && oSelf.aSubItemsChain.length == 0) {
          delete oSelf.aSubItemsChain;
          oSelf.aItemsNextLvl = [];
          oSelf.iOpenLvl = 1;

        }


      } catch (e) {
        console.warn('Error opening node levels', e);
      }
    },
    // open start
    openStart: function(cRowId, iState) {

      if (this.bExpandRdy == false)
        return false;

      this.isExpanding = cRowId;

      if (this.dragDropActive == true)
        return;

      // delete all children before opening to reload them
      if (iState == 1) {

        const cSelectedRowID = this.dhx.getSelectedRowId(),
          cParentID = this.dhx.getParentId(cSelectedRowID);
        // if row has children selected
        if (cRowId == cParentID)
          this.bSelectParentNode = true;
        else
          this.bSelectParentNode = false;

        this.dhx.deleteChildItems(cRowId);


      }

      return true;
    },

    // open end
    openEnd: function(cRowId, iState) {

      this.isExpanding = null;

      if (this.bExpandRdy == false)
        return false;

      if (this.dragDropActive == true)
        return;

      // check for additional operations
      if (iState == 1) {


        // check if we have an open chain -> call it for next level
        const aChain = this.prop.openChain;
        if (aChain && aChain.length > 0)
          this.openChain();

        // check for select
        if (this.prop.select) {
          const aSelect = this.prop.select;
          for (const i in aSelect) {
            if (i == '0')
              this.dhx.selectRowById(aSelect[i], false, true, true);
            else
              this.dhx.selectRowById(aSelect[i], true, false, false);
          }
          delete this.prop.select;
        }


      }

      if (this.bSelectParentNode) {
        this.bSelectParentNode = false;
        this.dhx.selectRowById(cRowId, false, true, true);
      }

      return true;
    },

    // open row
    openRow: function(cIndex) {
      // do default behavior
      const oSelf = this;
      const oGrid = oSelf.dhx;
      const iIndex = oGrid.getRowIndex(cIndex);
      const bOpened = oGrid.getOpenState(cIndex);

      // check if we have an open chain and check if loaded -> otherwise wait for call in dataUpdate
      if (iIndex > -1 && !bOpened)

        oGrid.openItem(cIndex);

      else if (!bOpened && bOpened != undefined)
        oSelf.openRow(cIndex);
      else {
        const cLast = this.prop.openChain[this.prop.openChain.length - 1];
        oGrid.selectRowById(cLast, false, true, true);
        this.prop.openChain = null;
      }
    },

    // open chain
    openChain: function() {
      const aChain = this.prop.openChain;
      if (aChain) {
        const oGrid = this.dhx;
        const cNextRow = aChain[0];
        oGrid.closeItem(aChain[0]);

        // if more than one row
        let iIndex;
        if (aChain.length > 1) {
          this.prop.openChain = aChain.slice(1);
          this.openRow(cNextRow); // now check, what to do -> only open it, when already available
        } else if (aChain.length == 1) { // last row -> just select it
          iIndex = oGrid.getRowIndex(aChain[0]);

          // if not available -> wait a little bit
          if (iIndex != -1) {
            oGrid.selectRowById(aChain[0], false, true, true);
            this.prop.openChain = null;
          }
          oGrid.selectRowById(aChain[0], false, true, true);
        }

        if (iIndex > 0) {
          this.prop.openChain = aChain.slice(1);
          this.openChain();
        }

      }
    },

    // reposition tree
    reposition2Hdl: function(cHdl) {

      // check if row is available
      const oSelf = this,
        iIndex = this.dhx.getRowIndex(cHdl);

      // if row is available
      if (iIndex > -1)
        this.dhx.selectRowById(cHdl, false, true, true);

      // if not ...
      else {
        // check in server
        akioma.invokeServerTask({
          name: this.getOperationsEntity(),
          methodName: 'GetChainToRoot',
          paramObj: { plcParameter: { Value: cHdl } }
        }
        ).done(oResult => {
          oSelf.prop.openChain = oResult.plcParameter.Value.split(',');
          setTimeout(() => {

            oSelf.openChain();

          }, 700);
        });
      }
    },

    // before drag ***************
    beforeDrag: function() {
      return true;
    },

    // drag *********************
    drag: function(cSrcId, cTrgId, oSrc, oTrg) {
      const oGrid = this.dhx,
        cDropMode = oGrid.dragContext.dropmode,
        cCopyMove = oGrid.dragContext.mode;
      oGrid.dragContext.slist();
      this.drop = {
        mode: cCopyMove,
        dropmode: cDropMode,
        srcHdlMove: cSrcId,
        srcHdlCopy: oSrc.getSelectedRowId(),
        trgHdl: cTrgId,
        srcElm: oSrc.akElm,
        trgElm: oTrg.akElm,
        srcObj: oSrc,
        trgObj: oTrg
      };

      // get dialog
      akioma.treeDragAndDrop.getDragForm.apply(this);

      // return false to prevent copy or move -> will be done after the dialog
      return false;
    },

    // execute drop mechanism (executed from dialog)
    executeDrop: function(oElm) {

      // check if we are in drop mode
      if (!this.drop)
        return;

      akioma.treeDragAndDrop.executeDrop.apply(oElm);
    },

    // show / hide tree
    tbViewTreeStructure: function() {

      // get panel
      const oParent = this.parent;
      if (oParent.view == 'panel') {

        // toggle panel
        oParent.toggleShowHide();
      }
    },

    // save
    tbNodeSave: function() {
      // get tree
      const oGrid = this.dhx;
      const cSelected = oGrid.getSelectedRowId();

      this.saveContainer(cSelected);

      const oWindowParent = this.dynObject.container.controller;

      $(oWindowParent.cell).focus();
    },

    /**
 * Delete node in a treegrid
 * @param {object} oElm
 * @instance
 * @memberof ak_treegrid
 */
    tbNodeDelete: function(oElm) {
      const oSelf = this,
        oGrid = this.dhx,
        aSelected = oGrid.getSelectedRowId().split(',');
      let cText = akioma.tran('messageBox.text.deleteEntry', { defaultValue: 'Soll der markierte Eintrag gelöscht werden?' });

      if (aSelected.length > 1)
        cText = akioma.tran('messageBox.text.deleteEntries', { defaultValue: 'Sollen die markierten Einträge gelöscht werden?' });


      if ($.inArray(this.cGridRootNodeId, aSelected) > -1)
        akioma.message({ type: 'alert-error', title: 'Delete tree node', text: 'Error deleting treenode: <br />You can\'t delete root node.' });
      else {
        akioma.message({
          type: 'confirm',
          dangerMode: (oElm && oElm.dangerMode) ? oElm.dangerMode : false,
          title: akioma.tran('messageBox.title.deleteEntries', { defaultValue: 'Löschen' }),
          text: cText,
          blockEsc: true,
          buttonText: akioma.tran('messageBox.button.delete', { defaultValue: 'Löschen' }),
          callback: function(lOk) {
            if (lOk) {
              if (oSelf.opt.delConfirm) {
                akioma.message({
                  type: 'confirm',
                  dangerMode: true,
                  title: akioma.tran('messageBox.title.deleteEntries', { defaultValue: 'Löschen' }),
                  text: akioma.tran('messageBox.text.delConfirm', { defaultValue: oSelf.opt.delConfirm }),
                  buttonText: akioma.tran('messageBox.button.delete', { defaultValue: 'Löschen' }),
                  callback: function(lOk) {
                    lOk && oSelf.deleteNodes();
                  }
                });
              } else
                oSelf.deleteNodes();
            }
          }
        });
      }
    },

    /**
     * Delete node in a treegrid with dangerMode true
     * @param {object} oElm
     * @instance
     * @memberof ak_treegrid
     */
    tbNodeDeleteDangerMode(oElm) {
      oElm.dangerMode = true;
      this.tbNodeDelete(oElm);
    },

    deleteNodes: function() {
      const grid = this.dhx;
      const selectedRowId = grid.getSelectedRowId();

      if (isNull(selectedRowId)) return;

      const container = this.getAncestor('frame,popup,window');
      container.dhx.progressOn();

      akioma.invokeServerTask({
        name: this.getOperationsEntity(),
        methodName: 'DeleteStructRecords',
        paramObj: {
          plcParameter: {
            Source: selectedRowId,
            IncludeChildren: 'YES'
          }
        }
      }).done(({ plcParameter }) => {
        try {
          const response = JSON.parse(plcParameter.Response);

          if (response.result === 'error') {
            akioma.message({ type: 'alert-error', title: 'Delete tree node', text: `Error deleting treenode: <br />${response.message}` });
            return;
          }

          if (response.result === 'warning')
            akioma.message({ type: 'alert', title: 'Delete tree node', text: `Information for deleting treenode: <br />${response.message}` });

          // remove node in treegrid
          grid.deleteSelectedRows();

          // delete container
          this.deleteContainer();

          // refresh tree
          this.refreshAllVisibileNodes();

        } catch (e) {
          !_isIE && console.error([ 'Error deleting tree', this, e.message ]);
          akioma.message({ type: 'alert-error', title: 'Delete tree node', text: `Error deleting treenode: <br />${e.message}` });
        } finally {
          container.dhx.progressOff();
        }
      }).fail(err => {
        console.error(err);
        container.dhx.progressOff();
      });
    },

    // new sibling node
    tbNodeSibling: function(oElm) {
      this.createNewNode(oElm, 1);
    },

    // new child node
    tbNodeChild: function(oElm) {
      this.createNewNode(oElm, 3);
    },

    // create new node *****************
    createNewNode: function(oElm, iType) {

      // get tree
      const oGrid = this.dhx;
      this.selected = oGrid.getSelectedRowId();

      // check if root and change from sibling node add to add as child
      if (iType == 1 && this.cGridRootNodeId && this.cGridRootNodeId == this.selected)
        iType = 3;

      // if already in add mode -> save first
      if (this.state == 'add') {
        this.bSkipSelectNode = true;
        this.saveContainer(this.dhx.getSelectedRowId());
      }

      this.state = 'add';


      // disable tree
      this.parent.dhx.progressOn();

      // get node type
      let cType = '';
      if (this.typeOfAdd)
        cType = this.typeOfAdd;
      else
        cType = this.dynObject.getLinkValue('USER1:SOURCE', 'tbNodeType');
      const oNode = app.nodeTypes[cType];


      // create new id
      const oNew = app.controller.callServerMethod(
          'Akioma/Swat/Struct/getNewStructHdl.p',
          [
            { type: 'iCHAR', value: this.selected },
            { type: 'iCHAR', value: cType },
            { type: 'iINT', value: iType },
            { type: 'iCHAR', value: '' },
            { type: 'oCHAR', name: 'newHdl' }
          ]),
        cNewId = oNew.newHdl;

      if (isNull(cNewId) || (!cNewId.startsWith('000:') && !isValidHdl(cNewId))) {
        akioma.message({ type: 'alert-error', title: 'Create new node', text: `Error creating new node.<br />Invalid handle form server: <br />${cNewId}` });
        return;
      }

      // add row
      try {
        if (iType == 1)
          oGrid.addRowAfter(cNewId, [`(${oNode.typeDesc})`], this.selected, oNode.image);
        else {
          const newRowParent = oGrid._h2.get[this.selected];
          newRowParent._xml_await = true;
          oGrid.openItem(this.selected);

          const subItems = oGrid.getSubItems(this.selected);
          if (subItems) {
            const firstItem = subItems.split(',')[0];
            oGrid.addRowBefore(cNewId, [`(${oNode.typeDesc})`], firstItem, oNode.image, false);
          } else
            oGrid.addRow(cNewId, [`(${oNode.typeDesc})`], 0, this.selected, oNode.image, false);

          const newRow = oGrid._h2.get[cNewId];
          newRow._xml_await = true;
          oGrid.openItem(this.selected);
        }
      } catch (e) {
        akioma.log.error(e);
      }

      if (this.aRowsAddState.indexOf(cNewId) == -1)
        this.aRowsAddState.push(cNewId);


      // set user data
      oGrid.setUserData(cNewId, 'typehdl', oNode.typeHdl);
      oGrid.setUserData(cNewId, 'progname', `${oNode.posFrame}.r`);
      oGrid.setUserData(cNewId, 'typekey', oNode.typeKey);
      oGrid.setUserData(cNewId, 'image', oNode.image);
      oGrid.setUserData(cNewId, 'datarecdesc', '');
      oGrid.setUserData(cNewId, 'typescreen', oNode.posFrame);

      // now select row
      this.mode[cNewId] = {

        recMode: 'add',
        recSibling: iType,
        recParent: this.selected,
        recType: cType
      };

      this.iAddTypeHdl = iType;
      this.iAddRecType = cType;
      this.iAddParent = this.selected;

      oGrid.selectRowById(cNewId, false, true, true);

      this.parent.dhx.progressOff();

      this.parent.dhx.disableInput();
    },

    // undo ************************
    tbUndo: function() {
      const cIndex = this.getIndex();

      // enable tree
      this.parent.dhx.progressOff();

      // reset actual record
      switch (this.state) {
        case 'add':
          this.deleteContainer();

          this.dhx.deleteRow(cIndex);
          this.selected && this.dhx.selectRowById(this.selected, false, true, true);
          break;
        default: {
          const cCurrRowID = this.dhx.getSelectedId();
          this.dhx.selectRowById(cCurrRowID, false, true, true);
          break;
        }
      }

      this.state = '';
    },

    // edit cell *****************************
    editCell: function(iStage, rId, iCol, nValue) {
      let cEvent;
      switch (iStage) {
        case 0:
          cEvent = 'focus';
          break;
        case 1:
          break;
        case 2:
          cEvent = 'blur';
          break;
      }

      // if we have a valid event
      if (cEvent) {

        // check for status of security
        if (cEvent == 'focus')
          return setProperty(this, $.extend({ mode: 'check' }, this.security));

      }

      return nValue;
    },

    // get columns settings
    getColumnSettings: function() {
      const oGrid = this.dhx;
      const oSettings = [];

      for (let i = 0; i < oGrid.getColumnsNum(); i++) {
        oSettings.push({
          hidden: oGrid.isColumnHidden(i),
          size: oGrid.isColumnHidden(i) ? (parseInt(oGrid.hdr.rows[0].cells[i]._oldWidth) || oGrid.initCellWidth[i]) : oGrid.getColWidth(i),
          id: oGrid.getColumnId(i)
        });
      }

      return oSettings;
    },

    // apply columns settings
    applyColumnSettings: function(settings) {
      if (!settings)
        return;

      const oGrid = this.dhx;

      if (typeof settings == 'string')
        settings = JSON.parse(settings);

      for (const i in settings) {
        if (+i != oGrid.getColIndexById(settings[+i].id))
          oGrid.moveColumn(oGrid.getColIndexById(settings[+i].id), +i);
        if (settings[+i].size != oGrid.getColWidth(+i))
          oGrid.setColWidth(+i, settings[+i].size);
        if (settings[+i].hidden != oGrid.isColumnHidden(+i))
          oGrid.setColumnHidden(+i, settings[+i].hidden);
      }
    },

    destroy: function() {
      const oSelf = this;

      // Destroy/unbind watcher
      if (this.hasChangesWindowWatcher && typeof (this.hasChangesWindowWatcher) === 'function')
        this.hasChangesWindowWatcher();

      akioma.SocketTreeGridRoom.leave(this.lastOwner);

      if (oSelf.aOpenNodeLevelsKeys.length > 0) {
        // unbind key events
        const mousetrap = oSelf.getAncestor('window').oMouseTrap;
        mousetrap.unbind(oSelf.aOpenNodeLevelsKeys);
        mousetrap.unbind('del');
        mousetrap.unbind('ctrl+ins');
        mousetrap.unbind('ins');
        mousetrap.unbind('shift');
        mousetrap.unbind('ctrl');

        Mousetrap.unbind(`tab.${oSelf.opt.id}`);
        Mousetrap.unbind(`enter.${oSelf.opt.id}`);
      }
    },

    getOperationsEntity() {
      return this.opt.useBusinessEntity ? this.opt.resourceName : 'Akioma.Swat.Struct.StructBT';
    }
  });

})(jQuery, jQuery);
