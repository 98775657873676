(function($) {

  // ********************* accordion ******************
  $.extend({
    /**
     * @param  {options} Object Repository attributes for Accordion widget.
     * @class ak_accordion
     * @tutorial accordion-desc
     * @param {Object} options Repository attributes for SwatAccordion.
     * @param {string} options.HtmlClass CLASS property for an HTML tag
     * @param {string} options.Template
     * @param {string} options.EventRowSelected client side code executed when a row is selected (becomes current row in a set of rows), e.g. by single click
     * @param {string} options.EventClick Client side validation code (JavaScript)
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
     * @param {boolean} options.multiMode allow multiple items opened at the same time
     * @augments ak_global
     */
    ak_accordion: function(options) {
      const oSelf = this,
        defaults = { iOpen: 0 };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;

      this.registerDynObject = true;
      this.useParentDynObjectLink = true;

      this.oLinks = [];

      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;


      // for zapp mode
      this.zappingContainerCreated = false;

      try {
        const oPanel = this.parent.dhx;
        const oAccordion = oPanel.attachAccordion();

        if (this.opt.multiMode)
          oAccordion.enableMultiMode(true);

        oAccordion.attachEvent('onActive', (itemId, state) => oSelf.onActive(itemId, state));

        $.extend(this, { dhx: oAccordion });


      } catch (e) {
        !_isIE && console.error(`error creating accordion ${this.opt.name}: ${e.message}`);
      }
    }
  });

  // methods for accordion
  $.ak_accordion.prototype = {

    finishConstruct: function() {
      const oSelf = this;
      // set akstyle in accordion
      $(oSelf.dhx.base).attr('akstyle', oSelf.opt.customStyle);
    },

    enableZappingMode: function() {
      const oAccordion = this,
        deferred = $.Deferred();

      // get prev opened accordion item to show on zapping disabled
      oAccordion.children(function() {
        if (oAccordion.dhx.cells(this.opt.id).isOpened()) {
          oAccordion.cBeforeZappingMode = this.opt.id;
          oAccordion.dhx.cells(this.opt.id).open();
        }

      });
      oAccordion.hideAllItems();
      // show only zappmode container
      oAccordion.children(function() {
        if (this.opt.id == oAccordion.zappingId) {
          oAccordion.dhx.cells(oAccordion.zappingId).show();
          oAccordion.dhx.cells(oAccordion.zappingId).open(false);
          oAccordion.skipActiveLoad = true;

          const oAccItem = oAccordion.dhx.cells(oAccordion.zappingId).akElm;
          if (oAccItem && oAccItem.opt.loadData) {
            const launchContainer = app.controller.launchContainer({
              target: oAccItem,
              proc: 'include.r',
              para: `RunFile=${oAccItem.opt.loadData}&Page=*` + `&guid=${oAccItem.opt.id}`,
              skipDesktopToggle: true,
              data: false,
              self: this,
              async: true
            });
            launchContainer.done(oElmP => {
              oAccordion.zappingContainerCreated = true;
              delete oAccordion.skipActiveLoad;
              oAccordion.zappModeContainer = oElmP;
              deferred.resolve(oElmP);

            });
          } else
            deferred.resolve(oAccordion.zappModeContainer);


        }

      });

      return deferred.promise();
    },

    onActive: function(itemId) {
      const oAccItem = this.dhx.cells(itemId).akElm,
        oSelf = this;

      if (oAccItem && oAccItem.opt.loadData && oSelf.skipActiveLoad == undefined && oAccItem.skipActiveLoad == undefined) {
        const launchContainer = app.controller.launchContainer();
        launchContainer.done(() => {
          oAccItem.skipActiveLoad = true;
        });
      }

      oSelf.childs.forEach(entry => {
        if (entry.popup)
          entry.popup.hide();
      });
    },

    showItem: function(itemId) {
      const oSelf = this;
      const accItems = oSelf.oLinks[itemId];
      if (accItems) {
        accItems.forEach(item => {
          if (item != oSelf.zappingId && !oSelf.zappingMode) {
            try {
              if (oSelf.dhx.t[item] != undefined) {
                oSelf.dhx.cells(item).show();
                oSelf.dhx.cells(item).open(true);
              }
            } catch (e) {
              akioma.log.error(e, 'Error showing accordion item.');
            }
          }
        });
      }
    },

    showAllItems: function() {
      const oSelf = this;
      oSelf.childs.forEach(item => {
        if (item.opt.id != oSelf.zappingId) // don't show zapping container
          oSelf.dhx.cells(item.opt.id).show();
      });
    },

    hideAllItems: function() {
      const oSelf = this;
      oSelf.childs.forEach(item => {
        if (oSelf.dhx.t[item.opt.id] != undefined)
          oSelf.dhx.cells(item.opt.id).hide();
      });
    },
    /**
     * Method for adding new accordion item from repository
     * @param {String} cLabel The label for the new accordion item
     * @param {String} cRepoName The name for the repository object to load
     * @param {Bool} initialOpen Initially open the accordion
     * @param [Object] oOptions Extra options including ForeignKey values
     * @memberOf ak_accordion
     * @instance
     */
    addAccordionItemRepository: function(cLabel, cRepoName, initialOpen, oOptions) {
      try {
        if (cRepoName == undefined)
          cRepoName = 'contactorgf';

        // create new accordionItem
        const cUID = dhtmlx.uid();
        const oAttrs = {
          id: cUID,
          title: cLabel,
          loadData: cRepoName,
          opened: initialOpen
        };

        if (oOptions) {
          if (oOptions.bFilterBusinessEntity)
            oAttrs.bFilterBusinessEntity = true;


          if (oOptions.caller)
            oAttrs.caller = oOptions.caller;

        }

        if (this.opt.multiMode == false && initialOpen == true)
          this.dhx.cells(cUID).open(true);


        if (oOptions) {
          if (oOptions.ForeignKey && oOptions.ForeignKey[0]) {
            const linkKey = oOptions.RelatedRecord;
            if (!this.oLinks[linkKey])
              this.oLinks[linkKey] = [];
            this.oLinks[linkKey].push(cUID); // links selfhdl with accordion item ID
          }
          if (oOptions.autoAdd != undefined)
            oAttrs.autoAdd = oOptions.autoAdd;
          if (oOptions.copy)
            akioma.accordionCopyRecID = oOptions.copy;

          if (oOptions.ForeignKey)
            akioma.accordionAddForeignKeys = oOptions.ForeignKey;

        }

        oAttrs.dynGuid = true;
        app.controller.parseProc({
          view: 'accordionItem',
          att: oAttrs,
          sub: []
        }, this);

        return cUID;

      } catch (e) {
        console.error('Could not add new accordionItem with repository definition', e);
      }
    }
  };

  // // ********************** accordionItem ***********************
  $.extend({
    ak_accordionItem: function(options) {
      const defaults = {};

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = 'accordionItem';

      this.registerDynObject = true;

      // initialize accordionItem
      const oParent = this.parent.dhx;
      if (oParent) {
        try {
          if (this.parent.opt.multiMode == false)
            oParent.addItem(this.opt.id, this.opt.title, false, '*');
          else
            oParent.addItem(this.opt.id, this.opt.title, this.opt.opened, '*');

          $.extend(this, { dhx: oParent.cells(this.opt.id) });
        } catch (e) {
          akioma.log.error([ 'Error adding accordionItem', this.opt.name, this, e.message ]);
        }
      } else
        akioma.log.error(`No valid parent for accordionItem ${this.opt.name}`);

    }
  });

  // // methods for accordionItem
  $.ak_accordionItem.prototype = {

    finishConstruct: function() {
      if (this.childs.length > 0)
        this.parent.dhx.cells(this.opt.id).open(true);
      if (this.parent.opt.multiMode == true && this.childs.length == 0 && this.opt.opened)
        this.openItem(this, this.opt.loadData);


      if (this.parent.opt.contextMenu)
        this.attachAccordionContextMenu(this);
    },

    openItem: function(item, loadData) {

      const oSelf = this;


      const oElm = {
        target: item,
        proc: (loadData.charAt(0) == '$') ? loadData : 'include.r',
        para: `RunFile=${loadData}&Page=*` + `&guid=${item.opt.id}`,
        skipDesktopToggle: true,
        self: item,
        async: true,
        autoAdd: (oSelf.opt.autoAdd || false)
      };

      if (this.opt.dynGuid)
        oElm.dynGuid = this.opt.dynGuid;
      if (oSelf.opt.caller)
        oElm.self = oSelf.opt.caller;


      app.controller.launchContainer(oElm);
    },

    attachAccordionContextMenu: function() {
      const oHeader = this.dhx;
      const oSelf = this;
      const oParent = this.parent;
      const btnConf = { className: 'class-headerGrid-btn', width: 16, height: 16 };
      const popup = oHeader.attachHeaderButton(btnConf, '');
      const layout = popup.attachLayout(150, 57, '1C');
      let mainMenu;

      popup.p.children[0].className += ' menuPopup';
      layout.cont.className += ' menuLayout';
      layout.cont.children[0].className += ' menuLayout';
      layout.cells('a').hideHeader();
      oSelf.popupBtn = popup;

      const aMainMenuOptions = [];

      const onItemClick = menuitemId => {
        if (oSelf.contextMenu != undefined)
          oSelf.contextMenu.applyAction(menuitemId, oSelf);
      };

      const onfinish = function() {
        if (oParent.menuContextLoaded == undefined) {
          oParent.menuContextLoaded = true;
          mainMenu.scan((itemid, label, icon) => {
            aMainMenuOptions.push({ id: itemid, text: label, img: icon });
          });
          oParent.menuItems = aMainMenuOptions;
        }

        const oContextMenu = layout.cells('a').attachMenu({});
        oContextMenu.setIconset('awesome');
        oContextMenu.loadStruct(oParent.menuItems);
        oContextMenu.renderAsContextMenu();
        oContextMenu.attachEvent('onClick', onItemClick);


        layout.cont.children[0].children[1].className += ' menuCell';
        for (let i = 0; i < oContextMenu.cont.children.length; i++) {
          $(oContextMenu.cont.children[i].children[0]).css('display', 'inline-block');
          $(oContextMenu.cont.children[i].children[1]).css('display', 'inline-block');
          $(oContextMenu.cont.children[i].children[0]).css('padding', '0 5px 0 15px');
        }
      };

      if (oParent.menuItems)
        onfinish();
      else {
        const cID = oParent.opt.contextMenu;
        mainMenu = app.controller.parseProc({
          view: 'menustructure',
          att: { id: cID },
          sub: []
        }, this);
        mainMenu.loadMenuElements(onfinish);
        oSelf.contextMenu = mainMenu;
      }

      return false;
    },

    setOption: function(option, value, oElm) {

      this.opt[option] = value;

      const oAccItem = this.dhx;
      const oAccordion = this.parent.dhx;

      if (oAccItem && oAccordion) {
        switch (option) {
          case 'title':
            oAccItem.showHeader();
            oAccItem.setText(value);
            break;
          case 'panelPopupObject':
            this.createPopupObject(value);
            break;
          case 'panelMenu':
            akioma.createPanelMenu(value, oElm, this);
            break;
        }
      }
      return this;
    },

    addPopover: function(cRepoName, displayType) {
      // create new Popover
      try {

        const oSelf = this;
        if (cRepoName == undefined)
          cRepoName = 'contactorgf';

        let oNew;
        switch (displayType) {

          case 'LAYOUT':
            oNew = app.controller.parseProc({
              view: 'popover',
              att: {
                id: dhtmlx.uid(),
                loadData: cRepoName
              },
              sub: []
            }, oSelf);
            break;

          case 'RIBBON':
            oNew = app.controller.parseProc({
              view: 'popover',
              att: { id: dhtmlx.uid() },
              sub: [
                {
                  view: 'panelset',
                  att: {
                    id: dhtmlx.uid(),
                    layout: '1C'
                  },
                  sub: [
                    {
                      view: 'panel',
                      att: {
                        id: dhtmlx.uid(),
                        layout: 'a'
                      },
                      sub: [
                        {
                          view: 'ribbon',
                          att: {
                            id: dhtmlx.uid(),
                            toolbarkey: `#${cRepoName}`
                          },
                          sub: []
                        }
                      ]
                    }
                  ]
                }
              ]
            }, oSelf);
            break;
        }

        oNew.akElm = oNew;

        return oNew;

      } catch (e) {
        console.error('Could not add new popover with repository definition', e);
      }

    },

    createContextMenuButton: function(popover, menuStructure, oTarget) {
      const layout = popover.attachLayout(150, 57, '1C');
      popover.p.children[0].className += ' menuPopup';
      layout.cont.className += ' menuLayout';
      layout.cont.children[0].className += ' menuLayout';
      layout.cells('a').hideHeader();

      const aMainMenuOptions = [];
      const oContextMenu = layout.cells('a').attachMenu({});


      menuStructure.scanMenuItemsObj(item => {
        if (item.icon != '')
          item.icon += ' fa-fw';
        if (item.type == 'break')
          aMainMenuOptions.push({ id: item.id, type: 'separator' });
        else
          aMainMenuOptions.push({ id: item.id, text: item.label, img: item.icon });
      });

      const onItemClick = menuitemId => {
        if (menuStructure.aMenuItems[menuitemId] && menuStructure.aMenuItems[menuitemId].code == 'getFiltersBT' && oTarget.bPreloadedFilters == undefined) {
          oTarget.bPreloadedFilters = true;
          const promiseFilters = oTarget.getFiltersBT();

          promiseFilters.done(aFilters => {
            oContextMenu.idPull[oContextMenu.idPrefix + menuitemId]._mouseOver = true;
            oContextMenu._updateItemComplexState(oContextMenu.idPrefix + menuitemId, true, true);
            oContextMenu._showPolygon(oContextMenu.idPrefix + menuitemId, oContextMenu.conf.dir_toplv);

            // add filters as children of parent menu
            for (const i in aFilters) {
              oContextMenu.addRadioButton('child', menuitemId, i, aFilters[i].FilterHdl, aFilters[i].Description, 'filtersgroup', aFilters[i].IsSetDefault);
              oTarget.aFtrHdls.push(aFilters[i].FilterHdl);

              if (aFilters[i].IsSetDefault)
                oContextMenu.setItemText(aFilters[i].FilterHdl, (`<i class="filterdef"></i>${aFilters[i].Description}`));
            }
            oContextMenu.addNewChild(menuitemId, ((oTarget.aFtrHdls.length > 0) ? oTarget.aFtrHdls.length : 0), 'mantainFilter', 'Maintain Filter');

            oTarget.panelHdrContextMenuParentID = menuitemId;
            oTarget.panelHdrContextMenu = oContextMenu;
          });

          return false;
        } else if (oTarget.aFtrHdls && oTarget.aFtrHdls.indexOf(menuitemId) != -1) {
          oTarget.useFilterBT(menuitemId);
          return false;
        } else if (menuitemId == 'mantainFilter') {
          app.controller.launchContainer({
            proc: 'editFilters.r',
            data: true,
            self: oTarget
          });
        }

        // normal handling, calling the menuStructure function code
        menuStructure.applyAction(menuitemId, oTarget);
        popover.hide();
      };

      oContextMenu.setWebModeTimeout(2000);
      oContextMenu.setIconset('awesome');
      oContextMenu.loadStruct(aMainMenuOptions);
      oContextMenu.attachEvent('onClick', onItemClick);

      layout.cont.children[0].children[1].className += ' menuCell';
      for (let i = 0; i < oContextMenu.cont.children.length; i++) {
        $(oContextMenu.cont.children[i].children[0]).css('display', 'inline-block');
        $(oContextMenu.cont.children[i].children[1]).css('display', 'inline-block');
        $(oContextMenu.cont.children[i].children[0]).css('padding', '0 5px 0 15px');
      }
    },

    closeAccordionItem: function() {
      const oSelf = this;
      const oParent = this.parent;
      const nextItem = oSelf.getNextSibling(oSelf.opt.id);
      try {
        if (nextItem) {
          oParent.dhx.cells(nextItem.opt.id).open();
          if (nextItem.childs.length == 0) {
            if (nextItem.opt.loadData)
              oSelf.openItem(nextItem, nextItem.opt.loadData);
          }
        } else {
          const prevItem = oSelf.getPreviousSibling(oSelf.opt.id);
          if (prevItem) {
            oParent.dhx.cells(prevItem.opt.id).open();
            if (prevItem.childs.length == 0) {
              if (prevItem.opt.loadData)
                oSelf.openItem(prevItem, prevItem.opt.loadData);
            }
          }
        }

        oSelf.destruct();
        const id = this.opt.id;
        this.parent.dhx.removeItem(id);
        for (let i = 0; i < this.parent.childs.length; i++) {
          if (this.parent.childs[i].opt.id == id)
            this.parent.childs.splice(i--, 1);
        }
        // oSelf.destroy(oSelf.opt.id);
        if (oSelf.popupBtn != undefined) {
          oSelf.popupBtn.hide();
          oSelf.popupBtn.destroy();
        }
      } catch (e) {
        akioma.log.error(e);
      }
    },

    undockAccordionItem: function() {
      const oParent = this.parent;
      const oSelf = this;
      oParent.dhx.cells(oSelf.opt.id).undock();
      oSelf.popupBtn.hide();
    },

    getNextSibling: function(id) {
      const itemsNumber = this.parent.childs.length;
      for (let i = 0; i < itemsNumber - 1; i++) {
        if (this.parent.childs[i].opt.id == id) {
          const nextAccItem = this.parent.childs[i + 1];
          return nextAccItem;
        }
      }
      return null;
    },

    getPreviousSibling: function(id) {
      const itemsNumber = this.parent.childs.length;
      for (let i = 1; i < itemsNumber; i++) {
        if (this.parent.childs[i].opt.id == id) {
          const prevAccItem = this.parent.childs[i - 1];
          return prevAccItem;
        }
      }
      return null;
    },

    destroy: function(id) {
      // check if dhtmlx element exists -> destroy all attached elements
      id = this.opt.id;
      if (this.dhx) {
        try {
          if (this.parent.dhx.cells(id)) {
            this.parent.dhx.cells(id).hide();
            this.parent.dhx.cells(id).detachObject(true);
            this.parent.dhx.removeItem(id);
            for (let i = 0; i < this.parent.childs.length; i++) {
              if (this.parent.childs[i].opt.id == id)
                this.parent.childs.splice(i--, 1);
            }
          }

        } catch (e) {
          !_isIE && console.error([ 'Error destroying', this.view, this.opt.name, e.message ]);
        }
      }
    }
  };

})(jQuery, jQuery);
