(function($) {

  // ********************* frame ******************
  $.extend({
    /**
 * SwatFrame Control
 * @class ak_frame
 * @param {Object} options Repository attributes for SwatFrame.
 * @param {string} options.PrivateData
 * @param {string} options.LayoutOptions List of multi-layout options for the object.
 * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
 * @param {boolean} options.isModal defines if the container should be modal (blocking all other ui elements)
 * @param {string} options.LABEL WidgetAttributes Label
 * @param {string} options.titleHeader specifies which panelHeader to use. when empty, then uses the header of its own panel. if "none" then no header at all. if "parent" it uses the header of the parent panel
 * @param {string} options.HtmlClass CLASS property for an HTML tag
 * @param {string} options.typeRange The type range which makes up a combobox in a Toolbar
 * @param {number} options.MinHeight The pre-determined minimum height of a visual object
 * @param {string} options.TITLE Browser, Frame, Dialog and Window title
 * @param {string} options.EventPreInitialize
 * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
 * @param {string} options.ForeignFields A comma-separated list, consisting of thefirst local db fieldname, followed by the matching source temp-table field name, followed by more pairs if there is more than one field to match.
 * @param {number} options.MinWidth The pre-determined minimum width of a visual object.
         * @param {string} options.hasChangesStyle Default is "akhaschanges". In case the value is empty the dirty state styling will not be applied.
 */
    ak_frame: function(options) {
      const oSelf = this,
        defaults = {};

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this._pendingMenuStructures = [];
      this._pendingMenuPromises = [];
      this._dynViewDataObjList = [];
      this.frameContainer = true;

      this.registerDynObject = true;
      this.registerVuexModule = true;

      if (options.securityRestrictions)
        this.securityRestrictions = options.securityRestrictions;

      akioma.BaseLayoutContainer.call(this);

      if (this.opt.title && this.parent.setOption) // for internal frames (used for pages) there is no setOption
        this.parent.setOption('title', this.opt.title);

      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      // frame promise if file is specified
      if (this.opt.file)
        this.promiseFrame = $.Deferred();


      // initialize tab
      const oParent = this.parent.dhx;
      if (oParent) {

        // check if parent is a frame -> use datasource from parent
        if (this.parent.view == 'frame' && this.parent.dataSource)
          this.dataSource = this.parent;

        const cell = oParent.cell;

        if (cell) {
          this.oMouseTrap = new Mousetrap(cell);
          $(cell).attr('tabindex', 1);

          this.oMouseTrap.bind(akioma.shortcutManager.get('FocusRibbonToolbar'), e => {
            if (e.preventDefault)
              e.preventDefault();

            let oControl = oSelf.getDescendant('ribbon') || oSelf.getDescendant('toolbar');
            if (!oControl)
              oControl = akioma.getRibbonToolbar(oSelf);

            akioma.focusRibbonToolbar(oControl);

            if (e.stopPropagation)
              e.stopPropagation();
          });
        }

        $.extend(this, { dhx: oParent });

      } else
        !_isIE && console.error('No valid parent for frame');


    }
  });

  // methods for frame
  Object.assign($.ak_frame.prototype, akioma.BaseLayoutContainer.prototype, {
    componentOptions: function() {
      const oSelf = this;
      return {
        watch: {
          'state.attributes.hasChanges': function(newValue, oldValue) {
            oSelf._hasChangesWatcher(newValue, oldValue);
          },
          'state.attributes.hasErrors': function(newValue, oldValue) {
            oSelf._hasErrorsWatcher(newValue, oldValue);
          }
        }
      };
    },

    /**
     * HasChanges vuex watcher
     * @param {boolean} newValue
     */
    _hasChangesWatcher: function(newValue) {
      if (!this.oVuexState)
        return;
      const hasChangesStyle = this.oVuexState.attributes.hasChangesStyle;
      if (hasChangesStyle !== '')
        $(this.dhx.cell).attr(hasChangesStyle, newValue);

      this._callEventOnStateChanged({ state: 'hasChanges', value: newValue });
    },

    /**
     * HasErrors Watcher for vuex store
     * @param {boolean} newValue
     */
    _hasErrorsWatcher: function(newValue) {
      if (!this.oVuexState)
        return;
      const hasErrorsStyle = this.oVuexState.attributes.hasErrorsStyle;
      if (hasErrorsStyle !== '')
        $(this.dhx.cell).attr(hasErrorsStyle, newValue);

      this._callEventOnStateChanged({ state: 'hasErrors', value: newValue });
    },

    /**
     * Method called after the frame has been fully parsed
     * @memberof ak_frame
     * @instance
     * @return  {void}
     */
    afterStructParsed: function() {
      const oSelf = this;

      // stop here if no more pendingMenuStructures
      if (oSelf._pendingMenuStructures.length == 0)
        return false;

      // load MenuStructure codes
      const menuStructuresLoaded = akioma.MenuStructureFactory.loadMenus(oSelf._pendingMenuStructures);

      menuStructuresLoaded.done(res => {
        for (const x in res) {
          const menu = oSelf._pendingMenuStructures[x];

          const promise = oSelf._pendingMenuPromises[x];
          // add to memory cache
          akioma.MenuStructureFactory.add(menu.opt.id, res[x]);

          if (res[x] === null) {
            akioma.notification({
              type: 'error',
              text: `Could not find menustructure code '${menu.opt.id}'.`
            });
            promise.resolve([]);
          } else
            promise.resolve(res[x]); // resolve menu structure

        }

      }).fail(e => {
        akioma.MenuStructureFactory.showMenuLoadError(e, oSelf._pendingMenuStructures);
      });
    },
    /**
     * Method for callback set after the frame has loaded the file repository object
     * @instance
     * @memberof ak_frame
     * @param {function} callback Function to be called
     */
    afterFileInit(callback) {
      if (!isNull(this.promiseFrame)) {
        this.promiseFrame.always(() => {
          callback.call();
        });
      } else
        callback.call();

    },
    /**
     * Method to refresh datasources from view
     * @instance
     * @memberof ak_frame
     */
    refreshViewDataFetch() {
      this._dynViewDataObjList.forEach(businessEntity => {
        businessEntity.object.refreshDataFetch(businessEntity.payload);
      });
      this._dynViewDataObjList = [];
    },
    // finish construct
    finishConstruct: function() {
      const oSelf = this;

      // set akstyle in frame
      $(oSelf.dhx.cell).attr('akstyle', oSelf.opt.customStyle);

      // propagate all hasChangesStyle from tab if frame loaded in tab ** will cover nested frames also
      const oTab = this.parent;

      if (oTab && oTab.view === 'tab') {
        oTab._dispatch('setHasChangesStyle', this.opt.hasChangesStyle);
        oTab._dispatch('setHasErrorsStyle', this.opt.hasErrorsStyle);
      } else {
        this._dispatch('setHasChangesStyle', this.opt.hasChangesStyle);
        this._dispatch('setHasErrorsStyle', this.opt.hasErrorsStyle);
      }

      if (!oSelf.opt.pages)
        oSelf.opt.pages = '*';


      // if we are a data target -> get datasource and use it for children
      if (this.opt.channelTrg) {
        for (const i in this.links) {
          const aLink = this.links[i].split(':');
          if (aLink[0] == 'DATA' && aLink[1] == 'TRG') {
            aLink[1] = 'SRC';
            const aData = app.messenger.getObjects(this.dynObject, aLink.join(':'));
            if (aData.length > 0) {
              this.dataSource = aData[0].controller;
              break;
            }
          }
        }
      }

      if (this.opt.file) {
        this.bIsFrameLoading = true;
        // load frame in container
        app.controller.launchContainer({
          target: oSelf,
          proc: 'include.r',
          para: oSelf.opt.file,
          pages: oSelf.opt.pages,
          data: true,
          self: oSelf
        }).always(() => {
          oSelf.bIsFrameLoading = false;
          oSelf.promiseFrame.resolve();
        });
      }

      // if parent layout cell is not defined and window of main layout is already loaded
      // set main content cell id

      if (akioma.cWindowParentCell == undefined && !akioma.loadingMainLayout) {
        if (this.opt.WindowParentCell == undefined)
          akioma.cWindowParentCell = 'b';
        else
          akioma.cWindowParentCell = this.opt.WindowParentCell;


        // for multi window create 2 windows, one for main dashboard window and the second for the dataview window items


        const oWindowsParentCell = oSelf.getDescendant('panelset').dhx.cells(akioma.cWindowParentCell);

        $(oWindowsParentCell.cell).append('<div id="mw-main-win-container"></div><div id="mw-wins-container"></div>');

        akioma.windowParentCell = oSelf.getDescendant('panelset').dhx.cells(akioma.cWindowParentCell).akElm;
        akioma.windowsParentCell = $('#mw-wins-container');
      }

      // init event
      if (this.opt.onInit)
        app.controller.callAkiomaCode(this, this.opt.onInit);

      this.callRules({
        eventEntity: this.dynObject.name,
        eventName: 'onInitialize',
        eventSource: ''
      });

      try {
        const oPanel = this.getAncestor('panel');
        if (oPanel && typeof (oPanel.afterAttachChild) == 'function') {
          const oChild = oPanel.childs[0];

          if (oChild && oChild.view === 'frame') {
            const filterednamespace = oChild.getFilteredNamespace();
            oPanel.afterAttachChild(filterednamespace);
          }
        }
      } catch (e) {
        console.error(e);
      }

      if (this.parent.view !== 'tab')
        akioma.setPanelHeader(this);

    },

    endConstruct: function() {
      this.checkAutoAdd();
    },

    setQueryParam: function(oElm) {
      const cObjectID = this.opt.name + this.opt.linkid;
      const aData = app.messenger.getObjects(this.dynObject, `PRIMARYSDO:TRG:${cObjectID}`);
      if (aData) {
        for (const i in aData)
          aData[i].setQueryParam(oElm);
      }
    },
    // this is used to automatically adding new business entity record
    checkAutoAdd: function() {
      const oSelf = this;
      // create new business entity record if autoadd option is true
      if (oSelf.opt.autoAdd) {
        const BE = oSelf.dynObject.getLink('PRIMARYSDO:TARGET').controller;

        // wait for initial BE load before adding new record
        // BE.dhx.attachEvent("onXLE", function(){
        BE.addCallback('afterFill', () => {
          // create default object from businessentity schema
          const oSchema = BE.jsdo.getSchema();
          const newRecordObj = {};
          for (const i in oSchema) {
            if (oSchema[i].default != undefined)
              newRecordObj[oSchema[i].name] = oSchema[i].default;
          }

          // add new record
          const iIndex = BE.dhx.add(newRecordObj);
          BE.dhx.setCursor(iIndex);
        });

      }
    },

    // add observer
    addObserver: function(oElm) {
      if (this.dataSource)
        this.dataSource.addObserver(oElm);
    },

    // data available
    dataAvailable: function(oElm) {
      this.openQuery(oElm);
    },

    // open query
    openQuery: function(oElm) {
      const oSelf = this;
      const deferred = new $.Deferred();
      const deferreds = [];

      const cObjectID = this.opt.name + this.opt.linkid;
      // try to get primarysdo
      const aData = app.messenger.getObjects(this.dynObject, `PRIMARYSDO:TRG:${cObjectID}`);


      if (aData) {
        for (const i in aData) {
          const bDataIsChildOfFrame = oSelf._checkIfChild(aData[i].controller);
          if (bDataIsChildOfFrame)
            deferreds.push(aData[i].openQuery(oElm));
        }
      }
      $.when.apply(null, deferreds).done(() => {
        deferred.resolve();
      }).fail(() => {
        deferred.reject();
      });

      return deferred.promise();

    },
    _checkIfChild: function(oElm) {
      try {
        const parent = oElm.parent;
        const oFrame = this;
        const oContainer = oElm.dynObject.container;

        const checkForParent = function(parent) {
          if (parent === oContainer.controller)
            return false;
          else if (parent === oFrame)
            return true;
          else if (parent.parent)
            return checkForParent(parent.parent);
          else
            return false;

        };

        return checkForParent(parent);
      } catch (e) {
        akioma.log.error('Error checking for frame child ', oElm, this);
      }
    },

    // get datasource (prim)
    getDataSource: function() {
      const cObjectID = this.opt.name + this.opt.linkid;
      const aData = app.messenger.getObjects(this.dynObject, `PRIMARYSDO:TRG:${cObjectID}`);
      if (aData && aData.length > 0)
        return aData[0];
      else
        return null;
    },

    // set title
    setTitle: function() {
      // check if parent is panel
      if (this.parent.view == 'panel') {
        // set title and panelMenu buttons in panel header
        akioma.setPanelHeader(this);
      }
    },

    // save profile
    saveProfile: function() {
      const oFrame = this.dhx;
      const aPar = [`${oFrame.getWidth()}|${oFrame.getHeight()}`];

      const pushPanel = (cId, oItem) => {
        aPar.push(`${cId}|${oItem.getId()}|${oItem.getWidth()}|${oItem.getHeight()}|${oItem.isCollapsed()}`);
      };

      const getPanelPar = (iId, oElm) => {
        // run function for each panel inside
        oElm.children('panel', function() {
          // check if panel is valid
          if (!this.dhx)
            return;

          // export panel size
          pushPanel(iId, this.dhx);

          // on home page look for tabbar
          if (iId == 0) {
            this.children('tabbar', function() {
              let i = 0;
              this.children('tab', function() {
                i += 1;
                const oPanelset = this.getDescendant('panelset');
                if (oPanelset)
                  getPanelPar(i, oPanelset);
              });
            });
          }
        });
      };

      // get layout
      this.children('panelset', function() {
        getPanelPar(0, this);
      });

      // save profile
      $.ajax({
        type: 'POST',
        url: '/akioma/saveProfile.p',
        dataType: 'json',
        data: `Proc=${this.opt.name}&Profile=${aPar.join(';')}`,
        success: function() { },
        error: function(xhr, textStatus, errorThrown) {
          akioma.log.error(`Error saving profile: ${textStatus} -> ${errorThrown}`);
        }
      });
    },

    destroy: function() {
      // check if we are attached in a dhx element
      if (this.dhx) {
        if (this.oMouseTrap) {
          this.oMouseTrap.reset();
          delete this.oMouseTrap;
        }
        this.dhx.detachMenu();
        this.dhx.detachToolbar();
        this.dhx.detachStatusBar();
        this.dhx.detachObject(true);

        $(this.dhx.cell).find('> .dhx_cell_hdr > .dhx_cell_hdr_text > div').remove();
        $(this.dhx.cell).find('> .dhx_cell_hdr > .dynSelectPanelHeader').remove();
      }
    }
  });

})(jQuery, jQuery);
