/**
 * Method used for when the user of the application is idle.
 * @param {number} minutes the time denominated in minutes after which the idle state is set.
 * @param {function} onIdle function executed when the user is idle
 * @param {function} onUnidle function executed when the user is active
 * @example Adding activeUser/inactiveUser class on body after 1 minute of inactivity.
 * akioma.setIdleTimeout(1, () => {
    document.body.classList.remove('activeUser');
    document.body.classList.add('inactiveUser');
  }, () => {
    document.body.classList.remove('inactiveUser');
    document.body.classList.add('activeUser');
  });
 */
akioma.setIdleTimeout = function(minutes, onIdle, onUnidle) {
  const milliseconds = minutes * 60000;
  let timeout = 0;
  startTimer();
  function startTimer() {
    timeout = setTimeout(onExpires, milliseconds);
    document.addEventListener('mousemove', onActivity);
    document.addEventListener('keydown', onActivity);
  }
  function onExpires() {
    timeout = 0;
    onIdle();
  }
  function onActivity() {
    if (timeout) clearTimeout(timeout);
    else onUnidle();
    // since the mouse is moving, we turn off our event hooks for 1 second
    document.removeEventListener('mousemove', onActivity);
    document.removeEventListener('keydown', onActivity);
    setTimeout(startTimer, 1000);
  }
};
