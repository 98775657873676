akioma.MasterLayoutHeader = class {
  /**
   * Method added on Layout header frame EventOnInitialize
   * for creating the userprofile and home icon in layout header
   * @param {ak_frame} frame
   */
  static renderCellsContent(frame) {

    // @todo will not be needed once we remove the extra frame when doing an include
    if (akioma.layoutHeaderSetupComplete || frame.controller.childs.length === 0)
      return;

    akioma.layoutHeaderSetupComplete = true;
    frame.controller.dhx.hideHeader();
    frame.controller.dhx.setHeight(70);
    frame.getFirstParentByType('panelset').dhx.setSeparatorSize(0, 0);

    const main_layout = frame.controller.dhx.dataObj;
    $(frame.controller.dhx.cell).addClass('taskbar-hdr-panel-container');
    akioma.headerSection = main_layout;

    const b = main_layout.cells('b');
    akioma.headerLayoutCell = b;

    b.hideHeader();
    akioma.headerSection.setSeparatorSize(0, 0);
    akioma.headerSection.setSeparatorSize(1, 0);
    app.containsVueTaskbarHeader = true;

    const a = main_layout.cells('a');
    this.createHomeIcon(a);

    const c = main_layout.cells('c');
    this.createUserProfileHeader(c, frame.controller);
    akioma.toggleAkMultiWindow(true);

  }

  /**
   * Method for creating the user profile in layout header
   * @param {dhtmlxLayoutCell} c
   * @param {object} oSelf
   */
  static createUserProfileHeader(c, oSelf) {

    c.hideHeader();
    c.setWidth(UserProfile.panelSize);

    akioma.toolBar = c.attachForm([]);
    $(c.cell).addClass('system-header');
    // styling systemPanel
    akioma.toolBar.cont.style.padding = 0;

    // global user profile dropdown menu here
    const opts = {
      type: 'combo',
      width: 100,
      className: 'userprofile',
      label: '',
      name: 'afterLogin',
      icon: '/dhtmlx/imgs/akioma/businessman-20.png',
      options: []
    };
    const userProfileMenu = app.controller.parseProc({
      view: 'menustructure',
      att: {
        id: (app.userMenuCode || 'UserProfileDropdown'),
        depth: 0
      },
      sub: []
    }, oSelf);

    let it;

    const onfinish = function() {
      userProfileMenu.scanMenuItemsObj(item => {
        opts.options.push({ text: item.label, value: item.id });
      });
      akioma.toolBar.addItem(null, opts, 1);

      it = akioma.toolBar.getCombo('afterLogin');
      $(it.base).addClass('akUserProfile');

      // load user avatar
      const typeKey = 'img.usr.main';
      $.ajax({
        type: 'POST',
        url: '/akioma/getdata.xml',
        dataType: 'json',
        data: `action=getImage&refHdl=${app.sessionData.userHdl}&Type=${typeKey}`,
        success: function(data) {
          let imageUrl = data.image;
          if (!imageUrl)
            imageUrl = '/imgs/profile/default-avatar.jpg';

          $(it.base).prepend(`<img src="${imageUrl}" alt="${app.sessionData.userDesc}"/>`);
        },
        error: function(xhr, textStatus, errorThrown) {
          akioma.notification({ type: 'error', text: `Error getting data from 'getimage': ${textStatus} -> ${errorThrown}` });
        }
      });

      $(it.DOMlist).addClass('topimp');

      it.unSelectOption();
      it.attachEvent('onChange', function(value) {

        if (value)
          userProfileMenu.applyAction(value, this);

        it.unSelectOption();
      });

      $(it.base).parent().append(`<p class="akUserInfo" title="${app.sessionData.userDesc}">${app.sessionData.userKey}</p>`);
    };
    const userProfileMenuPromise = userProfileMenu.loadMenuElements();
    userProfileMenuPromise.done(onfinish);

    const createMenuLookup = structureCode => {
      const oSelf = this;
      const aMainMenuOptions = [];
      let result;

      const mainMenu = app.controller.parseProc({
        view: 'menustructure',
        att: {
          id: structureCode,
          depth: 1
        },
        sub: []
      }, akioma.root.getDescendant('panelset').childs[0]);

      const onfinish = function() {
        mainMenu.scan((itemid, label, icon) => {
          aMainMenuOptions.push({ id: itemid, text: label, img: icon });
        });
        const templateMenu = Handlebars.compile(akioma.handlebarsTemplates.autocompleteSearchBT.templateMenu);
        const oData = { results: aMainMenuOptions };
        result = templateMenu(oData);
        mainMenu.result = result;
        oSelf.menuLookup = mainMenu;
      };
      mainMenu.loadMenuElements(onfinish);
    };
    let myPop;
    const addHeaderAutoCompleteSearch = () => {
      const optsSearch = { type: 'input', label: '', name: 'searchInput', width: 240, className: 'searchInput', inputHeight: 20 };
      akioma.toolBar.addItem(null, optsSearch, 1);
      const oInput = akioma.toolBar.getInput('searchInput');

      // create menu lookup here
      createMenuLookup(app.headerLookupMenuCode || 'headerLookup');

      myPop = new dhtmlXPopup({ form: akioma.toolBar, id: ['searchInput'] });
      myPop.p.className += ' mainLookup';
      const myLookup = null;
      self.gridSearchInputPopup = myPop;

      const oMouseTrap = new Mousetrap(oInput);
      oMouseTrap.bind('enter', function() {
        if (!myPop.isVisible()) myPop.show('searchInput');
        else {
          const searchItem = $(myPop.p).find('.searchItem').first()[0];
          const val = $(myPop.p).find('.searchItem').first().attr('id');
          app.controller.launchContainer({
            self: searchItem,
            para: `SelfHdl=${val}&Page=0,1`,
            extLink: val,
            data: true

          });

          this.gridSearchInputPopup.hide();
        }

      });
      oMouseTrap.bind('down', () => {
        $(myPop.p).find('.searchItem').first().focus();
        $(myPop.p).find('.searchItem').first().addClass('selected');

      });
      oMouseTrap.bind('up', () => {
        $(myPop.p).find('.searchItem').last().focus();
        $(myPop.p).find('.searchItem').last().addClass('selected');

      });

      akioma.toolBar.attachEvent('onInputChange', id => {
        if (id != 'searchInput') return;
        if (myLookup == null)
          if (!myPop.isVisible()) myPop.show('searchInput');

        if (!myPop.isVisible()) myPop.show('searchInput');
        const text = akioma.toolBar.getItemValue('searchInput');

        autoCompleteSearch(text);

      });
    };
    userProfileMenuPromise.always(addHeaderAutoCompleteSearch);


    function autoCompleteSearch(cInput) {
      const serviceURI = window.location.origin,
        jsdoSettings = {
          serviceURI: serviceURI,
          catalogURIs: `${serviceURI}/web/Catalog/Akioma.Swat.AutoCompleteSearchBT`,
          authenticationModel: progress.data.Session.AUTH_TYPE_FORM
        },
        oSelf = this;

      // after addCatalog perform a business task method call
      const onAfterAddCatalog = res => {
        // check for error type object
        if (akioma.isObjOfTypeError(res)) {
          akioma.log.error(res);
          return;
        }

        const dsTasks = new progress.data.JSDO ({ name: 'Akioma.Swat.AutoCompleteSearchBT' });

        // call param with input search term

        const oParameter = {
          plcParameter: {
            name: 'omniSearch',
            key: cInput,
            pos: ''
          }
        };

        // Perform the asynchronous call
        // true = asynchronously
        const call = dsTasks.GetMatchingResultSet (oParameter, true).deferred.promise();

        call.then (({ request }) => {

          // Retrieve JavaScript object from ABL serializable parameter
          const oOutput = request.response;

          const templateResults = Handlebars.compile(akioma.handlebarsTemplates.autocompleteSearchBT.templateResults);

          const oData = { results: oOutput.dsAutoCompleteSearch.autoCompleteSearch.searchResult };

          let result = templateResults(oData);
          if (oSelf.menuLookup)
            result += oSelf.menuLookup.result;
          myPop.attachHTML(result);
          myPop.p.children[1].className = 'dhx_popup_arrow dhx_popup_arrow_bottom';

          $('.searchItem').on('click', function() {
            const val = $(this).attr('id');
            app.controller.launchContainer({
              self: this,
              para: `SelfHdl=${val}&Page=0,1`,
              extLink: val,
              data: true
            });
            oSelf.gridSearchInputPopup.hide();
            return true;
          });

          $('.footer-add').on('click', function() {
            const id = $(this).attr('id');
            oSelf.menuLookup.applyAction(id, oSelf);
            oSelf.gridSearchInputPopup.hide();
            return true;
          });

          let $current;
          $('.searchItem:first-child').addClass('selected');
          $('.searchItem').mouseover(function() {
            $('.searchItem').removeClass('selected');
            $current = $(this);
            $current.addClass('selected');
          });
          $('.searchItem').mouseout(() => {
            $current.removeClass('selected');
          });

          // arrow-keys navigation for the omnisearch
          $(myPop.p).find('.searchItem').first().attr('tabindex', '0');
          $(myPop.p).find('.searchItem').each(function() {
            const searchItem = this;
            const oMouseTrap = Mousetrap(this);
            oMouseTrap.bind('down', () => {

              $(searchItem).removeClass('selected');
              if ($(searchItem).next().length) {
                $current = $(searchItem).next();
                $current.attr('tabindex', '0');
                $current.focus();
                $current.addClass('selected');
              } else {
                setTimeout(() => {
                  akioma.toolBar.getInput('searchInput').focus();
                }, 0);
              }

            });
            oMouseTrap.bind('up', () => {
              $(searchItem).removeClass('selected');
              if ($(searchItem).prev().length) {
                $current = $(searchItem).prev();
                $current.attr('tabindex', '0');
                $current.focus();
                $current.addClass('selected');
              } else {
                setTimeout(() => {
                  akioma.toolBar.getInput('searchInput').focus();
                }, 0);

              }

            });

            oMouseTrap.bind('enter', () => {
              $(searchItem).removeClass('selected');
              const val = $(searchItem).attr('id');
              app.controller.launchContainer({
                self: searchItem,
                para: `SelfHdl=${val}&Page=0,1`,
                extLink: val,
                data: true

              });
              oSelf.gridSearchInputPopup.hide();
              return true;
            });

          });


        }); // call.done

      }; // addCatalog.done

      // add catalog first
      akioma.restSession.addCatalog(jsdoSettings.catalogURIs).then(res => {
        onAfterAddCatalog(res);
      }).catch(e => {
        akioma.log.error(e);
      });

    }

    function addSocketIOStatusBtn() {
      // socket.io connection status
      akioma.toolBar.addItem(null, { type: 'btn2state', label: '', className: 'connectionBtn', checked: true }, 3);
      akioma.connectionBtn = $(akioma.toolBar.cont).find('.dhx_toolbar_btn:last-of-type');
      akioma.messaging.checkConnectionStatus();
    }

    userProfileMenuPromise.always(addSocketIOStatusBtn);
  }

  /**
   * Method for building the layout header / old htmlcontent template implementation
   * @param {*} main_layout
   * @param {object} oSelf
   */
  static renderCellsTemplateContent(main_layout, oSelf) {
    akioma.root.getDescendant('panelset').dhx.setSeparatorSize(0, 0);

    const cHeaderID = `main-header-${dhtmlx.uid()}`;

    main_layout.closest('.dhxform_obj_material').addClass('headerFormContainer').addClass('taskbar-header');

    main_layout.attr('id', cHeaderID);
    main_layout.addClass('multiwindow-header-cont');
    // build main app layout
    akioma.headerSection = new dhtmlXLayoutObject({
      pattern: '3W',
      parent: cHeaderID,
      offsets: { // optional, offsets for fullscreen init
        top: 0, // you can specify all four sides
        right: 0, // or only the side where you want to have an offset
        bottom: 0,
        left: 0
      }
    });
    akioma.headerSection.setSeparatorSize(0, 0);
    akioma.headerSection.setSeparatorSize(1, 0);
    main_layout = akioma.headerSection;

    const bContainsTaskbar = app.containsVueTaskbarHeader;
    const a = main_layout.cells('a');

    this.createHomeIcon(a);

    const b = main_layout.cells('b');
    main_layout.cells('b').hideHeader();
    akioma.headerLayoutCell = b;

    // old taskbar
    if (!bContainsTaskbar) {
      akioma.dataViewTabs = b.attachDataView({
        height: 40,
        type: {
          height: 40,
          width: 180,
          template: 'headertaskbar'
        },
        drag: true,
        select: true
      });
      akioma.dataViewTabs.define('type', 'headertaskbar');
      // toggle desktop state
      akioma.dataViewTabs.attachEvent('onItemClick', (id, ev) => {
        // dataview close and fit window
        const $target = $(ev.target),
          bClose = $target.hasClass('close-icon'),
          bFit = $target.hasClass('fit-icon');

        let oWin = null;
        if (bClose || bFit)
          oWin = $.getObjectByName({ id: id });


        if (bClose) {
          oWin.promptWindowClose().done(() => {
            if (akioma.dataViewTabs.get(id))
              akioma.dataViewTabs.remove(id);
          });

          return false;
        } else if (bFit) {
          oWin.parent._fitInWin(oWin.parent, id);
          return false;
        }

        // sets multiwindows winframe as visible
        akioma.toggleAkMultiWindow(false);
      });

      // on before drop arranges windows one next to the other
      akioma.dataViewTabs.attachEvent('onBeforeDrop', (context, ev) => {
        try {
          if (!akioma.toggleFlag) {
            akioma.stopWindowLoad = true;
            akioma.toggleAkMultiWindow(false);
          }

          const oWinSrc = akioma.aMultiWindows[context.start],
            oWinTrg = akioma.aMultiWindows[context.target];
          // move to left and right
          oWinSrc.posWindowAndCollapse('right');
          oWinTrg.posWindowAndCollapse('left');

          Animation.playRippleOnDrop(ev);

          if (oWinSrc && oWinTrg)
            return false;
        } catch (e) {
          console.error('Error on drop dataview item', e);
        }
      });
    }

    const c = main_layout.cells('c');
    this.createUserProfileHeader(c, oSelf);

  }

  /**
   * Method for creating home icon in layout header
   * @param {dhtmlxLayoutCell} a
   */
  static createHomeIcon(a) {

    $(a.cell).addClass('headerlayouts-ml');
    a.hideHeader();
    const themeName = akioma.Themes.getTheme();
    if (themeName == 'akioma-simple' ||
            themeName == 'dark-simple')
      UserProfile.homeButtonWidth = 60;
    else
      UserProfile.homeButtonWidth = 101;

    a.setWidth(UserProfile.homeButtonWidth);
    a.fixSize(1, 0);

    const toolbar_2 = a.attachToolbar();

    toolbar_2.setIconset('awesome');
    toolbar_2.addButtonTwoState('home', 1, '', 'switchdesktopsbtn', 'switchdesktopsbtn');

    // Handling for stacked icons
    if (!app.containsVueTaskbarHeader) {
      let cStackedTemplate = `<div class='homeIcon'>
                    <i class='fa-2x fa-fw firstIcon switchdesktopsbtn'></i>
                    <i class='fa-stack-1x fa-inverse secondIcon switchdesktopsbtn'</i>
                </div>`;
      if (themeName == 'playground-dark') {
        cStackedTemplate = `
                    <div class='homeIcon'>
                    </div>
                `;
      }
      const cIdPrefix = toolbar_2.idPrefix;
      const oHomeBtn = toolbar_2.objPull[`${cIdPrefix}home`].obj;
      $(oHomeBtn).find('i').remove();
      $(oHomeBtn).html(cStackedTemplate);

      if (akioma.getAkIdMode() == 'extended')
        $(oHomeBtn).attr('akId', 'homeButton');
    }


    toolbar_2.attachEvent('onBeforeStateChange', (id, state) => {
      if (id == 'home') {
        // check if no window in multiwindow winframe
        if (akioma.oWindowsParentCell.childs.length == 0 && state)
          return false;
      }

      return true;
    });

    // on home toolbar
    toolbar_2.attachEvent('onStateChange', (id, state) => {

      // home toolbar button click
      if (id == 'home')
        akioma.toggleAkMultiWindow(state);

    });

    toolbar_2.setItemState('home', true);
    akioma.homeMultiWindowsToolbar = toolbar_2;
  }
};
