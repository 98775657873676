dhtmlXLayoutCell.prototype.attachHeaderButton = function(conf, id, struct, rightPos, oSelf, bDropDownMode, helperObject) {
  function _getOffset(elem) {
    if (elem.getBoundingClientRect)
      return _getOffsetRect(elem);
    else
      return _getOffsetSum(elem);

  }

  function _getOffsetSum(elem) {
    let top = 0,
      left = 0;
    while (elem) {
      top = top + parseInt(elem.offsetTop);
      left = left + parseInt(elem.offsetLeft);
      elem = elem.offsetParent;
    }

    return { top: top, left: left };
  }

  function _getOffsetRect(elem) {
    const box = elem.getBoundingClientRect();

    const body = document.body;
    const docElem = document.documentElement;

    const scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;
    const scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft;

    const clientTop = docElem.clientTop || body.clientTop || 0;
    const clientLeft = docElem.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  }

  let cellLayout = null;
  let hdr = null;


  if (bDropDownMode == undefined)
    bDropDownMode = true;


  const cButtonAlign = app.sessionData.panelHeaderButtonAlign || 'left';

  if (id) {
    cellLayout = document.getElementById(id);
    hdr = (cellLayout ? cellLayout.getElementsByClassName('dhx_cell_hdr')[0] : null);
  } else
    hdr = this.cell.childNodes[this.conf.idx.hdr];


  if (rightPos == undefined)
    rightPos = '35px';
  const divBtn = document.createElement('div');
  // if there is a label set it as tooltip
  if (conf.label)
    divBtn.title = conf.label;
  if (helperObject && helperObject.tooltip && helperObject.tooltip !== '')
    divBtn.title = helperObject.tooltip;

  divBtn.className = (conf && conf.className ? conf.className : 'class-header-btn');
  divBtn.style.height = (conf && conf.height ? parseInt(conf.height) : 26);
  divBtn.style.width = (conf && conf.width ? parseInt(conf.width) : 26);
  divBtn.innerHTML = (conf && conf.content ? conf.content : '');
  let extraSpace = 0;
  if (helperObject) {
    if (helperObject.visualizationType == 'CHECKBOX' || helperObject.visualizationType == 'CHECK-BOX')
      divBtn.setAttribute('menu-name', helperObject.menuId);
  }
  const oCheckBoxes = ($(hdr).find('>div').find('.checkBoxPanelHeader'));
  if (oCheckBoxes.length) {
    for (const i in oCheckBoxes) {
      if (isFinite(parseInt(i)))
        extraSpace = extraSpace + parseInt($(oCheckBoxes[i]).innerWidth());
    }
  }

  divBtn.style.background = (conf && conf.backgroundImg ? conf.backgroundImg : '');
  divBtn.style.height = '20px';
  divBtn.style.width = '20px';
  divBtn.style.cursor = 'pointer';

  if (this.akElm) {

    if (this.akElm.parent.view == 'accordionItem')
      this.akElm = this.akElm.parent;
  }

  // Add akid for panel header button
  if (struct && struct.repoObject)
    $(divBtn).attr('akid', `panelHeaderMenu-${struct.repoObject}`);

  // Add panelMenu buttons in the header (icons)
  if (hdr) {
    if (cButtonAlign == 'left')
      hdr.children[0].appendChild(divBtn);
    else
      hdr.appendChild(divBtn);

    // Add checkbox filters text in the header
    if (helperObject && (helperObject.visualizationType == 'CHECKBOX' || helperObject.visualizationType == 'CHECK-BOX')) {
      const newElem = document.createElement('span');
      newElem.classList.add('checkBoxPanelHeader');

      const panelMenuVisibility = akioma.applicationSettings.uiStyle.panelMenuVisibility || 'always';
      if (panelMenuVisibility.toLowerCase() === 'always')
        newElem.classList.add('alwaysVisiblePanelBtn');

      newElem.textContent = helperObject.parentName.trim();
      newElem.style.paddingRight = '7px';
      newElem.style.lineHeight = '31px';

      if (cButtonAlign == 'left')
        hdr.children[0].appendChild(newElem);
      else
        hdr.appendChild(newElem);
    }
  }

  if (!bDropDownMode)
    return divBtn;

  let popup;
  if (struct == undefined)
    popup = new dhtmlXPopup();
  else {
    const displayType = (struct && struct.displayType ? struct.displayType : 'LAYOUT');
    popup = this.akElm.addPopover(struct.repoObject, displayType).dhx;
  }


  const doOnClick = function(e) {
    e.preventDefault();
    if (oSelf && oSelf.view == 'accordionItem')
      e.stopPropagation();
    let coordTL, top, left, height, width;

    if (!popup.isVisible()) {
      coordTL = _getOffset(divBtn);
      top = coordTL.top;
      left = coordTL.left;
      height = (conf && conf.height ? parseInt(conf.height) : 26);
      width = (conf && conf.width ? parseInt(conf.width) : 26);
      if (oSelf && oSelf.popup != undefined)
        oSelf.popup.hide();
      if (oSelf && oSelf.popup)
        oSelf.popup = popup;
      popup.show(left + 2, top, width, height);
    } else
      popup.hide();


    return false;
  };

  if (window.addEventListener)
    divBtn.addEventListener('click', doOnClick, false);
  else
    divBtn.attachEvent('onclick', doOnClick);


  popup.dhx = divBtn;

  if (oSelf)
    oSelf.parent.aPanelHeaderBtns.push(popup);

  return popup;
};

dhtmlXAccordionCell.prototype.attachHeaderButton = dhtmlXLayoutCell.prototype.attachHeaderButton;
dhtmlXLayoutCell.prototype.attachHeaderToolbar = function(id) {
  let cellLayout = null;
  let hdr = null;

  if (id) {
    cellLayout = document.getElementById(id);
    hdr = (cellLayout ? cellLayout.getElementsByClassName('dhx_cell_hdr')[0] : null);
  } else
    hdr = this.cell.childNodes[this.conf.idx.hdr];


  if (!hdr) return null;

  const divToolbar = document.createElement('div');
  hdr.appendChild(divToolbar);

  const toolbar = new dhtmlXToolbarObject({ parent: divToolbar });
  // unfortunately we cannot use css class because it will be overwritten later
  // divToolbar.className += divToolbar.className ? " class-headerToolbar" : "class-headerToolbar";
  // so we apply inline styles
  divToolbar.style.position = 'absolute';
  divToolbar.style.float = 'right';
  divToolbar.style.top = '0px';
  divToolbar.style.right = '65px';
  divToolbar.style.padding = '0px';
  divToolbar.style.marginLeft = '110px';
  divToolbar.style.background = 'initial';

  return toolbar;
};

// TODO - Add this handling in the FilterManager
dhtmlXLayoutCell.prototype.attachHeaderDynSelect = function(rightPos, oPanel, oTarget) {

  const oThis = this;
  const oDynSelect = {};
  oDynSelect.opt = {};

  const hdr = oThis.cell.childNodes[oThis.conf.idx.hdr];
  const div = document.createElement('div');
  $(div).addClass('dynSelectPanelHeader');
  if (hdr)
    $(hdr.children[0]).append(div);

  div.style.cursor = 'pointer';

  // Events
  oDynSelect.selectionChanged = function(e, oElement, oSelf) {
    oSelf.bSelected = true;
    const cHdl = e.params.data['filterhdl'];
    oSelf.oTarget.FilterManager.useFilter(cHdl);
    oSelf.bSelected = false;
  };

  oDynSelect.selectingResult = function() { };
  oDynSelect.getCheckboxSelection = function() { };
  oDynSelect.selectionRemoved = function() { };
  oDynSelect.openingResults = function() { };
  oDynSelect.openResults = function() {

    if (oDynSelect.select2.data().select2.dropdown.$search)
      akioma.swat.MasterLayout.disableLastFocusTrap({ setReturnFocus: oDynSelect.select2.data().select2.dropdown.$search[0] });
    else
      akioma.swat.MasterLayout.disableLastFocusTrap();
  };
  oDynSelect.closeResults = function() {
    akioma.swat.MasterLayout.enableLastFocusTrap();
  };

  oDynSelect.setFilter = function(id, desc) {
    const oSelf = this;
    const oOption = $(oSelf.dynSelectControl.oElement).find(`option[value="${id}"]`);
    if (oOption.length > 0)
      $(oOption).remove();

    const newOption = new Option(desc, id);
    $(newOption).data('custom', desc);
    $(newOption).data('bExtVal', true);
    $(oSelf.select2).append(newOption);
    $(oSelf.select2).val(id).trigger('change');
  };

  oDynSelect.displayMultiFields = function(oSelected) {
    const oSelf = this;
    let cRes = '';
    let bField = false;

    if (oSelf.opt.lookupKeyField) {
      const aFields = oSelf.opt.lookupKeyField.split(',');
      for (let i = 0; i < aFields.length; i++) {
        const cField = aFields[i];
        if (cField.indexOf('\'') == -1) {
          if (!isNull(oSelected[cField])) {
            cRes += oSelected[cField];
            bField = true;
          }
          if (oSelf.bPosRecord) {
            cRes = oSelected.text;
            bField = true;
          }
        } else
          cRes += cField.replace(/["']/g, '');

      }
    }

    if (bField == false)
      cRes = '';
    oSelf.cLookupToDisplay = cRes;
    return cRes;
  };

  // Hardcoded attributes for now
  oDynSelect.opt.entityName = 'Akioma.Swat.FilterBT';
  oDynSelect.opt.entityTable = 'eFilter';
  oDynSelect.opt.templateOptions = '|description|';
  oDynSelect.opt.template = 'GridFilterDynselectSearchTemplate';
  oDynSelect.opt.lookupKeyField = 'description';
  oDynSelect.opt.closeOnSelect = true;
  oDynSelect.view = 'dynSelectPanelHeader';
  oDynSelect.oTarget = oTarget;

  const select = document.createElement('select');
  $(select).addClass('dynSelect');
  $(div).append(select);

  akioma.createBusinessEntity(oDynSelect, oDynSelect.opt);
  oDynSelect.dynSelectControl = new akioma.DynSelect(oDynSelect, div, oDynSelect.opt);

  oDynSelect.businessEntity.query.addUniqueCondition('SDO', 'eq', (oTarget.dataSource ? oTarget.dataSource.opt.name : ''));
  oDynSelect.businessEntity.query.addUniqueCondition('Grid', 'eq', oTarget.opt.name);

  if (oTarget && oTarget.aPanelHdrDynselects)
    oTarget.aPanelHdrDynselects.push(oDynSelect);

  $(oDynSelect.select2).data('bMakeRequest', true);

  $(select.nextSibling).css('width', '200px');

  oTarget.FilterManager.setDynSelectFilter(oDynSelect);

  oTarget.FilterManager.oDynSelectPromise.then(() => {
    const oDefaultFilter = oTarget.FilterManager.getFilter(oTarget.FilterManager._cDefaultFilterHdl);
    oDynSelect.setFilter(oDefaultFilter.FilterHdl, oDefaultFilter.Description);
  });

};

(function($) {

  // ***************** panelset ******************
  $.extend({
    /**
     * SwatLayout Control
     * @class ak_panelset
     * @tutorial panelset-desc
     * @param {Object} options Repository attributes for SwatLayout.
     * @param {string} options.PrivateData
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {boolean} options.isModal defines if the container should be modal (blocking all other ui elements)
     * @param {string} options.LABEL WidgetAttributes Label
     * @param {string} options.HtmlClass CLASS property for an HTML tag
     * @param {string} options.typeRange The type range which makes up a combobox in a Toolbar
     * @param {string} options.TITLE Browser, Frame, Dialog and Window title
     * @param {string} options.EventPreInitialize
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     */
    ak_panelset: function(options) {
      const defaults = {},
        lHideParentHeader = false;

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.bIsLoaded = false;
      this.aPanelIds = [];

      if (!this.opt.layout)
        this.opt.layout = '1C';

      const oParent = this.parent.dhx;
      if (oParent) {
        // get header visible -> header will be hidden after attach layout
        let lHeader = false;
        if (oParent.isHeaderVisible && oParent.conf.hdr != undefined)
          lHeader = oParent.isHeaderVisible();

        let lNew = false;
        if (this.parent.dynObject && this.parent.dynObject.type === 'frame')
          lNew = true;


        if (!lNew)
          oParent._isCell = false;

        let oLayout;
        if (oParent.is && oParent.is('body')) {
          oLayout = new dhtmlXLayoutObject({
            pattern: this.opt.layout,
            skin: oDhx.skin,
            parent: oParent[0]
          });
        } else if (oParent) {
          if (oParent.akElm.view == 'popover' || oParent.akElm.parent.view == 'popover')
            oLayout = oParent.attachLayout(this.parent.opt.width || 400, this.parent.opt.height || 300, this.opt.layout, oDhx.skin);
          else
            oLayout = oParent.attachLayout(this.opt.layout, oDhx.skin);

        }
        if (!lNew)
          oParent._isCell = true;
        if (lNew && lHideParentHeader) {
          oParent.hideHeader();
          lHeader = false;
        }

        this.oLayout = oLayout;


        // sets panel offset
        if (this.opt.offset != undefined) {
          const iOffset = this.opt.offset;
          oLayout.setOffsets({
            top: iOffset,
            right: iOffset,
            bottom: iOffset + 10,
            left: iOffset
          });
        }


        this.aPanelHeaderBtns = [];

        // resize fix
        if (window.attachEvent)
          window.attachEvent('onresize', this.resizeLayout.bind(this));
        else
          window.addEventListener('resize', this.resizeLayout.bind(this), false);

        // check if we have to reactivate header
        if (lHeader)
          oParent.showHeader();

        oLayout.attachEvent('onExpand', id => {
          for (const i in this.childs) {
            if (this.childs[i].opt.layout == id) {
              const oPanel = this.dhx.cells(id);
              // check hidden header attribute/ apply because of dhtmlx expanding events that hide headers
              if (this.childs[i].opt.hideHeader)
                oPanel.hideHeader();
              else
                oPanel.showHeader();

              // check if disabled/hide arrow so that you can't collapse
              if (this.childs[i].opt.disabled) {
                oPanel.collapse();
                oPanel.hideArrow();
              }

              dhtmlx.delay(function() {
                this.childs[i].execOpt('expand');
              }, this, [id], 10);
            }
          }

          const expandedCell = this.dhx.cells(id);
          const headerButton = $(expandedCell.cell).find('.class-headerPanel-btn')[0];
          if (headerButton)
            $(headerButton).removeClass('adjustHeaderButton');

          // save window settings including panel sizes
          if (this.bIsLoaded)
            this.saveLayoutSettings(id);
        });

        oLayout.attachEvent('onCollapse', id => {
          const collapsedCell = this.dhx.cells(id);
          const headerButton = $(collapsedCell.cell).find('.class-headerPanel-btn')[0];
          if (headerButton)
            $(headerButton).addClass('adjustHeaderButton');

          // save window settings including panel sizes
          if (this.bIsLoaded)
            this.saveLayoutSettings(id);
        });

        oLayout.attachEvent('onPanelResizeFinish', ids => {
          if (ids) {
            for (const i in ids) {
              if (oLayout.cells(ids[i]).getWidth() < 100)
                oLayout.cells(ids[i]).setWidth(100);
            }
            // save window settings including panel sizes
            if (this.bIsLoaded)
              this.saveLayoutSettings(ids);
          }
        });

        $.extend(this, { dhx: oLayout });
      } else
        !_isIE && console.error(`panelset: Error creating Layout '${this.opt.name}': `);

    }
  });

  $.ak_panelset.prototype = {
    /**
     * Method for saving user profile layout settings for one or multiple given cell ids
     * @param {string|array}id An id or multiple cell ids
     * @instance
     * @memberof ak_panelset
     * @returns {void}
     */
    saveLayoutSettings: function(id) {
      // save window settings including panel sizes
      if (typeof (id) == 'string') {
        const oCell = this.dhx.cells(id);
        const oChild = oCell.akElm.childs[0];
        if (oChild && oChild.dynObject)
          oChild.dynObject.saveUserProfileSettings();
      } else {
        for (const e in id) {
          const idCell = id[e];
          const oCell = this.dhx.cells(idCell);
          for (const i in oCell.akElm.childs) {
            let oChild = oCell.akElm.childs[i];
            if (oChild.type === 'frame')
              oChild = oChild.getDescendant('panel').childs[0];

            // Check if it's having a custom save
            if (oChild && typeof oChild.customSaveLayoutSettings == 'function') {
              const container = oChild.dhx.container;
              let ids = [];
              if (container && container.dataObj)
                ids = Object.keys(container.dataObj.cdata);

              oChild.customSaveLayoutSettings(oChild, ids);
            } else if (oChild && oChild.dynObject)
              oChild.dynObject.saveUserProfileSettings();

          }
        }
      }

    },

    resizeLayout: function() {
      if (this.timeoutResize)
        window.clearTimeout(this.timeoutResize);
      const oLayout = this.oLayout;
      this.timeoutResize = window.setTimeout(() => {

        function doItemAction(cell) {
          if (cell.getWidth() == 0) {
            const iCellContWidth = $(cell.cell).width();
            if (iCellContWidth != 0)
              cell.setWidth(iCellContWidth);
          }
        }
        // calling iterator
        try {
          if (oLayout.forEachItem != undefined)
            oLayout.forEachItem(doItemAction);
        } catch (e) {
          akioma.log.error(e);
        }

      }, 400);
    },

    /**
     * Method which attaches and handles keyboard handling for nextPanel and previousPanel
     * @return {ak_panel} The first panel
     * @instance
     * @memberOf ak_panelset
     */
    allowShortcuts: function() {
      const oSelf = this;
      (function(oSelf) {
        const oMouseTrap = oSelf.getAncestor('panelset').oMouseTrap || oSelf.getAncestor('frame').oMouseTrap || oSelf.getAncestor('window').oMouseTrap;
        if (oMouseTrap) {
          oSelf.oMouseTrap = oMouseTrap;

          oMouseTrap.bind(akioma.shortcutManager.get('NextPanel'), e => {
            let bEvent;
            if (e.preventDefault) {
              e.preventDefault();
              bEvent = true;
            }

            const oPanel = oSelf.getActivePanel();

            // Grid Rows handling
            if (bEvent && $(e.target).parents('.hdrcell.filter').first().length > 0 && $(e.target).parents('.gridbox').first().find('.objbox').find('tr').length > 1)
              oPanel.setFocus(true, oPanel.oFocus.oActiveRow);
            else {
              // Bugfix: If we switch from a grid, we need to set the grid as inactive. Otherwise, focus might jump in the grid.
              if (oPanel) {
                const oPrevContent = oPanel.getDescendant('datagrid2');
                if (oPrevContent)
                  oPrevContent.dhx.setActive(false);
              }

              const oNextPanel = (oPanel) ? oPanel.getNextPanel() : oSelf.getFirstPanel();
              oNextPanel.setActivePanelState();
              oNextPanel.setFocus(false, (bEvent) ? oNextPanel.oFocus.oActiveField : '');
            }

            if (e.stopImmediatePropagation)
              e.stopImmediatePropagation();
          });

          oMouseTrap.bind(akioma.shortcutManager.get('PreviousPanel'), e => {
            let bEvent;
            if (e.preventDefault) {
              e.preventDefault();
              bEvent = true;
            }

            let oPrevContent;
            const oPanel = oSelf.getActivePanel();
            if (oPanel)
              oPrevContent = oPanel.getDescendant('datagrid2');

            // Grid Filter handling
            if (bEvent && ((e.target.tagName == 'TR') || $(e.target).hasClass('gridbox')) && oPrevContent.getIndex_FirstActiveFilter() != null)
              oPanel.setFocus(false, oPanel.oFocus.oActiveField);

            else {
              // Bugfix: If we switch from a grid, we need to set the grid as inactive. Otherwise, focus might jump in the grid.
              if (oPrevContent)
                oPrevContent.dhx.setActive(false);

              const oPrevPanel = (oPanel) ? oPanel.getPrevPanel() : oSelf.getLastPanel();
              oPrevPanel.setActivePanelState();
              oPrevPanel.setFocus(true, (bEvent) ? oPrevPanel.oFocus.oActiveRow || oPrevPanel.oFocus.oActiveField : 'lastActive');
            }

            if (e.stopImmediatePropagation)
              e.stopImmediatePropagation();
          });
        }

      })(oSelf);
    },

    endConstruct: function() {
      this.dhx.setSizes();
      this.bIsLoaded = true;

      if (this.dhx.base)
        this.oMouseTrap = new Mousetrap(this.dhx.base);

      this.allowShortcuts();

      try {
        const oSelf = this;
        oSelf.children('panel', function() {
          const oPanel = this;
          if (oPanel.opt.customStyle) {
            $(oSelf.dhx.cells(oPanel.opt.layout).cell).attr('akstyle', oPanel.opt.customStyle);
            $(oSelf.dhx.cells(oPanel.opt.layout).cell).parents('div.dhx_cell_nested_layout').attr('akstyle', oPanel.opt.customStyle);
          }
        });
      } catch (e) {
        akioma.log.error(e);
      }

    },

    /**
     * Method used for returning the first panel inside a panelset
     * @return {ak_panel} The first panel
     * @instance
     * @memberOf ak_panelset
     */
    getFirstPanel: function() {
      const oSelf = this;
      const aPanels = oSelf.aPanelIds;
      const oNextPanel = oSelf.dhx.cells(aPanels[0]);
      let oReturnPanel;

      if (oNextPanel) {
        oReturnPanel = (oNextPanel.akElm.view == 'frame') ? oNextPanel.akElm.getAncestor('panel') : oNextPanel.akElm;
        return (oReturnPanel.view == 'panel') ? oReturnPanel : null;
      } else
        return null;
    },

    /**
     * Method used for returning the last panel inside a panelset
     * @return {ak_panel} The last panel
     * @instance
     * @memberOf ak_panelset
     */
    getLastPanel: function() {
      const oSelf = this;
      const aPanels = oSelf.aPanelIds;
      const oNextPanel = oSelf.dhx.cells(aPanels[aPanels.length - 1]);
      if (oNextPanel)
        return (oNextPanel.akElm.view == 'frame') ? oNextPanel.akElm.parent : oNextPanel.akElm;
      else
        return null;
    },

    /**
     * Method used for returning the active panel inside a panelset
     * @return {ak_panel} The active panel
     * @instance
     * @memberOf ak_panelset
     */
    getActivePanel: function() {
      const oSelf = this;
      return oSelf.oActivePanel || null;
    },

    /**
     * Method used for setting a panel active in the panelset
     * @param  {string} cPanelId The panel id
     * @instance
     * @memberOf ak_panelset
     */
    setActivePanel: function(cPanelId) {
      const oSelf = this;
      const oPanel = oSelf.dhx.cells(cPanelId);
      if (oPanel)
        oPanel.akElm.setActivePanelState();
    },

    /**
     * Method used for setting a panel inactive in the panelset
     * @param  {string} cPanelId The panel id
     * @instance
     * @memberOf ak_panelset
     */
    setInactivePanel: function(cPanelId) {
      const oSelf = this;
      const oPanel = oSelf.dhx.cells(cPanelId);
      if (oPanel)
        oPanel.akElm.removeActivePanelState();
    },

    /**
     * Method used for returning a panel inside a panelset
     * @param  {integer} iIndex
     * @return {ak_panel}
     * @instance
     * @memberOf ak_panelset
     */
    getPanel: function(iIndex) {
      const i = iIndex - 1;
      const res = this.childs[i];

      return res;
    },

    destroy: function() {
      // check if dhtmlx element exists -> destroy all attached elements
      if (this.dhx) {
        try {
          if (this.oMouseTrap) {
            this.oMouseTrap.reset();
            delete this.oMouseTrap;
          }

          if (window.attachEvent)
            window.detachEvent('onresize', this.resizeLayout);
          else
            window.removeEventListener('resize', this.resizeLayout, false);


          for (const i in this.aPanelHeaderBtns) {
            const p = this.aPanelHeaderBtns[i];
            if (p.p)
              p.p.onclick = p.p.ontouchstart = p.p.oncontextmenu = null;

            if (p.p && p.p.parentNode)
              p.p.parentNode.removeChild(p.p);
            p.p = null;
            if (p && p.unload)
              p.unload();


          }

          for (const j in this.aContextMenus) {
            const cm = this.aContextMenus[j];
            if (cm.p)
              cm.p.onclick = cm.p.ontouchstart = cm.p.oncontextmenu = null;

            if (cm.p && cm.p.parentNode)
              cm.p.parentNode.removeChild(cm.p);
            cm.p = null;
            cm.unload();
          }

          // check if we are attached in a dhx element
          if (this.dhx.detachMenu) this.dhx.detachMenu();
          if (this.dhx.detachToolbar) this.dhx.detachToolbar();

          this.dhx = null;
        } catch (e) {
          !_isIE && console.error([ 'Error destroying', this.view, this.opt.name, e.message ]);
        }
      }
    }
  };

})(jQuery, jQuery);
