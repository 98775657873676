dhtmlXSideBar.prototype.setTooltip = function(id, text) {
  if (this.t[id]) {
    this.t[id].item.dataset.tooltip = text;
    this.t[id].item.className = `${this.t[id].item.className} tooltip-right`;
  }
};
dhtmlXSideBarCell.prototype.getIndex = function() {
  let i = 0;
  for (const key in this.sidebar.t) {
    if (key == this.sidebar.getActiveItem())
      return i;

    i++;
  }

};

dhtmlXSideBar.prototype.templates.icons = '#iconTemplate#';
dhtmlXSideBar.prototype.templates.icons_text_right = '#iconTemplate#';
dhtmlXSideBar.prototype.templates.icons_text_below = '#iconTemplate#';

dhtmlXTabBar.prototype.setTooltip = function(id, text) {
  if (this.t[id]) {
    this.t[id].tab.dataset.tooltip = text;
    this.t[id].tab.className = `${this.t[id].tab.className} tooltip-top`;
  }
};


(function($) {


  // ********************* tabbar ******************

  $.extend({
    /**
     * SwatTabbar Control
     * @class ak_tabbar
     * @tutorial tabbar-desc
     * @augments ak_global
     * @param {Object} options Repository object attributes
     * @param {Object} options Repository attributes for SwatTabbar.
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {string} options.viewTemplate template name, built in values are:&#10;icons,details,icons_text,text,tiles
     * @param {string} options.EventValueChanged fires immediately when a value changed (before leave)
     * @param {string} options.EventOnClose code executed when an object is closed
     * @param {string} options.TabVisualization This determines how the pages are visualized. Can be "tabbar", to display as tabs at the bottom or top (see also "FolderTabOrientation"), or can be "sidebar" to display as a sidebar, with the page-titlles on the left. As a synonym for "tabbar" you an also use "TABS", but this is deprecated and will be removed in a future version.
     * @param {number} options.MinHeight The pre-determined minimum height of a visual object
     * @param {integer} options.WIDTH-CHARS Sets the width of the sidebar. This is not available for tabbar.
     * @param {string} options.FolderTabOrientation If Visualization is tabbar, then this defines the orientation/location of the page-labels. can be "top" or "bottom".
     * @param {string} options.SUBTYPE
     * @param {string} options.akId Html custom attribute used for automatic testing. If akId is not set, the default is the 'options.name' attribute
     * @param {string} optrions.EventBeforeTabSelected Client side code executed before a record has been selected, if return
     * @fires ak_tabbar#EventBeforeTabSelected
     * @fires ak_tabbar#EventAfterTabSelected
     */
    ak_tabbar: function(options) {
      const oSelf = this,
        defaults = {
          mode: '',
          main: false,
          tabOrientation: 'top',
          nestedTab: false
        };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.aTabKeys = []; // tab pages by pageKey
      this.aTabGuid = []; // tab pages by pageGuid

      this.view = 'tabbar';

      this.registerVuexModule = true;
      this.registerDynObject = true;

      this.oLinks = [];

      // load saved settings for tabbar area
      if (this.parent) {
        const sTabModeKey = `ak_${this.parent.parent.parent.opt.name}_${this.view}_${this.opt.name}`;
        const oTabBarMode = UserProfile.loadLocalProfileDataSession(sTabModeKey);

        this.loadTabbarProfile(oTabBarMode);
      }

      // set akId
      oSelf.akId = ((this.opt.akId) ? this.opt.akId : this.opt.name);

      if (!this.opt.template) /* only relevant for sidebar view */
        this.opt.template = 'text'; // built-in templates: icons,details,icons_text,text,tiles

      if (!this.opt.viewMode)
        this.opt.viewMode = 'tabbar';

      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;


      if (this.opt.viewMode == 'sidebar') {
        this.templates = {
          icons: {
            normalIcons: '<img class="dhxsidebar_item_icon" src="#icon1#" border="0">',
            fontIcons: '<div class="dhxsidebar_item_icon #stackedIcon#"><i class="#icon1# fa-2x fa-fw #moduleClass1#" style="#iconStyle1#"></i>' +
              '<i class="#icon2# fa-stack-1x fa-inverse #moduleClass2#" style="#iconStyle2#"></i></div>'
          },
          icons_text_right: {
            normalIcons: '<div class="dhxsidebar_item_icon"><img class="dhxsidebar_item_icon" src="#icon1#" border="0"><div class="dhxsidebar_item_text">#title#</div></div>',
            fontIcons: '<div class="dhxsidebar_item_icon #stackedIcon#"><i class="#icon1# fa-2x fa-fw #moduleClass1#" style="#iconStyle1#"></i>' +
              '<i class="#icon2# fa-stack-1x fa-inverse #moduleClass2#" style="#iconStyle2#"></i></div><div class="dhxsidebar_item_text"><span>#title#</span></div>'
          },
          icons_text_below: {
            normalIcons: '<div class="dhxsidebar_item_icon"><img class="dhxsidebar_item_icon" src="#icon1#" border="0"></div><div class="dhxsidebar_item_text">#title#</div>',
            fontIcons: '<div class="dhxsidebar_item_icon #stackedIcon#"><i class="#icon1# fa-2x fa-fw #moduleClass1#" style="#iconStyle1#"></i>' +
              '<i class="#icon2# fa-stack-1x fa-inverse #moduleClass2#" style="#iconStyle2#"></i></div><div class="dhxsidebar_item_text"><span>#title#</span></div>'
          }
        };
      } else {
        this.templates = {
          icons: {
            normalIcons: '<div><img src="#icon1#" class="fa-fw"/></div>',
            fontIcons: '<div class="fa-stack #stackedIcon#"><i class="#icon1# #size# fa-fw #moduleClass1#" style="#iconStyle1#"></i>' +
              '<i class="#icon2# fa-stack-1x fa-inverse #moduleClass2#" style="#iconStyle2#"></i></div>'
          },
          icons_text_right: {
            normalIcons: '<div><img src="#icon1#" class="fa-fw"/><span>#title#</span></div>',
            fontIcons: '<div class="fa-stack #stackedIcon#"><i class="#icon1# #size# fa-fw #moduleClass1#" style="#iconStyle1#"></i>' +
            '<i class="#icon2# fa-stack-1x fa-inverse #moduleClass2#" style="#iconStyle2#"></i></div><span>#title#</span>'
          }
        };
      }

      // get parentabb
      try {
        if (this.opt.viewMode == 'TABS')
          this.aTabs = [];
        else
          this.aItems = [];


        this.render();

        this._bindTabbarEvents();

        // apply class on parent panel
        if (this.parent && this.parent.dhx)
          this.parent.dhx.cell.classList.add('tabbar_cell_container');


        this._bindKeyboardShortcuts();

      } catch (e) {
        console.error(e);
      }
    }
  });

  // methods for tabbar
  Object.assign($.ak_tabbar.prototype, $.ak_global.prototype, {
    render() {
      let oTabbar;
      const cTabOrientation = this.opt.tabOrientation.toLowerCase();
      if (!this.parent) {
        if (this.opt.viewMode == 'TABS')
          oTabbar = new dhtmlXTabBar({ parent: 'mainLayout' });
        else
          oTabbar = new dhtmlXSideBar({ parent: 'mainLayout' });

      } else {
        const oPanel = this.parent.dhx;
        if (this.opt.viewMode == 'sidebar') {
          const iWidth = (this.opt.width) ? this.opt.width : 50;
          const bAutoHide = (this.opt.autoHide != undefined) ? this.opt.autoHide : false;
          const oSidebarOptions = {
            width: iWidth,
            template: this.opt.template,
            skin: 'material'
          };

          if (bAutoHide) {
            oSidebarOptions.parent = 'sidebarObj';
            oSidebarOptions.header = true;
            oSidebarOptions.autohide = true;
          }
          oTabbar = oPanel.attachSidebar(oSidebarOptions);
          oPanel.hideHeader();

        } else {
          oTabbar = oPanel.attachTabbar(cTabOrientation);
          oTabbar.setAlign('top');
          oTabbar.setAlign('left');
          oTabbar.setSkin('material');
        }
      }

      this.dhx = oTabbar;
      this.dhx.akElm = this;
    },

    _bindTabbarEvents() {
      const oTabbar = this.dhx;
      const oSelf = this;

      if (this.opt.viewMode == 'sidebar') {

        // check first if dragged from input/prevent selection bug
        // sidebar selection bug fix : clicking in form element and dragging over sidebar item/ prevent selection
        try {
          oSelf.correctSelect = true;
          $(oTabbar.side).on('mousedown', '.dhxsidebar_item', () => {
            oSelf.correctSelect = true;
          });

          oTabbar.attachEvent('onBeforeSelect', (id, lastId) => {

            const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
            if (supportsTouch)
              oSelf.correctSelect = true;
            // check if clicked on item
            if (!oSelf.correctSelect)
              return false;
            else {
              oSelf.correctSelect = false;
              // check if settings items
              if (id == 'tabsettings') {
                oSelf._loadTabSettingsPopup();
                return false;
              }
              oSelf.onTabSelect(id, lastId);
              return true;
            }

          });

          oTabbar.attachEvent('onSelect', () => {
            if (oSelf.dynObject)
              oSelf.dynObject.container.controller._visibilityRules = [];
            return true;
          });
        } catch (e) {
          akioma.log.error('Error checking for correct sidebar selection', e);
        }

      } else {
        oTabbar.attachEvent('onSelect', (cId, cLastId) => oSelf.onTabSelect(cId, cLastId));
        oTabbar.attachEvent('onTabClose', cId => oSelf.onTabClose(cId));
        // reset visibility rules for tabbar
        oTabbar.attachEvent('onTabClick', () => {
          if (oSelf.dynObject)
            oSelf.dynObject.container.controller._visibilityRules = [];
          return true;
        });
      }
    },

    /**
     * Method for loading the settings of each child object
     * @instance
     * @memberof ak_tabbar
     * @return  {object}  The object parent control for which the settings will be loaded
     */
    loadSettings: function(oParent) {
      for (const x in oParent.childs) {
        const child = oParent.childs[x];
        if (child.dynObject && child.dynObject.loadUserProfileSettings)
          child.dynObject.loadUserProfileSettings();
        if (child.childs.length > 0)
          this.loadSettings(child);

      }
    },

    /**
     * Method used for getting the top tabbar. If there isn't any, take first tabbar from the topScreen.
     * @instance
     * @memberof ak_tabbar
     * @returns {ak_tabbar}
     */
    getTopMostTabbar() {
      const parentTabbar = this.parent.getAncestorUntil('tabbar', 'window');
      if (parentTabbar)
        return parentTabbar;
      else
        return this;

    },

    /**
     * Method for setting up keyboard shortcut events on tabbar control
     * @memberof ak_tabbar
     * @instance
     * @private
     */
    _bindKeyboardShortcuts() {
      const oSelf = this;

      const container = oSelf.getAncestor('frame') || oSelf.getAncestor('window');

      if (!container)
        return;

      const oMouseTrap = oSelf.getAncestor('frame').oMouseTrap || oSelf.getAncestor('window').oMouseTrap;
      oSelf.oMouseTrap = oMouseTrap;

      function goToNext(e) {
        let controller = oSelf;
        if (oSelf.isTabbarMode())
          controller = oSelf.getTopMostTabbar();

        controller.switchPage(true);
        e.preventDefault();
        e.stopImmediatePropagation();
      }

      function goToPrev(e) {
        let controller = oSelf;
        if (oSelf.isTabbarMode())
          controller = oSelf.getTopMostTabbar();

        controller.switchPage(false);
        e.preventDefault();
        e.stopImmediatePropagation();
      }

      // Tabbar shortcuts
      if (oSelf.isTabbarMode()) {
        oMouseTrap.bind(akioma.shortcutManager.get('SwitchTabbarRight'), e => {
          goToNext(e);
        });
        oMouseTrap.bind(akioma.shortcutManager.get('SwitchTabbarLeft'), e => {
          goToPrev(e);
        });
      } else { // Sidebar shortcuts
        oMouseTrap.bind(akioma.shortcutManager.get('SwitchSidebarDown'), e => {
          oSelf.correctSelect = true;
          goToNext(e);
        });
        oMouseTrap.bind(akioma.shortcutManager.get('SwitchSidebarUp'), e => {
          oSelf.correctSelect = true;
          goToPrev(e);
        });
      }
    },

    /**
     * Method to check it current tabbar is in tabbar mode
     * @memberof ak_tabbar
     * @instance
     * @return {boolean}
     */
    isTabbarMode() {
      const viewMode = (this.opt.viewMode || '').toLowerCase();
      return viewMode === 'tabbar' || viewMode === 'tabs';
    },

    /**
     * Method to check it current tabbar is in sidebar mode
     * @memberof ak_tabbar
     * @instance
     * @return {boolean}
     */
    isSidebarMode() {
      const viewMode = (this.opt.viewMode || '').toLowerCase();
      return viewMode === 'sidebar';
    },

    finishConstruct: function() {
      const oTab = this;

      // set akstyle in tabbar
      $(oTab.dhx.base).attr('akstyle', this.opt.customStyle);

      if (this.isTabbarMode())
        oTab.dhx.setArrowsMode('auto');

      // set akId in Sidebar/Tabbar
      $(oTab.dhx.base).attr('akId', oTab.akId);

      if (this.opt.title) {
        this.parent.dhx.showHeader();
        this.parent.dhx.setText(this.opt.title);
      }

      // check visibility rules for tabs
      VisibilityRules.checkTabbarVisibilityRules(this);

      if (this.dynObject.screen) {
        const oContainer = this.dynObject.screen.controller;
        if (oContainer.securityRestrictions && oContainer.securityRestrictions.tabs) {

          for (const tab in oContainer.securityRestrictions.tabs) {
            const tabValue = oContainer.securityRestrictions.tabs[tab];
            if (tabValue.restricted) {
              if (this.aTabGuid[tab])
                (this.opt.viewMode == 'sidebar') ? this.dhx.items(this.aTabGuid[tab]).hide() : this.dhx.tabs(this.aTabGuid[tab]).hide();
            }
            if (isNull(tabValue.restricted))
              akioma.canDo(tabValue.rule);
          }

        }
      }

      // initial call of AfterTabSelected event here
      // after all datasources and repository loads
      setTimeout(() => {
        this._callAfterTabSelectedScreenLoads(oTab);
        if (this.getActivePage() && this.getActivePage().init == false) {
          this.onTabSelect(this.currentPageID());
          this.getActivePage().init = true;
        }
      }, 20);

    },

    /**
     * Method for calling visibility rules for tabbar after ribbon load from menustructure
     * @param  {object} ribbonDynObj The Ribbon DynObject
     * @private
     * @memberOf ak_tabbar
     * @return {void}
     */
    _callRibbonVisibilityRules: function(ribbonDynObj) {
      try {
        const oTab = this;
        const iNewPage = oTab.currentPageNum();
        const cPageKey = oTab.aTabKeys[oTab.currentPageID()];
        let bVisibleTabCell;
        const oDomTabbar = $(this.dhx.base).parents('.dhx_cell_tabbar');

        if (oDomTabbar.length)
          bVisibleTabCell = (oDomTabbar.css('visibility') !== 'hidden');
        else
          bVisibleTabCell = true;


        if (ribbonDynObj && bVisibleTabCell)
          VisibilityRules.checkRibbonVisibilityRules(ribbonDynObj.controller, iNewPage, cPageKey);
      } catch (oErr) {
        akioma.log.warn(oErr);
      }
    },

    /**
     * Method for going to the next/previous page in a tabbar/sidebar
     * @param  {boolean} bMode Specifies the direction: true for next, false for previous
     * @instance
     * @memberOf ak_tabbar
     * @return {void}
     */
    switchPage: function(bMode) {
      const oSelf = this;
      (bMode) ? oSelf.fetchNext() : oSelf.fetchPrev();
      const oTab = oSelf.getActivePage().dhx;
      $(oTab.cell).attr('tabindex', '0');
      $(oTab.cell).focus();

    },

    loadTabbarProfile: function(oTabBarMode) {
      if (oTabBarMode != null) {
        if (oTabBarMode.tabbar.enable)
          this.opt.viewMode = 'tabbar';
        else {
          this.opt.viewMode = 'sidebar';
          const oSidebarSettings = oTabBarMode.sidebar;

          if (oSidebarSettings.icons && oSidebarSettings.text)
            this.opt.template = 'icons_text';
          else if (oSidebarSettings.icons)
            this.opt.template = 'icons';
          else if (oSidebarSettings.text)
            this.opt.template = 'text';


          this.opt.autoHide = oSidebarSettings.autohide;
        }
      }
    },

    /**
     * @param {String} cLabel New Tab label.
     * @param {String} cRepoName Name for Repository Object to load
     * @param {Bool} bInitSelect Initially select Tab
     * @param {String} cIcon Icon for Tab
     * @param {oOptions} Extra options
     * @instance
     * @memberOf ak_tabbar
     */
    addTabRepository: function(cLabel, cRepoName, bInitSelect, cIcon, oItem, oOptions) {
      try {
        const oSelf = this;

        oItem = null;
        if (typeof (cIcon) == 'object') {
          oItem = cIcon;
          cIcon = null;
        }

        const iAddPos = 0;

        if (cRepoName == undefined)
          cRepoName = 'contactorgf';

        let cPage, bAutoAdd;
        // get the page option if it's setup in menustructure
        if (oOptions.actionOption) {
          let oActionOption;
          try {
            const actionOption = oOptions.actionOption.replace(/'/g, '"');
            if (IsJsonString(actionOption))
              oActionOption = JSON.parse(actionOption);
          } catch (oErr) {
            akioma.log.error(`${oErr}. No valid actionOption ${oOptions.actionOption} for tab ${cLabel}`);
          }
          cPage = (oActionOption) ? (((oActionOption.pages) ? true : false) ? oActionOption.pages : '*') : '*';
          bAutoAdd = (oActionOption) ? ((oActionOption.autoAdd && oActionOption.autoAdd == true) ? true : false) : false;
        } else
          cPage = '*';

        // create new tab
        const cID = `${cLabel}_${dhtmlx.uid()}`;
        const bIsSidebar = (oSelf.parent.opt.viewMode != 'tabbar' && oSelf.parent.opt.viewMode != 'TABS');

        if (!oOptions)
          oOptions = {};
        if (oOptions.isClosable == undefined)
          oOptions.isClosable = true; // for dynamicly created tabs default is closable
        if (oOptions.name == undefined)
          oOptions.name = cLabel;

        const oAttrs = {
          title: cLabel,
          id: cID,
          PageKey: cID,
          page: cPage,
          addPos: iAddPos,
          selected: bInitSelect || false,
          object: cRepoName,
          item: oItem,
          dynGuid: true,
          isClosable: oOptions.isClosable,
          name: oOptions.name,
          autoAdd: bAutoAdd
        };

        if (cIcon != null && cIcon)

          oAttrs.icon = cIcon;


        if (bIsSidebar) {
          if (cIcon != undefined)
            oAttrs.image = cIcon;
          else
            oAttrs.icon = cIcon;
        }

        const oNew = app.controller.parseProc({
          view: 'tab',
          att: oAttrs,
          sub: []
        }, this);

        if (oItem != null) {
          const linkKey = oItem.RelatedRecord;
          if (!this.oLinks[linkKey])
            this.oLinks[linkKey] = [];

          this.oLinks[linkKey].push(cID); // links selfhdl with tab ID
        }

        if (this.oVuexState.attributes.hasChangesStyle !== '')
          this._dispatch('setHasChangesStyle', this.oVuexState.attributes.hasChangesStyle);

        oNew.dhx.akElm = oNew;

        if (bInitSelect) {
          oNew.init = false;
          oNew.parent.onTabSelect(cID);
        }
      } catch (e) {
        console.error('Could not add new tab with repository definition', e);
      }
    },

    /**
     * Returns the currently active tab index
     * @memberOf ak_tabbar
     * @memberOf ak_tabbar
     * @instance
     * @return {integer} The index of the tab
     */
    currentPageNum: function() {
      let iIdx;
      const oTabbar = this.dhx;
      if (oTabbar.getActiveItem) /* (this.opt.viewMode == "sidebar")*/ {
        const cCurrPage = oTabbar.getActiveItem();
        iIdx = oTabbar.getAllItems().indexOf(cCurrPage) + 1;
      } else if (oTabbar.getActiveTab) {
        const cCurrPage = oTabbar.getActiveTab();
        iIdx = oTabbar.getAllTabs().indexOf(cCurrPage) + 1;
      }
      return iIdx;
    },

    /**
     * Method returns currently selected PageKey
     * @return {string} The selected page PageKey attribute.
     * @instance
     * @memberOf ak_tabbar
     *
     */
    currentPageKey: function() {
      const cCurrPageID = this.currentPageID();
      return this.aTabKeys[cCurrPageID];
    },

    /**
     * Method returns currently selected page id
     * @return {string} The selected page id attribute.
     * @instance
     * @memberOf ak_tabbar
     */
    currentPageID: function() {
      let cCurrPage;
      const oTabbar = this.dhx;
      if (oTabbar.getActiveItem)
        cCurrPage = oTabbar.getActiveItem();
      else
        cCurrPage = oTabbar.getActiveTab();

      return cCurrPage;
    },

    /**
     * Returns page dom item by given pagekey
     * @param  {string} cPageKey The page key
     * @return {DOMNode}
     */
    getPageItem: function(cPageKey) {
      let cPageId;

      for (const i in this.childs) {
        if (this.childs[i].opt.PageKey) {
          if (this.childs[i].opt.PageKey.toLowerCase() == cPageKey.toLowerCase() || this.childs[i].opt.id.toLowerCase() == cPageKey.toLowerCase())
            cPageId = this.childs[i].opt.id;
        } else if (this.childs[i].opt.id.toLowerCase() == cPageKey.toLowerCase())
          cPageId = this.childs[i].opt.id;
      }
      if (cPageId)
        return this.dhx.t[cPageId].item;

      return null;
    },

    /**
     * Returns enabled status of the specified page
     * @param  {string} oPageId The page id (key or index)
     * @return {boolean}
     */
    isPageEnabled: function(oPageId) {
      const oTabbar = this.dhx;
      if (this.opt.viewMode == 'TABS') {
        const oTab = (typeof (oPageId) == 'number') ? oTabbar.tabs(oTabbar.getAllTabs()[oPageId - 1]) : oTabbar.tabs(this.aTabs[oPageId]);
        return oTab.isEnabled();
      } else {
        const oElm = this.getPageItem(oPageId);
        return $(oElm).attr('data-disable');
      }
    },

    /**
     * Returns disabled status of the specified page
     * @param  {string} oPageId The page id (key or index)
     * @return {boolean}
     */
    isPageDisabled: function() {
      return !this.isEnabled();
    },

    /**
     * Returns visibility status of the specified page
     * @param  {string} oPageId The page id (key or index)
     * @return {boolean}
     */
    isPageVisible: function(oPageId) {
      const oTabbar = this.dhx;
      if (this.opt.viewMode == 'TABS') {
        const oTab = (typeof (oPageId) == 'number') ? oTabbar.tabs(oTabbar.getAllTabs()[oPageId - 1]) : oTabbar.tabs(this.aTabs[oPageId]);
        return oTab.isVisible();
      } else {
        const oElm = this.getPageItem(oPageId);
        return $(oElm).attr('data-disable');
      }
    },

    /**
     * Returns hidden status of the specified page
     * @param  {string} oPageId The page id (key or index)
     * @return {boolean}
     */
    isPageHidden: function() {
      return !this.isVisible();
    },

    /**
     * This Method can enable an existing Page
     * @param  {integer|string} value Position of Page, index starting from 1 OR PageKey
     * @example
     * // get the Tabbar object of the current container and enable page 3 and page with PageKey = 'test'
     * // this could e.g. be placed inside an AfterDisplay Event of a Form
     * // currently not available for sidebar pages
     * if (someField == "somevalue")
     *   self.container.getObject("SimpleSwatTabbar").controller.enablePage(3);
     *   self.container.getObject("SimpleSwatTabbar").controller.enablePage('test');
     * @instance
     * @memberOf ak_tabbar
     */
    enablePage: function(value) {
      const tabbar = this.dhx;
      if (this.opt.viewMode == 'TABS') {
        if (typeof (value) == 'number')
          tabbar.tabs(tabbar.getAllTabs()[value - 1]).enable();
        else
          tabbar.tabs(this.aTabs[value]).enable();
      } else {
        let sidebarItem = '';
        if (typeof (value) == 'number')
          sidebarItem = tabbar.items(tabbar.getAllItems()[value - 1]);
        else
          sidebarItem = tabbar.items(this.aItems[value]);
        if (sidebarItem) {
          const item = this.dhx.t[sidebarItem._idd].item;
          $(sidebarItem.cell).attr('data-disable', false);
          $(item).attr('data-disable', false);
        }
      }
    },

    /**
     * This Method can disable an existing Page
     * @param  {integer|string} value Position of Page, index starting from 1 OR PageKey
     * @example
     * // get the Tabbar object of the current container and disable page 5 and page with PageKey = 'test'
     * // this could e.g. be placed inside an AfterDisplay Event of a Form
     * // currently not available for sidebar pages
     * if (someField == "somevalue")
     *   self.container.getObject("SimpleSwatTabbar").controller.disablePage(5);
     *   self.container.getObject("SimpleSwatTabbar").controller.disablePage('test');
     * @instance
     * @memberOf ak_tabbar
     */
    disablePage: function(value) {
      const tabbar = this.dhx;
      if (this.opt.viewMode == 'TABS') {
        if (typeof (value) == 'number')
          tabbar.tabs(tabbar.getAllTabs()[value - 1]).disable();
        else
          tabbar.tabs(this.aTabs[value]).disable();
      } else {
        let sidebarItem;
        if (typeof (value) == 'number')
          sidebarItem = tabbar.items(tabbar.getAllItems()[value - 1]);
        else
          sidebarItem = tabbar.items(this.aItems[value]);
        if (sidebarItem) {
          const item = this.dhx.t[sidebarItem._idd].item;
          $(sidebarItem.cell).attr('data-disable', true);
          $(item).attr('data-disable', true);
        }
      }
    },

    /**
     * Method to hide all Pages
     * @instance
     * @memberOf ak_tabbar
     */
    hideAllPages: function() {
      let aItems;
      const oTabbar = this.dhx;
      if (this.opt.viewMode == 'sidebar') {
        aItems = oTabbar.getAllItems();
        for (const i in aItems)
          oTabbar.items(aItems[i]).hide();
      } else {
        aItems = oTabbar.getAllTabs();
        for (const i in aItems)
          oTabbar.tabs(aItems[i]).hide();
      }
    },

    /**
     * Metod to display all Pages
     * @instance
     * @memberOf ak_tabbar
     */
    showAllPages: function() {
      let aItems;
      const oTabbar = this.dhx;
      if (this.opt.viewMode == 'sidebar') {
        aItems = oTabbar.getAllItems();
        for (const i in aItems)
          oTabbar.items(aItems[i]).show();
      } else {
        aItems = oTabbar.getAllTabs();
        for (const i in aItems)
          oTabbar.tabs(aItems[i]).show();
      }
    },

    /**
     * Method used for reseting the selected page.
     * @instance
     * @memberOf ak_tabbar
     */
    unselectPage: function() {
      const oTabbar = this.dhx;
      oTabbar.conf.selected = '';
    },

    /**
     * This Method can set an existing page as active
     * @param  {integer|string} value Position of Page, index starting from 1 OR PageKey
     * @param  {bool} bFocus Specifies if the page should be focused or not. If not specified, default is true (page will be focused)
     * @example
     * // get the Tabbar object of the current container and set as active page 2 and then set as active page with PageKey = 'test'
     * // this could e.g. be placed inside an AfterDisplay Event of a Form
     * if (someField == "somevalue")
     *   self.container.getObject("SimpleSwatTabbar").controller.setActivePage(2);
     *   self.container.getObject("SimpleSwatTabbar").controller.setActivePage('test');
     * @instance
     * @memberOf ak_tabbar
     */
    setActivePage: function(value, bFocus) {
      const oTabbar = this.dhx;
      const oPage = this.getPage(value);
      const oItem = oPage.controller || oPage;
      const oTab = (oItem.view == 'tab') ? oItem : oItem.parent;
      oTab.bFocusPage = true;

      if (bFocus == false)
        oTab.bFocusPage = false;
      try {
        if (this.opt.viewMode == 'sidebar') {
          this.correctSelect = true;
          if (typeof (value) == 'number')
            oTabbar.items(oTabbar.getAllItems()[value - 1]).setActive();
          else
            oTabbar.items(this.aItems[value]).setActive();
          this.correctSelect = false;
        } else if (typeof (value) == 'number')
          oTabbar.tabs(oTabbar.getAllTabs()[value - 1]).setActive();
        else
          oTabbar.tabs(this.aTabs[value]).setActive();
      } catch (e) {
        !_isIE && console.error([ 'Error on setting page active', this.view, this.opt.name, e.message ]);
      }
    },

    /**
     * This Method gets the active page
     * @example
     * // get the Tabbar object of the current container and get the active page
     * // this could e.g. be placed inside an AfterDisplay Event of a Form
     * self.container.getObject("SimpleSwatTabbar").controller.getActivePage();
     * @instance
     * @memberOf ak_tabbar
     * @return {object} The active page
     */
    getActivePage: function() {
      const oTabbar = this.dhx;
      if (this.opt.viewMode == 'sidebar') {
        const id = oTabbar.getActiveItem();
        const oPage = oTabbar.items(id);
        return (oPage) ? oPage.akElm : null;
      } else {
        const id = oTabbar.getActiveTab();
        const oPage = oTabbar.tabs(id);
        return (oPage) ? oPage.akElm : null;
      }
    },

    /**
     * This Method can hide an existing Page
     * @param  {integer|string} value Position of Page, index starting from 1 OR PageKey
     * @example
     * // get the Tabbar object of the current container and hide page 2 and page with PageKey = 'test'
     * // this could e.g. be placed inside an AfterDisplay Event of a Form
     * if (someField == "somevalue")
     *   self.container.getObject("SimpleSwatTabbar").controller.hidePage(2);
     *   self.container.getObject("SimpleSwatTabbar").controller.hidePage('test');
     * @instance
     * @memberOf ak_tabbar
     */
    hidePage: function(value) {
      const oTabbar = this.dhx;
      if (this.opt.viewMode == 'sidebar') {
        this.correctSelect = true;

        if (typeof (value) == 'number')
          oTabbar.items(oTabbar.getAllItems()[value - 1]).hide();
        else
          oTabbar.items(this.aItems[value]).hide();
      } else if (typeof (value) == 'number')
        oTabbar.tabs(oTabbar.getAllTabs()[value - 1]).hide();
      else
        oTabbar.tabs(this.aTabs[value]).hide();

    },

    /**
     * This Method can show an existing Page
     * @param  {integer|string} value Position of Page, index starting from 1 OR PageKey
     * @example
     * // get the Tabbar object of the current container and hide page 2 and page with PageKey = 'test'
     * // this could e.g. be placed inside an AfterDisplay Event of a Form
     * if (someField == "somevalue")
     *   self.container.getObject("SimpleSwatTabbar").controller.showPage(2);
     *   self.container.getObject("SimpleSwatTabbar").controller.showPage('test');
     * @instance
     * @memberOf ak_tabbar
     */
    showPage: function(value) {
      const oTabbar = this.dhx;
      if (this.opt.viewMode == 'sidebar') {
        if (typeof (value) == 'number')
          oTabbar.items(oTabbar.getAllItems()[value - 1]).show();
        else
          oTabbar.items(this.aItems[value]).show();
      } else if (typeof (value) == 'number')
        oTabbar.tabs(oTabbar.getAllTabs()[value - 1]).show();
      else
        oTabbar.tabs(this.aTabs[value]).show();

    },

    /**
     * Deactivates all pages
     * @param  {Number} iNum The index
     */
    deactivatePages: function() {
      const oTabbar = this.dhx;

      const itms = oTabbar.getAllItems();
      for (const x in itms)
        oTabbar.t[itms[x]].conf.transActv = false;


    },

    /**
     * Checks if page content has been loaded
     * @param  {integer|string} value Position of Page, index starting from 1 OR PageKey
     * @example
     * if (someField == "somevalue")
     *   var bPageContentLoaded = self.container.getObject("SimpleSwatTabbar").controller.pageContentLoaded(3);
     *   var bPageContentLoaded = self.container.getObject("SimpleSwatTabbar").controller.pageContentLoaded('testPageKey');
     * @instance
     * @memberOf ak_tabbar
     */
    pageContentLoaded: function(cPageName) {
      const oTabbar = this.dhx;
      let oElm = null;
      let bHasContent = false;
      if (this.opt.viewMode == 'sidebar') {
        if (typeof (cPageName) == 'number')
          oElm = oTabbar.items(oTabbar.getAllItems()[cPageName - 1]).akElm;
        else
          oElm = oTabbar.items(this.aItems[cPageName]).akElm;
      } else if (typeof (cPageName) == 'number')
        oElm = oTabbar.tabs(oTabbar.getAllTabs()[cPageName - 1]).akElm;
      else
        oElm = oTabbar.tabs(this.aTabs[cPageName]).akElm;

      if (oElm.childs.length > 0)
        bHasContent = true;

      return bHasContent;
    },

    /**
     * Method for finding a page by index or pagekey
     * @param  {integer|string} value Postion of Page, index starting from 1 OR PageKey
     * @example
     * // get the Tabbar object of the current container and show page 5 and page with PageKey = 'test'
     * // this could e.g. be placed inside an AfterDisplay Event of a Form
     * if (someField == "somevalue")
     *   self.container.getObject("SimpleSwatTabbar").controller.getPage(3);
     *   self.container.getObject("SimpleSwatTabbar").controller.getPage('testPageKey');
     * @instance
     * @memberOf ak_tabbar
     */
    getPage: function(value) {
      const oTabbar = this.dhx;
      let oElm = null;
      if (this.opt.viewMode == 'sidebar') {
        if (typeof (value) == 'number')
          oElm = oTabbar.items(oTabbar.getAllItems()[value - 1]).akElm;
        else
          oElm = oTabbar.items(this.aItems[value]).akElm;
        oElm = oElm.view === 'frame' ? oElm.parent : oElm;
      } else if (typeof (value) == 'number')
        oElm = oTabbar.tabs(oTabbar.getAllTabs()[value - 1]).akElm;
      else
        oElm = oTabbar.tabs(this.aTabs[value]).akElm;
      if (oElm.dynObject)
        oElm = oElm.dynObject;

      return oElm;
    },

    /**
     * Deprecated method for finding a page by index or pagekey. Please use getPage method
     * @deprecated
     * @param  {string|integer} value index or PageKey
     * @returns {object}
     * @instance
     * @memberOf ak_tabbar
     */
    fetchPage: function(value) {
      return this.getPage(value);
    },

    /**
     * This Method can show an existing Page
     * @param  {integer|string} value Postion of Page, index starting from 1 OR PageKey
     * @example
     * // get the Tabbar object of the current container and show page 5 and page with PageKey = 'test'
     * // this could e.g. be placed inside an AfterDisplay Event of a Form
     * if (someField == "somevalue")
     *   self.container.getObject("SimpleSwatTabbar").controller.viewPage(5);
     *   self.container.getObject("SimpleSwatTabbar").controller.viewPage('test');
     * @instance
     * @memberOf ak_tabbar
     */
    viewPage: function(value) {
      const oTabbar = this.dhx;
      if (this.opt.viewMode == 'sidebar') {
        if (typeof (value) == 'number')
          oTabbar.items(oTabbar.getAllItems()[value - 1]).show();
        else
          oTabbar.items(this.aItems[value]).show();
      } else if (typeof (value) == 'number')
        oTabbar.tabs(oTabbar.getAllTabs()[value - 1]).show();
      else
        oTabbar.tabs(this.aTabs[value]).show();
    },

    /**
     * Shows an active page from a given index while also setting the page as active/selected.
     * @param {Number} iNum The index of the page to show and mark as active.
     * @instance
     * @memberOf ak_tabbar
     */
    viewActivePage: function(iNum) {
      const oTabbar = this;
      setTimeout(() => {
        oTabbar.deactivatePage(iNum);
        oTabbar.viewPage(iNum);
        oTabbar.deactivatePages(iNum);
      }, 10);
    },

    /**
     * Method useful for switching to the next Page
     * @instance
     * @memberOf ak_tabbar
     */
    fetchNext: function() {
      const oTabbar = this.dhx;
      const currentActiveTab = oTabbar.akElm.activeTabId;
      let currentTabPosition = 0;

      while (currentTabPosition < oTabbar.akElm.childs.length) {
        if (oTabbar.akElm.childs[currentTabPosition].dhx._idd == currentActiveTab)
          break;
        currentTabPosition = currentTabPosition + 1;
      }

      let nextEnabledTab = currentTabPosition + 1;

      while (nextEnabledTab < oTabbar.akElm.childs.length) {
        if (oTabbar.akElm.childs[nextEnabledTab].opt.hidden == true)
          nextEnabledTab = nextEnabledTab + 1;
        else
        if (oTabbar.akElm.opt.viewMode == 'sidebar') {
          if (oTabbar.akElm.getPageItem(oTabbar.akElm.childs[nextEnabledTab].dhx._idd).getAttribute('data-disable') == 'true')
            nextEnabledTab = nextEnabledTab + 1;
          else
            break;
        } else
        if (oTabbar.akElm.childs[nextEnabledTab].dhx.isEnabled() == false)
          nextEnabledTab = nextEnabledTab + 1;
        else
          break;
      }

      if (nextEnabledTab < oTabbar.akElm.childs.length) {

        if (oTabbar.akElm.opt.viewMode == 'sidebar')
          oTabbar._setItemActive(oTabbar.akElm.childs[nextEnabledTab].dhx._idd, true);
        else {
          oTabbar._doOnClick(oTabbar.akElm.childs[nextEnabledTab].dhx._idd);
          oTabbar._setTabActive(oTabbar.akElm.childs[nextEnabledTab].dhx._idd, true);
        }
      }
    },

    /**
     * Method useful for switching to the previous Page
     * @instance
     * @memberOf ak_tabbar
     */
    fetchPrev: function() {
      const oTabbar = this.dhx;
      const currentActiveTab = oTabbar.akElm.activeTabId;
      let currentTabPosition = 0;

      while (currentTabPosition < oTabbar.akElm.childs.length) {
        if (oTabbar.akElm.childs[currentTabPosition].dhx._idd == currentActiveTab)
          break;
        currentTabPosition = currentTabPosition + 1;
      }

      let prevEnabledTab = currentTabPosition - 1;
      while (prevEnabledTab >= 0) {
        if (oTabbar.akElm.childs[prevEnabledTab].opt.hidden == true)
          prevEnabledTab = prevEnabledTab - 1;
        else
        if (oTabbar.akElm.opt.viewMode == 'sidebar') {
          if (oTabbar.akElm.getPageItem(oTabbar.akElm.childs[prevEnabledTab].dhx._idd).getAttribute('data-disable') == 'true')
            prevEnabledTab = prevEnabledTab - 1;
          else
            break;
        } else
        if (oTabbar.akElm.childs[prevEnabledTab].dhx.isEnabled() == false)
          prevEnabledTab = prevEnabledTab - 1;
        else
          break;
      }
      if (prevEnabledTab >= 0) {
        if (oTabbar.akElm.opt.viewMode == 'sidebar')
          oTabbar._setItemActive(oTabbar.akElm.childs[prevEnabledTab].dhx._idd, true);
        else {
          oTabbar._doOnClick(oTabbar.akElm.childs[prevEnabledTab].dhx._idd);
          oTabbar._setTabActive(oTabbar.akElm.childs[prevEnabledTab].dhx._idd, true);
        }
      }
    },

    convPageNameNum: function(para) {
      if (this.opt.viewMode == 'sidebar') {
        if ($.type(para) === 'string')
          return this.dhx.getAllItems().indexOf(para) + 1;
        else if ($.type(para) === 'number')
          return this.dhx.items(this.dhx.getAllItems()[para - 1]);
        else
          return null;
      } else if ($.type(para) === 'string')
        return this.dhx.getAllTabs().indexOf(para) + 1;
      else if ($.type(para) === 'number')
        return this.dhx.tabs(this.dhx.getAllTabs()[para - 1]);
      else
        return null;
    },

    _loadTabMode: function() {
      return {};
    },

    _loadTabSettingsPopup: function() {
      const dhxWins = new dhtmlXWindows(),
        id = 'tabsettings',
        sTabModeKey = `ak_${this.parent.parent.parent.opt.name}_${this.view}_${this.opt.name}`,
        oTabBarMode = UserProfile.loadLocalProfileDataSession(sTabModeKey);

      dhxWins.createWindow(id, 100, 100, 250, 300);
      dhxWins.window(id).setText('Settings for tab area');
      dhxWins.window(id).centerOnScreen();

      const myTree = dhxWins.window(id).attachTree(),
        oMode = this._loadTabMode();
      myTree.setImagesPath(`${oDhx.imagePath}dhxtree_terrace/`);
      myTree.setSkin(oDhx.skin);
      myTree.enableCheckBoxes(1);
      myTree.enableTreeImages(false);

      // populate tree with items
      myTree.parse([
        [ 'root', '0', 'Options' ],
        [ 'tabbar', 'root', 'Tabbar' ],
        [ 'sidebar', 'root', 'Sidebar' ],
        [ 'autohide', 'sidebar', 'Autohide' ],
        [ 'showIcons', 'sidebar', 'Show Icons' ],
        [ 'showText', 'sidebar', 'Show Text' ]
      ], 'jsarray');
      if (oTabBarMode != null) {
        if (oTabBarMode.tabbar.enable) {
          myTree.setCheck('tabbar', true);
          myTree.disableCheckbox('showIcons', true);
          myTree.disableCheckbox('showText', true);
          myTree.disableCheckbox('autohide', true);
        } else {
          myTree.setCheck('sidebar', true);
          myTree.openItem('sidebar');
          if (oTabBarMode.sidebar.icons)
            myTree.setCheck('showIcons', true);
          if (oTabBarMode.sidebar.text)
            myTree.setCheck('showText', true);
          if (oTabBarMode.sidebar.autohide)
            myTree.setCheck('autohide', true);
        }
        myTree.openItem('root');
      } else {
        myTree.disableCheckbox('showIcons', true);
        myTree.disableCheckbox('showText', true);
        myTree.disableCheckbox('autohide', true);
      }

      myTree.attachEvent('onCheck', (id, state) => {
        // check if sidebar, enable options
        if (id == 'sidebar' && state) {
          myTree.openItem(id);
          myTree.disableCheckbox('showIcons', false);
          myTree.disableCheckbox('showText', false);
          myTree.disableCheckbox('autohide', false);
        } else if (id == 'tabbar') {
          myTree.setCheck('showIcons', false);
          myTree.setCheck('showText', false);
          myTree.setCheck('autohide', false);
          myTree.disableCheckbox('showIcons', true);
          myTree.disableCheckbox('showText', true);
          myTree.disableCheckbox('autohide', true);
        }
      });

      myTree.disableCheckbox('root', true);
      myTree.showItemCheckbox('root', false);
      myTree.showItemSign('root', false);
      myTree.enableRadioButtons('root', true);
      myTree.enableRadioButtons('tabbar', false);
      myTree.enableRadioButtons('sidebar', false);
      myTree.enableRadioButtons('showIcons', false);
      myTree.enableRadioButtons('showText', false);
      myTree.enableRadioButtons('autohide', false);

      const myToolbar = dhxWins.window(id).attachToolbar();
      myToolbar.addButton(1, 0, 'Save');
      myToolbar.addButton(2, 1, 'Cancel');
      const oSelf = this,
        dhxWinCell = dhxWins.window(id);
      myToolbar.attachEvent('onClick', id => {
        // on save
        if (id == 1)
          oSelf.saveSelected();

        dhxWinCell.close();
      });
      this.saveSelected = function() {
        const checked = myTree.getAllCheckedBranches();

        oMode.tabbar = { enable: (checked.indexOf('tabbar') >= 0) };

        oMode.sidebar = {
          enable: (checked.indexOf('sidebar') >= 0),
          icons: (checked.indexOf('showIcons') >= 0),
          text: (checked.indexOf('showText') >= 0),
          autohide: (checked.indexOf('autohide') >= 0)
        };

        // save tab/sidebar mode settings
        const sTabModeKey = `ak_${this.parent.parent.parent.opt.name}_${this.view}_${this.opt.name}`;
        UserProfile.saveLocalProfileDataSession(sTabModeKey, oMode);
      };
    },

    onTabClose: function(cId) {
      const oSelf = this;
      oSelf.cLastClosedTabID = cId;
      if (oSelf.opt.onCloseTab)
        app.controller.callAkiomaCode(this, this.opt.onCloseTab);
      return true;
    },

    /**
     * Calls the method defined in the EventAfterTabSelected attribute. Will execute after all data and screens are loaded
     */
    _callEventAfterTabSelected: function() {
      const oSelf = this;
      /**
      * Client side code executed after a tab has been selected
      * @event ak_tabbar#EventAfterTabSelected
      * @type {object}
      */
      if (oSelf.opt.onTabSelect)
        oSelf.opt.EventAfterTabSelected = oSelf.opt.onTabSelect;

      const oTabbar = this.dhx;

      let id = null;
      if (this.opt.viewMode == 'sidebar')
        id = oTabbar.getActiveItem();
      else
        id = oTabbar.getActiveTab();

      if (id == null)
        return false;


      const oTabActive = oSelf.getActivePage();
      if (oTabActive)
        oSelf.loadSettings(oTabActive);


      if (oSelf.opt.EventAfterTabSelected)
        app.controller.callAkiomaCode(oSelf, oSelf.opt.EventAfterTabSelected);

      if (this.afterTabSelectTimeout)
        clearTimeout(this.afterTabSelectTimeout);

      this.afterTabSelectTimeout = setTimeout(() => {
        let oPanelSet;
        const setFocusFirstPanel = () => {
          const oPanel = oPanelSet.getFirstPanel();
          if (oPanel) {
            oPanel.setActivePanelState();
            oPanel.setFocus(true);
          }
        };

        const oTab = (oTabActive.view == 'tab') ? oTabActive : oTabActive.parent;
        if (oTab.bFocus) {
          const aPanels = oTabActive.childs.filter(obj => obj.view == 'panelset');
          if (aPanels.length > 0) {
            oPanelSet = aPanels[aPanels.length - 1];

            // Check for last focus
            const oActivePanel = oPanelSet.oActivePanel;
            if (oActivePanel) {
              const oFocus = oActivePanel.oFocus;
              // Check for last focus as a field
              if (oTab.bAfterTabSelected && !oFocus.bRowMode && oFocus.oActiveField) {
                oActivePanel.setActivePanelState();
                oActivePanel.setFocus(false, oActivePanel.oFocus.oActiveField);
              } else if (oTab.bAfterTabSelected && oFocus.bRowMode && oFocus.oActiveRow) {
                // Check for last focus as a row
                oActivePanel.setActivePanelState();
                oActivePanel.setFocus(true, oActivePanel.oFocus.oActiveRow);
              } else
                setFocusFirstPanel();
            } else // No previous focus, so focus on first panel, first active field
              setFocusFirstPanel();

            oTab.bAfterTabSelected = true;
          }
        }

      }, 300);
    },

    // after load of all data and repository screens
    /**
     * Get promises for all included data and repository screens
     * @param {ak_tabbar|ak_tab} oTab Tab or Tabbar
     * @memberOf ak_tabbar
     * @instance
     * @private
     * @returns {void}
     */
    _getAllDataAndRepoPromises: function(oTab) {
      const aPromises = [];
      const aInnerElms = oTab.getAllChildrenByType([ 'frame', 'tabbar', 'datagrid2' ], true); // get all nested children of type frame, tabbar, grid

      if (aInnerElms.length > 0) {
        // for each inner add to promise array and wait until content is loaded
        for (const x in aInnerElms) {
          const oNestedElm = aInnerElms[x];
          if (oNestedElm.view == 'frame' && oNestedElm.opt.file && oNestedElm.promiseFrame && oNestedElm.promiseFrame.state() !== 'resolved')
            aPromises.push(oNestedElm.promiseFrame);
          else if (oNestedElm.view == 'tabbar' && oNestedElm.promiseTab && oNestedElm.promiseTab.state() !== 'resolved')
            aPromises.push(oNestedElm.promiseTab);
          else if (oNestedElm.view == 'datagrid2' && oNestedElm.getFiltersPromise && oNestedElm.getFiltersPromise.state() !== 'resolved')
            aPromises.push(oNestedElm.getFiltersPromise);

        }
      }
      return aPromises;
    },

    /**
     * Waits until all nested data and repository screns are loaded
     * @param {ak_tabbar|ak_tab} oTab Tab or Tabbar
     * @memberof ak_tabbar
     * @instance
     * @private
     * @returns {void}
     */
    _waitForAllNestedLoaded: function(oTab) {
      const oSelf = this;
      const aPromises = oSelf._getAllDataAndRepoPromises(oTab);
      const deferred = $.Deferred();

      // now all nested screens are loaded
      $.when(...aPromises).then(() => {
        // look for next level nested loaded possible screens and wait for those to load also before triggering the event
        // if found just wait again for all the promises to finish up
        const aNestPromises = oSelf._getAllDataAndRepoPromises(oTab);
        let oPromise;
        if (aNestPromises.length > 0)
          oPromise = oSelf._waitForAllNestedLoaded(oTab);

        // if there are no more nested objects of type tabbar or frame
        //  then wait for the datasource to load, if not yet loaded, and trigger the eventAfterTabSelected

        if (oPromise) {
          oPromise.done(() => {
            deferred.resolve();
          });
        } else {
          akioma.executeAsync(() => {
            deferred.resolve();
          });
        }

      });

      return deferred.promise();
    },
    /**
     * Waits until all the datasources and screens are loaded and then triggers the EventAfterTabSelected
     * @param {ak_tab|ak_tabbar} oTab Tab or tabbar
     * @memberof ak_tabbar
     * @instance
     * @private
     * @returns {void}
     */
    _callAfterTabSelectedScreenLoads: function(oTab) {
      const oSelf = this;

      const aPromises = this._getAllDataAndRepoPromises(oTab);
      aPromises.push(oSelf.promiseTab);
      // now all nested screens are loaded
      const oAllRepoLoaded = this._waitForAllNestedLoaded(oTab);
      let oRibbon = null;
      let ribbonDynObj = null;

      if (this.dynObject.container)
        oRibbon = this.dynObject.container.controller.getDescendant('ribbon');
      if (oRibbon)
        ribbonDynObj = oRibbon.dynObject;

      oAllRepoLoaded.done(() => {
        // wait for businessEntity to start query
        setTimeout(() => {
          const oParWindow = oSelf.getAncestor([ 'popover', 'window' ]);
          const iParentWinDataToLoad = oParWindow.iDataSourceToLoad;

          //  parentwin dataload flag is setup, if gt 0 means it's loading BEs
          if (iParentWinDataToLoad > 0) {
            akioma.eventEmitter.once(`${oParWindow.opt.id}:dataLoaded`, () => {
              // else set tab as loaded
              for (const x in oSelf.childs) {
                const oCurrTab = oSelf.childs[x];
                oCurrTab.bIsLoaded = oCurrTab.init || false;
                if (oCurrTab.childs.length > 0)
                  oCurrTab.bIsLoaded = true;
              }
              oSelf._callEventAfterTabSelected();

              if (ribbonDynObj) {
                oSelf._callRibbonVisibilityRules(ribbonDynObj);
                ribbonDynObj.controller.show();
              }

              akioma.eventEmitter.emit(`${oParWindow.opt.id}:repoAndDataLoaded`);

            });
          } else if (!oTab.bIsLoaded) {
            // else set tab as loaded
            for (const x in oSelf.childs) {
              const oCurrTab = oSelf.childs[x];
              oCurrTab.bIsLoaded = oCurrTab.init || false;
              if (oCurrTab.childs.length > 0)
                oCurrTab.bIsLoaded = true;
            }
            oSelf._callEventAfterTabSelected();

            if (ribbonDynObj) {
              const aInnerTabbars = oSelf.dynObject.container.controller.getAllChildrenByType('tabbar');
              for (const x in aInnerTabbars) {
                const oTabbar = aInnerTabbars[x];
                const iNewPage = oTabbar.currentPageNum();
                const cCurrPageID = oTabbar.currentPageID();
                const cPageKey = oTabbar.aTabKeys[cCurrPageID];

                let bVisibleTabCell;
                const oDomTabbar = $(oTabbar.dhx.base).parents('.dhx_cell_tabbar');

                if (oDomTabbar.length)
                  bVisibleTabCell = (oDomTabbar.css('visibility') !== 'hidden');
                else
                  bVisibleTabCell = true;


                if (!oSelf.isNestedActivePage(oTabbar))
                  bVisibleTabCell = false;

                if (bVisibleTabCell) {
                  VisibilityRules.checkRibbonVisibilityRules(ribbonDynObj.controller, iNewPage, cPageKey);
                  ribbonDynObj.controller.show();
                }
              }
            }
            akioma.eventEmitter.emit(`${oParWindow.opt.id}:repoAndDataLoaded`);
          } else
            akioma.eventEmitter.emit(`${oParWindow.opt.id}:repoAndDataLoaded`);
        }, 25);
      });
    },
    /**
     * Check if tabbar is in active page
     * @param {ak_tabbar} oInnerTabbar
     * @returns {boolean}
     */
    isNestedActivePage(oInnerTabbar) {
      const parentTab = oInnerTabbar.dynObject.getFirstParentByType('tab');
      if (parentTab) {
        const inactiveParentTab = parentTab.parent.activeTabId !== parentTab.opt.id;
        if (inactiveParentTab)
          return false;

        if (parentTab.parent.getAncestor('tab'))
          return this.isNestedActivePage(parentTab.parent.getAncestor('tab').parent);
      }
      return true;
    },

    onTabSelect: function(cId) {
      // get object
      const oTab = this.dhx.cells(cId).akElm,
        oSelf = this;

      this.activeTabId = cId;
      // check if settings tab
      if (this.opt.viewMode == 'tabbar' && cId == 'tabsettings') {
        this._loadTabSettingsPopup();
        return false;
      }

      if (oTab) {
        const oItem = (oTab.view == 'tab') ? oTab : oTab.parent;
        if (oItem) {
          oItem.bFocus = (oItem.bFocusPage != undefined) ? oItem.bFocusPage : true;
          oItem.bFocusPage = undefined;
        }
      }

      /**
        * Client side code executed before a tab has been selected
        * eg. <code>$ akioma.samples.beforeTabSelect(self);</code>
        * @example
        * // on select of a tab if the currently selected page index is 3 then stop the select event by returning false
        * akioma.samples.beforeTabSelect = function(self){
        *   if(self.currentPageNum() == 3){
        *     return false;
        *   }
        * };
        * @event ak_tabbar#EventBeforeTabSelected
        *
        * @type {object}
        */
      if (oSelf.opt.EventBeforeTabSelected) {

        const bRes = app.controller.callAkiomaCode(oSelf, oSelf.opt.EventBeforeTabSelected);

        if (bRes == false)
          return false;
      }

      // check if it's already initialized
      let promiseLaunch;
      if (oTab && oTab.init == false) {
        let cObj = this.opt.object;
        if (oTab.opt.object)
          cObj = oTab.opt.object;

        if (cObj) {
          // attributes for newly added tab element
          const oOpts = {
            target: oTab,
            proc: 'include.r',
            para: `RunFile=${cObj}&Page=${oTab.opt.page}&guid=${this.opt.guid}`,
            data: true,
            containerinsguid: oSelf.opt._ContainerInstanceGuid,
            self: this,
            async: true
          };

          if (oTab.opt.dynGuid)
            oOpts.dynGuid = oTab.opt.dynGuid;

          if (this.forceView != undefined)
            oOpts.forceView = 'frame';


          // load new object inside tab using launchContainer
          promiseLaunch = app.controller.launchContainer(oOpts);
          oSelf.promiseTab = promiseLaunch;
        } else {
          oSelf.promiseTab = $.Deferred();
          oSelf.promiseTab.resolve(this);
        }


        if (oTab.opt.item && oTab.opt.item.RelatedRecord) {
          const cPosElm = oTab.opt.item.RelatedRecord;
          promiseLaunch.done(oData => {
            // set businessEntity position if
            const BE = oData.getDescendant('businessEntity');

            //  query with a filter with selfhdl
            BE.query = new DataFilter({
              mainOperator: 'and',
              businessEntity: BE
            });

            BE.query.addCondition('SelfHdl', 'eq', cPosElm);

            oData.openQuery().always(() => {
              oSelf._callEventAfterTabSelected();
            });

          });
        }

        oTab.init = true;
        if (promiseLaunch) {
          promiseLaunch.always(() => {
            oSelf._callAfterTabSelectedScreenLoads(oTab);
          });
        }
      } else if (oTab)
        oSelf._callAfterTabSelectedScreenLoads(oTab.getAncestor('tab'));

      // load initial tab settings
      if (oTab && oTab.layoutLoaded == undefined) {
        try {
          const loadTabSettings = function() {
            oSelf.loadSettings(oTab);
          };

          // load saved tab layout
          if (promiseLaunch)
            promiseLaunch.done(loadTabSettings);
          else {
            // wait until tab is selected and panels visible
            setTimeout(() => {
              oSelf.loadSettings(oTab);
            }, 160);
          }

        } catch (e) {
          console.warn('Could not load settings for tab ', e);
        }

      }
      return true;
    },

    destroy: function() {
      // check if dhtmlx element exists -> destroy all attached elements
      if (this.dhx) {
        try {
          // check if we are attached in a dhx element
          $(this.dhx.base).off();

          this.oMouseTrap.unbind([ 'ctrl+down', 'ctrl+up', 'ctrl+left', 'ctrl+right' ]);

          if (this.opt.viewMode == 'sidebar')
            $(this.dhx.side).off();

          if (typeof (this.dhx.detachAllEvents) === 'function')
            this.dhx.detachAllEvents();

        } catch (e) {
          !_isIE && console.error([ 'Error destroying', this.view, this.opt.name, e.message ]);
        }
      }
    }
  });

  // after a tab is set to active load it's user profile settings
  const onAfterTabActive = function(obj) {
    try {
      let oTab;
      if (!isNull(obj.self.akElm)) {
        for (const i in obj.self.akElm.childs) {
          if (obj.self.akElm.childs[i].opt.id == obj.id)
            oTab = obj.self.akElm.childs[i];
        }

        if (oTab)
          oTab.callLoadedFormsAfterDisplay();

        let oRibbon = null;

        // check for visible tabbars inside this tab and call visibility rules for that one as well
        if (obj.self.akElm.dynObject && obj.self.akElm.dynObject.container) {
          const container = obj.self.akElm.dynObject.container;
          const aInnerTabbars = container.controller.getAllChildrenByType('tabbar');
          if (aInnerTabbars.length > 0) {
            const oMasterCont = obj.self.akElm.dynObject.container.controller;
            if (oMasterCont && oMasterCont.getDescendant('ribbon'))
              oRibbon = oMasterCont.getDescendant('ribbon').dynObject;

            if (oRibbon) {
              for (const t in aInnerTabbars) {
                try {
                  const oInnerTabbar = aInnerTabbars[t];
                  const iNewPage = oInnerTabbar.currentPageNum();
                  const cCurrPageID = oInnerTabbar.currentPageID();
                  const cPageKey = oInnerTabbar.aTabKeys[cCurrPageID];
                  let bVisibleTabCell;
                  const oDomTabbar = $(oInnerTabbar.dhx.base).parents('.dhx_cell_tabbar');

                  if (oDomTabbar.length)
                    bVisibleTabCell = (oDomTabbar.css('visibility') !== 'hidden');
                  else
                    bVisibleTabCell = true;

                  if (!obj.self.akElm.isNestedActivePage(oInnerTabbar))
                    bVisibleTabCell = false;

                  if (oRibbon && bVisibleTabCell) {
                    if (!oRibbon.controller.bHiddenRibbon)
                      oRibbon.controller.hide();
                    if (oTab) {
                      const oParWindow = oTab.getAncestor('window');
                      const iParentWinDataToLoad = oParWindow.iDataSourceToLoad;

                      if (oTab.bIsLoaded) {
                        if (iParentWinDataToLoad === 0)
                          VisibilityRules.checkRibbonVisibilityRules(oRibbon.controller, iNewPage, cPageKey);
                        else if (iParentWinDataToLoad > 0) {
                          // call visibility rules after dataSource loaded
                          akioma.eventEmitter.once(`${oParWindow.opt.id}:dataLoaded`, () => {
                            VisibilityRules.checkRibbonVisibilityRules(oRibbon.controller, iNewPage, cPageKey);
                          });
                        }
                      }
                    } else if (isNull(oTab))
                      VisibilityRules.checkRibbonVisibilityRules(oRibbon.controller, iNewPage, cPageKey);
                  }
                } catch (oErr) {
                  akioma.log.warn(oErr);
                }
              }

            }
          }
        }

        if (oTab) {
          const oParWindow = oTab.getAncestor('window');
          const iParentWinDataToLoad = oParWindow.iDataSourceToLoad;
          if (oTab.bIsLoaded && iParentWinDataToLoad == 0) {
            obj.self.akElm._callEventAfterTabSelected();

            if (!isNull(oRibbon))
              oRibbon.controller.show();
          }
        }

        setTimeout(() => {
          if (oTab)
            obj.self.akElm.loadSettings(oTab);
        }, 100);
      }

    } catch (e) {
      console.warn(e);
    }

  };

  akioma.eventEmitter.on([ 'TAB', 'AFTER', 'ACTIVE' ], onAfterTabActive);
  akioma.eventEmitter.on([ 'SIDEBARITEM', 'AFTER', 'ACTIVE' ], onAfterTabActive);

})(jQuery, jQuery);
