/**
 * Http Client Service for Mockup REST API
 * @class MockupHttpClient
 * @namespace akioma
 */
class MockupHttpClient {
  constructor(options = {}) {
    this._client = $.ajax;
    this._fetchUrl = options.fetchUrl || '';
  }

  /**
   * Method for fetching data on the Mock API
   * @returns {Deferred}
   */
  fetch() {
    const url = this._fetchUrl;
    akioma.HttpClient.ignoreErrors(url);
    const requestPayload = {
      url,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      processData: false
    };

    const fillPromise = this._client(requestPayload);
    return fillPromise;
  }
}

akioma.MockupHttpClient = MockupHttpClient;
