// opens urmel screen from keyboard shortcut
akioma.bindUrmelScreenEvent = function() {
  akioma.shortcutManager.bindGlobal(akioma.shortcutManager.get('UrmelScreenEvent'), () => {
    if (securityIsRestricted('CanUseDeveloperTools')) return;

    const cRepoObj = 'wUrmel';

    app.controller.launchContainer({
      proc: cRepoObj,
      data: true,
      async: true
    });
  });
};

// toggles autoTran from keyboard shortcut
akioma.bindAutoTranEvent = function() {
  akioma.shortcutManager.bindGlobal(akioma.shortcutManager.get('AutoTranEvent'), () => {
    akioma.enableAutoTranslate();
  });
};
// open repository object keyboard shortcut
akioma.bindOpenRepository = function() {
  // get button and toolbar
  akioma.shortcutManager.bindGlobal(akioma.shortcutManager.get('OpenRepository'), () => {
    if (securityIsRestricted('CanUseDeveloperTools')) return;
    app.message({
      type: 'input',
      title: 'Open repository object',
      text: 'Name:',
      buttonText: 'Open',
      callback: function(result) {
        if (result) {
          const cValue = result;
          if (cValue.substr(0, 1) == '!') {
            // from new repo
            app.controller.loadContainer({ proc: cValue.substr(1), newRepoLoad: true });
          } else {
            app.controller.launchContainer({
              proc: `${cValue}.r`,
              data: true
            });
          }
        } else
          return;
      }
    });
  });
};

// on shift-alt-del call clear applikation cache method
akioma.bindDeleteLocalStorage = function() {
  akioma.shortcutManager.bindGlobal(akioma.shortcutManager.get('DeleteLocalStorage'), () => {
    app.message({
      type: 'confirm',
      title: 'Clear cache',
      text: 'Applikations-Cache löschen?',
      buttonText: 'Yes',
      callback: function(result) {
        if (result)
          akioma.emptyStorage();

      }
    });
  });
};
// help screen binding
/**
 * Function used for setting up the keyboard event listener for shortcut 'F1' (opens help screen)
 */
akioma.bindHelpKeyboardEvent = function() {
  // for opening help screen
  akioma.shortcutManager.bindGlobal(akioma.shortcutManager.get('HelpKeyboardEvent'), () => {
    // focus input on f1 key
    try {
      let oTopMostWindow = null;
      if (akioma.toggleFlag)
        oTopMostWindow = akioma.oWindowsParentCell.dhx.getTopmostWindow();
      else
        oTopMostWindow = akioma.oWindowParentCell.dhx.getTopmostWindow();


      if (oTopMostWindow) {
        let cSelectedPage = '';
        const cObjName = oTopMostWindow.akElm.opt.name + oTopMostWindow.akElm.opt.linkid;
        const oSource = app.messenger.getObjects(oTopMostWindow.akElm.dynObject, `PRIMARYSDO:TRG:${cObjName}`);
        const oMainTab = oTopMostWindow.akElm.getDescendant('tabbar');
        let cActiveMainTab;

        if (oMainTab.opt.viewMode.toLowerCase() !== 'tabbar' && oMainTab.opt.viewMode.toLowerCase() !== 'tabs')
          cActiveMainTab = String(oMainTab.dhx.getActiveItem());
        else
          cActiveMainTab = oMainTab.dhx.getActiveTab();

        const oActiveTab = _.find(oMainTab.childs, tab => {
          if (tab.opt.id == cActiveMainTab) return true;
        });

        if (oActiveTab == undefined) {
          uiMessage.showError({ text: 'No active page found for opening the help screen.', expire: 7000 });
          return;
        }
        const iActiveMainTab = parseInt(oActiveTab.opt.PageSequence);
        const oData = {
          name: oTopMostWindow.akElm.opt.name,
          type: oTopMostWindow.akElm.view,
          selfHdl: oSource[0].getValue('selfhdl'),
          pageNum: iActiveMainTab
        };

        let oTree;
        try {
          oTree = oTopMostWindow.akElm.dynObject.getObject('itTree');
        } catch (err) {
          akioma.log.error(`error getting Tree-Node: ${err.message}`);
        }
        try {
          const oTabbar = oTopMostWindow.akElm.oPosFrame.getDescendant('tabbar');

          if (oTabbar) {
            oData.child = {};
            oData.child.name = oTabbar.opt.object;
            oData.child.selfHdl = oTree.getValue('selfhdl');
            oData.child.type = 'posframe';
            cSelectedPage = oTabbar.dhx.getActiveTab();
            oData.child.pageNum = parseInt(akioma.entry(akioma.numEntries(cSelectedPage, '_'), cSelectedPage, '_'));
            oData.child.child = {};
          }
        } catch (err) {
          akioma.log.error(`error getting posframe: ${err.message}`);
        }

        const oReturn = app.controller.callServerMethod('Akioma/Swat/UiInteraction/getContextHelp.p',
          [
            { type: 'iCHAR', value: JSON.stringify(oData) },
            { type: 'iCHAR', value: '' },
            { type: 'oCHAR', name: 'helpString' }
          ]);

        const dhxWins = new dhtmlXWindows();

        const w1 = dhxWins.createWindow('w1', 20, 30, 620, 700);
        w1.setText(`HELP FOR ${oTopMostWindow.akElm.opt.name}`);
        w1.stick();
        try {
          if (oReturn.helpString.search(/^http[s]?:\/\//) == 0)
            w1.attachURL(oReturn.helpString);
          else
            w1.attachHTMLString(oReturn.helpString);
        } catch (e) {
          akioma.log.error(e);
        }
      }
    } catch (e) {
      uiMessage.showError({ text: 'There was an error opening the help screen.', expire: 5000 });
    }

    return false;
  });
};
// displays version info of swat-webui and swat-backend from keyboard shortcut
akioma.bindVersionKeyboardEvent = function() {
  akioma.shortcutManager.bindGlobal(akioma.shortcutManager.get('VersionKeyboardEvent'), e => {
    const aVersionNo = [];

    aVersionNo['swat-webui'] = {
      version: ((akioma.version) ? akioma.version.FullSemVer : 'UNVERSIONED'),
      stack: ((akioma.version) ? akioma.version : { FullSemVer: 'UNVERSIONED' })
    };

    const promiseVersionSwatBackend = akioma.invokeServerTask(
      {
        name: 'Akioma.Swat.Versioning.VersionInfoBT',
        methodName: 'GetVersionInfo',
        paramObj: { plcParameter: { 'Product': 'swat-backend' } }
      }).done(oResult => {
      aVersionNo['swat-backend'] = {
        version: oResult.plcParameter.Version,
        stack: oResult.plcParameter
      };
    });

    $.when(
      promiseVersionSwatBackend
    ).then(() => {
      const objStacks = { webui: aVersionNo['swat-webui'].stack, backend: aVersionNo['swat-backend'].stack };

      uiMessage.showInfo({
        text: `SWAT-WEBUI: ${aVersionNo['swat-webui'].version
        }</br> SWAT-BACKEND: ${aVersionNo['swat-backend'].version}`, moretext: JSON.stringify(objStacks, null, 4)
      });
    });


    e.preventDefault();
    return false;
  }, 'keydown');
};

// Focus for omnisearch field
akioma.bindOmnisearchScreen = function() {
  akioma.shortcutManager.bindGlobal(akioma.shortcutManager.get('OmnisearchScreen'), e => {
    if (e.preventDefault)
      e.preventDefault();

    app.controller.launchContainer({
      proc: 'omniSearchW',
      async: true
    });
  });
};

// Switches between Desktop view and Taskbar view
akioma.bindDesktopModeEvent = function() {
  akioma.shortcutManager.bindGlobal(akioma.shortcutManager.get('DesktopModeEvent'), e => {
    if (e.preventDefault)
      e.preventDefault();

    if (akioma.headerSection) {
      const oToolbar = akioma.headerSection.cells('a').getAttachedToolbar();
      let bState = oToolbar.getItemState('home');
      if (akioma.oWindowsParentCell.childs.length == 0 && bState)
        bState = false;
      akioma.toggleAkMultiWindow(!bState);
    }

  });
};

// Closes active Window
akioma.bindCloseWindowKeyboardEvent = function() {
  akioma.shortcutManager.bindGlobal(akioma.shortcutManager.get('CloseWindowKeyboardEvent'), () => {
    const oCurrentWd = akioma.getActiveWindow();
    if (oCurrentWd)
      oCurrentWd.close();
  });
};

// Shortcuts for next/previous window
akioma.bindNextPreviousWindow = function() {
  akioma.shortcutManager.bindGlobal(akioma.shortcutManager.get('NextWindow'), () => {
    const oCurrentWd = akioma.getActiveWindow();
    let oNextWd;
    if (oCurrentWd)
      oNextWd = oCurrentWd.getNextWindow();
    if (oNextWd)
      oNextWd.setActiveWindow();
  });
  akioma.shortcutManager.bindGlobal(akioma.shortcutManager.get('PreviousWindow'), () => {
    const oCurrentWd = akioma.getActiveWindow();
    let oPrevWd;
    if (oCurrentWd)
      oPrevWd = oCurrentWd.getPrevWindow();
    if (oPrevWd)
      oPrevWd.setActiveWindow();
  });
};


akioma.bindClearAllProgressState = function() {
  akioma.shortcutManager.bindGlobal(akioma.shortcutManager.get('ClearAllProgressState'), () => {

    const activeProgressState = akioma.WaitCursor.isGlobalProgressStateActive();

    if (activeProgressState) {

      const activeWindows = akioma.swat.MasterLayout.getWindowsInWaitState();

      activeWindows.forEach(win => {
        win._setProgressState(false, true);
        try {
          akioma.notification({
            type: 'information',
            text: `Wait State has been removed for Container Window '${win.opt.name}'.`
          });
          akioma.log.warn(`Wait State has been removed for Container Window '${win.opt.name}'.`);
          akioma.log.warn('with uiContext set to ', win.lastUIContext);
        } catch (e) {
          console.error(e);
        }
      });
    }
  });
};

akioma.bindObjectNamesInTitles = function() {
  akioma.shortcutManager.bindGlobal(akioma.shortcutManager.get('ObjectNamesInTitles'), () => {
    const value = akioma.getSessionProperty('objectNamesInTitles');
    (value) ? akioma.setSessionProperty('objectNamesInTitles', false) : akioma.setSessionProperty('objectNamesInTitles', true);
  });
};

akioma.bindVersionKeyboardEvent();
akioma.bindUrmelScreenEvent();
akioma.bindAutoTranEvent();
akioma.bindDeleteLocalStorage();
akioma.bindOpenRepository();
akioma.bindHelpKeyboardEvent();
akioma.bindDesktopModeEvent();
akioma.bindCloseWindowKeyboardEvent();
akioma.bindNextPreviousWindow();
akioma.bindOmnisearchScreen();
akioma.bindClearAllProgressState();
akioma.bindObjectNamesInTitles();

