dhtmlXForm.prototype.items.iconpicker = {
  render(item, data) {
    item._type = 'iconpicker';
    item._enabled = true;

    this.doAddLabel(item, data);

    const oSelf = $.getObjectByName({ id: data.userdata.id });

    item.akElm = oSelf;
    item._name - oSelf.opt.name;
    oSelf.dhx = item;
    oSelf._internalId = dhtmlx.uid();

    // -> bind input to form
    $(` <div class="dhxform_control">
                <div class="akIconPickerContainer">
                    <i id="IconPreview_${oSelf._internalId}" data-iconpicker-input="input#IconInput_${oSelf._internalId}" data-iconpicker-preview="i#IconPreview_${oSelf._internalId}" class="fad fa-image akIconPickerImage"></i>
                    <input type="text" id="IconInput_${oSelf._internalId}" class="akIconPickerField dhxform_textarea"/>
                </div>
        </div>`)
      .appendTo(item)
      .css({ left: data.inputLeft + 3, top: data.inputTop })
      .find('.akIconPickerField')
      .blur(() => oSelf.eventLeave())
      .focus(() => oSelf.eventFocus())
      .change(() => oSelf.eventChange())
      .end()
      .get(0);

    // set akstyle in iconpicker
    $(oSelf.dhx).find('.dhxform_control').attr('akstyle', oSelf.opt.customStyle);

    return this;
  },

  enable(item) {
    $('input:first', item).removeAttr('readonly');
  },

  disable(item) {
    $('input:first', item).attr('readonly', 'readonly');
  },

  setValue(item, value) {
    item._value = value;
    if (value && value.length > 0)
      $(item).addClass('active');
    else
      $(item).removeClass('active');

    $('input:first', item).val(value);
    if (item.akElm)
      item.akElm._old_val = value;

    this.updateIconPickerImage(item, value);
  },

  getValue(item) {
    return $(item).find('input').val();
  },

  setFocus(item) {
    $('input:first', item).focus();
  },

  getIconPickerInput(item) {
    return $(item).find('input').get(0);
  },

  updateIconPickerImage(item, value) {
    if (isNull(value))
      value = '';
    const valueComponents = value.split('#');
    const icon = valueComponents[0];
    const style = isNull(valueComponents[1]) ? '' : valueComponents[1];

    const imagePreview = $('i:first', item);
    imagePreview.removeClass();
    imagePreview.addClass(icon);
    imagePreview.addClass('akIconPickerImage');
    imagePreview.removeAttr('style');
    if (style > '')
      imagePreview.attr('style', style);
    item.akElm.previousStyle = item.akElm.currentStyle;
    item.akElm.currentStyle = style;

    if (imagePreview.width() == 0) {
      imagePreview.removeClass();
      imagePreview.addClass('fad fa-search akIconPickerImage');
      imagePreview.removeAttr('style');
    }
  }
};
(function() {
  for (const a in { doAddLabel: 1, destruct: 1, doUnloadNestedLists: 1, setText: 1, getText: 1, isEnabled: 1, setWidth: 1 })
    dhtmlXForm.prototype.items.iconpicker[a] = dhtmlXForm.prototype.items.input[a];
})();
(function() {
  dhtmlXForm.prototype.items.iconpicker._getItemNode = function(item) {
    return item;
  };
})();
dhtmlXForm.prototype.getIconPickerInput = function(name) {
  return this.doWithItem(name, 'getIconPickerInput');
};
dhtmlXForm.prototype.setFormData_iconpicker = function(name, value) {
  return this.doWithItem(name, 'setValue', value);
};
dhtmlXForm.prototype.getFormData_iconpicker = function(name) {
  return this.doWithItem(name, 'getValue');
};
dhtmlXForm.prototype.updateIconPickerImage = function(name, value) {
  return this.doWithItem(name, 'updateIconPickerImage', value);
};

/**
 * Swaticonpicker Control
 * @class ak_iconpicker
 * @param {Object} options Repository attributes for Swaticonpicker.
 * @param {string} options.align alignment of column. can be left, right or centered
 * @param {boolean} options.CanFilter Set to FALSE if this field should not be used to filter the data object query.
 * @param {boolean} options.CanSort Set to FALSE if this field should not be used to sort the data object query.
 * @param {boolean} options.CHECKED
 * @param {number} options.COLUMN Column position. This may currently be used when rendering some objects. There is no getColumns function, use getCol to retrieve the realized value from an object.
 * @param {boolean} options.CreateField
 * @param {string} options.DATA-TYPE WidgetAttributes Data-Type
 * @param {boolean} options.ENABLED WidgetAttributes Enabled
 * @param {string} options.EventAkValidate Client side validation code (JavaScript)
 * @param {string} options.EventClick Client side validation code (JavaScript)
 * @param {string} options.EventEntry Event which should run on entry of a field
 * @param {string} options.EventEntryType Language of "entry" trigger
 * @param {string} options.EventLeave Event when leaving a field
 * @param {string} options.EventLeaveType Language, the event is written in.
 * @param {string} options.FieldName The name of the associated SDO field this SmartDataField maps to. This is usually 'set' from the containing SmartDataViewer.
 * @param {string} options.filter
 * @param {string} options.FilterOperator
 * @param {string} options.FolderWindowToLaunch If Dynamics is running, this property specifies a window to launch upon the occurence of toolbar events "View", "Copy", "Modify" or "Add".
 * @param {string} options.FORMAT WidgetAttributes Format
 * @param {number} options.HEIGHT-CHARS Height in characters. This may currently be used when rendering some objects. There is no get function, use getHeight to retrieve the realized value from an object.
 * @param {string} options.HELP WidgetAttributes Help
 * @param {string} options.InitialValue WidgetAttributes InitialValue
 * @param {string} options.KeyField Name of the Dynamic Lookup/Dynamic Combo key field to assign value from (Table.Field)
 * @param {string} options.LABEL WidgetAttributes Label
 * @param {boolean} options.LABELS If false then NO-LABEL is used.  This attribute applies to most field level widgets and frames
 * @param {string} options.LIST-ITEM-PAIRS
 * @param {string} options.LIST-ITEMS
 * @param {boolean} options.Mandatory WidgetAttributes Mandatory
 * @param {boolean} options.MULTIPLE
 * @param {number} options.ROW Row position.
 * @param {string} options.SUBTYPE
 * @param {string} options.TableName WidgetAttributes TableName
 * @param {string} options.TemplateFile The relative path and filename of the static object used as the template at design time
 * @param {string} options.Validation Validation-Rules, e.g. ValidInteger,ValidEMail...
 * @param {string} options.ViewAs The 'ViewAs' definition  of the selection.&#10;- combo-box,radio-set,selection-list OR browse &#10;- Uses colon as separator to define SUB-TYPE for combo-box or &#10;horizontal/vertical radio-set,
 * @param {boolean} options.VISIBLE WidgetAttributes Visible
 * @param {string} options.VisualizationType WidgetAttributes VisualizationType
 * @param {string} options.Width A widget's width. The unit of measurement is determined by another&#10;parameter.
 * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
 */
$.ak_iconpicker = class {
  constructor(options) {
    akioma.BaseFormDataField.call(this, options);

    const defaults = {};

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
    this.view = options.view;
    this.currentStyle = '';
    this.previousStyle = '';

    this.registerDynObject = true;
    this.registerVuexWatcher = true;
    this.useParentDynObjectLink = true;

    // get parent
    const oParent = this.parent;


    if (oParent) {

      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      // this.opt.label = akioma.tran( akioma.getForm(this).opt.name + "." + this.opt.name, { defaultValue: this.opt.label } );

      if (oParent.prop.fields) {
        oParent.prop.fields.push({
          type: 'iconpicker',
          inputTop: parseInt(this.opt.top),
          inputLeft: parseInt(this.opt.left),
          inputWidth: parseInt(this.opt.width),
          label: this.opt.label,
          labelTop: parseInt(this.opt.top),
          labelLeft: oParent.labelLeft(this),
          name: this.opt.name,
          disabled: this.opt.disabled ? true : false,
          errorMsg: '',
          hasError: false,
          mandatory: this.opt.required,
          enabled: !this.opt.enabled,
          className: 'w4-formField w4-inputField iconpickerFormField active',
          position: 'label-top',
          userdata: { id: this.opt.id }
        });
      }

      // append to elements in form
      if (this.parent.view == 'form')
        this.parent.elements.push(this);

      $.extend(this, { security: {} });
    }
  }
};

Object.assign($.ak_iconpicker.prototype, akioma.BaseFormDataField.prototype, {
  componentOptions() {
    const oSelf = this;
    return {
      watch: {
        'getters.getFormFieldState': {
          fn: function(newValue, oldValue) {
            oSelf._hasFormFieldChangesWatcher(newValue, oldValue);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldEnabled': {
          fn: function(bEnabled) {
            oSelf._enabledFormFieldWatcher(bEnabled);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldMandatory': {
          fn: function(bMandatory) {
            oSelf._mandatoryFormFieldWatcher(bMandatory);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldError': {
          fn: function(newValue, oldValue) {
            oSelf._errorFormFieldWatcher(newValue, oldValue);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldErrorMsg': {
          fn: function(cErrorMsg) {
            oSelf._errorFormFieldMsgWatcher(cErrorMsg);
          },
          params: [this.opt.id]
        }
      }
    };
  },

  /**
 * Watcher for the enable/disable iconpicker
 * @memberof ak_iconpicker
 * @instance
 * @private
 * @param {Boolean} bEnabled
 */
  _enabledFormFieldWatcher(bEnabled) {
    const oOverlay = this.$domElement.find('.akIconPickerImage');
    if (bEnabled) {
      this.form.enableItem(this.opt.name);
      oOverlay.removeClass('disabled');
    } else {
      this.form.disableItem(this.opt.name);
      oOverlay.addClass('disabled');
    }
  },

  /**
 * Watcher for the mandatory fields in iconpicker
 * @memberof ak_iconpicker
 * @instance
 * @private
 * @param {Boolean} bMandatory
 */
  _mandatoryFormFieldWatcher(bMandatory) {
    if (typeof (this.setRequired) == 'function')
      this.setRequired(bMandatory);
    this.form.setRequired(this.opt.name, bMandatory);
  },

  /**
 * Watcher for the iconpicker error message
 * @memberof ak_iconpicker
 * @instance
 * @private
 * @param {Boolean} newValue
 */
  _errorFormFieldMsgWatcher(newValue) {
    const oFormField = akioma.getDhxFormElement(this.form, this.opt.name);

    // setup error message under input
    if (oFormField) {
      const oFormCtrl = $(oFormField).find('> .dhxform_control');
      if (oFormCtrl.find('.validation-error-smartmessage').length == 0)
        oFormCtrl.append(`<span class="validation-error-smartmessage">${newValue}</span>`);
      else
        oFormCtrl.find('.validation-error-smartmessage').text(newValue);
    }
  },

  /**
 * Get the value of the iconpicker
 * @memberof ak_iconpicker
 * @instance
 * @returns {String}
 */
  getValue() {
    return this.form.getItemValue(this.opt.name);
  },

  /**
 * Set the value of the iconpicker
 * @memberof ak_iconpicker
 * @instance
 * @param {String} cValue
 */
  setValue(cValue) {
    this.form.doWithItem(this.opt.name, 'setValue', cValue);
  },

  /**
 * Method executed on iconpicker input changes
 * @memberof ak_iconpicker
 * @instance
 */
  eventChange() {
    this.eventBlur();
    this.form.akElm.dataSource.setChanged(true);
    const cVal = $(this.form.getIconPickerInput(this.opt.name)).val();
    this._old_val = this._val;
    this._val = cVal;
    this.dynObject.akEvent = {
      currentValue: cVal,
      oldValue: this._old_val
    };
    // call selection event
    if (this.opt.validateEvent)
      app.controller.callAkiomaCode(this, this.opt.validateEvent);
  },

  /**
 * Method executed on leave
 * @memberof ak_iconpicker
 * @instance
 */
  eventLeave() {
    if (this.opt.leaveEvent)
      app.controller.callAkiomaCode(this, this.opt.leaveEvent);
    this.eventBlur();
    const cVal = $(this.form.getIconPickerInput(this.opt.name)).val();
    this._old_val = this._val;
    this._val = cVal;
  },

  /**
 * Method executed on focus
 * @memberof ak_iconpicker
 * @instance
 */
  eventFocus() {
    const formDiv = this.form.cont.children[0];
    $(formDiv).children().removeClass('focusedinp');
    const $itemcont = $(this.form.getIconPickerInput(this.opt.name)).closest('.iconpickerFormField');
    $itemcont.addClass('active').addClass('focusedinp');
  },

  /**
 * Method executed on blur
 * @memberof ak_iconpicker
 * @instance
 */
  eventBlur() {
    const $itemcont = $(this.form.getIconPickerInput(this.opt.name)).closest('.iconpickerFormField');
    const cVal = $(this.form.getIconPickerInput(this.opt.name)).val();
    if (cVal.length == 0)
      $itemcont.removeClass('active');
    $itemcont.removeClass('focusedinp');

    // vuex dirty change
    if (this._old_val !== cVal) {
      const oForm = this.form.akElm;
      oForm.setFieldHasChanges(this.opt.name, true);
    }

    this.form.updateIconPickerImage(this.opt.name, cVal);
  },

  destroy() {
    $('body').off('click', '.ip-icons-footer .cancel', this._cancelCallback);
  },

  /**
 * Code executed when clicking cancel on the iconPicker
 * @memberof ak_iconpicker
 * @instance
 */
  onCancelClick() {
    akioma.swat.MasterLayout.enableLastFocusTrap();
  },
  /**
 * Code executed after the construct is done.
 * @memberof ak_iconpicker
 * @instance
 */
  finishConstruct() {
    try {
      this.form = akioma.getForm(this).dhx;

      const iconpickerNode = akioma.getDhxFormElement(this.form, this.opt.name);
      this.$domElement = $(iconpickerNode);
      this.setResponsiveSizes();
      this.domTag = this.$domElement.find('.akIconPickerField')[0];
      this._setTabIndex(this.domTag);

      IconPicker.Init({
        // Required: You have to set the path of IconPicker JSON file to "jsonUrl" option. e.g. '/content/plugins/IconPicker/dist/iconpicker-1.5.0.json'
        jsonUrl: '/resources/iconpicker-icons.json',
        /* This can be extracted from fontawesome's search page with the following code:
                {
                    fontList = "";
                    count = 0;
                    jQuery('.fad').toArray().forEach((item) => {fontList += `,\n"${count++}": "${item.className}"`;});
                }
                */

        // Optional: Change the buttons or search placeholder text according to the language.
        searchPlaceholder: 'Search Icon',
        showAllButton: 'Show All',
        cancelButton: 'Cancel',
        noResultsFound: 'No results found.', // v1.5.0 and the next
        versionsborderRadius: '20px' // v1.5.0 and the next versions
      });
      IconPicker.Run(`#IconPreview_${this._internalId}`, () => {
        this.$domElement.find('input').trigger('change');
        // we get the value and set it through the proper method so all required actions are called
        let value = this.getValue();
        if (this.previousStyle > '')
          value += `#${this.previousStyle}`;
        this.setValue(value);
        akioma.swat.MasterLayout.enableLastFocusTrap();
      });

      const ipButton = document.querySelector(`#IconPreview_${this._internalId}`);
      ipButton.addEventListener('click', () => {
        akioma.swat.MasterLayout.disableLastFocusTrap();
      });// cancel button click listener on

      this._cancelCallback = this.onCancelClick.bind(this);
      $('body').on('click', '.ip-icons-footer .cancel', this._cancelCallback);

    } catch (e) {
      console.error('Error', e);
    }
  }
});
