/**
 * Prevents adding/removing properties from akioma.sessionContext
 * @private
 */
akioma._sealSessionContext = function() {
  Object.seal(akioma.sessionContext);
  for (const context in akioma.sessionContext) {
    if (Object.prototype.hasOwnProperty.call(akioma.sessionContext, context))
      Object.seal(akioma.sessionContext[context]);
  }
};

/**
 * Gets session property
 * @param {string}  cName      The property name.
 * @param {string}  [cContext] Represents the session context where the property will be searched (e.g eSessionContext, eSwatSessionContext).
 * <br> If not specified, default is eSwatSessionContext
 * @returns {string | undefined} Returns the session property value
 */
akioma.getSessionProperty = function(cName, cContext) {
  if (!cContext)
    cContext = 'eSwatSessionContext';
  if (akioma.sessionContext)
    return akioma.sessionContext[cContext][cName];
  return undefined;
};

/**
 * Sets session property
 * @param {string}                 cName      The property name.
 * @param {string|integer|boolean} cValue     The property new value.
 * @param {string}                 [cContext] Represents the session context where the property will be set (e.g eSessionContext, eSwatSessionContext).
 * <br> If not specified, default is eSwatSessionContext
 * @param {boolean}                [bSave]    Specifies if the property will be saved or not to the backend. Default is true (will be saved).
 */
akioma.setSessionProperty = function(cName, cValue, cContext, bSave) {
  const logSessionManager = akioma.log.getLogger('SessionManager');
  logSessionManager.setLevel('warn');

  if (!cName || cValue === undefined) {
    logSessionManager.warn('Could not set session property!');
    return;
  }

  if (bSave === undefined || bSave === null)
    bSave = true;
  if (!cContext)
    cContext = 'eSwatSessionContext';

  if (akioma.sessionContext && akioma.sessionContext[cContext] && akioma.sessionContext[cContext][cName] !== undefined) {
    akioma.sessionContext[cContext][cName] = cValue;
    if (cContext == 'eSwatSessionContext' && app.sessionData)
      app.sessionData[cName] = cValue;
    if (bSave)
      akioma.saveSessionProperty(cName, cContext);
  } else
    logSessionManager.warn('Could not set session property!');
};

/**
 * Saves a session property
 * @param {string}  cName      The property name.
 * @param {string}  [cContext] Represents the session context where the proprty will be set (e.g eSessionContext, eSwatSessionContext).
 * <br> If not specified, default is eSwatSessionContext
 */
akioma.saveSessionProperty = function(cName, cContext) {
  if (!cContext)
    cContext = 'eSwatSessionContext';

  const cUrl = `/web/SessionContext/${cContext}`;

  if (arguments.length > 0) {
    if (akioma.sessionContext && akioma.sessionContext[cContext] && akioma.sessionContext[cContext][cName] !== undefined) {
      const oData = {};
      oData[cName] = akioma.sessionContext[cContext][cName];
      akioma._saveSession(cUrl, oData);
    }
  }
};

/**
 * Saves the entire session context
 */
akioma.saveSessionContext = function() {
  const cUrl = '/web/SessionContext/*';
  akioma._saveSession(cUrl, akioma.sessionContext);
};


/**
 * Triggers save request for sessionContext or sessionProperty
 * @param {string} cUrl  Url for request
 * @param {string} oData Data sent to the backend
 */
akioma._saveSession = function(cUrl, oData) {
  const logSessionManager = akioma.log.getLogger('SessionManager');

  if (oData && !$.isEmptyObject(oData)) {
    $.ajax({
      type: 'PUT',
      url: cUrl,
      contentType: 'application/json',
      data: JSON.stringify(oData),

      success: function(data) {
        logSessionManager.setLevel('info');
        logSessionManager.info('Session context saved successfully!');

        akioma.sessionContext = (typeof (data) == 'object') ? data : undefined;
        $.extend(app.sessionData, akioma.sessionContext.eSwatSessionContext);

        if (akioma.sessionContext && akioma.sessionContext.eSwatSessionContext.translationNamespaces) {
          const aNamespaces = akioma.sessionContext.eSwatSessionContext.translationNamespaces.split(',');
          app.sessionData.translationNamespace = aNamespaces[0];
        }

        akioma._sealSessionContext();

      },

      error: function() {
        logSessionManager.setLevel('warn');
        logSessionManager.warn('Error on saving session context!');
      }
    });
  } else {
    logSessionManager.setLevel('warn');
    logSessionManager.warn('Could not save session context!');
  }
};

// Akioma windows session save
// global context function
// eslint-disable-next-line no-unused-vars
function saveCurrentSession() {
  const oAkiomaSession = {
    iActiveWindow: 0,
    aWindows: []
  };

  const cSelectedDataViewTabID = akioma.dataViewTabs.getSelected();
  oAkiomaSession.iActiveWindow = akioma.dataViewTabs.indexById(cSelectedDataViewTabID);

  akioma.windowParent.dhx.forEachWindow(win => {

    if (win.akiomaWindowObject.opt.name.toLowerCase() != akioma.cMainContainer.toLowerCase()) {
      const oNewWin = {};

      // set selected record
      const selfHdl = win.akiomaWindowObject.dynObject.getLink('PRIMARYSDO:TARGET').getValue('selfhdl');
      oNewWin.cSelectedRecord = selfHdl;

      // save window positions and sizes
      win.akiomaWindowObject.saveWindowProfile();

      const oWindowChildren = win.akiomaWindowObject.dynObject.childs;
      for (const key in oWindowChildren) {
        if (Object.prototype.hasOwnProperty.call(oWindowChildren, key)) {
          const oChild = oWindowChildren[key];
          if (oChild != null && oChild.type) {
            if (oChild.type == 'tabbar') {
              const cTabId = oChild.controller.dhx.getActiveTab();

              // set selected page/tab
              oNewWin.cSelectedPage = oChild.controller.dhx.tabs(cTabId).getIndex();

            }
          }
        }
      }

      oAkiomaSession.aWindows.push(oNewWin);
    }
  });

  UserProfile.saveLocalProfileDataSession('ak_main_session', oAkiomaSession);

  return true;
}

// Akioma session loading
// global context function
// eslint-disable-next-line no-unused-vars
function loadCurrentSession() {
  const oAkiomaSession = UserProfile.loadLocalProfileDataSession('ak_main_session'),
    aWindows = oAkiomaSession.aWindows;

  function selectActiveWindowSessionSave() {
    const oAkiomaSession = UserProfile.loadLocalProfileDataSession('ak_main_session');
    $(akioma.dataViewTabs.$view).find(`> div:nth-child(${oAkiomaSession.iActiveWindow + 1})`).click();
  }

  for (let w = 0; w < aWindows.length; w++) {
    const cHdl = aWindows[w].cSelectedRecord;

    // opens windows from session
    const oNewWindow = app.controller.launchContainer({
      proc: 'launchContainer.r',
      para: `SelfHdl=${cHdl}&Page=0,1`,
      data: true,
      extLink: cHdl,
      self: this
    });

    // on last window added, select the active Akioma session window
    if (w == aWindows.length - 1) {
      oNewWindow.children('datasource', function() {
        const ds = this;
        const eSelectAfterDataLoad = ds.dhx.attachEvent('onAfterCursorChange', () => {
          selectActiveWindowSessionSave();
          ds.dhx.detachEvent(eSelectAfterDataLoad);
        });
      });
    }

    const oWindowChildren = oNewWindow.dynObject.childs;
    for (const key in oWindowChildren) {
      if (Object.prototype.hasOwnProperty.call(oWindowChildren, key)) {
        const oChild = oWindowChildren[key];
        if (oChild != null && oChild.type) {
          if (oChild.type == 'tabbar') {
            // selects page/tab
            oChild.controller.dhx.forEachTab(tab => {
              if (tab.getIndex() == aWindows[w].cSelectedPage)
                tab.setActive();

            });
          }
        }
      }
    }
  }
}

/**
 * Gets akIdMode attribute value from eSwatSessionContext
 * @returns {string | undefined} Returns the session property value
 */
akioma.getAkIdMode = function() {
  return akioma.getSessionProperty('akIdMode');
};

/**
 * Check sessionContext for security token restrictions. <br/>
 * Returns true if any of the tokens checked is restricted.
 * @param {String|Array} tokens Tokens to check.
 * @return {boolean}
 */
// global context function
// eslint-disable-next-line no-unused-vars
function securityIsRestricted(tokens) {

  if (!akioma.sessionContext) return false;

  // Convert parameter into string.
  const tokensToCheck = Array.isArray(tokens) ? tokens.join(',') : tokens;

  const restrictedTokens = akioma.sessionContext.eSessionContext.RestrictedTokens.split(',');

  for (const restrictedToken of restrictedTokens) {
    if (akioma.canDo(tokensToCheck, restrictedToken))
      return true;
  }

  return false;
}


// global context function
// eslint-disable-next-line no-unused-vars
/**
 * Helper function for enabling/disabling the Swat dev tools
 */
akioma.toggleSwatDevMode = function() {

  const oldValue = akioma.getSessionProperty('objectNamesInTitles');
  akioma.setSessionProperty('objectNamesInTitles', !oldValue);
};
