// Namespaces
// Define all global namespaces
[
  'akioma', // used throughout swat js codebase
  'Animation', // akioma.animate.js
  'LoadDataHelper' // loadDataHelper.js
].forEach(entry => {
  if (!window[entry])
    window[entry] = {};
});
[
  'swat', // main swat package
  'repository', // akRepository.js
  'messaging', // akioma.messaging.js
  'chartTypes', // chartTypes.js
  'bpm', //  akioma.bpm.js
  'config', // akioma.configurator.js
  'text', // akioma.text.js
  'toolbar', // akioma.toolbar.js
  'user', // akioma.user.js
  'events', // akioma.logic.js
  'globalData' // akioma.dateUtils.js and akioma.global.js
].forEach(entry => {
  if (!window.akioma[entry])
    window.akioma[entry] = {};
});

// Global objects
// akioma.appointment.js
window.schedulers = [];
// akioma.global.js
window.myPop;
// global.js
window.showTaskbar = true;
window.lDebug = true;
window.oDebugWindow = null;
window.oDhxTree;
window.dataSource = { list: new Array() };
window.oDhx = {
  imagePath: '/dhtmlx/imgs/',
  skin: 'material'
};
window.oDebugElm = null;
// akioma.object.js, akioma.dhxobject.js
window.akRepositoryObjects = [];
