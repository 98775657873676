/**
 * Class for handling of SmartMessages
 * @class SmartMessage
 * @example
 *
 * var message = new akioma.SmartMessage();
 *
 *
 * // old and deprecated version, use akioma.invokeServerTask instead
 * message.invokeBusinessTask({
 *     name: 'Osiv.Sendung.Sendung.SendungBT',
 *     methodName: 'SendungInPapierkorb',
 *     plcParameter: {
 * 		Sendung_ID: 123456997,
 * 		Geloescht_Grund: 'VW'
 * 	  }
 * }).fireOnAnswer(function(question, questions){
 *     // will be called for each question answered
 *     console.log(question);
 * });
 *
 *
 * @namespace akioma
 */
akioma.SmartMessage = class {
  constructor(options) {
    this._eventsOnAnswer = [];
    this._eventsOnSuccess = [];
    this._eventsOnError = [];
    this.opts = options;
    if (options) {
      this.name = options.name;
      this.methodName = options.methodName;
    }
  }

  /**
     * Method for calling a businessTask for a given businessTask name, businessTask methodName, businessTask plcParameter
     * @param {object} options Object settings
     * @param {string} options.name The businessTask name
     * @param {string} options.methodName The businessTask method name
     * @deprecated
     * @memberof SmartMessage
     */
  invokeBusinessTask(options) {

    console.warn('[Deprecated] Please use akioma.invokeServerTask method instead.');

    const BTName = options.name;
    const BTMethodName = options.methodName;
    const paramObj = options.paramObj || {};

    if (options.plcParameter)
      paramObj.plcParameter = options.plcParameter;


    this.methodName = options.methodName;
    this._eventsOnError = [];
    this.name = options.name;

    akioma.callServerTask({
      name: BTName,
      methodName: BTMethodName,
      paramObj: paramObj
    })
      .done(response => {
        const ret = response.plcParameter;

        if (ret.Error) {
          let errorText = ret.ErrorText;
          if (isNull(errorText) && ret.Error != true)
            errorText = ret.Error;

          if (errorText)
            this.displayErrorText(errorText);
          else
            this.callErrorEvents(response);

        } else {
          let questionUnAnswered;
          // console.log(ret);
          if (ret.akUiControl && ret.akUiControl.Questions && ret.akUiControl.Questions.length > 0)
            questionUnAnswered = _.findWhere(ret.akUiControl.Questions, { MessageReply: 'Unanswered' });
          else
            questionUnAnswered = false;

          if (questionUnAnswered) {
            for (const question of ret.akUiControl.Questions) {
              if (question.MessageReply.toLowerCase() == 'unanswered') {
                this.loadQuestion(question, ret.akUiControl.Questions, response);
                break;
              }
            }
          } else {
            // //if there are no more questions unaswered
            this.callSuccessEvents(response);
          }

        }
      }).fail((jsdo, success, request) => {

        const cErrorMsg = request.response.message ? request.response.message : (`${request.response.title} : ${request.response.returnValue}`);

        if (cErrorMsg) {

          akioma.SmartMessage.parseServerText(cErrorMsg).done(aFinalMsg => {

            let cFinalMsg = '';
            let bModal = false; // set for each message in parseServerText; leave to false

            const cTitle = aFinalMsg[0].title;

            for (const i in aFinalMsg) {
              cFinalMsg += (`${aFinalMsg[i].text}</br>`);
              if (aFinalMsg[i].modal)
                bModal = true;
            }

            // call on error events
            this.callErrorEvents(jsdo, success, request);

            if (bModal)
              akioma.message({ title: cTitle, type: 'error', text: cFinalMsg });
            else
              akioma.notification({ type: 'error', text: cFinalMsg });


          });
        }
      });

    return this;
  }

  /**
     * Method for asking a question, will display the modal window dialog with the SmartMessage question
     * @param {object} question
     * @param {array} aFinalMsg array of messages
     * @param {object} questions
     * @param {object} response
     * @private
     * @memberof SmartMessage
     */
  askQuestion(question, aFinalMsg, questions, response) {
    const aAnswers = question.MessageButtons.split(/(?=[A-Z])/);

    const cDefaultReply = question.DefaultReply;

    let cFinalMsg = '';
    let bModal = true;
    let messageType = 'information';
    const oSelf = this;

    const cTitle = aFinalMsg[0].title;

    for (const i in aFinalMsg) {
      cFinalMsg += (`${aFinalMsg[i].text}\n`);
      cFinalMsg = cFinalMsg.split('<br />').join('\n');
      bModal = aFinalMsg[i].modal;
      messageType = aFinalMsg[i].type;
    }

    if (bModal) {
      const oButtons = {};
      for (const a in aAnswers) {
        const answer = aAnswers[a];

        let cClassName;
        switch (answer) {
          case 'No':
            cClassName = (cDefaultReply == 'ReplyNo' ? 'default' : '');
            oButtons.noreply = {
              text: akioma.tran(`Modalbox.answer_${aAnswers[a].toLowerCase()}`, { defaultValue: aAnswers[a] }),
              value: 'ReplyNo',
              visible: true,
              closeModal: true,
              className: cClassName
            };
            break;
          case 'Yes':
            cClassName = (cDefaultReply == 'ReplyYes' ? 'default' : '');
            oButtons.yesreply = {
              text: akioma.tran(`Modalbox.answer_${aAnswers[a].toLowerCase()}`, { defaultValue: aAnswers[a] }),
              value: 'ReplyYes',
              visible: true,
              closeModal: true,
              className: cClassName
            };
            break;
          case 'Ok':
            cClassName = (cDefaultReply == 'ReplyOk' ? 'default' : '');
            oButtons.okreply = {
              text: akioma.tran(`Modalbox.answer_${aAnswers[a].toLowerCase()}`, { defaultValue: aAnswers[a] }),
              value: 'ReplyOk',
              visible: true,
              closeModal: true,
              className: cClassName
            };
            break;
          case 'Cancel':
            cClassName = (cDefaultReply == 'ReplyCancel' ? 'default' : '');
            oButtons.cancelreply = {
              text: akioma.tran(`Modalbox.answer_${aAnswers[a].toLowerCase()}`, { defaultValue: aAnswers[a] }),
              value: 'ReplyCancel',
              visible: true,
              closeModal: true,
              className: cClassName
            };
            break;
        }
      }

      akioma.message({
        type: messageType,
        title: cTitle,
        text: cFinalMsg,
        buttons: oButtons,
        callback: function(cSelected) {
          if (oSelf.onAnswerSelected)
            oSelf.onAnswerSelected(cSelected, question, questions, aAnswers, response);

        }
      });

    } else {
      switch (messageType) {
        case 'information':
        case 'question':
          akioma.notification({ type: 'info', text: cFinalMsg });
          break;
        case 'error':
          akioma.notification({ type: 'error', text: cFinalMsg });
          break;
        case 'warning':
          akioma.notification({ type: 'warning', text: cFinalMsg });
      }

    }
    // TODO: stop the progress (progressOff())

  }

  /**
     * Add callback for the businessTask method fired
     * @param {function} fnCallback callback after businessTask message fired
     * @memberof SmartMessage
     */
  fireOnAnswer(fnCallback) {
    this._eventsOnAnswer.push(fnCallback);
    return this;
  }

  fireOnSuccess(fnCallback) {
    this._eventsOnSuccess.push(fnCallback);
    return this;
  }

  fireOnError(fnCallback) {
    this._eventsOnError.push(fnCallback);
    return this;
  }

  /**
     * Method for calling all subscribed Error events
     *
     * @param   {array}  params  The array of parameters to call the success events with
     * @private
     * @memberof SmartMessage
     * @return  {void}
     *
     */
  callErrorEvents(...params) {

    for (const x in this._eventsOnError)
      this._eventsOnError[x].apply(this, params);


    this._eventsOnError = [];
  }

  /**
     * Method for calling all subscribed Success Events
     *
     * @param   {array}  params  The array of parameters to call the success events with
     * @private
     * @memberof SmartMessage
     * @return  {void}
     */
  callSuccessEvents(...params) {
    for (const x in this._eventsOnSuccess)
      this._eventsOnSuccess[x].apply(this, params);


    this._eventsOnSuccess = [];
  }

  /**
     * Displays error message and calls error events
     *
     * @param {string} errorText The error text value
     * @private
     * @memberof SmartMessage
     * @return {void}
     */
  displayErrorText(errorText) {
    if (errorText) {
      const promiseErrorMessage = akioma.SmartMessage.showErrorMessage(errorText);
      promiseErrorMessage.always(cErrText => {
        this.callErrorEvents(cErrText);
      });
    } else
      this.callErrorEvents();

  }

  /**
     * Method called when user selects an answer
     * @param {string} cSelected
     * @param {object} question
     * @param {array} questions
     * @param {object} response
     * @private
     * @memberof SmartMessage
     */
  onAnswerSelected(cSelected, question, questions, aAnswers, response) {
    const replyQuestionObj = question;

    for (const a in aAnswers)
      aAnswers[a] = aAnswers[a].toLowerCase();


    const cSelectedReply = cSelected;
    replyQuestionObj.MessageReply = cSelectedReply;


    // replace with MessageReply
    _.extend(_.findWhere(questions, { MessageID: question.MessageID }), question);


    // replace in response object with message reply
    _.extend(_.findWhere(response.plcParameter.akUiControl.Questions, { MessageID: question.MessageID }), question);


    // call event callback onAnswer
    for (const ev of this._eventsOnAnswer)
      ev(question, questions);


    // stop if cancel reply
    if (question.MessageReply == 'ReplyCancel') {
      if (this.opts.showWaitCursor && this.opts.uiContext) {
        akioma.WaitCursor.hideWaitCursor(this.opts.uiContext);
        akioma.WaitCursor.hideProgressState(this.opts.uiContext);
      }
      return true;
    }

    akioma.callServerTask({
      name: this.name,
      methodName: this.methodName,
      paramObj: response
    }).done(response => {
      const ret = response.plcParameter;

      if (ret.Error) {
        const errorText = ret.ErrorText || ret.Error;
        this.displayErrorText(errorText);
      } else {
        // console.log(ret);
        if (ret.akUiControl && ret.akUiControl.Questions && ret.akUiControl.Questions.length > 0) {
          for (const question of ret.akUiControl.Questions) {
            if (question.MessageReply.toLowerCase() == 'unanswered') {
              this.loadQuestion(question, ret.akUiControl.Questions, response);
              break;
            }
          }
        }
        let questionUnAnswered;
        if (ret.akUiControl.Questions)
          questionUnAnswered = _.findWhere(ret.akUiControl.Questions, { MessageReply: 'Unanswered' });
        // //if there are no more questions unaswered call onSuccess events
        if (!questionUnAnswered)
          this.callSuccessEvents(response);

      }


    }).fail((jsdo, success, request) => {

      let cErrorMsg;
      if (request && request.response)
        cErrorMsg = request.response.message ? request.response.message : (`${request.response.title} : ${request.response.returnValue}`);
      else if (jsdo.message)
        akioma.notification({ type: 'error', text: jsdo.message });
      else
        akioma.notification({ type: 'error', text: `There was an error invoking the business task '${this.name}', method named '${this.methodName}'.` });


      if (cErrorMsg) {

        akioma.SmartMessage.parseServerText(cErrorMsg).done(aFinalMsg => {

          let cFinalMsg = '';
          let bModal = false; // set for each message in parseServerText; leave to false

          const cTitle = aFinalMsg[0].title;

          for (const i in aFinalMsg) {
            cFinalMsg += (`${aFinalMsg[i].text}</br>`);
            if (aFinalMsg[i].modal)
              bModal = true;
          }

          // call on error events
          this.callErrorEvents(jsdo, success, request);

          if (bModal) {

            akioma.message({
              type: 'error',
              title: cTitle,
              text: cFinalMsg
            });

          } else {

            akioma.notification({
              type: 'error',
              text: cFinalMsg
            });
          }
        });
      }


    });


  }

  static showErrorMessage(cErrorMsg) {
    const deferred = $.Deferred();

    akioma.SmartMessage.parseServerText(cErrorMsg).done(aFinalMsg => {
      let cFinalMsg = '';
      let bModal = false; // set for each message in parseServerText; leave to false
      const cTitle = aFinalMsg[0].title;

      for (const i in aFinalMsg) {
        cFinalMsg += (`${aFinalMsg[i].text}\n`);
        cFinalMsg = cFinalMsg.split('<br />').join('\n');
        if (aFinalMsg[i].modal)
          bModal = aFinalMsg[i].modal;
      }

      if (bModal) {
        akioma.message({
          type: 'error',
          title: cTitle,
          text: cFinalMsg
        });
      } else {
        akioma.notification({
          type: 'error',
          text: cFinalMsg,
          lifetime: -1,
          expire: -1
        });
      }

      deferred.resolve(cFinalMsg);
    }).fail(() => {
      // console.log(e);
      akioma.message({
        text: 'Could not load SmartMessage.',
        lifetime: -1,
        expire: -1,
        type: 'error'
      });

      deferred.resolve('Could not load SmartMessage.');
    });

    return deferred.promise();
  }

  /**
     * Returns SmartMessage with replaced formated message
     * @param {Object} result
     * @param {array} mySplitResult
     */
  static getSmartMessagesLoaded(result, mySplitResult, cQuestionTitle) {
    let oSmartMessageObj = null;
    if (result) {

      let substituteMessage = result.MessageText;
      // check for modal/non-modal
      let bModal = akioma.swat.isDefaultMessageBoxStyleModal();
      if (result.MessageBoxStyle)
        bModal = (result.MessageBoxStyle.toLowerCase() == 'modal');

      // replace placeholders globally in text
      for (let iSubstitute = 3; iSubstitute < mySplitResult.length; iSubstitute++) {
        const oSubstRegEx = new RegExp(`&${iSubstitute - 2}`, 'g');
        substituteMessage = substituteMessage.replace(oSubstRegEx, mySplitResult[iSubstitute]);
      }

      // console.log ("After Substitute", substituteMessage);
      substituteMessage = this.replaceEmptyPlaceholders(substituteMessage);

      // replace CR/LF in SmartMessage text
      substituteMessage = substituteMessage.replace(/(?:\r\n|\r|\n)/g, '<br />');

      let cTitle = '';

      if (cQuestionTitle !== undefined)
        cTitle = cQuestionTitle;

      oSmartMessageObj = {
        title: cTitle,
        text: substituteMessage,
        modal: bModal
      };

      // set smartmessage message type
      if (result.MessageType)
        oSmartMessageObj.type = result.MessageType.toLowerCase();

    }
    return oSmartMessageObj;
  }

  /**
     * Clears empty placeholders &1, &2, &3 in text message
     * @param {string} text The text with placeholders to clean
     */
  static replaceEmptyPlaceholders(text) {
    const maxNumOfIterations = 100;
    let currentIteration = 1;
    while (text.indexOf('&') > -1 &&
            !isNaN(text[text.indexOf('&') + 1])) {

      const cutAfterIndex = text.substr(text.indexOf('&') + 1);
      let number = '';

      for (const x in cutAfterIndex) {
        if (isNaN(cutAfterIndex[x]) || cutAfterIndex[x] === ' ')
          break;

        number += cutAfterIndex[x];
      }

      // replace the number placeholder
      if (number !== '') {
        const oSubstRegEx = new RegExp(`&${number}`, 'g');
        text = text.replace(oSubstRegEx, '');
      }

      if (currentIteration >= maxNumOfIterations)
        break;

      currentIteration++;
    }

    return text;
  }

  /**
     * Method for loading SmartMessages, will be temporary stored in memory if already loaded
     * @param {array} mySplitResult
     * @param {string} urlMessageRequest path for requesting smartmessage
     */
  static loadSmartMessage(mySplitResult, urlMessageRequest, cQuestionTitle) {
    const deferredSmartMessage = $.Deferred();

    // if already temporary cached in memory
    if (akioma.SmartMessage.aTempSmartMessages[urlMessageRequest] != undefined) {
      const result = akioma.SmartMessage.aTempSmartMessages[urlMessageRequest];
      const oSM = akioma.SmartMessage.getSmartMessagesLoaded(result, mySplitResult, cQuestionTitle);
      deferredSmartMessage.resolve(oSM);
    } else {
      const oDeffPromiseSmartMessage = $.ajax({ url: `${akioma.SmartMessage.serviceURI}/SmartMessage${urlMessageRequest}` });
      oDeffPromiseSmartMessage.done(result => {
        akioma.SmartMessage.aTempSmartMessages[urlMessageRequest] = result;
        const oSM = akioma.SmartMessage.getSmartMessagesLoaded(result, mySplitResult, cQuestionTitle);
        deferredSmartMessage.resolve(oSM);
      });
    }

    return deferredSmartMessage.promise();
  }

  /**
     * Fetches the message text, type, details etc. for the message number.
     * Note that the function is async and returns a jQuery promise (see example).
     * @param {string} msgGroup The message group
     * @param {number} msgNum The message number
     * @example
     * akioma.NotificationMessage.getMessage("OpenEdge", 1).done(function(result) {console.log(result)});
     * @memberOf uiMessage
     * @return {promise} Returns a jQuery promise
     */
  static getMessage(msgGroup, msgNum) {

    const ret = $.get(`/web/SmartMessage/${msgGroup}/${msgNum}`).promise();

    ret.fail(res => {

      if (res.responseJSON) {
        let msg = '';

        if (res.responseJSON.messages)
          msg = res.responseJSON.messages.map(msg => msg.message).join('<br/>');
        else
        if (res.responseJSON.title)
          msg = res.responseJSON.title;
        else
        if (res.responseJSON.error)
          msg = res.responseJSON.error;
        else
          msg = 'An error has occured';

        akioma.notification({ type: 'error', text: msg });
      }
    });

    return ret;
  }

  static parseServerText(cSmartMessageText) {
    const aFinalMsg = [];
    const aDefferedObjs = [];
    const deferred = $.Deferred();
    const bModal = akioma.swat.isDefaultMessageBoxStyleModal();

    if (cSmartMessageText.indexOf('SmartMessage') > -1) {
      const aMessageSplit = cSmartMessageText.split(String.fromCharCode(3));

      for (const cSmartMessage of aMessageSplit) {
        if (cSmartMessage.indexOf('SmartMessage') == 0) {
          const mySplitResult1 = cSmartMessage.split('\t').splice(0, cSmartMessage.split('\t').length - 1);
          const mySplitResult = mySplitResult1.concat(cSmartMessage.split('\t') // split by \t in array
            .splice(cSmartMessage.split('\t').length - 1, 1)[0]
            .split(String.fromCharCode(4))); // split by charCode 4

          let urlMessageRequest = '';
          if (mySplitResult.length >= 2)
            urlMessageRequest = `${urlMessageRequest}/${mySplitResult[1]}/${mySplitResult[2]}`;

          // load smartmessage
          const result = akioma.SmartMessage.loadSmartMessage(mySplitResult, urlMessageRequest);

          aDefferedObjs.push(result);
        } else
          aFinalMsg.push({ text: cSmartMessage, modal: bModal });
      }
    } else
      deferred.resolve([{ text: cSmartMessageText, modal: bModal }]);

    // after all deffered ended get complete message string
    $.when.all(aDefferedObjs).then(aFinalMsg => {
      deferred.resolve(aFinalMsg);
    }).fail(e => {
      deferred.reject(new Error(e));
    });

    return deferred.promise();
  }

  /**
     * Load Question SmartMessage and display
     * @param {object} question
     * @param {array} questions
     * @param {object} response
     */
  loadQuestion(question, questions, response) {
    const aDefferedObjs = [];
    const oSelf = this;
    const cSmartMessageText = question.MessageText;
    const cQuestionTitle = question.MessageTitle;

    const aMessageSplit = cSmartMessageText.split(String.fromCharCode(3));
    for (const cSmartMessage of aMessageSplit) {
      if (cSmartMessage.indexOf('SmartMessage') == 0) {
        const mySplitResult1 = cSmartMessage.split('\t').splice(0, cSmartMessage.split('\t').length - 1);
        const mySplitResult = mySplitResult1.concat(cSmartMessage.split('\t') // split by \t in array
          .splice(cSmartMessage.split('\t').length - 1, 1)[0]
          .split(String.fromCharCode(4))); // split by charCode 4

        let urlMessageRequest = '';
        if (mySplitResult.length >= 2)
          urlMessageRequest = `${urlMessageRequest}/${mySplitResult[1]}/${mySplitResult[2]}`;


        // load smartmessage
        const result = akioma.SmartMessage.loadSmartMessage(mySplitResult, urlMessageRequest, cQuestionTitle);

        aDefferedObjs.push(result);
      } else {
        const deferredMessage = $.Deferred();
        deferredMessage.resolve({ title: cQuestionTitle, text: cSmartMessage, modal: true, type: 'question' });
        aDefferedObjs.push(deferredMessage);
      }
    }

    // after all deffered ended get complete message string
    $.when.all(aDefferedObjs).then(aFinalMsg => {
      oSelf.askQuestion(question, aFinalMsg, questions, response);
    });
  }
};

/**
 * @property {array} replyOptions All the possible reply options for a SmartMessage
 */
akioma.SmartMessage.replyOptions = [ 'ReplyYes', 'ReplyNo', 'ReplyOk', 'ReplyCancel' ];
akioma.SmartMessage.replyOptionsText = [ 'yes', 'no', 'ok', 'cancel' ];
/**
 * @property {array} replyOptions All the possible reply options for a SmartMessage
 */
akioma.SmartMessage.aTempSmartMessages = [];
/**
 * @property {array} replyOptions All the possible reply options for a SmartMessage
 */
akioma.SmartMessage.serviceURI = '/web';
