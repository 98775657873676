

// ********************** data grid col *****************
$.extend({
  /**
   * SwatGridCol
   * @class  ak_datagridcol
   * @param  {object} options Repository Attributes for SwatGridCol
   * @param {string} options.FilterOperator Specify Filter as a string. Options are:
   *
   * ```'eq', '=', 'neq','ne','<>', 'startswith', 'begins', 'contains', 'matches', 'beginsmatches', 'ends', 'gte', 'ge', '>=', 'gt', '>', 'lte','le','<=','lt','<','and','or'```
   * .. or you can specify an event eg. ```$ akioma.getFilterOpSample(self)```.
   * Code Sample:
   * ```
   * akioma.getFilterOpSample = function(oCol){
   *    console.log(['getFilterOp', oCol]);
   *    var oParent = oCol.parent,
   *        cFilter = 'eq';
   *
   *    if(oParent.opt.name == 'AdresseGrid')
   *        cFilter = 'startswith';
   *
   *    // return the filter to use per column
   *    return cFilter;
   * };
   * ```
   * @param {string} options.objectName New Object name for grid column.
   * @param {string} options.align Specifies text alignment in the column. Possible values are: right, left, center, default.
   <br> When using default, the default values are: 'right' for date, integer, decimal; 'center' for logical; 'left' for all other column types.
    * @param {string} options.CanSort Set to FALSE if this field should not be used to sort the data object query.
    * @param {string} options.Width Column width.
    * @param {string} options.DATA-TYPE Specify column data type.
    * @param {string} options.FolderWindowToLaunch Window to launch from a grid column.
    * @param {string} options.updateRecordContainer name of repository object to be called for updating records or "#dynamic" for opening it with Hdl as the container name.
    * @param {string} options.LABEL The label of the grid column.
    * @param {string} options.EventClick Code executed on click event.
    * @param {string} options.EventEntry Code executed on focus event.
    * @param {string} options.EventLeave Code executed on blur/leave event.
    * @param {string} options.EventAkValidate
    * @param {string} options.cacheStrategy Specify type of cache strategy to use. Use <code>client</code> for saving in the local store or empty for disabling the local store cache.
    * @param {string} options.extendedFormat Specifies different formats based on the column type.
    * <br> <b> Logical columns </b> - defines the font-icons used for logical_filter columns. Pipe-separated list of font-icons: emptyIcon|trueIcon|falseIcon|nullIcon|filterTrueIcon|filterFalseIcon. Every icon is optional. The first four icons refer to the rows cells, the last two icons refer to the column header cells. The icons also support:
    * <br> 1. Css attributes, defined like this: fa fa-user#color:red
    * <br> 2. Css classes, defined like this: fa fa-user#_style:module_prod
    * <br> 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod
    * <br> <b> Date/Datetime columns </b> - defines a custom date/datetime format for the column. More details can be found on the Confluence page: https://help.build.one/display/AKDOC/Date+and+Datetime+fields+formatting
    * <br> <b> Integer/Decimal columns </b> - defines a custom numeric format for the column. Format is numberFormat|groupSep|decSep. More details can be found on the Confluence page: https://help.build.one/display/AKDOC/Numeric+fields+formatting
    * @param {boolean} options.multiple Specifies single selection (false) or multiple selection (true). Default is false.
    * @param {boolean} options.delay The delay to use in milliseconds between the autocomplete search BusinessEntity requests, defaults to 350 ms if not specified
    * @param {boolean} options.template Can be used for two different use cases:
    * <br> 1. Defining the dropdown template for the dynSelect filter
      <br> 2. Defining the column cells template. It can be either a simple template defined in-line (e.g. {{selfdesc}} -- {{selfno}} ) or a more complex template defined in a server file (e.g /template/itemFlags.html). In this case,'opt.VisualizationType = htmlTemplate' needs to be set as well.
    * @param {boolean} options.allowClear Applies for dynSelect cells. Specifies if the cell values can be removed or not.
    * @param {string} options.LIST-ITEM-PAIRS Used in dynSelect filter and cells. Comma-separated list of options to be loaded in dynSelect. Format is: desc1,key1,desc2,key2,desc3,key3, where desc[i],key[i] will form an option with value=key and text=desc
    * @param {boolean} options.lookupKeyField Used in dynSelect filter and cells. Defines the DynSelect BE field used for displaying the value in dynSelect, when selecting a record.
    * @param {boolean} options.lookupKeyValueColumn Used in dynSelect filter. Defines the DynSelect BE field used for filtering.
    * @param {boolean} options.lookupKeyValueBinding Used in dynSelect cells. Defines the Grid BE field used for displaying the value in dynSelect.
    * @param {boolean} options.lookupFields Used in dynSelect cells. Comma-separated list of fields from the DynSelect BE which will be saved. When saving data from a listItemPairs dynSelect, the fields can be either 'listItemKey' or 'listItemDesc'
    * @param {boolean} options.lookupControls Used in dynSelect cells. Comma-separated list of fields from Grid BE in which the values of the fields defined by ’lookupFields ’ will be saved (1:1 Mapping to ‘lookupFields’)
    * @param {string} options.AxLookupDialog Used in dynSelect filter and cells. Opens a dialog to choose a record within the dynSelect. The dialog can be triggered using the arrow button in the dynSelect.
    * <br> For multiselect filters, it's also possible to select multiple records from the dialog, by holding SHIFT/CTRL key.
    * @param {string} options.filter This allows individual dynSelect filters on any column (aklink, htmlTemplate). Format is 'object:<master_name>'.
    * @param {string} options.multiDelimiter Specifies the delimiter for description fields.
    * @param {string} options.multipleBehavior Specifies in which cases the MULTIPLE attribute will be applied. Possible values are:
    * <br> 1. standard - the 'MULTIPLE' attribute will be applied for the filter only. This is also the default value.
    * <br> 2. cell - the 'MULTIPLE' attribute will be applied for the cells only
    * <br> 3. filter+cell - the 'MULTIPLE' attribute will be applied for both filter and cells
    * @fires ak_datagridcol2#EventAkValidate
    */
  ak_datagridcol2: function(options) {
    const oSelf = this;

    this.opt = $.extend({}, options.att);
    this.parent = options.parent;
    this.security = {};
    this._enabled = this.opt.enabled;

    if (oSelf.opt.dynSelect) {
      const oDynSelect = oSelf.opt.dynSelect;
      $(oSelf.select2).data('bMakeRequest', true);

      akioma.createBusinessEntity(this, oDynSelect);

      oSelf.opt.lookupDialog = oDynSelect.lookupDialog;

      oSelf.tokenSeparators = [];
      oSelf.maximumSelectionLength = 0;
      if (oDynSelect.maximumSelectionLength)
        oSelf.maximumSelectionLength = oDynSelect.maximumSelectionLength;
      if (oDynSelect.tokenSeparators)
        oSelf.tokenSeparators = oDynSelect.tokenSeparators.split('|');
      if (oDynSelect.initialFetch)
        oSelf.opt.initialFetch = oDynSelect.initialFetch;
      if (oDynSelect.onBeforeFetch)
        oSelf.opt.onBeforeFetch = oDynSelect.onBeforeFetch;

      if (oSelf.opt.MULTIPLE) {
        if (oSelf.opt.LookupFields) {
          oSelf.lookupFields_multiple = {};
          const fields = oSelf.opt.LookupFields.split(',');
          for (const field of fields)
            oSelf.lookupFields_multiple[field] = [];

          const index = oSelf.opt.LookupControls.split(',').indexOf(oSelf.opt.LookupKeyValueBinding);
          oSelf.lookupDisplayField = (index !== -1) ? fields[index] : '';
          oSelf.lookupKeyField = fields[0];
        }
        oSelf.multiDelimiter = oSelf.opt.multiDelimiter || ',';
      }
    }

    if (oSelf.opt.colType == 'ron' || oSelf.opt.colType == 'edn') {
      oSelf.numeric = {};
      const cFormat = app.sessionData.globalNumericFormat || akioma.globalData.globalNumericFormat.numberFormat;
      const cDecSep = app.sessionData.globalNumericDecSep || akioma.globalData.globalNumericFormat.decSep;
      const cGroupSep = app.sessionData.globalNumericGroupSep || akioma.globalData.globalNumericFormat.groupSep;
      const cIntFormat = app.sessionData.globalNumericFormat.split('.')[0] || '0,000';
      if (oSelf.opt.extendedFormat) {
        try {
          // At this point this.opt.extendedFormat contains only ' and no "
          // First replace all ' to " so we can JSON parse, then """ -> "'" so we support ' at the cost of not supporting ".
          const parsedJSON = JSON.parse(this.opt.extendedFormat.replace(/'/g, '"').replace(/"""/g, '"\'"'));
          oSelf.numeric.cFormat = (parsedJSON.format) ? parsedJSON.format : cFormat;
          oSelf.numeric.groupSep = (parsedJSON.groupSep) ? parsedJSON.groupSep : cGroupSep;
          oSelf.numeric.decSep = (parsedJSON.decSep) ? parsedJSON.decSep : cDecSep;
          oSelf.numeric.maxLength = (parsedJSON.maxLength) ? parsedJSON.maxLength : 0;
          oSelf.numeric.minVal = parseFloat(parsedJSON.minVal) ? parseFloat(parsedJSON.minVal) : null;
          oSelf.numeric.maxVal = parseFloat(parsedJSON.maxVal) ? parseFloat(parsedJSON.maxVal) : null;
        } catch (e) {
          /* If extendedFormat can't be parsed as JSON we assume its pipes */
          const aParts = oSelf.opt.extendedFormat.split('|');
          oSelf.numeric.cFormat = (aParts[0]) ? aParts[0] : cFormat;
          oSelf.numeric.groupSep = (aParts[1] != undefined) ? aParts[1] : cGroupSep;
          oSelf.numeric.decSep = (aParts[2] != undefined) ? aParts[2] : cDecSep;
          oSelf.numeric.maxLength = (aParts[3] != undefined) ? aParts[3] : 0;
          oSelf.numeric.minVal = parseFloat(aParts[4]) ? parseFloat(aParts[4]) : null;
          oSelf.numeric.maxVal = parseFloat(aParts[5]) ? parseFloat(aParts[5]) : null;
        }

        if (oSelf.numeric.cFormat.startsWith('+')) {
          oSelf.numeric.cFormat = oSelf.numeric.cFormat.substr(1);
          oSelf.numeric.bPositive = true;
        }
      } else {
        oSelf.numeric.cFormat = (oSelf.opt.dataType == 'integer') ? cIntFormat : cFormat;
        oSelf.numeric.groupSep = cGroupSep;
        oSelf.numeric.decSep = (oSelf.opt.dataType == 'integer') ? '' : cDecSep;
      }

      const cCurrSymbol = (oSelf.opt.isCurrency && !oSelf.opt.extendedFormat) ? app.sessionData.mainCurrencySymbol : '';
      oSelf.setNumberFormat(`${cCurrSymbol} ${oSelf.numeric.cFormat}`, oSelf.numeric.groupSep, oSelf.numeric.decSep);

      if (oSelf.opt.showZeroAsEmpty == '')
        oSelf.opt.showZeroAsEmpty = oSelf.parent.opt.showZeroAsEmpty;
      oSelf.opt.showZeroAsEmpty = (oSelf.opt.showZeroAsEmpty == 'true') ? true : false;

    }

    if (oSelf.opt.colType == 'handlebars') {
      if (oSelf.opt.template[0] == '/') {
        dhtmlxAjax.get(oSelf.opt.template, r => {
          const cTemplate = r.xmlDoc.response;
          oSelf.templateResponse = cTemplate;
        });
      } else
        oSelf.templateResponse = oSelf.opt.template;
    }

    if (oSelf.opt.colType == 'datetime') {
      oSelf.oDate = {};
      oSelf.oDate.cServerFormat = oSelf.parent.dhx.calFormats[oSelf.opt.name.toLowerCase()];
      oSelf.oDate.cFormat = '%Y-%m-%d';
      if (oSelf.opt.extendedFormat)
        oSelf.oDate.cFormat = oSelf.opt.extendedFormat;
      else
        oSelf.oDate.cFormat = (oSelf.oDate.cServerFormat.indexOf('%H') > -1) ? window.dhx.dateTimeFormat[window.dhx.dateLang] : window.dhx.dateFormat[window.dhx.dateLang];

      oSelf.oDate.cInputMaskFormat = akioma.date.convertDateFormat(oSelf.oDate.cFormat);
      oSelf.oDate.cMaxDate = akioma.date.getMax_MinDate(oSelf.oDate.cInputMaskFormat, true);
      oSelf.oDate.cMinDate = akioma.date.getMax_MinDate(oSelf.oDate.cInputMaskFormat);
    }

    this.getSortPhrase();

    if (this.opt.dataField && this.parent.columns)
      this.parent.columns[this.opt.dataField.toLowerCase()] = this;
  }
});

// methods for datagridcol
$.ak_datagridcol2.prototype = {
  /**
   * Sets dynselect batch size
   * @private
   * @instance
   * @memberOf ak_datagridcol
   */
  _setBatchSize: function() {
    const oSelf = this;
    const oAttributes = (oSelf.bCell) ? oSelf.opt : oSelf.oAttributes;

    if (!oSelf.bStopInitialFetch && oAttributes.initialFetch.indexOf('initFetch:All') > -1)
      oSelf.businessEntity.batchSize.top = 1000;
    else
      oSelf.businessEntity.batchSize.top = oAttributes.dynSelect.rowsToBatch;

  },

  _validateDate: function(cDate, bFilter) {
    const oSelf = this;
    const cFormat = (bFilter) ? '%d.%m.%Y' : oSelf.oDate.cFormat;
    let bValid = true;

    if (cDate) {
      // bugfix for months
      let iMonthIndex = cFormat.indexOf('%m');
      const iYearIndex = cFormat.indexOf('%Y');
      if (iYearIndex < iMonthIndex)
        iMonthIndex = iMonthIndex + 2;
      const cMonth = cDate.substring(iMonthIndex, iMonthIndex + 2);
      const iMonth = Number(cMonth);
      bValid = (iMonth && iMonth < 13) ? true : false;
    }

    const oInput = (oSelf.bTo) ? oSelf.dhx.children[1] : oSelf.dhx.children[0];
    let oDhxCalendar;
    if (oSelf.gridCalendar)
      oDhxCalendar = oSelf.gridCalendar;
    else
      oDhxCalendar = (oSelf.bTo) ? oSelf.gridCalendarTo : oSelf.gridCalendarFrom;

    if (!bValid && !/[a-zA-Z]/.test(cDate)) {
      $(oInput).addClass('gridValidateError');
      oSelf.bError = true;
      return false;
    } else {
      const cMessage = (cDate) ? oDhxCalendar._strToDate(cDate, cFormat) : '';
      if (cMessage == 'Invalid Date') {
        // Autofill missing date parts with current date
        const oNewDate = akioma.date.fillDate('dd.mm.yyyy', cDate);
        if (bFilter) {
          oInput.value = oDhxCalendar._dateToStr(oNewDate, cFormat);
          oDhxCalendar.setDate(oNewDate);
        }
      }
      $(oInput).removeClass('gridValidateError');
    }
    return true;
  },

  /**
   * Gets the enabled attribute which is used in the grid
   * @instance
   * @memberOf ak_datagridcol
   * @return {boolean}
   */
  isEnabled() {
    return this._enabled;
  },

  /**
   * Sets the enabled attribute which is used from the grid
   * @param  {boolean} bEnable true/false
   * @instance
   * @memberOf ak_datagridcol
   */
  setEnabled(bEnable) {
    const oGrid = this.parent.dhx;
    const colIndex = oGrid.getColIndexById(this.opt.dataField);

    this._enabled = bEnable;

    if (bEnable)
      oGrid.setColumnExcellType(colIndex, this.opt.createType); // do this in case a column is disabled by default
    else {
      const oRowId = oGrid.getSelectedRowId();
      const oCell = oGrid.cells(oRowId, colIndex);

      switch (this.opt.createType) {
        case 'dynselect':
          oCell.clearEditCells();
          break;
        case 'aklink':
          oGrid.setColumnExcellType(colIndex, 'ro');
          break;
        default:
          oGrid.editStop();
      }
    }
  },

  /**
   * Sets the numeric format
   * @param  {String} cFormat  The numeric format (e.g 0,000.00)
   * @param  {String} groupSep The group separator
   * @param  {String} decSep   The decimal separator
   * @instance
   * @memberOf ak_datagridcol
   */
  setNumberFormat: function(cFormat, groupSep, decSep) {
    const oSelf = this;
    const iIndex = oSelf.parent._initColIds.indexOf(oSelf.opt.dataField);
    if (oSelf.opt.colType == 'ron' || oSelf.opt.colType == 'edn')
      oSelf.parent.dhx.setNumberFormat(cFormat, iIndex, decSep, groupSep);

  },

  /**
   * Changes filter icon for logical column
   * @param {HTMLElement} oHeader HTML Element where filter is located.
   * @param {string} cCurrentState Current state of filter.
   * @param {Object[]} aExtendedFormat Array of extendedFormats. One of these icons will be used for replacing the filter icon.
   * @private
   * @instance
   * @memberOf ak_datagridcol
   */
  changeLogicalFilterIcon: function(oHeader, cCurrentState, aExtendedFormat) {
    const oSelf = this;

    let oFilterIcon, oNewIcon, cNewState;
    switch (cCurrentState) {
      case '':
        oFilterIcon = aExtendedFormat['filterTrue'];
        oNewIcon = akioma.icons.replaceIcon(oFilterIcon);
        cNewState = 'yes';
        break;
      case 'yes':
        oFilterIcon = aExtendedFormat['filterFalse'];
        oNewIcon = akioma.icons.replaceIcon(oFilterIcon);
        cNewState = 'no';
        break;
      case 'no':
        oFilterIcon = aExtendedFormat['empty'];
        oNewIcon = akioma.icons.replaceIcon(oFilterIcon);
        cNewState = '';
        break;
    }

    const oFirstIcon = $(oHeader).find('i').eq(0);
    const oSecondIcon = $(oHeader).find('i').eq(1);
    $(oHeader).removeClass('stackedIcon');

    $(oFirstIcon).removeClass().addClass(`${oNewIcon.icon1} ${oNewIcon.style1}`);
    oFirstIcon[0].style.cssText = oNewIcon.attributes1;
    $(oSecondIcon).removeClass().addClass('fa-stack-1x fa-inverse');
    if (oNewIcon.icon2) {
      $(oSecondIcon).addClass(`${oNewIcon.icon2} ${oNewIcon.style2}`);
      oSecondIcon[0].style.cssText = oNewIcon.attributes2;
      $(oFirstIcon).addClass('fa-stack-2x fa-2x fa-fw');
      $(oHeader).addClass('stackedIcon');
    } else
      $(oFirstIcon).addClass('fa-stack-lg fa-lg fa-fw');


    oSelf.cLogicalState = cNewState;
    oHeader.value = cNewState;
  },

  /**
   * closeEvent in dynselect filter
   * @param  {Object} e event
   * @private
   * @instance
   * @memberOf ak_datagridcol2
   * @return {Promise}
   */
  closeResults: function(e, tag, self) {
    const oSelf = (self) ? self : this;
    $(oSelf.select2).attr('tabindex', '0');
    $(oSelf.select2).focus();

    if (oSelf.select2.data().select2.dropdown.$search)
      akioma.swat.MasterLayout.enableLastFocusTrap();
  },

  /**
   * Parse dynselect data for preloading filter options and cells descriptions, if no cache then it will request for
   * data and save in the local store if the cacheStrategy is set to 'client'
   * @param  {Object} res IndexedDb PromiseCache result
   * @param {String} timestampLastChanged The returned GetTimeLastChanged
   * @param  {Deferred} def The promise to resolve for final data display, waiting for options load
   * @private
   * @instance
   * @memberOf ak_datagridcol2
   * @return {Promise}
   */
  _parseLoadedDynselect: function(res, def, timestampLastChanged) {
    const oSelf = this,
      cCacheStrategy = oSelf.opt.cacheStrategy.toLowerCase(),
      bUseCache = (cCacheStrategy === 'client');

    // if there was an entry in the indexeddb, compare if saved timestamp is older than the timestampLastChanged
    if (bUseCache && res && res.data && timestampLastChanged === res.timestamp && !isNull(timestampLastChanged)) {
      oSelf.rows = res.data;
      oSelf.businessEntity.batchSize.top = 10;
      def.resolve(oSelf.rows);
    } else {
      // load from BE, preload data
      oSelf.businessEntity.addAfterCatalogAdd(() => {
        oSelf.businessEntity.setNamedQueryParam('AutoCompleteSearch', 'SearchKey', '', 'character');
        oSelf.businessEntity.aAfterFillOnce = [];

        oSelf.businessEntity.addAfterFillOnceCallback(rows => {
          oSelf.businessEntity.batchSize.top = 10;

          const aRecs = [];
          rows.each(item => {
            aRecs.push(item);
          });
          oSelf.rows = aRecs;

          // add to indexdb cache
          // only if cacheStrategy is 'client'
          if (bUseCache)
            akCacheDb.addToCache(`${oSelf.parent.opt._ObjectName}_${oSelf.opt._InstanceName}`, timestampLastChanged, aRecs);

          def.resolve(oSelf.rows);
        });
        oSelf.businessEntity.openQuery({ applyFilters: true });

      });
    }


  },

  setDefaultAttributes: function() {
    const oSelf = this;
    const oAttributes = (oSelf.bCell) ? oSelf.opt : oSelf.oAttributes;

    if (!oSelf.businessEntity) {
      const aListItemFields = [ 'listitemkey', 'listitemdesc', 'listitemshortdesc' ];
      if (aListItemFields.indexOf(oAttributes.dynSelect.lookupKeyField.toLowerCase()) == -1)
        oAttributes.dynSelect.lookupKeyField = 'listItemDesc';
    }
  },

  displayMultiFields: function(oSelected) {
    const oSelf = this;
    let cRes = '';
    let bField = false;

    if (oSelf.oAttributes.dynSelect.lookupKeyField) {
      const aFields = oSelf.oAttributes.dynSelect.lookupKeyField.split(',');
      for (let i = 0; i < aFields.length; i++) {
        const cField = aFields[i];
        if (cField.indexOf('\'') == -1) {
          if (!isNull(oSelected[cField])) {
            cRes += oSelected[cField];
            bField = true;
          }
        } else
          cRes += cField.replace(/["']/g, '');

      }
    }

    if (bField == false)
      cRes = '';
    oSelf.cLookupToDisplay = cRes;
    return cRes;
  },

  /**
   * Checkes if a dynSelect cell is multiple selection or not. Returns true if multiple, false otherwise
   * @instance
   * @memberOf ak_datagridcol2
   * @return {boolean}
   */
  isCellMultiple() {
    if ((this.opt.multipleBehavior.toLowerCase() == 'cell' || this.opt.multipleBehavior.toLowerCase() == 'filter+cell') && this.opt.MULTIPLE)
      return true;
    return false;
  },

  /**
   * Checks if the dynSelect is a checkbox selection (option elements containing checkbox for selection)
   *
   * @instance
   * @memberof ak_datagridcol2
   * @return {boolean|null}
   */
  getCheckboxSelection() {
    // Nullity safety check to handle any unforseen scenarios where options are null.
    if (isNull(this.opt)) return null;

    if (this.opt.template)
      return this.opt.template.toLowerCase() == 'genericautocompletesearchtemplatedynselect_checkbox';
    else
      return null;

  },

  /**
   * Change selection event - called on dynselect cell AND filter
   * @param {object} e
   * @param {HtmlDomElement} tag
   * @param {ak_grid} self
   * @param {DynSelect}
   * @memberof ak_datagrid
   * @instance
   */
  selectionChanged: function(e, tag, self, dynSelectControl) {
    const oSelf = (self) ? self : this;
    const oGrid = this.parent;
    let data = jQuery.extend(true, {}, e.params.data);
    const oAttributes = (oSelf.bCell) ? oSelf.opt : oSelf.oAttributes;
    const lookupKeyValueColumn = oAttributes.dynSelect.lookupKeyValueColumn;
    const multiple = oAttributes.dynSelect.multiple;
    let newValue;

    // handling for dynselect cells editing
    if (oSelf.bCell) {
      if (oSelf.bNewValue) {
        data[lookupKeyValueColumn] = data.id;
        oSelf.cLookupToDisplay = data.id;
        newValue = data.id;
      } else if (oSelf.businessEntity) {
        oSelf.businessEntity.dhx.setCursor(data.id);
        newValue = data[lookupKeyValueColumn];
      } else if (oGrid.listItemPairs[oSelf.opt.dataField])
        newValue = data.listItemKey;


      if (oSelf.isCellMultiple())
        data = dynSelectControl._multipleUpdateLookupFields(data);

      const oCurItem = oGrid.dataSource.getSelectedRecord();
      dynSelectControl._updateLookupControls(oGrid, oCurItem, data);

      oGrid.dhx.callEvent('onEditCell', [ 2, oGrid.dhx.getSelectedRowId(), tag.cellIndex, newValue ]);
      oSelf.bSelected = false;

      // dirty flag for dynselect
      const oDataSource = oSelf.parent.dataSource;

      if (oDataSource) {
        const oItem = oDataSource.dhx.item(oDataSource.dhx.getCursor());
        const cGeneratedRowID = oSelf.parent.computeRowAkIdAttribute(oItem);

        oSelf.parent._commit('ADD_CHANGED_ROW', cGeneratedRowID);
      }
    } else { // handling for dynselect filter value change
      if (e.target)
        oGrid.removeEmptyTag_dynSelect(e.target.nextSibling);
      if (multiple && oSelf.bNewValue)
        $(oSelf.dhx).val('');
      const cCurMultiFilterColName = oGrid.aDynSelectFilters[tag.index].colname;

      let oSelectedItem;
      if (oSelf.businessEntity) {
        oSelectedItem = {
          'id': (data[lookupKeyValueColumn] != undefined) ? data[lookupKeyValueColumn] : data.id,
          'desc': oSelf.cLookupToDisplay || data.id
        };
      } else {
        oSelectedItem = {
          'id': data.listItemKey || data.id,
          'listItemKey': data.listItemKey || data.id,
          'listItemDesc': data.listItemDesc || data.id,
          'listItemShortDesc': data.listItemShortDesc || data.id,
          'listItemText': data.listItemText || data.id
        };
      }

      if (multiple == false)
        oSelf.clearSelect(e, tag);

      oGrid.setDynSelectFilters(cCurMultiFilterColName, oSelectedItem);
      const joinedVals = oGrid.aDynSelectStatus.aSelectedValues[cCurMultiFilterColName].join('|');
      $(tag).find('select.dynSelect')[0].dataset.selectVal = joinedVals;

      if (oAttributes.dynSelect.validateEvent)
        app.controller.callAkiomaCode(oSelf, oAttributes.dynSelect.validateEvent);

      $(tag).find('span.select2-selection--multiple').scrollTop(0);
    }
  },

  /**
   * Remove selection event - called on dynselect cell AND filter
   * @param {object} e
   * @param {HtmlDomElement} tag
   * @param {ak_grid} self
   * @param {DynSelect}
   * @memberof ak_datagrid
   * @instance
   */
  selectionRemoved: function(e, tag, self) {
    const oSelf = (self) ? self : this;
    const oGrid = this.parent;
    const oAttributes = (oSelf.bCell) ? oSelf.opt : oSelf.oAttributes;
    const lookupKeyValueColumn = oAttributes.dynSelect.lookupKeyValueColumn;
    const oFilter = this.parent.filter;
    oSelf.bRemove = true;
    oSelf.bRemoveVal = true;

    // handling for dynselect cells editing
    if (oSelf.bCell) {
      let data;
      if (oSelf.listItemPairs)
        data = jQuery.extend(true, {}, e.params.data);
      if (data == undefined)
        data = {};

      // select row
      if (e.target.dataset) {
        const cRowId = e.target.dataset.rowId;
        if (oGrid.dataSource.view.toLowerCase() !== 'businessentity2')
          oGrid.dataSource.setIndex(cRowId);
        else
          oGrid.dataSource.setIndex(cRowId, oSelf.opt.entityName);

        if (oSelf.isCellMultiple()) {
          let index = -1;
          if (e.params.data[oSelf.lookupKeyField])
            index = oSelf.lookupFields_multiple[oSelf.lookupKeyField].indexOf(e.params.data[oSelf.lookupKeyField]);
          else
            index = oSelf.lookupFields_multiple[oSelf.lookupDisplayField].indexOf(e.params.data.id);

          data = oSelf.dynSelectControl._multipleUpdateLookupFields(data, index);

          // dirty flag for dynselect
          const oDataSource = oSelf.parent.dataSource;
          if (oDataSource) {
            const oItem = oDataSource.dhx.item(oDataSource.dhx.getCursor());
            const cGeneratedRowID = oSelf.parent.computeRowAkIdAttribute(oItem);
            oSelf.parent._commit('ADD_CHANGED_ROW', cGeneratedRowID);
          }
        }

        oGrid.dhx.selectRowById(e.target.dataset.rowId, true, false, true);
        oSelf.bNewValue = false;
        const oCurItem = oGrid.dataSource.getSelectedRecord();
        oSelf.dynSelectControl._updateLookupControls(oGrid, oCurItem, data, 'remove');
      }
    } else { // handling for dynselect filter value change

      const deleteFilter = (list, filterVal, bMode) => {
        if (bMode) {
          for (let i = 0; i < list.length; i++) {
            if (list[i].id == filterVal)
              list.splice(i, 1);
          }
        } else {
          for (let i = 0; i < list.length; i++) {
            if (list[i] == filterVal)
              list.splice(i, 1);
          }
        }
      };

      const cCurMultiFilterColName = oGrid.aDynSelectFilters[tag.index].colname;
      const id = e.params.data[lookupKeyValueColumn] || e.params.data.id;
      oFilter[cCurMultiFilterColName].splice(oFilter[cCurMultiFilterColName].indexOf(id), 1);
      deleteFilter(oGrid.aDynSelectStatus.aSelectedItems[cCurMultiFilterColName], id, true);
      deleteFilter(oGrid.aDynSelectStatus.aSelectedValues[cCurMultiFilterColName], id);
      const joinedVals = oGrid.aDynSelectStatus.aSelectedValues[cCurMultiFilterColName].join('|');
      $(tag).find('select.dynSelect')[0].dataset.selectVal = joinedVals;
      oSelf.bRemoveVal = true;

      if (oAttributes.dynSelect.validateEvent)
        app.controller.callAkiomaCode(oSelf, oAttributes.dynSelect.validateEvent);
    }
  },


  /**
   * Clearing selection event - called on dynselect cell AND filter
   * @param {object} e
   * @param {HtmlDomElement} tag
   * @param {ak_grid} self
   * @param {DynSelect}
   * @memberof ak_datagrid
   * @instance
   */
  clearSelect: function(e, tag, self) {
    const oSelf = (self) ? self : this;
    const oGrid = this.parent;

    // handling for dynselect filter value change
    if (oSelf.bCell == undefined) {
      const cCurMultiFilterColName = oGrid.aDynSelectFilters[tag.index].colname;
      oGrid.aDynSelectStatus.aSelectedValues[cCurMultiFilterColName] = [];
      oGrid.aDynSelectStatus.aSelectedItems[cCurMultiFilterColName] = [];

      const index = $(tag).closest('td')[0].cellIndex;
      oGrid.setHeaderFieldFocus(index);

    } else {
      // handling for dynselect cells editing
      const val = $(tag).val();
      try {
        if (val !== '') {
          const oItem = oGrid.dataSource.dhx.item(oGrid.dataSource.dhx.getCursor());
          const cGeneratedRowID = oGrid.computeRowAkIdAttribute(oItem);
          oGrid._commit('ADD_CHANGED_ROW', cGeneratedRowID);

          if (oSelf.isCellMultiple())
            oSelf.dynSelectControl._multipleClear();
        }
      } catch (e) {
        console.warn(e);
      }
    }
  },

  /**
   * Opening dropdown event, triggered before the dropdown is opened. Can prevent the dropdown opening - called on dynselect cell AND filter
   * @param {object} e
   * @param {HtmlDomElement} tag
   * @param {ak_grid} self
   * @param {DynSelect}
   * @memberof ak_datagrid
   * @instance
   */
  openingResults: function(e, tag, self) {
    const oSelf = (self) ? self : this;
    const originalEvent = e.params.args.originalEvent;
    const oAttributes = (oSelf.bCell) ? oSelf.opt : oSelf.oAttributes;
    oSelf.bSelected = false;

    // Prevents request in case of chooseWindow or removing selection
    $(oSelf.select2).data('bMakeRequest', true);

    if (oSelf.bRemove) {
      $(oSelf.select2).data('bMakeRequest', false);
      oSelf.bRemove = false;
    }

    if ($(tag).data('unselecting')) {
      $(tag).removeData('unselecting');
      e.preventDefault();
    }
    if ($(tag).find('select.dynSelect').data('bClose') && oAttributes.dynSelect.multiple)
      e.preventDefault();

    if (oSelf.opt.lookupDialog && originalEvent) {
      const cElem = $(originalEvent.target).prop('tagName');
      const cClass = $(originalEvent.target).attr('class');
      if (cElem == 'SPAN' && cClass === 'select2-selection__lookup') {

        oSelf.dynSelectControl.launchLookupDialog();
        $(oSelf.select2).data('bMakeRequest', false);
        e.preventDefault();
      }
    }
  },

  /**
   * Open dropdown event, triggered after the dropdown is opened - called on dynselect cell AND filter
   * @param {object} e
   * @param {HtmlDomElement} tag
   * @param {ak_grid} self
   * @param {DynSelect}
   * @memberof ak_datagrid
   * @instance
   */
  openResults: function(e, tag, self) {
    const oSelf = (self) ? self : this;

    if (oSelf.bCell)
      $('span.select2-dropdown').addClass('custom-dropdown-cell');
    else {
      $(tag).find('span.select2-selection--multiple').scrollTop(0);
      $('span.select2-dropdown').addClass('custom-dropdown');
    }

    // SWAT-4467 set timeout to 100 to fix focus-trap library issues; focus-trap Line 120
    if (oSelf.select2.data().select2.dropdown.$search) {
      akioma.swat.MasterLayout.disableLastFocusTrap({ setReturnFocus: oSelf.select2.data().select2.dropdown.$search[0] });

      if (!tag.firstChild.classList.contains('dynSelectHeaderFilter')) { // opened row result list; manually focus here
        setTimeout(() => {
          oSelf.select2.data().select2.dropdown.$search.trigger('focus');
        }, 100);
      }
    }
  },

  selectingResult: function(e, tag, self) {
    const oSelf = (self) ? self : this;
    if (oSelf.businessEntity && oSelf.businessEntity._pendingrequest)
      e.preventDefault();
  },

  unselectingResult: function(e, tag) {
    $(tag).data('unselecting', true);
  },

  // edit cell ***********************
  editCell: function(cEvent) {
    // check if there is a code for this event
    if (this.opt[cEvent]) {
      try {
        app.controller.callAkiomaCode(this, this.opt[cEvent]);
      } catch (e) {
        akioma.log.error(`Error while executing event: ${cEvent} in col '${this.opt.dataField}': ${e.message}`);
      }
    }
  },

  // get sort phrase attribute setting
  getSortPhrase: function() {
    let cSortPhrase = this.opt.sortPhrase;
    const oSort = null;
    if (cSortPhrase) {
      cSortPhrase = this.opt.sortPhrase;
      this.parent.aSortPhrases[this.opt.dataField] = cSortPhrase;
    }
    return oSort;
  },

  /**
   * Gets the value of a filter (input filters only)
   * @instance
   * @memberOf ak_datagridcol2
   * @return {string}
   */
  getValueFilter: function() {
    const filter = this.dhx;
    return filter.value;
  },

  /**
   * Sets the value of a filter (input filters only)
   * @instance
   * @param {String} value The new value
   * @memberOf ak_datagridcol2
   */
  setValueFilter: function(value) {
    const filter = this.dhx;
    filter.value = value;
    if (this.opt.colType == 'ch')
      this.setCheckboxFilter(value);

  },

  /**
   * Sets the value of a checkbox filter
   * @param {string} value
   * @instance
   * @memberof ak_datagridcol2
   */
  setCheckboxFilter: function(value) {
    const index = this.parent._initColIds.indexOf(this.opt.name.toLowerCase());
    if (value == 'true' || value == 'yes')
      value = '';
    else if (value == 'false' || value == 'no')
      value = 'yes';

    this.changeLogicalFilterIcon(this.dhx, value, this.parent.aExtendedFormat[index]);
  },

  // get value **************
  getValue: function() {
    const oGrid = this.parent.dhx,
      cRow = oGrid.getSelectedRowId(),
      iCol = oGrid.getColIndexById(this.opt.dataField),
      oCell = oGrid.cellById(cRow, iCol);

    return oCell.getValue();
  },

  // get value from server *************
  getValueFromServer: function(cValue, oLookup, cType) {
    const oData = getLookupValue({
        value: cValue,
        tableName: this.opt.lookup_tableName,
        extHdl: this.opt.lookup_extHdl,
        extKey: this.opt.lookup_extKey
      }),
      oParent = this.parent,
      oSource = oParent.dataSource;

    oData.key = (oData.value == '?') ? '?' : cValue;

    if (cType == 'cell') {
      oParent.setFieldValue(this.opt.dataField, oData.value);
      oParent.setFieldValue(this.opt.lookup_showDesc, oData.valueDesc);
      oSource.setFieldValue({ name: this.opt.lookup_showKey, value: oData.key, state: true });
    } else
      return oData;
  },

  // get ext value ****************
  getExtValue: function(oSource) {
    // get value
    const cValueHdl = oSource.getFieldValue(this.opt.lookup_extHdl),
      cValueKey = oSource.getFieldValue(this.opt.lookup_extKey),
      cValueDesc = oSource.getFieldValue(this.opt.lookup_extDesc);

    // get datasource of grid
    const oParent = this.parent;
    oParent.dataSource.setFieldValue({ name: this.opt.lookup_showKey, value: cValueKey, state: 'update' });
    oParent.setFieldValue(this.opt.lookup_showDesc, cValueDesc);
    oParent.setFieldValue(this.opt.dataField, cValueHdl);
  },

  // getLookupKey
  getLookupKey: function(cIndex) {
    const oSource = this.parent.dataSource,
      cKey = oSource.getFieldValue(this.opt.dynSelect.lookupKeyValueBinding, cIndex);

    return cKey;
  },

  setLookupFilter: function(oSource) {
    // get filter field
    const oGrid = this.parent.dhx,
      iCol = oGrid.getColIndexById(this.opt.dataField),
      oInput = oGrid.getFilterElement(iCol),
      cValue = oSource.getFieldValue(this.opt.lookup_extKey),
      cValHdl = oSource.getFieldValue(this.opt.lookup_extHdl);

    oInput.value = cValue;
    oInput.valhdl = (cValHdl == '?') ? null : cValHdl;

    oGrid.filterByAll();
  },

  destroy: function() {
    $(this.select2).select2('destroy');
    if (this.businessEntity) {
      this.businessEntity.destroy();
      delete this.businessEntity;
    }
    if (this.gridCalendarFrom && this.gridCalendarTo) {
      this.gridCalendarFrom.unload();
      this.gridCalendarTo.unload();
    }
  }
};
