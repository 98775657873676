// ************************* ribbonselect ********************************
$.extend({
  ak_ribbonselectoption: function(options) {
    const defaults = {
      id: '',
      title: '',
      disabled: false,
      image: '',
      image2: ''
    };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    this.registerDynObject = true;
    this.useParentDynObjectLink = true;

    const oRibbonCtl = this.getAncestor('ribbon').dhx;
    const oSelectConf = oRibbonCtl._items[this.parent.opt.name].conf;

    if (!oSelectConf.data)
      oSelectConf.data = [];
    oSelectConf.data.push({
      id: this.opt.name,
      text: this.opt.text,
      img: this.opt.image
    });
  }
});


// methods for ribbon select
$.ak_ribbonselectoption.prototype = {

  // click *****************
  click: function(cId) {
    const oRibbon = akioma.getRibbon(this);

    if (oRibbon.ribbonMenuStructure != undefined) {
      const oMenu = oRibbon.ribbonMenuStructure.getMenuItemByCode(cId);
      oRibbon.ribbonMenuStructure.applyAction(oMenu.id, oRibbon, this);
      return true;
    }
  }
};
