dhtmlXForm.prototype.items.lookup = {
  render: function(item, data) {

    item._type = 'lookup';

    this.doAddLabel(item, data);

    const oSelf = $.getObjectByName({ id: data.userdata.id });

    item.akElm = oSelf;
    oSelf.dhx = item;

    let cTag = '';

    cTag = '<div class="dhxform_control">'
      + '<span>'
      + '<input type="text" class="lookupKey dhxform_textarea" ';
    if (oSelf.opt.disabled)
      cTag += ' disabled="true" ';
    cTag += ' />'
      + '<img src="/imgs/akioma/suchen.png" height="14" width="14" class="lookupImg" />'
      + '<input type="text" class="lookupDisplayed dhxform_textarea" disabled="true" />'
      + '</span>'
      + '</div>';

    // -> bind input to form
    $(cTag)
      .appendTo(item)
      .css({
        left: data.inputLeft,
        top: data.inputTop,
        width: data.inputWidth
      })
      .find('input.lookupKey')
      .focus(function() {
        oSelf.onFocus($(this).val());
      })
      .blur(function() {
        oSelf.getValueFromServer($(this).val());
      })
      .css({ width: '80px' })
      .end()
      .find('input.lookupDisplayed')
      .css({ width: `${parseInt(oSelf.opt.width) - 160}px` })
      .end()
      .find('img.lookupImg')
      .hover(
        function() {
          $(this)
            .css({ cursor: 'hand' });
        },
        function() {
          $(this)
            .css({ cursor: 'pointer' });
        }
      )
      .click(() => {
        oSelf.popupDialog();
      })
      .end()
      .get(0);

    const parentAkId = akioma.getForm(oSelf).akId;
    $(item.children[1]).attr('akId', `${parentAkId}-${data.name}`);
    $(item.children[1]).attr('akstyle', data.type);

    return this;
  },

  setValue: function(item, value) {
    item.akElm.showValue(value);
  },

  getValue: function(item) {
    return item.akElm.getValue();
  },

  setFocus: function(item) {
    $('input:first', item).focus();
  },

  getLookup: function(item) {
    return item;
  }
};
(function() {
  for (const a in { doAddLabel: 1, doAddInput: 1, destruct: 1, doUnloadNestedLists: 1, setText: 1, getText: 1, enable: 1, disable: 1, setWidth: 1, updateValue: 1 })
    dhtmlXForm.prototype.items.lookup[a] = dhtmlXForm.prototype.items.input[a];
})();
dhtmlXForm.prototype.getLookup = function(name) {
  return this.doWithItem(name, 'getLookup');
};
dhtmlXForm.prototype.setFormData_lookup = function(name, value) {
  return this.doWithItem(name, 'setValue', value);
};
dhtmlXForm.prototype.getFormData_lookup = function(name) {
  return this.doWithItem(name, 'getValue');
};


(function($) {

  // ********************* lookup ********************
  $.extend({
    /**
     * SwatLookup Control
     * @class ak_lookup
     * @param {Object} options Repository attributes for SwatLookup.
     * @param {boolean} options.CanSort Set to FALSE if this field should not be used to sort the data object query.
     * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
     * @param {string} options.align alignment of column. can be left, right or centered
     * @param {string} options.HELP WidgetAttributes Help
     * @param {number} options.ROW Row position.
     * @param {string} options.VisualizationType WidgetAttributes VisualizationType
     * @param {string} options.LABEL WidgetAttributes Label
     * @param {string} options.EventEntryType Language of "entry" trigger
     * @param {number} options.HEIGHT-CHARS Height in characters. This may currently be used when rendering some objects. There is no get function, use getHeight to retrieve the realized value from an object.
     * @param {boolean} options.VISIBLE WidgetAttributes Visible
     * @param {boolean} options.CHECKED
     * @param {string} options.TemplateFile The relative path and filename of the static object used as the template at design time
     * @param {string} options.InitialValue WidgetAttributes InitialValue
     * @param {string} options.EventEntry Event which should run on entry of a field
     * @param {string} options.LIST-ITEMS
     * @param {boolean} options.CanFilter Set to FALSE if this field should not be used to filter the data object query.
     * @param {string} options.ViewAs The 'ViewAs' definition  of the selection.&#10;- combo-box,radio-set,selection-list OR browse &#10;- Uses colon as separator to define SUB-TYPE for combo-box or &#10;horizontal/vertical radio-set,
     * @param {string} options.FolderWindowToLaunch If Dynamics is running, this property specifies a window to launch upon the occurence of toolbar events "View", "Copy", "Modify" or "Add".
     * @param {string} options.EventLeaveType Language, the event is written in.
     * @param {string} options.TableName WidgetAttributes TableName
     * @param {string} options.FORMAT WidgetAttributes Format
     * @param {string} options.EventAkValidate Client side validation code (JavaScript)
     * @param {string} options.DATA-TYPE WidgetAttributes Data-Type
     * @param {string} options.Validation Validation-Rules, e.g. ValidInteger,ValidEMail...
     * @param {string} options.FilterOperator
     * @param {boolean} options.LABELS If false then NO-LABEL is used.  This attribute applies to most field level widgets and frames
     * @param {string} options.FieldName The name of the associated SDO field this SmartDataField maps to. This is usually 'set' from the containing SmartDataViewer.
     * @param {boolean} options.CreateField
     * @param {string} options.EventLeave Event when leaving a field
     * @param {string} options.SUBTYPE
     * @param {boolean} options.MULTIPLE
     * @param {string} options.Width A widget's width. The unit of measurement is determined by another&#10;parameter.
     * @param {number} options.COLUMN Column position. This may currently be used when rendering some objects. There is no getColumns function, use getCol to retrieve the realized value from an object.
     * @param {string} options.ServerProps Backend Properties
     * @param {string} options.KeyField Name of the Dynamic Lookup/Dynamic Combo key field to assign value from (Table.Field)
     * @param {string} options.ViewerLinkedFields Dynamic Lookup Linked fields to update value of on viewer, comma list of table.fieldname.
     * @param {string} options.LookupControls A comma seperated list of Control names. The value of the corresponding Columns gets filled into those Controls.
     * @param {string} options.templateOptions options which are passed thtough to the template
     * @param {string} options.FieldLabel Label for the Dynamic Lookup/Dynamic Combo field on the viewer.
     * @param {string} options.LookupKeyValueBinding The data-binding field (in the viewer containing a lookup) that should receive the lookup's LookupKeyValueColumn field
     * @param {string} options.QueryTables Comma list of query tables for Dynamic Lookups/Dynamic Combos (Buffers)
     * @param {string} options.initialFetch if not empty, then the lookup will trigger the fetching of data from backend when it gets focus, before a key has been typed.
     * @param {string} options.EventBeforeFetch code to be executed before fetching data from the backend
     * @param {string} options.ObjectType
     * @param {string} options.LookupKeyField The name of the field which is returned by the SmartLookup.
     * @param {string} options.minimumInputLength Minimum number of characters required to start a search.
     * @param {string} options.ViewerLinkedWidgets Dynamic Lookup linked field corresponding widget names to update value of in viewer, comma list, ? if not widget
     * @param {number} options.maximumSelectionLength The maximum number of items that may be selected in a multi-select control. If the value 0, the number of selected items will not be limited.
     * @param {boolean} options.ENABLED WidgetAttributes Enabled
     * @param {string} options.LookupKeyValueColumn The name of the Data Source column which is used for retrieving the LookupKeyValue property
     * @param {string} options.Template
     * @param {string} options.DisplayedFields A comma-separated list of the columns displayed by the visualization object.
     * @param {string} options.DisplayDataType Datatype of Dynamic Lookup display field.
     * @param {boolean} options.multiMode allow multiple items opened at the same time
     * @param {string} options.EntityTable The primary temp-table of the Business Entity used by the component
     * @param {string} options.LIST-ITEM-PAIRS
     * @param {string} options.resourceName
     * @param {string} options.MappedFields A comma separated paired list of data source field names and widget names on a viewer to be used to map fields from a data source with widgets on a viewer when linked fields are used in a Dynamic Lookup.
     * @param {string} options.EntityName The Name of the Business Entity used by the component
     * @param {boolean} options.allowTagging allow dynamically create new options from text input by the user in the search box
     * @param {string} options.LookupFields A comma seperated list of Column names. The value of those fields gets filled into the corresponding Controls of the Property LookupControls.
     * @param {string} options.AxLookupDialog Axilon: Dialog to choose record within the lookup
     * @param {boolean} options.Mandatory WidgetAttributes Mandatory
     * @param {string} options.EventClick Client side validation code (JavaScript)
     * @param {string} options.filter
     */
    ak_lookup: function(options) {
      const defaults = {};

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;

      this.opt.autoComplete = false;

      this.registerDynObject = true;
      this.useParentDynObjectLink = true;

      // get parent
      const oParent = this.parent;
      if (oParent) {

        if (!this.opt.customStyle)
          this.opt.customStyle = this.view;

        this.opt.label = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });

        if (app.sessionData.objectNamesInTitles) {
          if (!this.opt.note)
            this.opt.note = '';
          this.opt.note = `${this.opt.name} | ${this.opt.note}`;
        }

        oParent.prop.fields.push({
          type: (this.opt.autoComplete) ? 'combo' : 'lookup',
          inputTop: parseInt(this.opt.top),
          inputLeft: parseInt(this.opt.left),
          inputWidth: parseInt(this.opt.width),
          label: this.opt.label,
          labelTop: parseInt(this.opt.top),
          labelLeft: oParent.labelLeft(this),
          name: this.opt.name,
          disabled: this.opt.disabled,
          note: { text: (this.opt.note) ? this.opt.note : '' },
          readonly: this.opt.readonly,
          className: (this.opt.autoComplete) ? '' : 'w4-formField w4-inputField',
          position: 'label-top',
          userdata: { id: this.opt.id }
        });


        // append to elements in form
        if (this.parent.view == 'form')
          this.parent.elements.push(this);
        else if (this.parent.view == 'fieldset')
          this.parent.parent.elements.push(this);

        $.extend(this, {
          val: {
            value: '',
            valueKey: '',
            valueDesc: ''
          }
        });
      }
    }
  });

  // methods for form
  $.ak_lookup.prototype = {

    // finish construct
    finishConstruct: function() {
      // get field
      this.form = akioma.getForm(this).dhx;
      this.dhx = (this.opt.autoComplete ? this.form.getCombo(this.opt.name) : null);

      const oCombo = this.dhx;
      if (this.opt.autoComplete) {
        oCombo.setTemplate({ input: '#key#', option: '#key# - #desc#' });
        oCombo.enableFilteringMode(true, `/akioma/comboFilter.xml?Type=getCombo&name=${this.opt.name}&comboname=${this.opt.comboName}`, true);
      }

      const oForm = akioma.getForm(this).dhx;
      const oItem = (this.opt.autoComplete) ? oForm.getCombo(this.opt.name) : oForm.getLookup(this.opt.name);
      const $itemcont = $(oItem);
      $itemcont.addClass('active');
    },

    // en- or disable field ********************
    enabled: function() {

      if (typeof this.opt.disabled != 'boolean')
        return;

      const oElm = this.dhx;
      $(oElm)
        .find('input.lookupKey')
        .attr('disabled', this.opt.disabled);
    },

    // show value ****************
    showValue: function(cValue) {

      const cKeyField = this.opt.showKey,
        cShowDesc = this.opt.showDesc,
        oSource = akioma.getForm(this).dataSource;

      // if we don't have a value -> get value from exthdl
      if (!cValue)
        cValue = oSource.getFieldValue(this.opt.extHdl);

      // set value
      this.val.value = cValue;

      // values are available in record
      if (cKeyField && cShowDesc) {

        const cKey = oSource.getFieldValue(cKeyField);
        this.setValueKey(cKey);

        const cShow = oSource.getFieldValue(cShowDesc);
        this.setValueDesc(cShow);
      }
    },

    // get value *****************
    getValue: function() {
      return this.val.value;
    },

    // set value ********************
    setValue: function(cValue) {
      this.val.value = cValue;
    },

    // get value key *****************
    getValueKey: function() {

      if (this.opt.autoComplete)
        this.val.valueKey = this.dhx.getActualValue();
      else {
        this.val.valueKey = $(this.dynObject.parent.controller.dhx.getLookup(this.opt.name))
          .find('input.lookupKey')
          .val();
      }
      return this.val.valueKey;
    },

    // set value key ********************
    setValueKey: function(cValue) {
      this.val.valueKey = cValue;

      if (this.opt.autoComplete)
      // this.dhx.setComboValue(cValue);
        this.dhx.setComboText(cValue);
      else {
        $(this.dynObject.parent.controller.dhx.getLookup(this.opt.name))
          .find('input.lookupKey')
          .val(cValue);
      }

      if (akioma.getForm(this).dataSource)
        akioma.getForm(this).dataSource.setFieldValue({ name: this.opt.showKey, value: cValue });
    },

    // get value desc ********************
    getValueDesc: function() {

      if (this.displayField)
        this.val.valueDesc = this.displayField.getValue();
      else {
        this.val.valueDesc = $(this.dynObject.parent.controller.dhx.getLookup(this.opt.name))
          .find('input.lookupDisplayed')
          .val();
      }
      return this.val.valueDesc;
    },


    // set value desc ********************
    setValueDesc: function(cValue) {
      this.val.valueDesc = cValue;
      // this.form.setNote(this.opt.name, {text: cValue, width: this.opt.width});

      if (this.displayField)
        this.displayField.setValue(cValue);
      else {
        $(this.dynObject.parent.controller.dhx.getLookup(this.opt.name))
          .find('input.lookupDisplayed')
          .val(cValue);
      }

      if (akioma.getForm(this).dataSource)
        akioma.getForm(this).dataSource.setFieldValue({ name: this.opt.showDesc, value: cValue });
    },

    // get value from external ********
    getExtValue: function(oSource) {

      // get value
      const cValueKey = oSource.getFieldValue(this.opt.extKey);
      let cValueHdl = oSource.getFieldValue(this.opt.extHdl);
      const cValueDesc = oSource.getFieldValue(this.opt.extDesc);

      /* Quick hack for contact lookup in offer */
      if (this.opt.lookupDialog == 'contactg')
        cValueHdl = oSource.getFieldValue('refhdl');

      // set value
      this.setValueKey(cValueKey);
      this.setValue(cValueHdl);
      this.setValueDesc(cValueDesc);

      // set data changed
      if (akioma.getForm(this).dataSource)
        akioma.getForm(this).dataSource.setChanged(true);

      // tell form to execute change events
      this.form.doWithItem(this.opt.name, 'updateValue', this.dhx);

      // check if we have to call the leave event
      if (this.opt.leaveEvent)
        app.controller.callAkiomaCode(this, this.opt.leaveEvent);

      // set focus to lookup field
      this.form.setItemFocus(this.opt.name);
    },

    // popup dialog **********************
    popupDialog: function() {
      const oForm = this.getAncestor('form');

      if (this.security.readOnly)
        return;

      // check for disabled
      if (!this.opt.disabled) {

        // call dialog
        app.controller.launchContainer({
          proc: 'popup.r',
          para: `RunFile=${this.opt.lookupDialog}&FieldName=${this.opt.name}&TargetId=${oForm.opt.id}`,
          self: this,
          data: true
        });
      }
    },

    selectionChanged: function() {},
    changed: function() {
      this.val.value = this.dhx.getComboText();
      this.getValueFromServer(this.dhx.getComboText());
    },

    // get value from server *************
    getValueFromServer: function(value) {

      if (!value)
        value = this.getValueKey();

      // check first if values has been changed
      if (this.oldValue == value)
        return;

      const oData = getLookupValue({
        value: value,
        tableName: this.opt.tableName,
        extHdl: this.opt.extHdl,
        extKey: this.opt.extKey
      });

      if (oData) {
        if (akioma.getForm(this).dataSource)
          this.setValueKey(this.getValueKey());
        this.setValue(oData.value);
        this.setValueDesc(oData.valueDesc);

        if (akioma.getForm(this).dataSource)
          akioma.getForm(this).dataSource.setChanged(true);

        // check if we have to call the leave event
        if (this.opt.leaveEvent)
          app.controller.callAkiomaCode(this, this.opt.leaveEvent);
      }
    },

    // event leave *******************
    eventLeave: function() {
      // check if we have to call the create event
      if (this.opt.leaveEvent)
        app.controller.callAkiomaCode(this, this.opt.leaveEvent);
    },

    // get value *****************
    onFocus: function(value) {
      // save old value for compare
      this.oldValue = value;
      akioma.lastFocus.field = this.opt.name;
      akioma.lastFocus.form = akioma.getForm(this).opt.name;
    },


    // destroy ***************
    destroy: function() {
    }
  };

})(jQuery, jQuery);
