/**
 * Method for exporting the menus as JSON
 *
 * @param   {object}  self
 * @return  {void}
 */
akioma.repository.exportMenuAsJSON = function(self) {
  let cHdl = '';
  let cDesc = '';

  try {
    cHdl = getSDOValue(self, 'selfhdl');
    cDesc = getSDOValue(self, 'selfdesc');
  } catch (e) {
    !_isIE && app.trace && console.error([ 'error: ', e ]);
  }

  if (isValidHdl(cHdl) == false) {
    akioma.messaging
      .error('Es konnten keine zu exportierenden Daten ermittelt werden.');
    return;
  }

  dhtmlx.confirm({
    type: 'confirm',
    text: `${cDesc}<br> als JSON exportieren?`,
    callback: function(result) {
      if (result == true) {
        const oReturn = app.controller.callServerMethod(
          'stubs/exportStructAsJSON.p', [
            { type: 'iCHAR', value: cHdl },
            { type: 'iCHAR', value: '' },
            { type: 'oLONG', name: 'cFile' },
            { type: 'oCHAR', name: 'cResult' }
          ]);
        akioma.messaging.info(`Export beendet. </br>${oReturn.cFile}`);
      }

    }
  });
};

/**
 * Method called to enable corresponding pages in designer on load and for making the instance grid uneditable
 * @param {object} oWin
 * @returns {void}
 */
akioma.repository.onDefaultDesignerLoad = function(oWin) {
  const oTabbar = oWin.controller.getDescendant('tabbar');
  const oDesigner = oWin.controller.getDescendant('designer');
  const oGraphEditor = oWin.controller.getDescendant('graphEditor');

  oTabbar.enablePage('design');
  oTabbar.enablePage('flow');
  oTabbar.enablePage('Instances');
  oTabbar.enablePage('Master');

  oDesigner.parent.expand();
  oDesigner.parent.dhx.showArrow();
  oGraphEditor.parent.dhx.showArrow();

  const instancesGrid = oTabbar.dynObject.getObject('InstanceAttributeValuePropertyGrid');
  if (!isNull(instancesGrid))
    instancesGrid.controller.dhx.setEditable(false);

};

/**
 * Method called to enable corresponding pages in designer on load and for making the instance grid uneditable
 * @param {object} oWin
 * @returns {void}
 */
akioma.repository.onFormDesignerLoad = function(oWin) {
  const oTabbar = oWin.controller.getDescendant('tabbar');
  const oGraphEditor = oWin.controller.getDescendant('graphEditor');

  oTabbar.enablePage('design');
  oTabbar.disablePage('flow');
  oTabbar.enablePage('Instances');
  // oTabbar.enablePage('GraphEditor');
  oTabbar.enablePage('Master');

  oGraphEditor.parent.dhx.hideArrow();

  const instancesGrid = oTabbar.dynObject.getObject('InstanceAttributeValuePropertyGrid');
  if (!isNull(instancesGrid))
    instancesGrid.controller.dhx.setEditable(false);
};

/**
 * Method for making grid editable when opening repo object with no design(container builder mode)
 *
 * @param   {object}  oWin
 * @return  {void}
 */
akioma.repository.onInstancesDesignLoad = function(oWin) {
  const oTabbar = oWin.controller.getDescendant('tabbar');

  oTabbar.disablePage('design');
  oTabbar.disablePage('flow');
  oTabbar.enablePage('Instances');
  // oTabbar.enablePage('GraphEditor');
  oTabbar.enablePage('Master');
  const InstanceAttributeValuePropertyGrid = oTabbar.dynObject.getObject('InstanceAttributeValuePropertyGrid');
  if (InstanceAttributeValuePropertyGrid)
    InstanceAttributeValuePropertyGrid.controller.dhx.setEditable(true);
};

/**
 * Method to set geeneric designer props context
 *
 * @param   {object}  self
 *
 * @return  {string}        The name of the method that will be called to get the BT method name
 */
akioma.repository.setGenericDesignerPropsContext = function({ typeOfRecord, dataSource, propertyGrid }) {

  const framePropertyGrids = propertyGrid.dynObject.screen.controller.getAllChildrenByType('propertygrid');
  framePropertyGrids.forEach(propertyGrid => {
    if (typeOfRecord === 'master') {
      propertyGrid.opt.entityName = dataSource.opt.entityName = dataSource.entityName = 'eSmartObjectMaster';
      dataSource.opt.identifier = 'objectmasterguid';
      propertyGrid.opt.identifier = 'objectmasterguid';
      propertyGrid.opt.setValuesMethodName = 'UpdateObjectMasterDesignAttributes';
      propertyGrid.opt.getValuesMethodName = 'GetObjectMasterDesignAttributes';
      propertyGrid.opt.onAfterGetValues = '$ akioma.repository.onAfterGetValuesMasterProps(self)';
    } else if (typeOfRecord === 'instances') {
      propertyGrid.opt.entityName = dataSource.opt.entityName = dataSource.entityName = 'eSmartObjectInstance';
      dataSource.opt.identifier = 'objectinstanceguid';
      propertyGrid.opt.identifier = 'objectinstanceguid';
      propertyGrid.opt.setValuesMethodName = 'UpdateObjectInstanceDesignAttributes';
      propertyGrid.opt.getValuesMethodName = 'GetObjectInstancesDesignAttributes';
      propertyGrid.opt.onAfterGetValues = '$ akioma.repository.onAfterGetValuesInsProps(self)';
    } else if (typeOfRecord === 'pages') {
      propertyGrid.opt.entityName = dataSource.opt.entityName = dataSource.entityName = 'eSmartPage';
      dataSource.opt.identifier = 'pageguid';
      propertyGrid.opt.identifier = 'pageguid';
      propertyGrid.opt.onAfterGetValues = '$ akioma.repository.onAfterGetValuesMasterProps(self)';
    }
  });
};

/**
 * Generic method for positioning layout designer cursor, used in diagram and treeview selection
 * @param {object} options
 * @param {ak_businessEntity} dataSource The business entity object
 * @param {ak_propertyGrid} propertyGrid The property grid object
 * @param {string} guid The guid to position
 */
akioma.repository.positionDesignerCursor = function({ dataSource, propertyGrid, guid }) {

  let entityName = 'eSmartObjectInstance';
  let typeOfRecord = 'instances';
  let dhxStoreId = dataSource.controller.getIdFrom('objectinstanceguid', guid, entityName);
  if (dhxStoreId)
    dataSource.controller.switchJSDOWorkingRecord(entityName);

  if (!dhxStoreId) {
    entityName = 'eSmartObjectMaster';
    typeOfRecord = 'master';
    dataSource.controller.switchJSDOWorkingRecord(entityName);
    dhxStoreId = dataSource.controller.getIdFrom('objectmasterguid', guid, entityName);
  }

  if (!dhxStoreId) {
    entityName = 'eSmartPage';
    typeOfRecord = 'pages';
    dataSource.controller.switchJSDOWorkingRecord(entityName);
    dhxStoreId = dataSource.controller.getIdFrom('pageguid', guid, entityName);
  }

  akioma.repository.setGenericDesignerPropsContext({
    typeOfRecord: typeOfRecord,
    dataSource: dataSource.controller,
    propertyGrid: propertyGrid.controller
  });

  const dataSourceLinks = dataSource.getLinks('DATA:TARGET');
  if (dataSourceLinks) {
    if (dataSource.getLink('DATA:TARGET').controller.dhx.topCell)
      dataSource.getLink('DATA:TARGET').controller.dhx.topCell.progressOn();
    akioma.WaitCursor.showWaitState(propertyGrid);

    const dataSourceLinked = dataSourceLinks.find(targetObj => targetObj.type.startsWith('businessEntity')).controller;

    const removeWaitCursor = () => {
      const delay = 300;
      setTimeout(() => {
        akioma.WaitCursor.hideWaitState(propertyGrid);
        if (dataSource.getLink('DATA:TARGET').controller.dhx.topCell)
          dataSource.getLink('DATA:TARGET').controller.dhx.topCell.progressOff();
      }, delay);
    };

    dataSourceLinked.addAfterFillOnceCallback(() => {
      propertyGrid.controller.attachEvent('AttributesFetch', removeWaitCursor);
      propertyGrid.controller.attachEvent('NoDataAvailable', removeWaitCursor);
    });
  }

  dataSource.controller.setIndex(dhxStoreId, entityName);
};

/**
 * Method executed on aftertabselection to position the master if selected
 *
 * @param   {object}  self
 * @return  {void}
 */
akioma.repository.onAfterDesignerTabSelected = async function(self) {

  const activePage = self.controller.getActivePage();

  if (activePage.opt.PageKey === 'Master') {
    const attrFrame = self.container.getObject('ObjectAttributesFrame').controller;
    await attrFrame.promiseFrame;

    const propertyGrid = self.container.getFirstChildByType('propertygrid');
    const designer = self.container.getFirstChildByType('designer');
    const dataSource = designer.dataSource;
    const dhxDesign = designer.dhx;

    designer.propertyDesignOpened = 'master';
    akioma.repository.setGenericDesignerPropsContext({
      typeOfRecord: 'master',
      dataSource: dataSource,
      propertyGrid: propertyGrid
    });

    if (designer.cLoadedObjMasterGuid)
      propertyGrid.getProperties(designer.cLoadedObjMasterGuid);

    const indexRootDesigner = Object.keys(dhxDesign.getService('UIState').pull)[0];
    if (!isNull(indexRootDesigner))
      dhxDesign.getService('UIState').setCursor(indexRootDesigner);

    const currentMasterStore = dataSource.getStore(propertyGrid.opt.entityName);
    const selectedRowId = currentMasterStore.getCursor();
    if (selectedRowId) {
      currentMasterStore._cursor = null;
      currentMasterStore.setCursor(selectedRowId);
    }
  } else if (activePage.opt.PageKey === 'Instances') {
    const propertyGrid = self.container.getFirstChildByType('propertygrid');
    const designer = self.container.getFirstChildByType('designer');
    const dataSource = designer.dataSource;

    designer.propertyDesignOpened = 'instances';
    akioma.repository.setGenericDesignerPropsContext({
      typeOfRecord: 'instances',
      dataSource: dataSource,
      propertyGrid: propertyGrid
    });

    const guid = dataSource.getIndex();
    const grid = activePage.getDescendant('datagrid2');
    let selectedRowId = grid.dhx.getSelectedRowId();
    if (!selectedRowId)
      selectedRowId = grid.dhx.getRowId(0);

    if (selectedRowId) {
      grid.dataSource.getStore(propertyGrid.opt.entityName)._cursor = null;
      grid.dhx.selectRowById(selectedRowId, true, true, true);
    }

    if (guid) {
      designer.cLastSelectGuid = guid;
      propertyGrid.getProperties(guid);
    }
  }

  const containerName = self.container.name.toLowerCase();
  if (containerName !== 'layoutdesignerw') {

    const ribbon = self.container.controller.getDescendant('ribbon');
    if (akioma.repository.designerTypes.current === 'formdesign') {
      if (activePage.opt.PageKey === 'design')
        ribbon.dhx.show('ObjectDesignerFormBlock'); // the ribbon.hideItem did not work so I had to use the dhx
      else
        ribbon.dhx.hide('ObjectDesignerFormBlock');
    } else
      ribbon.dhx.hide('ObjectDesignerFormBlock');
  }
};

/**
 * Method for setting instance context on select in instances grid
 * @param {object} self
 * @returns {void}
 */
akioma.repository.setInstanceContext = function(self) {
  if (!self.getFirstParentByType('tab').dhx.isActive())
    return false;

  const designer = self.container.getFirstChildByType('designer');
  designer.propertyDesignOpened = 'instances';

  akioma.repository.setGenericDesignerPropsContext({
    typeOfRecord: 'instances',
    dataSource: self.controller.dataSource,
    propertyGrid: self.container.getObject('MainInsAttributePropertyGrid').controller
  });
};

/**
 * Method executed on before parsing data in ObjectMaster propertyGrid
 * added on Master property grid, onBeforeSetValues attribute
 * @param {dynObject}   self PropertyGrid dynObject
 * @returns {void}
 */
akioma.repository.onBeforeSetValuesInsProps = function(self) {
  const oSelf = self.controller;
  const oInsVals = [];
  const changedRows = oSelf.dc.updatedRows;

  for (const c in changedRows) {
    const idattribute = changedRows[c];
    const item = oSelf.propStore.item(idattribute);
    const itemnew = Object.assign({}, item);
    delete itemnew.attributevalue;
    const cRepoType = item.repositorytype.toUpperCase();
    switch (cRepoType) {
      case 'CHARACTER':
        itemnew.charactervalue = item.attributevalue;
        break;
      case 'LOGICAL':
        itemnew.logicalvalue = item.attributevalue;
        break;
      case 'DECIMAL':
        itemnew.decimalvalue = item.attributevalue;
        break;
      case 'INTEGER':
        itemnew.integervalue = item.attributevalue;
        break;

    }

    itemnew.attributevalueguid = null;
    if (!isNull(itemnew.inheritedfrom))
      itemnew.inheritedfrom = oSelf.unsafe_tags_replace(item.inheritedfrom);
    if (item.constantvalue)
      itemnew.constantvalue = oSelf.stringToBoolean(item.constantvalue);
    if (item.propertyorevent)
      itemnew.propertyorevent = oSelf.stringToBoolean(item.propertyorevent);
    if (item.appliesatruntime)
      itemnew.appliesatruntime = oSelf.stringToBoolean(item.appliesatruntime);

    if (item.isinherited !== undefined)
      itemnew.isinherited = oSelf.stringToBoolean(item.isinherited);

    const specialField = akioma.repository.LayoutDesigner.fieldsToSkipOnInstanceSave;
    if (specialField.indexOf(itemnew.attributelabel.toLowerCase()) === -1)
      oInsVals.push(itemnew);
  }
  return oInsVals;
};

/**
 * Method called when updating the instances property grid isInherited attribute
 *
 * @param   {object}  self       The PropertyGrid control
 * @param   {object}  oGrid
 * @param   {string}  rId        The row id
 * @param   {string}  gridColId  The grid column id
 * @param   {string|boolean}  nValue  The grid cell value
 *
 */
akioma.repository.updateIsInheritedProperty = function({ self, oGrid, rId, gridColId, nValue }) {
  const designer = self.container.getFirstChildByType('designer');
  const oItem = oGrid.propStore.data.pull[rId];

  oItem[gridColId] = nValue;

  const objguid = oGrid.propStore.data.pull[rId]['objectinstanceguid'];
  let id = designer.businessEntity.getIdFrom('objectinstanceguid', objguid);
  if (!isNull(id)) {
    if (designer.tempAttributes.instances[objguid] == undefined)
      designer.tempAttributes.instances[objguid] = [];

    designer.tempAttributes.instances[objguid][rId] = oItem;

  } else {

    designer.businessEntity.switchJSDOWorkingRecord('eSmartObjectMaster');

    id = designer.businessEntity.getIdFrom('objectmasterguid', objguid);
    if (!isNull(id)) {
      if (designer.tempAttributes.masters[objguid] == undefined)
        designer.tempAttributes.masters[objguid] = [];

      designer.tempAttributes.masters[objguid][rId] = oItem;
    }
  }
};

/**
 * Method for checking the attribute value for validation errors
 * @param {ak_propertyGrid} self The property grid edited
 * @param {integer} stage the stage of editing (0-before start; can be canceled if return false,1 - the editor is opened,2- the editor is closed)
 * @param {integer} id the id of a row
 * @param {integer} index the index of a cell
 * @param {string} nValue New attribute value
 *
 * @returns {boolean}
 */
akioma.repository.validateDesignerAttributeValue = function(self, stage, id, index, nValue) {
  try {
    if (stage === 2 && id === 'LayoutPosition') {
      const designer = self.container.getFirstChildByType('designer');
      const treeModel = designer.dhx.getService('UIState');
      const selectedNode = treeModel.getItem(treeModel.getCursor());
      const selectedNodeType = selectedNode.config.$objtype;
      const parentNode = treeModel.getItem(selectedNode.$parent);
      const layoutType = parentNode.config.type;
      const regexNumLetterSplit = /(\d+)/;
      const maxChilds = +(regexNumLetterSplit.exec(layoutType)[0] || 0);
      let valid = true;

      let notificationText = `The LayoutPosition must be a lowercase character from 'a' to '${String.fromCharCode(maxChilds + 96)}'.`;

      // for toolbar and ribbon object types
      if (akioma.repository.LayoutDesigner.navigationReplaceKeepInstanceTypes.includes(selectedNodeType)) {
        valid = nValue.length === 0;

        if (String.fromCharCode(maxChilds + 96) === 'a')
          notificationText = 'The LayoutPosition must be a lowercase character \'a\' or an empty string.';
        else
          notificationText = `The LayoutPosition must be a lowercase character from 'a' to '${String.fromCharCode(maxChilds + 96)}' or an empty string.`;
        if (nValue.length === 1 && !valid) {
          const newPositionCount = nValue.charCodeAt(0) - 97;
          valid = newPositionCount >= 0 && newPositionCount < maxChilds;
        }
      } else if (selectedNodeType === 'swatbusinessentity') { // disallow DSO panel positioning
        valid = nValue === '' || nValue === null;
        notificationText = 'The SwatBusinessEntity object can\'t be added to a Panel. Please specify an empty LayoutPosition.';
      } else { // for panel level objects
        valid = nValue.length === 1;
        if (valid) {
          const newPositionCount = nValue.charCodeAt(0) - 97;
          valid = newPositionCount >= 0 && newPositionCount < maxChilds;
        }

        if (String.fromCharCode(maxChilds + 96) === 'a')
          notificationText = 'The LayoutPosition must be a lowercase character \'a\'.';
      }

      if (!valid) {
        akioma.notification({
          type: 'warning',
          text: notificationText
        });
      }

      return valid;
    }
  } catch (e) {
    console.error(e);
  }

  return true;
};

/**
 * Method executed from MasterObjects property grid in layoutdesignerw Master tab,
 * sets correct isInherited value. added on EventAkValidate in MasterPropertyGrid on column AttributeValue, switches isInherited checkbox state
 * @param {dynObject}   self PropertyGrid dynObject
 * @returns {void}
 */
akioma.repository.validateMasterProps = function(self) {
  const { iStage, rId, iCol, nValue } = self.akEvent;

  const validationState = akioma.repository.validateDesignerAttributeValue(self, iStage, rId, iCol, nValue);

  // block if attribute validation fails
  if (!validationState)
    return validationState;

  const oGrid = self.controller;
  const gridCell = oGrid.dhx.cells(rId, iCol);
  let gridColId = oGrid.dhx.getColumnId(iCol);
  if (gridColId)
    gridColId = gridColId.toLowerCase();

  const label = oGrid.dhx.getColumnId(iCol);
  // if updating a checkbox editMode 2 or 1 && is checkbox
  let designer = self.container.getFirstChildByType('designer');
  if (iStage === 2 || (gridCell.isCheckbox() && iStage === 1)) {
    if (gridColId !== 'isinherited') {
      const indInherited = oGrid.dhx.getColIndexById('isinherited');

      oGrid.propStore.data.pull[rId][label] = nValue;

      oGrid.dhx.callEvent('onCheckbox', [
        rId,
        indInherited,
        false
      ]);

      oGrid.dhx.callEvent('onCheck', [
        rId,
        indInherited,
        false
      ]);

      oGrid.propStore.data.pull[rId]['isinherited'] = false;
    } else if (designer) {
      akioma.repository.updateIsInheritedProperty({
        self,
        oGrid,
        rId,
        gridColId,
        nValue
      });
      return;
    }

    if (!designer)
      designer = self.container.getFirstChildByType('graphEditor');

    // on update of attribute value save in designer instances and master values
    if (designer)
      akioma.repository.updateDesignerProperty(designer, oGrid, rId, nValue, label);
  }

  return validationState;
};

/**
 * Method for setting the attribute value based on the attribute repository type, returns new attribute object
 * @param {object} attrRecord Attribute object
 * @param {any} value Value of attribute
 * @returns {object} attrRecord updated
 */
akioma.repository.getAttributeValueByRepoType = function(attrRecord, value) {
  if (attrRecord.RepositoryType.toUpperCase() == 'CHARACTER')
    attrRecord.CharacterValue = value;
  else if (attrRecord.RepositoryType.toUpperCase() == 'LOGICAL')
    attrRecord.LogicalValue = value;
  else if (attrRecord.RepositoryType.toUpperCase() == 'DECIMAL') {
    if (value != null)
      attrRecord.DecimalValue = parseFloat(value);
    else
      attrRecord.DecimalValue = 0;

  } else if (attrRecord.RepositoryType.toUpperCase() == 'INTEGER') {
    if (value != null)
      attrRecord.IntegerValue = parseInt(value);
    else
      attrRecord.IntegerValue = 0;

  }

  attrRecord.IsInherited = false;

  return attrRecord;
};

/**
 * Method for updating designer property in layoutdesigner or formdesigner
 * @param {ak_designer} designer Designer object
 * @param {ak_propertyGrid} propertyGrid The property Grid object
 * @param {string} attributeLabel the attribute label
 * @param {string} nValue the attribute value
 *
 */
akioma.repository.updateDesignerProperty = function(designer, propertyGrid, attributeLabel, nValue, columnLabel) {
  if (!designer)
    return;

  const objinsguid = propertyGrid.propStore.data.pull[attributeLabel]['objectinstanceguid'];
  const objmasterguid = propertyGrid.propStore.data.pull[attributeLabel]['objectmasterguid'];
  const guid = objinsguid || objmasterguid;
  if (designer.view === 'designer') {
    designer.saveDesignerTreeState();
    const promise = designer._onPropChange(guid, nValue, attributeLabel);
    promise.done(res => {
      if (res == 0) {
        const model = designer.dhx.getService('UIState');
        model.previousOpenStates = designer._treeNodesOpenState;
        const oElms = _.filter(model.pull, item => item.guid == guid);
        const modelAttr = oElms[0]._custom.find(v => v.label === attributeLabel || v.AttributeLabel === attributeLabel || v.name === attributeLabel);
        const storeElm = oElms[0];

        const values = {};
        values[modelAttr._idd] = nValue;

        model.updateItem(storeElm.$id, values);
        if (designer.dhx.tree.getSelectedItemId())
          designer.dhx.tree.focusItem(storeElm.$id);
        else
          designer.dhx.tree.findItem(storeElm.config.$name);

        propertyGrid.dhx.callEvent('attributeChange', [ attributeLabel, nValue ]);
      }
    });
  } else if (designer.view === 'graphEditor') { // handling for attributes in form designer
    if (columnLabel === 'isinherited') {
      // TODO needs to be fixed
      const oAttr = propertyGrid.propStore.data.pull[attributeLabel];
      oAttr.isinherited = nValue;
      const attrRecord = propertyGrid.akEvent.resGetProps.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue.find(item => item.AttributeLabel === attributeLabel);
      attrRecord.IsInherited = nValue;
      propertyGrid.propStore.update(attributeLabel, oAttr);
      propertyGrid.customData.state.data[guid] = {
        source: 'instance', // replace instances with instance maybe everywhere
        attributesById: { [attributeLabel]: attrRecord },
        attributes: [attrRecord],
        guid
      };
    } else
      akioma.repository.onFormDesignerAttributeUpdate(guid, attributeLabel, nValue, propertyGrid, designer);

    propertyGrid.dhx.callEvent('attributeChange', [ attributeLabel, nValue ]);
  }
};

/**
 * Method called on update of attribute in Form Designer
 * @param {string} objkey Guid
 * @param {string} label Attribute label
 * @param {any} value Value of attribute
 * @param {ak_propertyGrid} propertyGrid PropertyGrid object
 * @param {ak_graphEditor} formbuilder Formbuilder Graph
 */
akioma.repository.onFormDesignerAttributeUpdate = function(objkey, label, value, propertyGrid, formbuilder) {
  const guid = objkey;
  const isMasterRecord = objkey === formbuilder.dhx.state.master.objectmasterguid;

  if (isMasterRecord) { // update for object masters
    const cMasterGuid = objkey;
    const oMasterStore = formbuilder.dataSource.getStore('eSmartObjectMaster');
    formbuilder.dataSource.switchJSDOWorkingRecord('eSmartObjectMaster');

    if (label === 'name') { // for updating objectname of Master record
      let storeID;
      let item = oMasterStore.item(cMasterGuid);
      if (!item) {
        storeID = formbuilder.dataSource.getIdFrom('objectmasterguid', cMasterGuid);
        item = oMasterStore.item(storeID);
      }
      item.objectname = value;
      oMasterStore.update(storeID, item);
      // set state
      const attrRecord = propertyGrid.akEvent.resGetProps.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue.find(item => item.AttributeLabel === label);

      this.setGraphAttributeValue({ propertyGrid, guid, source: 'master', label, attrRecord });
    } else {
      const oAttr = propertyGrid.propStore.data.pull[label];
      let attrRecord = propertyGrid.akEvent.resGetProps.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue.find(item => item.AttributeLabel === label);

      if (oAttr.repositorytype.toUpperCase() == 'CHARACTER')
        oAttr.charactervalue = value;
      else if (oAttr.repositorytype.toUpperCase() == 'LOGICAL')
        oAttr.logicalvalue = value;
      else if (oAttr.repositorytype.toUpperCase() == 'DECIMAL') {
        if (value != null)
          oAttr.decimalvalue = parseFloat(value);
        else
          oAttr.decimalvalue = 0;

      } else if (oAttr.repositorytype.toUpperCase() == 'INTEGER') {
        if (value != null)
          oAttr.integervalue = parseInt(value);
        else
          oAttr.integervalue = 0;

      }
      oAttr.isinherited = false;

      attrRecord = akioma.repository.getAttributeValueByRepoType(attrRecord, value);

      propertyGrid.propStore.update(label, oAttr);

      // set state
      propertyGrid.customData.state.selectedGuid = guid;
      this.setGraphAttributeValue({ propertyGrid, guid, source: 'master', label, attrRecord });

    }
  } else { // handle updating instance record
    const cInsGuid = guid;
    const oInstanceStore = formbuilder.dataSource.getStore('eSmartObjectInstance');
    formbuilder.dataSource.switchJSDOWorkingRecord('eSmartObjectInstance');

    if (label === 'name') { // for updating instancename of Instance record
      // this only happens on update of Instances
      let item = oInstanceStore.item(cInsGuid);
      if (!item) {
        const storeID = formbuilder.dataSource.getIdFrom('objectinstanceguid', cInsGuid);
        item = oInstanceStore.item(storeID);
      }

      item.instancename = value;
      oInstanceStore.update(cInsGuid, item);
      const attrRecord = propertyGrid.akEvent.resGetProps.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue.find(item => item.AttributeLabel === label);
      attrRecord.CharacterValue = value;
      attrRecord.AttributeValueGuid = null;

      // set state
      propertyGrid.customData.state.selectedGuid = guid;

      this.setGraphAttributeValue({ propertyGrid, guid, label, attrRecord });

    } else { // this happens for all other attributes

      const oAttr = propertyGrid.propStore.data.pull[label];
      let attrRecord = propertyGrid.akEvent.resGetProps.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue.find(item => item.AttributeLabel === label);

      if (oAttr.repositorytype.toUpperCase() == 'CHARACTER')
        oAttr.charactervalue = value;
      else if (oAttr.repositorytype.toUpperCase() == 'LOGICAL')
        oAttr.logicalvalue = value;
      else if (oAttr.repositorytype.toUpperCase() == 'DECIMAL') {
        if (value != null)
          oAttr.decimalvalue = parseFloat(value);
        else
          oAttr.decimalvalue = 0;

      } else if (oAttr.repositorytype.toUpperCase() == 'INTEGER') {
        if (value != null)
          oAttr.integervalue = parseInt(value);
        else
          oAttr.integervalue = 0;
      }

      oAttr.isinherited = false;
      propertyGrid.propStore.update(label, oAttr);
      attrRecord.AttributeValueGuid = null;

      attrRecord = akioma.repository.getAttributeValueByRepoType(attrRecord, value);

      this.setGraphAttributeValue({ propertyGrid, guid, label, attrRecord });

    }
  }
};

/**
 * Method for setting the attribute value in designer
 * @param {ak_propertyGrid} propertyGrid
 * @param {string} source instance or master
 * @param {string} guid The object guid
 * @param {string} label The attribute label
 * @param {object} attrRecord The attribute data
 */
akioma.repository.setGraphAttributeValue = function({ propertyGrid, source = 'instance', guid, label, attrRecord }) {
  const state = propertyGrid.customData.state;
  if (!state.data[guid]) {
    state.data[guid] = {
      source,
      attributesById: { [label]: attrRecord },
      attributes: [attrRecord],
      guid
    };
  } else {
    state.data[guid].attributesById[label] = attrRecord;
    const indexAttr = state.data[guid].attributes.findIndex(attr => attr.AttributeLabel === label);
    if (indexAttr < 0)
      state.data[guid].attributes.push(attrRecord);
    else
      state.data[guid].attributes[indexAttr] = attrRecord;
  }
};

/**
 * Method executed after loading properties data in ObjectMaster propertyGrid
 * added on Master Property grid, onAfterGetValues attribute, parses rows in grid
 * @param {dynObject}   self PropertyGrid dynObject
 * @returns {void}
 */
akioma.repository.onAfterGetValuesMasterProps = function(self) {
  const oSelf = self.controller;
  const oResult = oSelf.akEvent.resGetProps;
  let aPropsMaster = oResult.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue;
  const aCellsTypes = [];

  if (aPropsMaster) {
    aPropsMaster = aPropsMaster.map(item => {
      let itemnew;
      try {
        itemnew = {};
        for (const x in item) {
          if (item[x] != undefined)
            itemnew[x.toLowerCase()] = item[x];
        }
        itemnew.id = item['AttributeLabel'];

        let cRepoType = item['RepositoryType'].toUpperCase();
        if (itemnew.attributelabel === 'Layout')
          cRepoType = 'SELECT';

        switch (cRepoType) {
          case 'CHARACTER':
            itemnew.attributevalue = item['CharacterValue'];
            aCellsTypes[itemnew.id] = 'ed';
            break;
          case 'LOGICAL':
            itemnew.attributevalue = item['LogicalValue'];
            aCellsTypes[itemnew.id] = 'ch';
            break;
          case 'DECIMAL':
            itemnew.attributevalue = item['DecimalValue'];
            aCellsTypes[itemnew.id] = 'edn';
            break;
          case 'INTEGER':
            itemnew.attributevalue = item['IntegerValue'];
            aCellsTypes[itemnew.id] = 'edn';
            break;
          case 'DATE':
            itemnew.attributevalue = item['DateValue'];
            aCellsTypes[itemnew.id] = 'dhxCalendarA';
            break;
          case 'DATETIME':
            itemnew.attributevalue = item['DateTimeValue'];
            aCellsTypes[itemnew.id] = 'dhxCalendarA';
            break;
          case 'SELECT':
            itemnew.attributevalue = item['CharacterValue'];
            aCellsTypes[itemnew.id] = 'coro';
            break;
        }
      } catch (e) {
        akioma.log.error(e, aPropsMaster);
      }
      return itemnew;
    });

    const EventXLE = oSelf.dhx.attachEvent('onXLE', () => {
      try {
        for (const cRowId in aCellsTypes) {
          const colInd = oSelf.dhx.getColIndexById('attributevalue');
          const colType = aCellsTypes[cRowId];

          if (colType == 'coro') {
            const options = [ '1C', '2E', '2U', '3E', '3L', '3W', '3J', '3T', '3U', '4H', '4I', '4T', '4U', '4E', '4A', '4W', '4L', '4J', '4F', '4G', '4C', '5H', '5I', '5U', '5E', '5W', '5K', '5S', '5G', '5C', '6H', '6I', '6A', '6C', '6J', '6E', '6W', '7H', '7I' ];
            const combo = oSelf.dhx.getCustomCombo(cRowId, colInd);
            combo.clear();
            options.forEach(opt => {
              combo.put(opt, opt);
            });
          }
          oSelf.dhx.setCellExcellType(cRowId, colInd, colType);
        }
      } catch (e) {
        akioma.log.error(e);
      }

      oSelf.dhx.detachEvent(EventXLE);
    });
    oSelf.propStore.parse(aPropsMaster);
  }

  const eventFilter = oSelf.FilterManager.getPanelFilterState('gridPanelObjectAttributesEventToggleFilter');
  if (eventFilter)
    oSelf.dhx.filterBy(oSelf.dhx.getColIndexById('propertyorevent'), row => row === false);

};

/**
* Method for parsing object properties
*
* @param   {string}  guid
* @param   {array}  aProps
* @return  {array}  props
*/
akioma.repository.parseObjectProps = function(designer, guid, aProps) {
  let iDif = 0;

  designer.props[guid] = [];
  for (const a in aProps) {
    if (designer.props[aProps[a].ObjectInstanceGuid] == undefined) {
      designer.props[aProps[a].ObjectInstanceGuid] = [];
      iDif = parseInt(a);
    }
    const logical = (aProps[a].RepositoryType.toUpperCase() === 'LOGICAL');
    const cType = (!logical ? 'input' : 'checkbox');

    let cVal;
    if (logical)
      cVal = aProps[a].LogicalValue;
    else
      cVal = aProps[a].CharacterValue;

    if (aProps[a].RepositoryType) {
      if (aProps[a].RepositoryType.toUpperCase() == 'DECIMAL') {
        if (aProps[a].DecimalValue != null)
          cVal = parseFloat(aProps[a].DecimalValue);
        else
          cVal = 0;
      }

      if (aProps[a].RepositoryType.toUpperCase() == 'INTEGER') {
        if (aProps[a].IntegerValue != null)
          cVal = parseInt(aProps[a].IntegerValue);
        else
          cVal = 0;
      }
    }

    let cUniqueIdd = `${parseInt(a) - iDif}_${aProps[a].ObjectInstanceGuid}`;
    if (aProps[a].AttributeLabel == 'LayoutPosition')
      cUniqueIdd = `${parseInt(a) - iDif}_${aProps[a].ObjectInstanceGuid}_layoutposition`;


    if (aProps[a].AttributeLabel == 'name')
      cUniqueIdd = `${parseInt(a) - iDif}_${aProps[a].ObjectInstanceGuid}_name`;


    if (aProps[a].AttributeLabel === 'Layout')
      cUniqueIdd = `${parseInt(a) - iDif}_${aProps[a].ObjectInstanceGuid}_maintype`;


    aProps[a]._idd = cUniqueIdd;

    designer.props[guid].push({
      label: aProps[a].AttributeLabel,
      type: cType,
      _idd: cUniqueIdd,
      value: cVal,
      original: aProps[a]
    });
  }

  return aProps;
};

/**
 * Client Logic method used in PropertyGrid After a fetch for reading the properties
 * @param {object} self
 * @returns {void}
 */
akioma.repository.onAfterGetValuesInsProps = function(self) {
  const oSelf = self.controller;
  const oResult = oSelf.akEvent.resGetProps;
  let aPropsInstances = oResult.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue;
  const aCellsTypes = [];

  let dataSource = oSelf.dataSource;
  if (oSelf.dataSource.dataSource) {
    const targetObj = oSelf.dataSource.dataSource.dynObject.getLink('DATA:TARGET');
    if (targetObj.type === 'designer' || targetObj.type === 'graphEditor')
      dataSource = oSelf.dataSource.dataSource;

  }

  const store = dataSource.getStore(dataSource.entityName);
  const item = store.item(store.getCursor());
  const objectmasterguid = item ? (item['objectmasterguid'] || item['objecttypeguid']) : null;

  if (aPropsInstances !== undefined && !isNull(objectmasterguid)) {
    try {
      aPropsInstances = aPropsInstances.map(item => {
        const itemnew = {};
        for (const x in item) {
          if (item[x] != undefined)
            itemnew[x.toLowerCase()] = item[x];
        }
        itemnew.id = item['AttributeLabel'];
        itemnew.objectmasterguid = objectmasterguid;
        if (item['InheritedFrom'])
          itemnew.inheritedfrom = oSelf.safe_tags_replace(item['InheritedFrom']);

        const itemRepoType = item['RepositoryType'] || 'CHARACTER';
        let cRepoType = itemRepoType.toUpperCase();


        if (itemnew.attributelabel === 'Layout' || item['AttributeLabel'] === 'Layout')
          cRepoType = 'SELECT';

        switch (cRepoType) {
          case 'CHARACTER':
            itemnew.attributevalue = item['CharacterValue'];
            aCellsTypes[itemnew.id] = 'ed';
            break;
          case 'LOGICAL':
            itemnew.attributevalue = item['LogicalValue'];
            aCellsTypes[itemnew.id] = 'ch';
            break;
          case 'DECIMAL':
            itemnew.attributevalue = item['DecimalValue'];
            aCellsTypes[itemnew.id] = 'edn';
            break;
          case 'INTEGER':
            itemnew.attributevalue = item['IntegerValue'];
            aCellsTypes[itemnew.id] = 'edn';
            break;
          case 'DATE':
            itemnew.attributevalue = item['DateValue'];
            aCellsTypes[itemnew.id] = 'dhxCalendarA';
            break;
          case 'DATETIME':
            itemnew.attributevalue = item['DateTimeValue'];
            aCellsTypes[itemnew.id] = 'dhxCalendarA';
            break;
          case 'SELECT':
            itemnew.attributevalue = item['CharacterValue'];
            aCellsTypes[itemnew.id] = 'coro';
            break;
        }
        return itemnew;
      });
    } catch (e) {
      akioma.log.error(e);
    }

    const EventXLE = oSelf.dhx.attachEvent('onXLE', () => {
      try {
        for (const cRowId in aCellsTypes) {
          const colInd = oSelf.dhx.getColIndexById('attributevalue');
          const colType = aCellsTypes[cRowId];

          if (colType == 'coro') {
            const options = [ '1C', '2E', '2U', '3E', '3L', '3W', '3J', '3T', '3U', '4H', '4I', '4T', '4U', '4E', '4A', '4W', '4L', '4J', '4F', '4G', '4C', '5H', '5I', '5U', '5E', '5W', '5K', '5S', '5G', '5C', '6H', '6I', '6A', '6C', '6J', '6E', '6W', '7H', '7I' ];
            const combo = oSelf.dhx.getCustomCombo(cRowId, colInd);
            combo.clear();
            options.forEach(opt => {
              combo.put(opt, opt);
            });
          }
          oSelf.dhx.setCellExcellType(cRowId, colInd, colType);
        }

        oSelf.dhx.filterByAll();
        oSelf.dhx.detachEvent(EventXLE);
      } catch (e) {
        console.error(e);
      }

    });

    oSelf.propStore.parse(aPropsInstances);
  }
};

akioma.repository.toggleAttributesEventFilter = function(self, event) {
  const oSelf = self.controller;
  const aExtendedFormat = {
    'true': { icon: 'far fa-check-square' },
    'false': { icon: 'far fa-square' }
  };

  const oHeader = event.currentTarget;
  const menuName = oHeader.getAttribute('menu-name');
  const cCurrentState = oSelf.FilterManager.getPanelFilterState(menuName);
  let toRemoveFilter = false;

  let oFilterIcon, oNewIcon;
  switch (cCurrentState.toString()) {
    case '':
      oFilterIcon = aExtendedFormat['true'];
      oNewIcon = akioma.icons.replaceIcon(oFilterIcon);
      oHeader.icon = oFilterIcon.icon;
      break;
    case 'false':
      oFilterIcon = aExtendedFormat['true'];
      oNewIcon = akioma.icons.replaceIcon(oFilterIcon);
      oHeader.icon = oFilterIcon.icon;
      break;
    case 'true':
      oFilterIcon = aExtendedFormat['false'];
      oNewIcon = akioma.icons.replaceIcon(oFilterIcon);
      oHeader.icon = oFilterIcon.icon;
      toRemoveFilter = true;
      break;
  }

  const oFirstIcon = $(oHeader).find('i').eq(0);
  const oSecondIcon = $(oHeader).find('i').eq(1);
  $(oHeader).removeClass('stackedIcon');

  $(oFirstIcon).removeClass().addClass(`${oNewIcon.icon1} ${oNewIcon.style1}`);
  oFirstIcon[0].style.cssText = oNewIcon.attributes1;
  $(oSecondIcon).removeClass().addClass('fa-stack-1x fa-inverse');
  if (oNewIcon.icon2) {
    $(oSecondIcon).addClass(`${oNewIcon.icon2} ${oNewIcon.style2}`);
    oSecondIcon[0].style.cssText = oNewIcon.attributes2;
    $(oFirstIcon).addClass('fa-stack-2x fa-2x fa-fw');
    $(oHeader).addClass('stackedIcon');
  } else
    $(oFirstIcon).addClass('fa-stack-lg fa-lg fa-fw');


  if (!toRemoveFilter)
    oSelf.FilterManager.setPanelFilterState(menuName, true);
  else
    oSelf.FilterManager.setPanelFilterState(menuName, false);

  oSelf.dhx.filterBy(oSelf.dhx.getColIndexById('propertyorevent'), row => row === false || toRemoveFilter);
};

/**
 * Method for opening an repository object in designer.
 * @param {object} self
 * @param {string} options
 */
akioma.repository.openInDesigner = function(self, { objectNameField = 'objectname', objectMasterField = 'objectmasterguid', objectTypeNameField = 'objecttypename' } = {}) {

  const objectName = self.parent.dataSource.dynObject.getValue(objectNameField);
  const objectMasterGuid = self.parent.dataSource.dynObject.getValue(objectMasterField);
  const objectTypeName = self.parent.dataSource.dynObject.getValue(objectTypeNameField);

  let deferredRequest;
  if (objectTypeName == null) {
    // if object type is not available in the data source, get it from the backend
    deferredRequest = akioma.invokeServerTask({
      name: 'Akioma.Swat.Repository.RepositoryBT',
      methodName: 'GetObjectTypeName',
      paramObj: { plcParameter: { Value: objectMasterGuid } }
    }).then(oResult => oResult.plcParameter.Value);
  } else {
    deferredRequest = $.Deferred();
    deferredRequest.resolve(objectTypeName);
  }

  deferredRequest.done(objectTypeName => {
    const oPromise = app.controller.launchContainer({ containerName: 'objectdesignerw', allowMultipleInstances: true });
    if (oPromise) {
      oPromise.then(container => {
        const layoutDesignerGrid = container.dynObject.getObject('ObjectMasterGrid');
        container.dynObject.getLink('PRIMARYSDO:TARGET').controller.addAfterFillOnceCallback(() => {
          akioma.loadRepoObjects(layoutDesignerGrid, objectName, objectMasterGuid, objectTypeName.toLowerCase());
        });
      });
    }
  });
};

/**
 * Method for enabling all form fields (dev tools).
 * @param {object} self
 */
akioma.repository.enableAllFormFields = function(self) {
  if (typeof self.enableAllFields == 'function')
    self.enableAllFields();

};

/**
 * Method for opening target object in designer (dev tools).
 * @param {object} self
 * @param {string} level
 */
akioma.repository.openTargetInDesigner = function(self, level = 'self') {

  let oObject;

  switch (level) {
    case 'self':
      oObject = self;
      break;
    case 'parent':
      oObject = self.parent;
      break;
    case 'topScreen':
      oObject = self.topScreen;
      break;
    default:
      oObject = self;
      break;
  }

  const objectMasterGuid = oObject.attributes.SubObjectGuid || oObject.attributes.ObjectMasterGuid;
  let objectName, objectTypeName;

  akioma.invokeServerTask({
    name: 'Akioma.Swat.Repository.ObjectMasterTask',
    methodName: 'FetchObjectMasterData',
    paramObj: { plcParameter: { Value: objectMasterGuid } }
  }).done(oResult => {
    let oObjectMaster = {};
    if (oResult.dsObjectMaster.dsObjectMaster && oResult.dsObjectMaster.dsObjectMaster.eSmartObjectMaster)
      oObjectMaster = oResult.dsObjectMaster.dsObjectMaster.eSmartObjectMaster[0];

    if (oObjectMaster) {
      objectName = oObjectMaster.ObjectName || '';
      objectTypeName = oObjectMaster.ObjectTypeName || '';
    }

    if (objectName === '' || objectTypeName === '') {
      akioma.notification({
        type: 'error',
        text: 'Invalid object name or type specified.'
      });

      return;
    }

    const oPromise = app.controller.launchContainer({ containerName: 'objectdesignerw', allowMultipleInstances: true });
    if (oPromise) {
      oPromise.then(container => {
        const layoutDesignerGrid = container.dynObject.getObject('ObjectMasterGrid');
        container.dynObject.getLink('PRIMARYSDO:TARGET').controller.addAfterFillOnceCallback(() => {
          akioma.loadRepoObjects(layoutDesignerGrid, objectName, objectMasterGuid, objectTypeName.toLowerCase());
        });
      });
    }
  }).fail(([ , , request ]) => {
    akioma.notification({
      type: 'error',
      text: 'Error fetching object details.',
      moretext: JSON.stringify(request.response, null, 4)
    });
  });
};

akioma.repository.openObjectDetails = function(self) {

  const parent = self.parent;
  const topScreen = self.topScreen;
  const objectMasterGuid = self.attributes.SubObjectGuid || self.attributes.ObjectMasterGuid;
  const parentMasterGuid = parent.attributes.SubObjectGuid || parent.attributes.ObjectMasterGuid;
  const topScreenMasterGuid = topScreen.attributes.SubObjectGuid || topScreen.attributes.ObjectMasterGuid;
  const promises = [];

  let objectName, objectTypeName, parentName, parentTypeName, topScreenName, topScreenTypeName;

  const oObjectPromise = akioma.invokeServerTask({
    name: 'Akioma.Swat.Repository.ObjectMasterTask',
    methodName: 'FetchObjectMasterData',
    paramObj: { plcParameter: { Value: objectMasterGuid } }
  }).done(oResult => {
    let oObjectMaster = {};
    if (oResult.dsObjectMaster.dsObjectMaster && oResult.dsObjectMaster.dsObjectMaster.eSmartObjectMaster)
      oObjectMaster = oResult.dsObjectMaster.dsObjectMaster.eSmartObjectMaster[0];

    if (oObjectMaster) {
      objectName = oObjectMaster.ObjectName || '';
      objectTypeName = oObjectMaster.ObjectTypeName || '';
    }
  }).fail(([ , , request ]) => {
    akioma.notification({
      type: 'error',
      text: 'Error fetching object details.',
      moretext: JSON.stringify(request.response, null, 4)
    });
  });

  const oParentPromise = akioma.invokeServerTask({
    name: 'Akioma.Swat.Repository.ObjectMasterTask',
    methodName: 'FetchObjectMasterData',
    paramObj: { plcParameter: { Value: parentMasterGuid } }
  }).done(oResult => {
    let oObjectMaster = {};
    if (oResult.dsObjectMaster.dsObjectMaster && oResult.dsObjectMaster.dsObjectMaster.eSmartObjectMaster)
      oObjectMaster = oResult.dsObjectMaster.dsObjectMaster.eSmartObjectMaster[0];

    if (oObjectMaster) {
      parentName = oObjectMaster.ObjectName || '';
      parentTypeName = oObjectMaster.ObjectTypeName || '';
    }
  }).fail(([ , , request ]) => {
    akioma.notification({
      type: 'error',
      text: 'Error fetching parent object details.',
      moretext: JSON.stringify(request.response, null, 4)
    });
  });

  const oTopScreenPromise = akioma.invokeServerTask({
    name: 'Akioma.Swat.Repository.ObjectMasterTask',
    methodName: 'FetchObjectMasterData',
    paramObj: { plcParameter: { Value: topScreenMasterGuid } }
  }).done(oResult => {
    let oObjectMaster = {};
    if (oResult.dsObjectMaster.dsObjectMaster && oResult.dsObjectMaster.dsObjectMaster.eSmartObjectMaster)
      oObjectMaster = oResult.dsObjectMaster.dsObjectMaster.eSmartObjectMaster[0];

    if (oObjectMaster) {
      topScreenName = oObjectMaster.ObjectName || '';
      topScreenTypeName = oObjectMaster.ObjectTypeName || '';
    }
  }).fail(([ , , request ]) => {
    akioma.notification({
      type: 'error',
      text: 'Error fetching top object details.',
      moretext: JSON.stringify(request.response, null, 4)
    });
  });

  promises.push(oObjectPromise);
  promises.push(oParentPromise);
  promises.push(oTopScreenPromise);

  Promise.all(promises).then(() => {
    const text = `<b>Object name:</b> ${objectName} \n<b>Object Type:</b> ${objectTypeName}\n\n<b>Parent name:</b> ${parentName} \n<b>Parent Type:</b> ${parentTypeName}\n\n<b>Top Screen name:</b> ${topScreenName} \n<b>Top Screen Type:</b> ${topScreenTypeName}`;

    akioma.createModalWindows();
    const win = akioma.oWindowsModals.createWindow({
      id: dhtmlx.uid(),
      caption: akioma.tran('messageBox.title.more', { defaultValue: 'More information' }),
      width: 400,
      height: 250
    });
    win.button('park').hide();
    win.button('minmax').hide();
    win.centerOnScreen();
    win.bringToTop();
    win.setModal(true);

    $(win.cell).find('.dhx_cell_cont_wins').css('overflow', 'auto');
    win.attachHTMLString(`<div style="color:#B22556;word-break:break-word;padding: 20px;white-space: pre;">${text}</div>`);
  });
};

akioma.repository.ExternalScreen = new akioma.ExternalScreen('Container-Builder');

/**
 * Method for opening designer in external screen
 * @param {object} self
 * @param {boolean} bExternal
 * @returns {void}
 */
akioma.repository.openObjectInDesigner = function(self, bExternal) {
  const cObjectName = self.opt.ObjectName;
  const cObjectMasterGuid = self.opt._ObjectMasterGuid;
  const cObjMasterType = self.opt.ObjectTypeName;

  akioma.eventEmitter.emit('openObjectInDesigner', self, cObjectName, cObjectMasterGuid, cObjMasterType, bExternal);
};

/**
 *  Listener for opening a designer object master in same window or in a different window
 */
akioma.eventEmitter.on('openObjectInDesigner', (oSelf, cObjectName, cObjectMasterGuid, cObjMasterType, bExternal) => {

  if (isNull(bExternal)) {
    const oDesigner = akioma.root.dynObject.getFirstChildByType('designer');
    if (oDesigner) {
      // open in designer screen new object master
      const self = akioma.root.dynObject.getObject('ObjectMasterGrid');
      akioma.loadRepoObjects(self, cObjectName, cObjectMasterGuid, cObjMasterType.toLowerCase());
    } else
      akioma.launchDesignerWithSelection(oSelf);
  } else {
    // launch External screen
    akioma.launchExternalScreen({
      autostartObjects: 'objectdesignerw',
      screenNamespace: 'Container-Builder',
      custom: {
        ObjectName: cObjectName,
        ObjectType: cObjMasterType.toLowerCase(),
        ObjectGuid: cObjectMasterGuid
      }
    });
  }
});

akioma.repository.positionDesignerInExternalScreen = function(self) {
  const cObjectName = self.opt.ObjectName;
  const cObjectMasterGuid = self.opt._ObjectMasterGuid;
  const cObjMasterType = self.opt.ObjectTypeName;
  // emit via socket.io to the external screen to position existing opened screen
  akioma.repository.ExternalScreen.emit('dataAvailable', {
    ObjectName: cObjectName,
    ObjectType: cObjMasterType.toLowerCase(),
    ObjectGuid: cObjectMasterGuid
  });
};

/**
 * Add onInit on designer, will read the custom external data and load the object in the designer
 * @param {object} self
 * @returns {void}
 */
akioma.repository.setDesignerListener = function(self) {

  const oSelf = self.controller;

  const oCustomData = akioma.repository.ExternalScreen.getExternalData();

  if (oCustomData) {
    const cObjectName = oCustomData.ObjectName;
    const cObjMasterType = oCustomData.ObjectType;
    const cObjMasterGuid = oCustomData.ObjectGuid;

    oSelf.dataSource.addAfterCatalogAdd(() => {
      akioma.loadRepoObjects(self, cObjectName, cObjMasterGuid, cObjMasterType);
    });


  }

  // send message with data for opening in External Screen
  if (akioma.socketConnection.exists()) {
    akioma.repository.ExternalScreen.on('dataAvailable', msg => {

      const cObjectName = msg.ObjectName;
      const cObjMasterType = msg.ObjectType;
      const cObjMasterGuid = msg.ObjectGuid;

      const self = akioma.root.dynObject.getObject('ObjectMasterGrid');
      akioma.loadRepoObjects(self, cObjectName, cObjMasterGuid, cObjMasterType);

    });
  } else
    akioma.notification({ type: 'warning', text: 'Please check your socket.io configuration for opening the designer in external screen.' });
};

/**
 * Method for binding Keyboard shortcut for opening a new window with docviewer
 * @returns {void}
 */
akioma.repository.bindOpenNewScreen = function() {

  Mousetrap.bind(akioma.shortcutManager.get('OpenLayoutDesignerScreen'), () => {
    if (securityIsRestricted('CanUseDeveloperTools')) return;
    akioma.launchExternalScreen({ autostartObjects: 'objectdesignerw' });
  });
};

akioma.repository.bindOpenNewScreen();


/**
 * @property {Array<string>} default List of objects that open in design tab.
 * @property {string} current Current tab opened.
 * @property {string} lastObjMasterGuidOpened Last object master guid opened in layout designer.
 */
akioma.repository.designerTypes = {
  default: [ 'swatwindow', 'swatframe', 'swatdialog' ],
  form: ['swatform'],
  current: 'none',
  lastObjMasterGuidOpened: ''
};

/**
 * @property {Array<string>} rootAndPageDropAddsInstanceTypes List of objects that are automatically added as children when droping on root or page level.
 * @property {Array<string>} navigationReplaceKeepInstanceTypes List of objects that on replace in tree keeps the instance and moves it below, navigation elements.
 * @property {Array<string>} fieldsToSkipOnInstanceSave List of fields that are skipped on instances attributes save from property grid event.
 */
akioma.repository.LayoutDesigner = {
  mockPageGuid: 'c4db7fa4-ee0b-4e31-b25d-8170ebba6ad1', // mock page guid extracted from MockWindow repository object, used for filtering when creating new pages in LayoutDesigner
  rootAndPageDropAddsInstanceTypes: [
    'swattoolbar',
    'swatribbon',
    'swatbusinessentity'
  ],
  swatLayoutLevelObjectTypes: [ 'swatframe', 'swatpopover', 'swatwindow', 'swatdialog' ],
  navigationReplaceKeepInstanceTypes: [
    'swattoolbar',
    'swatribbon',
    'toolbar',
    'ribbon'
  ],
  fieldsToSkipOnInstanceSave: [
    'objectname',
    'objectmastername',
    'objectinstancename',
    'name',
    'layoutposition',
    'enableoncreate',
    'enableonmodify',
    'enableonview'
  ]
};

/**
 * @property {string} defaultIconsStyle Default styling for all icons.
 * The variables supported for styling:
 * --fa-primary-color, --fa-secondary-color, --fa-primary-opacity, --fa-secondary-opacity
 */
akioma.repository.LayoutDesigner.defaultIconsStyle = `
    --fa-secondary-opacity: 0.7;
    --fa-primary-opacity: 0.8;
    --fa-primary-color: #0050ff;
    --fa-secondary-color: gray;
`;

/**
 * @property {object} icons List of icons by Object Types
 * @property {string} icons.icon The font awesome class
 * @property {string} style The variables supported for styling:
 * --fa-primary-color, --fa-secondary-color, --fa-primary-opacity, --fa-secondary-opacity
 */
akioma.repository.LayoutDesigner.icons = {
  swatbusinessentity: {
    icon: 'fad fa-database',
    style: `
            --fa-primary-color: orange;
            --fa-secondary-color: blue;
        `
  },
  swatdataview: {
    icon: 'fad fa-th',
    style: `
        `
  },
  swatdocviewer: {
    icon: 'fad fa-books',
    style: `
        `
  },
  swatform: {
    icon: 'fad fa-credit-card-front',
    style: `
        `
  },
  swatchart: {
    icon: 'fad fa-analytics',
    style: `
        `
  },
  swatgauge: {
    icon: 'fad fa-tachometer-alt',
    style: `
        `
  },
  swatscheduler: {
    icon: 'fad fa-calendar-alt',
    style: `
        `
  },
  swatformdesign: {
    icon: 'fad fa-ruler-triangle',
    style: `
        `
  },
  swatgrid: {
    icon: 'fad fa-list-ul',
    style: `
        `

  },
  swatdiagram: {
    icon: 'fad fa-chart-network',
    style: `
        `

  },
  swatpropertygrid: {
    icon: 'fad fa-sliders-h',
    style: `
        `

  },
  swatimage: {
    icon: 'fad fa-images',
    style: `
        `
  },
  swatframe: {
    icon: 'fad fa-object-ungroup',
    style: `
        `
  },
  swattabbar: {
    icon: 'fad fa-layer-group',
    style: `
        `
  },
  swattab: {
    icon: 'fad fa-border-top',
    style: `
        `
  },
  swattreegrid: {
    icon: 'fad fa-folder-tree',
    style: `
        `
  },
  swattext: {
    icon: 'fad fa-edit',
    style: `
        `
  },
  swattoolbar: {
    icon: 'fad fa-ruler-horizontal',
    style: `
        `
  },
  swatribbon: {
    icon: 'fad fa-ribbon',
    style: `
        `
  },
  container: {
    icon: 'fad fa-desktop',
    style: `
        `
  },
  swatnewcolumn: {
    icon: 'fad fa-border-center-v',
    style: ''
  },
  swatdatafield: {
    icon: 'fad fa-ruler-horizontal',
    style: ''
  },
  swatinput: {
    icon: 'fad fa-ruler-horizontal',
    style: ''
  },
  swatlookup: {
    icon: 'fad fa-ruler-horizontal',
    style: ''
  },
  swatselect: {
    icon: 'fad fa-ruler-horizontal',
    style: ''
  },
  swatcombobox: {
    icon: 'fad fa-ruler-horizontal',
    style: ''
  },
  swatblock: {
    icon: 'fad fa-th-large',
    style: `
        `
  },
  swatfieldset: {
    icon: 'fad fa-layer-group',
    style: `
        `
  },
  swatlayoutdesign: {
    icon: 'fad fa-drafting-compass',
    style: `
        `
  },
  default: {
    icon: 'fad fa-braille',
    style: `
        `
  },
  diagramLinkConnector: {
    icon: 'fad fa-arrow-down',
    style: `
            --fa-primary-color: black;
            --fa-secondary-color: red;
        `
  },
  empty: {
    icon: 'fad fa-empty-set',
    style: `
            --fa-primary-color: cadetblue;
            --fa-secondary-color: darkblue;
        `
  }
};

/**
 * Extends with default style for all icons
 */
akioma.repository.extendDesignerIconsStyle = function() {
  try {
    const iconsObjs = akioma.repository.LayoutDesigner.icons;
    for (const x in iconsObjs)
      iconsObjs[x].style = akioma.repository.LayoutDesigner.defaultIconsStyle + iconsObjs[x].style;

  } catch (e) {
    akioma.notification({
      type: 'error',
      text: 'Error extending default styling for icons in designer!'
    });
  }
};

akioma.repository.extendDesignerIconsStyle();

/**
 * Method for opening the object master in designer
 * @param {object} self
 * @returns {void}
 */
akioma.designerOpenRepository = function(self) {

  const akGrid = self.controller.parent.getDescendant('datagrid2');
  let dataView = null;
  let oSelectedNewObjectMaster = null;
  let oGrid = null;
  let cObjMasterName = '';
  let cObjMasterGuid = '';
  let cObjMasterType = '';

  if (akGrid == null)
    dataView = self.controller;


  if (dataView == null) {
    oGrid = akGrid.dhx;
    oSelectedNewObjectMaster = akGrid.dataSource.dhx.item(oGrid.getSelectedRowId());
    cObjMasterName = oSelectedNewObjectMaster.objectname;
    cObjMasterGuid = oSelectedNewObjectMaster.objectmasterguid;
    cObjMasterType = oSelectedNewObjectMaster.objecttypename.toLowerCase();
  } else {
    oSelectedNewObjectMaster = dataView.dataSource.dhx.item(dataView.getSelectedRowId());
    cObjMasterName = oSelectedNewObjectMaster.objectmastername;
    cObjMasterGuid = oSelectedNewObjectMaster.objectmasterguid;
    cObjMasterType = oSelectedNewObjectMaster.instancetypename.toLowerCase();
  }

  akioma.loadRepoObjects(self, cObjMasterName, cObjMasterGuid, cObjMasterType);

};

/**
 * Method to clear all businessEntity multitable stores
 * @param {object} oDesignerBE
 * @returns {void}
 */
akioma.repository.clearAllStores = function(oDesignerBE) {
  // reset datastore connector updated rows
  oDesignerBE.getStoreConnector('eSmartObjectMaster').updatedRows = [];
  oDesignerBE.getStoreConnector('eSmartPage').updatedRows = [];
  oDesignerBE.getStoreConnector('eSmartObjectInstance').updatedRows = [];
  oDesignerBE.getStoreConnector('eSmartLink').updatedRows = [];

  oDesignerBE.getStore('eSmartLink').clearAll();
  oDesignerBE.getStore('eSmartObjectMaster').clearAll();
  oDesignerBE.getStore('eSmartObjectInstance').clearAll();
  oDesignerBE.getStore('eSmartPage').clearAll();
};

/**
 * Method for opening/loading the repository objects in designer
 * @param {object}  self
 * @param {string}  cObjMasterName
 * @param {string}  cObjectMasterGuid
 * @param {string}  cObjMasterType
 * @returns {void}
 */
akioma.loadRepoObjects = function(self, cObjMasterName, cObjMasterGuid, cObjMasterType) {
  let oDesignerTabbar = self.container.controller.getDescendant('tabbar');
  let windowClose = false;
  let isObjectDesigner = false;
  const windowContainer = self.container;

  // if the window does not contain the designer object then load in caller / from Open Dialog
  if (oDesignerTabbar === null) {
    windowClose = true;
    self = self.container.caller.container.getFirstChildByType('designer').dynObject;
    oDesignerTabbar = self.container.controller.getDescendant('tabbar');
    isObjectDesigner = self.container.name.toLowerCase() !== 'layoutdesignerw';
  } else
    isObjectDesigner = self.container.name.toLowerCase() !== 'layoutdesignerw';


  // clears has changes for the form designer attributes
  const panelSwitcher = self.container.getFirstChildByType('panelSwitcher');
  if (panelSwitcher)
    akioma.repository.AttributeForm.clearHasChangesAttributeForms(panelSwitcher);


  const oDesignTab = oDesignerTabbar.dynObject.getObject('Design');
  const oDesigner = oDesignTab.getDescendant('designer');
  const oDesignerBE = oDesigner.store;

  const oDiagram = self.container.controller.getDescendant('diagram');

  // reset datastore connector updated rows
  const instancesConnector = oDesignerBE.getStoreConnector('eSmartObjectInstance');
  instancesConnector.updatedRows = [];

  const objMasterName = cObjMasterName;
  const designerBEQuery = oDesignerBE.getQuery();
  designerBEQuery.clearAll();
  designerBEQuery.addCondition('ObjectName', 'eq', objMasterName);

  const designerApp = self.container.getFirstChildByType('designer').dhx;
  const cTypeRender = designerApp.akElm.getObjType(cObjMasterType);
  oDesignerTabbar.dhx.progressOn();
  akioma.repository.clearAllStores(oDesignerBE);

  const ribbon = self.container.controller.getDescendant('ribbon');
  try {
    const allowedObjectLaunchList = akioma.repository.LayoutDesigner.swatLayoutLevelObjectTypes;
    if (allowedObjectLaunchList.includes(cTypeRender))
      ribbon.showItem('designerLaunchSelected');
    else
      ribbon.hideItem('designerLaunchSelected');

    if (cTypeRender == 'form')
      ribbon.showItem('formBlock');
    else
      ribbon.hideItem('formBlock');

    ribbon.showItem('designerReloadSelected');

  } catch (e) {
    akioma.log.warn(e);
  }

  const propertyGrid = self.container.getFirstChildByType('propertygrid');
  if (propertyGrid) {
    akioma.repository.setGenericDesignerPropsContext({
      typeOfRecord: 'master',
      dataSource: oDesignerBE,
      propertyGrid: propertyGrid
    });
  }

  // resetting before tab active events is called, setActive
  oDesigner.cLoadedObjMasterGuid = null;

  let oActiveTab = oDesignerTabbar.getActivePage();

  if (akioma.repository.designerTypes.default.indexOf(cObjMasterType.toLowerCase()) !== -1) {
    akioma.repository.onDefaultDesignerLoad(self.container);
    akioma.repository.designerTypes.current = 'containerdesign';

    if (!oActiveTab || oDesignerTabbar.isPageEnabled(oActiveTab.opt.PageKey) !== 'false')
      oActiveTab = oDesignerTabbar.dynObject.getObject('Design');
  } else
  if (akioma.repository.designerTypes.form.indexOf(cObjMasterType.toLowerCase()) !== -1 && isObjectDesigner) {
    akioma.repository.onFormDesignerLoad(self.container);
    akioma.repository.designerTypes.lastObjMasterGuidOpened = cObjMasterGuid;
    akioma.repository.designerTypes.current = 'formdesign';

    if (!oActiveTab || oDesignerTabbar.isPageEnabled(oActiveTab.opt.PageKey) !== 'false')
      oActiveTab = oDesignerTabbar.dynObject.getObject('Design');
  } else {
    akioma.repository.onInstancesDesignLoad(self.container);
    akioma.repository.designerTypes.lastObjMasterGuidOpened = cObjMasterGuid;
    akioma.repository.designerTypes.current = 'instancesdesign';

    if (!oActiveTab || oDesignerTabbar.isPageEnabled(oActiveTab.opt.PageKey) !== 'false')
      oActiveTab = oDesignerTabbar.dynObject.getObject('Object Details');
  }

  oDesignerTabbar.setActivePage(oActiveTab.opt.PageKey);
  akioma.repository.onAfterDesignerTabSelected(oDesignerTabbar.dynObject);

  oDesigner.setDesignerType(akioma.repository.designerTypes.current);

  oDesignerBE.addAfterFillAlways(function(request) {
    if (!request.success)
      return;
    const smartObjectMasterStore = oDesignerBE.getStore('eSmartObjectMaster');
    const oDhxDataSourceObjMaster = smartObjectMasterStore.data.pull;
    const oDhxDataSourceObjInstances = oDesignerBE.getStore('eSmartObjectInstance').data.pull;
    const oDhxLinks = oDesignerBE.getStore('eSmartLink').data.pull;

    oDesigner.loadDesignerConfig(objMasterName, cObjMasterGuid, cObjMasterType);

    if (akioma.repository.designerTypes.current === 'containerdesign')
      oDiagram.renderDiagram(oDhxDataSourceObjMaster, oDhxDataSourceObjInstances, oDhxLinks, oDesignerBE);
    else if (!this.oDhxLinks)
      oDiagram.clearDiagram();


    const smartObjectMaster = smartObjectMasterStore.item(smartObjectMasterStore.getCursor());
    const title = 'Design Layout';
    let subtitle = `'${smartObjectMaster.objectname}'`;

    if (smartObjectMaster.objectdescription)
      subtitle += ` - ${smartObjectMaster.objectdescription}`;

    // Set title on window
    self.container.controller.dhx.setText(`${title} ${subtitle}`);

    // emit global hook
    const windowClapi = akioma.swat.SwatFactory.createSwatObject(self.container.controller);
    akioma.swat.GlobalEmitter.emit(akioma.swat.GlobalHooks.DESIGNER.AFTER_OPEN, { self: windowClapi, title, subtitle });

    oDesignerTabbar.dhx.progressOff();
    if (windowClose) windowContainer.controller.close();

  });

  const oInstanceJSDO = oDesignerBE.jsdo['eSmartObjectInstance'];
  oInstanceJSDO._parent = oInstanceJSDO._name;
  oInstanceJSDO._jsdo.useRelationships = false;

  oDesignerBE.openQuery();
  oDesignerBE.decrementStoreChangesAndErrors();
};

/**
 * Method for launching designer screen and opening a new object
 * @param {object} self
 * @returns {void}
 */
akioma.launchDesignerWithSelection = function(self) {
  let screen = self.opt;
  if (self.view != 'window')
    screen = self.controller.dataSource.getSelectedRecord();

  const objectName = screen.ObjectName || screen.objectname;
  const objectMasterGuid = screen.ObjectMasterGuid || screen.objectmasterguid;
  const objectTypeName = screen.ObjectTypeName || screen.objecttypename;

  const oPromise = app.controller.launchContainer({ containerName: 'objectdesignerw', allowMultipleInstances: true });

  if (oPromise) {
    oPromise.then(container => {
      const layoutDesignerGrid = container.dynObject.getObject('ObjectMasterGrid');
      container.dynObject.getLink('PRIMARYSDO:TARGET').controller.addAfterFillOnceCallback(() => {
        akioma.loadRepoObjects(layoutDesignerGrid, objectName, objectMasterGuid, objectTypeName.toLowerCase());
      });
    });
  }
};

/**
 * Method for loading a form
 * @param   {object}  formbuilder  The formbuilder
 * @param   {string}  objectName   The object name
 * @param   {string}  objectType   The object type
 * @return  {void}
 */
akioma.repository.loadForm = function(formbuilder, objectName, objectType) {
  if (formbuilder && objectType.toLowerCase() === 'simpleswatform') {
    const filter = formbuilder.businessEntity.getQuery();
    filter.clearAll();
    filter.addCondition('ObjectName', 'eq', objectName);
    try {
      if (formbuilder.parent.dhx.progressOn) {
        formbuilder.parent.dhx.progressOn();
        formbuilder.businessEntity.aCallback['afterFill'] = function(res) {
          formbuilder.loadFormFields(objectType, res[0].objectmasterguid);
        };
        formbuilder.businessEntity.openQuery({ firstQuery: true });
      }
    } catch (e) {
      console.error(e);
    }
  }
};

/**
 * Method for adding a new repository object in designer
 * @param {object} self
 * @returns {void}
 */
akioma.repository.newObject = function(self) {
  const oDialog = self.container;
  const oTabbar = oDialog.getObject('itFolder');
  const cActiveTab = oTabbar.controller.aTabKeys[oTabbar.controller.activeTabId];
  const oForm = oDialog.getObject('newObjectV');
  const cTargetNewObjName = oForm.controller.getValue('cobjectname');
  const cDescription = oForm.controller.getValue('cobjectdescription');
  const cModulesName = oForm.getObject2('smartmodule').controller.getValue('desc');
  const lOpenAfterCreate = oForm.getValue('openAfterCreate');

  let cSourceNewObjName;
  switch (cActiveTab) {
    case 'Dataview':
      cSourceNewObjName = oDialog.getObject('ObjectMasterBE').controller.getFieldValue('objectname');
      break;
    case 'Grid':
      cSourceNewObjName = oDialog.getObject('ObjectMasterSources').controller.getFieldValue('objectname');
      break;
    default:
      akioma.log.error(`Unexpected tab '${cActiveTab}' selected when attempting to create new repository object '${cTargetNewObjName}'!`);
      return;
  }
  if (isNull(cSourceNewObjName)) {
    akioma.notification({ type: 'error', text: 'Please select a type or an object!' });
    return;
  }

  if (!cTargetNewObjName) {
    akioma.notification({ type: 'error', text: 'Object name must not be empty.' });
    return;
  }

  akioma.invokeServerTask({
    name: 'Akioma.Swat.Repository.ObjectMasterTask',
    methodName: 'CopyObjectMaster',
    paramObj: {
      plcParameter: {
        SourceObjectMaster: cSourceNewObjName,
        TargetObjectMasterName: cTargetNewObjName,
        ModuleName: cModulesName,
        Description: cDescription
      }
    },
    showWaitCursor: true,
    uiContext: self.container
  }).done(oResult => {
    akioma.notification({ type: 'success', text: `New object master "${oResult.plcParameter.TargetObjectMasterName}" has been successfuly created.` });

    self.container.controller.close();

    akioma.swat.RefreshEmitter.refreshData('eSmartObjectMaster', { lastRowState: 'add', lastUpdatedRecord: { objectmasterguid: oResult.plcParameter.ResultObjectMasterGuid } });

    if (lOpenAfterCreate) {
      akioma.launchExternalScreen({
        autostartObjects: 'objectdesignerw',
        custom: {
          ObjectName: oResult.plcParameter.TargetObjectMasterName,
          ObjectType: oResult.plcParameter.ObjectTypeName.toLowerCase(),
          ObjectGuid: oResult.plcParameter.ResultObjectMasterGuid
        }
      });
    }
  }).fail(([ , , request ]) => {
    akioma.notification({
      type: 'error',
      text: 'Error creating new object master copy.',
      moretext: JSON.stringify(request.response, null, 4)
    });
  });
};

/**
 * Method for checking if the multitable businessEntity has changes
 * @param {object} oSelf
 * @returns {boolean}
 */
akioma.repository.checkChanges = function(oSelf) {
  const oStoreIns = oSelf.businessEntity.getStoreConnector('eSmartObjectInstance'),
    oStorePages = oSelf.businessEntity.getStoreConnector('eSmartPage'),
    oStoreMaster = oSelf.businessEntity.getStoreConnector('eSmartObjectMaster'),
    oStoreLinks = oSelf.businessEntity.getStoreConnector('eSmartLink');
  const arrStores = [ oStoreIns, oStoreLinks, oStorePages, oStoreMaster ];

  let bHasChanges = false;
  for (const j in arrStores) {
    if (arrStores[j].updatedRows.length > 0)
      bHasChanges = true;

  }

  return bHasChanges;
};

/**
 * Method for exporting the repository objects
 *
 * @param   {object}  self
 *
 * @return  {void}
 */
akioma.repository.exportDataChanges = function(self) {
  akioma.invokeServerTask({
    name: 'Akioma.Swat.DataMaintenanceTask',
    methodName: 'ExportRepositoryObjects',
    showWaitCursor: true,
    uiContext: self.container,
    paramObj: { plcParameter: { 'Value': '*' } }
  }).done(() => {
    akioma.message({
      type: 'info',
      title: 'Export data changes',
      text: 'Data changes exported successfuly'
    });
    akioma.swat.RefreshEmitter.refreshData('eSmartModifiedState*', { lastRowState: 'delete' });
  });
};

/**
 * Method for exporting the base data changes
 * @param   {object}  self
 * @return  {void}
 */
akioma.repository.exportBaseDataChanges = function(self) {
  akioma.invokeServerTask({
    name: 'Akioma.Swat.DataMaintenanceTask',
    methodName: 'ExportBaseData',
    showWaitCursor: true,
    uiContext: self.container,
    paramObj: { plcParameter: { 'Value': '*' } }
  }).done(() => {
    akioma.message({
      type: 'info',
      title: 'Export base data changes',
      text: 'Base data changes exported successfuly'
    });
  });
};

/**
 * Method for exporting the menu changes
 * @param   {object}  self
 * @return  {void}
 */
akioma.repository.exportMenuChanges = function(self) {
  akioma.invokeServerTask({
    name: 'Akioma.Swat.DataMaintenanceTask',
    methodName: 'ExportMenuStructures',
    showWaitCursor: true,
    uiContext: self.container,
    paramObj: { plcParameter: { 'Value': '*' } }
  }).done(() => {
    akioma.message({
      type: 'info',
      title: 'Export menu changes',
      text: 'Menu changes exported successfuly'
    });
  });
};

/**
 * Method for exporting the repository type changes
 * @param   {object}  self
 * @return  {void}
 */
akioma.repository.exportRepositoryTypeChanges = function(self) {
  akioma.invokeServerTask({
    name: 'Akioma.Swat.DataMaintenanceTask',
    methodName: 'ExportRepositoryTypes',
    showWaitCursor: true,
    uiContext: self.container,
    paramObj: { plcParameter: { 'Value': '*' } }
  }).done(() => {
    akioma.message({
      type: 'info',
      title: 'Export repository type changes',
      text: 'Repository type changes exported successfuly'
    });
  });
};

/**
 * Method for deleting the selected object instance
 * @param {object} self
 * @returns {void}
 */
akioma.repository.deleteObjInstance = function(self) {
  const oInstancesGrid = self.container.getObject('ObjectInstanceGrid').controller;
  const instanceStore = oInstancesGrid.dataSource.getStore('eSmartObjectInstance');

  const cRowId = oInstancesGrid.dhx.getSelectedRowId();
  if (cRowId) {
    instanceStore.remove(cRowId);

    const instanceData = Object.keys(instanceStore.data.pull).map(key => instanceStore.data.pull[key]);
    if (instanceData.length > 0) { // if we have at least one remaining instance in grid select that one; otherwise do nothing; master already positioned correctly
      const firstRowId = instanceData[0].id;

      instanceStore.setCursor(firstRowId);
      oInstancesGrid.dhx.selectRowById(firstRowId);
    }
  } else {
    akioma.notification({
      type: 'info',
      text: 'Please select instance record to delete.'
    });
  }
};

/**
 * Method for saving instances and instances properties changes if any
 * @param {object} self
 * @returns {boolean}
 */
akioma.repository.saveInstancesDesignerType = function(self) {
  // save instances master from property grid
  akioma.repository.saveObjectMasterProps(self);

  const successMsg = function() {
    akioma.notification({ type: 'success', text: 'Repository objects saved successfuly.', expire: 3000 });
  };

  const oPropertyGrid = self.container.getObject('MainInsAttributePropertyGrid').controller;
  const oInstancesGrid = self.container.getObject('ObjectInstanceGrid').controller;
  const designer = self.container.getFirstChildByType('designer');
  const oDesignerBE = designer.businessEntity;
  const storeConnector = oInstancesGrid.dataSource.getStoreConnector(oInstancesGrid.dataSource.opt.entityName);
  const hasStoreChanges = storeConnector.updatedRows.length > 0;

  const saveSmartObjectMaster = () => {
    oDesignerBE.switchJSDOWorkingRecord('eSmartObjectMaster');
    oDesignerBE.getStoreConnector('eSmartObjectMaster').beforeSendDataAll = true;
    oDesignerBE.getStoreConnector('eSmartObjectMaster').sendData();
  };

  if (oInstancesGrid) {
    if (hasStoreChanges) {
      oInstancesGrid.dataSource.cleanSaveChangesOnceEvts();
      oInstancesGrid.dataSource.addAfterSaveChangesOnceCallback((success, jsdo, data) => {
        if (success) {
          const cTypeOfRecord = oDesignerBE.opt.entityName === 'eSmartObjectMaster' ? 'master' : 'instances';
          const cGuid = cTypeOfRecord === 'master' ? data.objectmasterguid : data.objectinstanceguid;
          designer.newInstances = [];
          designer.lastSelectedInstance = null;
          oPropertyGrid.setProperties(cGuid);
          saveSmartObjectMaster();

          akioma.reloadSelectedDesignerObject(self);
          successMsg();
        }
      });
      oInstancesGrid.recordSave({ entityTable: oDesignerBE.opt.entityName });
    } else {
      oPropertyGrid.updateProperties();
      saveSmartObjectMaster();

      akioma.reloadSelectedDesignerObject(self);
      successMsg();
    }
  } else {
    saveSmartObjectMaster();
    successMsg();

    akioma.reloadSelectedDesignerObject(self);
  }

  return false;
};

/**
 * Global method for saving Object Master Attributes
 * @param {object} self ribbon
 * @returns {void}
 */
akioma.repository.saveObjectMasterProps = function(self) {
  const oPropertyGridMaster = self.container.getObject('MasterAttributeValuePropertyGrid');
  if (oPropertyGridMaster)
    oPropertyGridMaster.controller.updateProperties();

  const oMasterGrid = self.container.getObject('ObjectMasterGrid');
  const oMasterForm = self.container.getObject('ObjectMasterForm').controller;
  if (oMasterForm) {
    oMasterForm.submitRow();
    oMasterForm.dataSource.jsdo.subscribe('afterSaveChanges', function onAfterSaveChanges(jsdo, success, request) {

      jsdo.unsubscribe('afterSaveChanges', onAfterSaveChanges);

      if (success) {
        if (request.response && request.response.dsObjectMaster && request.response.dsObjectMaster.eSmartObjectMaster)
          akioma.repository.clearObjectMasterCache(request.response.dsObjectMaster.eSmartObjectMaster);

        oMasterGrid.controller.FilterGo();
        // remove dirty state
        oMasterForm._dispatch('decrementHasChanges', 1);
        oMasterForm._dispatch('setHasChanges', false);
      }
    });
  }
};

/**
 * Method called when clicking the placeholder button open placeholder screen
 * @param {string}  cObjType
 * @param {string}  cGuidToReplace
 * @param {ak_designer}  designer
 * @returns {void}
 */
akioma.repository.replaceDesignerPlaceholder = function(cObjType, cGuidToReplace, designer) {

  // create new temporary BE for fetching the objecttypeguid
  const cResourceName = 'Consultingwerk.SmartFramework.Repository.Class.ObjectTypeBusinessEntity';
  const oBEoptions = {
    'att': {
      cacheLimit: 50,
      catalogURI: '',
      dataSource: '',
      entityName: 'eSmartObjectType',
      id: 'offerw45613645_businessEntity',
      identifier: 'objecttypeguid',
      name: 'businessEntity',
      rowsToBatch: 1000,
      resourceName: cResourceName,
      initialFetch: '#none',
      serviceURI: ''
    }
  };

  const businessEntity = new $['ak_businessEntity'](oBEoptions);
  businessEntity.finishConstruct();
  businessEntity.endConstruct();

  const oQuery = businessEntity.getQuery();

  oQuery.addCondition('ObjectTypeName', 'eq', cObjType.toLowerCase());
  businessEntity.addAfterCatalogAdd(() => {
    businessEntity.openQuery({}, res => {
      // now open and filter by the object type guid
      if (res[0]) {
        const record = res[0];
        app.controller.launchContainer({
          containerName: 'replacePlaceholderW',
          customData: {
            initialFilter: record.objecttypeguid,
            initialFilterDesc: cObjType,
            replaceGuid: cGuidToReplace,
            designer
          }
        });

      } else {
        akioma.notification({
          type: 'error',
          text: 'No Object Type found.'
        });
      }
    });
  });

};

// get the row selected and for the attribute value of the selected(updaterecord..) propertygrid attribute
/**
 * Method called on row chosen in attribute value selection
 */
akioma.repository.onChosenAttributeValue = function(self) {
  const oBE = self.container.getLink('PRIMARYSDO:TARGET').controller;
  const targetPropertyGrid = self.container.controller.customData.propertyGrid;
  const selectedRecord = oBE.getSelectedRecord();

  // set propertygrid selection in attribute value
  const cell = self.container.controller.customData.cell;
  const row_id = cell.id;
  const cell_index = cell.index;
  let value = selectedRecord.objectname;
  if (selectedRecord.objecttypename == 'SwatCode') {
    const masterDesignAttributesPromise = akioma.RepositoryStructure.fetchMasterDesignAttributes(selectedRecord.objectmasterguid);
    masterDesignAttributesPromise.done(data => {
      value = this.getDesignAttributeValue(data, 'EventCode');
      targetPropertyGrid.cellById(row_id, cell_index).setValue(value);
      targetPropertyGrid.akElm.editCell(2, row_id, cell_index, value);
    });
  } else {
    targetPropertyGrid.cellById(row_id, cell_index).setValue(value);
    targetPropertyGrid.akElm.editCell(2, row_id, cell_index, value);
  }
  self.container.controller.close();
};

/**
 * Used to get the attribute value of an attribute from the ObjectMasterDesignAttributes
 * @param {Object} data //the response from ObjectMasterDesignAttributes
 * @param {String} attributeLabel //the attribute label for who you want the value
 * @returns {String}
 */
akioma.repository.getDesignAttributeValue = function(data, attributeLabel) {
  const attributes = data.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue;
  let result = '';
  if (attributes) {
    attributes.find(element => {
      if (element.AttributeLabel == attributeLabel)
        result = element.CharacterValue;

    });
  }
  return result;
};

/**
 * Method used on beforefetch event on a replace attribute value screen
 * @param {Object} self
 */
akioma.repository.onBeforeReplaceAttributeValue = function(self) {
  const customData = self.container.controller.customData;
  const grid = self.container.getFirstChildByType('datagrid2');
  const val = [{ 'id': 'dee40d25-b37c-68bb-7a14-a001d4acb8c3', 'desc': 'SwatCode' }];
  let filters = [];

  if (customData.cell.id.toLowerCase().indexOf('event') > -1)
    filters = [{ fieldname: 'objecttypeguid', operator: 'eq', value: JSON.stringify(val) }];

  if (filters.length) {
    grid.setFilterFields(filters);
    grid.dataSource.setFilter(grid.filter, grid);
  }
};

// open placeholder screen
/**
 * Method to replace attribute value for selected designer object
 * @param {dhtmlxGrid} gridObj Property grid object
 */
akioma.repository.replaceAttributeValue = function(gridObj) {
  const gridSelectedCell = gridObj.akElm.cellEdited;
  if (gridSelectedCell) {
    app.controller.launchContainer({
      containerName: 'replaceDesignerAttributeValuew',
      customData: {
        propertyGrid: gridObj,
        cell: gridSelectedCell
      }
    });
  }
};

/**
 * Method used on beforefetch event on a placeholder screen
 * @param {object} self
 */
akioma.repository.onBeforePlaceholderW = function(self) {
  const oCustomData = self.container.controller.customData;
  const cInitialFilter = oCustomData.initialFilter;

  const oVal = [{ 'id': cInitialFilter, 'desc': oCustomData.initialFilterDesc }];
  const oGrid = self.container.getFirstChildByType('datagrid2');
  const aFilters = [{ fieldname: 'objecttypeguid', operator: '=', value: JSON.stringify(oVal) }];

  oGrid.setFilterFields(aFilters);
  oGrid.dataSource.setFilter(oGrid.filter, oGrid);

};

/**
 * Method added on row chosen we will replace the designer instance creating a new instance from the selected object master grid
 * @param {object}  self
 */
akioma.repository.onChosenPlaceholder = function(self) {
  // on row chosen we will replace the designer instance creating a new instance from the selected object master grid
  const oBE = self.container.getLink('PRIMARYSDO:TARGET').controller;
  const designerObj = self.container.controller.customData.designer;
  const model = designerObj.dhx.getService('UIState');
  const akDesigner = designerObj;

  const tGuid = self.container.controller.customData.replaceGuid;

  const oSelectedMasterRecord = oBE.getSelectedRecord();
  const tItem = _.findWhere(model.pull, { guid: tGuid });

  const oInstanceStore = akDesigner.businessEntity.getStore('eSmartObjectInstance');

  akDesigner.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');

  // get sequence number
  let iSequence = akDesigner._getObjectSequence(tItem, oSelectedMasterRecord);

  const cUniqueInstanceName = oSelectedMasterRecord.objectname;

  akDesigner._removeInstance(tItem.guid);

  let cPageGuidFromParent = '';
  // here we would check if correct drag&drop
  if ((oSelectedMasterRecord && oSelectedMasterRecord.pageguid == undefined) && tItem && tItem.$parent) {
    const tItemParent = model.getItem(tItem.$parent);
    if (tItemParent.config && tItemParent.config.id && akDesigner.aPageGuids.indexOf(tItemParent.config.id) != -1)
      cPageGuidFromParent = tItemParent.config.id;

  }

  if (iSequence < 1)
    iSequence = 1;

  const cNewUniqueInstanceName = cUniqueInstanceName;

  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

  const cNextAddedInsID = uuid();

  oInstanceStore.add({
    id: cNextAddedInsID,
    instancedescription: '',
    instancename: cUniqueInstanceName,
    containerobjectmasterguid: akDesigner.cLoadedObjMasterGuid,
    objectmasterguid: oSelectedMasterRecord.objectmasterguid,
    objectsequence: (iSequence + 1),
    layoutposition: alphabet[iSequence - 1],
    instancetypename: oSelectedMasterRecord.objecttypename,
    parentinstanceguid: '',
    pageguid: (oSelectedMasterRecord.pageguid || cPageGuidFromParent || '')
  }, cNextAddedInsID);

  oInstanceStore.item(cNextAddedInsID).objectinstanceguid = cNextAddedInsID;

  const cNewInstanceGuid = cNextAddedInsID;

  akDesigner.businessEntity.jsdo.subscribe('afterCreate', function onAfterCreate(jsdo, record, success) {
    // clear saved instance guid from memory
    if (success) {
      jsdo.unsubscribe('afterCreate', onAfterCreate);
      const instanceIndex = akDesigner.newInstances.indexOf(cNextAddedInsID);
      if (instanceIndex >= 0) {
        akDesigner.newInstances.splice(instanceIndex, 1);
        akDesigner.lastSelectedInstance = null;
      }
      // clear saved placeholder instances from memory
      const placeholderInstance = akDesigner.newPlaceholderInstances.find(item => item.newInstanceGuid === cNextAddedInsID);
      if (placeholderInstance) {
        const placeholderInstanceIndex = akDesigner.newPlaceholderInstances.indexOf(placeholderInstance);
        if (placeholderInstanceIndex >= 0)
          akDesigner.newPlaceholderInstances.splice(placeholderInstanceIndex, 1);

      }
    }
  });

  // replace removed guid instance with new created instance
  akDesigner._replaceLinks(tItem.guid, cNewInstanceGuid);

  // register new instance guid
  akDesigner.newInstances.push(cNextAddedInsID);
  akDesigner.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');

  const oInstanceJSDO = akDesigner.businessEntity.jsdo[akDesigner.businessEntity.entityName];
  oInstanceJSDO._jsdo._buffers[oInstanceJSDO._parent]._setRecord(oInstanceJSDO._jsdo._buffers[oInstanceJSDO._parent]._findFirst());

  // create the object instance that will replace the existing one in designer
  const cOriginalObjType = akDesigner.getObjType(oSelectedMasterRecord.objecttypename);
  let ui = cOriginalObjType;
  if (ui != 'akgridcol' && ui != 'swattoolbar' && ui != 'swatribbon')
    ui = 'abstract';

  if (cOriginalObjType == 'ribbon')
    ui = 'ribbon';
  if (cOriginalObjType == 'swatbusinessentity')
    ui = 'swatbusinessentity';


  const snippetId = uuid();
  const snippet = akDesigner.dhx.getService('UISnippets');

  const data = {
    id: snippetId,
    ui: ui,
    $id: snippetId,
    $objtype: cOriginalObjType,
    guid: cNewInstanceGuid,
    header: cNewUniqueInstanceName,
    instancename: cNewUniqueInstanceName,
    config: {
      header: cNewUniqueInstanceName,
      $name: `${cNewUniqueInstanceName}/${cOriginalObjType}`,
      $objicon: akioma.repository.getObjIcon(cOriginalObjType.toLowerCase()),
      $objstyle: akioma.repository.getObjStyle(oSelectedMasterRecord.objecttypename),
      $objtype: cOriginalObjType,
      $objmasterguid: oSelectedMasterRecord.objectmasterguid,
      $orgobjtype: oSelectedMasterRecord.objecttypename,
      typename: oSelectedMasterRecord.objecttypename,
      $objname: cNewUniqueInstanceName,
      $objdesc: oSelectedMasterRecord.objectdescription
    },
    _custom: []
  };

  // mark placeholder entries
  if (tItem.config.$objtype.includes('placeholder')) {
    // copy old attributes to new attribute values
    const oResult = akDesigner.copyAttributes(tGuid, cNextAddedInsID);

    // next replace the instance in designer with the new attributes
    const customConfig = oResult;
    if (Array.isArray(customConfig))
      data._custom = customConfig;


    akDesigner.newPlaceholderInstances.push({
      newInstanceGuid: cNextAddedInsID,
      oldInstanceGuid: tItem.guid
    });
  }

  akDesigner.dhx.addSnippet(data);

  const tId = tItem.$id;
  const item = model.getItem(+tId);

  model.replace(item.$id, snippet.getItem(snippetId));
  model.bus.callEvent('store:onCursor', [ item.$id, 0, item ]);
  model.setCursor(item.$id);

  const el = snippet.getItem(snippetId);
  const navigationObjectTypes = [ 'swattoolbar', 'swatribbon', 'toolbar', 'ribbon' ];
  if (navigationObjectTypes.indexOf(el.ui) !== -1) {
    try {
      const aExisting = model.branch[item.$parent] || [];
      if (navigationObjectTypes.indexOf(el.ui) === -1) {
        aExisting.splice(aExisting.indexOf(item) + 1, 0, {
          ui: 'empty',
          config: {}
        });
      }

      model.pull = _.omit(model.pull, item => aExisting.indexOf(item) > -1);
      model.branch[item.$parent] = aExisting;
      model._parse_rec(item.$parent, aExisting);
      model.bus.callEvent('store:onDataRefresh', [{}]);
    } catch (e) {
      console.warn('Adding to tree branch failed!', e);
    }
  }
  self.container.controller.close();
};

/**
 * Method for saving the changes in LayoutDesigner
 * @param {object} self
 * @returns {void}
 */
akioma.saveLayoutDesigner = function(self) {

  // clears has changes for the form designer attributes
  const panelSwitcher = self.container.getFirstChildByType('panelSwitcher');
  if (panelSwitcher)
    akioma.repository.AttributeForm.clearHasChangesAttributeForms(panelSwitcher);


  if (akioma.repository.designerTypes.current === 'instancesdesign')
    return akioma.repository.saveInstancesDesignerType(self); // save instances from grid and instances property grid


  const designer = self.container.getFirstChildByType('designer');

  // stop right here if no object opened or if will not open in containerdesign mode
  if (designer.cLoadedObjMasterGuid == '' && akioma.repository.designerTypes.current == 'containerdesign')
    return false;

  akioma.repository.saveObjectMasterProps(self); // save instances master from property grid

  const oDesignerBE = designer.businessEntity;

  // fix for working record in jsdo before save
  oDesignerBE.bSendAllRecords = true;
  let oInstanceJSDO = oDesignerBE.jsdo[oDesignerBE.entityName];
  oInstanceJSDO._parent = oInstanceJSDO._name;
  oInstanceJSDO._jsdo.useRelationships = false;
  const oFirstElm = oInstanceJSDO._jsdo._buffers[oInstanceJSDO._name]._findFirst();
  if (oFirstElm)
    oInstanceJSDO._jsdo._buffers[oInstanceJSDO._name]._setRecord(oFirstElm);

  // save instances data in store
  oDesignerBE.switchJSDOWorkingRecord('eSmartObjectInstance');
  oDesignerBE.getStoreConnector('eSmartObjectInstance').beforeSendDataAll = true;
  oDesignerBE.getStoreConnector('eSmartObjectInstance').sendData();

  // save object master LayoutOptions
  designer._saveMasterLayoutOptions();

  // save pages from store
  oDesignerBE.switchJSDOWorkingRecord('eSmartPage');
  oDesignerBE.getStoreConnector('eSmartPage').sendData();
  oDesignerBE.bSendAllRecords = true;

  oDesignerBE.switchJSDOWorkingRecord('eSmartLink');

  // working record fix
  oInstanceJSDO = oDesignerBE.jsdo[oDesignerBE.entityName];
  oInstanceJSDO._parent = oInstanceJSDO._name;
  oInstanceJSDO._jsdo.useRelationships = false;
  const oFirst = oInstanceJSDO._jsdo._buffers[oInstanceJSDO._name]._findFirst();
  if (oFirst)
    oInstanceJSDO._jsdo._buffers[oInstanceJSDO._name]._setRecord(oFirst);


  // save smart links
  oDesignerBE.getStoreConnector('eSmartLink').beforeSendDataAll = true;
  oDesignerBE.getStoreConnector('eSmartLink').sendData();


  // save opened object master
  oDesignerBE.switchJSDOWorkingRecord('eSmartObjectMaster');
  oDesignerBE.getStoreConnector('eSmartObjectMaster').beforeSendDataAll = true;
  oDesignerBE.getStoreConnector('eSmartObjectMaster').sendData();


  if (oDesignerBE.jsdo.hasChanges()) {
    self.container.controller.dhx.progressOn();

    // on after save changes wait for all
    oDesignerBE.jsdo.subscribe('afterSaveChanges', function onAfterSaveChanges(jsdo, success) {
      jsdo.unsubscribe('afterSaveChanges', onAfterSaveChanges);

      if (success) {
        const masterRecord = jsdo.eSmartObjectMaster.getData()[0];

        designer.setActiveMasterProps({
          LoadedObjMasterGuid: masterRecord.ObjectMasterGuid,
          LoadedObjMasterName: masterRecord.ObjectName,
          LoadedObjMasterType: masterRecord.ObjectTypeName.toLowerCase()
        });

        akioma.repository.saveLayoutDesignerAttributes(self).then(() => {
          akioma.notification({ type: 'success', text: 'Repository objects saved successfuly.', expire: 3000 });

          if (akioma.repository.designerTypes.current === 'formdesign')
            return window.akioma.repository.computeFormGridLayoutProperties(oDesignerBE);
        }).then(() => {
          self.container.controller.dhx.progressOff();
          akioma.reloadSelectedDesignerObject(self);
        }).catch(() => {
          self.container.controller.dhx.progressOff();
          akioma.notification({ type: 'error', text: 'Repository objects save failed.', expire: 3000 });
        });
      }
    });

    // save JSDO changes
    oDesignerBE.jsdo.saveChanges(true).catch(e => {
      self.container.controller.dhx.progressOff();
      akioma.reloadSelectedDesignerObject(self);
      akioma.log.error(e);
    });
  } else {
    self.container.controller.dhx.progressOn();
    // save instances attributes, hide progress icon
    akioma.repository.saveLayoutDesignerAttributes(self).done(() => {
      akioma.reloadSelectedDesignerObject(self);
    });
  }
};

// deprecated method, use akioma.saveLayoutDesigner instead
akioma.saveObjectDialog = akioma.saveLayoutDesigner;

/**
 * Method for activating repository object drag&drop in form
 */
akioma.repository.activateRepoDragDrop = function(oWindow) {
  const oGrid = oWindow.controller.getDescendant('datagrid2').dhx;

  window.lastOpenedDraggableFormbuilderGrid = oGrid;

  if (window.lastOpenedFormBuilder != undefined &&
        window.lastOpenedDraggableFormbuilderGrid && window.myMockup !== undefined)
    window.myMockup.initCustomDrop(oGrid);

};

/**
 * Method for activating grid drag&drop to add instances, for layout designer instances tab
 * @param {object}  self
 */
akioma.repository.activateInstancesGridDragDrop = function(self) {
  const oGrid = self.controller.dhx;

  oGrid.gridToGrid = function(rowId, sgrid) {
    const z = sgrid.akElm.dataSource.dhx.item(rowId); // prepare data for the target grid
    return z;
  };
  // snippet just copies the data, without applying any modifications to it
  oGrid._customDropHandler = function(nid, data) {
    const oInsStore = oGrid.akElm.dataSource.getStore('eSmartObjectInstance');

    const cNewObjInsGuid = uuid();

    const cNextAddedInsID = cNewObjInsGuid;
    let cNewFieldName = data.objectname;
    const aAllStore = oInsStore.data.pull;

    if (cNewFieldName.indexOf('.') > -1)
      cNewFieldName = cNewFieldName.substr(cNewFieldName.indexOf('.') + 1);

    const cUniqueInstanceName = akioma.SearchUniqueInstanceName(cNewFieldName, Object.values(aAllStore));
    oInsStore.add({
      instancedescription: '',
      instancename: cUniqueInstanceName,
      objectinstanceguid: cNewObjInsGuid,
      containerobjectmasterguid: akioma.repository.designerTypes.lastObjMasterGuidOpened,
      objectmasterguid: data.objectmasterguid,
      instancetypename: data.objecttypename, // add object master type
      objectsequence: 1,
      objectmastername: cUniqueInstanceName
    }, cNextAddedInsID);

    // const oPropGrid = oGrid.akElm.dynObject.container.getObject('MainInsAttributePropertyGrid');
    const akDesigner = oGrid.akElm.dynObject.container.getFirstChildByType('designer');
    akDesigner.newInstances.push(cNextAddedInsID);

    const targetData = akDesigner.dhx.getService('UIState').pull;
    const targetId = Object.keys(targetData).find(id => targetData[id].$parent === 0);

    const model = akDesigner.dhx.getService('UIState');
    const item = {
      id: cNextAddedInsID,
      guid: cNextAddedInsID,
      ui: data.objecttypename,
      instancename: cNextAddedInsID,
      $objtype: data.objecttypename,
      config: {
        $name: `${cUniqueInstanceName} / ${data.objecttypename}`,
        header: cUniqueInstanceName,
        $objicon: akioma.repository.getObjIcon(data.objecttypename),
        $objstyle: akioma.repository.getObjStyle(data.objecttypename),
        $objtype: data.objecttypename,
        $orgobjtype: data.objecttypename.toLowerCase(),
        typename: data.objecttypename,
        $objname: cUniqueInstanceName
      }
    };

    try {
      // make sure to add to the correct branch
      const aExisting = model.branch[targetId];
      const items = [item].concat(aExisting);

      model._parse_rec(targetId, items);

    } catch (e) {
      console.warn('Adding to tree branch failed!', e);
    }

    oGrid.selectRowById(cNewObjInsGuid, false, true, true);

    return false;
  };
};

/**
 * Method for launch from ribbon the selected opened screen
 *
 * @param   {object}  self  ribbon control
 *
 */
akioma.launchSelectedDesignerObject = function(self) {
  const des = self.container.controller.getDescendant('designer');
  if (des.cLoadedObjMasterName) {
    akioma.invokeServerTask({
      name: 'Akioma.Swat.Repository.ObjectMasterTask',
      methodName: 'ClearObjectMasterReferencesFromCache',
      paramObj: { plcParameter: { Value: des.cLoadedObjMasterName } }
    }).fail(oError => {
      akioma.log.error(oError);
    }).done(() => {
      akioma.launchContainer(des.cLoadedObjMasterName);
    });
  } else {
    akioma.notification({
      text: 'Please select an object to launch.',
      type: 'warning'
    });
  }
};

/**
 * Method for reloading selected in designer
 *
 * @param   {object}  self  Ribbon control
 *
 */
akioma.reloadSelectedDesignerObject = function(self) {
  const des = self.container.controller.getDescendant('designer');
  const cObjMasterName = des.cLoadedObjMasterName;

  if (cObjMasterName) {
    const cObjMasterGuid = des.cLoadedObjMasterGuid;
    const cObjMasterType = des.cLoadedObjMasterType.toLowerCase();

    des.saveDesignerTreeState();
    akioma.loadRepoObjects(self, cObjMasterName, cObjMasterGuid, cObjMasterType);

  } else {
    akioma.notification({
      text: 'Please select an object to reload.',
      type: 'warning'
    });
  }
};

/**
 * Method for loading a repository object from business entity of designer
 * @param {object} self
 * @returns {void}
 */
akioma.formOpenRepository = function(self) {
  let akGrid = self.controller.parent.getDescendant('datagrid2');
  if (akGrid == null)
    akGrid = self.controller.parent.getDescendant('datagrid');

  const oGrid = akGrid.dhx;
  const oSelectedNewObjectMaster = akGrid.dataSource.dhx.item(oGrid.getSelectedRowId());
  const cObjMasterName = oSelectedNewObjectMaster.objectname;
  const cObjMasterGuid = oSelectedNewObjectMaster.objectmasterguid;
  const oFormBuilder = self.controller.getAncestor('tabbar').childs[0].getDescendant('formbuilder');
  const oFormBuilderBE = oFormBuilder.store;

  // reset datastore connector updated rows
  oFormBuilderBE.getStoreConnector('eSmartObjectInstance').updatedRows = [];

  oFormBuilderBE.query.clearAll();
  oFormBuilderBE.query.addCondition('ObjectName', 'eq', cObjMasterName);

  oFormBuilderBE.aCallback['afterFill'] = function() {
    oFormBuilder.formDesignerBuild(cObjMasterName, cObjMasterGuid);
    self.controller.getAncestor('tabbar').dhx.progressOff();
  };

  oFormBuilderBE.openQuery({ firstQuery: true });

  self.controller.getAncestor('tabbar').childs[0].dhx.setActive();
};

/**
 * Method used for saving the contianer builder instances attributes, in containerdesign mode
 * @param {object} self
 * @param {boolean} bProgressOff
 * @returns {Deferred}
 */
akioma.repository.saveLayoutDesignerAttributes = function(self) {
  // save all attributes temporarely saved for instances
  const designer = self.container.getFirstChildByType('designer');
  const oPromiseSaveAttrs = designer.saveAttributes();
  return oPromiseSaveAttrs;
};

/**
 * Method for saving form builder attributes
 * @returns {Deferred}
 */
akioma.repository.saveFormBuilderAttributes = function(self) {
  const formBuilder = self.container.getFirstChildByType('formbuilder');
  const oPromiseSaveAttrs = formBuilder.saveAttributes();
  const oDesignerBE = formBuilder.dynObject.container.getLink('PRIMARYSDO:TARGET').controller;

  oPromiseSaveAttrs.done(r => {
    if (r.hasChanges)
      akioma.notification({ type: 'success', text: 'Form builder changes have been saved successfuly.', expire: 3000 });

    akioma.repository.computeFormGridLayoutProperties(oDesignerBE);
  }).fail(() => {
    akioma.notification({ type: 'error', text: 'There was an error saving the Form builder changes.', expire: 3000 });
  });

  return oPromiseSaveAttrs;
};

/**
 * Method for saving the form builder changes, save button in toolbar
 * @param {objec} self
 * @returns {void}
 */
akioma.saveFormBuilder = function(self) {
  const formBuilder = self.container.getFirstChildByType('formbuilder');
  const oFormDesignerBE = formBuilder.businessEntity;
  const updatedRows = oFormDesignerBE.getStoreConnector('eSmartObjectInstance').updatedRows;

  const oWind = self.controller.getAncestor('window');
  if (updatedRows.length > 0)
    oWind.dhx.progressOn();


  oFormDesignerBE.switchJSDOWorkingRecord('eSmartObjectInstance');
  oFormDesignerBE.bSendAllRecords = true;
  oFormDesignerBE.getStoreConnector('eSmartObjectInstance').beforeSendDataAll = true;
  oFormDesignerBE.getStoreConnector('eSmartObjectInstance').sendData();

  oFormDesignerBE.switchJSDOWorkingRecord('eSmartObjectMaster');
  oFormDesignerBE.getStoreConnector('eSmartObjectMaster').beforeSendDataAll = true;
  oFormDesignerBE.getStoreConnector('eSmartObjectMaster').sendData();

  oFormDesignerBE.switchJSDOWorkingRecord('eSmartObjectInstance');

  const oInstanceJSDO = oFormDesignerBE.jsdo;
  // save instances here
  if (oInstanceJSDO.hasChanges())
    oInstanceJSDO.saveChanges(true);

  // if no instances to save then save temp attributes
  if (!oInstanceJSDO.hasChanges()) {
    // saving the temporary attributes per instance level
    akioma.repository.saveFormBuilderAttributes(self);
  } else {
    oInstanceJSDO.subscribe('afterSaveChanges', function onAfterSaveChanges(jsdo, success) {
      jsdo.unsubscribe('afterSaveChanges', onAfterSaveChanges);
      oWind.dhx.progressOff();
      if (success) {
        // saving the temporary attributes per instance level
        akioma.repository.saveFormBuilderAttributes(self);
      } else
        akioma.notification({ type: 'error', text: 'There was an error saving the Form builder changes.', expire: 3000 });
    });
  }
};

/**
 * Method for seaching for unique instance name in store
 */
akioma.SearchUniqueInstanceName = function(cInsName, oStore) {
  const noOfExisting = _.filter(oStore, item => item.instancename == cInsName).length;

  if (noOfExisting > 0)
    return akioma.SearchUniqueInstanceName(cInsName + noOfExisting, oStore);
  else
    return cInsName;
};


/**
 * Method for returning object Style of each icon in layout designer
 * @param {string} objType The object type
 * @returns {string} Styling for corresponding object type icon, css variables
 */
akioma.repository.getObjStyle = function(objType) {
  try {
    objType = objType.toLowerCase();

    switch (objType) {
      case 'form':
        objType = 'swatform';
        break;
      case 'treegrid':
        objType = 'swattreegrid';
        break;
    }

    let repoIcon = akioma.repository.LayoutDesigner.icons[objType.toLowerCase()];
    if (!repoIcon)
      repoIcon = akioma.repository.LayoutDesigner.icons.default;

    return repoIcon.style;
  } catch (e) {
    console.warn(e);
  }
};

/**
 * Get object icon duotone in designer based on objecty type in layout designer
 * @param {string} objType The object type
 * @returns {string} The object icon based on type
 */
akioma.repository.getObjIcon = function(objType) {
  let iconPath = '';
  objType = objType.toLowerCase();
  switch (objType) {
    case 'businessentity':
      objType = 'swatbusinessentity';
      break;
    case 'dataview':
      objType = 'swatdataview';
      break;
    case 'docviewer':
      objType = 'swatdocviewer';
      break;
    case 'form':
      objType = 'swatform';
      break;
    case 'formbuilder':
      objType = 'swatformdesign';
      break;
    case 'frame':
      objType = 'swatframe';
      break;
    case 'grid':
      objType = 'swatgrid';
      break;
    case 'image':
      objType = 'swatimage';
      break;
    case 'tabbar':
      objType = 'swattabbar';
      break;
    case 'text':
      objType = 'swattext';
      break;
    case 'treegrid':
      objType = 'swattreegrid';
      break;
    case 'ribbon':
      objType = 'swatribbon';

      break;
    case 'toolbar':
      objType = 'swattoolbar';
  }

  const repoIcon = akioma.repository.LayoutDesigner.icons[objType];
  if (repoIcon)
    iconPath = repoIcon.icon;
  else
    iconPath = akioma.repository.LayoutDesigner.icons.default.icon;

  return iconPath;
};


akioma.repository.setBatchModule = function(self) {
  self.controller.businessEntity.opt.numRecords = 0;
};

akioma.repository.onBeforeFetchNewObjMaster = function(self) {
  self.controller.businessEntity.opt.tableRefFilter = 'eSmartObjectMaster';
  self.controller.businessEntity.opt.customContext = 'SkipCalculatedFields';
  self.controller.businessEntity.opt.numRecords = 50;
};

akioma.repository.onBeforeFetchObjectTypeEntity = function(self) {
  self.controller.businessEntity.opt.customContext = 'NoCalcFields';
  self.controller.businessEntity.opt.numRecords = 10;
};

/**
 * Computies form grid layout properties from the ribbon
 *
 * @param {object} ribbon The ribbon object
 */
akioma.repository.computeFormGridLayoutPropertiesRibbon = function(ribbon) {
  const dynObject = ribbon.dynObject || ribbon;
  const container = dynObject.container;
  const containerName = container.name.toLowerCase();
  const dataSource = (containerName === 'layoutdesignerw' || containerName === 'objectdesignerw'
    ? container.getObject('ActiveMasterBE') : container.getLink('PRIMARYSDO:TARGET'));

  akioma.repository.computeFormGridLayoutProperties(dataSource.controller);
};

/**
 * Method for computing Form Grid Layout Properties, clears attributes no success
 *
 * @param   {object} oDesignerBE The designer BE controller
 * @return  {void}
 */
akioma.repository.computeFormGridLayoutProperties = function(oDesignerBE) {

  const BEMasterStore = oDesignerBE.getStore('eSmartObjectMaster');
  const ObjectMasterGuid = BEMasterStore.item(BEMasterStore.first())['objectmasterguid'];
  const oWin = oDesignerBE.dynObject.container;

  return akioma.invokeServerTask(
    {
      name: 'Akioma.Swat.Repository.FormDesignerBT',
      methodName: 'ComputeGridLayoutProperties',
      paramObj: { plcParameter: { Value: ObjectMasterGuid } },
      showWaitCursor: true,
      uiContext: oWin
    }
  ).done(() => {
    akioma.notification({ type: 'success', text: 'Form builder grid sizes calculated successfully.', expire: 3000 });

    akioma.repository.clearObjectMasterCache([{ ObjectMasterGuid }]);
  });
};

/**
 * Method for setting initial grid for adding new datafields in form designer
 * @param {object} self
 * @returns {void}
 */
akioma.repository.setFormBuilderGridMasterNamedQuery = function(self) {
  self.controller.setNamedQueryParam('OfType', 'Type', 'SwatDatafield', 'character');
  self.controller.setNamedQueryParam('OfType', 'IncludeSubTypes', true, 'LOGICAL');
  self.controller.applyAllQueryFilters(true);
};

/**
 * Clears the server cache for the specified object master/s.
 *
 * @param {Array} objectMasterTable Object master table containing object master records for which to clear cache.
 *
 * @returns {Promise}
 */
akioma.repository.clearObjectMasterCache = function(objectMasterTable) {
  const serverTaskPromises = [];

  objectMasterTable.forEach(objectMaster => {
    const clearCachePromise = akioma.invokeServerTask({
      name: 'Akioma.Swat.Repository.ObjectMasterTask',
      methodName: 'ClearObjectMasterReferencesFromCache',
      paramObj: { plcParameter: { Value: objectMaster.ObjectMasterGuid } }
    });

    serverTaskPromises.push(clearCachePromise);
  });

  return Promise.all(serverTaskPromises);
};

akioma.repository.desktop = {
  launchSelectedObject: oToolbar => {
    const oDesktop = oToolbar.dynObject.screen;
    const oObjectBE = oDesktop.getLink('PRIMARYSDO:TARGET');
    const cSelectedObject = oObjectBE.controller.getFieldValue('objectname');
    app.controller.launchContainer({ containerName: cSelectedObject });
  }
};

/**
 * Method for triggering assign of object type attribute
 * @param {object} oToolbar
 * @returns {promise}
 */
akioma.repository.addObjectTypeAttribute = function(oToolbar) {
  const oWindow = oToolbar.container;
  const oForm = oWindow.getFirstChildByType('form');

  return akioma.invokeServerTask({
    name: 'Akioma.Swat.Repository.AttributeValuesTask',
    methodName: 'AddObjectTypeAttribute',
    paramObj: {
      plcParameter: {
        ObjectTypeGuid: oForm.dynObject.getValue('ObjectTypeGuid'),
        AttributeLabel: oForm.dynObject.getValue('AttributeLabel')
      }
    }
  }).then(() => {
    oWindow.controller.close();
    const oPropertyGrid = oWindow.caller;
    oPropertyGrid.controller.getProperties(oPropertyGrid.controller.dataSource.getIndex());
  });
};

/**
 * Method for committing attribute property grid changes
 * @param {object} propertyGrid
 * @param {object} options
 * @param {string} options.ObjectTypeGuid
 * @param {string} options.ObjectMasterGuid
 * @param {string} options.ObjectInstanceGuid
 * @returns {void}
 */
akioma.repository.saveAttributePropertyGridChanges = function(propertyGrid, { ObjectTypeGuid = null, ObjectMasterGuid = null, ObjectInstanceGuid = null }) {
  let methodName = '';
  if (ObjectTypeGuid)
    methodName = 'UpdateObjectTypeDesignAttributes';
  if (ObjectMasterGuid)
    methodName = 'UpdateObjectMasterDesignAttributes';
  if (ObjectInstanceGuid)
    methodName = 'UpdateObjectInstanceDesignAttributes';
  if (methodName === '')
    return;

  const payload = [];
  propertyGrid.dc.updatedRows.forEach(id => {
    const row = propertyGrid.dc._getRowData(id);
    switch (row.repositorytype.toUpperCase()) {
      case 'CHARACTER':
        row.charactervalue = row.attributevalue;
        break;
      case 'LOGICAL':
        row.logicalvalue = row.attributevalue;
        break;
      case 'DECIMAL':
        row.decimalvalue = row.attributevalue;
        break;
      case 'INTEGER':
        row.integervalue = row.attributevalue;
        break;
    }
    row.objectmasterguid = '';
    row.objectinstanceguid = '';
    payload.push(row);
  });

  akioma.invokeServerTask({
    name: propertyGrid.opt.resourceName,
    methodName,
    paramObj: {
      plcParameter: { ObjectTypeGuid, ObjectMasterGuid, ObjectInstanceGuid },
      dsDesignAttributeValue: { dsDesignAttributeValue: { eDesignAttributeValue: payload } }
    }
  }).then(() => {
    propertyGrid.getProperties(propertyGrid.dataSource.getIndex());
  }).fail(() => {
    akioma.notification({ type: 'error', text: 'Could not update object attribute!' });
  });
};
