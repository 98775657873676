/**
 * Dynselect custom adapters from dynselect and listItemsPairs
 */
$.fn.select2.amd.define('select2/data/customDataAdapter', [
  'select2/data/array',
  'select2/utils'
],
(ArrayAdapter, Utils) => {

  function CustomData($element, options) {
    this.$element = $element;
    this.options = options;
    CustomData.__super__.constructor.call(this, $element, options);
  }

  Utils.Extend(CustomData, ArrayAdapter);

  CustomData.prototype.query = function(params, callback) {
    const data = { results: [] };

    const oSelf = this.options.options.self;
    const currElement = this.$element;

    if (oSelf.readonly)
      return;


    // Clean results list before fetch.
    this.container.$results.find('li.select2-results__option').remove();
    this.container.dropdown._positionDropdown();

    oSelf._bDynSelectTriggered = false;
    oSelf._bDynSelectPending = true;

    if (params.term == undefined)
      params.term = '';

    if (params.term && params.term.length > 0)
      oSelf.bStopInitialFetch = true;

    if (oSelf.opt.initialFetch && !oSelf.bStopInitialFetch) {
      params.term = oSelf.opt.initialFetch;
      params.queryType = 'initialFetch';
    }

    if (oSelf.opt.preSelectExistingInput) {
      const oInputSearchVal = $(oSelf.select2).data('select2').$dropdown.find('input.select2-search__field').val();
      params.term = params.term || oInputSearchVal;
    }
    // call on before event code to specify calculated fields in businessEntity fetch
    //
    if (oSelf.opt.fieldlist) {
      const query = oSelf.businessEntity.getQuery();
      query.setFieldList(oSelf.opt.fieldlist);
    }
    /**
     * Code executed in the client before a fetch request has been made.
     * @event ak_dynselect#EventBeforeFetch
     * @type {object}
     */
    if (oSelf.opt.onBeforeFetch)
      app.controller.callAkiomaCode(oSelf.dynObject || oSelf, oSelf.opt.onBeforeFetch);

    if (typeof (params.term) == 'string') {

      const getJSDOData = () => {
        oSelf.businessEntity.aAfterFillOnce = [];

        if (oSelf.businessEntity.jsdo.fillxhr)
          oSelf.businessEntity.jsdo.fillxhr.abort();

        oSelf.businessEntity.setNamedQueryParam('AutoCompleteSearch', 'SearchKey', params.term, 'character');

        const oQuery = oSelf.businessEntity.getQuery();

        if (oSelf.opt.defaultSort !== '')
          oQuery.setStringSorting(oSelf.opt.defaultSort);

        if (typeof oSelf._setBatchSize === 'function')
          oSelf._setBatchSize();

        if ($(currElement).data('bMakeRequest') && !oSelf.readonly) {
          oSelf.businessEntity.setOpenQueryTimeoutDelay(0);
          oSelf.businessEntity.openQuery({ applyFilters: true });
        }

        oSelf.businessEntity.addAfterFillOnceCallback(rows => {
          data.results = rows;

          callback(data);

          oSelf._bDynSelectPending = false;
          if (oSelf._bDynSelectTriggered) { // if enter was pressed during request
            const enterKeyEvent = jQuery.Event('keydown', { keyCode: 13, which: 13 });
            // Trigger enter key press to select first result
            $(oSelf.select2).parent().find('input.select2-search__field').trigger(enterKeyEvent);
            $(oSelf.select2).data('select2').$dropdown.find('input.select2-search__field').trigger(enterKeyEvent);
            oSelf._bDynSelectTriggered = false;
          }
        });
      };

      if (oSelf.requestDelay)
        clearTimeout(oSelf.requestDelay);

      oSelf.requestDelay = setTimeout(getJSDOData, (oSelf.opt.delay || 350));
    }
  };

  return CustomData;
}
);

$.fn.select2.amd.define('select2/data/customListItemsAdapter', [ 'select2/data/array', 'select2/utils' ],
  (ArrayAdapter, Utils) => {
    function CustomDataAdapter($element, options) {
      CustomDataAdapter.__super__.constructor.call(this, $element, options);
    }
    Utils.Extend(CustomDataAdapter, ArrayAdapter);
    CustomDataAdapter.prototype.updateOptions = function(data) {
      this.$element.find('option').remove();
      this.addOptions(this.convertToOptions(data));
    };
    return CustomDataAdapter;
  }
);
/**
* @class DynSelect
* @namespace akioma
*/
akioma.DynSelect = class {
  /**
   * Creates a dynSelect object
   * @param  {Object}   oSelf Object which will have the dynSelect attached (e.g ak_dynSelect, ak_datagridcol2, ak_ribbon)
   * @param  {HTMLNode} oElement Html select element where the dynSelect will be created
   * @param  {Object}   oAttributes Attributes for configuring a dynSelect
   */
  constructor(oSelf, oElement, oAttributes) {

    // setting column object and element
    this.self = oSelf;
    this.oElement = oElement;

    this.log = akioma.log.getLogger('DynSelectControl');

    // setting up attributes for grid cell
    if (oAttributes == undefined)
      this.oAttributes = oSelf.opt;
    else
      this.oAttributes = oAttributes;

    // businessEntity linked should setup generic template
    if (oSelf.businessEntity) {
      this.templateOptionsManager = new akioma.TemplateOptions(this.oAttributes.templateOptions);
      const templateSettingsKeys = this.templateOptionsManager.getSettings();
      const aKeys = this.templateOptionsManager.getFields();
      // check if multi fields
      const aFieldsShowImg = aKeys[0]; // img
      const aFieldsShowKey = (aKeys[1]) ? aKeys[1].split(',') : ''; // key
      const aFieldsShowDesc = (aKeys[2]) ? aKeys[2].split(',') : ''; // desc

      this.genericTemplateName = this.oAttributes.template;
      this.aGenericKeys = {
        img: aFieldsShowImg,
        key: aFieldsShowKey,
        desc: aFieldsShowDesc,
        settings: templateSettingsKeys
      };
    }

    const cName = (oSelf.view == 'dynselect') ? oSelf.opt.name : oSelf.opt.dataField;

    this.name = cName;

    this.self.bStopInitialFetch = false;

    this.log.info('Creating DynSelect object for: ', this.self);

    // creates select2 object
    this.createSelectObject();

    // binds select 2 before core listeners
    this._bindSelectBeforeEvents();

    // binds select2 events
    this.bindSelectEvents();

    if (oSelf.readonly != undefined)
      oSelf.setReadonly(oSelf.readonly);

    oSelf._bDynSelectPending = false;
    oSelf._bDynSelectTriggered = false;

    if (oAttributes && oAttributes.aNewOptions)
      oSelf.select2.data('select2').dataAdapter.updateOptions(oAttributes.aNewOptions);

    this._allowSearchOnFocus();

    if (oSelf.opt.resultListMenuCode) {
      if (!oSelf.menuLookup)
        oSelf.createMenuLookup(oSelf.opt.resultListMenuCode, oSelf.select2.data('select2').dataAdapter.container.$results, false);
    }
  }

  /**
   * Creates new select2 object
   * @instance
   * @memberof DynSelect
   * @return  {void}
   */
  createSelectObject() {

    const oSelf = this.self;
    const oElement = this.oElement;
    const customDataAdapter = $.fn.select2.amd.require('select2/data/customDataAdapter');
    const customListItemsAdapter = $.fn.select2.amd.require('select2/data/customListItemsAdapter');

    let oHtmlSelect = $(oElement).find('select.dynSelect');
    if (oHtmlSelect.length == 0 && this.oAttributes.aNewOptions)
      oHtmlSelect = oElement;


    let cDropDownClass;
    switch (oSelf.view) {
      case 'dynselect':
        cDropDownClass = 'akFormDynSelectDropdown';
        break;
      case 'dynSelectPanelHeader':
        cDropDownClass = 'akPanelDynSelectDropdown';
        break;
      default:
        cDropDownClass = 'akGridDynSelectDropdown';
        break;
    }

    const oConfigOptions = {
      maximumSelectionLength: this.self.maximumSelectionLength,
      minimumInputLength: (this.oAttributes.minimumInputLength) ? this.oAttributes.minimumInputLength : 0,
      placeholder: (this.oAttributes.placeholder) ? this.oAttributes.placeholder : '',
      allowClear: (this.oAttributes.allowClear) ? true : false,
      closeOnSelect: (this.oAttributes.closeOnSelect) ? true : false,
      tags: (this.oAttributes.tags) ? true : false,
      tokenSeparators: this.self.tokenSeparators,
      templateResult: (this.self.businessEntity) ? this.formatBE.bind(this) : this.formatLS.bind(this),
      templateSelection: (this.self.businessEntity) ? this.templateSelectionBE.bind(this) : this.templateSelectionLS.bind(this),
      language: akioma.entry(1, akioma.translation.getLanguage(), '-').toLowerCase(),
      self: this.self,
      createTag: this.tagCustom.bind(this),
      dropdownCssClass: cDropDownClass,
      escapeMarkup: function(m) {
        return m;
      }
    };

    if (oSelf.businessEntity)
      oConfigOptions['dataAdapter'] = customDataAdapter;
    else if (this.oAttributes.aNewOptions)
      oConfigOptions['dataAdapter'] = customListItemsAdapter;


    oSelf.select2 = $(oHtmlSelect).select2(
      oConfigOptions
    );

  }

  /**
   * Method for registering initial event listeners on select2 control
   * @memberof DynSelect
   * @instance
   * @private
   */
  _bindSelectBeforeEvents() {
    const select2Obj = this.self.select2.data('select2');
    select2Obj._wasOpenedFromDynselect = false;

    // on before open event
    const onBeforeDynselectOpen = function() {
      select2Obj._wasOpenedFromDynselect = true;
    };

    const onKeyPressDynselect = function(event) {
      const key = event.which;
      const ENTER_KEY = 13;
      if (ENTER_KEY === key && !select2Obj.isOpen() && select2Obj._wasOpenedFromDynselect) {
        select2Obj.options.options._preventOnceOpenOnEnterKeypress = true;
        select2Obj._wasOpenedFromDynselect = false;
      }
    };

    select2Obj.$element.parent().find('input.select2-search__field').on('keyup', () => {
      const self = this.self;
      self.typedValue = $(self.select2).parent().find('input.select2-search__field').val();

      /**
       * Client-code that will be executed after a key press.
       * @event ak_dynselect#EventAfterKeyPress
       * @type {object}
       */
      if (self.opt.EventAfterKeyPress)
        app.controller.callAkiomaCode(self, self.opt.EventAfterKeyPress);
    });

    select2Obj.$dropdown.on('keyup', 'input.select2-search__field', () => {
      const self = this.self;
      self.typedValue = $(self.select2).data('select2').$dropdown.find('input.select2-search__field').val();

      /**
       * Client-code that will be executed after a key press.
       * @event ak_dynselect#EventAfterKeyPress
       * @type {object}
       */
      if (self.opt.EventAfterKeyPress)
        app.controller.callAkiomaCode(self, self.opt.EventAfterKeyPress);
    });

    select2Obj.listeners.keypress.unshift(onKeyPressDynselect);
    select2Obj.listeners.open.unshift(onBeforeDynselectOpen);
  }

  /**
   * Binding on Select2 events
   * @memberof DynSelect
   * @instance
   * @returns {void}
   */
  bindSelectEvents() {
    const oSelf = this.self;
    const oElement = this.oElement;

    oSelf.select2.on('select2:select', e => {
      oSelf.selectionChanged(e, oElement, oSelf, this);
    }).on('select2:selecting', e => {
      if (oSelf._bDynSelectPending) {
        // check if selection was done by enter key or mouse click
        if (!e.params.args.originalEvent) // originalEvent exists only for mouse click event.
          oSelf._bDynSelectTriggered = true; // set flag that enter key was pressed
        e.preventDefault();
      } else
        oSelf.selectingResult(e, oElement, oSelf);

    }).on('select2:unselect', e => {
      oSelf.selectionRemoved(e, oElement, oSelf);

    }).on ('select2:unselecting', e => {
      if (e.params.args.originalEvent) {
        const target = e.params.args.originalEvent.currentTarget;
        if ($(target).is('li') && !oSelf.getCheckboxSelection()) {
          e.preventDefault();
          return;
        }
      }
      oSelf.unselectingResult(e, oElement, oSelf);

    }).on('select2:clearing', e => {
      oSelf.clearSelect(e, oElement, oSelf);

    }).on('select2:opening', e => {
      oSelf.openingResults(e, oElement, oSelf);

    }).on('select2:open', e => {
      oSelf.openResults(e, oElement, oSelf);

    }).on('select2:closing', e => {
      oSelf.closeResults(e, oElement, oSelf);
    });
  }

  /**
   * Controls how the dropdown looks. Used for businessEntity data. Uses handlebars templates.
   * @param   {object}  item       [item description]
   * @param   {obj}  container  [container description]
   * @memberof DynSelect
   * @return  {string}
   */
  formatBE(item, container) {
    if (item.loading == undefined) {
      try {
        if (item.text) {
          item[this.aGenericKeys['key']] = item.text;
          item[this.aGenericKeys['desc']] = item.text;
        }
        const templateF = Handlebars.compile(akioma.handlebarsTemplates[this.genericTemplateName](this.aGenericKeys, item, this.self));
        const result = templateF(item);
        item.text = result;
        $(container).attr('akId', `${this.name}-${item.selfhdl}`);
        return item.text;
      } catch (e) {
        console.error(e);
      }
    }
  }

  /**
   * Sets value of select2 control
   *
   * @param   {object}  item
   * @param   {string}  key
   * @param   {string}  desc
   * @param   {string}  shortDesc
   * @instance
   * @memberof DynSelect
   * @return  {string}
   */
  setItem(item, key, desc, shortDesc, container) {

    const oSelf = this.self;
    item.id = key;
    item.listItemKey = key;
    item.listItemDesc = desc;
    item.listItemShortDesc = shortDesc;

    oSelf.displayMultiFields(item);

    $(container).attr('akId', `${this.name}-${key}`);
    $(item.element).val(key);
    $(item.element).text(desc);
    $(item.element).data('custom', oSelf.cLookupToDisplay);
    item.listItemText = oSelf.cLookupToDisplay;
  }

  /**
   * Controls how the dropdown looks. Used for listItemPairs data. Uses handlebars templates
   * @param   {object}  item
   * @param   {object}  container
   * @memberof DynSelect
   * @returns  {string}
   */
  formatLS(item, container) {
    const oSelf = this.self;

    if (item.loading == undefined && item.bSplit == undefined && item.element) {

      if (oSelf.view == 'datagridcol2' || (oSelf.view == 'datagridcol')) {
        if ($(item.element).parents('.xhdr').length > 0) {
          const aGridItem = item.text.split(',');
          item.text = (aGridItem[1]) ? aGridItem[1] : aGridItem[0];
          item.id = aGridItem[0];
        }
      }

      const aItem = item.text.split(' / ');
      const desc = (aItem[1]) ? aItem[1] : aItem[0];
      const shortDesc = aItem[0];
      this.setItem(item, item.id, desc, shortDesc, container);

      const templateF = Handlebars.compile(akioma.handlebarsTemplates['DynSelectGridTemplate'](item));
      const result = templateF(item);
      item.text = result;
      item.bSplit = true;
    } else
      $(container).attr('akId', `${this.name}-${item.id}`);
    return item.text;
  }

  /**
   * Controls what is displayed after a selection of an item. Used for businessEntity data.
   * @memberof Dynselect
   * @instance
   * @returns {string}
   */
  templateSelectionBE(data, container) {
    const oSelf = this.self;

    // Handling for values coming from chooseWindow
    if ($(data.element).data('bExtVal')) {
      oSelf.cLookupToDisplay = data.text;
      oSelf.bNewValue = false;
      return data.text;
    }

    // New values from tags
    if (oSelf.opt.tags && data._newTag)
      oSelf.bNewValue = true;
    else
      oSelf.bNewValue = false;

    if (oSelf.bSelected == false && !oSelf.bNewValue)
      oSelf.displayMultiFields(data);


    if (oSelf.bNewValue)
      oSelf.cLookupToDisplay = data.id;


    if (container == undefined && oSelf.bRemoveVal) { // bugfix for removing new value created (using tags)
      data.id = '';
      data.text = '';
      data.title = '';
      oSelf.cLookupToDisplay = '';
      oSelf.bRemoveVal = false;
      oSelf.bNewValue = false;
    }

    let cTitle = oSelf.cLookupToDisplay || data.text;
    if (oSelf.bNewValue)
      cTitle = data.id;
    data.title = cTitle;
    return cTitle;
  }

  /**
   * Controls what is displayed after a selection of an item. Used for listItemPairs data.
   * @memberof DynSelect
   * @instance
   * @returns {string}
   */
  templateSelectionLS(data) {
    const cToDisplay = $(data.element).data('custom');
    const oSelf = this.self;

    if (cToDisplay) {
      oSelf.bNewValue = false;
      oSelf.cLookupToDisplay = cToDisplay;
    } else {
      oSelf.bNewValue = true;
      oSelf.cLookupToDisplay = data.text;
    }

    data.title = oSelf.cLookupToDisplay;
    return oSelf.cLookupToDisplay;
  }

  /**
   * Controls new values (tags), removes duplicated values
   * @param   {object}  params
   * @memberOf DynSelect
   * @return  {object}         Object with id and text
   */
  tagCustom(params) {

    const oSelf = this.self;

    if (oSelf.businessEntity) {
      const aData = Object.values(oSelf.businessEntity.dhx.data.pull);
      const aFields = (oSelf.opt.dynSelect) ? oSelf.opt.dynSelect.templateOptions.split('|') : oSelf.opt.templateOptions.split('|');
      const cKey = aFields[1];
      const cDesc = aFields[2];

      for (let i = 0; i < aData.length; i++) {
        if (cKey && aData[i][cKey]) {
          if (aData[i] && aData[i][cKey] && String(aData[i][cKey]).toLowerCase() == params.term.toLowerCase())
            return null;
        }
        if (aData[i] && cDesc && aData[i][cDesc]) {
          if (aData[i] && aData[i][cDesc] && String(aData[i][cDesc]).toLowerCase() == params.term.toLowerCase())
            return null;
        }
      }
      return {
        id: params.term,
        text: params.term,
        _newTag: true
      };
    } else {
      let aData;
      if (oSelf.view == 'dynselect') {
        const oForm = akioma.getForm(oSelf);
        aData = oForm.dhx.comboValues[oSelf.opt.name];
      } else
        aData = oSelf.parent.dhx.comboValues[oSelf.opt.dataField];

      for (let i = 0; i < aData.length; i++) {
        const aSplit = aData[i].text.split(' / ');
        if (aSplit[1]) {
          if (aSplit[0].toLowerCase() == params.term.toLowerCase() || aSplit[1].toLowerCase() == params.term.toLowerCase())
            return null;
        } else
        if (aSplit[0].toLowerCase() == params.term.toLowerCase())
          return null;
      }
      return {
        id: params.term,
        text: params.term
      };
    }
  }

  /**
   * Controls the dropdown items (what items should be displayed or not)
   * @param   {object}  params
   * @param   {object}  data
   * @memberOf DynSelect
   * @returns  {object}
   */
  customMatcher(params, data) {
    if ($(data.element).data('listItemPairs'))
      return null;
    return data;
  }

  /**
   * Updates lookupKeyValueBinding field in Form/Grid dataSource. Used when making and removing a selection
   * @param  {Object} oSelf Object which has a dynSelect (e.g ak_dynSelect, ak_datagridcol2, ak_ribbon)
   * @param  {Object} oParent Parent object (e.g. Grid/Form)
   * @param  {Object} oNewItem New item with updated values
   * @instance
   * @private
   * @memberOf DynSelect
   * @returns {void}
   */
  _updateLookupKeyValueBinding(oParent, oNewItem) {
    const oSelf = this.self;

    const oAttributes = (this.self.view == 'dynSelect') ? oSelf.opt : $.extend (oSelf.opt, oSelf.opt.dynSelect);
    const cParent = (oParent.view == 'form') ? 'form' : 'grid';

    if (oAttributes.lookupKeyValueBinding == '_self') {
      if (oParent.dataSource) {
        oParent.dataSource.setFieldValue({
          name: oAttributes.name,
          value: oNewItem['selfhdl']
        });
      }
    } else if (oAttributes.lookupKeyValueBinding) {
      if (oParent.dataSource) {
        oParent.dataSource.setFieldValue({
          name: oAttributes.lookupKeyValueBinding,
          value: oNewItem[oAttributes.lookupKeyValueColumn]
        });
      }

      if (cParent == 'form') {
        const oDynSelectObj = oParent.dynObject.getObject(oAttributes.lookupKeyValueBinding);
        if (oDynSelectObj && oDynSelectObj.controller.view == 'dynselect')
          oDynSelectObj.controller.val.id = oNewItem[oAttributes.lookupKeyValueColumn];
      }

      if (isNull(oParent.dataSource)) {
        const oNewTemp = oParent.formStore.item(oParent.formStore.getCursor());
        oNewTemp[oAttributes.lookupKeyValueBinding] = oNewItem[oAttributes.lookupKeyValueColumn];
        oParent.formStore.update(oParent.formStore.getCursor(), oNewTemp);
      }
    }
  }

  /**
   * Updates lookupControls fields in Form/Grid dataSource. Used when making and removing a selection. For dynSelect in Form, calls setValue.
   * @param  {Object} oSelf    Object which has a dynSelect (e.g ak_dynSelect, ak_datagridcol2, ak_ribbon)
   * @param  {Object} oParent  Parent object (e.g. Grid/Form)
   * @param  {Object} oCurItem Current item in Form/Grid Datasource
   * @param  {Object} oNewItem New item with updated values
   * @param  {String} cMode    Defines the mode for update. If not defined, the default mode is 'normal' update.
   * @instance
   * @private
   * @memberOf DynSelect
   */
  _updateLookupControls(oParent, oCurItem, oNewItem, cMode) {
    try {
      const oSelf = this.self;
      const oAttributes = (oSelf.view == 'dynSelect') ? oSelf.opt : $.extend(oSelf.opt, oSelf.opt.dynSelect);
      const cParent = (oParent.view == 'form') ? 'form' : 'grid';

      // get LookupFields
      let lookupFields;
      if (oAttributes.LookupFields)
        lookupFields = oAttributes.LookupFields.split(',');

      // get lookupControls
      let lookupControls;
      if (oAttributes.LookupControls)
        lookupControls = oAttributes.LookupControls.split(',');

      // set LookupFields in LookupControls
      if (lookupFields && lookupControls) {
        for (let i = 0; i < lookupFields.length; i++) {
          let control;

          if (cParent == 'form')
            control = akioma.getDhxFormElement(akioma.getForm(oSelf), lookupControls[i]);

          if (cMode == 'remove' && !oSelf.opt.multiple)
            oNewItem[lookupFields[i]] = this._getEmptyValue(oCurItem[lookupControls[i]]);
          oCurItem[lookupControls[i]] = oNewItem[lookupFields[i]];

          if (oParent.dataSource) {
            const oItm = {};
            oItm[oAttributes.lookupKeyValueBinding] = oNewItem[oAttributes.lookupKeyValueColumn];
            for (const x in lookupControls)
              oItm[lookupControls[x]] = oNewItem[lookupFields[x]];


            if (cParent == 'form') {
              for (const x in lookupControls) {
                const cInputType = oParent.dhx.getItemType(lookupControls[x]);

                if (cInputType == null)
                  oParent.dataSource.dhx.data.pull[oParent.dataSource.dhx.getCursor()][lookupControls[x]] = oNewItem[lookupFields[x]];
                else if (cInputType.toLowerCase() == 'dynselect')
                  oParent.dhx.setItemValue(lookupControls[x], oItm);
                else
                  oParent.dhx.setItemValue(lookupControls[x], oNewItem[lookupFields[x]]);

              }
            } else
              oParent.dataSource.dhx.update(oParent.dataSource.dhx.getCursor(), oCurItem);

          } else
            oParent.formStore.update(oParent.formStore.getCursor(), oCurItem);

          if (control)
            $(control).parent().parent().addClass('active');
        }
      }

    } catch (e) {
      console.error(e);
    }

  }

  /**
   * Sets internally the multiple values for a multi-select dynSelect
   * @param  {Object} oItem Selected record data in Form/Grid Datasource
   * @instance
   * @memberOf DynSelect
   */
  _multipleSetValues(oItem) {
    const oSelf = this.self,
      oAttributes = (oSelf.view == 'dynSelect') ? oSelf.opt : $.extend(oSelf.opt, oSelf.opt.dynSelect),
      lookupFields = (oAttributes.LookupFields) ? oAttributes.LookupFields.split(',') : [],
      lookupControls = (oAttributes.LookupControls) ? oAttributes.LookupControls.split(',') : [];

    for (const index in lookupControls) {
      const field = lookupFields[index];
      oSelf.lookupFields_multiple[field] = (oItem && oItem[lookupControls[index]]) ? oItem[lookupControls[index]].split(oAttributes.multiDelimiter) : [];
    }
  }

  /**
   * Displays a set of multiple values in a multi-select dynSelect
   * @param {HTMLNode} oElement Html select element where the dynSelect is
   * @param {string}   key      List of keys (separator is specified by the multiDelimiter attribute)
   * @param {string}   [value]  List of descriptions (separator is specified by the multiDelimiter attribute)
   * @instance
   * @memberOf DynSelect
   */
  _multipleDisplayValues(oElement, key, value) {
    const oSelf = this.self,
      oAttributes = (oSelf.view == 'dynSelect') ? oSelf.opt : $.extend(oSelf.opt, oSelf.opt.dynSelect),
      keys = (key) ? key.split(oAttributes.multiDelimiter) : [],
      values = (value) ? value.split(oAttributes.multiDelimiter) : [];

    for (const index in keys) {
      const desc = values[index] || keys[index];
      const newOption = new Option(desc, keys[index], false, false);
      $(newOption).data('custom', desc);
      $(newOption).data('bExtVal', true);
      oElement.append(newOption);
    }

    oElement.val(keys);
    oElement.trigger('change');
  }

  /**
   * Updates internally the lookupKeyValueColumn values for a multi-select dynSelect
   * @param    {Object}  oItem   Selected record data in DynSelect Datasource
   * @param    {integer} [index] Used only when removing an existing value. Represents the position from where the value will be removed.
   * @instance
   * @memberOf DynSelect
   * @returns  {Object}  Returns the selected record data of the DynSelect (with all the multiple values)
   */
  _multipleUpdateLookupKeyValueColumn(oItem, index) {
    const oSelf = this.self,
      oAttributes = (oSelf.view == 'dynSelect') ? oSelf.opt : $.extend(oSelf.opt, oSelf.opt.dynSelect),
      data = oItem;

    if (oAttributes.MULTIPLE) {
      if (isNull(index)) {
        oSelf.lookupKeyValueColumn_multipleMap[data[oAttributes.lookupKeyValueColumn].toLowerCase()] = $.extend({}, data);
        oSelf.lookupKeyValueColumn_multiple.push(data[oAttributes.lookupKeyValueColumn]);
        data[oAttributes.lookupKeyValueColumn] = oSelf.lookupKeyValueColumn_multiple.join(oSelf.multiDelimiter);
      } else {
        const key = data[oAttributes.lookupKeyValueColumn] || data.id;
        delete oSelf.lookupKeyValueColumn_multipleMap[key.toLowerCase()];
        oSelf.lookupKeyValueColumn_multiple.splice(index, 1);
        data[oAttributes.lookupKeyValueColumn] = (index !== -1) ? oSelf.lookupKeyValueColumn_multiple.join(oSelf.multiDelimiter) : '';
      }
    }

    return data;

  }

  /**
   * Updates internally the lookupFields values for a multi-select dynSelect
   * @param    {Object}  oItem   Selected record data in DynSelect Datasource
   * @param    {integer} [index] Used only when removing an existing value. Represents the position from where the value will be removed.
   * @instance
   * @memberOf DynSelect
   * @returns  {Object}  Returns the selected record data of the DynSelect (with all the multiple values)
   */
  _multipleUpdateLookupFields(oItem, index) {
    const oSelf = this.self,
      oAttributes = (oSelf.view == 'dynSelect') ? oSelf.opt : $.extend(oSelf.opt, oSelf.opt.dynSelect),
      data = oItem;

    if (oAttributes.MULTIPLE && oSelf.lookupFields_multiple) {
      if (isNull(index)) {
        for (const field in oSelf.lookupFields_multiple) {
          const values = oSelf.lookupFields_multiple[field];
          values.push(data[field]);
          data[field] = values.join(oSelf.multiDelimiter);
        }
      } else {
        for (const field in oSelf.lookupFields_multiple) {
          oSelf.lookupFields_multiple[field].splice(index, 1);
          data[field] = (index !== -1) ? oSelf.lookupFields_multiple[field].join(oSelf.multiDelimiter) : '';
        }
      }
    }

    return data;

  }

  /**
   * Clears internally the lookupKeyValueColumn and lookupFields values for a multi-select dynSelect
   * @instance
   * @memberOf DynSelect
   */
  _multipleClear() {
    const oSelf = this.self,
      oAttributes = (oSelf.view == 'dynSelect') ? oSelf.opt : $.extend(oSelf.opt, oSelf.opt.dynSelect);

    if (oAttributes.MULTIPLE) {
      if (oSelf.lookupKeyValueColumn_multiple)
        oSelf.lookupKeyValueColumn_multiple = [];
      if (oSelf.lookupKeyValueColumn_multipleMap)
        oSelf.lookupKeyValueColumn_multiple = [];
      for (const field in oSelf.lookupFields_multiple)
        oSelf.lookupFields_multiple[field] = [];
    }
  }

  /**
   * Defines the empty value for a variable. Used on Form/Grid Datasource update, when one removes values from dynSelect
   * @param  {String|Number} value The variable for which we need its empty value
   * @return {String|Number|null}  The corresponding empty value
   * @instance
   * @private
   * @memberOf akioma
   */
  _getEmptyValue(value) {
    if (typeof (value) == 'number')
      return 0;
    if (typeof (value) == 'string')
      return '';
    return null;
  }

  /**
   * Appends option tag in select2 control
   * @param   {string}  val  The option value
   * @param {string} text Text for option
   * @memberof DynSelect
   * @instance
   * @returns  {void}
   */
  appendOption(val, text) {
    const oSelf = this.self;
    const oOption = oSelf.select2.find(`option[value="${val}"]`);
    if (oOption.length > 0)
      $(oOption).remove();

    const newOption = new Option(text, val);
    $(newOption).data('bExtVal', true);
    $(oSelf.select2).append(newOption);
    oSelf.bPosRecord = true;
  }

  /**
   * Removes option tag in select2 control
   * @param {string} val Value of option
   * @instance
   * @private
   * @memberof DynSelect
   */
  removeOption(val) {
    this.self.select2.find(`option[value="${val}"]`).remove();
  }

  /**
   * Sets an external value to a dynSelect
   * @param  {Object} oValue External value which will be set
   * @instance
   * @private
   * @memberOf DynSelect
   */
  setExtValue(oValue) {
    const oSelf = this.self;

    if (oValue) {
      oSelf.bPosRecord = false;
      // oSelf.bSelected  = true;
      oSelf.displayMultiFields(oValue);

      const isMultipleDynselect = oSelf.oAttributes && oSelf.oAttributes.dynSelect.multiple;
      const $select = $(oSelf.select2);

      if (isMultipleDynselect) {
        // Handling for multiple selection in chooseWindow
        const aItems = [];
        $select.find('option').each(function() {
          aItems.push($(this).val());
        });

        aItems.push(oValue[oSelf.oAttributes.dynSelect.lookupKeyValueColumn]);
        this.appendOption(oValue[oSelf.oAttributes.dynSelect.lookupKeyValueColumn], oSelf.cLookupToDisplay);
        $select.val(aItems).trigger('change');

      } else if (oSelf.opt.listItemPairs) {
        this.appendOption(oValue.listItemKey, oSelf.cLookupToDisplay);
        $select.val(oValue.listItemKey).trigger('change');
      } else {
        this.appendOption(oSelf.opt.name, oSelf.cLookupToDisplay);
        $select.val(oSelf.opt.name).trigger('change');
      }
    }
  }

  /**
   * Sets new ext value and changes selection in dynselect
   * @param   {object}  oData  record data
   * @instance
   * @memberof DynSelect
   * @returns  {void}
   */
  setNewVal(oData) {
    const oSelf = this.self;
    for (let i = 0; i < oData.length; i++) {
      const oElement = {
        'params': {
          'data': oData[i],
          'bExtVal': true
        }
      };

      // Flag for external record positioned in dynSelect
      oSelf.bPosRecord = true;
      this.setExtValue(oData[i]);
      oSelf.oPositionedElement = oData[i];
      oSelf.selectionChanged(oElement, oSelf.select2[0].parentElement);
      oSelf.bPosRecord = false;
    }
  }

  /**
   * This method is used for positioning a dynselect on an exact result (or multiple results). The outcome is the same as selecting a result in the dropdown list automatically.
   * @param  {String|Object[]} oValue If String, it represents the KeyValue value used for filtering using a FetchByUniqueKey NamedQuery. </br>
   * If Array of Objects, it represents the new data that will be set in dynSelect
   * @instance
   * @memberOf DynSelect
   * @returns {Promise}
   */
  positionToRecord(oValue) {
    const oSelf = this.self;

    const queryPromise = new Promise(resolve => {
      if (typeof oValue == 'object') {
        this.setNewVal(oValue);
        resolve(oValue);
      } else {
        oSelf.businessEntity.addAfterCatalogAdd(() => {

          oSelf.businessEntity.setNamedQueryParam('FetchByUniqueKey', 'KeyValue', oValue, 'character');

          oSelf.businessEntity.addAfterFillOnceCallback(rows => {
            this.setNewVal(rows);
            oSelf.businessEntity.removeNamedQueryParam('FetchByUniqueKey');
            resolve(rows);
          });

          const oQuery = oSelf.businessEntity.getQuery();

          if (oSelf.opt.defaultSort !== '')
            oQuery.setStringSorting(oSelf.opt.defaultSort);

          oSelf.businessEntity.openQuery({});

        });
      }
    });

    return queryPromise;

  }

  /**
   * Displays multiple fields in dynselect after a record selection.
   * @param  {Object} oValue The object you have selected
   * @private
   * @instance
   * @memberOf DynSelect
   * @returns {String}        The displayed result
   */
  displayMultiFields(oValue) {
    const oSelf = this.self;
    let cRes = '';
    let bField = false;

    if (oSelf.opt.lookupKeyField) {
      const aFields = oSelf.opt.lookupKeyField.split(',');
      for (let i = 0; i < aFields.length; i++) {
        const cField = aFields[i];
        if (cField.indexOf('\'') == -1) {
          if (oValue[cField]) {
            cRes += oValue[cField];
            bField = true;
          }
          if (oSelf.bPosRecord) {
            cRes = oValue.text;
            bField = true;
          }
        } else
          cRes += cField.replace(/["']/g, '');

      }
    }

    if (bField == false)
      cRes = '';
    oSelf.cLookupToDisplay = cRes;
    return cRes;
  }

  /**
   * Opens a new popup dialog (choosewindow) for selecting one (or more) records in dynselect.
   * @instance
   * @memberOf DynSelect
   * @returns {void}
   */
  launchLookupDialog() {
    const oSelf = this.self;
    if (oSelf.opt.lookupDialog) {
      // call dialog
      app.controller.launchContainer({
        proc: 'popup.r',
        para: `RunFile=${oSelf.opt.lookupDialog}&FieldName=${oSelf.opt.name}&TargetId=${oSelf.opt.id}`,
        caller: oSelf,
        allowMultipleInstances: true,
        self: oSelf,
        data: true
      });
    }
  }

  /**
   * When dynSelect has focus, typing automatically starts the search
   * @instance
   * @private
   * @memberOf DynSelect
   */
  _allowSearchOnFocus() {
    const oSelf = this.self;

    $(oSelf.select2).parent().find('.select2-selection').keydown(ev => {
      if (oSelf.readonly)
        return;

      if (ev.which < 32)
        return;

      if (ev.altKey || ev.ctrlKey || ev.shiftKey)
        return;

      let target = jQuery(ev.target).closest('.select2-container');
      if (!target.length)
        return;

      target = target.prev();
      const search = target.data('select2').dropdown.$search ||
                     target.data('select2').selection.$search;

      /* Safari does not have key */
      const key = ev.key ? ev.key : String.fromCharCode(ev.which);


      $(oSelf.select2).select2('open');

      /* filter function keys such as "Meta" */
      if (key.length === 1)
        search.val(search.val() + key);

      $(oSelf.select2).data('select2').trigger('query', { term: search.val() });

      ev.stopImmediatePropagation();
      ev.preventDefault();

    });
  }

};

