akioma.getToolbar = function(oSelf) {
  const getTool = self => {
    if (self.view == 'toolbar')
      return self;
    else
      return getTool(self.parent);
  };
  return getTool(oSelf);
};

// get array of type list from server and mix it with local nodelist for images
// global context function
// eslint-disable-next-line no-unused-vars
akioma.toolbar.reqTypeList = function(self, cTypeRange) {
  let i;

  // get target
  const oTarget	= self.controller;
  const callback = function(oOptions) {
    for (i in oOptions.comboOptions)
      oOptions.comboOptions[i].image	= akioma.icons.forceIconStyle(app.nodeTypes[oOptions.comboOptions[i].hdl] ? app.nodeTypes[oOptions.comboOptions[i].hdl].image : null);


    // add options to combo
    if (oOptions.comboOptions)
      oTarget.setComboOptions(oOptions.comboOptions);

    if (oOptions.comboOptions.length > 0)
      oTarget.optionSelected(oOptions.comboOptions[0].hdl);
  };
  // get options
  app.controller.callServerMethod(
    'akioma/getComboToolbar.p',
    [{ type: 'iCHAR', value: cTypeRange }], true, callback);
};

($ => {

  // ****************** toolbar ***********************
  $.extend({
    /**
     * SwatToolbar Control
     * @class ak_toolbar
     * @param {Object} options Repository attributes for SwatToolbar.
     * @param {boolean} options.Toolbar TRUE if a Toolbar is to be generated.
     * @param {string} options.PanelType The type of Panel: Navigation, Save, Update
     * @param {string} options.ActionGroups Comma separated list of actionGroups.&#10;Repository toolbar uses categories while non-repository objects&#10;uses parent actions
     * @param {boolean} options.Menu TRUE if a menu is to be generated.
     * @param {string} options.ToolBarKey Key of the used toolbar
     * @param {string} options.EventToolbarCancel Code to be executed when Cancel Button in Toolbar is pressed
     * @param {string} options.EventToolbarGo Code to be executed when a GoButton in the Toolbar is pressed </br>
     * <a href="/sports-webui/docs/Order_getCustomData.js.html" target="_blank">Save Record Example</a>
     * @param {string} options.ContainerObject For a Toolbar specifies where the toolbar should be rendered. if empty, then in the panel it resides, if WIN then in the window&#10;
     * @param {string} options.IconPath Defines the handling of icons in the Toolbar. If you want to use font-icons set this to "font:{name}" where {name} is the name of your font, e.g. "fa" for font-awesome. If you want to use image files, leave this empty or specify a server-side path where your images are located
     * @param {string} options.labelDisplay Specifies how all the labels will be handled. Possible values are 'standard', 'show', 'hide'. Default value is 'standard'. </br>
     * standard - labels will be displayed according to the menuFunction settings </br>
     * show - all the labels will be displayed. This setting will overwrite the menuFunction settings </br>
     * hide - all the labels will not be displayed. This setting will overwrite the menuFunction settings
     */
    ak_toolbar: function(options) {
      const oSelf = this,
        defaults = { pos: -1 };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;
      this.aToolbarItems = [];
      this.oActiveItem = { id: null, index: -1 };
      this.toolbarClickDelay = 350;
      this.registerDynObject = true;


      // get panelset
      let oParent;
      if (this.opt.containerObject && this.opt.containerObject == 'win')
        oParent = this.parent.parent.dhx;
      else
        oParent = this.parent.dhx;

      if (oParent) {
        let oToolbar;

        if (!this.opt.customStyle)
          this.opt.customStyle = this.view;

        if (this.opt.containerObject === 'header')
          oToolbar = oParent.attachHeaderToolbar();
        else
          oToolbar = oParent.attachToolbar();

        if (oToolbar) {
          // initialize toolbar
          if (!this.opt.iconPath)
            this.opt.iconPath = oDhx.imagePath;
          if (this.opt.iconPath.startsWith('font'))
            oToolbar.setIconset('awesome');
          else
            oToolbar.setIconsPath(this.opt.iconPath);

          oToolbar.setSkin('material');

          oToolbar.attachEvent('onClick', cId => oSelf.click(cId));
          oToolbar.attachEvent('onStateChange', (cId, lState) => oSelf.stateChange(cId, lState));
          oToolbar.attachEvent('onEnter', (cId, cValue) => oSelf.enterText(cId, cValue));

          oToolbar.attachEvent('onSegmentedChange', (cGroup, cId, lState) => {
            if (lState == true)
              return oSelf.segmentedChange(cGroup, cId);
          });


          if (this.opt.toolbarkey) {
            this.toolbarMenuID = this.opt.toolbarkey[0] == '#' ? this.opt.toolbarkey.substring(1) : this.opt.toolbarkey;
            this.createMenuStructure(this.toolbarMenuID);
          }

          const oMouseTrap = Mousetrap(oToolbar.base);
          oMouseTrap.bind('right', () => {
            oSelf._focusItem(oSelf.getNextItem(oSelf.oActiveItem.index));
          });

          oMouseTrap.bind('left', () => {
            oSelf._focusItem(oSelf.getPrevItem(oSelf.oActiveItem.index));
          });

          oMouseTrap.bind('enter', () => {

            if (oSelf.oActiveItem.id !== null) {
              const item = oSelf.getItemById(oSelf.oActiveItem.id);
              oSelf.click(item.opt.name);
            }

          });

          oMouseTrap.bind(akioma.shortcutManager.get('FocusRibbonToolbar'), e => {
            if (e.preventDefault)
              e.preventDefault();

            const oControl = akioma.getRibbonToolbar(oSelf, oSelf);
            akioma.focusRibbonToolbar(oControl);

            if (e.stopPropagation)
              e.stopPropagation();
          });


          $.extend(this, { dhx: oToolbar });
        } else
          akioma.log.error([ 'error attaching toolbar to Parent:', oParent ]);

      } else
        !_isIE && console.error(`No valid parent for toolbar ${this.opt.name}`);

    }
  });

  // methods for toolbar *****************************
  $.ak_toolbar.prototype = {

    finishConstruct: function() {

      this.applyResponsiveBehaviour();

      // set akstyle in toolbar
      $(this.dhx.cont.parentElement).attr('akstyle', this.opt.customStyle);

      if (akioma.getAkIdMode() == 'extended')
        $(this.dhx.cont.parentElement).attr('akId', this.toolbarMenuID);
    },

    endConstruct: function() {
      const oWin = this.dynObject.container.controller;
      const oPromiseLoaded = oWin._waitForAllNestedLoaded();
      const oSelf = this;
      oPromiseLoaded.then(() => {
        const iParentWinDataToLoad = oSelf.iDataSourceToLoad;

        //  parentwin dataload flag is setup, if gt 0 means it's loading BEs
        if (iParentWinDataToLoad > 0) {
          akioma.eventEmitter.once(`${oWin.opt.id}:dataLoaded`, () => {
            VisibilityRules.checkToolbarVisibilityRules(oSelf);
          });
        } else if (oSelf.toolbarMenuStructPromise.state() == 'resolved')
          VisibilityRules.checkToolbarVisibilityRules(oSelf);
        else {
          oSelf.toolbarMenuStructPromise.then(() => {
            VisibilityRules.checkToolbarVisibilityRules(oSelf);
          });
        }

      });
    },

    /**
 * Method for applying reponsive behaviour/ CSS class
 * @memberOf ak_toolbar
 * @instance
 * @return {void}
 */
    applyResponsiveBehaviour: function() {
      if (this.opt.responsiveBehaviour)
        $(this.dhx.cont.parentElement).addClass(this.opt.responsiveBehaviour);

    },

    /**
      * Enables an item in Toolbar
      * @param  {string}  cName Name or menuCode (when using menuStructure) of the item
      * @instance
      * @example
      * // get the Toolbar object of the current container and enable a toolbarButton with name='toolItemTest'
      * // this could e.g. be placed inside an AfterDisplay Event of a Form
      * if (someField == "somevalue")
      *   self.container.getObject("SimpleSwatToolbar").controller.enableItem('toolItemTest');
      * @memberOf ak_toolbar
      */
    enableItem: function(cName) {
      const oSelf = this;
      oSelf.dhx.enableItem(cName);
    },

    /**
      * Disables an item or a block of items in Toolbar
      * @param  {string}  cName Name or menuCode (when using menuStructure) of the item
      * @instance
      * @example
      * // get the Toolbar object of the current container and disable a toolbarButton with name='toolItemTest'
      * // this could e.g. be placed inside an AfterDisplay Event of a Form
      * if (someField == "somevalue")
      *   self.container.getObject("SimpleSwatToolbar").controller.disableItem('toolItemTest');
      * @memberOf ak_toolbar
      */
    disableItem: function(cName) {
      const oSelf = this;
      oSelf.dhx.disableItem(cName);
    },

    /**
      * Hides an item in Toolbar
      * @param  {string}  cName Name or menuCode (when using menuStructure) of the item
      * @instance
      * @example
      * // get the Toolbar object of the current container and disable a toolbarButton with name='toolItemTest'
      * // this could e.g. be placed inside an AfterDisplay Event of a Form
      * if (someField == "somevalue")
      *   self.container.getObject("SimpleSwatToolbar").controller.hideItem('toolItemTest');
      * @memberOf ak_toolbar
      */
    hideItem: function(cName) {
      this.dhx.hideItem(cName);
    },

    /**
      * Shows an item in Toolbar
      * @param  {string}  cName Name or menuCode (when using menuStructure) of the item
      * @instance
      * @example
      * // get the Toolbar object of the current container and disable a toolbarButton with name='toolItemTest'
      * // this could e.g. be placed inside an AfterDisplay Event of a Form
      * if (someField == "somevalue")
      *   self.container.getObject("SimpleSwatToolbar").controller.showItem('toolItemTest');
      * @memberOf ak_toolbar
      */
    showItem: function(cName) {
      this.dhx.showItem(cName);
    },

    createMenuStructure: function(menuStructure) {
      const oSelf = this;

      const menu = app.controller.parseProc({
        view: 'menustructure',
        att: {
          id: menuStructure,
          depth: 0
        },
        sub: []
      }, oSelf);


      oSelf.toolbarMenuStructure = menu;

      oSelf.toolbarMenuStructPromise = menu.loadMenuElements();

      oSelf.toolbarMenuStructPromise.done(() => {
        menu.scanMenuItemsObj(item => {
          if (!item.parentid)
            oSelf.createToolbarItem(item);
        });

        if (oSelf.opt.EventAfterInitialize) {
          try {
            app.controller.callAkiomaCode(oSelf, oSelf.opt.EventAfterInitialize);
          } catch (e) {
            console.error(e);
          }
        }
      });
    },

    createToolbarItem: function(item) {
      const oParent = this;

      let logger;
      try {
        // create logger on module Toolbar
        logger = akioma.log.getLogger('Menu.FunctionCode');

        // display FunctionCode not defined in toolbar menustructure
        if (item.code && item.code.split('-').length == 5 && item.code.length) {
          logger.log.info('=================================');
          logger.log.info('Found FunctionCode with no code:');
          logger.log.info(`FunctionCode:${item.code}`);
          logger.log.info(`Label(MenuName):${item.label}`);
          logger.log.info(`MenuStructureCode :${item.menuCode}`);
          logger.log.info('Toolbar Item:', item);
          logger.log.info('=================================');
        }
      } catch (e) {
        logger.log.warn(e);
      }

      const options = {
        view: item.type,
        att: {
          id: item.menuCode || item.code || item.id,
          title: item.label,
          tooltip: item.tooltip,
          name: item.menuCode || item.code || item.id,
          image: item.icon,
          image2: item.icon,
          imageOnly: item.imageOnly || false,
          hidden: item.hidden || false,
          disabled: item.disabled || false,
          defaultGoBtn: item.isDefaultAction || false,
          VisibilityRules: item.VisibilityRules,
          children: item.children,
          sequence: item.sequence
        },
        parent: oParent
      };
      const oMenuFunc = this.toolbarMenuStructure.aMenuActions[item.id];
      switch (options.view) {
        case 'item':
          options.view = 'toolbarbutton';
          if (oMenuFunc && oMenuFunc.visualisationType)
            options.att.type = oMenuFunc.visualisationType;

          break;
        case 'combo':
          options.view = 'toolbarcombo';
          if (item.selectEvent)
            options.att.selectEvent = item.selectEvent;
          break;
        case 'input':
          options.view = 'toolbarfillin';
          break;
        case 'select':
          options.view = 'toolbarselect';
          options.children = options.att.children && options.att.children.map(item =>
            ({
              att: {
                name: item.MenuStructureCode || item.FunctionCode || '',
                text: item.MenuName || item.MenuStructureCode || item.FunctionCode || '',
                image: item.MenuSmallImage
              }
            })
          );
          delete options.att.children;
          break;
        case 'break':
          options.view = 'toolbarseparator';
          break;
      }

      if (item.createEvent)
        options.att.createEvent = item.createEvent;
      const oFunc = this.toolbarMenuStructure.aMenuActions[item.id];

      if (oFunc) {
        switch (oFunc.actionLoadMenu) {
          case 'GoAction':
            oFunc.Action = oParent.opt.EventToolbarGo;
            break;
          case 'CancelAction':
            oFunc.Action = oParent.opt.EventToolbarCancel;
            break;
        }
      }

      app.controller.parseProc({
        view: options.view,
        att: options.att,
        sub: options.view == 'toolbarselect' ? options.children : []
      }, oParent);
    },


    /**
      * Returns the controller of an item from the toolbar
      * @param   {string}  id Name or menuCode (when using menuStructure) of the item
      * @returns {Object} Controller of the item from the toolbar
      * @instance
      * @memberOf ak_toolbar
      */
    getItemById: function(id) {
      return this.dynObject.getObject(id).controller;
    },

    /**
      * Returns the html of an item from the toolbar
      * @param   {string}  id Name or menuCode (when using menuStructure) of the item
      * @returns {Object} Html of the item from the toolbar
      * @instance
      * @memberOf ak_toolbar
      */
    getItemHtml: function(id) {
      const idPrefix = this.dhx.idPrefix;
      return this.dhx.objPull[idPrefix + id].obj;
    },

    /**
      * Focuses the item from the toolbar
      * @param   {Object}  item Object containing the id and the index of the item
      * @instance
      * @private
      * @memberOf ak_toolbar
      */
    _focusItem: function(item) {

      const oItemHtml = this.getItemHtml(item.id);
      $(oItemHtml).attr('tabindex', '0');
      $(oItemHtml).focus();
      this.oActiveItem = item;

    },

    /**
      * Returns the next active and visible item from the toolbar
      * @param   {Integer}  iIndex Index of the current item in the toolbar
      * @returns {Object}   Object containing the id and the index of the next item
      * @instance
      * @private
      * @memberOf ak_toolbar
      */
    getNextItem: function(iIndex) {

      const oNextItem = {};
      if (!iIndex && iIndex != 0)
        iIndex = -1;

      do {
        if (iIndex < this.aToolbarItems.length - 1)
          iIndex++;
        else
          iIndex = 0;
      }
      while (!this.isVisible(this.aToolbarItems[iIndex]) || !this.isEnabled(this.aToolbarItems[iIndex]));
      oNextItem.id = this.aToolbarItems[iIndex];
      oNextItem.index = iIndex;
      return oNextItem;

    },

    /**
      * Returns the previous active and visible item from the toolbar
      * @param   {Integer}  iIndex Index of the current item in the toolbar
      * @returns {Object}   Object containing the id and the index of the previous item
      * @instance
      * @private
      * @memberOf ak_toolbar
      */
    getPrevItem: function(iIndex) {

      const oPrevItem = {};
      if (!iIndex && iIndex != 0)
        iIndex = this.allElements.length;

      do {
        if (iIndex > 0)
          iIndex--;
        else
          iIndex = this.aToolbarItems.length - 1;
      }
      while (!this.isVisible(this.aToolbarItems[iIndex]) || !this.isEnabled(this.aToolbarItems[iIndex]));
      oPrevItem.id = this.aToolbarItems[iIndex];
      oPrevItem.index = iIndex;
      return oPrevItem;

    },

    /**
      * Checks if the item from the toolbar is visible
      * @param   {Integer}  id Name or menuCode (when using menuStructure) of the item
      * @returns {boolean}  True for visible False otherwise
      * @instance
      * @private
      * @memberOf ak_toolbar
      */
    isVisible: function(id) {
      const oItem = this.getItemById(id);
      if (!this.dhx.isVisible(id))
        return false;
      else {
        const oParent = oItem.parent.controller;
        if (oParent) {
          if (!this.dhx.isVisible(oParent.opt.id))
            return false;
        }
      }
      return true;
    },

    /**
    * Checks if the item from the toolbar is enabled
    * @param   {Integer}  id Name or menuCode (when using menuStructure) of the item
    * @returns {boolean}  True for enabled False otherwise
    * @instance
    * @private
    * @memberOf ak_toolbar
    */
    isEnabled: function(id) {
      const oItem = this.getItemById(id);
      if (!this.dhx.isEnabled(id))
        return false;
      else {
        const oParent = oItem.parent.controller;
        if (oParent) {
          if (!this.dhx.isEnabled(oParent.opt.id))
            return false;
        }
      }
      return true;
    },


    // click
    click: function(cId) {
      let oElm;


      // if timeout clear timeout
      if (this.dATimeout)
        clearTimeout(this.dATimeout);

      // set timeout and execute click when ended
      this.dATimeout = setTimeout(() => {

        clearTimeout(this.dATimeout);
        // look for element and execute click
        for (const i in this.childs) {
          switch (this.childs[i].view) {
            case 'toolbarcombo':
            case 'toolbarselect':
              if (this.dhx.getAllListOptions(this.childs[i].opt.name).indexOf(cId) > -1) {
                oElm = this.childs[i];
                return oElm.click(cId);
              }
              break;

            default:
              if (this.childs[i].cName == cId) {
                oElm = this.childs[i];
                return oElm.click(cId);
              }
              break;
          }
        }

      }, this.toolbarClickDelay);


      return false;
    },

    // segementedChange change
    segmentedChange: function(cId, cOption) {

      // look for element and execute click
      for (const i in this.childs) {
        if (this.childs[i].opt.name == cId)
          return this.childs[i].segmentedChange(cOption);
      }
      return false;
    },

    // state change
    stateChange: function(cId, lState) {

      // look for element and execute click
      for (const i in this.childs) {
        if (this.childs[i].opt.name == cId)
          return this.childs[i].stateChange(cId, lState);
      }
      return false;
    },

    // enter text
    enterText: function(cId, cValue) {

      // look for element and execute function
      for (const i in this.childs) {
        if (this.childs[i].opt.name == cId)
          return this.childs[i].enterText(cValue);

      }
      return false;
    },

    // set value
    setFieldValue: function(cName, cValue) {

      // look for element and set value inside
      for (const i in this.childs) {
        if (this.childs[i].opt.name == cName)
          return this.childs[i].setFieldValue(cValue);

      }
    },

    // query position ******************
    queryPosition: function(oElm) {
      const cState = oElm.state;

      let oNav = null;
      switch (cState) {
        case 'FirstRecord':
          oNav = { navFirst: false, navPrev: false, navNext: true, navLast: true };
          break;
        case 'LastRecord':
          oNav = { navFirst: true, navPrev: true, navNext: false, navLast: false };
          break;
        case 'NotFirstOrLast':
          oNav = { navFirst: true, navPrev: true, navNext: true, navLast: true };
          break;
        default:
          oNav = { navFirst: false, navPrev: false, navNext: false, navLast: false };
          break;
      }

      const oToolbar = this.dhx;
      for (const i in this.childs) {
        const cName = this.childs[i].opt.name;
        if (oNav[cName] != undefined) {
          if (oNav[cName])
            oToolbar.enableItem(this.childs[i].opt.id);
          else
            oToolbar.disableItem(this.childs[i].opt.id);
        }
      }
    },

    destroy: function() {
      // check if dhtmlx element exists -> destroy it
      if (this.dhx) {
        try {
          // remove all items from toolbar
          this.dhx.clearAll();
        } catch (e) {
          !_isIE && console.error([ 'Error destroying', this.view, this.opt.name, e.message ]);
        }
      }
    },

    /**
 * Returns the text of an item in the Toolbar
 * @param  {string}  cName Name or menuCode (when using menuStructure) of the item
 * @returns {string}  Text of the item from the toolbar
 * @instance
 * @memberOf ak_toolbar
 */
    getItemText: function(cName) {
      const oSelf = this;
      const cText = oSelf.dhx.getItemText(cName);
      return cText;
    },

    /**
 * Sets the text of an item in the Toolbar
 * @param  {string}  cName Name or menuCode (when using menuStructure) of the item
 * @param  {string}  cText The value to set
 * @instance
 * @memberOf ak_toolbar
 */
    setItemText: function(cName, cText) {
      const oSelf = this;
      oSelf.dhx.setItemText(cName, cText);
    }
  };

})(jQuery, jQuery);
