/**
 * TemplateOptions utils class for handling templateOptions attribute
 * for Dynselect, Dataview, HtmlContainer templateOptions support
 */
akioma.TemplateOptions = class {
  constructor(templateOptions) {
    this._templateOptions = templateOptions;
    this._settingsDelimiter = '#';
    this._entryDelimiter = '|';
    this._templateParts = templateOptions.split(this._settingsDelimiter);
  }

  /**
   * Method for getting fields from templateOptions
   * @returns {Array<string>}
   */
  getFields() {
    const templateOptionsKeys = this._templateParts[0].split(this._entryDelimiter);
    templateOptionsKeys.map(templateOption => templateOption.toLowerCase());
    return templateOptionsKeys;
  }

  /**
   * Method for getting the template options settings
   * @returns {Array<string>}
   */
  getSettings() {
    return (this._templateParts[1] || '').split(this._entryDelimiter);
  }
};
