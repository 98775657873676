/**
 * Class BaseFormContainer - form, block, fieldset controls
 * @class BaseFormContainer
 */
akioma.BaseFormFieldContainer = function() {
};

Object.assign(akioma.BaseFormFieldContainer.prototype, akioma.BaseFormDataField.prototype, {

  /**
     * Method for applying grid column template class for Form Field container
     * @memberof BaseFormContainer
     * @instance
     * @returns {void}
     */
  applyGridColumnTemplate: function() {
    if (!this.opt.COLUMNS) return;

    this.$baseDomElement
      .addClass(`f-${this.opt.COLUMNS}-col`);
  },
  /**
     * Method for setting all the CSS grid reponsive sizes, including grid column template
     * @instance
     * @memberof BaseFormContainer
     * @returns {void}
     */
  setResponsiveSizes: function() {
    akioma.BaseFormDataField.prototype.setResponsiveSizes.call(this);
    this.applyGridColumnTemplate();
  },

  /**
   * Repositions form fields when hiding element from grid
   *
   * @param   {string}  fieldName  Name of the form field
   * @instance
   * @memberof BaseFormContainer
   * @return  {void}
   */
  repositionFieldByVisibility: function(fieldName, visibility) {

    const formFieldDynObject = this.dynObject.getObject(fieldName);

    if (!formFieldDynObject)
      return;

    const activeFormControl = formFieldDynObject.controller ? formFieldDynObject.controller : formFieldDynObject;
    const parentControl = activeFormControl.parent;
    const index = parentControl.childs.indexOf(activeFormControl);
    if (!activeFormControl.$domElement) {
      activeFormControl.setFieldVisibilityAfterLoad(fieldName, visibility);
      return;
    }
    const isFieldHidden = activeFormControl.$domElement.is(':hidden');

    // stop if already visible or hidden
    if (visibility === !isFieldHidden)
      return;


    akioma.log.info('Adjust col template for ', fieldName, visibility);

    try {
      // if found field not last, go over each from last to index and move them to fill it's space
      for (let x = parentControl.childs.length - 1; x >= index; x--) {

        const formControl = parentControl.childs[x];
        akioma.log.info('Adjust for field ', formControl.opt.name, visibility);

        akioma.log.info(`org ROW:${formControl.opt.ROW}`, `org COLUMN:${formControl.opt.COLUMN}`);

        // skip when child is a menustructue (ex. fab menu)
        if (formControl.view == 'menustructure') continue;
        if (formControl._ROW !== activeFormControl._ROW) continue;
        // update form control column
        if (visibility) {
          formControl.opt.COLUMN = formControl._COLUMN;
          if (!formControl.opt.VISIBLE && formControl.parent.opt.COLUMNS === formControl.opt.COLUMN)
            this._repositionHiddenFormField(formControl);

        } else if (formControl._COLUMN > 1 && formControl.opt.name !== activeFormControl.opt.name && !formControl.$domElement.is(':hidden')) {
          this.adjustColumnsTemplate(formControl.opt.name, visibility);
          formControl.opt.COLUMN = formControl._COLUMN - +activeFormControl.opt.Width;
        }

        akioma.log.info(`ROW:${formControl.opt.ROW}`, `COLUMN:${formControl.opt.COLUMN}`);
        akioma.log.info(`_ROW:${formControl._ROW}`, `_COLUMN:${formControl._COLUMN}`);
        akioma.log.info('=======================');

        // remove existing col{no} classes for recalculation
        formControl.$domElement.removeClass ((index, css) => (css.match (/(^|\s)col\S+/g) || []).join(' '));

        formControl.setResponsiveSizes();

      }

      akioma.log.info('===========================================================');


      // check if column needs to be removed after hiding formField
      this.adjustColumnsTemplate(fieldName, visibility);


    } catch (e) {
      console.error(e);
      akioma.notification({
        type: 'error',
        text: 'Error calculating new Grid position on visibility form field update.'
      });
    }

  },
  /**
   * Method for repositioning hidden form field
   * @param {BaseFormDataField} formControl
   * @memberof ak_form
   * @instance
   * @returns {void}
   */
  _repositionHiddenFormField(formControl) {
    const fieldColumn = formControl.opt.COLUMN;
    const fieldRow = formControl.opt.ROW;
    let fieldsMatching = formControl.parent.childs.filter(field => field.opt.ROW === fieldRow && field.opt.COLUMN === fieldColumn);
    let firstField = fieldsMatching.reduce((field, currentField) => {
      if (formControl.parent.childs.indexOf(field) > formControl.parent.childs.indexOf(currentField))
        return field;
      else
        return currentField;
    });

    if (firstField)
      firstField.opt.ROW = firstField._ROW + +firstField.opt.Height;

    firstField.setResponsiveSizes();

    fieldsMatching = formControl.parent.childs.filter(field => field.opt.ROW === firstField.opt.ROW && field.opt.COLUMN === firstField.opt.COLUMN);

    if (fieldsMatching.length > 1) {
      firstField = fieldsMatching.reduce((field, currentField) => {
        if (formControl.parent.childs.indexOf(field) > formControl.parent.childs.indexOf(currentField))
          return field;
        else
          return currentField;
      });
      if (firstField)
        this._repositionHiddenFormField(firstField);
    }

  },
  /**
     * Method for adjusting the Columns template of the parent form control, when showing/hiding form fields
     * @memberof BaseFormContainer
     * @instance
     * @returns {void}
     */
  adjustColumnsTemplate: function(fieldName, visibility) {

    const formFieldDynObject = this.dynObject.getObject(fieldName);
    const activeFormControl = formFieldDynObject.controller ? formFieldDynObject.controller : formFieldDynObject;
    const currCol = activeFormControl.opt.COLUMN;

    if (!activeFormControl.parent.$baseDomElement && currCol <= 1) return;

    const currColSelector = `> .col${currCol}`;
    const visibleActiveFormColumns = activeFormControl.parent.$baseDomElement.find(`${currColSelector}:visible`);

    akioma.log.info('adjust columns template for ', fieldName, visibility);

    akioma.log.info(`ROW:${activeFormControl.opt.ROW}`, `COLUMN:${activeFormControl.opt.COLUMN}`);

    if (visibility) {
      // show back form control and reset correct columns template for parent of form control
      if (visibleActiveFormColumns.length === 0) {

        if (activeFormControl.opt.COLUMN + +activeFormControl.opt.Width > activeFormControl.parent._COLUMNS) {
          activeFormControl.parent.opt.COLUMNS = activeFormControl.parent._COLUMNS;

          activeFormControl.parent.$baseDomElement
            .removeClass(`f-${activeFormControl.parent.opt.COLUMNS}-col`);
          activeFormControl.parent.setResponsiveSizes();
        }
      }
    } else if (visibleActiveFormColumns.length === 1) {
      // if there is only one last form field visible inside this container

      // remove column, reposition all children
      activeFormControl.parent.$baseDomElement
        .removeClass(`f-${activeFormControl.parent.opt.COLUMNS}-col`);

      activeFormControl.parent.opt.COLUMNS = activeFormControl.parent.opt.COLUMNS - (activeFormControl.gridRowEnd() - activeFormControl.gridRowStart);

      activeFormControl.parent.setResponsiveSizes();

      for (let e = activeFormControl.parent.childs.length - 1; e >= 0; e--) {
        const formField = activeFormControl.parent.childs[e];
        const isSame = formField.opt.id === activeFormControl.opt.id;
        const isHidden = formField.$domElement.is(':hidden');
        if (formField.opt.COLUMN < currCol || isHidden || isSame)
          continue;

        formField.opt.COLUMN = formField._COLUMN - 1;
        formField.setResponsiveSizes();
      }

    }

    akioma.log.info(`ROW:${activeFormControl.opt.ROW}`, `COLUMN:${activeFormControl.opt.COLUMN}`);
    akioma.log.info(`_ROW:${activeFormControl._ROW}`, `_COLUMN:${activeFormControl._COLUMN}`);

  }
});
