//
// docviewer html5

(function($) {
  $.extend({
    /**
     * The Akioma DocViewer Object
     * @param  {Object} options Repository Object Attributes
     * @tutorial docviewer-desc
     * @class ak_docviewer
     * @param {Object} options Repository attributes for SwatDocViewer.
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     * @param {string} options.contextMenu the id of a menuStructure which will be used as a context-menu
     * @param {string} options.floatingActionButton the id of a menustructure, which will be rendered as a FAB
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {string} options.panelMenu comma separated list of menu-structures which will be shown as panelHeader-Buttons </br>
     * Can also contain the flag #NoDropDown that specifies that the menu should not be loaded as a dropdown but each menu item should be added in the panel-Header. </br>
     * For example: </br>
     * <code>menuStructSave,menuSettings#NoDropDown,menuLookup</code> </br>
     * The buttons support font icons with the following attributes: </br>
     * 1. Css attributes, defined like this: fa fa-user#color:red </br>
     * 2. Css classes, defined like this: fa fa-user#_style:module_prod
     * 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod </br>
     * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
     * @param {string} options.titleHeader specifies which panelHeader to use. when empty, then uses the header of its own panel. if "none" then no header at all. if "parent" it uses the header of the parent panel
     * @param {string} options.serviceURI The URL to be used for loading the PDFs in the iframe DocViewer
     * @param {string} options.DisplayedFields The name of the business entity fieldname used for getting the docviewer file path.
     * @fires ak_docviewer#eventDocCallback
     */
    ak_docviewer: function(options) {
      const defaults = {
        height: 600,
        width: '100%',
        documenturl: ''
      };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.registerVuexModule = true;
      this.registerDynObject = true;
      this.hasChangesControl = true;
      this.opt.title = akioma.tran(`${this.opt.name}._title`, { defaultValue: this.opt.title });
      this._onPostMessageListener = null;
      this._iframeLoadListener = null;


      // use pasoe setting if document url is empty
      if (this.opt.documenturl === '')
        this.documenturl = (app.sessionData.documentViewerURI || this.opt.documenturl);
      else
        this.documenturl = this.opt.documenturl;


      this._callbackEvents = [];
      this.iframeIsReady = false;
      this.queueValues = [];

      this.waitForIframeLoad();

      // get parent
      const oParent = this.parent;
      if (oParent) {
        oParent.dhx.showInnerScroll();
        oParent.dhx.attachHTMLString(`<div class='docviewerframe' id='${this.opt.id}-container'></div>`);
      }
    }
  });

  // methods for docviewer
  $.ak_docviewer.prototype = {
    componentOptions: function() {
      const oSelf = this;
      return {
        watch: {
          'state.attributes.hasChanges': function(newValue, oldValue) {
            oSelf._hasChangesWatcher(newValue, oldValue);
          }
        }
      };
    },
    /**
     * Post message event binding for iframe scripts loading
     * @memberof ak_docviewer
     * @private
     * @instance
     */
    waitForIframeLoad() {
      const responseHandler = res => {

        if (res.data.docviewerid === this.opt.id && res.data.eventName == 'virtualViewerLoaded') {
          this.iframeIsReady = true;
          this.queueValues.forEach(value => {
            this.postMessage(value);
          });
          this.queueValues = [];

        }

      };

      this._iframeLoadListener = responseHandler;

      this.bindEvent(window, 'message', this._iframeLoadListener);
    },
    /**
         * Method for clearing the mutation observer used for dynamic frame switch
         * @instance
         * @private
         * @memberOf ak_docviewer
         */
    waitForIframeUnLoad() {
      this._mutationObserver = new MutationObserver(mutations => {
        // check for removed target
        mutations.forEach(mutation => {
          const nodes = Array.from(mutation.removedNodes);
          const directMatch = nodes.indexOf(this.html) > -1;
          const parentMatch = nodes.some(parent => parent.contains(this.html));

          if (directMatch || parentMatch)
            this.iframeIsReady = false;


        });
      });

      const config = {
        subtree: true,
        childList: true
      };

      this._mutationObserver.observe(this.html.closest('.dhxlayout_base_material').parentNode, config);
    },
    /**
         * Method for clearing the mutation observer used for dynamic frame switch
         * @private
         * @instance
         * @memberOf ak_docviewer
         */
    clearMutationObserver() {
      this._mutationObserver.disconnect();
    },
    /**
         * Watcher for the hasChanges attribute
         */
    _hasChangesWatcher: function(newValue) {
      const oSelf = this,
        oPanelset = oSelf.getAncestor('panelset').dhx,
        cPanelLayout = oSelf.getAncestor('panel').opt.layout,
        oPanelCell = oPanelset.cells(cPanelLayout).cell;

      if (oSelf.oVuexState.attributes.enabled !== '' && oSelf.oVuexState.attributes.hasChangesStyle !== '')
        $(oPanelCell).attr(oSelf.oVuexState.attributes.hasChangesStyle, newValue);

      this._callEventOnStateChanged({ state: 'hasChanges', value: newValue });

    },
    // finish construct **********
    finishConstruct: function() {
      const oSelf = this;

      // if no url is specified, don't display anything
      if (oSelf.documenturl == '') return;

      $(`#${this.opt.id}-container`).append('<iframe name="VVDemo" src=""></iframe>');

      // open default docviewer screen
      const $frame = $(`#${this.opt.id}-container iframe`);

      let cFileName = '';

      if (oSelf.dataSource) {
        const oItem = oSelf.dataSource.dhx.item(oSelf.dataSource.dhx.getCursor());
        if (oItem && oItem[oSelf.opt.displayedFields.toLowerCase()])
          cFileName = oItem[oSelf.opt.displayedFields.toLowerCase()].replace('docViewerFile:', '');
      }


      $frame.attr('src', oSelf._buildURL(cFileName));
      this.html = $frame[0];

      // in case of dynamic view switch, set flag not ready
      this.waitForIframeUnLoad();

      // set title and panelMenu buttons in panel header
      akioma.setPanelHeader(oSelf);

      // Listens to message from child window
      this._onPostMessageListener = this._onPostMessage.bind(this);
      this.bindEvent(window, 'message', this._onPostMessageListener);

    },
    /**
     * Method used for calling a Docviewer / Snowbound method with or without params
     * @param  {string} cMethodName The name of the method to call
     * @param  {array} params      The list of parameters to call the method with.
     * @instance
     * @memberOf  ak_docviewer
     * @return {Promise}
     */
    callDocViewerMethod: function(cMethodName, params) {
      const oSelf = this;
      return new Promise(resolve => {
        oSelf.postMessage({
          method: cMethodName,
          params: params
        });


        function responseHandler(res) {

          if ((res.data.eventType == 'results' && cMethodName == res.data.methodName)
                        && (res.data.docviewerid && res.data.docviewerid === oSelf.opt.id)) {
            resolve(res.data.result);
            oSelf.unbindEvent(window, 'message', responseHandler);
          }
        }

        oSelf.bindEvent(window, 'message', responseHandler);

      });

    },

    /**
         * Listener for post message events
         * @param {object} e Event with data from iframe
         * @instance
         * @memberof ak_docviewer
         * @private
         * @return  {void}
         */
    _onPostMessage: function(e) {
      const oSelf = this;

      if (e.data.eventType == 'Error') {
        akioma.notification({ type: 'error', text: e.data.eventMsg });
        return false;
      }

      // focus window when clicking the docviewer frame
      if (e.data.docviewerid && e.data.docviewerid === oSelf.opt.id && e.data.eventName == 'ClickFocus') {
        const win = oSelf.dynObject.container.controller;
        win.dhx.bringToTop();
        return false;
      }

      if (e.data.eventType == 'results')
        return false;
      // check for dirty state changes
      try {
        if (e.data.docviewerid && e.data.docviewerid === oSelf.opt.id && e.data.eventName === 'hasChanges') {

          if (e.data.value == true) {
            if (!oSelf.oVuexState.attributes.hasChanges)
              oSelf._dispatch('incrementHasChanges', 1);
            oSelf._dispatch('setHasChanges', e.data.value);

          } else if (oSelf.oVuexState.attributes.hasChanges)
            oSelf._dispatch('decrementHasChanges', 1);

          return;
        }
      } catch (e) {
        akioma.notification({ type: 'error', text: `Error setting dirty flag for docviewer ${oSelf.opt.name}` });
      }


      if (e.data.docviewerid && e.data.docviewerid === oSelf.opt.id) {
        for (const x in oSelf._callbackEvents)
          oSelf._callbackEvents[x](e.data);

      }

      // set params
      this.dynObject.akEvent = e.data;

      /**
             * Client side code executed when a docviewer callback is called
             * @event ak_docviewer#eventDocCallback
             * @type {object}
             */
      if (oSelf.opt.eventDocCallback)
        app.controller.callAkiomaCode(oSelf, oSelf.opt.eventDocCallback);
    },

    /**
     * Method executed on data available
     *
     * @memberof ak_docviewer
     * @instance
     */
    dataAvailable: function() {
      this.cursorChange();
    },

    /**
     * Method executed on cursor change
     *
     * @memberof ak_docviewer
     * @instance
     */
    cursorChange: function() {
      try {
        if (isNull(this.dataSource) || this.opt.displayedFields === '')
          return;

        const oItem = this.dataSource.dhx.item(this.dataSource.dhx.getCursor());
        if (isNull(oItem) && !isNull(this.opt.displayedFields))
          return;

        const fieldVal = oItem[this.opt.displayedFields.toLowerCase()];
        let cFileName = fieldVal;
        if (fieldVal)
          cFileName = fieldVal.replace('docViewerFile:', '');


        this.setValue(cFileName);
      } catch (e) {
        console.error('Error on cursorChange in docViewer', e);
      }
    },
    /**
     * Method for setting up event listener for Docviewer Callback Events
     * @param {function} fn
     * @returns {number} id
     * @instance
     * @memberof ak_docviewer
     */
    fireOnCallbackEvent: function(fn) {
      return this._callbackEvents.push(fn);
    },
    /**
     * Method for removing callback event based on given index
     * @param {number} index Event index
     * @memberof ak_docviewer
     * @instance
     */
    removeCallbackEvent: function(index) {
      if (index > -1)
        this._callbackEvents.splice(index, 1);

    },
    /**
         * Method for binding post message events on docviewer
         *
         * @param   {DomNode}  element       elemnt to bind the event on
         * @param   {string}  eventName     the name of the event
         * @param   {function}  eventHandler  the event handler
         * @instance
         * @private
         * @memberof ak_docviewer
         * @return  {void}
         */
    bindEvent: function(element, eventName, eventHandler) {
      if (element.addEventListener)
        element.addEventListener(eventName, eventHandler, false);
      else if (element.attachEvent)
        element.attachEvent(`on${eventName}`, eventHandler);

    },
    /**
         * Method for unbinding events in docviewer
         * @param   {DomNode}  element       element to bind the event on
         * @param   {string}  eventName     the name of the event
         * @param   {function}  eventHandler  the event handler
         * @instance
         * @private
         * @memberOf ak_docviewer
         */
    unbindEvent: function(element, eventName, eventHandler) {
      if (element.addEventListener)
        element.removeEventListener(eventName, eventHandler, false);
      else if (element.attachEvent)
        element.detachEvent(`on${eventName}`, eventHandler);

    },
    /**
         * Hide toolbar button in docviewer based on given name
         * @param {string} name The name of the toolbar button
         * @returns {void}
         */
    hideToolbarButton: function(name) {
      this.callVirtualViewerMethod('hideToolbarButton', [name]);
    },
    /**
         * Show toolbar button in docviewer based on given name
         * @param {string} name The  name of the toolbar button
         * @returns {void}
         */
    showToolbarButton: function(name) {
      this.callVirtualViewerMethod('showToolbarButton', [name]);
    },
    /** *
         * Hide Side Toolbar navigation in docviewer
         * @returns {void}
         */
    hideSideToolbar: function() {
      this.callVirtualViewerMethod('hideSideToolbar');
    },
    /**
         * Shows side Toolbar navigation in docviewer
         * @returns {void}
         */
    showSideToolbar: function() {
      this.callVirtualViewerMethod('showSideToolbar');
    },
    /**
         * Hides top Toolbar navigation in docviewer
         * @memberof ak_docviewer
         * @returns {void}
         */
    hideTopToolbar: function() {
      this.callVirtualViewerMethod('hideTopToolbar');
    },
    /**
         * Shows top Toolbar navigation in docviewer
         * @instance
         * @memberof ak_docviewer
         * @returns {void}
         */
    showTopToolbar: function() {
      this.callVirtualViewerMethod('showTopToolbar');
    },
    /**
         * Method for calling methods inside the docviewer iframe VirtualViewer class
         * @param {string} method The name of the method
         * @param {array} params The array of params
         * @memberof ak_docviewer
         * @instance
         * @returns {void}
         */
    callVirtualViewerMethod: function(method, params) {
      this.postMessage({
        method: method,
        parent: 'VirtualViewer',
        params: (params || [])
      });
    },
    /**
         * Method for postMessage sending to docviewer iframe
         * @param {object} opt
         * @param {string} method
         * @param {array} params
         * @param {string} docviewerid
         * @instance
         * @memberof ak_docviewer
         * @returns {void}
         */
    postMessage({ method, parent, params, documentid }) {
      const data = {};

      if (documentid !== undefined)
        data.documentid = documentid;

      if (method !== undefined)
        data.callMethod = method;

      if (parent !== undefined)
        data.callMethodParent = parent;

      if (params !== undefined)
        data.params = params;

      try {
        if (isNull(this.html.contentWindow)) {
          this.html.addEventListener('load', () => {
            this.html.contentWindow.postMessage(data, '*');
          });
        } else
          this.html.contentWindow.postMessage(data, '*');

      } catch (e) {
        console.error(e);
      }
    },
    /**
     * Method used for setting the document id for loading the actual document in the Snowbound iframe
     * @instance
     * @param {string} cFileName The document file name
     * @memberof ak_docviewer
     */
    setValue: function(cFileName) {
      const oSelf = this;
      const $frame = $(`#${this.opt.id}-container iframe`);
      const cExistingSRC = $frame.attr('src');


      return new Promise(resolve => {
        // method to bind for documentload event
        function responseHandler(res) {
          const oRes = res.data;
          if (('switchToTab' == oRes.eventName) && (oRes.docviewerid && oRes.docviewerid === oSelf.opt.id)) {
            oSelf.unbindEvent(window, 'message', responseHandler);
            resolve();
          }
        }

        oSelf.bindEvent(window, 'message', responseHandler);

        // sets the iframe src via url change or postMessages
        if (cExistingSRC == '') {
          this.iframeIsReady = false;
          $frame.attr('src', oSelf._buildURL(cFileName));
        } else if (this.iframeIsReady) {
          // wait for javascript execution inside iframe
          this.postMessage({ documentid: cFileName });
        } else
          this.queueValues.push({ documentid: cFileName });


      });
    },

    /**
         * Reloads the snowbound document by calling the reloadDocumentModel method
         * @instance
         * @memberof ak_docviewer
         */
    reloadDocumentModel: function() {
      // virtualViewer.reloadDocumentModel()

      this.postMessage({ 'method': 'reloadDocumentModel', params: [] });
      this.postMessage({ 'method': 'setAnnotationsModifiedSinceInit', 'parent': 'documentModel', params: [false] });
      this.postMessage({ 'method': 'setMetadataModifiedSinceInit', 'parent': 'documentModel', params: [false] });
      this.postMessage({ 'method': 'setPagesModifiedSinceInit', 'parent': 'documentModel', params: [false] });
      this.postMessage({ 'method': 'setAnnotationsAsNotNew', 'parent': 'documentModel', params: [] });

    },
    _buildURL: function(cFileName) {
      return (`${this.documenturl}?documentId=${cFileName}&docviewerid=${this.opt.id}`);
    },
    // destroy
    destroy: function() {

      $(`#${this.opt.id}-container`).remove();
      this._callbackEvents = [];
      this.unbindEvent(window, 'message', this._onPostMessage);
      this.unbindEvent(window, 'message', this._iframeLoadListener);
      this.clearMutationObserver();
    }

  };
})(jQuery, jQuery);
