CKEDITOR.config.resize_enabled = false;

dhtmlXForm.prototype.items.textedit = {
  render: function(item, data) {

    item._type = 'ta';
    item._enabled = true;
    const oSelf = $.getObjectByName({ id: data.userdata.id });
    item.akElm = oSelf;
    oSelf.dhx = item;
    item.parentNode.className = `${item.parentNode.className} form_base_for_editor ittext_editor_control`;

    this.doAddLabel(item, data);
    this.doAddInput(item, data, ('TEXTAREA'), (null), true, true, 'dhxlist_txt_textarea');
    this.doAttachEvents(item);

    oSelf.oInitialOptions = {};
    if (app.sessionData.ckEditorCustomConfig)
      app.controller.callAkiomaCode(oSelf, app.sessionData.ckEditorCustomConfig);
    else {
      const oInitialOptions = {
        templates_replaceContent: false,
        removePlugins: 'image,xdsoft_translater,floating-tools'
      };
      const cConfig = '/config/akStandard.js';
      const cLang = akioma.entry(1, akioma.translation.getLanguage(), '-');
      let cToolbarName = (oSelf.opt.simpleMode) ? 'Simple' : null;

      if (oSelf.opt.editorToolbar)
        cToolbarName = oSelf.opt.editorToolbar;

      if (oSelf.security.readOnly) {
        cToolbarName = 'ReadOnly';
        oInitialOptions.allowedContent = true;
      }

      if (!isNull(cToolbarName))
        oInitialOptions.toolbar = cToolbarName;


      oInitialOptions.language = cLang;
      oInitialOptions.customConfig = cConfig;
      oInitialOptions.filebrowserImageBrowseUrl = 'dummyUrl_handled_dynamically';

      oInitialOptions.extraAllowedContent = 'td th[width,height]; img[src,alt,width,height]';
      oSelf.oInitialOptions = oInitialOptions;

    }

    oSelf.oInitialOptions.on = {
      instanceReady: function() {
        this.dataProcessor.writer.setRules('p', {
          indent: false,
          breakBeforeOpen: true,
          breakAfterOpen: false,
          breakBeforeClose: false,
          breakAfterClose: false
        });
      }
    };

    // set akstyle in ittext
    $(oSelf.dhx.children[0]).attr('akstyle', oSelf.opt.customStyle);

    const textarea = $(item).find('textarea');
    textarea.ckeditor(() => {
      oSelf.finishEditor();
    }, oSelf.oInitialOptions);

    return this;
  },

  setValue: function(item, value) {
    if (akioma.sessionContext && akioma.sessionContext.eSwatSessionContext.ckEditorImageSource)
      value = value.replace(/(<img[^]+?src=")(?!http:\/\/)(.*?)"/gi, `$1${akioma.sessionContext.eSwatSessionContext.ckEditorImageSource}$2"`);
    $(item).find('textarea').val(value);
  },

  getValue: function(item) {
    let value = $(item).find('textarea').val();
    if (akioma.sessionContext && akioma.sessionContext.eSwatSessionContext.ckEditorImageSource)
      value = value.replace(new RegExp(`(<img[^]+?src=")(${akioma.sessionContext.eSwatSessionContext.ckEditorImageSource})`, 'gi'), '$1');
    return value;
  },

  userDisable: function(item) {
    if (String(item.className).search('disabled') < 0) item.className += ' disabled';
    // if (item.akElm.oCkEditor)
    item.akElm.callAfterReady(() => {
      item.akElm.oCkEditor.setReadOnly();
    });

  },

  userEnable: function(item) {
    if (String(item.className).search('disabled') >= 0) item.className = String(item.className).replace(/disabled/gi, '');
    // if (item.akElm.oCkEditor)
    //   item.akElm.oCkEditor.setReadOnly(false);
    item.akElm.callAfterReady(() => {
      item.akElm.oCkEditor.setReadOnly(false);
    });
  },

  setWidth: function(item, value) {
    try {
      const oText = $(item).find('textarea').ckeditorGet();
      if (typeof value != 'number')
        oText.resize('100%', '100%');
      else
        oText.resize(value, '100%');
    } catch (e) {
      !_isIE && console.error([ 'Error setting width', e, item, value ]);
    }
  },

  getTextedit: function(item) {
    return item;
  }
};
(function() {
  for (const a in { doAttachEvents: 1, doAddLabel: 1, doAddInput: 1, destruct: 1, doUnloadNestedLists: 1, setText: 1, getText: 1 })
    dhtmlXForm.prototype.items.textedit[a] = dhtmlXForm.prototype.items.input[a];
})();
dhtmlXForm.prototype.getTextedit = function(name) {
  return this.doWithItem(name, 'getTextedit');
};
dhtmlXForm.prototype.setFormData_textedit = function(name, value) {
  return this.doWithItem(name, 'setValue', value);
};
dhtmlXForm.prototype.getFormData_textedit = function(name) {
  return this.doWithItem(name, 'getValue');
};

(function($) {

  // ********************* lookup ********************
  $.extend({
    /**
     * SwatText Control
     * @class ak_ittext
     * @param {Object} options Repository attributes for SwatText.
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     * @param {string} options.contextMenu the id of a menuStructure which will be used as a context-menu
     * @param {string} options.floatingActionButton the id of a menustructure, which will be rendered as a FAB
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {string} options.panelMenu comma separated list of menu-structures which will be shown as panelHeader-Buttons </br>
     * Can also contain the flag #NoDropDown that specifies that the menu should not be loaded as a dropdown but each menu item should be added in the panel-Header. </br>
     * For example: </br>
     * <code>menuStructSave,menuSettings#NoDropDown,menuLookup</code> </br>
     * The buttons support font icons with the following attributes: </br>
     * 1. Css attributes, defined like this: fa fa-user#color:red </br>
             * 2. Css classes, defined like this: fa fa-user#_style:module_prod
             * 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod </br>
     * @param {string} options.typeRange The type range which makes up a combobox in a Toolbar
     * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
     * @param {boolean} options.simpleMode Activates the simple mode of the text editor, which only provides basic formatting options. Used for notes-like text.
     * @param {string} options.ForeignFields A comma-separated list, consisting of thefirst local db fieldname, followed by the matching source temp-table field name, followed by more pairs if there is more than one field to match.
     * @param {string} options.RecMode If "SINGLE" then the text module expects to get a specific record from its datasource. In this case, the type and language are not relevant.&#10;If "MULTI" then only the refHdl is specified from the datasource, and type/lang come from the textmodule
     * @param {string} options.EventAkAfterSave Executed after save event
     * @param {string} options.titleHeader specifies which panelHeader to use. when empty, then uses the header of its own panel. if "none" then no header at all. if "parent" it uses the header of the parent panel
     * @param {string} options.textToolbar Toolbar definition for Text editor
     * @param {boolean} options.ENABLED WidgetAttributes Enabled
     * @fires ak_ittext#EventOnInitialize
     */
    ak_ittext: function(options) {

      akioma.BaseFormDataField.call(this, options);

      const defaults = { id: uuid() };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;
      this._panelEvents = [];
      this._changeEvents = [];

      this.registerDynObject = true;
      this.registerVuexWatcher = true;
      this.useParentDynObjectLink = true;

      // set title in panel (text sitzt in einem Form, deshalb 2x parent... */
      if (!this.opt.title)
        this.opt.title = 'Texteditor';

      this.opt.title = akioma.tran(`${akioma.getForm(this).opt.name}._title`, { defaultValue: this.opt.title });
      this.parent.parent.setOption('title', this.opt.title);

      // get parent
      const oParent = this.parent;


      if (oParent) {

        if (!this.opt.customStyle)
          this.opt.customStyle = this.view;
        this._eventsAfterLoaded = [];
        oParent.prop.fields.push({
          type: 'textedit',
          required: this.opt.required,
          mandatory: this.opt.required,
          hasError: false,
          errorMsg: '',
          name: this.opt.name,
          rows: 20,
          position: 'label-top',
          className: 'ittext_control',
          disabled: this.opt.disabled,
          enabled: !this.opt.disabled,
          userdata: { id: this.opt.id }
        });

        // append to elements in form
        if (this.parent.view == 'form')
          this.parent.elements.push(this);

      }
    }
  });

  // methods for form
  Object.assign($.ak_ittext.prototype, akioma.BaseFormDataField.prototype, {
    componentOptions: function() {
      const oSelf = this;
      return {
        watch: {
          'getters.getFormFieldState': {
            fn: function(newValue, oldValue) {
              oSelf._hasFormFieldChangesWatcher(newValue, oldValue);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldEnabled': {
            fn: function(bEnabled) {
              oSelf._enabledFormFieldWatcher(bEnabled);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldMandatory': {
            fn: function(bMandatory) {
              oSelf._mandatoryFormFieldWatcher(bMandatory);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldError': {
            fn: function(newValue, oldValue) {
              oSelf._errorFormFieldWatcher(newValue, oldValue);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldErrorMsg': {
            fn: function(cErrorMsg) {
              oSelf._errorFormFieldMsgWatcher(cErrorMsg);
            },
            params: [this.opt.id]
          }

        }
      };
    },
    _enabledFormFieldWatcher: function(bEnabled) {
      const oSelf = this;
      const oForm = akioma.getForm(oSelf);
      const cName = oSelf.opt.name;

      if (oForm.dhx) {
        if (bEnabled)
          oForm.dhx.enableItem(cName);
        else
          oForm.dhx.disableItem(cName);

      }
    },
    _mandatoryFormFieldWatcher: function(bMandatory) {
      const oSelf = this;
      const oForm = akioma.getForm(oSelf);
      const cName = oSelf.opt.name;

      if (oForm && oForm.dhx) {
        if (typeof (oSelf.setRequired) == 'function')
          oSelf.setRequired(bMandatory);

        oForm.dhx.setRequired(cName, bMandatory);
      }
    },
    /**
     * Watcher for the form error message
     */
    _errorFormFieldMsgWatcher: function(newValue) {
      const oSelf = this;
      const cName = oSelf.opt.name;
      const oForm = akioma.getForm(oSelf);
      const oFormField = akioma.getDhxFormElement(oForm.dhx, cName);

      // setup error message under input
      if (oFormField) {
        const oFormCtrl = $(oFormField).find('> .dhxform_control');
        if (oFormCtrl.find('.validation-error-smartmessage').length == 0)
          oFormCtrl.append(`<span class="validation-error-smartmessage">${newValue}</span>`);
        else
          oFormCtrl.find('.validation-error-smartmessage').text(newValue);
      }

    },
    // finish construct **********
    finishConstruct: function() {
      this.form = this.parent.dhx;
      this.cActIndex = '-';

      try {
        setTimeout(() => {
          if (this.parent && this.parent.parent && this.parent.parent.view === 'panel') {
            const filterednamespace = this.parent.getFilteredNamespace();
            this.parent.parent.afterAttachChild(filterednamespace);
          }
        }, 1);
      } catch (e) {
        console.error(e);
      }
    },

    // Sets custom configuration for editor
    setConfig: function(oConfig) {
      const oSelf = this;
      oSelf.oInitialOptions = oConfig;
    },
    /**
     * Method for addding change event listener
     *
     * @param   {function}  listenerFn  Listener function
     * @memberof ak_ittext
     */
    addChangeEventOnce(listenerFn) {
      this._changeEvents.push(listenerFn);
    },

    // finish editor ***************
    finishEditor: function() {
      // get size from parent
      const oPanel = this.getAncestor('panel').dhx,
        oSelf = this;
      if (oPanel) {
        let iWidth = oPanel.getWidth(),
          iHeight = oPanel.getHeight(),
          oText;

        try {
          oText = $(this.form.getTextedit(this.opt.name)).find('textarea').ckeditorGet();

          oText.on('blur', () => {
            $(oSelf.dhx).removeClass('focusedinp');
          });
          oText.on('focus', () => {
            oSelf._old_value = oText.getData();
            $(oSelf.dhx).addClass('active').addClass('focusedinp');
          });

          const oForm = oSelf.getAncestor('form');

          oText.on('change', () => {
            const new_value = oText.getData();

            // if value has changed since focused then there are changes and dispatch action
            if (oSelf._old_value != undefined && new_value !== oSelf._old_value
              && oForm.aPausedDirtyFields.indexOf(oSelf.opt.name) === -1)

              oForm.setFieldHasChanges(oSelf.opt.name, true);

            try {
              oSelf._changeEvents.forEach(ev => ev());
              oSelf._changeEvents = [];
            } catch (e) {
              console.error(e);
            }
          });

          this.dynObject.parent.ckEdit = oText;
          this.oCkEditor = oText;
          oText.akElm = this;

          const ckEditorContent = $(oText.ui.contentsElement.$).children().first();

          if (oText.config.font_defaultLabel)
            ckEditorContent.css('font-family', oText.config.font_defaultLabel);
          if (oText.config.fontSize_defaultLabel)
            ckEditorContent.css('font-size', `${oText.config.fontSize_defaultLabel}pt`);

          const $Form = $(this.parent.dhx.cont);
          $Form.css('overflow', 'hidden');
          const iDiffHeight = ($(oText.container.$).offset().top - $(oPanel.cell).offset().top);
          const iDiffWidth = 16;
          iHeight -= ($(oText.container.$).find('.cke_top').height() + 42);
          iWidth -= (parseInt($(this.oCkEditor.container.$).find('.cke_editable').css('padding-left')) + parseInt($(this.oCkEditor.container.$).find('.cke_editable').css('padding-right')));
          oText.resize(iWidth + iDiffWidth, iHeight - iDiffHeight, true);

          const oPanelset = this.getAncestor('panelset');

          const getCkEditorWidth = () => oPanel.getWidth() + iDiffWidth - parseInt($(this.oCkEditor.container.$).find('.cke_editable').css('padding-left')) - parseInt($(this.oCkEditor.container.$).find('.cke_editable').css('padding-right'));

          const getCkEditorHeight = () => oPanel.getHeight() - ($(this.oCkEditor.container.$).offset().top - $(oPanel.cell).offset().top) - ($(this.oCkEditor.container.$).find('.cke_top').height() + 42);

          if (oPanelset) {
            const panelsetIdResizeEv = oPanelset.dhx.attachEvent('onResizeFinish', () => {
              let iWidth = getCkEditorWidth(),
                iHeight = getCkEditorHeight();
              oText.resize(iWidth, iHeight, true);
              iWidth = getCkEditorWidth(),
              iHeight = getCkEditorHeight();
              oText.resize(iWidth, iHeight, true);
            });
            const panelsetIdResizeFinishEv = oPanelset.dhx.attachEvent('onPanelResizeFinish', () => {
              let iWidth = getCkEditorWidth(),
                iHeight = getCkEditorHeight();
              oText.resize(iWidth, iHeight, true);
              iWidth = getCkEditorWidth(),
              iHeight = getCkEditorHeight();
              oText.resize(iWidth, iHeight, true);

            });
            this._panelEvents.push(panelsetIdResizeEv);
            this._panelEvents.push(panelsetIdResizeFinishEv);
          }


          // call finishEditor events now
          for (const ev in oSelf._eventsAfterLoaded)
            oSelf._eventsAfterLoaded[ev]();

          oSelf._eventsAfterLoaded = [];


          /**
           * Client side code executed on initialize event for ckeditor from articlew window
           * @event ak_ittext#EventOnInitialize
           * @type {object}
           */
          if (this.opt.EventOnInitialize)
            app.controller.callAkiomaCode(this, this.opt.EventOnInitialize);

        } catch (e) {
          akioma.log.error([ 'Error getting editor', e ]);
        }
      } else
        akioma.log.error([ 'Textedit: no valid panel', this ]);
    },

    callAfterReady: function(fn) {
      if (this.oCkEditor == undefined)
        this._eventsAfterLoaded.push(fn);
      else
        fn();
    },

    // get value *****************
    getValue: function() {
      const cHtml = this.getValueFormatted();
      return $(`<div>${cHtml}</div>`).text();
    },

    // get value formatted - HTML version of text **********
    getValueFormatted: function() {
      return this.form.getItemValue(this.opt.name);
    },

    // set value ********************
    setValue: function(cValue) {
      this.form.setItemValue(this.opt.name, cValue);
    },

    dataAvailable: function() {
      this.cursorChange();
    },

    // called when cursor has been changed *********
    cursorChange: function() {
      delete this._old_value;
      const oType = this.dynObject.parent.getLink('TABLEIO:SOURCE').controller;
      const oToolbar = this.dynObject.parent.getLink('TABLEIO:SOURCE').controller;
      const oToolbarMenuPromise = oToolbar.toolbarMenuStructPromise;

      // on monu loaded and if the textLang and textTypes are loaded/ combos above itText then load the text data
      const onMenuLoaded = function() {
        if (oToolbar) {
          if (oToolbar.textLangPromise && oToolbar.textLangPromise.state() != 'resolved' && oToolbar.textTypePromise && oToolbar.textTypePromise.state() != 'resolved')
            return;
        }
        getAkText(oType, 'external');
      };
      if (oToolbarMenuPromise && oToolbarMenuPromise.state() != 'resolved') {
        oToolbarMenuPromise.done(() => {
          onMenuLoaded();
        });
      } else
        onMenuLoaded();


    },

    /**
     * Removes the title attribute (tooltip functionality)
     * @instance
     * @memberof ak_ittext
     */
    removeTitle: function() {
      $(this.dhx).find('.cke_wysiwyg_div').attr({ title: '', 'aria-label': '' });
    },

    // destroy ****************
    destroy: function() {
      try {
        // get editor
        const oText = this.form.getTextedit(this.opt.name),
          oCK = $(oText).find('textarea').ckeditorGet();

        // delete instance
        if (oCK)
          CKEDITOR.remove(oCK);
        oCK.destroy(true);

        const oPanelset = this.getAncestor('panelset');
        if (oPanelset) {
          for (const i in this._panelEvents) {
            const eventId = this._panelEvents[i];
            oPanelset.dhx.detachEvent(eventId);
          }
        }
      } catch (e) {
        akioma.log.error([ 'Error destroying itText', e, this ]);
      }
    }
  });
})(jQuery, jQuery);
