/**
 * Class for Waiting Cursor, progress cursor and blocking windows state (ProgressState)
 * @class WaitCursor
 * @namespace akioma
 * @static
 */
akioma.WaitCursor = class {
  /**
     * Method used for showing the wait cursor and enabling the progress state blocking the user's pointer events
     * @param {dynObject} uiContext
     * @memberof WaitCursor
     * @static
     * @returns {void}
     */
  static showWaitState(uiContext) {
    this.showWaitCursor(uiContext);
    this.showProgressState(uiContext);
    this.showExternalWaitStates(uiContext);
  }

  /**
     * Method for showing External wait state in external screen / linked wait state
     * @param {ak_object} uiContext
     * @static
     * @memberof WaitCursor
     */
  static showExternalWaitStates(uiContext) {
    const externalWindowId = akioma.WaitCursor.getLastActiveExternalWindow();
    let externalWaitState;

    if (externalWindowId)
      externalWaitState = this.externalWaitState.find(link => link.uiContext === uiContext && link.externalWindowId === externalWindowId);
    else
      externalWaitState = this.externalWaitState.find(link => link.uiContext === uiContext);


    this.log.info('Showing External Wait State', uiContext, externalWaitState, (externalWaitState ? externalWaitState.externalWindowId : null));

    if (!isNull(externalWaitState)) {

      const emitData = {
        type: 'ShowExternalWaitStates',
        user: window.akioma.socketConnection.generateRoomKey(),
        externalWindowId: externalWaitState.externalWindowId
      };

      if (window.akioma.socketConnection.exists())
        window.akioma.socketConnection.emitJoinRoom(emitData);

    }
  }

  /**
   * Method for returning last active external window id
   * @returns {string} External window id
   */
  static getLastActiveExternalWindow() {
    return this.externalWindowId;
  }

  /**
     * Method for hinding External screen wait state / linked wait state
     * @param {ak_object} uiContext
     * @static
     * @memberof WaitCursor
     */
  static hideExternalWaitStates(uiContext) {
    const externalWindowId = akioma.WaitCursor.getLastActiveExternalWindow();
    let externalWaitState;

    if (externalWindowId)
      externalWaitState = this.externalWaitState.find(link => link.uiContext === uiContext && link.externalWindowId === externalWindowId);
    else
      externalWaitState = this.externalWaitState.find(link => link.uiContext === uiContext);


    this.log.info('Hiding External Wait State', uiContext, externalWaitState, (externalWaitState ? externalWaitState.externalWindowId : null));

    if (!isNull(externalWaitState)) {

      const emitData = {
        type: 'HideExternalWaitStates',
        user: window.akioma.socketConnection.generateRoomKey(),
        externalWindowId: externalWaitState.externalWindowId
      };

      if (window.akioma.socketConnection.exists())
        window.akioma.socketConnection.emitJoinRoom(emitData);

    }

  }

  /**
     * Method for linking external screen wait state
     * @param {ak_object} uiContext The control in this screen linked to the external window
     * @param {string} externalWindowId the external window id
     * @static
     * @memberof WaitCursor
     */
  static linkExternalWaitState(uiContext, externalWindowId) {
    const existingLinkedWaitState = this.externalWaitState.find(state => uiContext === state.uiContext && state.externalWindowId === externalWindowId);

    this.externalWindowId = externalWindowId;

    if (isNull(existingLinkedWaitState)) {
      this.log.info('New link registration for external screen:', uiContext, externalWindowId);

      this.externalWaitState.push({
        uiContext,
        externalWindowId
      });
    }
  }

  /**
     * Method used for hiding the wait cursor and removing the progress state blocking the user's pointer events
     * @param {dynObject} uiContext The dynObject Source of the wait State
     * @memberof WaitCursor
     * @static
     * @returns {void}
     */
  static hideWaitState(uiContext) {
    this.hideWaitCursor(uiContext);
    this.hideProgressState(uiContext);
    this.hideExternalWaitStates(uiContext);
  }

  /**
     * Method for showing the loading cursor, round circle animating in panels
     * @param {dynObject|ak_panel}    uiContext Swat dynObject
     * @param {boolean}               bCancellable If wait cursor can be cancelled
     * @static
     * @memberof WaitCursor
     * @returns {void}
     */
  static showWaitCursor(uiContext, bCancellable) {
    let oCont = null;

    if (uiContext.type == 'window')
      oCont = uiContext.controller;
    else if (uiContext.view == 'panel')
      oCont = uiContext;
    else
      oCont = uiContext.controller.getAncestor('panel');

    if (!isNull(uiContext.container))
      uiContext.container.controller.lastUIContext = uiContext.controller;
    else
      uiContext.getAncestor('window').lastUIContext = uiContext.controller;

    oCont.progressOn(null, bCancellable);
  }

  /**
   * Method for cancelling the loading cursor, round circle animating in panels
   * @param {ak_panel}    uiContext Swat dynObject
   * @static
   * @memberof WaitCursor
   * @returns {void}
   */
  static cancelWaitCursor(uiContext) {
    let oCont = null;

    if (uiContext.type == 'window') return;
    if (uiContext.view == 'panel' || uiContext.view == 'frame')
      oCont = uiContext;
    else
      oCont = uiContext.controller.getAncestor('panel');

    if (!oCont) return;
    if (!oCont.loadingBatches) {
      if (oCont.view == 'panel') { // panel
        const oChild = oCont.getFirstChild();
        if (oChild && oChild.dataSource) oChild.dataSource.cancelCurrentRequest(); // cancel JSDO request; hide cursor done in afterFill once request is marked as cancelled;
      } else { // frame
        const oDataSource = oCont.getDataSource().controller;
        if (oDataSource) oDataSource.cancelCurrentRequest();
      }
    }
  }

  /**
     * Method for hiding the loading cursor
     * @param {dynObject|ak_panel}   uiContext Swat dynObject
     * @memberof WaitCursor
     * @static
     * @returns {void}
     */
  static hideWaitCursor(uiContext) {
    let oCont = null;
    if (uiContext.type == 'window')
      oCont = uiContext.controller;
    else if (uiContext.view == 'panel')
      oCont = uiContext;
    else
      oCont = uiContext.controller.getAncestor('panel');

    if (!uiContext.loadingBatches)
      oCont.progressOff();
  }

  /**
     * Method for activating the progress state, this will block the user from closing
     * or clicking on buttons inside the window/container object
     * @param {dynObject}   uiContext Swat dynObject
     * @static
     * @memberof WaitCursor
     * @returns {void}
     */
  static showProgressState(uiContext) {
    uiContext.container.controller._setProgressState(true);
  }

  /**
     * Method for deactivating the progress state, unblocks user action in window/container object
     * @param {dynObject}   uiContext Swat dynObject
     * @memberof WaitCursor
     * @static
     * @returns {void}
     */
  static hideProgressState(uiContext) {
    uiContext.container.controller._setProgressState(false);
  }

  /**
     * Method returning current blocking progress state
     * @static
     * @memberof WaitCursor
     * @returns {boolean}
     */
  static isProgressStateActive(uiContext) {
    let oWin = null;

    if (uiContext.view == 'panel')
      oWin = uiContext.controller;
    else
      oWin = uiContext.controller.getAncestor('window');


    return oWin.hasActiveProgressState;
  }

  /**
     * Method returning current global state of wait cursor
     * @static
     * @memberof WaitCursor
     * @returns {boolean}
     */
  static isGlobalProgressStateActive() {
    return $('div[akloadingwindow="true"]').length > 0;
  }

  /**
     * Method for returning if element is in wait state
     * @param {dynObject} uiContext
     * @returns {boolean}
     */
  static isWaitStateActive(uiContext) {
    const container = uiContext.container;
    if (!isNull(container))
      return container.controller.hasActiveProgressState;
    return false;
  }

  /**
     * Method returning given uiContext dynObject waitCursor visibility
     * @param {dynObject|ak_panel}   uiContext Swat dynObject or panel control
     * @static
     * @memberof WaitCursor
     * @returns {boolean}
     */
  static isCursorVisible(uiContext) {
    let oPanel = null;

    if (uiContext.view == 'panel')
      oPanel = uiContext.controller;
    else
      oPanel = uiContext.controller.getAncestor('panel');

    return oPanel.hasActiveCursor;
  }

  /**
     * Method for creating logger for WaitCursor class
     * @static
     * @memberof WaitCursor
     * @returns {void}
     */
  static createLogger() {
    this.log = akioma.log.getLogger('WaitCursor');
    if (this.debugMode === true)
      this.log.setLevel('debug');
    else
      this.log.setLevel('warn');

  }

  /**
     * Method used for setting the waitCursor for all linked targets of the given businessEntity
     * @param {dynObject} oBusinessEntity The businessEntity Source of the action, uiContext
     * @param {boolean} bProgress The progress State value
     * @param {boolean} bCancellable If the wait cursor can be cancelled. Only used for show cursor
     * @static
     * @memberof WaitCursor
     * @returns {void}
     */
  static setWaitCursorForLinks(oBusinessEntity, bProgress, bCancellable) {
    try {
      if (oBusinessEntity && app.sessionData.showProgressState) {
        const aDisplayTargets = oBusinessEntity.getLinks('DISPLAY:TARGET') || [];
        const aDataTargets = oBusinessEntity.getLinks('DATA:TARGET') || [];

        const aAllLinks = aDisplayTargets.concat(aDataTargets);

        for (const a in aAllLinks) {
          const linkTarget = aAllLinks[a];
          if (linkTarget) {
            // call method again for any dataSource to dataSource link use-cases
            if (linkTarget.type.startsWith('businessEntity')) {
              if (bProgress == false) {
                linkTarget.controller.callAfterPendingRequest(() => {
                  akioma.WaitCursor.setWaitCursorForLinks(linkTarget, bProgress, bCancellable);
                });
              } else
                akioma.WaitCursor.setWaitCursorForLinks(linkTarget, bProgress, bCancellable);

              continue;
            }

            const linkTargetPanel = linkTarget.getFirstParentByType(['panel']);
            const bNotContainer = (linkTarget.type !== 'frame' && linkTarget.type !== 'window');

            if (linkTargetPanel.dhx && bNotContainer) {
              this.log.debug(linkTarget, ' progresson/off on ', linkTargetPanel, bProgress);
              if (bProgress)
                this.showWaitCursor(linkTargetPanel, bCancellable);
              else
                this.hideWaitCursor(linkTargetPanel);
            }
          }
        }
      }
    } catch (e) {
      this.log.error(e);
    }

  }

  /**
     * Method for clearing up the progress state globally for all windows in case of a client-side javascript error
     * @static
     * @memberof WaitCursor
     * @returns {void}
     */
  static onErrorClearProgressState() {
    // fix for errors in console
    window.addEventListener('error', event => {
      const { message, source, lineno, colno, error } = event;
      this.log.debug('Error triggered :', message, source, lineno, colno, error);

      this.clearProgressStateGlobally();
    });
  }

  /**
     * Method used for handling http error codes
     * @memberof WaitCursor
     * @static
     * @returns {void}
     */
  static onHttpFailedClearProgressState() {
    // fix for any possible errors
    akioma.HttpClient.addHttpErrorCallback(xhr => {
      // if 500 error remove progress state globally
      switch (xhr.status) {
        case '500':
        case '401':
        case '403':
          this.clearProgressStateGlobally();
          break;
      }
    });
  }

  /**
     * Method to clear progress state globally on all windows, used in case of error http or client logic JS code error
     * @memberof WaitCursor
     * @static
     * @returns {void}
     */
  static clearProgressStateGlobally() {
    const activeWindows = akioma.swat.MasterLayout.getWindowsInWaitState();
    activeWindows.forEach(win => {
      win._setProgressState(false);
    });
  }

  /**
     * Method for init, setup default values and events
     * @memberof WaitCursor
     * @static
     * @returns {void}
     */
  static init() {
    // default value for progress blocking state set to false
    this.debugMode = false;
    this.externalWaitState = [];
    this.externalWindowId = null;
    this.createLogger(); // creates logger for Wait Cursor

    // cleanup on http error or javascript code error
    this.onErrorClearProgressState(); // setup window onerror event to remove progress state
    this.onHttpFailedClearProgressState(); // setup window onerror event to remove progress state
  }
};

akioma.WaitCursor.init(); // init events and default values


