// htmlcontent custom form element
dhtmlXForm.prototype.items.htmlcontent = {
  render: function(item, data) {
    item._type = 'htmlcontent';
    item._hcId = `htmlcontent${window.dhx4.newId()}`;
    item._data = data;
    item.parentNode.style.width = '100%';
    item.style.width = '100%';
    this.setValue(item, data.value);
    return this;
  },

  setValue: function(item, value) {
    const data = item._data;
    const oSelf = $.getObjectByName({ id: data.userdata.id });

    try {
      if (oSelf) {
        // load special template value
        if (oSelf.opt.template == '$AkiomaHeader' || (app.sessionData.externalScreenContainer !== undefined && oSelf.opt.template == '$OsivTaskbar')) {
          $(oSelf.getAncestor('panel').dhx.cell).addClass('multiw-header-cont');

          // create header for multi window masterLayout
          akioma.MasterLayoutHeader.renderCellsTemplateContent($(item), oSelf);
        } else {
          // if #FORM# get form attribute template
          let cTemplate;
          if (oSelf.opt.template == '#FORM#')
            cTemplate = oSelf.parent.opt.template;
          else
            cTemplate = oSelf.opt.template;

          // check if path is set
          if (cTemplate[0] == '/') {
            const cPath = cTemplate;
            if (oSelf.templateResponse) {
              const template = Handlebars.compile(oSelf.templateResponse);
              if (!$.isEmptyObject(oSelf.oData)) {
                const result = template(oSelf.oData);
                item.innerHTML = result;
              }
            } else {
              dhtmlxAjax.get(cPath, r => {
                cTemplate = r.xmlDoc.response;
                oSelf.templateResponse = cTemplate;
                const template = Handlebars.compile(cTemplate);
                if (!$.isEmptyObject(oSelf.oData)) {

                  let oTemplateDataInit = {};

                  const cTemplateName = oSelf.opt.name;
                  if (akioma.osiv && akioma.osiv[`${cTemplateName}_initialize`]) {
                    const oDisplayLink = akioma.getForm(oSelf).dynObject.getLink('DISPLAY:TRG');

                    if (oDisplayLink) {
                      try {
                        oTemplateDataInit = akioma.osiv[`${cTemplateName}_initialize`](akioma.getForm(oSelf).dynObject);
                      } catch (e) {
                        console.warn(e);
                      }

                    }
                  }
                  if (akioma.handlebars.handlers[`${cTemplateName}_initialize`])
                    oTemplateDataInit = akioma.handlebars.handlers[`${cTemplateName}_initialize`](akioma.getForm(oSelf).dynObject);


                  const oTemplateObj = $.extend(oSelf.oData, oTemplateDataInit);
                  const result = template(oTemplateObj);

                  item.innerHTML = result;
                }
              });
            }
          } else if (cTemplate) { // otherwise use form item attribute
            const template = Handlebars.compile(cTemplate);
            const result = template(oSelf.oData);
            item.innerHTML = result;
          }
        }
      }
    } catch (e) {
      akioma.log.error(e);
    }

    item._value = value;
  },

  getValue: function(item) {
    return item._value;
  },

  destruct: function(item) {
    item._unloading = true;
    // this.d2(item);
    item = null;
  },

  enable: function(item) {
    item._is_enabled = true;
  },

  disable: function(item) {
    item._is_enabled = false;
  }
};

(function() {
  for (const a in { doAddLabel: 1, doAddInput: 1, setText: 1, getText: 1, isEnabled: 1, doUnloadNestedLists: 1 })
    dhtmlXForm.prototype.items.htmlcontent[a] = dhtmlXForm.prototype.items.input[a];
})();

dhtmlXForm.prototype.items.htmlcontent.d2 = dhtmlXForm.prototype.items.input.destruct;

dhtmlXForm.prototype.setFormData_ckeditor = function(name, value) {
  return this.doWithItem(name, 'setValue', value);
};

dhtmlXForm.prototype.getFormData_ckeditor = function(name) {
  return this.doWithItem(name, 'getValue');
};

// called by akLink helper
// global context function
// eslint-disable-next-line no-unused-vars
const callAkLinkLaunchCont = function(event, cHdl) {
  app.controller.launchContainer({
    proc: 'launchContainer.r',
    para: `SelfHdl=${cHdl}&Page=0,1`,
    data: true,
    activation: !event.ctrlKey,
    extLink: cHdl
  });
};

// link helper
Handlebars.registerHelper('akLink', (context, options) => {
  if (context != undefined) {
    const cLink = `javascript: callAkLinkLaunchCont(event, '${options.data.root.selfhdl}');`;
    return `<a href="#" onclick="${cLink}" class="akLinkClass">${context}</a>`;
  } else
    return '';
});

// Lock helper
Handlebars.registerHelper('akLock', context => {
  let img = '';
  if (context)
    img = '<span> / </span><i class="fas fa-lock fa-lg fa-fw akImageFontIcon"></i>';

  return new Handlebars.SafeString(img);
});

// html content helper
Handlebars.registerHelper('akTextHtml', context => {
  let html = '';
  if (context != undefined)
    html = context.replace(/^.*?<body[^>]*>(.*?)<\/body>.*?$/i, '$1');
  return new Handlebars.SafeString(html);
});

// image handlebar helper
Handlebars.registerHelper('akImage', context => {
  let img = '';
  if (context == undefined || context == '')
    context = 'fa fa-shopping-cart#color:orange';

  const iconObject = akioma.icons.getIconType(context);
  switch (iconObject.type) {
    case 'normal-icon':
      img = `<img alt="" src="${context}" class="akImageImg" />`;
      break;
    case 'font-icon':
      img = `<i class="${context} fa-lg fa-fw akImageFontIcon"></i>`;
      break;
    case 'font-icon-attributes':
      img = `<i class="${iconObject.icon} fa-lg fa-fw akImageFontIcon" style="${iconObject.attributes}"></i>`;
      break;
  }

  return new Handlebars.SafeString(img);

});

(function($) {

  // ********************* image ********************
  $.extend({
    /**
     * The HTML Content Akioma Control.
     * Based on the value of name the HTML content will run an initilization function that returns </br>
     * implemented computed properties from the existing BusinessEntity field values.
     * @example
     *  akioma.handlebars.handlers.contactdetails_initialize = function(self){ //nameoftemplate_initialize
     *  // console.log([self, 'contactdetails_initialize']);
     *  // get business Entity data
     *  var oData = self.controller.getCurrentlySelectedDataStoreItem();
     *  var oResult = {};
     *
     *  // get sex
     *  if(oData.sex && oData.sex.toLowerCase() == 'm')
     *      oResult.computed_sex = 'MALE';
     *  else if(oData.sex && oData.sex.toLowerCase() == 'f')
     *      oResult.computed_sex = 'FEMALE';
     *
     *  // get function desc/official function data
     *  if(oData.officialfunction == ''){
     *      oResult.computed_func_label = 'Function Desc';
     *      oResult.computed_function = oData.functiondesc;
     *  }else{
     *      oResult.computed_func_label = 'Official Function';
     *     oResult.computed_function = oData.officialfunction;
     *  }
     *
     *  if(oData.islocked){
     *      oResult.computed_lockedclass = 'active';
     *  }else{
     *      oResult.computed_lockedclass = 'inactive';
     *  }
     *  // return the computed properties
     *  return oResult;
     *};
      * @class ak_htmlcontent
      * @param  {object} options The Repository Attributes
      * @param {boolean} options.CanSort Set to FALSE if this field should not be used to sort the data object query.
      * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
      * @param {string} options.align alignment of column. can be left, right or centered
      * @param {string} options.HELP WidgetAttributes Help
      * @param {number} options.ROW Row position.
      * @param {boolean} options.Mandatory WidgetAttributes Mandatory
      * @param {string} options.VisualizationType WidgetAttributes VisualizationType
      * @param {string} options.LABEL WidgetAttributes Label
      * @param {string} options.EventEntryType Language of "entry" trigger
      * @param {number} options.HEIGHT-CHARS Height in characters. This may currently be used when rendering some objects. There is no get function, use getHeight to retrieve the realized value from an object.
      * @param {boolean} options.VISIBLE WidgetAttributes Visible
      * @param {boolean} options.CHECKED
      * @param {string} options.TemplateFile The relative path and filename of the static object used as the template at design time
      * @param {string} options.InitialValue WidgetAttributes InitialValue
      * @param {string} options.EventEntry Event which should run on entry of a field/focus in field.
      * @param {string} options.LIST-ITEMS
      * @param {boolean} options.CanFilter Set to FALSE if this field should not be used to filter the data object query.
      * @param {string} options.ViewAs The 'ViewAs' definition  of the selection.&#10;- combo-box,radio-set,selection-list OR browse &#10;- Uses colon as separator to define SUB-TYPE for combo-box or &#10;horizontal/vertical radio-set,
      * @param {string} options.FolderWindowToLaunch If Dynamics is running, this property specifies a window to launch upon the occurence of toolbar events "View", "Copy", "Modify" or "Add".
      * @param {string} options.EventLeaveType Language, the event is written in.
      * @param {string} options.TableName WidgetAttributes TableName
      * @param {string} options.FORMAT WidgetAttributes Format
      * @param {string} options.LIST-ITEM-PAIRS
      * @param {string} options.EventAkValidate Client side validation code (JavaScript)
      * @param {string} options.DATA-TYPE WidgetAttributes Data-Type
      * @param {string} options.Validation Validation-Rules, e.g. ValidInteger,ValidEMail...
      * @param {string} options.FilterOperator
      * @param {boolean} options.LABELS If false then NO-LABEL is used.  This attribute applies to most field level widgets and frames
      * @param {string} options.FieldName The name of the associated SDO field this SmartDataField maps to. This is usually 'set' from the containing SmartDataViewer.
      * @param {boolean} options.ENABLED WidgetAttributes Enabled
      * @param {boolean} options.CreateField
      * @param {string} options.EventLeave Event when leaving a field
      * @param {string} options.SUBTYPE
      * @param {boolean} options.MULTIPLE
      * @param {string} options.Width A widget's width. The unit of measurement is determined by another&#10;parameter.
      * @param {number} options.COLUMN Column position. This may currently be used when rendering some objects. There is no getColumns function, use getCol to retrieve the realized value from an object.
      * @param {string} options.EventUploadComplete
      * @param {boolean} options.uploadShowTitle
      * @param {boolean} options.uploadAutoStart
      * @param {boolean} options.uploadAutoRemove
      * @param {string} options.Template
      * @param {string} options.uploadValidFileTypes
      * @param {string} options.note field description displayed below the field
      * @param {string} options.uploadURL
      * @param {string} options.uploadServerCallbackObject
      * @param {string} options.uploadTargetDir
      * @param {string} options.EventUploadFileAdd
      * @param {string} options.EventClick Client side validation code (JavaScript)
      * @param {string} options.filter
      */
    ak_htmlcontent: function(options) {
      const defaults = { template: '' };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      this.oData = {};
      this.opt.id += dhtmlx.uid();
      this.registerDynObject = true;

      // get parent
      const oParent = this.parent;
      if (oParent) {

        oParent.prop.fields.push({
          type: 'htmlcontent',
          inputTop: parseInt(this.opt.top),
          inputLeft: parseInt(this.opt.left),
          inputWidth: parseInt(this.opt.width),
          label: this.opt.label,
          labelTop: parseInt(this.opt.top),
          labelLeft: oParent.labelLeft(this),
          name: this.opt.name,
          position: (this.opt.positionMode != undefined) ? this.opt.positionMode : oParent.opt.positionMode,
          className: 'w4-formField',
          userdata: { id: this.opt.id }
        });

        // append to elements in form
        if (this.parent.view == 'form')
          this.parent.elements.push(this);

        // oInput.akElm = this;
        $.extend(this, { security: {} });
      }
    }
  });

  // methods for imagebox
  $.ak_htmlcontent.prototype = {
    // finish construct **********
    finishConstruct: function() {
      // get field
      this.form = this.parent.dhx;

    },

    dataAvailable: function() {
      this.cursorChange();
    },

    cursorChange: function() {
      const oSelf = this;

      let oParent = this.parent;
      if (oParent.view != 'form')
        oParent = akioma.getForm(this);

      if (oParent.dataSource != undefined) {
        // load template context from dataSource, session data and
        // custom data
        oSelf.oData = {};
        const oItem = oParent.dataSource.dhx.item(oParent.dataSource.dhx.getCursor());

        let oTemplateDataInit = null;
        const cTemplateName = oSelf.opt.name;
        if (akioma.osiv && akioma.osiv[`${cTemplateName}_initialize`]) {
          const oDisplayLink = akioma.getForm(oSelf).dynObject.getLink('DISPLAY:TRG');

          if (oDisplayLink) {
            try {
              oTemplateDataInit = akioma.osiv[`${cTemplateName}_initialize`](akioma.getForm(oSelf).dynObject);
            } catch (e) {
              akioma.notification({ type: 'error', text: e.message });
              console.warn(e);
            }

          }
        }

        if (akioma.handlebars.handlers[`${cTemplateName}_initialize`])
          oTemplateDataInit = akioma.handlebars.handlers[`${cTemplateName}_initialize`](akioma.getForm(oSelf).dynObject);


        oSelf.oData = $.extend(oSelf.oData, oItem);
        oSelf.oData = $.extend(oSelf.oData, app.sessionData);

        if (oSelf.opt.customData != undefined) {
          try {
            $.extend(oSelf.oData, JSON.parse(oSelf.opt.customData));
          } catch (e) {
            akioma.log.error(`Could not load customData for:${oSelf.opt.name}`);
          }
        }


        oSelf.oData = oSelf._ObjKeysToLowerCase(oSelf.oData);

        if (oTemplateDataInit != null)
          $.extend(oSelf.oData, oTemplateDataInit);

        oSelf.setValue(oSelf.opt.template);
      }


    },
    _ObjKeysToLowerCase: function(obj) {
      const keys = Object.keys(obj);
      let n = keys.length;
      const newobj = {};
      while (n--) {
        const key = keys[n];
        newobj[key.toLowerCase()] = obj[key];
      }

      return newobj;
    },

    // get value *****************
    getValue: function() {
      const oForm = akioma.getForm(this);

      return oForm.getItemValue(this.opt.name);
    },

    // set value ********************
    setValue: function(cValue) {
      const oForm = akioma.getForm(this);
      oForm.dhx.setItemValue(this.opt.name, cValue);
    }
  };

})(jQuery, jQuery);
