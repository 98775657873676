//
// Gauge Control

(function($) {

  $.extend({
    /**
     * SwatGauge Control
     * @class ak_gauge
     * @tutorial gauge-desc
     * @param {Object} options Repository attributes for SwatGauge.
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     * @param {string} options.titleHeader specifies which panelHeader to use. when empty, then uses the header of its own panel. if "none" then no header at all. if "parent" it uses the header of the parent panel
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {string} options.panelMenu comma separated list of menu-structures which will be shown as panelHeader-Buttons. </br>
     * Can also contain the flag #NoDropDown that specifies that the menu should not be loaded as a dropdown but each menu item should be added in the panel-Header. </br>
     * For example: </br>
     * <code>menuStructSave,menuSettings#NoDropDown,menuLookup</code> </br>
     * The buttons support font icons with the following attributes: </br>
     * 1. Css attributes, defined like this: fa fa-user#color:red </br>
     * 2. Css classes, defined like this: fa fa-user#_style:module_prod
     * 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod </br>
     * @param {string} options.DisplayedFields A comma-separated list of the columns displayed by the visualization object.
     * @param {string} options.contextMenu the id of a menuStructure which will be used as a context-menu
     * @param {string} options.GaugeType The Type of Gauge: either linear or radial
     * @param {string} options.minValue Minimum Value
     * @param {string} options.maxValue The maximum Value
     * @param {string} options.Ranges
     * @param {string} options.pointedColors
     * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
     * @param {string} options.floatingActionButton the id of a menustructure, which will be rendered as a FAB
     * @param {number} options.majorUnit
     */
    ak_gauge: function(options) {
      const oSelf = this,
        defaults = {
          type: 'radial',
          name: 'testgauge',
          minorUnit: 5, // minor unit
          majorUnit: 10, // major unit
          startAngle: -30, // start angle for radial gauge
          endAngle: 210, // end angle for radial gauge
          max: 100,
          min: 0,
          pointerSizes: '0.15,0.1', // this will supply pointer sizes
          pointerColors: 'red,green,orange,blue', // this will supply colors for pointers, in fieldvalues order (word or hex value)
          filterValues: '', // this will supply the values of the gauge
          ranges: '' // this will supply the gauge ranges (eg. 10,30,blue;70,100,gray )
        };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;

      this.useParentDynObjectLink = true;
      this.registerDynObject = true;

      // attach new kendoui element to parent
      const oParent = oSelf.parent;
      if (oParent) {
        if (!this.opt.customStyle)
          this.opt.customStyle = this.view;

        const cID = this.opt.id;
        const cGaugeHTML = `<div class="gauge"><div class="akgauge" id="container${cID}" akstyle="${this.opt.customStyle}"><div id="${cID}"></div></div></div>`;
        oParent.dhx.attachHTMLString(cGaugeHTML);
        const $gaugeCont = $(`#container${cID}`);
        $gaugeCont.append(cGaugeHTML);
        $gaugeCont.css({
          zIndex: 9999,
          position: 'absolute',
          top: '50%',
          left: '50%'
        });

        // get array of pointer fields
        oSelf.aPointers = oSelf.opt.filterValues.split(',');
        // get array of pointer colors
        oSelf.aPointersColors = oSelf.opt.pointerColors.split(',');
        // get array of pointer sizes
        oSelf.aPointersSizes = oSelf.opt.pointerSizes.split(',');
        // get array of ranges
        oSelf.aRanges = oSelf.opt.ranges.split(';');

        // set title in panel
        oSelf.opt.title = akioma.tran(`${this.opt.name}._title`, { defaultValue: oSelf.opt.title });
      }
    }
  });

  // methods for gauge
  $.ak_gauge.prototype = {
    // finish construct
    finishConstruct: function() {
      const oSelf = this,
        cID = this.opt.id,
        $gauge = $(`#${cID}`);

      const aPointers = [],
        aRanges = [];

      // set title and panelMenu buttons in panel header
      akioma.setPanelHeader(oSelf);

      // initialize ranges

      for (let r = 0; r < oSelf.aRanges.length; r++) {
        const aRangeVals = oSelf.aRanges[r].split(',');
        aRanges.push({
          from: Number(aRangeVals[0]),
          to: Number(aRangeVals[1]),
          color: aRangeVals[2]
        });
      }

      // initialize pointers from filtervalues

      for (let i = 0; i < oSelf.aPointers.length; i++) {
        const iVal = 0,
          iColors = oSelf.aPointersColors[i],
          iSize = oSelf.aPointersSizes[i];

        let iMargin;
        if (i > 0 && oSelf.opt.type == 'linear')
          iMargin = i * 5;
        else
          iMargin = 0;

        aPointers.push({
          value: Number(iVal),
          color: iColors,
          margin: Number(iMargin)
        });

        if (iSize)
          aPointers[i].cap = { size: Number(iSize) };
      }


      const oScale = {
        minorUnit: Number(oSelf.opt.minorUnit),
        majorUnit: Number(oSelf.opt.majorUnit),
        startAngle: Number(oSelf.opt.startAngle),
        endAngle: Number(oSelf.opt.endAngle),
        max: Number(oSelf.opt.max),
        min: Number(oSelf.opt.min),
        ranges: aRanges
      };

      // create radial
      const oSettings = {
        pointer: aPointers,
        scale: oScale
      };

      // check type and init gauge
      if (this.opt.type == 'linear') {
        $gauge.kendoLinearGauge(oSettings);
        oSelf.dhx = $gauge.data('kendoLinearGauge');
      } else {
        $gauge.kendoRadialGauge(oSettings);
        oSelf.dhx = $gauge.data('kendoRadialGauge');
      }

      // set akId
      this.akId = ((this.opt.akId) ? this.opt.akId : this.opt.name);

      // set floatingActionButton in panel
      if (this.opt.floatingActionButton) {
        const oFloatingActionTarget = this;
        this.parent.setOption('floatingActionButton', this.opt.floatingActionButton, oFloatingActionTarget);
      }
    },

    dataAvailable: function() {
      const oSelf = this;
      const cObjName = this.getObjName('DATA', 'TRG');
      const oSource = app.messenger.getObjects(this.dynObject, `DATA:SRC:${cObjName}`);
      const oSelected = oSource[0].controller.dhx.data.item(oSource[0].controller.dhx.getCursor());
      // update selected cursor pointer values
      for (let i = 0; i < oSelf.aPointers.length; i++) {
        const iVal = oSelected[oSelf.aPointers[i].toLowerCase()];
        oSelf.dhx.pointers[i].value(iVal);
      }

    },

    cursorChange: function() {
      const oSelf = this;
      const oSelected = oSelf.dataSource.dhx.item(oSelf.dataSource.dhx.getCursor());
      // update selected cursor pointer values
      for (let i = 0; i < oSelf.aPointers.length; i++) {
        const iVal = oSelected[oSelf.aPointers[i].toLowerCase()];
        oSelf.dhx.pointers[i].value(iVal);
      }
    },


    // destroy
    destroy: function() {
      const cID = this.opt.id,
        $gauge = $(`#${cID}`),
        $gaugeCont = $(`#container${cID}`);

      kendo.destroy($gauge);
      $gaugeCont.remove();
    }

  };
})(jQuery, jQuery);
