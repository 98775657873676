CKEDITOR.config.resize_enabled = false;


dhtmlXForm.prototype.items.richtext = {
  render: function(item, data) {

    item._type = 'ta';
    item._enabled = true;
    const oSelf = $.getObjectByName({ id: data.userdata.id });
    item.akElm = oSelf;
    oSelf.dhx = item;
    item.parentNode.className = `${item.parentNode.className} form_base_for_editor `;
    item.className = `${item.className} ak_richtext`;

    this.doAddLabel(item, data);
    this.doAddInput(item, data, ('TEXTAREA'), (null), true, true, 'dhxlist_txt_textarea');
    this.doAttachEvents(item);

    oSelf.oInitialOptions = {};
    if (app.sessionData.ckEditorCustomConfig)
      app.controller.callAkiomaCode(oSelf, app.sessionData.ckEditorCustomConfig);
    else {


      const oInitialOptions = {
        templates_replaceContent: false,
        removePlugins: 'image,xdsoft_translater,floating-tools'
      };
      const cConfig = '/config/akStandard.js';
      const cLang = akioma.entry(1, akioma.translation.getLanguage(), '-');
      let cToolbarName = (oSelf.opt.simpleMode) ? 'Simple' : null;

      if (oSelf.opt.editorToolbar)
        cToolbarName = oSelf.opt.editorToolbar;

      if (oSelf.security.readOnly) {
        cToolbarName = 'ReadOnly';
        oInitialOptions.allowedContent = true;
      }

      if (!isNull(cToolbarName))
        oInitialOptions.toolbar = cToolbarName;


      oInitialOptions.language = cLang;
      oInitialOptions.customConfig = cConfig;
      oInitialOptions.filebrowserImageBrowseUrl = 'dummyUrl_handled_dynamically';

      oInitialOptions.extraAllowedContent = 'td th[width,height]; img[src,alt,width,height]';
      oSelf.oInitialOptions = oInitialOptions;

    }

    if (!oSelf.oInitialOptions.on) {
      oSelf.oInitialOptions.on = {
        instanceReady: function() {
          this.dataProcessor.writer.setRules('p', {
            indent: false,
            breakBeforeOpen: true,
            breakAfterOpen: false,
            breakBeforeClose: false,
            breakAfterClose: false
          });
        }
      };
    }

    // set akstyle in ittext
    $(oSelf.dhx.children[0]).attr('akstyle', oSelf.opt.customStyle);
    const def = new jQuery.Deferred;
    oSelf.form.aRichtextPromises[oSelf.opt.name] = def;
    oSelf.oPromise = def;

    const textarea = $(item).find('textarea');
    textarea.ckeditor(() => {
      oSelf.finishEditor();
    }, oSelf.oInitialOptions);

    return this;
  },

  setValue: function(item, value) {
    item.akElm.setValue(value);
  },

  getValue: function(item) {
    return item.akElm.getValue();
  },

  disable: function(item) {
    if (String(item.className).search('disabled') < 0) item.className += ' disabled';
    item.akElm.editorLoaded.then(() => {
      item.akElm.oCkEditor.setReadOnly();
    });
  },

  enable: function(item) {
    if (String(item.className).search('disabled') >= 0) item.className = String(item.className).replace(/disabled/gi, '');
    item.akElm.editorLoaded.then(() => {
      item.akElm.oCkEditor.setReadOnly(false);
    });
  },

  setWidth: function(item, value) {
    try {
      const oText = $(item).find('textarea').ckeditorGet();
      if (typeof value != 'number')
        oText.resize('100%', '100%');
      else
        oText.resize(value, '100%');
    } catch (e) {
      !_isIE && console.error([ 'Error setting width', e, item, value ]);
    }
  },

  getRichtext: function(item) {
    return item;
  }
};
(function() {
  for (const a in { doAttachEvents: 1, doAddLabel: 1, doAddInput: 1, destruct: 1, doUnloadNestedLists: 1, setText: 1, getText: 1 })
    dhtmlXForm.prototype.items.richtext[a] = dhtmlXForm.prototype.items.input[a];
})();
dhtmlXForm.prototype.getRichtext = function(name) {
  return this.doWithItem(name, 'getRichtext');
};
dhtmlXForm.prototype.setFormData_richtext = function(name, value) {
  return this.doWithItem(name, 'setValue', value);
};
dhtmlXForm.prototype.getFormData_richtext = function(name) {
  return this.doWithItem(name, 'getValue');
};

(function($) {

  // ********************* lookup ********************
  $.extend({
    /**
 * SwatRichText Control
 * @class ak_richtext
 * @param {string} options.LABEL Sets the ckEditor label
 * @param {integer} options.WIDTH-CHARS Sets the CkEditor width. If not set, will be calculated automatically to fill the form.
 * @param {integer} options.HEIGHT-CHARS Sets the CkEditor height. If not set, will be calculated automatically to fill the form.
 * @param {string} options.textToolbar Toolbar definition for CkEditor
 * @param {boolean} options.ENABLED Sets the ckEditor as enabled or disabled
 * @param {string} options.fieldMapping Form BusinessEntity fields that will be set on saving. The field defined in 'noFormatFieldName' will be set with the non-formatted (ASCII) value.
   For example: </br>
 * <code>{"noFormatFieldName": "someFieldname"}</code>
 */

    ak_richtext: function(options) {

      akioma.BaseFormDataField.call(this, options);

      const defaults = { rowHeight: 23 };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;
      this.val = {};
      this._panelEvents = [];
      this._windowEvents = [];

      this.registerDynObject = true;
      this.registerVuexWatcher = true;

      this.editorLoaded = new Promise((resolve, reject) => {
        this.editorPromiseResolve = resolve;
        this.editorPromiseReject = reject;
      });

      // set title in panel (text sitzt in einem Form, deshalb 2x parent... */
      if (!this.opt.title)
        this.opt.title = 'Texteditor';

      this.form = akioma.getForm(this);
      this.opt.title = akioma.tran(`${akioma.getForm(this).opt.name}._title`, { defaultValue: this.opt.title });

      // get parent
      const oParent = this.parent;


      if (oParent) {

        if (!this.opt.customStyle)
          this.opt.customStyle = this.view;

        oParent.prop.fields.push({
          type: 'richtext',
          name: this.opt.name,
          rows: 20,
          label: this.opt.label,
          required: this.opt.required,
          mandatory: this.opt.required,
          enabled: !this.opt.disabled,
          disabled: this.opt.disabled,
          errorMsg: '',
          hasError: false,
          userdata: { id: this.opt.id }
        });

        // append to elements in form
        if (this.parent.view == 'form')
          this.parent.elements.push(this);
      }
    }
  });

  // methods for form
  Object.assign($.ak_richtext.prototype, akioma.BaseFormDataField.prototype, {
    componentOptions: function() {
      const oSelf = this;
      return {
        watch: {
          'getters.getFormFieldState': {
            fn: function(newValue, oldValue) {
              oSelf._hasFormFieldChangesWatcher(newValue, oldValue);
            },
            params: [this.opt.id]
          },
          'getters.getCustomStates': {
            fn: function(customStates) {
              oSelf._customStatesWatcher(customStates);
            },
            params: [this.opt.id],
            watchOptions: { deep: true }
          },
          'getters.getFormFieldEnabled': {
            fn: function(bEnabled) {
              oSelf._enabledFormFieldWatcher(bEnabled);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldMandatory': {
            fn: function(bMandatory) {
              oSelf._mandatoryFormFieldWatcher(bMandatory);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldError': {
            fn: function(newValue, oldValue) {
              oSelf._errorFormFieldWatcher(newValue, oldValue);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldErrorMsg': {
            fn: function(cErrorMsg) {
              oSelf._errorFormFieldMsgWatcher(cErrorMsg);
            },
            params: [this.opt.id]
          }
        }
      };
    },
    _enabledFormFieldWatcher: function(bEnabled) {
      const oSelf = this;
      const oForm = akioma.getForm(oSelf);
      const cName = oSelf.opt.name;

      if (oForm.dhx) {
        if (bEnabled)
          oForm.dhx.enableItem(cName);
        else
          oForm.dhx.disableItem(cName);

      }

    },
    _mandatoryFormFieldWatcher: function(bMandatory) {
      const oSelf = this;
      const oForm = akioma.getForm(oSelf);
      const cName = oSelf.opt.name;

      if (oForm && oForm.dhx) {
        if (typeof (oSelf.setRequired) == 'function')
          oSelf.setRequired(bMandatory);

        oForm.dhx.setRequired(cName, bMandatory);
      }

    },
    /**
     * Watcher for the form error message
     */
    _errorFormFieldMsgWatcher: function(newValue) {
      const oSelf = this;
      const cName = oSelf.opt.name;
      const oForm = akioma.getForm(oSelf);
      const oFormField = akioma.getDhxFormElement(oForm.dhx, cName);

      // setup error message under input
      if (oFormField) {
        const oFormCtrl = $(oFormField).find('> .dhxform_control');
        if (oFormCtrl.find('.validation-error-smartmessage').length == 0)
          oFormCtrl.append(`<span class="validation-error-smartmessage">${newValue}</span>`);
        else
          oFormCtrl.find('.validation-error-smartmessage').text(newValue);
      }

    },
    // finish construct **********
    finishConstruct: function() {
      // get field
      this.form = this.form.dhx;
      this.cActIndex = '-';

      const richTextNode = akioma.getDhxFormElement(this.form, this.opt.name);
      this.$domElement = $(richTextNode);
      this.setResponsiveSizes();

    },

    /**
     * Sets custom configuration for richtext
     * @param  {Object} oConfig Object which specifies the richtext configuration
     * @instance
     * @memberOf ak_richtext
     */
    setConfig: function(oConfig) {
      const oSelf = this;
      oSelf.oInitialOptions = oConfig;
    },

    /**
     * Sets richtext as anonymised (blurred data)
     * @instance
     * @memberOf ak_richtext
     */
    setAnonymised: function() {
      const oSelf = this;
      oSelf.bAnonymised = true;
      if (oSelf.oCkEditor)
        $(oSelf.oCkEditor.ui.contentsElement.$).addClass('anonymisedClass');
    },

    // finish editor ***************
    finishEditor: function() {
      // get size from parent
      const oSelf = this;
      const oPanel = this.getAncestor('panel').dhx;
      if (oPanel) {
        let oText;

        try {
          oText = $(this.form.getRichtext(this.opt.name)).find('textarea').ckeditorGet();

          oText.on('blur', () => {
            $(oSelf.dhx).removeClass('focusedinp');
          });

          oText.on('focus', () => {
            oSelf._old_value = oText.getData();
            $(oSelf.dhx).addClass('active').addClass('focusedinp');
          });

          oText.on('change', () => {
            const new_value = oText.getData();
            // if value has changed since focused then there are changes and dispatch action
            if (oSelf._old_value != undefined && new_value !== oSelf._old_value) {
              const oForm = oSelf.getAncestor('form');
              oForm.setFieldHasChanges(oSelf.opt.name, true);
            }
          });

          this.dynObject.parent.ckEdit = oText;
          this.oCkEditor = oText;
          oText.akElm = this;

          const ckEditorContent = $(oText.ui.contentsElement.$).children().first();

          if (oText.config.font_defaultLabel)
            ckEditorContent.css('font-family', oText.config.font_defaultLabel);
          if (oText.config.fontSize_defaultLabel)
            ckEditorContent.css('font-size', `${oText.config.fontSize_defaultLabel}pt`);

          // Add form base padding
          const oPadding = parseInt($(oSelf.dhx).parents('.form_base_for_editor').first().css('padding-right'));

          let iDiffWidth = 45 + oPadding;
          const iDiffHeight = 10;
          const iTopHeight = $(oText.ui.contentsElement.$.previousSibling).height();
          const iBottomHeight = $(oText.ui.contentsElement.$.nextSibling).height();

          // Add fieldset padding
          if (oSelf.parent.view == 'fieldset')
            iDiffWidth = iDiffWidth + parseInt($(oSelf.parent.dhx).css('padding-left')) + parseInt($(oSelf.parent.dhx).css('padding-right')) + 20;


          oSelf.iHeight = (oSelf.opt.rows) ? oSelf.opt.rows * oSelf.opt.rowHeight + iTopHeight + iBottomHeight : oPanel.getHeight() - ($(oText.container.$).offset().top - $(oPanel.cell).offset().top) - iDiffHeight;

          // auto adjust richtext size if smaller than container
          if (!isNull(oSelf.opt['WIDTH-CHARS']) && oSelf.opt['WIDTH-CHARS'] === 0) {
            oSelf.opt.width = false;
            oSelf.iWidth = this.$domElement.width() - 20;
          }

          oText.resize(oSelf.iWidth, oSelf.iHeight);

          const oPanelset = this.getAncestor('panelset');

          const windowControl = this.dynObject.container.controller;

          const getCkEditorHeight = () => (oSelf.opt.rows) ? oSelf.iHeight : oPanel.getHeight() - ($(oText.container.$).offset().top - $(oPanel.cell).offset().top) - iDiffHeight;

          if (!isNull(windowControl)) {
            const windowIdResizeEv = windowControl.dhx.attachEvent('onResizeFinish', () => {
              const iWidth = (oSelf.opt.width) ? oSelf.iWidth : oPanel.getWidth() - iDiffWidth;
              let iHeight = getCkEditorHeight();
              oText.resize(iWidth, iHeight);

              iHeight = getCkEditorHeight();
              oText.resize(iWidth, iHeight);
            });
            this._windowEvents.push(windowIdResizeEv);
          }


          if (oPanelset) {
            const panelsetIdResizeEv = oPanelset.dhx.attachEvent('onResizeFinish', () => {
              const iWidth = (oSelf.opt.width) ? oSelf.iWidth : oPanel.getWidth() - iDiffWidth;
              let iHeight = getCkEditorHeight();
              oText.resize(iWidth, iHeight);

              iHeight = getCkEditorHeight();
              oText.resize(iWidth, iHeight);

            });
            const panelsetIdResizeFinishEv = oPanelset.dhx.attachEvent('onPanelResizeFinish', () => {
              const iWidth = (oSelf.opt.width) ? oSelf.iWidth : oPanel.getWidth() - iDiffWidth;
              let iHeight = getCkEditorHeight();
              oText.resize(iWidth, iHeight);

              iHeight = getCkEditorHeight();
              oText.resize(iWidth, iHeight);

            });

            this._panelEvents.push(panelsetIdResizeEv);
            this._panelEvents.push(panelsetIdResizeFinishEv);
          }


          if (this.bAnonymised) {
            this.setAnonymised();
            this.bAnonymised = false;
          }

          if (this.opt.disabled)
            this.form.akElm.disableFormField(this.opt.name);

          const oPromise = oSelf.form.akElm.aRichtextPromises[oSelf.opt.name];
          oPromise.resolve(arguments);
        } catch (e) {
          akioma.notification({
            text: `Error on finishEditor richtext control${this.opt.name}`,
            expire: 2000,
            type: 'error'
          });
          console.error(e);
        }
        this.editorPromiseResolve();
      } else
        akioma.log.error([ 'Textedit: no valid panel', this ]);
    },

    /**
     * This Method returns the formatted (HTML) or non-formatted value (ASCII) of the CkEditor
     * @param  {String} cType If not specified, it will return the formatted value. If set to 'ascii' it will return the non-formatted value.
     * @return {String} The formatted (HTML) or non-formatted value (ASCII) of the CkEditor
     * @instance
     * @memberOf ak_richtext
    */
    getValue: function(cType) {
      const oCkTextArea = $(this.form.getRichtext(this.opt.name)).find('textarea');
      if (this.opt.fieldMapping && this.opt.fieldMapping.noFormatFieldName) {
        const newVal = oCkTextArea.ckeditorGet().getData();
        this.val.cNonFormatted = $(`<div>${newVal}</div>`).text();
        const cNonFormatted = this.val.cNonFormatted;

        this.form.akElm.dataSource.setFieldValue({
          name: this.opt.fieldMapping.noFormatFieldName,
          value: cNonFormatted
        });
      }

      if (cType == 'ascii')
        return this.val.cNonFormatted;
      else {
        this.val.cFormatted = oCkTextArea.ckeditorGet().getData();
        if (akioma.sessionContext && akioma.sessionContext.eSwatSessionContext.ckEditorImageSource)
          this.val.cFormatted = this.val.cFormatted.replace(new RegExp(`(<img[^]+?src=")(${akioma.sessionContext.eSwatSessionContext.ckEditorImageSource})`, 'gi'), '$1');
        return this.val.cFormatted;
      }


    },

    /**
     * Sets the formatted (HTML) value of the ckEditor.
     * @param {Object|String} oValue DataSource record object or the new text value from ckEditor
     * @instance
     * @memberOf ak_richtext
    */
    setValue: function(value) {

      const oSelf = this;
      if (typeof (value) == 'object') {
        this.val.cFormatted = value[this.opt.name];
        if (akioma.sessionContext && akioma.sessionContext.eSwatSessionContext.ckEditorImageSource)
          this.val.cFormatted = (!isNull(this.val.cFormatted)) ? this.val.cFormatted.replace(/(<img[^]+?src=")(?!http:\/\/)(.*?)"/gi, `$1${akioma.sessionContext.eSwatSessionContext.ckEditorImageSource}$2"`) : '';
        this.val.cNonFormatted = (this.opt.fieldMapping && this.opt.fieldMapping.noFormatFieldName) ? value[this.opt.fieldMapping.noFormatFieldName] : '';
      } else {
        this.val.cFormatted = value;
        this.val.cNonFormatted = $(`<div>${value}</div>`).text();
      }

      oSelf.oPromise.then(() => {
        oSelf.oCkEditor.setData(oSelf.val.cFormatted);
      });

      $(oSelf.dhx).addClass('active');

    },

    // called when cursor has been changed *********
    cursorChange: function() {},

    // destroy ****************
    destroy: function() {

      try {
        // get editor
        const oSelf = this,
          oText = this.form.getRichtext(this.opt.name),
          oCK = $(oText).find('textarea').ckeditorGet();

        // delete instance
        if (oCK) {
          CKEDITOR.remove(oCK);
          delete oSelf.oCkEditor;
        }

        const oPanelset = this.getAncestor('panelset');
        const windowControl = this.dynObject.screen.controller;

        if (oPanelset) {
          for (const i in this._panelEvents) {
            const eventId = this._panelEvents[i];
            oPanelset.dhx.detachEvent(eventId);
          }
        }

        if (windowControl) {
          for (const i in this._windowEvents) {
            const eventId = this._windowEvents[i];
            windowControl.dhx.detachEvent(eventId);
          }
        }

      } catch (e) {
        akioma.log.error([ 'Error destroying itText', e, this ]);
      }

    }

  });

})(jQuery, jQuery);
