/**
 * Setting defaults for all sweetalerts/Modal windows
 */
swalOriginal.setDefaults({
  closeOnEsc: true,
  closeOnClickOutside: false
});

/**
 * Messages queue for showing each message one after another.
 *
 * @private
 *
 * @type {akioma.AkQueue}
 */
const messageQueue = new akioma.AkQueue();

/**
 * Flag if a message is currently displayed.
 *
 * @private
 *
 * @type {boolean}
 */
let messageDisplayed = false;

/**
 *
 * Display swal message.
 * <br> Note: All queued messages will be displayed in order until queue is empty.
 *
 * @private
 *
 * @param {Object} par
 */
const displayMessage = function(par) {
  messageDisplayed = true;

  const message = akioma.ModalMessage.message(par);

  message.finally(() => {
    messageDisplayed = false;

    if (messageQueue.length > 0) {
      const messageParameter = messageQueue.pop();

      displayMessage(messageParameter);
    }
  });

  return par.id;
};

/**
 * Global Akioma method for Modal messages
 * @namespace akioma
 * @param {Object} par
 * @returns {void}
 */
akioma.message = function(par) {

  if (!par.id)
    par.id = uuid();

  if (messageDisplayed) {
    messageQueue.push(par);

    return par.id;
  } else
    return displayMessage(par);
};

// app namespace should not be used, but just for safety we keep it for a bit longer. . 20.09.2019 - Sebastian
app.message = akioma.message;
