akioma.version = 
{
  "Major": 22,
  "Minor": 4,
  "Patch": 0,
  "PreReleaseTag": "beta.1",
  "PreReleaseTagWithDash": "-beta.1",
  "PreReleaseLabel": "beta",
  "PreReleaseLabelWithDash": "-beta",
  "PreReleaseNumber": 1,
  "WeightedPreReleaseNumber": 1,
  "BuildMetaData": 126,
  "BuildMetaDataPadded": "0126",
  "FullBuildMetaData": "126.Branch.beta.Sha.182ee0bf6a2113453d2c09c5f16deecbbec7dc65",
  "MajorMinorPatch": "22.4.0",
  "SemVer": "22.4.0-beta.1",
  "LegacySemVer": "22.4.0-beta1",
  "LegacySemVerPadded": "22.4.0-beta0001",
  "AssemblySemVer": "22.4.0.0",
  "AssemblySemFileVer": "22.4.0.0",
  "FullSemVer": "22.4.0-beta.1+126",
  "InformationalVersion": "22.4.0-beta.1+126.Branch.beta.Sha.182ee0bf6a2113453d2c09c5f16deecbbec7dc65",
  "BranchName": "beta",
  "EscapedBranchName": "beta",
  "Sha": "182ee0bf6a2113453d2c09c5f16deecbbec7dc65",
  "ShortSha": "182ee0b",
  "NuGetVersionV2": "22.4.0-beta0001",
  "NuGetVersion": "22.4.0-beta0001",
  "NuGetPreReleaseTagV2": "beta0001",
  "NuGetPreReleaseTag": "beta0001",
  "VersionSourceSha": "65f3abe0132b74a78e65453486ff02659439b441",
  "CommitsSinceVersionSource": 126,
  "CommitsSinceVersionSourcePadded": "0126",
  "UncommittedChanges": 1,
  "CommitDate": "2022-05-27"
}
