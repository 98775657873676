/**
 * Base Class for BE based Objects
 * @class BaseDataSource
 *
 */
akioma.BaseDataSource = class {
  constructor(options) {
    const defaults = { page: 0 };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    this.observers = new Array();
    this.aCallback = [];

    this.registerVuexModule = true;
    this.registerDynObject = true;
    this.isDataSourceObject = true;

    this.init = false;

    this.aNonSortableFields = [];
    this.aNonFilterableFields = [];

    this.prop = {
      update: false,
      bind: false
    };
    this.serverProp = { srvrProp: [] };

    this.batchSize = {};
    this._pendingrequest = false;
    this.aErrRowsSmartMessages = [];

    this.aAfterFillOnce = [];

    this._eventAfterPendingRequests = [];
    this._eventAfterSaveChanges = [];
    this._aFireAfterSaveCallbacks = [];

    this.aTempSmartMessages = [];
    this.bInitialFetchedReq = false;

    this.oQueryParam = null;
    this.resourceName = this.opt.resourceName;

    this.entityName = this.opt.entityName;
    this.serviceURI = /* "https://" + app.sessionData.serverURL +*/ '/web';
    this.catalogURI = `${this.serviceURI}/Catalog/${this.resourceName}`;

    // set offset value for batching
    this.iCurrentBatchPos = 0;
    this.aContexts = [];
    this.urlQuery = {};

    this._eventAfterCatalogAdd = [];
    this._tableRefFilter = null;

    // fixes identifier casing
    if (this.opt.SUBTYPE !== 'ElasticSearchCollection')
      this.opt.identifier = this.opt.identifier ? this.opt.identifier.toLowerCase() : this.opt.identifier;

    // create logger on module DataSource
    this.log = akioma.log.getLogger('DataSource');
    this.log.setLevel('warn');
    this.tempStore = {};
    this.log.info(`Creating BusinessEntity ${this.opt.name}`);

    // create new filter object
    Object.defineProperty(this, 'query', {
      set(value) {
        this._query = value;
      },
      get() {
        return this._query;
      }
    });

    this.initializeQuery();

    // Deprecated property kept for backward compatibility. Use query insead.
    if (isNull(this.newfilter))
      this.newfilter = this.query;
  }

  hasCancelRequestCursor() {
    return (this.opt.SUBTYPE === '' || this.opt.SUBTYPE === undefined || this.opt.SUBTYPE.startsWith('BE')) && akioma.swat.canCancelRequest();
  }

  /**
   * Initialize the query.
   * @instance
   * @memberof BaseDataSource
   */
  initializeQuery() {
    this.query = new DataFilter({
      mainOperator: 'and',
      businessEntity: this
    });
  }

  /**
   * Initialize the JSDO.
   * @instance
   * @memberof BaseDataSource
   */
  initializeJSDO() {
    this.jsdo = new progress.data.JSDO({ name: this.resourceName });
    this.jsdo.restURI = '/web';
    this.jsdo.autoApplyChanges = false;
    this.jsdo.self = this;
  }

  /**
   * Returns the businessEntity Query f && ilter
   * @instance
   * @memberof BaseDataSource
   * @return {Object} The BE query filter object
   */
  getQuery() {
    return this.query;
  }

  /**
   * Gets the selected record from BusinessEntity2
   * @instance
   * @example
   * // get the selected record data for a businessEntity2 in a Form
   * // this could e.g. be placed inside an AfterDisplay Event of a Form
   * var oData = self.controller.dataSource.getSelectedRecord();
   * @memberof BaseDataSource
   * @return {object} Selected record
   */
  getSelectedRecord() {
    const oSelf = this;
    const oStore = oSelf.getStore(oSelf.entityName);
    const cursor = oStore.getCursor();

    return oStore.item(cursor);
  }

  /**
   * Decrements store changes and errors on linked objects.
   *
   * @memberof BaseDataSource
   */
  decrementStoreChangesAndErrors() {
    this._recursiveDecrementStoreState();
  }

  /**
   * Decrements store changes and errors recursively on this business entity and sub business entities.
   *
   * @private
   * @memberof BaseDataSource
   * @param {Array<BaseDataSource>} [visitedBEs=[]] Visited business entities.
   */
  _recursiveDecrementStoreState(visitedBEs = []) {
    if (visitedBEs.includes(this)) return;
    visitedBEs.push(this);

    try {
      for (const key in this.observers) {
        const oObserver = this.observers[key];
        if (oObserver.hasChangesControl)
          oObserver._dispatch('decrementHasChanges', 1);
        else if (oObserver.isGridObject) {
          if (oObserver && oObserver.oVuexState && oObserver.oVuexState.changedRows.length == 0)
            oObserver._dispatch('decrementHasChanges', 1);
        } if (oObserver.isDataSourceObject)
          oObserver._recursiveDecrementStoreState(visitedBEs);
      }
    } catch (e) {
      this.log.warn(e);
    }

    // remove also error state
    try {
      for (const key in this.observers) {
        const oControl = this.observers[key];
        if (oControl.view == 'form') {
          // remove dirty errors state
          if (oControl.oVuexState.attributes.errors > 0)
            oControl._dispatch('decrementHasErrors', 1);

        }
      }
    } catch (e) {
      this.log.warn(e);
    }
  }

  /**
   * Checks if any of the linked controls have changes.
   * @memberof BaseDataSource
   * @returns {boolean}
   */
  hasLinkedChanges() {
    return this._recursiveHasChanges();
  }

  /**
   * Prompt the user with 'hasChanges' dialog. Returns promise with prompt result. <br>
   * Notes: decrements store changes and errors if prompt result is 'yes'.
   * @memberof BaseDataSource
   * @returns {Promise<boolean|null>}
   */
  promptDiscardHasChangesDialog() {
    const deferred = $.Deferred();
    const oSelf = this;

    akioma.message({
      type: 'warning',
      title: akioma.tran('messageBox.title.warning', { defaultValue: 'Warning' }),
      text: akioma.tran('messageBox.text.changeCursor', { defaultValue: 'Changing the working record will remove all changes. Are you sure you want to continue?' }),
      buttonText: akioma.tran('messageBox.button.yes', { defaultValue: 'Yes' }),
      callback: function(result) {
        if (result)
          oSelf.decrementStoreChangesAndErrors();

        deferred.resolve(result);
      }
    });

    return deferred.promise();
  }

  /**
   * Gets the number of records from BusinessEntity
   * @instance
   * @return {integer} Number of records
   * @memberof BaseDataSource
   */
  getNumberofRecords() {
    const activeStore = this.getStore(this.opt.entityName);
    if (activeStore)
      return activeStore.dataCount();
    return 0;
  }

  /**
   * Method used for cancelling the current jsdo request (if any)
   * @instance
   * @memberof BaseDataSource
   * @returns {void}
   */
  cancelCurrentRequest() {
    const oSelf = this;
    if (oSelf.jsdo == undefined)
      return;
    else if (oSelf._pendingrequest)
      oSelf.jsdo.cancelCurrentRequest();
    else {
      const dataSourceLinks = oSelf.dynObject.getLinks('DATA:SRC') || [];
      const dataSourceLinked = dataSourceLinks.filter(targetObj => targetObj.type.startsWith('businessEntity'));
      if (dataSourceLinked) {
        for (const index in dataSourceLinked)
          if (dataSourceLinked[index].controller._pendingrequest) dataSourceLinked[index].controller.cancelCurrentRequest();
      }
    }
  }

  addAfterCatalogAdd(fn) {
    const oSelf = this;
    if (oSelf.jsdo == undefined)
      oSelf._eventAfterCatalogAdd.push(fn);
    else
      fn();
  }

  resolveFrom(oData, i, oParent) {
    this.continueFinConstr = {
      data: oData,
      index: i,
      parent: oParent
    };
  }

  /**
   * Method used for copy of record, will set SmartCopiedFrom in the GetInitialValues method params request.
   * @instance
   * @memberof BaseDataSource
   * @param {string} cSource SmartCopiedFrom foreignKey value that will be passed in the GetInitialValues method call
   * @returns {void}
   */
  copyRecord(cSource) {
    const oSelf = this;
    oSelf.addAfterCatalogAdd(() => {
      // add foreignKeys for copy record
      oSelf.setForeignKeys([
        {
          name: 'SmartCopiedFrom',
          value: `${cSource}`
        }
      ]);

      // add new record in store
      const cID = oSelf.getStore(oSelf.opt.entityName).add({ SmartCopiedFrom: cSource });

      oSelf.getStore(oSelf.opt.entityName).setCursor(cID);
    });
  }

  /**
   * Method used for calling a callback after a pending request,
   * if there is no pending request, the callback will be called
   * immediately
   * @memberof BaseDataSource
   * @instance
   * @param {function} callback
   */
  callAfterPendingRequest(callback) {
    if (!this._pendingrequest)
      callback();
    else
      this._eventAfterPendingRequests.push(callback);
  }

  /**
   * Method for adding an after save changes once callback event of the JSDO
   * @params {Function} Callback
   * @memberof BaseDataSource
   * @instance
   * @returns {string} uid
   */
  addAfterSaveChangesOnceCallback(fn) {
    const uid = dhtmlx.uid();
    this._eventAfterSaveChanges[uid] = fn;
    return uid;
  }

  /**
   * Method for cleaning all save changes once event of the JSDO
   * @params {Function} Callback
   * @memberof BaseDataSource
   * @instance
   * @returns {void}
   */
  cleanSaveChangesOnceEvts() {
    this._eventAfterSaveChanges = [];
  }

  /**
   * Method for adding an event after fill of the JSDO, executes only once
   * @params {Function} Callback
   * @memberof BaseDataSource
   * @instance
   * @returns {void}
   */
  addAfterFillOnceCallback(fn) {
    this.aAfterFillOnce.push(fn);
  }

  /**
   * Method to get id from selfhdl shortcut
   * @param  {string} cSelfHdl
   * @memberof BaseDataSource
   * @instance
   * @return {void}
   */
  getIdFromSelfHdl(cSelfHdl) {
    const items = this.dhx.data.pull;

    for (const i in items) {
      if (cSelfHdl == items[i].selfhdl)
        return i;
    }
  }

  /**
   * Method for setting a NamedQuery Param filter on the BusinessEntity
   * @param {string}  queryName
   * @param {string}  paramName
   * @param {any}     paramVal
   * @param {string}  paramType
   * @memberof BaseDataSource
   * @returns {void}
   */
  setNamedQueryParam(queryName, paramName, paramVal, paramType) {
    try {
      const oSelf = this;

      // init queryname if it wasn't already
      if (oSelf.oNamedQuery == undefined)
        oSelf.oNamedQuery = { name: queryName, parameters: [] };

      // select current query name
      const currentNameQuery = oSelf.oNamedQuery;

      // now set parameter
      if (paramType == undefined)
        paramType = 'char';

      // look if param already added and select
      const aCurParam = $.grep(currentNameQuery.parameters, e => e.name == paramName);
      const oParam = {
        'name': paramName,
        'type': paramType,
        'value': paramVal
      };

      if ('FetchByUniqueKey' == queryName) {
        oSelf.oNamedQuery = {
          name: queryName,
          parameters: [oParam]
        };
      } else if (aCurParam.length == 0) { // if the param doesn't exist already
        const oExistingNamedQueryParam = _.find(currentNameQuery.parameters, el => el.name === oParam.name && el.type === oParam.type);
        if (!oExistingNamedQueryParam)
          currentNameQuery.parameters.push(oParam);
        else {

          const iExistingIndex = currentNameQuery.parameters.indexOf(oExistingNamedQueryParam);

          currentNameQuery.parameters[iExistingIndex] = oParam;

        }
      } else { // if it exists then update values
        aCurParam[0].type = paramType;
        aCurParam[0].value = paramVal;
      }
    } catch (e) {
      this.log.error('There was an error setting the businessEntity Named Query: ', e); // check oselflog property/method
    }
  }

  _getFormFromObs(cField) {
    const oSelf = this;
    for (let i = 0; i < oSelf.observers.length; i++) {
      const oItem = oSelf.observers[i].dhx;
      if (oItem && oItem.isItem != undefined && oItem.isItem(cField) != null)
        return oItem;
    }
  }

  _getGridFromObs() {
    const oSelf = this;
    for (let i = 0; i < oSelf.observers.length; i++) {
      const oItem = oSelf.observers[i].dhx;
      if (oItem && oItem.akElm && oItem.akElm.view.startsWith('datagrid'))
        return oItem;
    }
  }

  callUiAttributes() {
    const oSelf = this;
    const oUiAttributes = oSelf.oUiActions.UiAttributes;

    if (oUiAttributes) {
      for (let i = 0; i < oUiAttributes.length; i++) {
        const oAttribute = oUiAttributes[i],
          cFieldName = oAttribute.FieldName.toLowerCase(),
          oForm = oSelf._getFormFromObs(cFieldName),
          oGrid = oSelf._getGridFromObs();

        if (oAttribute.Visible !== undefined && oAttribute.Visible !== null) {
          oSelf.log.info(`apply visible: ${oAttribute.Visible}`);
          if (oForm) {
            if (oAttribute.Visible)
              oForm.showItem(cFieldName);
            else
              oForm.hideItem(cFieldName);
          }
        }
        if (oAttribute.Enabled !== undefined && oAttribute.Enabled !== null) {
          oSelf.log.info(`apply enabled${oAttribute.Enabled}`);
          if (oForm) {
            if (oAttribute.Enabled)
              oForm.enableItem(cFieldName);
            else
              oForm.disableItem(cFieldName);
          }
        }
        if (oAttribute.IsAnonymized) {
          if (oGrid) {
            const oCell = oGrid.akElm.getCell(oSelf.recordIndex, cFieldName);
            if (oCell)
              $(oCell.cell).addClass('anonymizedCell');
          }

          if (oForm && oForm.akElm.bUiAttributes) {
            if (oAttribute.IsAnonymized)
              oForm.akElm.setAnonymisedControl(cFieldName);
          }
        }

        if (oAttribute.Styles) {
          if (oGrid) {
            const oCell = oGrid.akElm.getCell(oSelf.recordIndex, cFieldName);
            if (oCell)
              $(oCell.cell).addClass(oAttribute.Styles);
          }
        }
      }
    }
  }

  callUiActions(request) {
    const aReplys = [ 'ReplyYes', 'ReplyNo', 'ReplyOk', 'ReplyCancel' ];
    const oSelf = this;
    const aQuestionsUnAnswered = [];
    for (const q in oSelf.oUiActions.Questions) {
      const question = oSelf.oUiActions.Questions[q];
      if (question.MessageReply.toLowerCase() == 'unanswered')
        aQuestionsUnAnswered.push(question);
    }

    function askQuestion(i, request) {
      const iIndQuestion = i;
      function onSelect(cSel, i, request) {
        const iReplyIndex = aReplys.indexOf(cSel);

        switch (iReplyIndex) {
          case 0:
            oSelf.oUiActions.Questions[i].MessageReply = aReplys[0];
            break;
          case 1:
            oSelf.oUiActions.Questions[i].MessageReply = aReplys[1];
            break;
          case 2:
            oSelf.oUiActions.Questions[i].MessageReply = aReplys[2];
            break;
          case 3:
            oSelf.oUiActions.Questions[i].MessageReply = aReplys[3];
        }
        oSelf.oLastUiAction = JSON.parse(JSON.stringify(oSelf.oUiActions));

        if (oSelf.oUiActions.Questions[i].MessageReply !== 'ReplyCancel')
          askQuestion(i + 1, request);
        else {
          oSelf.akUiActions = '';
          oSelf._cleanupAnsweredQuestions();
        }

        oSelf.callAfterQuestionAnswered();
      }

      if (oSelf.oUiActions && oSelf.oUiActions.Questions && oSelf.oUiActions.Questions[i]) {
        const oCurrentQ = oSelf.oUiActions.Questions[i];
        const aAnswers = oCurrentQ.MessageButtons.split(/(?=[A-Z])/);

        // only show the question if unaswered
        if (oCurrentQ.MessageReply.toLowerCase() == 'unanswered') {
          // smartmessage in akuiActions
          if (oCurrentQ.MessageText.indexOf('SmartMessage') > -1) {
            const cErrorMsg = oCurrentQ.MessageText;
            const aErrorSplit = cErrorMsg.split(String.fromCharCode(3));
            const aDefferedObjs = [];
            const aFinalMsg = [];
            const cQuestionTitle = oCurrentQ.MessageTitle;

            for (const i in aErrorSplit) {
              const cCurrentErrorPart = aErrorSplit[i];
              if (cCurrentErrorPart.indexOf('SmartMessage') == 0) {
                let urlMessageRequest = '';
                const mySplitResult1 = cCurrentErrorPart.split('\t').splice(0, cCurrentErrorPart.split('\t').length - 1);
                const mySplitResult = mySplitResult1.concat(cCurrentErrorPart.split('\t') // split by \t in array
                  .splice(cCurrentErrorPart.split('\t').length - 1, 1)[0]
                  .split(String.fromCharCode(4))); // split by charCode 4

                if (mySplitResult.length >= 2)
                  urlMessageRequest = `${urlMessageRequest}/${mySplitResult[1]}/${mySplitResult[2]}`;

                const result = akioma.SmartMessage.loadSmartMessage(mySplitResult, urlMessageRequest);
                aDefferedObjs.push(result);
              } else
                aFinalMsg.push({ text: cCurrentErrorPart, modal: true });

              // after all deffered ended get complete message string
              $.when.all(aDefferedObjs).then(aFinalMsg => {
                oSelf._afterAllSmartMessagesLoaded(aFinalMsg, request, 'nonmodal', cSelected => {
                  onSelect(cSelected, Number(iIndQuestion), request);
                }, aAnswers, cQuestionTitle);
              });
            }
          } else {
            akioma.message({
              type: 'confirm',
              title: oCurrentQ.MessageTitle,
              text: oCurrentQ.MessageText,
              buttonText: 'Ok',
              callback: function(cSelected) {
                if (onSelect) {
                  cSelected = (cSelected == true) ? 0 : 1;
                  onSelect(cSelected, iIndQuestion, request);
                }
              }
            });
          }
        }
      } else if (aQuestionsUnAnswered.length > 0) {
        const oelm = oSelf.objectKeysToLowerCase(request.batch.operations[0].jsrecord.data);
        delete oelm[''];

        oelm.akUiActions = JSON.stringify(oSelf.oUiActions);
        oelm.akuiactions = oelm.akUiActions;
        oSelf.dhx.update(oSelf.dhx.getCursor(), oelm);
        let oRecord;
        if (oelm._id != undefined)
          oRecord = oSelf.jsdo[oSelf.entityName].findById(oelm._id);


        // reject row changes
        // check status of record
        // based on status perform operation
        const cRowState = oSelf.dc.getState(oelm._id);

        if (oRecord || oSelf.aErrRowsSmartMessages[oSelf.dhx.getCursor()]) {
          if (cRowState == 'updated')

            oSelf.jsdo[oSelf.entityName].assign(oelm);
          else if (cRowState == 'created')

            oSelf.jsdo[oSelf.entityName].add(oelm);
          else if (cRowState == 'deleted')
            oRecord.data.akUiActions = oelm.akUiActions;

        }
        oSelf.dc.sendData();
      }
    }

    this._cleanupAnsweredQuestions();

    for (const q in oSelf.oUiActions.Questions) {
      const question = oSelf.oUiActions.Questions[q];
      if (question.MessageReply.toLowerCase() == 'replycancel') {
        const oelm = oSelf.dhx.item(oSelf.dhx.getCursor());
        if (oelm.akuiactions !== undefined) {
          oelm.akuiactions = '';
          oelm.akUiActions = '';
          oSelf.jsdo[oSelf.opt.entityName].findById(oSelf.dhx.getCursor());
          const record = request.batch.operations[0].jsrecord;
          record.akUiActions = '';
        }
        break;
      }

      if (question.MessageReply.toLowerCase() == 'unanswered') {
        askQuestion(q, request);
        break;
      }
    }
  }

  _cleanupAnsweredQuestions() {
    const oSelf = this;
    let questionsUnanswered = 0;
    if (oSelf.oUiActions) {
      for (const q in oSelf.oUiActions.Questions) {
        const question = oSelf.oUiActions.Questions[q];
        if (question.MessageReply.toLowerCase() == 'unanswered') {
          questionsUnanswered++;
          break;
        }
      }
    }

    // clean up akUiActions because there are no other questions to answer
    if (questionsUnanswered == 0 && oSelf.dhx) {
      const iInx = oSelf.dhx.getCursor();
      if (oSelf.dhx.data.pull[iInx]) {
        oSelf.dhx.data.pull[iInx].akuiactions = '';
        oSelf.dhx.data.pull[iInx].akUiActions = '';
      }
    }
  }

  // show akUiActions.Notifications
  _showNotification(notification, request) {
    const oSelf = this;
    // smartmessage in akuiActions
    if (notification.Text.indexOf('SmartMessage') > -1) {
      const cNotificationMsg = notification.Text;
      const aNotificationSplit = cNotificationMsg.split(String.fromCharCode(3));
      const aDefferedObjs = [];
      const aFinalMsg = [];
      const bModal = akioma.swat.isDefaultMessageBoxStyleModal();

      for (const i in aNotificationSplit) {
        const cCurrentErrorPart = aNotificationSplit[i];
        if (cCurrentErrorPart.indexOf('SmartMessage') == 0) {
          const mySplitResult1 = cCurrentErrorPart.split('\t').splice(0, cCurrentErrorPart.split('\t').length - 1);
          const mySplitResult = mySplitResult1.concat(cCurrentErrorPart.split('\t').splice(cCurrentErrorPart.split('\t').length - 1, 1)[0].split(String.fromCharCode(4)));

          let urlMessageRequest = '';
          if (mySplitResult.length >= 2)
            urlMessageRequest = `${urlMessageRequest}/${mySplitResult[1]}/${mySplitResult[2]}`;


          // return final msg or promise for loading smartmessage
          const result = akioma.SmartMessage.loadSmartMessage(mySplitResult, urlMessageRequest);

          aDefferedObjs.push(result);
        } else
          aFinalMsg.push({ text: cCurrentErrorPart, modal: bModal });


        $.when.all(aDefferedObjs).then(aFinalMsg => {
          oSelf._afterAllSmartMessagesLoaded(aFinalMsg, request, 'nonmodal');
        });
      }
    } else {
      for (const oCurrentQ in oSelf.oUiActions.Questions) {
        akioma.message({
          type: 'confirm',
          text: oCurrentQ.Text,
          title: oCurrentQ.MessageTitle
        });
      }
    }
    // clean up akUiActions
    const oelm = oSelf.dhx.item(oSelf.dhx.getCursor());
    oelm.akuiactions = '';
    oelm.akUiActions = '';
    oSelf.dhx.update(oSelf.dhx.getCursor(), oelm);
  }

  cursorChange() {
    this.openQuery({});
  }

  // add observer ****************
  addObserver(observer) {
    // just add observer to list
    if ($.inArray(observer, this.observers) == -1) {
      this.observers.push(observer);

      // and set datasource
      observer.dataSource = this;
    }
  }

  // get url query
  getUrlQuery() {
    return this.urlQuery;
  }

  // get foreign key
  getForeignKey() {
    if (this.dataSource && this.opt.foreignKey) {
      const cRefHdl = this.dataSource.getIndex();
      if (cRefHdl) {
        return {
          name: this.opt.foreignKey,
          value: this.dataSource.getFieldValue(this.opt.extKey)
        };
      }
    }

    return {
      name: '',
      value: ''
    };
  }

  setQueryParam(oElm) {
    this.oQueryParam = oElm;
  }

  /**
   * Call the callback function for every grid observable
   * @memberof BaseDataSource
   * @instance
   * @param {array} observables
   * @param {function} callback
   */
  forEachGridObservable(observables, callback) {
    for (const o in observables) {
      const oObserver = observables[o];
      if (oObserver.view.indexOf('datagrid') == 0 || oObserver.view.indexOf('datagrid2') == 0)
        callback(oObserver);
    }
  }

  /**
   * Method for setting a table ref filter
   * @memberof BaseDataSource
   * @param {string} entityName Entity name
   */
  setTableRefFilter(entityName) {
    this._tableRefFilter = entityName;
  }

  /**
   * Get table ref filter of businessEntity
   * @memberof BaseDataSource
   * @instance
   * @returns {string}
   */
  getTableRefFilter() {
    return this._tableRefFilter || this.opt.tableRefFilter;
  }

  /**
   * Method for calling existing pending request listeners
   * @private
   * @instance
   * @memberof BaseDataSource
   */
  _callAfterPendingRequestListeners() {
    this._eventAfterPendingRequests.forEach(event => {
      event();
    });

    this._eventAfterPendingRequests = [];
  }

  _checkSortableAndFilterable(oSchema) {
    const oSelf = this;

    oSelf.aNonSortableFields = [];
    oSelf.aNonFilterableFields = [];

    for (let r = 0; r < oSchema.length; r++) {
      const fieldData = oSchema[r];
      const cFieldName = fieldData.name.toLowerCase();
      const cFieldNonSortable = fieldData.nonSortable || false; // defaults to sortable field
      const cFieldNonFilterable = fieldData.nonFilterable || false; // defaults to filterable field

      if (cFieldName != '_errorstring') {
        if (cFieldNonSortable)
          oSelf.aNonSortableFields.push(cFieldName);

        if (cFieldNonFilterable)
          oSelf.aNonFilterableFields.push(cFieldName);
      }
    }
  }

  // sets batch mode for businessEntity, skip = how many pages/ top = how many records
  setBatch(iSkip, iTop) {
    const oSelf = this;
    oSelf.batchSize = {
      skip: iSkip,
      top: iTop
    };
  }

  setLastIndex(iLastIndex) {
    const oSelf = this;
    oSelf.batchSize.lastIndex = iLastIndex;
  }

  objectKeysToLowerCase(obj) {
    const newObj = {};
    for (const i in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, i))
        newObj[i.toLocaleLowerCase()] = obj[i];
    }
    return newObj;
  }

  // relaod data from server
  reloadData() {
    this.openQuery({ bind: false });
  }

  /**
   * Method for adding a new empty record
   * @memberof BaseDataSource
   */
  addRecord() {
    const oSelf = this;
    // add empty row data
    const iIndex = oSelf.getStore(oSelf.opt.entityName).add({});

    // now find index
    if (iIndex)
      oSelf.getStore(oSelf.opt.entityName).setCursor(iIndex);
  }

  /**
   * Method for setting up an event listener when performing a save, will be called as long as there are SmartMessages
   * @param {function} fn Callback function to be called
   * @instance
   * @memberof BaseDataSource
   */
  fireAfterUpdate(fn) {
    const oSelf = this;
    oSelf._aFireAfterSaveCallbacks.push(fn);
  }

  // find data ***************
  findData(oElm) {
    const oData = this.dhx.data.pull;

    for (const i in oData) {
      if (oData[i][oElm.field] == oElm.value)
        return Number(i);
    }
    return false;
  }

  addCallback(event, fn) {
    this.aCallback[event] = fn;
  }

  // set changed ***************
  setChanged(lUpdate, cType) {
    const iCursor = this.getStore(this.entityName).getCursor();

    if (iCursor)
      this.getStoreConnector(this.entityName).setUpdated(iCursor, lUpdate, cType);
  }

  // undo record **************
  undoRecord() {
    this.opt.recMode = '';
    this.opt.recSibling = '';
    this.opt.recParent = '';
    this.opt.recType = '';
    this.data.undoRecord();
    this.resetRecord();
  }

  // reset record ************
  resetRecord() {
    const cObjectID = this.opt.name + this.opt.linkid;

    // tell all observers to reset record
    app.messenger.publish({
      link: {
        LinkName: 'DISPLAY',
        LinkType: 'TRG',
        ObjName: cObjectID
      },
      method: 'dataAvailable',
      caller: this
    });
  }

  // get field value ************
  getFieldValue(cFieldName, id) {
    let iAct;
    const activeStore = this.getStore(this.opt.entityName);

    if (id)
      iAct = id;
    else
      iAct = activeStore.getCursor();
    // check if field exists
    const oItem = activeStore.item(iAct);
    if (oItem)
      return oItem[cFieldName];

    return null;
  }

  // set field value ************
  setFieldValue(oElm) {
    const activeStore = this.getStore(this.opt.entityName);
    const activeConnector = this.getStoreConnector(this.opt.entityName);
    const id = (oElm.index ? oElm.index : activeStore.getCursor());
    let oItem;

    if (id) {
      oItem = activeStore.item(id);
      oItem[oElm.name] = oElm.value;

      if (oElm.state) {
        oItem.rowmod = oElm.state.substr(0, 1);
        activeConnector.setUpdated(id, true, oElm.state);
      }
    }
    return true;
  }

  // get indexes ************
  getIndex() {
    const activeStore = this.getStore(this.opt.entityName);
    const id = activeStore.getCursor();
    if (id)
      return activeStore.item(id)[this.opt.identifier];
    else
      return '';
  }

  // set filter ************
  _setFilter(oFilter, oControl) {
    let aFieldList = (this.urlQuery.filterFields) ? this.urlQuery.filterFields.split(',') : [];
    const oQuery = this.getQuery();
    // delete all filters before
    for (const i in aFieldList)
      delete this.urlQuery[aFieldList[i]];
    aFieldList = [];

    // go through array
    for (const cField in oFilter) {
      // fill filters
      if (oFilter[cField])
        aFieldList.push(cField);
    }
    try {
      // new filter format here
      oQuery.clearAll();
      for (const i in aFieldList) {
        let val = oFilter[aFieldList[i]];
        let operator = 'eq';

        if (val[0] && val[0].indexOf('[') == 0 && typeof (val) !== 'string')
          val = val[0];

        if (val.replace) {
          let cNewVal = val;
          const cColType = oControl.getColTypeByName(aFieldList[i]);

          if (cColType == 'character')
            operator = 'beginsmatches';
          else if (oControl)
            operator = oControl.getColDefaultOperator(aFieldList[i]);
          else
            operator = 'eq';

          if (oControl == undefined)
            akioma.notification({ type: 'error', text: 'control undefined!' });

          // remove asterisks if not beginsmatches
          if (operator != 'beginsmatches')
            cNewVal = val.replace(/\*/g, '');

          // means it is a dynselect filter object
          if (val.indexOf('[') == 0) {
            val = JSON.parse(val);

            for (const x in val) {
              const rec = val[x];
              cNewVal = rec.id;
              oQuery.setSubOperator(aFieldList[i], 'or');
              oQuery.addCondition(aFieldList[i], operator, cNewVal);
            }

          } else if (oControl.columns[aFieldList[i]].opt.filter == '#daterange_filter') {
            const aDate = cNewVal.split('_');

            if (aDate[0] && aDate[1]) oQuery.setSubOperator(aFieldList[i], 'and');
            if (aDate[0]) oQuery.addCondition(aFieldList[i], 'gte', aDate[0]);
            if (aDate[1]) oQuery.addCondition(aFieldList[i], 'lte', aDate[1]);

          } else {
            const aMultiFilter = cNewVal.split(';');
            if (cNewVal.length > 1) {
              for (const x in aMultiFilter)
                oQuery.addCondition(aFieldList[i], operator, aMultiFilter[x]);

            } else
              oQuery.addCondition(aFieldList[i], operator, cNewVal);

          }
        }
      }

      const oAkQueryFilters = oQuery.getFilter();

      if (oAkQueryFilters.length == 0 && oAkQueryFilters.length != undefined)
        this.urlQuery.akQuery = {};
      else {
        this.urlQuery.akQuery = {
          SDO: this.opt.SDO, // entity name
          ui_context: {
            controlName: this.controllerName, // control
            controlType: '', // grid etc
            container: '' // container name, window frame..
          },
          filters: oAkQueryFilters

        };
      }

    } catch (e) {
      this.log.warn(e);
    }
  }

  // after update of dp
  afterUpdate(sid, action, tid, oXML) {
    const oSelf = this;
    tid = Number(tid);

    if (!this.dhx)
      return false;

    // if message -> error occured
    if ($(oXML).attr('message')) {
      const cValue = $(oXML).text();

      akioma.showServerMessage(cValue);
      if (oSelf.dataSource && oSelf.dataSource.finishUpdate)
        oSelf.dataSource.finishUpdate(oSelf, false);

      return false;
    }

    // check if item is still available
    const oItem = this.dhx.item(tid);
    if (oItem) {
      // type
      let cChanged;
      if (action == 'inserted')
        cChanged = '*';
      else
        cChanged = $(oXML).find('record > changedfields').text().toLowerCase();

      // get record and update fields
      if (cChanged) {
        $(oXML)
          .find(`record > ${cChanged}`)
          .filter(() => ($.inArray(this.nodeName, [ 'changedfields', 'rownum', 'rowmod' ]) > -1) ? false : true)
          .each(() => {
            const cField = this.nodeName;
            const cValue = $(this).text();
            oItem[cField] = cValue;
          });
      }

      // reset rowmod
      oItem.rowmod = '';

      const cObjectID = oSelf.opt.name + oSelf.opt.linkid;

      // now check for translation field
      app.messenger.publish({
        link: {
          LinkName: 'DISPLAY',
          LinkType: 'TRG',
          ObjName: cObjectID
        },
        method: 'saveTranslat',
        caller: oSelf
      });

      // set new title in window
      oSelf.setTitle();
    }

    // check if we have to finish the update
    if (oSelf.dataSource && oSelf.dataSource.finishUpdate) {
      const cRef = $(oXML).find('record > refhdl').text();
      oSelf.dataSource.finishUpdate(oSelf, cRef);
    }

    return true;
  }

  getSrvProp(cName) {
    if (this.serverProp[cName])
      return this.serverProp[cName];
    else
      return '';
  }

  setSrvProp(cName, cValue) {

    if ($.inArray(cName, this.serverProp.srvrProp) == -1)
      this.serverProp.srvrProp.push(cName);

    if (cValue.substr(0, 1) == '$') {
      let oSelf, self, cCode;
      try {
        // get variable for dynObject
        oSelf = this;
        // required in the eval context
        // eslint-disable-next-line no-unused-vars
        self = oSelf.dynObject;
        cCode = cValue.substr(1);
        this.serverProp[cName] = eval(cCode);
      } catch (e) {
        this.log.error('Error executing akioma code', this, cCode, e);
        akioma.message({ type: 'alert-error', title: 'Error executing akioma code', text: `${cCode}<br />${e.message}` });
      }
    } else
      this.serverProp[cName] = cValue;
  }

  delSrvProp(cName) {
    if (this.serverProp[cName]) {
      delete this.serverProp[cName];
      $.removeEntry(this.serverProp.srvrProp, cName);
    }
  }

  endConstruct() {
    // check for grid
    for (const i in this.observers) {
      if (this.observers[i].view == 'datagrid') {
        this.controllerName = this.observers[i].opt.name;
        break;
      }
    }
  }

  applyFilter() {
    this.openQuery({});
  }

  // fetch **************
  fetch(oElm) {
    if (this.dhx.dataCount() < 1)
      return;

    const oSelf = this;
    const oData = this.dhx;
    const iNumRec = oData.dataCount();
    let iSelected = oData.getCursor();
    const iOldInd = iSelected;

    let cMethod;
    try {
      cMethod = oElm.direction;
    } catch (e) {
      cMethod = 'Next';
    }

    const bPrev = (cMethod == 'Prev' && oData.indexById(iSelected) == 0),
      bNext = (iNumRec == oData.indexById(iSelected) + 1 && cMethod == 'Next');

    if (bNext) {
      this.iCurrentBatchPos += 1;
      this.openQuery({ goBackwards: bPrev }, rows => {
        try {
          oData.parse(rows);
        } catch (e) {
          this.log.error(`Error loading rows for ${oSelf.opt.name}`, e);
        }

        iSelected = oData.next(iSelected);
        if (iSelected)
          oData.setCursor(iSelected);
      });
    } else {
      let lLoad = true;
      // if we only have one record -> always load or if prev on batched data
      if (iNumRec == 1)
        lLoad = true;
        // try to get inside of data
      else if (iSelected) {

        switch (cMethod) {
          case 'First':
            iSelected = oData.first();
            break;
          case 'Prev':
            iSelected = oData.previous(iSelected);
            break;
          case 'Next':
            iSelected = oData.next(iSelected);
            break;
          case 'Last':
            iSelected = oData.last();
            break;
          default:
            !_isIE && console.error([ `Invalid direction for fetch: ${cMethod}`, this ]);
            break;
        }
        if (iSelected) {
          lLoad = false;
          if (iSelected)
            oData.setCursor(iSelected);
        }
      }
      if (bPrev) {
        this.iCurrentBatchPos -= 1;
        if (this.iCurrentBatchPos < 0)
          this.iCurrentBatchPos = 0;
        this.openQuery({ goBackwards: bPrev }, rows => {
          try {
            oData.parse(rows);
          } catch (e) {
            oSelf.log.error(`Error loading rows for ${oSelf.opt.name}`, e);
          }

          oData.parse(rows);
          iSelected = oData.last();
          if (iSelected)
            oData.setCursor(iSelected);
        });
      }

      if (lLoad && iOldInd) {

        // get identifier
        const cSelected = oData.item(iOldInd)[this.opt.identifier];

        // set parameter for read
        this.urlQuery.fetch = cMethod;
        this.urlQuery.selfHdl = cSelected;

        // now get data
        this.openQuery({ goBackwards: bPrev });
      }
    }
  }
};
