akioma.onInitJiraDesktop = function() {
  if (!akioma.sessionContext) return;

  if (akioma.sessionContext.eSwatSessionContext.HasJiraAccess == false)
    app.controller.launchContainer({ proc: 'JiraLoginDialog' });
};

akioma.launchGitpodWorkspace = function(oSelf) {
  try {
    const oWindow = oSelf.controller.getAncestor('window');
    const oValue = oSelf.parent.getField('launchGitpodSelect').controller.getValue();
    if (!oValue) {
      akioma.notification({ type: 'error', text: 'No branch selected.', expire: 4000 });
      return;
    }

    let cBranchName = '';
    switch (oValue) {
      case 'DEVELOP':
        cBranchName = 'develop';
        break;
      case 'BETA':
        cBranchName = 'beta';
        break;
      case 'MASTER':
        cBranchName = 'master';
        break;
      case 'SELECTED-BRANCH': {
        const oGrid = oWindow.caller;
        const oSelectedRecords = oGrid.getSelectedRecords();
        if (!oSelectedRecords[0]) {
          akioma.notification({ type: 'error', text: 'No branch selected in the grid.', expire: 4000 });
          return;
        }

        cBranchName = oSelectedRecords[0].branchname || '';
        break;
      }
    }

    if (!cBranchName) {
      akioma.notification({ type: 'error', text: 'Invalid branch Name.', expire: 4000 });
      return;
    }

    loadApplicationSettingsByDomain('Gitpod').then(applicationSettings => {
      let cGitpodUrl = applicationSettings.GitpodUrl || '',
        cGitpodRepositoryUrl = applicationSettings.GitpodRepositoryUrl || '';
      if (!cGitpodUrl) {
        akioma.notification({ type: 'error', text: 'Invalid Gitpod Url.', expire: 4000 });
        return;
      }

      if (!cGitpodRepositoryUrl) {
        akioma.notification({ type: 'error', text: 'Invalid Gitpod Repository Url.', expire: 4000 });
        return;
      }

      if (!cGitpodUrl.endsWith('/#')) {
        if (cGitpodUrl.endsWith('/'))
          cGitpodUrl = `${cGitpodUrl}#`;
        else
          cGitpodUrl = `${cGitpodUrl}/#`;
      }

      if (!cGitpodRepositoryUrl.endsWith('/'))
        cGitpodRepositoryUrl = `${cGitpodRepositoryUrl}/`;

      const cEnvironmentUrl = cGitpodUrl + cGitpodRepositoryUrl + cBranchName;
      window.open(cEnvironmentUrl, '_blank');

      oWindow.close();
    });
  } catch (err) {
    akioma.log.error(err);
  }
};

akioma.jiraLogin = function(oSelf) {
  const oDialog = oSelf.dynObject.screen;
  const oForm = oSelf.parent;

  const cJiraUrl = oForm.dynObject.childs['login_jira_url'].getValue();
  const cUserName = oForm.dynObject.childs['login_name'].getValue();
  const cPassword = oForm.dynObject.childs['login_password'].getValue();

  if (cJiraUrl == null || cJiraUrl == '')
    return;
  if (cUserName == null || cUserName == '')
    return;
  if (cPassword == null || cPassword == '')
    return;

  akioma.invokeServerTask({
    name: 'Akioma.Swat.Authentication.AuthenticationHelperBT',
    methodName: 'AddJiraAuthentication',
    paramObj: {
      plcParameter: {
        Url: cJiraUrl,
        UserName: cUserName,
        Password: cPassword
      }
    }
  }).then(() => {
    getSessionContext().then(result => akioma.setSessionProperty('HasJiraAccess', result.eSwatSessionContext.HasJiraAccess, null, false));
    oDialog.controller.close();
    akioma.swat.RefreshEmitter.refreshData('eBoard', { lastRowState: 'update' });
  });
};
