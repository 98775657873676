/**
 * Base Class for Form Fields
 * @class BaseFormDataField
 *
 */
akioma.BaseFormDataField = function(options) {
  if (!isNull(options) && isNull(this.opt)) {
    this.opt = options.att;
    this.parent = options.parent;
    const form = this.getAncestor('form');
    form.registerField(this);
  }
  this.setOriginalSizes();

};

akioma.BaseFormDataField.prototype = {
  /**
     * Watcher for the form field hasChanges state
     * @instance
     * @memberof akioma.BaseFormDataField
     * @protected
     * @param {boolean} newValue
     */
  _hasFormFieldChangesWatcher: function(newValue) {
    const oForm = akioma.getForm(this);
    const hasChangesStyle = oForm.oVuexState.attributes.hasChangesStyle;
    let input = '';

    if (hasChangesStyle !== '') {
      if (this.$domElement)
        input = this.$domElement;
      else
        input = $(this.dhx).parent().parent();

      if (input)
        input.attr(hasChangesStyle, newValue);

    }

    this._callEventOnStateChanged({ state: 'hasChanges', value: newValue });
  },
  /**
     * Watcher for the form field error state
     * @instance
     * @memberof akioma.BaseFormDataField
     * @protected
     * @param {boolean} newValue
     */
  _errorFormFieldWatcher: function(newValue) {
    const oForm = akioma.getForm(this);
    const cName = this.opt.name;

    // setting up validation class based on error per field
    if (oForm && oForm.dhx)
      oForm.dhx.setValidateCss(cName, !newValue);


    this._callEventOnStateChanged({ state: 'hasErrors', value: newValue });
  },
  /**
     * Method used to call eventOnStateChanged
     * @instance
     * @memberof akioma.BaseFormDataField
     * @protected
     * @param {String|Object} state a string containing all the customStates of the current object
     * or an object with the dirtystate(hasChanges/hasErrors) and the value(true/false)
     */
  _callEventOnStateChanged: function(state) {
    if (this.opt.eventOnStateChanged) {
      try {
        this.stateData = state;
        app.controller.callAkiomaCode(this, this.opt.eventOnStateChanged);
      } catch (e) {
        console.error(e);
      }
    }
  },

  /**
     * Method used to call eventOnCustomStateChange
     * @instance
     * @memberof akioma.BaseFormDataField
     * @protected
     * @param {String} state a string containing all the customStates of the current object.
     */
  _callEventOnCustomStateChange: function(state) {
    if (this.opt.EventOnCustomStateChange) {
      try {
        this.stateData = state;
        app.controller.callAkiomaCode(this, this.opt.EventOnCustomStateChange);
      } catch (e) {
        console.error(e);
      }
    }
  },

  /**
     * Method for calling rules
     * @private
     * @memberof BaseFormDataField
     * @param {Object) evt The event object.
     */
  callRules(evt) {

    const screen = this.dynObject.directRuleScreen;
    if (!screen) return;

    Corticon.callRules(screen, evt);
  },

  /**
     * Method for returning form field grid column end position
     * @memberof BaseFormDataField
     * @return  {integer}
     */
  gridColumnEnd() {
    return this.gridColumnStart + Number(this.opt.Width);
  },
  /**
     * Method for returning the form field's grid row end position
     * @memberof BaseFormDataField
     * @return  {integer}
     */
  gridRowEnd() {
    return this.gridRowStart + Number(this.opt.Height);
  },
  /**
     * Method for applying reponsive behaviour/ CSS class
     * @memberof BaseFormDataField
     * @instance
     * @return {void}
     */
  applyResponsiveBehaviour: function() {
    if (this.opt.responsiveBehaviour)
      this.$domElement.addClass(this.opt.responsiveBehaviour);

  },
  /**
     * Method for applying grid-column-start line
     * @memberof BaseFormDataField
     * @instance
     * @return  {void}
     */
  applyGridColumnStart: function() {
    if (this.opt.COLUMN) {
      this.gridColumnStart = Number(this.opt.COLUMN);
      this.$domElement
        .addClass(`col${this.gridColumnStart}`)
        .css({ 'grid-column-start': String(this.gridColumnStart) });
    }
  },
  /**
     * Method for applying grid row start
     * @instance
     * @memberof BaseFormDataField
     * @return  {void}
     */
  applyGridRowStart: function() {
    if (this.opt.ROW) {
      this.gridRowStart = Number(this.opt.ROW);
      this.$domElement.css({ 'grid-row-start': String(this.gridRowStart) });
    }
  },
  /**
     * Method for applying width
     * @instance
     * @memberof BaseFormDataField
     * @return {void}
     */
  applyWidth: function() {
    if (this.opt.Width)
      this.$domElement.css({ 'grid-column-end': String(this.gridColumnEnd()) });

  },
  /**
     * Method for applying height
     * @instance
     * @memberof BaseFormDataField
     * @return {void}
     */
  applyHeight: function() {
    if (this.opt.Height)
      this.$domElement.css({ 'grid-row-end': String(this.gridRowEnd()) });

  },
  /**
     * Method for setting all the CSS grid reponsive sizes
     * @instance
     * @memberof BaseFormDataField
     * @returns {void}
     */
  setResponsiveSizes: function() {
    this.applyResponsiveBehaviour();
    this.applyGridRowStart();
    this.applyGridColumnStart();
    this.applyWidth();
    this.applyHeight();
    this.applyVisibilityAfterLoad();
  },
  /**
     * Method for setting the original sizes column, row or columns for each form field
     * @instance
     * @memberof BaseFormDataField
     * @returns {void}
     */
  setOriginalSizes: function() {
    this._COLUMN = this.opt.COLUMN;
    this._ROW = this.opt.ROW;

    if (!isNull(this.opt.COLUMNS))
      this._COLUMNS = this.opt.COLUMNS;

  },
  /**
     * Method for applying visibility after load per field,
     * in case setVisible was called on form and element was not found
     * @memberof BaseFormDataField
     * @returns {void}
     */
  applyVisibilityAfterLoad: function() {
    if (this._callFieldRepositionOnFormLoad) {
      const form = akioma.getForm(this);
      if (form) {
        form.setVisible(...this._callrepositionFieldByVisibilityParams);
        this._callrepositionFieldByVisibilityParams = [];
        this._callFieldRepositionOnFormLoad = false;
      }
    }
  },
  /**
     * Method for setting the form field visibility after load
     * @memberof BaseFormDataField
     * @returns {void}
     */
  setFieldVisibilityAfterLoad: function(fieldName, visibility) {
    this._callFieldRepositionOnFormLoad = true;
    this._callrepositionFieldByVisibilityParams = [ fieldName, visibility ];
  },
  /**
     * Watcher for the customState.
     * @param {array} customStates
     * @instance
     * @protected
     * @memberof BaseFormDataField
     * @returns {void}
     */
  _customStatesWatcher: function(customStates) {
    if (isNull(customStates)) return;
    let value = '';
    for (let index = 0; index < customStates.length; index++) {
      if (index == 0)
        value += customStates[index].name;
      else
        value += ` ${customStates[index].name}`;

    }
    const find = this.$domElement.find('.custom-icon');
    if (value && find.length == 0) {
      this.$domElement.attr('akcustomstate', value);
      if (value && find.length == 0)
        this.$domElement.append('<div class="custom-icon"><i></i></div>');

    } else if (value)
      this.$domElement.attr('akcustomstate', value);
    else {
      this.$domElement.removeAttr('akcustomstate');
      $(find).remove();
    }

    const fieldStore = this.getAncestor('form').oVuexState.children[this.opt.id];
    const stateData = this._getCustomStateData(fieldStore);
    this._callEventOnStateChanged(stateData);
    this._callEventOnCustomStateChange(stateData);

  },

  /**
     * Adds the autocomplete attribute.
     * @param {HTMLElement}
     * @instance
     * @protected
     * @memberof BaseFormDataField
     * @returns {void}
     */
  _setAutocomplete: function(input) {
    if (this.opt.Autocomplete)
      $(input).children()[0].setAttribute('autocomplete', this.opt.Autocomplete);
    else
      $(input).children()[0].removeAttribute('autocomplete');

  },

  /**
     * Adds the tabindex attribute.
     * @param {HTMLElement}
     * @instance
     * @protected
     * @memberof BaseFormDataField
     * @returns {void}
     */
  _setTabIndex(input) {
    try {
      if (this.opt.tabIndex) {
        let index = 0;
        if (this.view == 'form')
          index = this.formFieldsLength + +this.opt.tabIndex;
        else if (this.form.view == 'form')
          index = this.form.formFieldsLength + +this.opt.tabIndex;
        else
          index = this.form.akElm.formFieldsLength + +this.opt.tabIndex;

        input.setAttribute('tabindex', index);
      }
    } catch (e) {
      console.error(e);
    }
  },

  /**
   * Parses the rules settings
   * Cannot be called when creating the object
   * @memberOf BaseFormDataField
   * @instance
   */
  _parseRulesSettings: function() {

    const rulesSettings = {
      hasLeaveRules: 'onLeave',
      hasKeyUpRules: '',
      hasChangeRules: ''
    };

    if (this.opt.RulesBehaviour) {
      const ruleList = this.opt.RulesBehaviour.split(',');
      const ruleMap = ruleList.reduce((map, entity) => {
        map[entity] = entity;
        return map;
      }, {});

      rulesSettings.hasLeaveRules = ruleMap['onLeave'] || '';
      rulesSettings.hasKeyUpRules = ruleMap['onKeyUp'] || '';
      rulesSettings.hasChangeRules = ruleMap['onChange'] || '';
    }

    this._rulesSettings = rulesSettings;
  },

  /**
   * Returns the rules settings
   * @memberOf BaseFormDataField
   * @instance
   * @returns {Object} The rule settings for leave, keyUp, change
   */
  getRulesSettings: function() {
    if (!this._ruleSettings)
      this._parseRulesSettings();

    return this._rulesSettings;
  },

  /**
     * Method to check if Leave Rules are enabled (if not set then enabled by default)
     * @memberOf BaseFormDataField
     * @instance
     * @returns {String} The event for the Leave Rules (if defined, otherwise empty String)
     */
  hasLeaveRules: function() {
    return this.getRulesSettings().hasLeaveRules;
  },

  /**
     * Method to check if Change Rules are enabled
     * @memberOf BaseFormDataField
     * @instance
     * @returns {String} The event for the Change Rules (if defined, otherwise empty String)
     */
  hasChangeRules: function() {
    return this.getRulesSettings().hasChangeRules;
  },

  /**
     * Method to check if Change Key Up Rules are enabled
     * @memberOf BaseFormDataField
     * @instance
     * @returns {String} The event for the Key Up Rules (if defined, otherwise empty String)
     */
  hasKeyUpRules: function() {
    return this.getRulesSettings().hasKeyUpRules;
  },

  /**
     * Method for showing the info button of a form field
     * @memberof BaseFormDataField
     * @returns {void}
     */
  showInfoButton() {
    this.getAncestor('form').dhx.showInfoButton(this.opt.name);
  },

  /**
     * Method for hiding the info button of a form field
     * @memberof BaseFormDataField
     * @returns {void}
     */
  hideInfoButton() {
    this.getAncestor('form').dhx.hideInfoButton(this.opt.name);
  }
};
