// Put somewhere in your scripting environment
if (typeof jQuery.when.all === 'undefined') {
  jQuery.when.all = function(deferreds) {
    return $.Deferred(def => {
      $.when.apply(jQuery, deferreds).then(
        function() {
          def.resolveWith(this, [Array.prototype.slice.call(arguments)]);
        },
        function() {
          def.rejectWith(this, [Array.prototype.slice.call(arguments)]);
        });
    });
  };
}

// ***************** datasource ******************
/**
     * This class is a datastore type class that uses JSDO to communicate with businessEntities.
     * @param  {Object} options Repository Object Attributes.
     * @class  ak_businessEntity
     * @augments ak_global
     * @param {Object} options Repository attributes for SwatBusinessEntity.
     * @param {string} options.SUBTYPE
     * @param {string} options.resourceName
     * @param {string} options.Tables A comma delimited list of tables in the Query Object. Qualified with database name if the query is defined with dbname.&#10;It is a design time property, as it's very expensive to resolve on the server at run-time.&#10;There is no way to change the order of the tables at run time. But it would be even more important to have this as a property&#10;if the order of the tables were changed dynamically, because several other properties have table delimiters and are depending on the design-time order of this.
     * @param {string} options.ServerProp Pass any kind of property to the SDO at runtime.&#10;&#10;Format:&#10;&#10;PropName#PropValue|PropName#PropValue&#10;&#10;...
     * @param {string} options.EntityName The Name of the Business Entity used by the component
     * @param {string} options.KeyFields The indexInformation will be used to try to figure out the&#10;default KeyFields list, but this is currently restricted to cases &#10;where: &#10;     - The First Table in the join is the Only enabled table.&#10;     - All the fields of the index is present is the SDO.             &#10;The following index may be selected.                          &#10;            1. Primary index if unique.&#10;            2. First Unique index.
     * @param {string} options.EventDataAvailable Client-side code to excecute when SDO read a record
     *
     *  #Example - SWAT-1251
     *  Attribute value can be: <code>$ akioma.showViewSidebar(self);</code>
     *  Sample for show/hide tabbar in sidebar mode tabs/pages based on a field value:
     *  <pre class="prettyprint source"><code>
     *   akioma.changeViewSidebar = function(self){
     *     console.log(['changeViewSidebar', self]);
     *     // check if BE selected record has a selfno startign with something, then show sidebar item 2 or item 1
     *     //
     *     var cSelfNo = self.getValue('selfno');
     *     var oTabbar = self.container.controller.getDescendant('tabbar');
     *     // if field starts with 104 then open using Launchcontainer view
     *     //
     *     oTabbar.hideAllPages();
     *     oTabbar.unselectPage();
     *     if(cSelfNo.startsWith('104')){
     *          oTabbar.viewActivePage(2);
     *     }else{
     *          oTabbar.viewActivePage(1);
     *
     *     }
     *   };
     *   </code></pre>
     *
     * #Example - SWAT-1251
     * The value of the attribute can be set to for eg. <code>$ akioma.changeView(self);</code>
     * Sample for show/hide view mode of a panel based on a field value:
     * <pre class="prettyprint source"><code>
     * akioma.changeView = function(self){
     *      console.log(self);
     *      var oPanel =  self.container.getObject('offer_headerv').controller.parent;
     *      var cSelfNo = self.getValue('selfno');
     *      // if field starts with 104 then open using Launchcontainer view
     *      if(cSelfNo.startsWith('104')){
     *          if(oPanel.getView('testviewformframe') == undefined){
     *              app.controller.launchContainer( {
     *                  target:   oPanel,
     *                  dynGuid: dhtmlx.uid(),
     *                  view: 'testviewformframe',
     *                  containerinsguid : self.parent.controller.opt._ContainerInstanceGuid,
     *                  proc:   "testviewformframe.r",
     *                  pages:   "0,1",
     *                  // self:   oSelf,
     *                  data: true
     *                  // containerUserData: {type : 'widget'}
     *              } );
     *          }else{
     *              oPanel.showView('testviewformframe');
     *          }
     *      }else{
     *          oPanel.showView('def');
     *      }
     *  }
     *  </code></pre>
     * @param {boolean} options.signalSuccessfulSave when true, the ui displays a non-modal message after a successfull save
     * @param {string} options.defaultSort default sort phrase for a businessEntirty, if no filtering is defined by the user.
     * @param {string} options.DataColumns A list of all the DataColumns of all the Data Objects in this SBO, each qualified by the SDO ObjectName.&#10;The list of columns for each contained Data Object is comma-delimited, and qualified by Object Names, as an alternative to the ContainedDataColumns form of the list which divides the list by SDO.
     * @param {number} options.RowsToBatch The number of rows to be transferred from the database query into the RowObject temp-table at a time.&#10;Setting RowsToBatch to 0 indicates that ALL records should be  read.
     * @param {string} options.Title_Function Title Function for Attribute Browser, Frame, Dialog and Window title
     * @param {string} options.ServerFileName The actual server-side object filename to run on the AppServer -- may not be the ObjectName if that has been modified.&#10;Defaults to the target-procedure file-name without the _cl.
     * @param {string} options.namedQuery optional namedQuery definition. Must be set in JSON format, e.g.:&#10;{ name: queryName , parameters: []}&#10;
     * @param {string} options.ForeignFields A comma-separated list, consisting of thefirst local db fieldname, followed by the matching source temp-table field name, followed by more pairs if there is more than one field to match.
     * @param {string} options.serviceURI
     * @param {string} options.EventBeforeFetch code to be executed before fetching data from the backend
     * @param {string} options.catalogURI
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     * @param {string} options.EventAfterFetch client side code executed after a fetch
     * @param {string} options.fieldlist list of calculated fields to be sent on a before fetch akQuery fieldlist
     * @fires ak_businessEntity#EventAfterFetch
     * @fires ak_businessEntity#EventAfterSave
     * @fires ak_businessEntity#EventBeforeFetch
     * @fires ak_businessEntity#EventDataAvailable
     * @fires ak_businessEntity#EventOnInitialize
     */
$.ak_businessEntity = class extends akioma.BaseDataSource {
  constructor(options) {
    super(options);
    const oSelf = this;
    this.view = 'businessEntity';
    this.lastUpdatedRecord = null;

    this.iOpenQueryTimeoutDelay = 10;
    this.bApplyQueryFilters = false;

    // Hardcoded for now
    if (oSelf.opt.name == 'filterd')
      oSelf.opt.identifier = 'filterhdl';

    this.hasRecordsToBatch = true;
    this.aErrRowsStatusSmartMessages = [];
    this._oldBatchSize = null;
    this.aBeforeFillOnce = [];
    this.aAfterFill = [];
    this.bContainsSmartMessage = false;
    this.refreshSkipRules = {}; // refresh skip publishing rules for "update", "remove", "add" operations

    // harcoded LN fix for chooseFileG
    if (this.opt.name == 'dFileList')
      this.opt.numRecords = 0;

    this.oLastUiAction = null;

    try {

      // create global datastore
      this.dhx = new dhtmlXDataStore({ datatype: 'json' });
      this.dhx.data.akElm = this;

      // dirty flag prevents/prompt user for cursor change on BE
      // DEPRECATED
      this.dhx.preventMethodCheck = function(newId) {
        try {
          if (oSelf.hasChanges()) {
            oSelf.promptCursorChange(newId);
            return true;
          }
          return false;
        } catch (e) {
          oSelf.log.warn(e);
        }
      };

      this.dhx.attachEvent('onBeforeCursorChange', () => {
        try {
          // cleanup form ready flag for validation
          for (const i in oSelf.observers) {
            const oControl = oSelf.observers[i];
            if (oControl.view == 'form') {
              if (oControl.dhx.formReadyForValidation)
                delete oControl.dhx.formReadyForValidation;

            }
          }
        } catch (e) {
          console.warn(e);
        }
      });

      this.dhx.attachEvent('onAfterCursorChange', cNew => {
        try {
          this._commit('SET_CURSOR', cNew);
        } catch (e) {
          console.error(e);
        }
        this.clearTempStore();
        this.decrementStoreChangesAndErrors();
        this._publishDataAvail(cNew);
        this._resetCallAfterDisplay();

      });

      this.dhx.attachEvent('onXLE', function() {

        // cleanup dirty state on load of data
        // fixes reprompt of cursor change
        oSelf.decrementStoreChangesAndErrors();

        if (this.dataCount() > 0) {
          const hasKeepSelectionDataInObservers = oSelf.checkObserversWithKeepSelection();
          if (!hasKeepSelectionDataInObservers)
            this.setCursor(this.first());
        } else { // fix for dhx select node
          if (!(oSelf.jsdo instanceof akioma.KinveyDataAdapter) && oSelf.jsdo[oSelf.opt.entityName]
                && oSelf.jsdo[oSelf.opt.entityName].getData()[0] && oSelf.jsdo[oSelf.opt.entityName].getData()[0]._id) {
            if (this.item(oSelf.jsdo[oSelf.opt.entityName].getData()[0]._id) != null)
              this.setCursor(oSelf.jsdo[oSelf.opt.entityName].getData()[0]._id);
            else
              this.setCursor(oSelf.jsdo[oSelf.opt.entityName].getData()[0].id);
          } else
            this.refresh();

          oSelf._commit('SET_CURSOR', '');
          oSelf._publishDataAvail('');

          if (oSelf.dynObject) {
            const aDisplayTargets = oSelf.dynObject.getLinks('DISPLAY:TARGET') || [];
            const aDataTargets = oSelf.dynObject.getLinks('DATA:TARGET') || [];
            const aNavSrcs = oSelf.dynObject.getLinks('NAVIGATION:SRC') || [];

            const aAllLinks = aDisplayTargets.concat(aDataTargets, aNavSrcs);

            for (const a in aAllLinks) {
              const linkTarget = aAllLinks[a];
              if (linkTarget.controller.dhx && linkTarget.controller.dhx.callEvent)
                linkTarget.controller.dhx.callEvent('onAfterCursorChange', '');
            }

            oSelf._callNoDataAvailEvent();
          }
        }
      });

      this._loadCatalogService(options);

    } catch (oErr) {
      this.log.error('addCatalog Error', oErr);
    }

    // event afterAdd to call GetInitialValues
    this.dhx.attachEvent('onAfterAdd', (id, pos) => {
      oSelf.eventAfterAddInitValues(id, pos);
    });
  }

  /**
   * Method for loading service catalog, based on active store adapter
   * @param {object} options DataSource repository attributes
   * @instance
   * @private
   * @memberof ak_businessEntity
   */
  _loadCatalogService(options) {
    const oSelf = this;
    const subType = this.opt.SUBTYPE;
    switch (subType) {
      case 'MockupCollection':
        this.jsdo = new akioma.MockupDataAdapter({ fetchUrl: this.opt.MockupData });
        setTimeout(() => {
          oSelf.onAfterCatalogAdd({ jsdosession: akioma.restSession, result: 1, info: null });
        }, 5);
        break;
      case 'KinveyCollection':
        this.jsdo = new akioma.KinveyDataAdapter(options.att.resourceName);
        setTimeout(() => {
          oSelf.onAfterCatalogAdd({ jsdosession: akioma.restSession, result: 1, info: null });
        }, 5);
        break;
      case 'ElasticSearchCollection':
        this.jsdo = new akioma.ElasticSearchDataAdapter(options.att.resourceName, options.att.entityName);
        setTimeout(() => {
          oSelf.onAfterCatalogAdd({ jsdosession: akioma.restSession, result: 1, info: null });
        }, 5);
        break;
      default: {
        if (!this.isCatalogLoaded())
          oSelf._loadCatalog();
        else {
          setTimeout(() => {
            if (this.isCatalogLoaded())
              oSelf.onAfterCatalogAdd({ jsdosession: akioma.restSession, result: 1, info: null });
            else
              oSelf._loadCatalog();
          }, 5);
        }
      }
    }
  }

  /**
   * Method for loading a catalog JSDO resource
   * @memberof ak_businessEntity
   * @instance
   * @private
   */
  _loadCatalog() {
    this.bAfterAddCatalog = akioma.restSession.addCatalog(this.catalogURI)
      .then(this.onAfterCatalogAdd.bind(this))
      .catch(this.onAfterCatalogAdd.bind(this));
  }

  /**
   * Method for checking if JSDO catalog is loaded
   * @memberof ak_businessEntity
   * @instance
   * @private
   * @returns {boolean}
   */
  isCatalogLoaded() {
    const oMobileCatalogService = progress.data.ServicesManager._resources[this.opt.resourceName];
    return !!oMobileCatalogService;
  }

  // methods for datasource
  /**
   * Method used for cleanup up the Refresh Listeners
   */
  cleanUpRefreshListeners() {
    const oSelf = this;
    this.log.debug('Cleanup Refresh Listeners for ', oSelf.opt.name);
    akioma.RefreshEmitter.CleanUp(oSelf.dynObject);
  }

  /**
   * Method used for setting the refresh scheme payload
   * @param {Object} payload
   * @instance
   * @memberof ak_businessEntity
   */
  setRefreshSchemePayload(payload) {
    this.payload = payload;
  }

  /**
   * Method used for getting the refresh scheme payload
   * @instance
   * @memberof ak_businessEntity
   * @return {Object|null}
   */
  getRefreshSchemePayload() {
    return (this.payload) ? this.payload : null;
  }

  /**
   * Method for checking if container frame is active
   * @instance
   * @memberof ak_businessEntity
   * @returns {boolean} If dataSource is inside an active dynamic view
   */
  _isActiveFrame() {
    const oFrame = this.dynObject.getFirstParentByType('frame');

    if (oFrame && !isNull(oFrame.inParentView)) {
      const oPanel = oFrame.dynObject.getFirstParentByType('panel');

      if (oPanel.getCurrentView() !== oFrame.inParentView)
        return false;
    }

    return true;
  }

  /**
   * Method to return parent view frame
   * @instance
   * @memberof ak_businessEntity
   * @returns ak_frame
   */
  getParentView() {
    const oFrame = this.dynObject.getFirstParentByType('frame');
    return oFrame;
  }

  /**
   * Method used for refreshing data
   * @param {Object} payload
   * @instance
   * @private
   * @memberof ak_businessEntity
   */
  refreshDataFetch(payload) {

    this.log.debug('refreshDataFetch called ', payload);

    const oSelf = this;

    let bSameSource = (payload.sourceId == this.opt.id);

    const isActiveFrame = this._isActiveFrame();

    // in case it is called from a BT akioma.refreshData
    if (payload.sourceId == undefined)
      bSameSource = false;

    payload.cancelRefresh = false;

    oSelf.setRefreshSchemePayload(payload);
    const callbackFunc = payload.RefreshScheme.RefreshConditionCallback;

    if (!isNull(callbackFunc)) {
      // here, self is a controller, not a dynObject
      payload = akioma.swat.evaluateCode({ code: callbackFunc, controller: oSelf, dynObj: oSelf.dynObject });

      if (payload.cancelRefresh)
        return;
    }

    if (!isActiveFrame) {
      const parentViewFrame = this.getParentView();
      parentViewFrame._dynViewDataObjList.push({
        object: this,
        payload
      });
    }
    // refresh data if no smartmessage, if no pending request, if not triggered from same DataSource-BE
    if (!this.containsSmartMessage() && !this._pendingrequest && !bSameSource && isActiveFrame) {
      if (this.dhx != null) {
        const currentAddRepositionTo = payload.RefreshScheme.AddRepositionTo;
        const currentRemoveRepositionTo = payload.RefreshScheme.RemoveRepositionTo;
        const currentUpdateRepositionTo = payload.RefreshScheme.UpdateRepositionTo;
        const currentAddSkipRefresh = payload.RefreshScheme.AddSkipRefresh;
        const currentRemoveSkipRefresh = payload.RefreshScheme.RemoveSkipRefresh;
        const currentUpdateSkipRefresh = payload.RefreshScheme.UpdateSkipRefresh;
        const currentRepositionToKey = payload.RefreshScheme.RepositionToKey;

        const aTables = this.RefreshScheme.Tables.split(',');

        // default is first event
        let tableNameSrc = (aTables[0] || this.opt.entityName);

        const entityNameSrc = payload.entityNameSourceEmit;

        let addRepositionTo = payload.RefreshScheme.AddRepositionTo;
        let removeRepositionTo = payload.RefreshScheme.RemoveRepositionTo;
        let updateRepositionTo = payload.RefreshScheme.UpdateRepositionTo;
        let addSkipRefresh = payload.RefreshScheme.AddSkipRefresh;
        let removeSkipRefresh = payload.RefreshScheme.RemoveSkipRefresh;
        let updateSkipRefresh = payload.RefreshScheme.UpdateSkipRefresh;
        let repositionToKeyRefresh = payload.RefreshScheme.RepositionToKey;

        let oParams = {};

        // match tables of emit source table name
        for (const t in aTables) {
          const table = aTables[t];

          // wildcard table
          if (table.includes('*')) {
            const cleanTableStart = table.replace('*', '');

            if (entityNameSrc.startsWith(cleanTableStart)) {
              tableNameSrc = table;
              break;
            }

            // if matches
          } else if (table == entityNameSrc) {
            tableNameSrc = table;
            break;
          }
        }

        // check for tables matching
        let indexOfSetting = aTables.indexOf(tableNameSrc.replace('*'));

        // check for wildcard matches
        if (indexOfSetting == -1)
          indexOfSetting = aTables.indexOf(tableNameSrc);

        // check for current setting for repositionTo
        // based on current eventName triggered
        // if multiple are defined
        if (currentAddRepositionTo.includes(',')) {
          const aSettingsAdd = currentAddRepositionTo.split(',');
          addRepositionTo = aSettingsAdd[indexOfSetting];
        }

        if (currentRemoveRepositionTo.includes(',')) {
          const aSettingsRemove = currentRemoveRepositionTo.split(',');
          removeRepositionTo = aSettingsRemove[indexOfSetting];
        }

        if (currentUpdateRepositionTo.includes(',')) {
          const aSettingsUpdate = currentUpdateRepositionTo.split(',');
          updateRepositionTo = aSettingsUpdate[indexOfSetting];
        }

        if (currentAddSkipRefresh && currentAddSkipRefresh.includes(',')) {
          const aSettingsSkipAdd = currentAddSkipRefresh.split(',');
          addSkipRefresh = aSettingsSkipAdd[indexOfSetting];
        }

        if (currentRemoveSkipRefresh && currentRemoveSkipRefresh.includes(',')) {
          const aSettingsSkipRemove = currentRemoveSkipRefresh.split(',');
          removeSkipRefresh = aSettingsSkipRemove[indexOfSetting];
        }

        if (currentUpdateSkipRefresh && currentUpdateSkipRefresh.includes(',')) {
          const aSettingsSkipUpdate = currentUpdateSkipRefresh.split(',');
          updateSkipRefresh = aSettingsSkipUpdate[indexOfSetting];
        }

        if (currentRepositionToKey && currentRepositionToKey.includes(',')) {
          const aSettingsRepositionKey = currentRepositionToKey.split(',');
          repositionToKeyRefresh = aSettingsRepositionKey[indexOfSetting];
        }

        // check if businessEntity refresh validation
        this.setRefreshSkip('update', updateSkipRefresh);
        this.setRefreshSkip('delete', removeSkipRefresh);
        this.setRefreshSkip('add', addSkipRefresh);

        // prepare repositionTo params for openQuery
        switch (payload.lastRowState) {

          case 'update':
          // selects next available record
            if (updateRepositionTo == '#NextRow')
              oParams = { repositionTo: '#NextRow' };

            break;

          case 'delete':
          // select next row or keepselection
            if (removeRepositionTo == '#NextRow')
              oParams = { repositionTo: '#NextRow' };
            else if (removeRepositionTo == '#KeepSelection')
              oParams = { repositionTo: '#KeepSelection' };

            break;

          case 'add':
          // selects next available record
            if (addRepositionTo == '#NextRow')
              oParams = { repositionTo: '#NextRow' };
            else if (addRepositionTo == '#KeepSelection')
              oParams = {};
            else if (addRepositionTo == '#NewRecord') {
              if (repositionToKeyRefresh)
                oParams = { repositionTo: payload.lastUpdatedRecord[repositionToKeyRefresh] };
              else oParams = { repositionTo: payload.lastUpdatedRecord.selfhdl };
            }
            break;
        }

        // set reposition to key if exists
        if (repositionToKeyRefresh) {
          oParams.repositionToKey = repositionToKeyRefresh;

          if (oParams.repositionTo === '#KeepSelection')
            oParams.repositionTo = payload.lastUpdatedRecord[repositionToKeyRefresh];
        }

        if (oParams.repositionTo == '#NextRow')
          oParams.repositionTo = this.getNext(true) || this.getPrevious(true);

        // keep filters in grid header and set correct batching size before refreshing
        this.refreshDataFilterBatch();

        // set form visibility for AfterDisplay when called from RefreshScheme
        this.setFormVisibleOnRefresh();

        // refresh the DataSource
        this.openQuery(oParams);
      }
    }
  }

  /**
   * Method for checking JSDO has changes
   * @instance
   * @memberof ak_businessEntity
   * @returns {boolean}
   */
  hasJSDOChanges() {
    return this.jsdo.hasChanges();
  }

  /**
   * Method for setting form visibile, for refreshed data AfterDisplay method
   * @private
   * @memberof ak_businessEntity
   * @instance
   * @returns  {void}
   */
  setFormVisibleOnRefresh() {
    try {
      const aDisplayTargets = this.dynObject.getLinks('DISPLAY:TARGET') || [];
      const aDataTargets = this.dynObject.getLinks('DATA:TARGET') || [];

      const aAllLinks = aDisplayTargets.concat(aDataTargets);

      aAllLinks.forEach(control => {
        const controller = control.controller;
        if (controller.view === 'form')
          controller.refreshDataVisible = true;

      });
    } catch (e) {
      this.log.error(e);
    }
  }

  /**
   * Method used when refreshing the dataSource in order to keep the grid filters in header and
   * setting up the correct filter for batching if present
   * @instance
   * @private
   * @memberof ak_businessEntity
   */
  refreshDataFilterBatch() {
    try {
      const oSelf = this;
      // keep filters in grid header
      const aDisplayTargets = oSelf.dynObject.getLinks('DISPLAY:TARGET') || [];
      const aDataTargets = oSelf.dynObject.getLinks('DATA:TARGET') || [];

      const aAllLinks = aDisplayTargets.concat(aDataTargets);

      for (const a in aAllLinks) {
        const linkTarget = aAllLinks[a];
        if (linkTarget.controller.view.indexOf('datagrid2') > -1) {

          // render the grid filters in grid header
          if (!linkTarget.controller.bFilterError)
            linkTarget.controller._renderMultiSelectStateAfterFill();

          // check if batchMode and if so then
          // clear data and reload data
          if (linkTarget.controller.opt.batchingMode == true) {
            const iBatchSize = (oSelf.batchSize.lastIndex) ? oSelf.batchSize.lastIndex + linkTarget.controller.iBatchSize : linkTarget.controller.iBatchSize;
            const iSkip = 0;

            linkTarget.controller.pauseGridBatchHandler = true;

            oSelf.clearAllData();
            oSelf.setBatch(iSkip, iBatchSize);
          }
        }
      }
    } catch (e) {
      this.log.error(e);
    }
  }

  /**
   * Method called when adding a new record in the dhx Store, it will call GetInitialValues businessTask method and set the initial record values
   * @private
   * @instance
   * @memberOf ak_businessEntity
   * @return  {void}
   */
  eventAfterAddInitValues(id) {
    const oSelf = this;
    try {
      if (oSelf.cancelAfterAdd)
        return true;

      if (this.opt.SUBTYPE === 'KinveyCollection') {
        const dataTarget = oSelf.dynObject.getLinks('DISPLAY:TARGET');

        if (isNull(dataTarget[0]) || isNull(dataTarget[0].controller))
          throw new Error('Kinvey collection has no data target for fetching initial values on recordCreate!');

        this.bCreateMode = true;

        const fields = akioma.DataAdapterHelper.getFieldsFromForm(dataTarget[0].controller);

        const defaultValues = {};

        fields.forEach(field => {
          defaultValues[field.fieldName.toLowerCase()] = field.defaultValue;
        });

        this.dhx.update(id, defaultValues);
        this.dhx.setCursor(id);

        this.bCreateMode = false;

        return;
      }

      const oItem = oSelf.dhx.item(id);

      // add all tables from jsdo
      const aTablesNames = [];
      for (const key in oSelf.jsdo._buffers) {
        if (Object.prototype.hasOwnProperty.call(oSelf.jsdo._buffers, key))
          aTablesNames.push(key);

      }

      const aForeignKeyFields = [];
      let aForeignKeyValues = [];

      if (oSelf.foreignKeyFields != undefined) {
        for (const i in oSelf.foreignKeyFields) {
          aForeignKeyFields.push(oSelf.foreignKeyFields[i].name);
          aForeignKeyValues.push(oSelf.foreignKeyFields[i].value);
        }
      }
      while (aForeignKeyFields.length < aTablesNames.length) {
        aForeignKeyFields.push('');
        aForeignKeyValues.push('');
      }

      aForeignKeyValues = aForeignKeyValues.map(val => {
        if (val === null || val === undefined)
          return null;

        return val.toString();
      });

      const oParameter = {
        plcParameter: {
          TableNames: aTablesNames.join(','),
          ForeignKeyFields: aForeignKeyFields,
          ForeignKeyValues: aForeignKeyValues
        }
      };
      const getInitialValuesMethod = oSelf.jsdo.GetInitialValues (oParameter, false);

      const oReturnDefs = getInitialValuesMethod.response;
      if (oReturnDefs.error && oReturnDefs.message) {
        akioma.notification({ type: 'error', text: oReturnDefs.message, expire: 10000 });
        this.log.error('Could not load initial values.', oReturnDefs);
      } else {
        const oDefData = oReturnDefs.plcDataset[oSelf.jsdo._dataSetName][oSelf.entityName][0];

        let oNewToExtend;
        if (oItem != undefined)
          oNewToExtend = oItem;

        oSelf.bCreateMode = true;

        const oNewItem = $.extend({}, oNewToExtend, oSelf.objectKeysToLowerCase(oDefData), { id: id, _id: id });

        this.log.info('New record added:', oNewItem);

        try {
          oSelf.dhx.update(id, oSelf.objectKeysToLowerCase(oNewItem));
        } catch (e) {
          this.log.error('There was a problem updating the record data with initial record data.');
        }
        oSelf.dc.setState(id, 'inserted');
        oSelf.dhx.setCursor(id);

        oSelf.bCreateMode = false;

      }
    } catch (e) {
      akioma.notification({ type: 'error', text: 'Could not load initial values, error in GetInitialValues method call.', expire: 10000 });
      this.log.error('Could not load initial values.', e);
    }

    oSelf._rulesDataLoaded = true;

    this.callRules({
      eventEntity: oSelf.opt.name,
      eventName: 'onDataAvailable',
      eventSource: ''
    });
  }

  addCatalog() {
    const oSelf = this;
    const deferred = $.Deferred();

    oSelf.bAfterAddCatalog = akioma.restSession.addCatalog(oSelf.catalogURI)
      .then(res => {
        oSelf.onAfterCatalogAdd(res);
        deferred.resolve();
      })
      .catch(res => {
        oSelf.onAfterCatalogAdd(res);
        deferred.reject();
      });

    return deferred.promise();
  }

  /**
   * Method for setting up RefreshScheme Listeners from the refreshScheme attribute JSON data
   * @instance
   * @memberof ak_businessEntity
   * @returns {void}
   */
  setupRefreshSchemeListeners() {
    // add refresh listeners here listeners here
    // will also check for refreshSchema
    if (akioma.appdata && akioma.appdata.refreshAfterSave && this.dynObject) {
      // default refresh Scheme
      const RefreshScheme = {
        'Tables': '', // Sendung,SendungSource
        'AddRepositionTo': '#NewRecord', // default reposition to newly created recrod
        'UpdateRepositionTo': '#KeepSelection', // default keeps selection of the selected record
        'RemoveRepositionTo': '#NextRow' // default reposition to next available record using #NextRow
      };

      // read defined RefreshScheme
      if (this.opt.refreshScheme !== '' && !isNull(this.opt.refreshScheme))
        $.extend(RefreshScheme, JSON.parse(this.opt.refreshScheme.replace(/'/g, '"')));

      this.RefreshScheme = RefreshScheme;

      if (RefreshScheme.Tables) {
        const aTables = RefreshScheme.Tables.split(','); // get Table Names

        for (const table in aTables) {

          const tableName = aTables[table];

          this.log.debug('adding', [ 'dataChanged', tableName ]);

          // add eventListener for each entity Table defined in RefreshScheme
          akioma.RefreshEmitter.Add(this.dynObject, [ 'dataChanged', tableName ], payload => {
            this.refreshDataFetch({
              ...payload,
              RefreshScheme: this.RefreshScheme
            });
          });

        }
      } else {

        this.log.debug('adding', [ 'dataChanged', this.opt.entityName ]);

        // add listener for it's own entity
        akioma.RefreshEmitter.Add(this.dynObject, [ 'dataChanged', this.opt.entityName ], payload => {
          this.refreshDataFetch({
            ...payload,
            RefreshScheme: this.RefreshScheme
          });
        });
      }
    }
  }

  /**
   * Method for for emitting the events for RefreshScheme
   * @instance
   * @memberof ak_businessEntity
   * @returns {void}
   */
  afterSaveChangesEmitRefresh() {
    // emit the refresh Event for the entity name
    // this will go over schema and call the events for every entityTable name
    if (akioma.appdata && akioma.appdata.refreshAfterSave) {

      // emit the table name itself
      akioma.RefreshEmitter.Emit(this.dynObject, [ 'dataChanged', this.opt.entityName ]);

      // look for begins events setup
      const aBeginsDataChangedEvents = _.filter(Object.keys(akioma.eventEmitter._events), item => {
        if (item.startsWith('dataChanged,') && item.includes('*'))
          return true;

      });

      // if found, prepare search filterValues
      let aEventBeginsFilters;
      if (aBeginsDataChangedEvents && aBeginsDataChangedEvents.length > 0)
        aEventBeginsFilters = aBeginsDataChangedEvents.map(item => item.replace('dataChanged,', '').replace('*', ''));

      // if any of the filterValues match the current Entity, emit refresh event/datachanged
      if (aEventBeginsFilters && aEventBeginsFilters.length > 0) {
        aEventBeginsFilters.forEach(filterVal => {
          if (this.opt.entityName.startsWith(filterVal)) {
            // emit the event with the begins rule
            akioma.RefreshEmitter.Emit(this.dynObject, [ 'dataChanged', `${filterVal}*` ]);
          }
        });
      }
    }
  }

  // finish construct ************
  finishConstruct() {
    const oSelf = this;

    // setup refresh listeners from RefreshScheme
    this.setupRefreshSchemeListeners();

    if (oSelf.jsdo == undefined) {
      // wait for catalog load
      oSelf.callFinishConstruct = true;

      return false;
    }

    if (this.opt.serverProp) {
      const aProp = this.opt.serverProp.split('|');
      for (const i in aProp) {
        const aVal = aProp[i].split('#');
        this.setSrvProp(aVal[0], aVal[1]);
      }
    }

    // set initial namedquery
    if (this.opt.namedQuery)
      this.oNamedQuery = this.opt.namedQuery;

    if (this.opt.onInit)
      app.controller.callAkiomaCode(this, this.opt.onInit);

    // if we are a primary sdo -> link it with frame
    if (this.opt.primarySDO) {
      // check for frame
      const oFrame = this.parent;

      this.log.debug([ 'primarySDO, Frame:', oFrame, this ]);

      if (oFrame && oFrame.view == 'frame') {

        const cObjName = oFrame.getObjName('DATA', 'TRG');
        const aSource = app.messenger.getObjects(this.dynObject, `DATA:SRC:${cObjName}`);

        this.log.debug([ 'ObjName:', cObjName, 'Source:', aSource ]);

        if (aSource && aSource.length > 0)
          aSource[0].controller.addObserver(this);

        // now check if parent of frame is a frame -> exchange reference for foreignkey
        if (oFrame.parent.view == 'frame') {
          // if foreignkey is the same
          if (oFrame.parent.opt.foreignKey == this.opt.foreignKey)
            this.opt.extKey = oFrame.parent.opt.extKey;
        }
      }
    }

    this.dhx.attachEvent('onXLS', () => {
      if (oSelf.batchSize.skip == undefined) // don't clear up if in batchmode
        oSelf.clearAllData();
    });

    this.dhx.data.scheme({
      $init: function() {
        oSelf.prop.update = false;
      },
      $update: function() {
        oSelf.prop.update = true;
      }
    });

    // changedata with callback to change success
    this.jsdo.subscribe('afterUpdate', (jsdo, record, success, request) => {
      oSelf._eventAfterUpdateJSDO(jsdo, record, success, request);
    });

    this.jsdo.subscribe('afterSaveChanges', (jsdo, success, request) => {
      oSelf._eventAfterSaveChangesJSDO(jsdo, success, request);
    });

    // add a simple record
    this.jsdo.subscribe('afterCreate', (jsdo, record, success, request) => {
      oSelf._eventAfterCreateJSDO(jsdo, record, success, request);
    });

    // deletedata with callback to change success
    this.jsdo.subscribe('afterDelete', (jsdo, record, success, request) => {
      oSelf._eventAfterDeleteJSDO(jsdo, record, success, request);
    });

    /* end of jsdo */

    this.dc = new dataConnector();
    this.dc.akElm = this;

    this.dc.init(this.dhx);

    this.dc.attachEvent('onBeforeSaveChanges', () => {
      if (oSelf.opt.eventBeforeSave)
        app.controller.callAkiomaCode(oSelf, oSelf.opt.eventBeforeSave);
    });

    this.dc.attachEvent('onBeforeUpdate', (rid, status, obj) => {
      oSelf._eventBeforeUpdate(rid, status, obj);
    });

    if (oSelf.opt.initialFetch == '#none')
      oSelf.bInitialFetchedReq = true;

    return true;
  }

  /**
   * Method called before saving the JSDO records
   *
   * @param   {string}  rid     The record id
   * @param   {string}  status  status type
   * @param   {object}  obj     the record
   * @private
   * @instance
   * @memberOf ak_businessEntity
   * @return  {boolean}          returns false
   */
  _eventBeforeUpdate(rid, status, obj) {
    akioma.WaitCursor.showProgressState(this.dynObject);
    this.setWaitCursorForLinks(true); // in progress, not cancellable

    this.log.info('eventBeforeUpdate', rid, status, obj);

    if (Object.prototype.hasOwnProperty.call(obj, '!nativeeditor_status'))
      delete obj['!nativeeditor_status'];

    const cFristChar = status[0].toLowerCase();
    const jsdoEntityStore = this.opt.SUBTYPE !== 'KinveyCollection' ? this.jsdo[this.entityName] : null;
    let oSelectedRecord = null;
    try {
      switch (cFristChar) {
        case 'u':
          // JSDO tells that the row was updated but after refreshing it looks like it wasn't updated
          this.beforeUpdate(rid, status);

          if (this.opt.SUBTYPE !== 'KinveyCollection') {
            oSelectedRecord = jsdoEntityStore.findById(rid);
            const dhxStoreRecord = this.dhx.item(rid);
            // in case the working record is not found search by identifier and update store id
            if (typeof (jsdoEntityStore) === 'object') {
              if (oSelectedRecord == null && typeof (dhxStoreRecord) !== 'undefined')
                oSelectedRecord = jsdoEntityStore.findById(dhxStoreRecord._id);
              else if (typeof (dhxStoreRecord) === 'undefined')
                break;
              jsdoEntityStore.assign(obj);
              this.jsdo.saveChanges();
            }
          } else {
            const updatedRecord = akioma.DataAdapterHelper.getRecordWithCasings(obj, this);
            updatedRecord._id = obj._id;

            this.jsdo.update(updatedRecord);
          }
          break;
        case 'd':
          if (this.opt.SUBTYPE !== 'KinveyCollection') {
            oSelectedRecord = jsdoEntityStore.findById(rid);
            if (oSelectedRecord) {
              jsdoEntityStore.remove();
              this.jsdo.saveChanges();
            }
          } else {
            this.jsdo.findById(obj._id).then(result => {
              if (!isNull(result))
                this.jsdo.remove(result);

            });
          }
          break;
        case 'i':
          if (this.opt.SUBTYPE !== 'KinveyCollection') {
            this.jsdo.skipQuery = true;

            const record = jsdoEntityStore.add(obj);
            jsdoEntityStore.findById(record.getId());

            this.jsdo.saveChanges();

            this.dhx.setCursor(rid);

            this.jsdo.skipQuery = false;
          } else {
            const createdRecord = akioma.DataAdapterHelper.getRecordWithCasings(obj, this);

            this.jsdo.create(createdRecord);

            this.dhx.setCursor(rid);
          }
          break;
      }
    } catch (e) {
      // remove wait cursor
      this.setWaitCursorForLinks(false);
      akioma.WaitCursor.hideProgressState(this.dynObject);

      this.log.error(e);
      akioma.notification({ type: 'error', text: `There was an error when saving "${this.opt.name}" Entity.` });
    }

    return false;
  }

  /**
   * Method executed when the afterDelete event of the JSDO is getting called
   * @param {JSDO} jsdo
   * @param {object} record
   * @param {boolean} success
   * @param {object} request
   */
  _eventAfterDeleteJSDO(jsdo, record, success, request) {
    const oSelf = this;

    if (success) {
      /* If before-image data is enabled, call getErrorString()
          on the record to identify a defined _errorString property
          value and handle the error if it has one; otherwise,
          do the normal thing for a successful delete operation.
          For example, get the values from the record that was
          deleted to display a confirmation message */
      oSelf.log.info(`Successfully deleted ${record.data._id}`);
      const JSDOrecord = oSelf.dhx.item(oSelf.dhx.getCursor());
      if (JSDOrecord) {
        oSelf.dhx.remove(oSelf.dhx.getCursor());
        oSelf.dc.setUpdated(oSelf.dhx.getCursor(), false);
        oSelf.dhx.setCursor(oSelf.dhx.first());
        const JSDOnextRecord = oSelf.dhx.item(oSelf.dhx.getCursor());
        // go over each observer and trigger keepselection
        for (const i in oSelf.observers) {
          const oObserver = oSelf.observers[i];
          if (oObserver.view.indexOf('datagrid') > -1) {
            oObserver.setKeepSelection({
              identifier: oSelf.opt.identifier,
              value: JSDOnextRecord[oSelf.opt.identifier.toLowerCase()]
            });
            // then it is a grid and we should keep selection
            if (oObserver.view.indexOf('datagrid') > -1)
              oObserver.keepSelection();

          }

        }
      }
      oSelf.jsdo.acceptChanges();
      if (oSelf.dc)
        oSelf.dc.setUpdated(record.data._id, false);
      // check successful message signaling
      if (oSelf.opt.signalSuccessfulSave) {
        const cTextSuccessDelete = akioma.tran('BusinessEntity.successfulDelete', { defaultValue: 'Datensatz gelöscht' });
        akioma.notification({ type: 'success', text: cTextSuccessDelete, expire: 5000 });
      }
    } else {
      const cErrorMsg = request.jsrecord.getErrorString();
      if (cErrorMsg && cErrorMsg.indexOf('SmartMessage') < 0 && cErrorMsg.substr(0, 1) != '_')
        akioma.showServerMessage(cErrorMsg);

      try {
        const oItem = oSelf.dhx.item(record.data._id);
        if (oItem) {
          oSelf.dhx.update(record.data._id, $.extend(oSelf.objectKeysToLowerCase(record.data), { _id: record.getId() }));
          oSelf.dc.setUpdated(record.data._id, false);

        } else {
          oSelf.cancelAfterAdd = true;
          if (this.opt.SUBTYPE !== 'KinveyCollection') {
            const iNewIdx = oSelf._getNextAvailIndex(record);
            oSelf.dhx.addAtIndex($.extend(oSelf.objectKeysToLowerCase(record.data), { _id: record.getId() }), iNewIdx, record.getId());
          }
          oSelf.dc.setUpdated(record.data._id, false);
          oSelf.cancelAfterAdd = false;
        }

        // go over each observer and trigger keepselection
        for (const i in oSelf.observers) {
          const oObserver = oSelf.observers[i];
          // then it is a grid and we should keep selection
          if (oObserver.view.indexOf('datagrid') > -1)
            oObserver.keepSelection();

        }
        oSelf.dhx.setCursor(record.data._id);
      } catch (e) {
        oSelf.log.error(e);
      }

      if (request.response && request.response._errors &&
                        request.response._errors.length > 0) {
        const lenErrors = request.response._errors.length;
        for (let idxError = 0; idxError < lenErrors; idxError++) {
          const errorEntry = request.response._errors[idxError];
          const errorMsg = errorEntry._errorMsg;
          akioma.notification({ type: 'error', text: errorMsg });
          /* handle delete operation error */
        }
      }
    }

    oSelf.bDelete = false;
  }

  /**
   * Method executed when the AfterCreate event on the JSDO is called
   * @param {JSDO} jsdo
   * @param {object} record
   * @param {boolean} success
   * @param {object} request
   */
  _eventAfterCreateJSDO(jsdo, record, success, request) {
    const oSelf = this;

    if (success) {
      oSelf.log.info(`Successfully created ${record.data._id}`);
      /* Call getErrorString() on each record of request.jsrecords to
          identify records returned with a defined _errorString property
          value
          and handle the errors for any records that have one;
          otherwise do the normal thing for a successful submit operation
          */
      oSelf.jsdo.acceptChanges();

      oSelf.bContainsSmartMessage = false;

      oSelf.dhx.update(oSelf.dhx.getCursor(), $.extend(oSelf.objectKeysToLowerCase(record.data), { _id: record.getId() }));

      // check successful message signaling
      if (oSelf.opt.signalSuccessfulSave) {
        const cTextSuccessCreate = akioma.tran('BusinessEntity.successfulCreate', { defaultValue: 'Datensatz wurde angelegt' });
        akioma.notification({ type: 'success', text: cTextSuccessCreate, expire: 5000 });
      }

      // this will fix the id problem after creating
      const oItem = oSelf.dhx.item(oSelf.dhx.getCursor());
      if (oItem.id)
        oSelf.dc.setUpdated(oSelf.dhx.getCursor(), false);

      if (oSelf.dhx.item(oItem.id) == undefined)
        oSelf.dc.setUpdated(oSelf.dhx.getCursor(), false);

      if (oSelf.aCallback['afterCreate']) {
        oSelf.aCallback['afterCreate'](true, oItem);
        delete oSelf.aCallback;
      }

      // oSelf.dhx.setCursor(record.getId());
      // check for tree
      if (oSelf.dataSource && oSelf.dataSource.view == 'treegrid') {
        const oTree = oSelf.dataSource;
        if (oSelf.bTreeRefresh != undefined && oSelf.bTreeRefresh != false) {
          oSelf.bTreeRefresh = false;
          oTree.refreshAllVisibileNodes(() => {
            oSelf.log.info('selected node after loading new tree:', oTree.cNewNodeToSelect);
          });
        }
      }
      oSelf.dc.setUpdated(record.data._id, false);
    } else {
      const cErrorMsg = request.jsrecord.getErrorString();
      if (cErrorMsg && cErrorMsg.indexOf('SmartMessage') < 0 && cErrorMsg.substr(0, 1) != '_')
        akioma.showServerMessage(cErrorMsg);
      else {
        // add to list of records with smartmessage errors
        oSelf.aErrRowsSmartMessages[request.jsrecord.data.id] = request.jsrecord.getId();
        oSelf.aErrRowsStatusSmartMessages[request.jsrecord.data.id] = 'create';

      }
      if (request.response && request.response._errors &&
                        request.response._errors.length > 0) {
        const lenErrors = request.response._errors.length;
        for (let idxError = 0; idxError < lenErrors; idxError++) {
          const errorEntry = request.response._errors[idxError];
          const errorMsg = errorEntry._errorMsg;
          /* handle submit operation error */
          akioma.notification({ type: 'error', text: errorMsg });
        }
      }
    }
  }

  /**
   * Method executed when the afterSaveChanges on JSDO is called
   * @param {JSDO} jsdo
   * @param {boolean} success
   * @param {object} request
   */
  _eventAfterSaveChangesJSDO(jsdo, success, request) {
    const oSelf = this;
    oSelf.setWaitCursorForLinks(false);
    akioma.WaitCursor.hideProgressState(oSelf.dynObject);

    /* number of resource operations invoked by saveChanges() */
    const len = request.batch.operations.length;

    // if failed then ..
    if (!success) {
      /* one or more resource operations invoked by saveChanges() failed */
      for (let idx = 0; idx < len; idx++) {

        const operationEntry = request.batch.operations[idx];

        switch (operationEntry.operation) {
          case 1:
            oSelf.log.info('Operation: Create');
            break;
          case 3:
            oSelf.log.info('Operation: Update');
            break;
          case 4:
            oSelf.log.info('Operation: Delete');
            break;
          default:
            oSelf.log.info(`Operation: Unexpected Code:${operationEntry.operation}`);
        }

        if (!operationEntry.success) { /* handle operation error condition */
          if (operationEntry.response && operationEntry.response._errors && operationEntry.response._errors.length > 0) {
            const lenErrors = operationEntry.response._errors.length;
            for (let idxError = 0; idxError < lenErrors; idxError++) {

              const error = operationEntry.response._errors[idxError];
              const errorMsg = error._errorMsg;
              akioma.showError({ text: errorMsg });
            }
          } else {
            let cUiActions = request.batch.operations[0].jsrecord.data.akUiActions;
            let cErrorMsg = request.batch.operations[0].jsrecord.getErrorString();

            // check in Before request result in case of delete
            if (request.batch.operations[0].fnName == '_delete')
              cUiActions = oSelf._getBeforeRecActions(request.batch.operations[0]);

            // call uiActions
            if (cUiActions != undefined && cUiActions != '') {
              oSelf.oUiActions = JSON.parse(cUiActions);
              oSelf.oLastUiAction = JSON.parse(cUiActions);
              oSelf.callUiActions(request);
              oSelf.callUiAttributes();
            }

            jsdo.rejectChanges();

            if (operationEntry.response && operationEntry.response.error != undefined)
              cErrorMsg = operationEntry.response.error;

            if (cErrorMsg) {
              // if there is at least one SmartMessage
              if (cErrorMsg.indexOf('SmartMessage') >= 0)
                oSelf._showSmartMessageError(cErrorMsg, request, jsdo); // display SmartMessage error in modal
              else if (cErrorMsg.indexOf('SmartMessage') < 0 && cErrorMsg.indexOf('_') != 0) { // if there isn't a SmartMessage

                if (request.batch.operations[0].xhr.status == 500) {
                  akioma.message({
                    type: 'error',
                    text: operationEntry.response.message || '500 Internal Server Error',
                    moretext: operationEntry.response.message ? null : JSON.stringify(operationEntry.response, null, 4)
                  });
                } else {
                  const aErrs = operationEntry.jsdo.getErrors();
                  let errMsg = operationEntry.response.message;

                  // error handling
                  if (errMsg == undefined) {
                    errMsg = '';
                    for (const a in aErrs) {
                      const err = aErrs[a];
                      errMsg += (`${err.error}<br/>`);
                    }
                  }

                  // for any status code
                  akioma.notification({ type: 'error', text: errMsg });
                }

                if (cErrorMsg.indexOf('_CANCEL') > -1)
                  oSelf.bContainsSmartMessage = false;

                for (const i in request.batch.operations) {
                  if (jsdo._defaultTableRef)
                    jsdo._defaultTableRef._processed[request.batch.operations[i].jsrecord.data._id] = null;
                }
              } else if (cErrorMsg.indexOf('_CANCEL') > -1) {
                // clean up akUiActions
                const oelm = oSelf.dhx.item(oSelf.dhx.getCursor());
                if (oelm.akuiactions !== undefined) {
                  oelm.akuiactions = '';
                  oelm.akUiActions = '';

                  oSelf.jsdo[oSelf.opt.entityName].findById(oSelf.dhx.getCursor());
                  const record = request.batch.operations[0].jsrecord;
                  record.data.akUiActions = '';
                }
              } else if (cErrorMsg.indexOf('_QUESTIONS-PENDING') > -1) {
                if (request.batch.operations[0].fnName == '_delete')
                  oSelf.dc.setState(request.batch.operations[0].jsrecord.data._id, 'deleted');
                oSelf.bContainsSmartMessage = true;
              }
            } else {
              const errorInfo = {
                statusCode: operationEntry.xhr.status,
                statusText: operationEntry.xhr.statusText,
                errorText: '',
                type: operationEntry.xhr.type,
                url: jsdo.url,
                extras: [ operationEntry, operationEntry.xhr ]
              };

              akioma.HttpClient.displayErrorMessage(errorInfo, operationEntry.xhr);
            }
          }
        } else {
          /* operation succeeded . . . */
        }
      } /* for each CUD operation */
    } else {

      // remove dirty state from observers
      for (const x in oSelf.observers) {
        const oObserver = oSelf.observers[x];
        if (oObserver.view == 'form')
          oObserver._dispatch('decrementHasChanges', 1);
        else if (oObserver.view == 'datagrid2') {
          const oItem = oSelf.objectKeysToLowerCase(request.batch.operations[0].jsrecord.data);
          const cGenerated = oObserver.computeRowAkIdAttribute(oItem);
          oObserver._commit('REMOVE_CHANGED_ROW', cGenerated);
          if (oObserver.oVuexState.changedRows.length == 0)
            oObserver._dispatch('decrementHasChanges', 1);
        }
      }

      // remove from list of records with smartmessage errors
      const iSmartErrIndex = oSelf.aErrRowsSmartMessages.indexOf(request.batch.operations[0].jsrecord.data.id);
      if (oSelf.aErrRowsSmartMessages.indexOf(request.batch.operations[0].jsrecord.data.id) != -1)
        oSelf.aErrRowsSmartMessages.splice(iSmartErrIndex, 1);

      // Notifications handling
      try {
        const cUiActions = request.batch.operations[0].jsrecord.data.akUiActions;
        if (cUiActions) {
          const oUiActions = JSON.parse(cUiActions);
          if (oUiActions && oUiActions.Notifications) {
            for (const n in oUiActions.Notifications) {
              const notification = oUiActions.Notifications[n];
              const aAnswers = [];

              for (const a in aAnswers)
                aAnswers[a] = akioma.tran(`Modalbox.answer_${aAnswers[a].toLowerCase()}`, { defaultValue: aAnswers[a] });

              // show notifications here
              oSelf._showNotification(notification, request);
            }
          }

          oSelf._cleanupAnsweredQuestions();
        }
      } catch (e) {
        oSelf.log.error(e);
      }
    }

    if (request.batch && request.batch.operations[0] && request.batch.operations[0].operation) {
      if (request.batch.operations[0].operation == 1)
        oSelf.lastRowState = 'add';
      else if (request.batch.operations[0].operation == 3)
        oSelf.lastRowState = 'update';
      else if (request.batch.operations[0].operation == 4)
        oSelf.lastRowState = 'delete';
    }

    // set lastRecord updated
    if (request.batch.operations[0].jsrecord && request.batch.operations[0].jsrecord.data) {
      const record = oSelf.objectKeysToLowerCase(request.batch.operations[0].jsrecord.data);
      oSelf._setLastUpdatedRecord(record);
    }

    // emits Refresh events to RefreshScheme Listeners on successful save
    if (success)
      this.afterSaveChangesEmitRefresh();

    /**
     * Client side code executed after a save operation
     * <br/>
     * @event ak_businessEntity#EventAfterSave
     * @type {object}
     */
    if (oSelf.opt.eventAfterSave) {
      oSelf.saveSuccess = success;
      app.controller.callAkiomaCode(oSelf, oSelf.opt.eventAfterSave);
      delete oSelf.saveSuccess;
    }

    /* operation succeeded call one time eventAfterSave */
    if (success) {
      for (const ev in oSelf._eventAfterSaveChanges) {
        try {
          oSelf._eventAfterSaveChanges[ev](success, jsdo, oSelf.objectKeysToLowerCase(request.batch.operations[0].jsrecord.data));
        } catch (e) {
          akioma.notification({ type: 'error', text: 'Could not call oneTime _eventAfterSaveChanges in businessEntity.' });
        }
      }
      oSelf._eventAfterSaveChanges = [];
    }

    const aCopyFireAfterSave = oSelf._aFireAfterSaveCallbacks.slice();
    // calls events afterSave
    for (const ev in oSelf._aFireAfterSaveCallbacks) {
      const callbackFn = oSelf._aFireAfterSaveCallbacks[ev];

      if (!oSelf.containsSmartMessage())
        aCopyFireAfterSave.splice(ev, 1);

      callbackFn(jsdo, success, request, oSelf.objectKeysToLowerCase(request.batch.operations[0].jsrecord.data));
    }

    oSelf._aFireAfterSaveCallbacks = aCopyFireAfterSave;
    const dataSourceClapi = akioma.swat.SwatFactory.createSwatObject(this);
    akioma.swat.GlobalEmitter.emit(akioma.swat.GlobalHooks.DATASOURCE.AFTER_SAVE_CHANGES, dataSourceClapi);

    try {
      if (success)
        oSelf._publishChanges(); // openQuery on data:targets when saving and cursorChange on itText control for reloading data
    } catch (e) {
      this.log.error(`Error publishing changes in ${this.opt.name}.`);
    }
  }

  /**
   * Clears has changes flag on all linked controls
   * @instace
   * @memberof ak_businessEntity
   */
  clearLinkedHasChanges() {
    const oSelf = this;

    try {
      // remove dirty state from observers
      for (const x in oSelf.observers) {
        const oObserver = oSelf.observers[x];
        if (oObserver.oVuexState
                   && oObserver.oVuexState.attributes
                   && oObserver.oVuexState.attributes.hasChanges) {
          if (oObserver.view == 'form' || oObserver.view == 'docviewer')
            oObserver._dispatch('decrementHasChanges', 1);
          else if (oObserver.view == 'datagrid2') {
            oObserver._commit('CLEAR_CHANGED_ROWS');
            oObserver._dispatch('decrementHasChanges', 1);
          }
        }
      }
    } catch (e) {
      oSelf.log.warn(e);
    }
  }

  /**
   * Checks if there is any observer Grid with keepSelection, that will call the cursorChange event
   * @instance
   * @memberof ak_businessEntity
   * @returns {boolean} True if keepSelection found, false otherwise
   */
  checkObserversWithKeepSelection() {
    try {
      const oSelf = this;
      if (oSelf.dynObject) {
        const ObserversData = oSelf.dynObject.getLinks('DATA:TARGET') || [];
        const ObserversDisplay = oSelf.dynObject.getLinks('DISPLAY:TARGET') || [];
        const Observers = ObserversData.concat(ObserversDisplay);

        for (const o in Observers) {
          if (ObserversData[o]) {
            const obs = ObserversData[o].controller;
            if (obs && obs.view.indexOf('datagrid') > -1 && obs.oKeepSelectionData !== null)
              return true;

          }
        }
      } else
        return false;

    } catch (e) {
      this.log.error(e);
      return false;
    }
    return false;
  }

  /**
   * Call cursor change or just openQuery on publishChanges
   * @memberof ak_businessEntity
   * @instance
   * @private
   * @return {void}
   */
  _publishChanges() {
    const oSelf = this;
    try {
      // call openQuery for all DATA links of type BE
      if (!oSelf.containsSmartMessage() && oSelf.dynObject.controller) {
        const ObserversData = oSelf.dynObject.getLinks('DATA:TARGET');
        const ObserversDisplay = oSelf.dynObject.getLinks('DISPLAY:TARGET');

        for (const o in ObserversData) {
          const obs = ObserversData[o].controller;
          if (obs.view == 'businessEntity' || obs.view == 'businessEntity2')
            obs.openQuery();
        }

        for (const x in ObserversDisplay) {
          const obs = ObserversDisplay[x].controller;
          const itText = obs.getDescendant('ittext');
          if (itText)
            itText.cursorChange();
        }
      }
    } catch (e) {
      oSelf.log.error(e);
    }
  }

  /**
   * Sets last updated record data
   * @private
   * @instance
   * @param {object} oData
   * @memberof ak_businessEntity
   */
  _setLastUpdatedRecord(oData) {
    this.lastUpdatedRecord = oData;
  }

  /**
   * Get last updated record data
   * @instance
   * @param {object} oData
   * @memberof ak_businessEntity
   */
  getLastUpdatedRecord() {
    return this.lastUpdatedRecord;
  }

  /**
   * Method called for loading and displaying the returned SmartMessages after a unsuccessful save.
   * @private
   * @memberof ak_businessEntity
   * @instance
   * @param {string} cErrorMsg Error string for JSDO afterSaveChanges operation
   * @param {object} request
   */
  _showSmartMessageError(cErrorMsg, request, jsdo) {
    const oSelf = this;
    const aErrorSplit = cErrorMsg.split(String.fromCharCode(3));
    const aDefferedObjs = [];
    const aFinalMsg = [];
    const cTitle = '';
    const bModal = akioma.swat.isDefaultMessageBoxStyleModal();

    oSelf.bContainsSmartMessage = true;

    for (const i in aErrorSplit) {
      const cCurrentErrorPart = aErrorSplit[i];

      if (cCurrentErrorPart.indexOf('SmartMessage') == 0) {
        let urlMessageRequest = '';
        const mySplitResult1 = cCurrentErrorPart.split('\t').splice(0, cCurrentErrorPart.split('\t').length - 1);
        const mySplitResult = mySplitResult1.concat(cCurrentErrorPart.split('\t').splice(cCurrentErrorPart.split('\t').length - 1, 1)[0].split(String.fromCharCode(4)));

        if (mySplitResult.length >= 2)
          urlMessageRequest = `${urlMessageRequest}/${mySplitResult[1]}/${mySplitResult[2]}`;

        if (request.batch) {
          for (const i in request.batch.operations) {
            if (jsdo._defaultTableRef)
              jsdo._defaultTableRef._processed[request.batch.operations[i].jsrecord.data._id] = null;
          }
        }

        // return final msg or promise for loading smartmessage
        const result = akioma.SmartMessage.loadSmartMessage(mySplitResult, urlMessageRequest);

        aDefferedObjs.push(result);
      } else if (cCurrentErrorPart.indexOf('_QUESTIONS-PENDING') < 0 && cCurrentErrorPart.indexOf('_CANCEL') < 0)
        aFinalMsg.push({ text: cCurrentErrorPart, modal: bModal });

    }// end go over each error part

    // after all deffered ended get complete message string
    $.when.all(aDefferedObjs).then(aFinalMsg => {
      const onSelect = function() {
        if (request.batch && request.batch.operations.length > 0) {
          const record = request.batch.operations[0].jsrecord;
          oSelf.jsdo[oSelf.opt.entityName].findById(oSelf.dhx.getCursor());
          oSelf.dhx.update(record.data._id, $.extend(oSelf.objectKeysToLowerCase(record.data), { _id: record.getId() }));
          oSelf.dc.setUpdated(record.data._id, false);
        }
        oSelf.callAfterQuestionAnswered();
      };
      const aAnswers = ['Ok'];
      oSelf._afterAllSmartMessagesLoaded(aFinalMsg, request, 'modal', () => {
        onSelect();
      }, aAnswers, cTitle);
    });
  }

  /**
   * Method called after all the SmartMessages are loaded in the afterSaveChanges for displaying the SmartMessages error in modal
   * @instance
   * @memberof ak_businessEntity
   * @private
   * @param {array} aFinalMsg Final list of SmartMessages
   * @param {object} request The request object
   * @param {string} cMessageBoxStyle The message box style, modal/nonmodal
   * @param {function} onSelect The method called when selecting an answer
   * @param {array} aAnswers The possible reply options, optional
   */
  _afterAllSmartMessagesLoaded(aFinalMsg, request, cMessageBoxStyle, onSelect, aAnswers, cQuestionTitle) {
    let cFinalMsg = '';
    let bModal = (cMessageBoxStyle.toLowerCase() == 'modal');
    let messageType = 'information';

    for (const i in aFinalMsg) {
      bModal = aFinalMsg[i].modal;
      messageType = aFinalMsg[i].type;
    }

    if (bModal) {
      const oButtons = { };
      for (const i in aFinalMsg)
        cFinalMsg += (`${aFinalMsg[i].text}<br />`);

      for (const a in aAnswers) {
        const answer = aAnswers[a];
        switch (answer) {
          case 'No':
            oButtons.replyno = {
              text: akioma.tran(`Modalbox.answer_${aAnswers[a].toLowerCase()}`, { defaultValue: aAnswers[a] }),
              value: 'ReplyNo',
              visible: true,
              closeModal: true
            };
            break;
          case 'Yes':
            oButtons.replyyes = {
              text: akioma.tran(`Modalbox.answer_${aAnswers[a].toLowerCase()}`, { defaultValue: aAnswers[a] }),
              value: 'ReplyYes',
              visible: true,
              closeModal: true
            };
            break;
          case 'Ok':
            oButtons.replyok = {
              text: akioma.tran(`Modalbox.answer_${aAnswers[a].toLowerCase()}`, { defaultValue: aAnswers[a] }),
              value: 'ReplyOk',
              visible: true,
              closeModal: true
            };
            break;
          case 'Cancel':
            oButtons.replycancel = {
              text: akioma.tran(`Modalbox.answer_${aAnswers[a].toLowerCase()}`, { defaultValue: aAnswers[a] }),
              value: 'ReplyCancel',
              visible: true,
              closeModal: true
            };
            break;
        }
      }

      akioma.message({
        type: messageType,
        title: cQuestionTitle,
        text: cFinalMsg,
        buttons: oButtons,
        callback: function(cSelected) {
          if (onSelect) {
            // cSelected = (cSelected == true) ? 0 : 1;
            onSelect(cSelected);
          }
        }
      });

    } else {
      for (const i in aFinalMsg) {
        cFinalMsg += (`${aFinalMsg[i].text}\n`);
        cFinalMsg = cFinalMsg.split('<br />').join('\n');
      }
      switch (messageType) {
        case 'information':
        case 'question':
          akioma.notification({ type: 'info', text: cFinalMsg });
          break;
        case 'error':
          akioma.notification({ type: 'error', text: cFinalMsg });
          break;
        case 'warning':
          akioma.notification({ type: 'warning', text: cFinalMsg });
      }

    }
  }

  /**
   * Method executed on an afterUpdate event on the JSDO
   * @param {JSDO} jsdo
   * @param {object} record
   * @param {boolean} success
   * @param {object} request
   */
  _eventAfterUpdateJSDO(jsdo, record, success, request) {
    const oSelf = this;

    if (success) {
      if (oSelf.dhx == null) {
        // user might have canceled operation/ no datastore available
        oSelf.log.warn('dataStore can not be updated', oSelf);
        return false;
      }

      oSelf.jsdo.acceptChanges();

      oSelf.bContainsSmartMessage = false;

      oSelf.dhx.update(record.getId(), $.extend(oSelf.objectKeysToLowerCase(record.data), { _id: record.getId() }));

      oSelf.log.info(`Successfully updated ${record.data._id}`);

      if (oSelf.dc.updatedRows[record.data._id] != undefined && oSelf.dc.updatedRows.indexOf(record.data._id) > 0) {
        oSelf.dc.setUpdated(record.getId(), false);
        oSelf.dc.setUpdated(record.data._id, false);
      } else
        oSelf.dc.setUpdated(record.getId(), false);

      // check successful message signaling
      if (oSelf.opt.signalSuccessfulSave) {
        const cTextSuccessSave = akioma.tran('BusinessEntity.successfulSave', { defaultValue: 'Datensatz erfolgreich gespeichert' });
        akioma.notification({ type: 'success', text: cTextSuccessSave, expire: 5000 });
      }

      // cleanup business entity ui validation
      if (oSelf.oUiActions)
        delete oSelf.oUiActions;

      if (oSelf.dataSource && oSelf.dataSource.view == 'treegrid') {
        const oTree = oSelf.dataSource;
        oTree.refreshAllVisibileNodes(() => {
          if (oTree.bSkipSelect != undefined && !oTree.bSkipSelect) {
            oTree.dhx.selectRowById(oTree.cNewNodeToSelect, false, true, false);
            oSelf.log.info('selected node after loading new tree:', oTree.cNewNodeToSelect);
            delete oTree.cNewNodeToSelect;
          }
        });
      }
    } else {
      const cErrorMsg = request.jsrecord.getErrorString();

      // display normal errors if not SmartMessage or uiQuestions
      if (cErrorMsg && cErrorMsg.indexOf('SmartMessage') < 0) {
        if (cErrorMsg.substr(0, 1) != '_') { // then we need to display error if not starting with underscore
          // update operation error
          oSelf.bContainsSmartMessage = false;
          akioma.showServerMessage(cErrorMsg);
          for (const i in request.batch.operations) {
            if (jsdo._defaultTableRef)
              jsdo._defaultTableRef._processed[request.batch.operations[i].jsrecord.data._id] = null;
          }
          record.rejectRowChanges();
        } else
          oSelf.bContainsSmartMessage = true;

      }
    }
  }

  /**
   * Publishes the dataAvailable and cursorChange events
   * @param {string} cNew The DataStore cursor
   * @memberof ak_businessEntity
   * @instance
   * @private
   * @returns {void}
   */
  _publishDataAvail() {
    const oSelf = this;
    if (oSelf.parent) {
      oSelf.setTitle();

      const cObjectID = oSelf.opt.name + oSelf.opt.linkid;
      if (akioma.eventEmitter)
        akioma.eventEmitter.emit([ 'DISPLAY', 'TRG', cObjectID ], { elm: oSelf });

      // event onDataAvail
      if (oSelf.opt.onDataAvail)
        applyAkiomaCode(oSelf, oSelf.opt.onDataAvail);

      // tell all observers that cursor has been changed
      app.messenger.publish({
        link: {
          LinkName: 'DISPLAY',
          LinkType: 'TRG',
          ObjName: cObjectID
        },
        method: 'cursorChange',
        caller: oSelf
      });
      // tell all observers that cursor has been changed
      app.messenger.publish({
        link: {
          LinkName: 'DATA',
          LinkType: 'TRG',
          ObjName: cObjectID
        },
        method: 'dataAvailable',
        caller: oSelf
      });
    }

    this.callRules({
      eventEntity: oSelf.opt.name,
      eventName: 'onDataAvailable',
      eventSource: ''
    });
  }

  /**
   * Method for calling rules
   * @private
   * @memberof ak_businessEntity
   * @param {Object) evt The event object.
   */
  callRules(evt) {

    // dynSelect business entities have no dynObject
    if (!this.dynObject)
      return;

    const screen = this.dynObject.directRuleScreen;
    if (!screen) return;

    Corticon.callRules(screen, evt);
  }

  /**
   * Calls the noDataAvailable event on the form or calls the respective callNoDataAvailEvent method on the BusinessEntity
   * @memberOf ak_businessEntity
   * @return {void}
   * @instance
   * @private
   */
  _callNoDataAvailEvent() {
    const oSelf = this;
    let aAllLinks = [];
    const aDisplayTargets = oSelf.dynObject.getLinks('DISPLAY:TARGET') || [];
    const aDataTargets = oSelf.dynObject.getLinks('DATA:TARGET') || [];
    const aNavSrcs = oSelf.dynObject.getLinks('NAVIGATION:SRC') || [];

    aAllLinks = aDisplayTargets.concat(aDataTargets, aNavSrcs);

    for (const o in aAllLinks) {
      const obs = aAllLinks[o].controller;
      if (obs.view === 'form')
        akioma.eventEmitter.emit([ 'noDataAvailable', obs.opt.id ]);

    }

    akioma.WaitCursor.hideProgressState(oSelf.dynObject);

  }

  /**
   * Get Next available index of a JSDO Record
   * @param  {object} record The jsdo record
   * @private
   * @memberOf ak_businessEntity
   * @instance
   * @return {void}
   */
  _getNextAvailIndex(record) {
    const oSelf = this;
    const cCurrId = record.getId();
    const aProp = cCurrId.split('-');
    aProp[1] = `${parseInt(aProp[1]) - 1}`;
    const cPrevId = aProp.join('-');
    const oPrevInx = oSelf.jsdo[oSelf.opt.entityName]._index[cPrevId];
    let iNewIdx = 0;
    if (oPrevInx)
      iNewIdx = oPrevInx.index + 1;

    return iNewIdx;
  }

  /**
   * Get before JSDO Record akUiActions field value for SmartMessage questions display
   * @param  {object} operation The jsdo operation record
   * @private
   * @memberOf ak_businessEntity
   * @instance
   * @return {object}
   */
  _getBeforeRecActions(operation) {
    const oSelf = this;
    try {
      return operation.response[operation.jsdo._dataSetName]['prods:before'][oSelf.entityName][0].akUiActions;
    } catch (e) {
      return null;
    }
  }

  /**
   * Returns the last state of a row, after a save it gets updated
   * @instance
   * @memberOf ak_businessEntity
   * @return {string}
   */
  getRowState() {
    return this.lastRowState;
  }

  /**
   * Checks if it contains a smartmessage in the previous record saving. Useful when refreshing using akioma.eventEmitter <br/>
   * for eg. when refreshing data when a record has been saved on a different business entity
   * @example
   * akioma.osiv.adressverbindung.RefreshQuery = function(oDSO) {
   *
   *       // if it does not contain a SmartMessage then refresh eAdressverbindungView
   *       if(!oDSO.controller.containsSmartMessage())
   *           akioma.eventEmitter.emit(["dataChanged", 'eAdressverbindungView']);
   *
   * };
   *
   * @memberOf ak_businessEntity
   * @return {boolean}
   */
  containsSmartMessage() {
    return this.bContainsSmartMessage;
  }

  /**
   * Reset bAfterDisplayCalledOnce flag after cursor change
   * @private
   * @memberof ak_businessEntity
   */
  _resetCallAfterDisplay() {
    try {
      for (const key in this.observers) {
        const oControl = this.observers[key];
        if (oControl.view == 'form')
          oControl.bAfterDisplayCalledOnce = false;

      }
    } catch (e) {
      this.log.warn(e);
    }
  }

  // DEPRECATED
  promptCursorChange(id) {
    const deferred = $.Deferred();
    const oWin = this.dynObject.container.controller;
    const oSelf = this;

    if (oWin.oVuexState.attributes.hasChanges) {
      this.promptDiscardHasChangesDialog().then(result => {
        if (result) {
          // continue setCursor ..
          oSelf.dhx.setCursor(id);
        } else {
          // go over each observer and trigger keepselection
          for (const i in oSelf.observers) {
            const oObserver = oSelf.observers[i];
            // then it is a grid and we should keep selection
            if (oObserver.view.indexOf('datagrid') > -1)
              oObserver.dhx.selectRowById(oSelf.dhx.getCursor(), true, false, true);

          }
          // stop cursor change, probably need to revert grid row/ keepSelection
          return;
        }
      });
    } else {
      // oWin.close();
      deferred.resolve();
    }
    return deferred.promise();
  }

  getCorrectSchemaBasedItem(items) {
    const data = {};
    const oSchema = this.jsdo.getSchema();
    const aSchemaMap = {};

    for (const i in oSchema) {
      if (oSchema[i].name.substr(0, 2) != '_e')
        aSchemaMap[oSchema[i].name.toLowerCase()] = oSchema[i].name;
    }

    for (const a in items) {
      if (aSchemaMap)
        data[aSchemaMap[a.toLowerCase()]] = items[a];
      else if (a != undefined)
        data[a] = items[a];
    }

    return data;
  }

  /**
   * Method called on After Catalog Add, depending on store adapter
   * @param {object} res
   * @instance
   * @private
   * @memberof ak_businessEntity
   */
  _afterCatalogAddAdapterHandler(res) {
    const oSelf = this;
    const subType = this.opt.SUBTYPE;
    let bErrorCatalogLoaded = false;

    switch (subType) {
      case 'KinveyCollection':
      case 'ElasticSearchCollection':
      case 'MockupCollection':
        break;
      default: {
        const { jsdosession, result, info } = res;
        const bError = (result != progress.data.Session.SUCCESS);

        if (bError) {
          // ignore catalog already loaded error
          const errorObj = info.errorObject;
          if (errorObj && errorObj.message.startsWith('CATALOGFAILJSDO')) {
            oSelf.log.debug('catalogAlreadyLoaded', jsdosession, result, info);
            bErrorCatalogLoaded = true;
          }
        }

        oSelf.log.debug('catalogLoaded', jsdosession, result, info);

        if (oSelf.jsdo == undefined && (!bError || bErrorCatalogLoaded))
          oSelf.initializeJSDO();

        // read the SmartRestrictions from Catalog
        try {
          let oService;

          // catalog already loaded
          if (info == null) {
            const oMobileCatalogService = _.find(akioma.restSession.services, { name: `web-${oSelf.opt.resourceName}` });
            oService = progress.data.ServicesManager.getService(oMobileCatalogService.name);
          } else { // catalog just loaded
            const cRes = info.xhr.responseText;
            const oRes = JSON.parse(cRes);
            oService = oRes.services[0];
          }
          const oSmartRestrictions = oService.resources[0].smartRestrictions;
          if (oSmartRestrictions)
            oSelf.setSmartRestrictions(oSmartRestrictions);

        } catch (e) {
          oSelf.log.warn(e);
        }

        // check for catalog add errors
        if (bError && !bErrorCatalogLoaded) {

          if (info.result === progress.data.Session.AUTHENTICATION_FAILURE)
            akioma.NotificationMessage.showError({ text: `Authentication error: <br/>${info.catalogURI}` });
          else if (info.result === progress.data.Session.GENERAL_FAILURE)
            akioma.NotificationMessage.showError({ text: `General Catalog load error: <br/>${info.errorObject.message}` }); // disabled because of JSDO bug with saved catalogs in cache services error
          else
            akioma.NotificationMessage.showError({ text: `Catalog load error: <br/>${oSelf.opt.resourceName}` });


          const detailsObj = info;
          const detailsXhr = detailsObj.xhr;

          if (detailsObj && detailsObj.code === undefined)
            akioma.handleHttpStatus(detailsXhr, detailsObj.errorObject.message);

        }
      }

    }
  }

  // on after catalog Add method
  onAfterCatalogAdd(res) {
    const oSelf = this;

    // check for error type object
    if (akioma.isObjOfTypeError(res)) {
      akioma.log.error(res);
      return;
    }

    this._afterCatalogAddAdapterHandler(res);

    if (oSelf.callFinishConstruct) {
      delete oSelf.callFinishConstruct;
      if (oSelf.continueFinConstr)
        app.controller.parseProc(oSelf.continueFinConstr.data, oSelf.continueFinConstr.parent);
      else
        oSelf.finishConstruct();

      if (oSelf._eventAfterCatalogAdd) {
        for (const i in oSelf._eventAfterCatalogAdd)
          oSelf._eventAfterCatalogAdd[i]();

        oSelf._eventAfterCatalogAdd = [];
      }
    }

    // stops datasource link from publishing a query request on the DATA:TARGET if already loaded
    // and from the same container

    let bDataSourceOfDataSource = false;
    if (oSelf.dynObject) {
      const oPar = oSelf.dynObject.getFirstParentByType('tab', 'window');

      const aDataSrcs = oSelf.dynObject.getLinks('DATA:SRC') || [];

      for (const i in aDataSrcs) {
        const obs = aDataSrcs[i];

        if (obs.type == 'businessEntity') {
          const oObsPar = obs.getFirstParentByType('tab', 'window');

          if (oObsPar == oPar && (oObsPar !== null && (oObsPar.view !== 'tab' || !oObsPar.bIsLoaded)))
            bDataSourceOfDataSource = true;

          break;
        }
      }
    }

    if (oSelf.opt.initialFetch !== '#none' && !bDataSourceOfDataSource) {
      oSelf.openQuery(oSelf.oQueryParam);
      delete oSelf.oQueryParam;
    } else
      oSelf.bInitialFetchedReq = true;


    if (oSelf.callOpenQuery && oSelf.opt.autoQuery == undefined && !oSelf.bInitialFetchedReq) {
      oSelf.openQuery(oSelf.callOpenQuery.elm, oSelf.callOpenQuery.callback);
      oSelf.callOpenQuery = false;
    }
  }

  /**
   * Checks if any of the linked controls have changes, recursively.
   *
   * @private
   * @memberof BaseDataSource
   * @param {Array<BaseDataSource>} [visitedBEs=[]] Visited business entities.
   * @returns {boolean}
   */
  _recursiveHasChanges(visitedBEs = []) {
    if (visitedBEs.includes(this)) return false;
    visitedBEs.push(this);

    if (isNull(this.dynObject)) return false;

    // go over each linked obeserver and check has changes
    const ObserversData = this.dynObject.getLinks('DATA:TARGET') || [];
    const ObserversDisplay = this.dynObject.getLinks('DISPLAY:TARGET') || [];
    const aAllLinks = ObserversData.concat(ObserversDisplay);

    for (const link in aAllLinks) {
      const obs = aAllLinks[link].controller;

      if (obs.registerVuexModule === true && obs._getters('hasChanges') && !obs.view.startsWith('datagrid'))
        return true;

      if (obs.view.startsWith('businessEntity')) {
        if (obs._recursiveHasChanges(visitedBEs))
          return true;
      }
    }

    return false;
  }

  /**
   * Set Smart Restrictions from catalog data (used for visibility rules)
   * @param {object} oSmartRestrictions
   * @memberof ak_businessEntity
   * @instance
   * @private
   */
  setSmartRestrictions(oSmartRestrictions) {
    const oSelf = this;
    oSelf.oSmartRestrictions = oSmartRestrictions;
  }

  /**
   * Returns the SecurityRestrictons defined in Catalog, if any
   * @memberof ak_businessEntity
   * @instance
   */
  getSmartRestrictions() {
    const oSelf = this;
    return oSelf.oSmartRestrictions;
  }

  /**
   * Method used for setting the foreign key fields for the businessEntity that will be passed in the request payload of GetInitialValues
   * @param {array} aKeys The list of foreign key fields with values
   * @instance
   * @memberOf ak_businessEntity
   * @returns {void}
   */
  setForeignKeys(aKeys) {
    this.foreignKeyFields = aKeys;
  }

  /**
   * Method for adding an event before fill of the JSDO, executes only once
   * @params {Function} Callback
   * @memberof ak_businessEntity
   * @instance
   * @returns {void}
   */
  addBeforeFillOnceCallback(fn) {
    this.aBeforeFillOnce.push(fn);
  }

  /**
   * Method for cleaning a given callback after fill once event of the JSDO
   * @params {Function} Callback
   * @memberof ak_businessEntity
   * @instance
   * @returns {void}
   */
  cleanAfterFillOnceCallback(fn) {
    const indexToRemove = this.aAfterFillOnce.indexOf(fn);
    if (indexToRemove > -1)
      this.aAfterFillOnce.splice(indexToRemove, 1);
  }

  /**
   * Method for cleaning all the after fill once events
   * @params {Function} Callback
   * @memberof ak_businessEntity
   * @instance
   * @returns {void}
   */
  cleanAfterFillOnce() {
    this.aAfterFillOnce = [];
  }

  /**
   * Method for adding an after fill event of the JSDO
   * @params {Function} Callback
   * @memberof ak_businessEntity
   * @instance
   * @returns {void}
   */
  addAfterFillCallback(fn) {
    this.aAfterFill.push(fn);
  }

  // record description
  getRecordDescription(cTemplate) {
    if (!cTemplate)
      return null;
    let result = '';
    const template = Handlebars.compile(cTemplate);
    const oData = this.getSelectedRecord();

    if (oData) {
      // oData = this.objectKeysToLowerCase(this.jsdo[this.opt.entityName].record.data);
      if (!$.isEmptyObject(oData))
        result = template(oData);

    } else
      result = template({});

    return result;
  }

  /**
   * Method for getting the field from store id
   * @param  {string} cFieldName The name of the field
   * @param  {string} id         The ID of the dhx store
   * @private
   * @memberOf ak_businessEntity
   * @return {string}
   */
  getFieldFromId(cFieldName, id) {
    if (id && this.dhx.item(id))
      return this.dhx.item(id)[cFieldName];
    else
      return '';
  }

  /**
   * Method to return store id from given field with given value, used for keepSleection in grid
   * @param  {string} cFieldName  The field name
   * @param  {string} cFieldValue The field value
   * @memberOf ak_businessEntity
   * @return {void}
   */
  getIdFrom(cFieldName, cFieldValue) {
    const items = this.dhx.data.pull;

    for (const i in items) {
      if (cFieldValue == items[i][cFieldName] || cFieldValue == items[i][cFieldName.toLowerCase()])
        return i;

    }
  }

  /**
   * Method to return the locally stored data found within the DataStore.
   * @memberOf ak_businessEntity
   * @return {Object}
   */
  getData() {
    return this.dhx.data.pull;
  }

  /**
   * Method for removing NamedQuery by name
   * @param {string} name Name of namedQuery
   * @returns {void}
   */
  removeNamedQueryParam(name) {
    if (this.oNamedQuery && this.oNamedQuery.name == name) {
      this.oNamedQuery = null;
      this.urlQuery.NamedQuery = null;
    }
  }

  /** Method callsed on question reply from user
   * @private
   * @instance
   * @membeof ak_businessEntity
   */
  callAfterQuestionAnswered() {
    const oSelf = this;
    if (oSelf.opt.afterQuestionAnswered)
      applyAkiomaCode(oSelf, oSelf.opt.afterQuestionAnswered);
  }

  /**
   * Method for setting a value in temp store
   * @instance
   * @memberof ak_businessEntity
   * @param {string} fieldName The name of the field to store in temp Store
   * @param {any} value The value to assign to this field
   * @returns {void}
   */
  setTempValue(fieldName, value) {
    this.tempStore[fieldName] = value;
  }

  /**
   * Method for reading field values from temp store
   * @instance
   * @memberof ak_businessEntity
   * @param {string} fieldName The name of the field to return the value from
   * @returns {any}
    */
  getTempValue(fieldName) {
    return this.tempStore[fieldName];
  }

  /**
   * Method for clearing the temp store
   * @instance
   * @memberof ak_businessEntity
   * @returns {void}
   */
  clearTempStore() {
    this.tempStore = {};
  }

  /**
   * Sets refresh validation based on entityNames
   * @memberof ak_businessEntity
   * @instance
   * @param {string}  operation Can be the "add", "remove", "update"
   * @param {array}   aEntityNames List of entity names
   * @returns {void}
   */
  setRefreshSkip(operation, aEntityNames) {
    this.refreshSkipRules[operation] = aEntityNames;
  }

  /**
   * Checks for refresh skip
   * @param {string} operation Operation
   * @param {array} aEntityNames List of entityNames to be skipped
   * @instance
   * @memberof ak_businessEntity
   * @returns {boolean}
   */
  checkRefreshSkip() {
    let skipCurrentQuery = false;

    // if there is a dataSource we should check the schema for containing BE names
    if (this.dataSource) {
      const parentRefreshRules = this.dataSource.refreshSkipRules;
      const operation = this.dataSource.lastSourceRowState;

      // if undefined then
      if (operation === undefined)
        return skipCurrentQuery;

      const currentOperationRefreshRules = parentRefreshRules[operation];

      if (!currentOperationRefreshRules)
        return skipCurrentQuery;

      const aRefreshEntityNames = currentOperationRefreshRules.split(',');
      const ignoreAll = aRefreshEntityNames.indexOf('*') > -1;

      if (!ignoreAll) {
        skipCurrentQuery = (aRefreshEntityNames.indexOf(this.opt.entityName) !== -1);

        // check for startsWith patterns
        const wildCardEntityNameEntries = aRefreshEntityNames.filter(entity => entity.length > 0 && entity.endsWith('*')).map(entity => entity.replace('*', ''));
        // check wildcard and matches entityName, skip Query
        wildCardEntityNameEntries.forEach(startTxt => {
          if (this.opt.entityName.startsWith(startTxt))
            skipCurrentQuery = true;
        });
      } else
        skipCurrentQuery = ignoreAll; // skip Query, '*' found so ignore them all
    }

    return skipCurrentQuery;
  }

  /**
   * Method called when publishing data link changes
   * @memberof ak_businessEntity
   * @instance
   * @returns {void}
   */
  dataAvailable() {
    const skipCurrentQuery = this.checkRefreshSkip();

    // if not skipped from source BE then fetch data
    // skipped only if RefreshScheme of the parent contains
    if (!skipCurrentQuery)
      this.openQuery({});
  }

  setTitle() {
    // if we are primarySDO -> change title
    if (this.opt.primarySDO) {

      // try to get key
      let cKey = this.getFieldValue('selfno');
      let cDesc = this.getFieldValue('selfdesc');
      if (!cKey)
        cKey = this.getFieldValue('selfkey');

      if (!cKey)
        cKey = this.getFieldValue('artikel-nr');

      // MLi: hardcoded check for osiv
      if (!cKey)
        cKey = this.getFieldValue('stamm_id');

      if (!cDesc)
        cDesc = this.getFieldValue('fulldesc');

      // MLi: hardcoded check for osiv
      if (!cDesc)
        cDesc = `${this.getFieldValue('vorname') || ''} ${this.getFieldValue('nachname') || ''}`;

      // set tile in window
      const oWindow = this.getAncestor('window,frame');
      if (oWindow && oWindow.view == 'window') {
        let cWindowTitle = '';
        if (cKey)
          cWindowTitle += `${cKey} - `;
        if (cDesc)
          cWindowTitle += cDesc;

        oWindow.setTitle(cWindowTitle);

        // check security
        const cProp = this.getFieldValue('rowuserprop');
        oWindow.callInChildren('setProperty', { security: { readOnly: (cProp == 'READ-ONLY') } });

      }
    }
  }

  /**
   * Sets the foreignKey filters in the businessEntity for filtering
   * @private
   * @instance
   * @memberOf ak_businessEntity
   * @returns {boolean} Stops the openQuery request if returns true otherwise returns false
   */
  setForeignKeyFilter() {
    const aKeyValues = this.opt.foreignKey.split(',');
    const aExtKeys = this.opt.extKey.split(',');

    for (const i in aKeyValues) {
      const cKeyField = aKeyValues[i];
      const cKeyExt = aExtKeys[i];
      const aForeignKeyData = cKeyField.split('.');
      const cForeignKeyField = (aForeignKeyData[1] || aForeignKeyData[0]);
      const oQuery = this.getQuery();
      const isPendingQueryRequest = this.dataSource._pendingrequest;

      if (isPendingQueryRequest) {
        this.dataSource.addAfterFillOnceCallback(() => {
          oQuery.addUniqueCondition(cForeignKeyField, 'eq', this.dataSource.getFieldValue(cKeyExt));
          this.openQuery({});
        });
        return true;
      } else
        oQuery.addUniqueCondition(cForeignKeyField, 'eq', this.dataSource.getFieldValue(cKeyExt));
    }

    return false;
  }

  /**
   * Check if panel header filters are loading
   * @instance
   * @memberOf ak_businessEntity
   */
  checkFilterPanelHdr() {
    try {
      const filter = this.observers.find(obs => obs.view == 'datagrid2');
      if (!filter)
        return false;

      const filterPromise = filter.filtersLoader;
      if (filterPromise && filterPromise.state() !== 'resolved')
        return true;

    } catch (e) {
      this.log.warn(e);
    }

    return false;
  }

  /**
   * Apply filters on future queries on the businessEntity
   * @memberOf ak_businessEntity
   * @instance
   * @param {boolean} bApply
   */
  applyAllQueryFilters(bApply) {
    const oSelf = this;
    oSelf.bApplyQueryFilters = bApply;
  }

  /**
   * Method handling repositionTo and repositionToKey data for openQuery
   * @param {object} oElm openQuery params
   * @returns {void}
   */
  applyRepositionTo(oElm) {
    if (oElm.repositionTo) {
      const repositionToKey = (oElm.repositionToKey || this.opt.identifier).toLowerCase();

      if (oElm.repositionTo == '#NextRow') {
        oElm.repositionTo = this.getNext(true) || this.getPrevious(true);
        this.log.info('Reposition to : ', oElm.repositionTo);
        if (oElm.repositionTo !== '')
          this.setNext() || this.setPrevious();
      }

      if (oElm.repositionTo !== '') {
        this.forEachGridObservable(this.observers, oObserver => {
          oObserver.setKeepSelection({
            identifier: repositionToKey,
            value: String(oElm.repositionTo)
          });
        });
      }
    }
  }

  /**
   * Method to return promise of grid panel header filters
   * @instance
   * @memberOf ak_businessEntity
   */
  getGridFilterHdrPromise() {

    return new Promise((resolve, reject) => {
      const filter = this.observers.find(obs => obs.view == 'datagrid2');
      if (!filter)
        resolve();

      const filterPromise = filter.filtersLoader;

      filterPromise.done(() => {
        resolve();
      }).fail(() => {
        reject();
      });
    });
  }

  /**
   * Promise for after loading default filter in default manager via panel header menu
   * @returns {Promise}
   * @instance
   * @memberof ak_businessEntity
   */
  getDefaultFilterPromise() {
    return new Promise(resolve => {
      if (isNull(this.jsdo)) {
        this.addAfterCatalogAdd(() => {
          this.getGridFilterHdrPromise().then(resolve).catch(resolve);
        });
      } else
        this.getGridFilterHdrPromise().then(resolve).catch(resolve);
    });
  }

  /**
   * Method for clearing data from the dataStore and clearing the linked forms data
   * @instance
   * @memberof ak_businessEntity
   */
  clearAllData() {
    try {
      this.getStore(this.entityName).clearAll();
      if (this.dynObject) {
        const dataTargetLinks = this.dynObject.getLinks('DATA:TARGET') || [];
        const displayTargetLinks = this.dynObject.getLinks('DISPLAY:TARGET') || [];
        const formDataTargetLinks = dataTargetLinks.filter(repoObject => repoObject.type === 'form');
        const formDisplayTargetLinks = displayTargetLinks.filter(repoObject => repoObject.type === 'form');
        const targetFormLinks = formDataTargetLinks.concat(formDisplayTargetLinks);
        targetFormLinks.forEach(form => form.controller.dhx.clear());
      }
    } catch (e) {
      akioma.log.error(e);
    }
  }

  /**
   * Method used for filtering on the JSDO
   * @param  {Object}   oElm     Query options
   * @param  {string}   oElm.repositionTo  Handle value used to position a record in Grid. Can be a comma-separated list of handles when using a multiselect Grid.
   * <br> If set to '#NextRow', it will reposition to the next record.
   * @param {boolean} oElm.filterSkip Handles skipping check for filters of the BE from Grid
   * @param {string}  oElm.repositionToKey Handles field used to position a record. default is selfhdl
   * @param {integer} oElm.mergeMode Handles different types of JSDO fill operations. </br>
   * Available merge modes:
   * progress.data.JSDO.MODE_APPEND = 1
   * progress.data.JSDO.MODE_EMPTY = 2
   * progress.data.JSDO.MODE_MERGE = 3
   * progress.data.JSDO.MODE_REPLACE = 4
   * @param  {Function} callback Method called afterFill event
   * @instance
   * @memberOf ak_businessEntity
   */
  openQuery(oElm, callback) {
    const oSelf = this,
      oData = this.dhx,
      deferred = $.Deferred();
    let oFillPromise = null;

    if (isNull(oElm))
      oElm = {};

    if (isNull(this.jsdo)) {
      this.addAfterCatalogAdd(() => {
        this.setQueryParam(oElm);
        this.openQuery(oElm, callback);
      });
    } else {

      if (oSelf.stop)
        return false;

      // no need to reset the data loaded flag to false if query will never be open.
      // there are also cases where the the get initial values is used and the open query is called with the stop set to true.
      oSelf._rulesDataLoaded = false;

      const bFilterWait = oSelf.checkFilterPanelHdr();

      if (bFilterWait && oElm.filterSkip !== true)
        return false;

      if (isNull(oElm.mergeMode) && oSelf.batchMode) {

        this._oldBatchSize = JSON.parse(JSON.stringify(oSelf.batchSize));

        const iSkip = 0;
        const iBatchSize = oSelf.batchSize.skip + oSelf.batchSize.top;

        oSelf.setBatch(iSkip, iBatchSize);
        oSelf.addAfterFillOnceCallback(() => {
          oSelf.setBatch(this._oldBatchSize.skip, this._oldBatchSize.top);
        });
      }

      // skip progress state if foreignKey is present
      if (oSelf.opt.foreignKey != '' && oSelf.opt.foreignKey != undefined)
        oElm.skipProgress = true;

      // set progress state on, for data and display targets
      if (this.hasCancelRequestCursor())
        oSelf.setWaitCursorForLinks(true, true);
      else
        oSelf.setWaitCursorForLinks(true);

      if (oSelf.dynObject) {
        const filterSource = oSelf.dynObject.getLink('FILTER:SOURCE');
        if (filterSource)
          filterSource.controller.FilterGo();
      }

      // allow batching for more records
      oSelf.hasRecordsToBatch = true;

      // in case of refresh fetch set the lastSourceRowState
      if (oElm.RefreshFetch)
        oSelf.lastSourceRowState = oElm.lastSourceRowState;

      // reposition to logic
      this.applyRepositionTo(oElm);

      // rendering filters
      this.forEachGridObservable(oSelf.observers, oObserver => {
        oObserver._renderMultiSelectStateAfterFill();
      });

      // clear datasource
      if (oElm.skipClear == undefined)
        this.clearAllData();

      if (this.openQueryTimeout)
        clearTimeout(this.openQueryTimeout);

      this._pendingrequest = true;

      this.openQueryTimeout = setTimeout(() => {

        if (isNull(oSelf.jsdo)) // object already destroyed
          return;

        if (oSelf.jsdo.fillxhr)
          oSelf.jsdo.fillxhr.abort();

        if (oSelf.opt.fieldlist) {
          const query = oSelf.getQuery();
          query.setFieldList(oSelf.opt.fieldlist);
        }

        /**
         * Client side code executed before a data fetch request.
         * <br/>
         * <strong>Example #SWAT-1390</strong> <br/>
         * <a href="/sports-webui/docs/Customer_setSortingCustomer.js.html" target="_blank">Custom Sorting Based on Filter Value Example</a><br>
         * This sample can be tested in the Customer Details window, in the City column filter by 'Los Angeles'
         * @event ak_businessEntity#EventBeforeFetch
         * @type {object}
         */
        if (oSelf.opt.onBeforeFetch) {
          const eventBeforeFetchResult = callReturnAkiomaCode(oSelf, oSelf.opt.onBeforeFetch);
          if (eventBeforeFetchResult && eventBeforeFetchResult.preventQuery) {
            this._pendingrequest = false;
            return;
          }
        }

        oSelf.log.debug('DataSource openQuery call on ', oSelf.opt.name);

        // check default sorting
        if (oSelf.opt.defaultSort && oSelf.query.aSorting == undefined) {

          const cSort = oSelf.opt.defaultSort;

          oSelf.query.setStringSorting(cSort);
        }
        oSelf.query.buildQuery();

        if (oElm.extLink)
          oSelf.query.addUniqueCondition('selfhdl', 'eq', oElm.extLink);

        // copy urlquery
        let oPar = oSelf.getUrlQuery();

        let bStopQuery = false;
        // check for specials
        if (oElm.foreignKey) {
          let aForeignKeys = [];
          if (oSelf.opt.foreignKey)
            aForeignKeys = oSelf.opt.foreignKey.split(',');
          const aForeignKeyData = aForeignKeys.length > 0 ? aForeignKeys[0].split('.') : [];
          const cForeignKeyField = aForeignKeyData[1] || aForeignKeyData[0] || 'refhdl';
          oPar.foreignKey = cForeignKeyField;
          oPar[cForeignKeyField] = oElm.foreignKey;
          oSelf.query.addUniqueCondition(cForeignKeyField, 'eq', oElm.foreignKey);
        } else if (oSelf.dataSource && oSelf.opt.foreignKey) {
          const bStopBE = oSelf.setForeignKeyFilter();

          if (bStopBE)
            bStopQuery = true;
        }

        if (bStopQuery)
          return bStopQuery;

        if (oSelf.opt.initialFetch !== '#none')
          oSelf._incrementDataSourceLoaded();
        else if (oSelf.opt.initialFetch == '#none' && oSelf.bInitialFetchedReq)
          oSelf._incrementDataSourceLoaded();

        if (oElm.extKey)
          oPar.extKey = oElm.extKey;
        if (oElm.extLink)
          oPar.extLink = oElm.extLink;
        if (oElm.sort) {
          oSelf.serverProp.sortField = oElm.sort;
          oSelf.serverProp.sortDir = oElm.dir;
        }

        // check for mode -> add mode elements to parameter
        if (oElm.mode)
          $.extend(oPar, oElm.mode, oPar);

        // reset bind mechanism
        oData.data.detachEvent('onBeforeFilter');
        oData.data.feed = undefined;
        oData.dataFeed_setter(true);

        if (oSelf.prop.bind && oSelf.dhx.e && (typeof oElm.bind == 'undefined' || oElm.bind == true)) {
          // get target datastore element
          const key = oSelf.dhx.e.id, // e = settings
            oTarget = dhx.ui.get(key);

          // reset bind in target
          oTarget.ia[key] = false; // ia = _bind_settings
          oSelf.dhx.callEvent('onBindRequest');

          // reset binding for second query (filter)
          oSelf.prop.bind = false;

        } else {
          oSelf.jsdo.subscribe('afterFill', function onAfterFill(jsdo, success, request) {
            oSelf._pendingrequest = false;

            jsdo.unsubscribe('afterFill', onAfterFill);

            // call afterFill openQuery callback
            oSelf._eventAfterFillOpenQuery(jsdo, success, request, deferred, callback);

          });

          oSelf.log.info('JSDO fetch:', oSelf.entityName, oPar);

          const oGenericProps = {};
          oGenericProps.clientSessionId = docCookies.getItem('ak_UsrSI');
          const bAllowContext = false;

          if (bAllowContext) {
            // check for next context, for batching
            if (oSelf.cNextContext != '' && !oElm.goBackwards)
              oPar.Context = oSelf.cNextContext;
            else if (oElm.goBackwards) {
              if (oSelf.cPrevContext != '')
                oPar.Context = oSelf.cPrevContext;
            }
          }

          if (oSelf.stop)
            return false;

          // check for multitable param
          if (oSelf.oMultiTableParam)
            oPar = oSelf.oMultiTableParam;

          // check for MergeMode
          if (typeof (oElm.mergeMode) !== 'undefined')
            oPar.mergeMode = oElm.mergeMode;
          else
            delete oPar.mergeMode;

          const tableRef = oSelf.getTableRefFilter();
          if (tableRef)
            oPar.filters = { ...oPar.filters, 'tableRef': tableRef };

          if (oSelf.opt.numRecords != undefined)
            oPar.numRecords = oSelf.opt.numRecords;

          // fill jsdo
          if (oSelf.batchSize && (oSelf.batchSize.skip != undefined || oSelf.batchSize.top != undefined)) {
            if (oSelf.batchSize.skip !== undefined)
              oPar.skip = oSelf.batchSize.skip;

            if (oSelf.batchSize.top !== undefined)
              oPar.top = oSelf.batchSize.top;
          }

          // Workaround for setting real field names for kinvey collection instead of lowercased
          if (oSelf.jsdo instanceof akioma.DataAdapter && (!isNull(oPar.akQuery) || !isNull(oPar.filters)) && oSelf.dynObject) {
            const dataTarget = oSelf.dynObject.getLink('DATA:TARGET') || oSelf.dynObject.getLink('DISPLAY:TARGET');
            if (!isNull(dataTarget) && !isNull(dataTarget.controller.aAkId) && !isNull(dataTarget.controller) && !isNull(oPar.akQuery) && !isNull(oPar.akQuery.filters))
              akioma.DataAdapterHelper.fixFilterFields(oPar.akQuery.filters, dataTarget.controller.aAkId, dataTarget.controller.cols ? dataTarget.controller.cols.dataTypes : null);
          }

          if (oSelf.jsdo instanceof akioma.DataAdapter && oSelf.dynObject) {
            const dataTarget = oSelf.dynObject.getLink('DATA:TARGET');
            if (!isNull(dataTarget) && dataTarget.type.startsWith('datagrid'))
              akioma.DataAdapterHelper.fixSortFilter(oPar, dataTarget.controller.aAkId);
          }

          if (oSelf.oNamedQuery) {
            oPar.NamedQuery = oSelf.oNamedQuery;

            const bTypesHardcoded = (oSelf.opt.resourceName == 'Consultingwerk.SmartFramework.Repository.Class.ObjectTypeBusinessEntity');
            if (oPar.filters == undefined)
              oPar.filters = {};

            if (oSelf.opt.customContext || bTypesHardcoded)
              oPar.filters.CustomContext = 'NoCalcFields';

            oFillPromise = oSelf.jsdo.fill(oPar);
          } else {
            if (oSelf.batchSize && (oSelf.batchSize.skip != undefined || oSelf.batchSize.top != undefined)) {
              if (oSelf.batchSize.skip !== undefined)
                oPar.skip = oSelf.batchSize.skip;

              if (oSelf.batchSize.top !== undefined)
                oPar.top = oSelf.batchSize.top;
            }

            if (oPar.filters == undefined)
              oPar.filters = {};

            oFillPromise = oSelf.jsdo.fill(oPar);
            oSelf.setOpenQueryTimeoutDelay(0);
          }
        }
      }, oSelf.iOpenQueryTimeoutDelay);
    }

    if (oFillPromise) {
      oFillPromise.catch(e => {
        if (e.request.exception.message !== 'Network error while executing HTTP request.')
          uiMessage.showError({ text: `Error fetching data in "${oSelf.opt.name}"` });
      });
    }

    return deferred.promise();
  }

  /**
   * Method for updating the openQuery timeout
   * @param {integer} iVal
   * @private
   * @instance
   * @memberof ak_businessEntity
   */
  setOpenQueryTimeoutDelay(iVal) {
    this.iOpenQueryTimeoutDelay = iVal;
  }

  /**
   * Method for setting the progress state on all the data-target, display targets of the businessEntity
   * @param {boolean} bProgress
   * @param {boolean} bCancellable
   * @private
   * @instance
   * @memberof ak_businessEntity
   */
  setWaitCursorForLinks(bProgress, bCancellable) {
    akioma.WaitCursor.setWaitCursorForLinks(this.dynObject, bProgress, bCancellable);
  }

  /**
   * Method for parsing request response into entity records
   * @param {JSDOSession} jsdo JSDO object
   * @param {object} request
   * @private
   * @instance
   * @memberOf ak_businessEntity
   * @returns {array} rows
   */
  _afterFillDataParse(jsdo, request, success) {
    const oSelf = this;
    const subType = this.opt.SUBTYPE;
    const rows = [];

    switch (subType) {
      case 'ElasticSearchCollection':
      case 'KinveyCollection':
      case 'MockupCollection':
        request.response.forEach(dataRow => {
          rows.push($.extend(oSelf.objectKeysToLowerCase(dataRow), { id: dataRow.id }));
        });
        break;
      default: {
        // check sortable and filterable attributes
        if (jsdo[oSelf.entityName] == undefined)
          akioma.notification({ type: 'warning', text: `The businessEntity ${oSelf.opt.name} entity name "${oSelf.entityName}" is not found in "${oSelf.opt.resourceName}` });

        const oSchema = jsdo[oSelf.entityName].getSchema();
        oSelf._checkSortableAndFilterable(oSchema);

        if (!success && request.response) {
          akioma.notification({
            type: 'error',
            text: (request.response.title || request.response.error),
            moretext: JSON.stringify(request.response, null, 4)
          });

          akioma.notification({
            type: 'error',
            text: request.response._errors[0]._errorMsg
          });
        }
        // set context value
        if (request.response && request.response.pcContext) {
          oSelf.cPrevContext = request.response.pcPrevContext;
          if (oSelf.cNextContext)
            oSelf.cContext = oSelf.cNextContext;
          else
            oSelf.cContext = '';
          oSelf.cNextContext = request.response.pcContext;
        }

        if (jsdo[oSelf.entityName]) {
          jsdo[oSelf.entityName].foreach(dataRow => {
            rows.push($.extend(oSelf.objectKeysToLowerCase(dataRow.data), { id: dataRow.getId() }));
          });
        }
      }
    }
    return rows;
  }

  /**
   * Method called on an AfterFill JSDO event from the openQuery request
   *
   * @param   {JSDO}  jsdo
   * @param   {boolean}  success
   * @param   {object}  request  request obj
   * @param   {promise} deferred
   * @param   {function} callback
   * @private
   * @instance
   * @memberOf ak_businessEntity
   * @return  {void}
   */
  _eventAfterFillOpenQuery(jsdo, success, request, deferred, callback) {
    try {
      const oSelf = this;
      const oData = this.dhx;

      oSelf._rulesDataLoaded = true;
      oSelf.dataLoaded = true;
      if (success) {
        const rows = oSelf._afterFillDataParse(jsdo, request, success);

        if (callback)
          callback(rows, oData);
        else {
          try {
            let oReturn = {};
            if (oSelf.batchMode) {
              const lastIndex = oSelf.batchSize.lastIndex || 0;
              oReturn = {
                data: rows.slice(lastIndex),
                pos: oSelf.batchSize.lastIndex || 0, // from what position did you receive this value
                total_count: oSelf.noOfRecords
              };

              // if length is less than the number of records batched then we have reached the end of the query and need to set it appropriately
              if (rows.length < oSelf.batchSize.top)
                oSelf.hasRecordsToBatch = false;
            } else
              oReturn.data = rows;

            if (oSelf.aBeforeFillOnce) {
              for (const i in oSelf.aBeforeFillOnce)
                oSelf.aBeforeFillOnce[i](oReturn.data);

              oSelf.aBeforeFillOnce = [];
            }

            if (oSelf.batchMode)
              oData.parse(oReturn, 'json');
            else
              oData.parse(rows);

          } catch (e) {
            oSelf.log.error(e);
          }
        }

        oSelf.recordIndex = 0;
        try {
          rows.forEach(record => {
            if (!$.isEmptyObject(oSelf.dhx.data.pull)) {
              const cUiActions = record.akuiactions;
              // call uiActions
              if (cUiActions != undefined && cUiActions != '') {
                oSelf.oUiActions = JSON.parse(cUiActions);
                oSelf.oLastUiAction = JSON.parse(cUiActions);
                oSelf.callUiAttributes();
              }
              oSelf.recordIndex += 1;
            }
          });
          oSelf.recordIndex = -1;
        } catch (e) {
          oSelf.log.warn(e);
        }

        // fire onAfterFetch code if available
        //
        /**
         * Client side code executed after a data fetch request.
         * @event ak_businessEntity#EventAfterFetch
         * @type {object}
          */
        if (oSelf.opt.onAfterFetch)
          applyAkiomaCode(oSelf, oSelf.opt.onAfterFetch);

        if (oSelf.aCallback['afterFill']) {

          oSelf.aCallback['afterFill'](rows);
          delete oSelf.aCallback['afterFill'];
        }


        if (oSelf.aAfterFillOnce) {
          for (const i in oSelf.aAfterFillOnce)
            oSelf.aAfterFillOnce[i](oSelf.dhx.data.getIndexRange());

          oSelf.aAfterFillOnce = [];
        }
        if (oSelf.aAfterFill) {
          for (const i in oSelf.aAfterFill)
            oSelf.aAfterFill[i](oSelf.dhx.data.getIndexRange());

        }

        // Apply keepSelection on datagrid
        for (const o in oSelf.observers) {
          const oObserver = oSelf.observers[o];
          if (oObserver.view.indexOf('datagrid') == 0 || oObserver.view.indexOf('datagrid2') == 0 || oObserver.view.indexOf('treegrid') == 0) {

            oObserver.keepSelection();

            // Apply repositonTo on BE
            if (oObserver.oKeepSelectionData) {
              const cId = oSelf.getIdFrom(oObserver.oKeepSelectionData.identifier, oObserver.oKeepSelectionData.value);
              if (cId)
                oSelf.setIndex(cId);
            }

            // Keep focus when grid is filtered
            if (oObserver.bFilterMode) {
              if (oObserver.oFocused) {
                $(oObserver.oFocused).focus();
                if ($.contains(oObserver.dhx.hdrBox, oObserver.oFocused))
                  oObserver.oKeepFilter = oObserver.oFocused;
              } else
                oObserver.parent.setFocus();
              oObserver.bFilterMode = false;
            }
          }
        }

        deferred.resolve(rows);
      } else {

        oSelf._handleFetchErrors(request, jsdo);

        deferred.reject();
      }

      deferred.resolve();

    } catch (e) {
      // if loading undefined values it will trigger error
      // but we still need it to run
      // so we silently report because we might actually have an error we don't want to miss
      this.log.warn(`parsing loading rows for ${this.opt.name}`, e);
    }

    // set progress state off, for data and display targets
    this.setWaitCursorForLinks(false);

    this._decrementDataSourceLoaded();

    this._callAfterPendingRequestListeners();
  }

  /**
   * Handles the fetch operation errors by displaying the non-modal messages
   * @param {object} request Request object.
   * @param {object} jsdo Request JSDO object.
   * @instance
   * @memberof ak_businessEntity
   * @private
   */
  _handleFetchErrors(request, jsdo) {

    if (request.xhr.cancelled) return; // cancelled request

    let cError = `There was an error fetching the data for ${this.opt.name}`;
    let bModal = akioma.swat.isDefaultMessageBoxStyleModal();

    // Handle Request-URI Too Long Error
    if (request.xhr.status == '414') {
      cError = 'Fetch data request error. Request-URI Too Long. Status Code 414.';
      akioma.notification({ type: 'error', text: cError });
      /* handle Read operation errors */
    } else if (request.response && request.response.error && request.response.message) {
      // special hide attempt to reach behind end of query
      const cQueryOffException = 'Consultingwerk.OERA.Exceptions.QueryOffEndException';
      if (request && request.response && request.response.properties && request.response.properties.MessageBoxStyle)
        bModal = request.response.properties.MessageBoxStyle.toLowerCase() == 'modal';

      if (request.response && request.response.message) {
        if (request.response.message.indexOf('SmartMessage') >= 0)
          this._showSmartMessageError(request.response.message, request, jsdo);
        else if (cQueryOffException !== request.response.error) {
          if (bModal) {
            akioma.message({
              type: 'error',
              text: request.response.message
            });
          } else {
            akioma.notification({
              type: 'error',
              text: request.response.message
            });
          }
        }

        this.forEachGridObservable(this.observers, oObserver => {
          oObserver.stopBatching();
        });
      }
      // any other usecase where there is an error
    } else if (request.xhr.status !== 0) {

      cError = request.response.message || '';

      // if multi messages append to one large message
      if (request.response.messages) {
        request.response.messages.forEach(msg => {
          cError += (`${msg.message}\n`);
        });
      }

      const errorInfo = {
        statusCode: request.xhr.status,
        statusText: request.xhr.statusText,
        errorText: cError,
        type: request.xhr.type,
        url: request.jsdo.url,
        extras: [ request.objParam, request, request.xhr ]
      };

      akioma.HttpClient.displayErrorMessage(errorInfo, request.xhr);

    }

    this.forEachGridObservable(this.observers, oObserver => {
      oObserver._renderMultiSelectState();
    });
  }

  /**
   * Increments the dataSource iterator that need to be loaded
   * @memberof ak_businessEntity
   * @instance
   * @private
   * @returns {void}
   */
  _incrementDataSourceLoaded() {
    const oParWin = this.getAncestor([ 'popover', 'window' ]);
    if (oParWin && !this.dataFirstLoad) {
      this.dataFirstLoad = true;
      this.log.debug(this.opt.name, ` BE increased loads of ${oParWin.iDataSourceToLoad + 1}`);
      oParWin.iDataSourceToLoad = oParWin.iDataSourceToLoad + 1;
    }
  }

  /**
   * Decrement the number of dataSource that need to be loaded
   * @memberof ak_businessEntity
   * @instance
   * @private
   * @returns {void}
   */
  _decrementDataSourceLoaded() {
    const oSelf = this,
      oParWin = oSelf.getAncestor([ 'popover', 'window' ]);

    if (oParWin && oSelf.dataFirstLoad !== false) {
      oSelf.dataFirstLoad = false;
      this.log.debug(oSelf.opt.name, ` BE decreased loads of ${oParWin.iDataSourceToLoad - 1}`);
      oParWin.iDataSourceToLoad = oParWin.iDataSourceToLoad - 1;
    }
  }

  /**
   * Get the last UIAction Object from the datasource AkUiActions
   * @memberOf ak_businessEntity
   * @instance
   * @returns {object} The
   */
  getUIAction() {
    return this.oLastUiAction;
  }

  /**
   * Method for saving the JSDO data and closing the ancestor window
   * @param  {object} oElm
   * @instance
   * @memberOf ak_businessEntity
   */
  updateRecordClose(oElm) {
    const oSelf = this,
      cont = oSelf.getAncestor('window');

    // setup callback to be able to work with smartmessages
    oElm.callback = function(jsdo, success) {
      if (success)
        cont.close();
    };

    oSelf.updateRecord(oElm);
  }

  /**
   * Checks All linked forms/grids for validation errors
   * @return {boolean} Validation status of all forms
   * @instance
   * @memberOf ak_businessEntity
   */
  checkValidationObservers() {
    const oSelf = this;
    let bValid = true;

    try {
      for (const i in oSelf.observers) {
        const oControl = oSelf.observers[i];
        if (oControl.view == 'form' || oControl.view == 'datagrid2') {
          const validation = oSelf.observers[i].validate();
          if (bValid && !validation) {
            bValid = false;
            // add dirty errors state
            if (!oControl.oVuexState.attributes.hasErrors)
              oControl._dispatch('incrementHasErrors', 1);
            oControl._dispatch('setHasErrors', true);

          } else if (oControl.oVuexState.attributes.errors > 0) {
            // remove dirty errors state
            oControl._dispatch('decrementHasErrors', 1);
          }
        }
      }
    } catch (e) {
      console.warn(e);
    }

    return bValid;
  }

  /**
   * Check linked BE with deep hasChanges
   * @instance
   * @memberof ak_businessEntity
   */
  linkedDataSourceHasChanges() {
    const links = this.dynObject.getLinks('DATA:TARGET');
    let changes = false;
    if (links) {
      links.forEach(element => {
        if (element.type === 'businessEntity' && element.controller.hasChanges())
          changes = true;

      });
    }
    return changes;
  }

  /**
   * Method used for updating a record / saving the JSDO record data and checking the form observers validation
   * @param  {Object} oElm
   * @instance
   * @memberof ak_businessEntity
   */
  updateRecord(oElm) {
    const oSelf = this;

    const linkedDataSourceChanges = oSelf.linkedDataSourceHasChanges();
    if (linkedDataSourceChanges) {
      akioma.swat.Message.message({
        type: 'warning',
        title: akioma.tran('messageBox.title.warning', { defaultValue: 'Warning' }),
        text: window.akioma.tran('messageBox.text.unsavedChanges',
          { defaultValue: 'Dependant changes are unsaved and would be lost when saving. Are you sure?' }),
        modal: true,
        buttons: {
          ok: window.akioma.tran('messageBox.button.yes', { defaultValue: 'Yes' }),
          cancel: window.akioma.tran('messageBox.button.cancel', { defaultValue: 'No' })
        }, callback(val) {
          if (val)
            oSelf.saveRecord(oElm);

        }
      });
    } else
      oSelf.saveRecord(oElm);

    return this;
  }

  /**
   * Update/save a JSDO record
   * @instance
   * @memberof ak_businessEntity
   */
  saveRecord(oElm) {
    const oSelf = this;
    const cObjectID = this.opt.name + this.opt.linkid;

    // save forms in batch mode
    try {
      this.dhx.saveBatch(() => {
        // tell all observers to update data in source
        app.messenger.publish({
          link: {
            LinkName: 'DISPLAY',
            LinkType: 'TRG',
            ObjName: cObjectID
          },
          method: 'submitRow',
          caller: oSelf
        });
      });
    } catch (e) {
      this.log.warn(e);
    }

    // save changes if no validation errors
    this.formObserversValidation();

    this.jsdo.subscribe('afterSaveChanges', function onAfterSaveChanges(jsdo, success, request) {
      if (success)
        jsdo.unsubscribe('afterSaveChanges', onAfterSaveChanges);
      if (oElm.callback)
        oElm.callback(jsdo, success, request);
    });

    return this;
  }

  /**
   * Saves data changes if no validation errors, else shows error Message
   * @memberof ak_businessEntity
   * @instance
   * @returns {void}
   */
  formObserversValidation() {
    const oSelf = this;
    // save changes if no validation errors
    if (oSelf.checkValidationObservers())
      oSelf.dc.sendData();
    else
      oSelf.showFormValidationError();
  }

  showFormValidationError() {
    const cErrorTxt = akioma.tran('BusinessEntity.Validation', { defaultValue: 'There was a validation error when saving the BusinessEntity.' });

    akioma.notification({ type: 'error', text: cErrorTxt });
  }

  deleteRecord(oElm) {

    // set default param with closeWindow
    if (isNull(oElm))
      oElm = { closeWindow: true };

    if (oElm.closeWindow === undefined)
      oElm.closeWindow = true;

    return new Promise((resolve, reject) => {
      const cId = this.dhx.getCursor();

      akioma.message({
        type: 'confirm',
        text: akioma.tran('BusinessEntity.deleteRecord.text', { defaultValue: 'Wollen Sie wirklich löschen?' }),
        title: akioma.tran('BusinessEntity.deleteRecord.title', { defaultValue: 'Löschen?' }),
        callback: result => {
          if (result == true) {
            this.bDelete = true;
            this.dhx.remove(cId);

            if (this.dc.updatedRows.length === 0)
              resolve();
            else {
              this.dc.sendData();
              this.addAfterSaveChangesOnceCallback(success => {
                if (oElm.closeWindow) {
                  if (success && this.dynObject.container && this.dynObject.container.controller.close)
                    this.dynObject.container.controller.close();
                }
                resolve();
              });
            }
          } else
            reject();
        }
      });
    });
  }

  /**
   * Method used to return the businessEntity next cursor.
   * @param  {boolean} bItem If true, will return the next item identifier, otherwise will return the next cursor.
   * @return {String} Next item identifier or next cursor
   * @instance
   * @memberOf ak_businessEntity
   */
  getNext(bItem) {
    const cCurrentId = this.dhx.getCursor();
    const id = this.dhx.next(cCurrentId);

    if (bItem) {
      if (id && this.dhx.item(id))
        return this.dhx.item(id)[this.opt.identifier];
      else
        return '';
    } else
      return id;

  }

  /**
   * Method used to return the businessEntity previous cursor.
   * @param  {boolean} bItem If true, will return the previous item identifier, otherwise will return the previous cursor.
   * @return {String} Previous item identifier or previous cursor
   * @instance
   * @memberOf ak_businessEntity
   */
  getPrevious(bItem) {
    const cCurrentId = this.dhx.getCursor();
    const id = this.dhx.previous(cCurrentId);

    if (bItem) {
      if (id && this.dhx.item(id))
        return this.dhx.item(id)[this.opt.identifier];
      else
        return '';
    } else
      return id;

  }

  /**
   * Method used to set the next cursor in businessEntity
   * @return {boolean} Returns true if there is a next item, false otherwise
   * @instance
   * @memberOf ak_businessEntity
   */
  setNext() {
    const cNextId = this.getNext();
    if (cNextId) {
      this.setIndex(cNextId);
      return true;
    }
    return false;
  }

  /**
   * Method used to set the next cursor in businessEntity
   * @return {boolean} Returns true if there is a previous item, false otherwise
   * @instance
   * @memberOf ak_businessEntity
   */
  setPrevious() {
    const cPrevId = this.getPrevious();
    if (cPrevId) {
      this.setIndex(cPrevId);
      return true;
    }
    return false;
  }

  // set index ***********
  /**
   * Method to set the cursor businessEntity.
   * @param {String} value The cursor.
   * @memberOf ak_businessEntity
   * @instance
   */
  setIndex(value) {
    const oData = this.dhx,
      iIndex = value;

    // get queryposition and recordstate
    let cState = 'NoRecordAvailable';
    if (iIndex) {

      // check if rownum is first or last
      switch (iIndex) {
        case this.dhx.first():
          cState = 'FirstRecord';
          break;
        case this.dhx.last():
          cState = 'LastRecord';
          break;
        default:
          cState = 'NotFirstOrLast';
          break;
      }
      this.prop.recordState = 'RecordAvailable';
    } else
      this.prop.recordState = cState;

    this.prop.queryPosition = cState;

    // publish queryposition
    app.messenger.publish({
      link: {
        LinkName: 'NAVIGATION',
        LinkType: 'SRC',
        ObjName: this.getObjName('NAVIGATION', 'TRG')
      },
      method: 'queryPosition',
      state: cState,
      caller: this
    });

    // check if value has been changed
    if (oData.getCursor && oData.getCursor() != iIndex)
      oData.setCursorWithPrevent(iIndex);

  }

  /**
   * Method for returning the data store
   * @returns {DhtmlxDataStore}
   */
  getStore() {
    return this.dhx;
  }

  /**
   * Method for returning the data connector
   * @returns {dataConnector}
   */
  getStoreConnector() {
    return this.dc;
  }

  // set filter ************
  setFilter(oFilter, oControl) {
    this._setFilter(oFilter, oControl);

    // detach filter event -> function with url is loaded persistent
    this.dhx.data.detachEvent('onBeforeFilter');
    this.dhx.data.feed = undefined;
    this.dhx.dataFeed_setter(true);
  }

  // switches JSDO working record table - for multitable
  switchJSDOWorkingRecord() {}

  // before update
  beforeUpdate() {
    const oSelf = this;
    const cObjectID = oSelf.opt.name + oSelf.opt.linkid;
    // check for translation field
    app.messenger.publish({
      link: {
        LinkName: 'DISPLAY',
        LinkType: 'TRG',
        ObjName: cObjectID
      },
      method: 'saveTranslat',
      caller: this
    });
  }

  beforeDestroy() {
    // cleanup all RefreshListeners inside the Window
    try {
      if (akioma.appdata && akioma.appdata.refreshAfterSave)
        this.cleanUpRefreshListeners();

    } catch (e) {
      akioma.notification({ type: 'error', text: `Error cleaning up Refresh Listeners in ${this.opt.name}` });
    }

    try {
      this.setWaitCursorForLinks(false);
    } catch (e) {
      akioma.notification({ type: 'error', text: 'There was an error on beforeDestroy, disabling wait cursor for links.' });
    }
  }

  destroy() {
    try {
      this.observers.forEach(ob => {
        if (ob.dhx && ob.dhx._settings && ob.dhx._settings.id) {
          if (ob.dhx.unbind)
            ob.dhx.unbind(this.dhx);

          if (window.RootDhx.ui.views[ob.dhx._settings.id])
            delete window.RootDhx.ui.views[ob.dhx._settings.id];

        }
      });

      if (this.dhx._settings)
        delete window.RootDhx.ui.views[this.dhx._settings.id];

      if (this.jsdo)
        progress.data.ServicesManager.cleanSession(this.jsdo._session);

      this.dc = null;

      this.dhx = null;

      if (this.jsdo) {
        this.jsdo.unsubscribeAll();
        delete this.jsdo;
      }

      this.serverProp = null;
      this.urlQuery = null;
      this.observers = null;

    } catch (e) {
      console.error(e);
    }
  }
};

