dhtmlXForm.prototype.items.dynselect = {

  render: function(item, data) {

    item.data = data;

    item.akElm = $.getObjectByName({ id: item.data.userdata.id });

    let multiple = '';
    let placeholder = '';

    if (data.multiple || (data.multiple && data.placeholder))
      multiple = ' multiple="multiple" ';
    else if (data.placeholder)
      placeholder = '<option></option>';

    item._controlType = 'dynselect';

    item._enabled = data.enabled;

    $(`<div class="${data.control}">`
              + `<select id="dynSelect_${data.userdata.id}" class="dynSelect"${multiple}>${placeholder}</select>`
              + `<div class="dhxform_note">${data.note.text}</div>`
              + '</div>')
      .appendTo(item)
      .find(`select#dynSelect_${data.userdata.id}`);

    $(item).find('div.dhxform_note').css('width', data.note.width);

    if (data.hidden)
      $(item).hide();

    $(item).find(`.${item.data.control}`).focusin (() => {
      if (!item.akElm)
        return;
      const oForm = item.akElm.form;
      const isEnabledField = oForm.getFormFieldEnabled(item.akElm.opt.name);

      if (isEnabledField) {
        $(item).addClass('active').addClass('focusedinp');

        const oParentPanel = item.akElm.getAncestor('panel');
        oForm.bFocused = true;
        if (oParentPanel != null) {
          oParentPanel.setActivePanelState();
          oParentPanel.oFocus.oActiveField = item.akElm;
        }
        if (item.akElm.bPreventLeave)
          item.akElm.bPreventLeave = false;
      }
    });
    $(item).find(`.${item.data.control}`).focusout (() => {
      if (!item.akElm)
        return;
      const form = item.akElm.form;
      const isEnabledField = form.getFormFieldEnabled(item.akElm.opt.name);
      if (isEnabledField) {
        const dropdown = ($('body').find('.select2-container--open').length);
        const dynselectSelectionVal = item.akElm.select2.data().select2.$selection.text().trim();
        if ((item.akElm.lookupKeyValueColumn_multiple && dropdown) || dynselectSelectionVal !== '')
          $(item).removeClass('focusedinp');
        else
          $(item).removeClass('active focusedinp');


        item.akElm.validate();

      }
    });


    item._ll = false;

    if (data.control == 'dhxform_control')
      this.doAddLabel(item, data);
    if (data.control == 'dhxribbon_control') {
      const conf = {
        'id': data.name,
        'text': data.label,
        'disable': data.disabled
      };
      this.conf = conf;
    }

    this.base = item;
    this.id = data.name;

    return this;
  },
  _validate: function(item) {
    let res = true;

    let resRequired = true;

    // The order should be id || desc. But even so, there shouldn't be any cases where the ID is not defined. Needs to be checked
    const val = item.akElm.val.desc || item.akElm.val.id || item.akElm.select2.data().select2.$selection.text().trim();

    if (item.akElm) {
      const oForm = item.akElm.form;
      const fieldEnabled = oForm.getFormFieldEnabled(item.akElm.opt.name);
      if (!fieldEnabled)
        return true;


      const fieldHidden = oForm.getFieldIsHidden(item.akElm.opt.name);
      if (fieldHidden)
        return true;

    }

    // check for required attr
    if (item._validate) {
      for (let q = 0; q < item._validate.length; q++) {
        const v = `is${item._validate[q]}`;

        if ((val == null || val.length == 0) && v == 'isNotEmpty' && item._type != 'container') {
          // field required and empty
          resRequired = false;
        }
      }
    }


    if (item.akElm) {
      let oForm = akioma.getForm(item.akElm);
      if ((oForm.dataSource && oForm.dataSource.bDelete))
        return true;

      res = oForm.checkSmartMessageValidation(item.akElm.opt.name);

      oForm = item.getForm();

      const cFieldName = item.akElm.opt.name;
      const cFieldId = item.akElm.opt.id;

      const bValid = (res && resRequired);

      if (!bValid) {
        oForm.akElm._setFormFieldError(cFieldName, { hasError: true });

        // add dirty errors state
        if (!oForm.akElm.oVuexState.attributes.hasErrors)
          oForm.akElm._dispatch('incrementHasErrors', 1);
        oForm.akElm._dispatch('setHasErrors', true);
      } else {
        // remove dirty errors state
        const fieldsWithErrorsNo = $(oForm.cont).find('.validate_error').length;
        const bSame = ($(oForm.cont).find('.validate_error').length == 0 || $(oForm.cont).find('.validate_error')[0] == item);
        // remove dirty errors state
        if (oForm.akElm.oVuexState.children[cFieldId].hasError && fieldsWithErrorsNo <= 1 && bSame)
          oForm.akElm._dispatch('decrementHasErrors', 1);


        oForm.akElm._setFormFieldError(cFieldName, {
          hasError: false,
          errorMsg: ''
        });
      }
    }


    return (res && resRequired);
  },

  setValue: function(item, value) {
    item.akElm.setValue(value);
    let oForm;
    if (item.akElm && item.akElm.dhx)
      oForm = akioma.getForm(item.akElm);

    if (oForm && oForm.bFocused)
      this._validate(item);

    if (item.akElm && !item.akElm.bSelected) {
      const akEvent = {};
      akEvent.lastValue = {
        desc: (item.akElm.dynObject && item.akElm.dynObject.akEvent) ? item.akElm.dynObject.akEvent.currentValue.desc : undefined,
        id: (item.akElm.dynObject && item.akElm.dynObject.akEvent) ? item.akElm.dynObject.akEvent.currentValue.id : undefined
      };
      akEvent.currentValue = {
        desc: item.akElm.val.desc,
        id: item.akElm.val.id
      };
      akEvent.currentValueNative = akEvent.currentValue.id;
      item.akElm.dynObject.akEvent = akEvent;
    }
  },

  getValue: function(item) {
    return item.akElm.getValue();
  },

  disable: function(item) {
    item.akElm.setReadonly(true);
  },

  enable: function(item) {
    item.akElm.setReadonly(false);
    if (item.akElm.domTag)
      item.akElm._setTabIndex(item.akElm.domTag);

  },

  setFocus: function(item) {
    $(item).find('.select2-selection.select2-selection--single').focus();
  },
  getDynSelectControl: function(item) {
    return item.akElm;
  },
  getDynSelect: function(item) {
    return item;
  },
  _getItemNode: function(item) {
    return item;
  },
  destruct: function(item) {
    $(item).find(`.${item.data.control}`).off();
    item = null;
  }

};
(function() {
  for (const a in { doAddLabel: 1, doAddInput: 1, doLoadOpts: 1, doUnloadNestedLists: 1, setText: 1, getText: 1, isEnabled: 1, _checkNoteWidth: 1 })
    dhtmlXForm.prototype.items.dynselect[a] = dhtmlXForm.prototype.items.select[a];
})();

dhtmlXForm.prototype.items.dynselect.d2 = dhtmlXForm.prototype.items.select.destruct;
dhtmlXForm.prototype.getDynSelect = function(name) {
  return this.doWithItem(name, 'getDynSelect');
};
dhtmlXForm.prototype.getDynSelectControl = function(name) {
  return this.doWithItem(name, 'getDynSelectControl');
};
dhtmlXForm.prototype.setFormData_dynselect = function(name, value) {
  return this.doWithItem(name, 'setValue', value);
};
dhtmlXForm.prototype.getFormData_dynselect = function(name) {
  return this.doWithItem(name, 'getValue');
};
dhtmlXRibbon.prototype.doWithItem = function(id, method, a, b, c, d) {
  const line = this._items[id];
  if (line && line[method])
    return line[method](this.itemPull[id], a, b, c, d);
};
dhtmlXRibbon.prototype.items.dynselect = dhtmlXForm.prototype.items.dynselect;
dhtmlXRibbon.prototype.getDynSelect = function(name) {
  return this.doWithItem(name, 'getDynSelect');
};
(function($, jQuery) {

  // ********************* accordion ******************
  $.extend({
    /**
     * Dynselect Control
     * @tutorial dynselect
     * @tutorial dynselect-desc
     * @class ak_dynselect
     * @augments ak_global
     * @param  {Object} options Repository Attributes for new dynselect.
     * @param {boolean} options.CanSort Set to FALSE if this field should not be used to sort the data object query.
     * @param {boolean} options.allowClear
     * @param {string} options.placeholder
     * @param {boolean} options.tags
     * @param {string} options.tokenSeparators
     * @param {string} options.resultListMenuCode Contains the name of a menuStructure that will be displayed at the end of the results list
     * @param {boolean} options.closeOnSelect
     * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
     * @param {string} options.align alignment of column. can be left, right or centered
     * @param {string} options.HELP WidgetAttributes Help
     * @param {number} options.ROW Row position.
     * @param {string} options.VisualizationType WidgetAttributes VisualizationType
     * @param {string} options.LABEL WidgetAttributes Label
     * @param {string} options.EventEntryType Language of "entry" trigger
     * @param {number} options.HEIGHT-CHARS Height in characters. This may currently be used when rendering some objects. There is no get function, use getHeight to retrieve the realized value from an object.
     * @param {boolean} options.VISIBLE WidgetAttributes Visible
     * @param {boolean} options.CHECKED
     * @param {string} options.TemplateFile The relative path and filename of the static object used as the template at design time
     * @param {string} options.LIST-ITEM-PAIRS Comma-separated list of options to be loaded in dynSelect. Format is: desc1,key1,desc2,key2,desc3,key3, where desc[i],key[i] will form an option with value=key and text=desc
     * @param {string} options.InitialValue Used only with List-Item-Pairs attribute. Sets the initial value of a dynSelect. Must be a key which exists in List-item-pairs options. Default value will be the first key from the options list.
     * @param {string} options.EventEntry Event which should run on entry of a field
     * @param {boolean} options.CanFilter Set to FALSE if this field should not be used to filter the data object query.
     * @param {string} options.ViewAs The 'ViewAs' definition  of the selection.
    - combo-box,radio-set,selection-list OR browse
    - Uses colon as separator to define SUB-TYPE for combo-box or
    horizontal/vertical radio-set,
     * @param {string} options.FolderWindowToLaunch If Dynamics is running, this property specifies a window to launch upon the occurence of toolbar events "View", "Copy", "Modify" or "Add".
     * @param {string} options.EventLeaveType Language, the event is written in.
     * @param {string} options.TableName WidgetAttributes TableName
     * @param {string} options.FORMAT WidgetAttributes Format
     * @param {string} options.EventAkValidate Client side validation code (JavaScript)
     * @param {boolean} options.infoButton Show/hide info icon
     * @param {string} options.eventOnInfoSelected Executed on info click
     * @param {string} options.DATA-TYPE WidgetAttributes Data-Type
     * @param {string} options.Validation Validation-Rules, e.g. ValidInteger,ValidEMail...
     * @param {string} options.FilterOperator
     * @param {boolean} options.LABELS If false then NO-LABEL is used.  This attribute applies to most field level widgets and frames
     * @param {string} options.FieldName The name of the associated SDO field this SmartDataField maps to. This is usually 'set' from the containing SmartDataViewer.
     * @param {boolean} options.CreateField
     * @param {string} options.EventLeave Event when leaving a field
     * @param {string} options.SUBTYPE
     * @param {boolean} options.MULTIPLE
     * @param {string} options.Width A widget's width. The unit of measurement is determined by another
    parameter.
     * @param {number} options.COLUMN Column position. This may currently be used when rendering some objects. There is no getColumns function, use getCol to retrieve the realized value from an object.
     * @param {string} options.FieldLabel Label for the Dynamic Lookup/Dynamic Combo field on the viewer.
     * @param {string} options.EntityName The Name of the Business Entity used by the component
     * @param {string} options.EventBeforeFetch code to be executed before fetching data from the backend
     * @param {string} options.KeyField Name of the Dynamic Lookup/Dynamic Combo key field to assign value from (Table.Field)
     * @param {string} options.LookupControls A comma seperated list of Control names. The value of the corresponding Columns gets filled into those Controls.
     * @param {string} options.initialFetch if not empty, then the lookup will trigger the fetching of data from backend when it gets focus, before a key has been typed.
     * @param {string} options.QueryTables Comma list of query tables for Dynamic Lookups/Dynamic Combos (Buffers)
     * @param {string} options.ViewerLinkedFields Dynamic Lookup Linked fields to update value of on viewer, comma list of table.fieldname.
     * @param {string} options.LIST-ITEM-PAIRS
     * @param {string} options.templateOptions Pipe-delimited list (img|key|desc) specifying the fields displayed in the results list. The key and desc can also be a comma-separated list of fields and constants. The image can support the following attributes:
     * <br> 1. Css attributes, defined like this: fa fa-user#color:red
     * <br> 2. Css classes, defined like this: fa fa-user#_style:module_prod
     * <br> 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod
     * @param {string} options.LookupKeyValueBinding The data-binding field (in the viewer containing a lookup) that should receive the lookup's LookupKeyValueColumn field
     * @param {string} options.resourceName
     * @param {boolean} options.Mandatory WidgetAttributes Mandatory
     * @param {string} options.AxLookupDialog Axilon: Dialog to choose record within the dynSelect. The dialog can be triggered using the ALT-L shortcut or using the arrow button in the dynSelect. </br><strong><a href="https://help.build.one/display/AKDOC/How+to+design+a+Choose+Window" target="_blank" >Tutorial How to design a Choose Window</a></strong>
     * @param {string} options.LookupKeyField The name of the field which is returned by the SmartLookup.
     * @param {string} options.Template
     * @param {string} options.ViewerLinkedWidgets Dynamic Lookup linked field corresponding widget names to update value of in viewer, comma list, ? if not widget
     * @param {boolean} options.allowTagging allow dynamically create new options from text input by the user in the search box
     * @param {string} options.LookupKeyValueColumn The name of the Data Source column which is used for retrieving the LookupKeyValue property
     * @param {string} options.ObjectType
     * @param {string} options.LookupFields A comma seperated list of Column names. The value of those fields gets filled into the corresponding Controls of the Property LookupControls.
     * @param {string} options.MappedFields A comma separated paired list of data source field names and widget names on a viewer to be used to map fields from a data source with widgets on a viewer when linked fields are used in a Dynamic Lookup.
     * @param {boolean} options.ENABLED WidgetAttributes Enabled
     * @param {boolean} options.multiMode allow multiple items opened at the same time
     * @param {string} options.EntityTable The primary temp-table of the Business Entity used by the component
     * @param {string} options.DisplayedField For Dynamic Lookups. The name of the field being displayed from the query. (Table.Field). For Dynamic Combo's a comma seperated list of table.field name of the fields to be displayed as description values in the combo-box.
     * @param {string} options.minimumInputLength Minimum number of characters required to start a search.
     * @param {number} options.maximumSelectionLength The maximum number of items that may be selected in a multi-select control. If the value 0, the number of selected items will not be limited.
     * @param {string} options.DisplayDataType Datatype of Dynamic Lookup display field.
     * @param {string} options.ServerProps Backend Properties
     * @param {string} options.EventClick Client side validation code (JavaScript)
     * @param {string} options.note field description displayed below the field
     * @param {string} options.defaultSort Specify sorting string, default is empty string. eg. single sort: "desc <field1>" or multiple sort fields: "<field1>, desc <field2>". default asc not required
     * @param {string} options.extendedFormat allows specifying extend input mask formatting.
     * @param {string} options.rowsToBatch allows specifying extend input mask formatting.
     * @param {boolean} options.preLoad If true data will be loaded on initialization
    e.g. "99-99-99-99" would allow to enter 4 pairs of integer values, separated by dashes
     * @param {boolean} options.delay The delay to use in milliseconds between the autocomplete search BusinessEntity requests, defaults to 350 ms if not specified
     * @param {boolean} options.preSelectExistingInput If true, the existing content in dynSelect will be in the input search and selected. Only working for dynSelects that are using BusinessEntity.
    <br> By pressing cursor-left (or right/left keyboard arrows), the existing content will be kept and it can be changed (e.g. adding one more letter).
    <br> By typing, the entire selection will be replaced in order to search for something completely different.
     * @param {string} options.filter
     * @param {string} options.multiDelimiter Specifies the delimiter for description fields.
     * @param {string} options.multipleBehavior Specifies in which cases the MULTIPLE attribute will be applied. Default value is 'standard'.
     * @fires ak_dynselect#defaultActionEvent
     * @fires ak_dynselect#EventLeave
     * @fires ak_dynselect#EventBeforeFetch
     * @fires ak_dynselect#EventAkValidate
     * @fires ak_dynselect#focusEvent
     * @fires ak_dynselect#eventOnInfoSelected
     */
    ak_dynselect: function(options) {

      akioma.BaseFormDataField.call(this, options);

      // Default values should be rechecked and adjusted
      const defaults = {
        disabled: false,
        entityName: 'Akioma.Crm.MasterData.Customer.CustomerEntity',
        entityTable: 'eCustomer',
        // array of params for template and selection
        template: 'GenericAutocompleteSearchTemplateDynSelect',
        // selfhdl | img | key | description
        templateOptions: 'selfhdl||city|name1',
        allowClear: true,
        closeOnSelect: true,
        tags: false
      };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;

      this.registerDynObject = true;
      this.registerVuexWatcher = true;

      this.formViews = [ 'form', 'fieldset', 'block' ];
      this.ribbonViews = [ 'ribbon', 'ribbontab', 'ribbonblock' ];

      // get parent
      const oParent = this.parent;

      if (oParent) {


        this.addElement_toParent();

        this.serverProp = { srvrProp: [] };

        this.aMouseTrapBindings = [];
        this._selectionPayload = {};
        this.ignoreValidateEvent = false;

        this.tokenSeparators = [];
        this.maximumSelectionLength = 0;
        this.bPausedOldValueSet = false;
        this.afterPreloadData = $.Deferred();

        if (!this.opt.preLoad)
          this.afterPreloadData.resolve();


        if (this.opt.multiple) {

          if (this.opt.maximumSelectionLength)
            this.maximumSelectionLength = this.opt.maximumSelectionLength;
          if (this.opt.tokenSeparators)
            this.tokenSeparators = this.opt.tokenSeparators.split('|');

          // Contains the currently selected options (only Ids - lookupKeyValueColumn values)
          this.lookupKeyValueColumn_multiple = [];
          // Contains the currently selected options (only Ids - lookupKeyValueColumn values) and their corresponding data
          this.lookupKeyValueColumn_multipleMap = [];


          if (this.opt.lookupFields) {
            // Contains the currently selected options (all the other field mappings - lookupFields)
            this.lookupFields_multiple = {};
            const fields = this.opt.lookupFields.split(',');
            for (const field of fields)
              this.lookupFields_multiple[field] = [];
          }

          if (typeof this.opt.multiDelimiter == 'string')
            this.opt.multiDelimiter = this.opt.multiDelimiter.replaceAll('<space>', ' ');

          this.multiDelimiter = this.opt.multiDelimiter || ',';
        }

        if (this.opt.comboText && this.opt.comboValues) {
          this.listItemPairs = true;
          this.setDefaultAttributes();
        } else
          akioma.createBusinessEntity(this);

        $.extend(this, {
          val: {
            id: '',
            desc: ''
          }
        });

      }
    }
  });

  // methods for dynSelect
  Object.assign($.ak_dynselect.prototype, akioma.BaseFormDataField.prototype, {
    componentOptions: function() {
      const oSelf = this;
      return {
        watch: {
          'getters.getFormFieldState': {
            fn: function(newValue, oldValue) {
              oSelf._hasFormFieldChangesWatcher(newValue, oldValue);
            },
            params: [this.opt.id]
          },
          'getters.getCustomStates': {
            fn: function(customStates) {
              oSelf._customStatesWatcher(customStates);
            },
            params: [this.opt.id],
            watchOptions: { deep: true }
          },
          'getters.getFormFieldEnabled': {
            fn: function(bEnabled) {
              oSelf._enabledFormFieldWatcher(bEnabled);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldMandatory': {
            fn: function(bMandatory) {
              oSelf._mandatoryFormFieldWatcher(bMandatory);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldError': {
            fn: function(bHasError) {
              oSelf._errorFormFieldWatcher(bHasError);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldErrorMsg': {
            fn: function(cErrorMsg) {
              oSelf._errorFormFieldMsgWatcher(cErrorMsg);
            },
            params: [this.opt.id]
          }
        }
      };
    },
    _enabledFormFieldWatcher: function(bEnabled) {
      const oSelf = this;
      const oForm = akioma.getForm(oSelf);
      const cName = oSelf.opt.name;

      try {
        if (oForm.dhx) {
          const formField = akioma.getDhxFormElement(oForm.dhx, oSelf.opt.name);
          if (bEnabled) {
            formField._enabled = true;
            oForm.dhx.enableItem(cName);
          } else {
            formField._enabled = false;
            oForm.dhx.disableItem(cName);
          }
        }
      } catch (e) {
        console.warn(e);
      }
    },
    _mandatoryFormFieldWatcher: function(bMandatory) {
      const oSelf = this;
      const oForm = akioma.getForm(oSelf);
      const cName = oSelf.opt.name;

      if (oForm && oForm.dhx) {
        if (typeof (oSelf.setRequired) == 'function')
          oSelf.setRequired(bMandatory);
        oForm.dhx.setRequired(cName, bMandatory);
      }
    },
    /**
     * Watcher for the form error message
     */
    _errorFormFieldMsgWatcher: function(newValue) {
      const oSelf = this;
      const cName = oSelf.opt.name;
      const oForm = akioma.getForm(oSelf);
      const oFormField = akioma.getDhxFormElement(oForm.dhx, cName);

      // setup error message under input
      if (oFormField) {
        const oFormCtrl = $(oFormField).find('> .dhxform_control');
        if (oFormCtrl.find('.validation-error-smartmessage').length == 0)
          oFormCtrl.append(`<span class="validation-error-smartmessage">${newValue}</span>`);
        else
          oFormCtrl.find('.validation-error-smartmessage').text(newValue);
      }

    },

    /**
     * Code executed when on key up event
     * @memberof ak_dynselect
     * @instance
     */
    onKeyUp() {
      this.callRules({
        eventEntity: this.opt.InstanceName,
        eventName: 'onKeyUp',
        eventSource: this.opt.InstanceName,
        eventObject: this
      });
    },

    finishConstruct: function() {

      let parent;
      const oSelf = this;
      if ($.inArray(this.parent.view, this.formViews) !== -1)
        parent = akioma.getForm(this);
      if ($.inArray(this.parent.view, this.ribbonViews) !== -1)
        parent = akioma.getRibbon(this);

      let control;
      if (parent) {
        this.dhx = parent.dhx.getDynSelect(this.opt.name);
        const oDynSelect = this.dhx;
        const controlAkId = parent.akId;
        oSelf.form = parent;

        // Set akId
        control = $(oDynSelect).find(`.${this.controlClass}`);
        $(control).attr('akId', `${controlAkId}-${this.opt.name}`);
        $(control).addClass('akFormDynSelect');

        this.$domElement = $(control).parent();
        this._setAutocomplete(control);

        this.setResponsiveSizes();

        if (oSelf.opt.required)
          $(control).addClass('akMandatory akDynSelectMandatory');


        // Handling for _initialListItemPairValue in ListItemPairs
        if (oSelf.listItemPairs) {
          const aItems = [];
          const aItemsMapping = [];
          const aDescription = oSelf.opt.comboText.split(',');
          const aValues = oSelf.opt.comboValues.split(',');

          for (let i = 0; i < aDescription.length; i++) {
            aDescription[i] = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}_option_${aValues[i]}`, { defaultValue: aDescription[i] });
            $(oSelf.dhx).find('select').append($('<option>', {
              value: aValues[i],
              text: aDescription[i]
            }));
            aItems.push({
              value: aValues[i],
              text: aDescription[i]
            });
            aItemsMapping[aValues[i]] = aDescription[i];
          }
          if (!oSelf._initialListItemPairValue) {
            oSelf._initialListItemPairValue = aValues[0];
            oSelf.val.desc = aDescription[0];
          }

          oSelf.val.id = oSelf._initialListItemPairValue;
          oSelf.val.desc = aItemsMapping[oSelf.val.id];

          parent.dhx.comboValues[oSelf.opt.name] = aItems;
          oSelf.listItemsArray = aItems;

          if (!oSelf.form.aDynSelectLIP)
            oSelf.form.aDynSelectLIP = [];
          oSelf.form.aDynSelectLIP.push(oSelf);

          const akEvent = {};
          akEvent.lastValue = {
            desc: (oSelf.dynObject && oSelf.dynObject.akEvent) ? oSelf.dynObject.akEvent.currentValue.desc : undefined,
            id: (oSelf.dynObject && oSelf.dynObject.akEvent) ? oSelf.dynObject.akEvent.currentValue.id : undefined
          };
          akEvent.currentValue = {
            desc: oSelf.val.desc,
            id: oSelf.val.id
          };
          akEvent.currentValueNative = akEvent.currentValue.id;
          oSelf.dynObject.akEvent = akEvent;
        }

        this.dynSelectControl = new akioma.DynSelect(this, this.dhx);

        if (this.listItemPairs) {
          $(this.dhx).find('select').val(this._initialListItemPairValue);
          $(this.dhx).find('select').trigger('change');
          $(this.dhx).addClass('active');
        }

        // blur event
        $(this.dhx).find('span.select2-selection--single').blur(() => {
          if (this.opt.leaveEvent && !this.bOpened && !this.bPreventLeave)
            app.controller.callAkiomaCode(this, this.opt.leaveEvent);


          this.callRules({
            eventEntity: this.opt.InstanceName,
            eventName: 'onLeave',
            eventSource: this.opt.InstanceName,
            eventObject: this
          });
        });

        this._onKeyUp = this.onKeyUp.bind(this);
        $(document).on('keyup', '.select2-search__field', this._onKeyUp);

        // Set corresponding Mousetrap bindings
        if (this.opt.multiple) {
          const element = $(this.dhx).find('span.select2-selection.select2-selection--multiple');
          oSelf.mousetrapBinding(element, 'alt+l');
          if (oSelf.opt.defaultActionEvent)
            oSelf.mousetrapBinding(element, 'ctrl+enter');
        } else {
          const element = $(this.dhx).find('span.select2-selection.select2-selection--single');
          const dropdown = $(this.select2).data('select2').$dropdown.find('input.select2-search__field');
          oSelf.mousetrapBinding(element, 'alt+l');
          oSelf.mousetrapBinding(dropdown, 'alt+l');

          if (oSelf.opt.defaultActionEvent)
            oSelf.mousetrapBinding(element, 'ctrl+enter');
        }

        if ($.inArray(this.parent.view, this.ribbonViews) !== -1) {
          $(oSelf.dhx).find('span.select2-selection--multiple').css('min-height', '25px');
          $(oSelf.dhx).find('span.select2-selection--single').css('height', '25px');
          $(oSelf.dhx).find('span.select2-container--default').css('height', '25px');
        }

        if (!oSelf.opt.visible && oSelf.opt.visible != undefined)
          parent.dhx.hideItem(oSelf.opt.name);

        oDynSelect.akElm = this;
      }

      $(oSelf.dhx).on('EventAfterSearchResultDisplay', () => {
        app.controller.callAkiomaCode(oSelf, oSelf.opt.EventAfterSearchResultDisplay);
      });

      this.domTag = $(control).find('span.select2-selection')[0];
      this._setTabIndex(this.domTag);
    },

    /**
     * Method for setting dynselect value
     */
    _setInitialDynselectValue: function(cInitialValue, oDataSource) {
      const oSelf = this;

      if (cInitialValue) {
        $(oSelf.dhx).find('select').val(cInitialValue);
        $(oSelf.dhx).find('select').trigger('change');
        $(oSelf.dhx).addClass('active');
      }

      if (cInitialValue && oDataSource) {
        oDataSource.setFieldValue({
          name: oSelf.opt.name,
          value: cInitialValue
        });
      }
    },

    /**
     * Method used for getting the count of the results from the results list.
     * @instance
     * @memberOf ak_dynselect
     * @returns {number}
     */
    getResultsCount() {
      const select = $(this.select2).data('select2');
      if (select.$results)
        return select.$results.find('li.select2-results__option--selectable').length;
      else
        return 0;
    },

    /**
     * Show a footer item from the dynselect dropdown footer by the item name.
     * @instance
     * @memberOf ak_dynselect
     * @param {string} itemName
     */
    showFooterItem(itemName) {
      this.select2.data('select2').dataAdapter.container.$dropdown.find(`.${itemName}`).show();
    },

    /**
     * Hide a footer item from the dynselect dropdown footer by the item name.
     * @instance
     * @memberOf ak_dynselect
     * @param {string} itemName
     */
    hideFooterItem(itemName) {
      this.select2.data('select2').dataAdapter.container.$dropdown.find(`.${itemName}`).hide();
    },

    /**
     * Sets initialValue for ListItemPairs dynSelects
     * @instance
     * @memberOf ak_dynselect
     */
    setInitialValue: function() {
      const oSelf = this;
      if (oSelf.listItemPairs) {
        const oDataSource = akioma.getForm(this).dataSource;
        const oItem = oSelf.getFormRecord(this.form.opt.entityName);

        if (!oDataSource) {
          const cInitialValue = oSelf.opt.InitialValue || oItem[oSelf.opt.name] || oSelf._initialListItemPairValue;
          this._setInitialDynselectValue(cInitialValue);
          return;
        }

        if (oDataSource.getStore(this.form.opt.entityName).saving_batch)
          return;

        if (oItem) {
          // if updating record do nothing
          const cState = oDataSource.getStoreConnector(this.form.opt.entityName).getState(oItem._id);
          if (cState === 'updated')
            return;

          if (!cState && !oItem[oSelf.opt.name]) {
            const cInitialValue = oSelf.opt.InitialValue || oSelf._initialListItemPairValue;
            // when value not set from BE or we override with value from UI, also set the value in the datasource
            this._setInitialDynselectValue(cInitialValue, oDataSource);
            return;
          }

          if (cState === 'inserted') {
            const cInitialValue = oSelf.opt.InitialValue || oItem[oSelf.opt.name] || oSelf._initialListItemPairValue;

            // on insert check if user manually changed selection (currentSel <> initialValue (if set)); if so, use that one
            if (cInitialValue !== oItem[oSelf.opt.name])
              // when value not set from BE or we override with value from UI, also set the value in the datasource
              this._setInitialDynselectValue(cInitialValue, oDataSource);
          }
        }
      }
    },

    /**
     * Sets the BatchSize for the BusienssEntity of the dynselect / similar in grid filter dynselect
     * @instance
     * @private
     * @memberOf ak_dynselect
     */
    _setBatchSize: function() {
      const oSelf = this;

      if (!oSelf.bStopInitialFetch && oSelf.opt.initialFetch.indexOf('initFetch:All') > -1)
        oSelf.businessEntity.batchSize.top = 1000;
      else
        oSelf.businessEntity.batchSize.top = oSelf.opt.rowsToBatch;

    },

    /**
     * Sets dynSelect as anonymised (blurred data)
     * @instance
     * @memberOf ak_dynselect
     */
    setAnonymised: function() {
      const control = $(this.dhx).find(`.${this.controlClass}`);
      $(control).addClass('anonymisedClass');
    },

    /**
     * Sets dynSelect as required
     * @param {boolean} bRequired True if required, false otherwise
     * @instance
     * @memberOf ak_dynselect
     */
    setRequired: function(bRequired) {
      const oSelf = this;
      const control = $(oSelf.dhx).find(`.${oSelf.controlClass}`);
      (bRequired) ? $(control).addClass('akMandatory akDynSelectMandatory') : $(control).removeClass('akMandatory akDynSelectMandatory');
    },

    /**
     * Sets default attributes for listItemPair dynSelect
     * @instance
     * @memberOf ak_dynselect
     */
    setDefaultAttributes: function() {
      const oSelf = this;
      const aListItemFields = [ 'listitemkey', 'listitemdesc', 'listitemshortdesc' ];
      if (aListItemFields.indexOf(oSelf.opt.lookupKeyField.toLowerCase()) == -1)
        oSelf.opt.lookupKeyField = 'listItemDesc';
      if (aListItemFields.indexOf(oSelf.opt.lookupKeyValueColumn.toLowerCase()) == -1)
        oSelf.opt.lookupKeyValueColumn = 'listItemKey';
      oSelf.opt.lookupFields = 'listItemDesc';
    },

    /**
     * Changes dynSelect dropdown items with a new list of items
     * @param {string} cListItemPairs Comma-separated list of new items. Format is: Text1,Id1,Text2,Id2 where Text[i] and Id[i] will form an item.
     * @example
     * // get the dynSelect object from the Form and call method for setting new items
     * // this could e.g. be placed inside an AfterDisplay Event of a Form
     * var newList    = 'Text1,Id1,Text2,Id2,Text3,Id3';
     * var oDynSelect = self.getObject2('sprache_bez').controller;
     * oDynSelect.setListItemPairs(newList);
     * @memberOf ak_dynselect
     */
    setListItemPairs: function(cListItemPairs) {
      const oSelf = this;
      const oForm = akioma.getForm(this);

      oSelf.bNewValue = false;

      const aNewOptions = [];
      const aListItemPairs = cListItemPairs.split(',');
      for (let i = 0; i < aListItemPairs.length; i++) {
        aNewOptions.push({
          id: aListItemPairs[i + 1],
          text: aListItemPairs[i]
        });
        i++;
      }

      oForm.dhx.comboValues[oSelf.opt.name] = aNewOptions;
      oSelf.opt.listItems = cListItemPairs;
      oSelf.opt.aNewOptions = aNewOptions;

      this.dynSelectControl = new akioma.DynSelect(oSelf, oSelf.dhx);

      this.val.id = aListItemPairs[1];
      this.val.desc = aListItemPairs[0];

      $(oSelf.dhx).find('select').trigger('change');
    },

    // get value *****************
    /**
     * This Method returns the value of the dynselect depending on given parameter('id','desc')
     * @param  {String} cType The type('id','desc'), default is 'id'
     * @return {String} The value of the dynselect
     * @instance
     * @memberOf ak_dynselect
     */
    getValue: function(cType) {

      switch (cType) {
        case 'id':
          return this.val.id;
        case 'desc':
          return akioma.decodeHTML(this.val.desc);
        case 'key':
        // return this.val.key;
          break;
        default:
          return this.val.id || '';
      }

    },
    /**
     * Sets the current dynselect as readonly
     * @instance
     * @memberOf ak_dynselect
     * @param {boolean} bVal
     */
    setReadonly: function(bVal) {
      this.readonly = bVal;

      if (this.select2) {
        const oElement = $(this.select2[0].nextSibling).find('span.select2-selection');
        const oFormControl = $(this.select2).parent();
        const select2Selection = this.select2.data('select2').selection;

        (bVal) ? (select2Selection._tabindex = -1) : (select2Selection._tabindex = 0);
        (bVal) ? oFormControl.addClass('akReadOnlyDynselect') : oFormControl.removeClass('akReadOnlyDynselect');
        (bVal) ? oElement.addClass('readonlyDynSelect') : oElement.removeClass('readonlyDynSelect');

        if (this.opt.multiple) {
          const oInput = oElement.find('input.select2-search__field');

          if (bVal)
            oInput.attr('readonly', 'readonly');
          else
            oInput.removeAttr('readonly');

        }
      }
    },

    /**
     * Setting the value of the dynselect
     * @param {Object} oValue DataSource record object.
     * @instance
     * @memberOf ak_dynselect
     */
    setValue: function(oValue) {
      const oSelf = this;
      const $select2Elm = $(this.dhx).find('select');
      this.afterPreloadData.then(() => {

        // For listItemPairs: If oValue is string, just set the value. If oValue is object, value is taken from Form BE, using this.opt.name
        if (this.listItemPairs) {
          if (typeof (oValue) !== 'object') {
            this.select2.val(oValue).trigger('change');
            this.val.id = oValue || ''; // hdl

            for (const x in this.listItemsArray) {
              if (this.listItemsArray[x].value == oValue)
                this.val.desc = oValue[this.opt.name];
            }
          } else {
            this.select2.val(oValue[this.opt.name]).trigger('change');
            this.val.id = oValue[this.opt.name] || ''; // hdl

            for (const x in this.listItemsArray) {
              if (this.listItemsArray[x].value == oValue[this.opt.name])
                this.val.desc = oValue[this.opt.name];
            }
          }

          if (!oSelf.bPausedOldValueSet) {
            this._old_id = this.val.id;
            this._old_desc = this.val.desc;
          }

          return;
        }

        // Some special case here
        if (this.opt.lookupKeyValueBinding == '_self') {
          let cVal = oValue[this.opt.name];
          if (cVal == undefined)
            cVal = '';
          this.val.id = cVal; // for combo bind
          if (oSelf.opt.LookupKeyField) {
            const oFound = _.find(oSelf.oPreloadedData, item => item['selfhdl'] == oValue[oSelf.opt.name]);
            this.val.desc = (oFound) ? oFound[oSelf.opt.LookupKeyField] : '';
          }

        // Handling for Preload Data
        } else if (this.opt.preLoad) {

          let cVal = oValue[this.opt.name];
          if (cVal == undefined)
            cVal = '';
          this.val.id = cVal; // for combo bind
          const oFound = _.find(this.oPreloadedData, item => item[this.opt.lookupKeyValueColumn] == oValue[this.opt.name]);
          this.val.desc = (oFound) ? this.displayMultiFields(oFound) : '';

        // Normal behaviour
        } else {
          const cVal = oValue[this.opt.lookupKeyValueBinding];

          if (this.opt.multiple) {
            this.lookupKeyValueColumn_multiple = (oValue[this.opt.lookupKeyValueBinding]) ? oValue[this.opt.lookupKeyValueBinding].split(this.multiDelimiter) : [];
            if (oValue._id)
              this.lookupKeyValueColumn_multipleMap = this.lookupKeyValueColumn_multiple.reduce((obj, key) => (obj[key.toLowerCase()] = { id: key, text: key }, obj), {});
            this.dynSelectControl._multipleSetValues(oValue);
          }

          this.val.id = cVal; // hdl
          this.val.desc = (!isNull(oValue[this.opt.name])) ? akioma.encodeHTML(oValue[this.opt.name]) : '';
        }

        if (oValue[this.opt.name] == undefined || oValue[this.opt.name] == null)
          oValue[this.opt.name] = '';


        // Create HTML Option element and append it to dynSelect
        if (!this.bSelected) {
          this.bNewValue = false;

          $select2Elm.val(null).trigger('change');
          if (!this.listItemPairs)
            $select2Elm.empty();

          let option;
          if (this.opt.multiple) {
            this.dynSelectControl._multipleDisplayValues($select2Elm, this.val.id, this.val.desc);
            this.removeEmptyTag_dynSelect($select2Elm[0]);
          } else {
            option = new Option(this.val.desc, this.val.id, false, false);
            $(option).data('custom', this.val.desc);
            let ref = this.val.id;
            if (typeof ref == 'string')
              ref = replaceAllOccurencesInString(this.val.id, '"', '\\"');
            if ($select2Elm.find(`option[value="${ref}"]`).length > 0)
              $select2Elm.val(this.val.id);
            else {
              $select2Elm.append(option);
              $select2Elm.val(this.val.id);
            }
            $select2Elm.trigger('change');

          }
          if (oSelf.listItemPairs)
            $(option).data('listItemPairs', true);
        }

        if (!isNull(this.val.id))
          $(this.dhx).addClass('active');
        else
          $(this.dhx).removeClass('active');

        if (!this.bPausedOldValueSet) {
          this._old_id = this.val.id;
          this._old_desc = this.val.desc;
        }

        const akEvent = {};
        akEvent.lastValue = {
          desc: (this.dynObject && this.dynObject.akEvent) ? this.dynObject.akEvent.currentValue.desc : undefined,
          id: (this.dynObject && this.dynObject.akEvent) ? this.dynObject.akEvent.currentValue.id : undefined
        };
        akEvent.currentValue = {
          desc: this.val.desc,
          id: this.val.id
        };
        akEvent.currentValueNative = akEvent.currentValue.id;
        this.dynObject.akEvent = akEvent;

      });

    },

    getExtValue: function(oSource) {
      // get value
      const cValueHdl = oSource.getFieldValue(this.opt.extHdl);

      this.dynSelectControl.positionToRecord(cValueHdl);

      // set data changed
      if (akioma.getForm(this).dataSource)
        akioma.getForm(this).dataSource.setChanged(true);

    },


    /**
     * Displays multiple fields in dynselect after a record selection.
     * @param  {Object} oSelected The object you have selected
     * @return {String}           The displayed result
     * @private
     * @instance
     * @memberOf ak_dynselect
     */
    displayMultiFields: function(oSelected) {
      // This method should be in dynSelectUtils. Should be the same handling in Form and Grid.
      const oSelf = this;
      let cRes = '';
      let bField = false;

      if (oSelf.opt.lookupKeyField) {
        const aFields = oSelf.opt.lookupKeyField.split(',');
        for (let i = 0; i < aFields.length; i++) {
          const cField = aFields[i];
          if (cField.indexOf('\'') == -1) {
            if (!isNull(oSelected[cField])) {
              cRes += oSelected[cField];
              bField = true;
            }
            if (oSelf.bPosRecord) {
              cRes = oSelected.text;
              bField = true;
            }
          } else
            cRes += cField.replace(/["']/g, '');

        }
      }

      if (bField == false)
        cRes = '';
      oSelf.cLookupToDisplay = akioma.encodeHTML(cRes);
      return cRes;
    },

    /**
     * Gets BusinessEntity data for selected record in dynSelect or the record selected from chooseWindow.
     * @return {Object} Returns record data
     * @instance
     * @memberOf ak_dynselect
     */
    getSelectedRecord: function() {
      if (!isNull(this.oPositionedElement))
        return this.oPositionedElement;

      const cursor = this.businessEntity.dhx.getCursor();
      return this.businessEntity.dhx.item(cursor);
    },

    /**
     * Gets BusinessEntity data for Form record
     * @param  {string}  [storeName] Specifies the store name, only needed for multiStore businessEntity.
     * @return {Object} Returns BusinessEntity data
     * @instance
     * @memberOf ak_dynselect
     */
    getFormRecord: function(storeName) {
      const oForm = akioma.getForm(this);
      if (oForm.dataSource) {
        if (oForm.dataSource.view == 'businessEntity')
          return oForm.dataSource.dhx.data.pull[oForm.dataSource.dhx.getCursor()];
        else {
          const store = oForm.dataSource.getStore(storeName);
          return store.data.pull[store.getCursor()];
        }
      } else
        return oForm.formStore.item(oForm.formStore.getCursor());
    },

    /**
     * Checks if the dynSelect is a checkbox selection (option elements containing checkbox for selection).
     * @instance
     * @memberof ak_datagridcol2
     * @return {boolean|null}
     */
    getCheckboxSelection() {
      // Nullity safety check to handle any unforseen scenarios where options are null.
      if (isNull(this.opt)) return null;

      if (this.opt.template)
        return this.opt.template.toLowerCase() == 'genericautocompletesearchtemplatedynselect_checkbox';
      else
        return null;

    },
    /**
     * Method used for storing selection payload information
     * @param {object} data Payload from selection event
     * @instance
     * @memberof ak_dynselect
     * @private
     */
    _setSelectionPayload(data) {
      this._selectionPayload = data;
    },
    /**
     * Method to return last selection payload
     * @returns {object}
     * @memberof ak_dynselect
     * @instance
     */
    getSelectionPayload() {
      return this._selectionPayload;
    },
    /**
     * Method called when a user selects a result.
     * @param  {Object} event select3 event Object
     * @private
     * @instance
     * @memberOf ak_dynselect
     */
    selectionChanged: function(event) {

      const oSelf = this;
      let data = jQuery.extend(true, {}, event.params.data);

      this._setSelectionPayload(data);

      // Flag for detecting we're in select mode
      this.bSelected = true;

      if ($.inArray(this.parent.view, this.ribbonViews) !== -1) {
        // call validate event
        if (this.opt.validateEvent)
          app.controller.callAkiomaCode(this, this.opt.validateEvent);
      } else if (data) {

        if (oSelf.opt.multiple && event.target) {
          oSelf.removeEmptyTag_dynSelect(event.target.nextSibling);
          if (oSelf.bNewValue)
            $(oSelf.dhx).find('input').val('');
        }

        $(this.dhx).addClass('active');

        if (!oSelf.bPosRecord)
          oSelf.oPositionedElement = null;

        if (oSelf.bNewValue && (oSelf.bPosRecord == undefined || oSelf.bPosRecord == false)) {
          // Handling for new values coming from tags attribute
          data[this.opt.lookupKeyValueColumn] = data.id;
          oSelf.cLookupToDisplay = data.id;
        } else if (oSelf.businessEntity)
          oSelf.businessEntity.dhx.setCursor(data.id);
        oSelf.pauseOldValueUpdate();

        const akEvent = {};
        akEvent.lastValue = {
          desc: (this.dynObject && this.dynObject.akEvent) ? this.dynObject.akEvent.currentValue.desc : undefined,
          id: (this.dynObject && this.dynObject.akEvent) ? this.dynObject.akEvent.currentValue.id : undefined
        };

        if (oSelf.opt.multiple) {
          try {
            data = oSelf.dynSelectControl._multipleUpdateLookupKeyValueColumn(data);
            data = oSelf.dynSelectControl._multipleUpdateLookupFields(data);
          } catch (e) {
            console.error(e);
          }
        }

        this.dynSelectControl._updateLookupKeyValueBinding(oSelf.form, data);

        const oCurItem = oSelf.getFormRecord('eSmartObjectMaster');
        this.dynSelectControl._updateLookupControls(oSelf.form, oCurItem, data);

        oSelf.resumeOldValueUpdate();

        // if _self is specified then use the lookup selfhdl field/ special case used for combo
        if (this.opt.lookupKeyValueBinding == '_self')
          this.val.id = data['selfhdl'];
        else
          this.val.id = (oSelf.listItemPairs) ? data.listItemKey : data[oSelf.opt.lookupKeyValueColumn];

        // use the selected dropdown value or if not available use the data lookupKeyField value
        let cDesc = oSelf.cLookupToDisplay;
        if (!cDesc || oSelf.opt.multiple)
          cDesc = data[oSelf.opt.lookupKeyField];

        this.val.desc = cDesc;
        $(this.dhx).find('span.select2-selection').data('bItemSelected', true);

        akEvent.currentValue = {
          desc: this.val.desc,
          id: this.val.id
        };
        akEvent.currentValueNative = akEvent.currentValue.id;

        this.dynObject.akEvent = akEvent;

        this._checkHasChanges();

        if (this.opt.validateEvent && !this.ignoreValidateEvent)
          app.controller.callAkiomaCode(this, this.opt.validateEvent);

        this.callRules({
          eventEntity: this.opt.InstanceName,
          eventName: 'onChange',
          eventSource: this.opt.InstanceName,
          eventObject: this
        });

        oSelf.validate();
      }

      this._old_id = this.val.id;
      this._old_desc = this.val.desc;

      oSelf.bSelected = false;
      oSelf.bChooseWindow = false;

    },
    /**
     * Method to check if current selection is a value change and apply hasChanges
     * @memberof ak_dynselect
     * @private
     * @instance
     */
    _checkHasChanges() {
      // if record not positioned and if oldvalue is not the same as the new value then dispatch vuex setHasChanges
      if ((!this.bPosRecord || this.bChooseWindow) &&
      !(this.dynObject.akEvent.lastValue.id === this.dynObject.akEvent.currentValue.id &&
        this.dynObject.akEvent.lastValue.desc === this.dynObject.akEvent.currentValue.desc)) {
        const oForm = akioma.getForm(this);

        oForm.setFieldHasChanges(this.opt.name, true);
      }
    },
    /**
     * Stops update in setValue of old values selected in current dynselect
     * @memberOf ak_dynselect
     * @instance
     * @return {void}
     */
    pauseOldValueUpdate: function() {
      const oSelf = this;
      oSelf.bPausedOldValueSet = true;
    },
    /**
     * Resumes update in setValue of old values selected in current dynselect
     * @memberOf ak_dynselect
     * @instance
     * @return {void}
     */
    resumeOldValueUpdate: function() {
      const oSelf = this;
      oSelf.bPausedOldValueSet = false;
    },

    /**
     * Removes empty tags and inline search bugs
     * @memberOf ak_dynselect
     * @instance
     */
    removeEmptyTag_dynSelect: function(elem) {
      const tag = $(elem).find('li.select2-selection__choice[title=""] , li.select2-selection__choice:not([title])');
      if (tag.length > 0)
        tag.remove();
      if ($(elem.parentElement).find('select option:first').text() == '')
        $(elem.parentElement).find('select option:first').remove();

    },
    /**
     * Parse dynselect data for preloading the dynselect records
     * @param  {Object} res IndexedDb PromiseCache result
     * @param {String} timestampLastChanged The returned GetTimeLastChanged
     * @param  {Deferred} def The promise to resolve for final data display, waiting for options load
     * @private
     * @instace
     * @memberOf ak_dynselect
     * @return {Promise}
     */
    _parseLoadedDynselect: function(res, def, timestampLastChanged) {
      const oSelf = this,
        oForm = akioma.getForm(oSelf);

      // if there was an entry in the indexeddb, compare if saved timestamp is older than the timestampLastChanged
      if (res.data && timestampLastChanged === res.timestamp && !isNull(timestampLastChanged)) {
        if (oSelf.businessEntity)
          oSelf._setBatchSize();

        def.resolve(res.data);
      } else {
        // load from BE, preload data
        oSelf.businessEntity.addAfterCatalogAdd(() => {
          oSelf.businessEntity.setNamedQueryParam('AutoCompleteSearch', 'SearchKey', '', 'character');
          const oQuery = oSelf.businessEntity.getQuery();

          if (oSelf.opt.defaultSort !== '') {
            oQuery.setStringSorting(oSelf.opt.defaultSort);
            oQuery.buildQuery();
          }

          oSelf.businessEntity.aAfterFillOnce = [];

          oSelf.businessEntity.addAfterFillOnceCallback(rows => {
            oSelf._setBatchSize();

            const aRecs = [];
            rows.each(item => {
              aRecs.push(item);
            });
            // add to indexdb cache
            akCacheDb.addToCache(`${oForm.opt._ObjectName}_${oSelf.opt._InstanceName}`, timestampLastChanged, aRecs);
            def.resolve(aRecs);
          });
          oSelf.businessEntity.openQuery({ applyFilters: true });

        });
      }


    },
    /**
     * Sets dynselect data when preloading the dynselect records and record selection
     * @param  {Object} result records from dataSource
     * @private
     * @instace
     * @memberOf ak_dynselect
     * @return {void}
     */
    _setPreloadDataSelection: function(result) {
      const oDyn = this;

      oDyn.oPreloadedData = result;
      for (const x in result) {
        const item = result[x];
        if ((oDyn.opt.LookupKeyValueBinding == '_self' && item['selfhdl'] == oDyn.getValue()) ||
          item[oDyn.opt.lookupKeyValueColumn] === oDyn.getValue()) {

          const oElement = { 'params': { 'data': item } };

          oDyn.bPosRecord = true;
          oDyn.dynSelectControl.setExtValue(item);
          oDyn.oPositionedElement = item;
          oDyn.selectionChanged(oElement);
          oDyn.bPosRecord = false;
        }
      }

      this.afterPreloadData.resolve(result);
    },

    /**
     * Method for removing values dynamically from multiple dynselect
     * @param keys the string or an array of string values to remove from the dynselect.
     * @instance
     * @memberOf ak_dynselect
     */
    removeSelection(keys) {
      const oSelf = this;
      const $select2 = oSelf.select2;

      if (!oSelf.opt.multiple)
        return;

      if (keys instanceof Array && keys.length == 0)
        return;

      if (!(keys instanceof Array))
        keys = keys.split(',');

      keys.forEach(key => {
        const data = oSelf.lookupKeyValueColumn_multipleMap[key.toLowerCase()];
        if (!isNull(data)) {
          $select2.trigger({
            type: 'select2:unselect',
            params: { data: data }
          });
        }
      });

      oSelf.bRemove = false;
      const remainingKeys = Object.keys(oSelf.lookupKeyValueColumn_multipleMap).map(key => oSelf.lookupKeyValueColumn_multipleMap[key].id);
      $select2.val(remainingKeys);
      $select2.trigger('change');
    },

    /**
     * Method called when a user removes a result.
     * @param  {Object}   e    select2 event Object
     * @private
     * @instance
     * @memberOf ak_dynselect
     */
    selectionRemoved: function(e) {
      const oSelf = this;
      oSelf.bSelected = false;
      // Marks the start of the remove selection
      oSelf.bRemove = true;
      oSelf.bPreventLeave = true;

      let data = jQuery.extend(true, {}, e.params.data);

      if (data == undefined)
        data = {};

      const oCurItem = oSelf.getFormRecord(oSelf.form.opt.entityName);

      if (oSelf.opt.multiple) {
        // Prevents options being cleared and added again in setValue (due to _updateLookupControls -> which calls setValue)
        oSelf.bSelected = true;
        const index = oSelf.lookupKeyValueColumn_multiple.indexOf(e.params.data[this.opt.lookupKeyValueColumn] || e.params.data.id);
        data = oSelf.dynSelectControl._multipleUpdateLookupKeyValueColumn(data, index);
        data = oSelf.dynSelectControl._multipleUpdateLookupFields(data, index);
      } else
        data[this.opt.lookupKeyValueColumn] = this._getEmptyValue(oCurItem ? oCurItem[this.opt.lookupKeyValueBinding] : null);

      const event = {};
      event.lastValue = {
        desc: (this.dynObject.akEvent.currentValue.desc != undefined) ? this.dynObject.akEvent.currentValue.desc : undefined,
        id: (this.dynObject.akEvent.currentValue.id != undefined) ? this.dynObject.akEvent.currentValue.id : undefined
      };

      this.dynSelectControl._updateLookupKeyValueBinding(oSelf.form, data);
      this.dynSelectControl._updateLookupControls(oSelf.form, oCurItem, data, 'remove');

      oSelf.bRemoveVal = true;
      oSelf.bSelected = false;

      if (oSelf.opt.multiple) {
        const joinedValues = oSelf.lookupKeyValueColumn_multiple.join(oSelf.multiDelimiter);
        event.currentValue = {
          desc: joinedValues,
          id: joinedValues
        };
        event.currentValueNative = event.currentValue.id;
        this.val.id = joinedValues;
        this.val.desc = joinedValues;
      }

      this.dynObject.akEvent = event;

      if (!oSelf.opt.multiple) {
        event.currentValue = {
          desc: '',
          id: ''
        };
        event.currentValueNative = event.currentValue.id;
        this.val.id = '';
        this.val.desc = '';
      }

      const oForm = akioma.getForm(this);
      oForm.setFieldHasChanges(this.opt.name, true);

      oSelf.dynSelectControl.removeOption(e.params.data.id);

      if (!this.val.id)
        oSelf.businessEntity.dhx.setCursor();

      if (this.opt.validateEvent && !this.ignoreValidateEvent)
        app.controller.callAkiomaCode(this, this.opt.validateEvent);

      oSelf.validate();

      if (oSelf.opt.multiple)
        $(oSelf.dhx).find('input').focus();
    },

    /**
     * This Method is called from the clear event of the select2 and can be used to clean up value
     */
    clearSelect: function() {
      this.val.id = '';
      this.val.desc = '';
      this.oPositionedElement = null;
      if (this.businessEntity)
        this.businessEntity.dhx.setCursor(null);
      if (this.opt.multiple)
        this.dynSelectControl._multipleClear();
    },
    /**
     * This method is called before the dropdown is opened. Can prevent opening the dropdown.
     * @param  {Object} e   Event
     * @param  {HTMLNode} tag
     * @private
     * @instance
     * @memberOf ak_dynselect
     */
    openingResults: function(e, tag) {
      const oSelf = this;
      const originalEvent = e.params.args.originalEvent;
      oSelf.bSelected = false;

      // Prevents backend request in case of chooseWindow or removing selection
      $(oSelf.select2).data('bMakeRequest', true);

      if (oSelf.bRemove) {
        $(oSelf.select2).data('bMakeRequest', false);
        oSelf.bRemove = false;
      }

      // If readonly, prevent dropdown to open
      if (oSelf.readonly) {
        e.preventDefault();
        return;
      }

      // In case of defaultAction button set in Ribbon or Toolbar, this will prevent Enter key to open the dropdown.
      if (originalEvent && originalEvent.which == 13) {
        if ($(this.dhx).find('span.select2-selection').data('bItemSelected'))
          e.preventDefault();
      }

      if (oSelf.opt.lookupDialog && originalEvent) {
        const cElem = $(originalEvent.target).prop('tagName');
        const cClass = $(originalEvent.target).attr('class');
        if (cElem == 'SPAN' && cClass === 'select2-selection__lookup') {
          oSelf.dynSelectControl.launchLookupDialog();
          $(oSelf.select2).data('bMakeRequest', false);
          e.preventDefault();
        }
      }

      // Prevents opening the dropdown after clicking on clear button
      if ($(tag).data('unselecting')) {
        $(tag).removeData('unselecting');
        e.preventDefault();
      }

      const bEnabled = oSelf.form._getters('getFormFieldEnabled', oSelf.opt.id);
      if (bEnabled)
        $(oSelf.dhx).addClass('active').addClass('focusedinp');
      if (oSelf.opt.focusEvent) {
        /**
         * Code executed on the client side when a dynselect is in focus.
         * @event ak_dynselect#focusEvent
         * @type {object}
         */
        app.controller.callAkiomaCode(oSelf, oSelf.opt.focusEvent);
      }
    },

    /**
     * This method is called when the dropdown is opened.
     * @private
     * @instance
     * @memberOf ak_dynselect
     */
    openResults: function() {
      const oSelf = this;

      // Flag for opened dropdown
      oSelf.bOpened = true;

      if (oSelf.opt.preSelectExistingInput && !oSelf.opt.listItemPairs) {
        const oInputSearch = $(oSelf.select2).data('select2').$dropdown.find('input.select2-search__field');
        $(oInputSearch).val(oSelf.val.desc);
        $(oInputSearch).select();
        $(oInputSearch).trigger('keyup');
      }

      // SWAT-4467 set timeout to 10 to fix focus-trap library issues; focus-trap Line 120
      if (oSelf.select2.data().select2.dropdown.$search)
        akioma.swat.MasterLayout.disableLastFocusTrap({ setReturnFocus: oSelf.select2.data().select2.dropdown.$search[0] });

    },

    /**
     * This method is called when the dropdown is closed.
     * @private
     * @instance
     * @memberOf ak_dynselect
     */
    closeResults: function() {
      this.bOpened = false;

      if (this.select2.data().select2.dropdown.$search)
        akioma.swat.MasterLayout.enableLastFocusTrap();
    },

    /**
     * This method is called before a selection is made. Can prevent selectionChanged method.
     * @param  {Object} e   Event
     * @private
     * @instance
     * @memberOf ak_dynselect
     */
    selectingResult: function(e) {
      const oSelf = this;

      // Prevent selecting a new value if there is already a pending request
      if (oSelf.businessEntity && oSelf.businessEntity._pendingrequest)
        e.preventDefault();

      if (oSelf.opt.multiple) {
        const data = e.params.args.data;
        const index = oSelf.lookupKeyValueColumn_multiple.indexOf(data[oSelf.opt.lookupKeyValueColumn]);
        if (index !== -1)
          e.preventDefault();
      }
    },

    /**
     * This method is called before a selection is removed. Can prevent selectionRemoved method.
     * @param  {Object} e   Event
     * @param  {HTMLNode} tag
     * @private
     * @instance
     * @memberOf ak_dynselect
     */
    unselectingResult: function(e, tag) {
      if (this.readonly) {
        e.preventDefault();
        return;
      }
      $(tag).data('unselecting', true);
    },

    /**
     * Defines the empty value for a variable. Used on Form Datasource update, when one removes values from dynSelect
     * @param  {String|Number}  currentValue  The variable for which we need its empty value
     * @return {String|Number|null} The corresponding empty value
     * @private
     * @instance
     * @memberOf ak_dynselect
     */
    _getEmptyValue: function(value) {
      if (typeof (value) == 'number')
        return 0;
      if (typeof (value) == 'string')
        return '';
      return null;
    },

    /**
     * Validates the dynSelect control
     * @instance
     * @memberOf ak_dynselect
     */
    validate: function() {
      let bValid = true;
      let oForm = this.getAncestor('form');
      if (this.controlClass == 'dhxform_control') {
        oForm = akioma.getForm(this);
        if (oForm.dhx && oForm.dhx.live_validate)
          bValid = oForm.dhx.validateItem(this.opt.name);
        // if validation don't find the item, then it's valid
        if (isNull(bValid))
          bValid = true;
      }

      // set form Error State
      if (!bValid) {
        // add dirty errors state
        if (!oForm.oVuexState.attributes.hasErrors)
          oForm._dispatch('incrementHasErrors', 1);
        oForm._dispatch('setHasErrors', true);

      } else if (oForm.dhx) {
        const item = oForm.dhx.getDynSelect(this.opt.name);
        const fieldsWithErrorsNo = $(oForm.dhx.cont).find('.validate_error').length;
        const bSame = ($(oForm.dhx.cont).find('.validate_error').length == 0 || $(oForm.dhx.cont).find('.validate_error')[0] == item);
        // remove dirty errors state
        if (oForm.oVuexState.attributes.errors > 0 && fieldsWithErrorsNo <= 1 && bSame)
          oForm._dispatch('decrementHasErrors', 1);
      }
    },

    /**
     * Adds new dynselect object depeding on parent object type(ribbon, form)
     * @private
     * @instance
     * @memberOf ak_dynselect
     */
    addElement_toParent: function() {
      // adds dynSelect to its parent (form, ribbon)
      const oSelf = this;
      const oParent = this.parent;

      if ($.inArray(this.parent.view, this.formViews) !== -1) {
        this.controlClass = 'dhxform_control';
        this.opt.label = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });
      }
      if ($.inArray(this.parent.view, this.ribbonViews) !== -1)
        this.controlClass = 'dhxribbon_control';


      oSelf.addValidation();

      const dynSelectProp = {
        type: 'dynselect',
        inputTop: parseInt(this.opt.top),
        inputLeft: parseInt(this.opt.left),
        inputWidth: parseInt(this.opt.width),
        label: this.opt.label,
        labelTop: parseInt(this.opt.top),
        // labelLeft:      oParent.labelLeft( this ),
        required: (this.opt.required) ? this.opt.required : false,
        mandatory: (this.opt.required) ? this.opt.required : false,
        name: this.opt.name,
        hasError: false,
        errorMsg: '',
        enabled: this.opt.ENABLED,
        disabled: !this.opt.ENABLED,
        hidden: (this.opt.visible == false),
        note: { text: (this.opt.note) ? this.opt.note : '', width: this.opt.width },
        // readonly:       this.opt.disabled,
        className: (this.opt.autoComplete) ? '' : 'w4-formField w4-inputField',
        info: !!this.opt.infoButton,
        position: 'label-top',
        buttonIcon: (this.opt.buttonIcon) ? this.opt.buttonIcon : 'fa fa-search',
        multiple: (this.opt.multiple) ? true : false,
        placeholder: (this.opt.placeholder) ? (this.opt.placeholder) : '',
        control: this.controlClass,
        userdata: { id: this.opt.id }

      };

      switch (this.parent.view) {
        case 'form':
          oParent.elements.push(this);
          oParent.prop.fields.push(dynSelectProp);
          break;
        case 'fieldset': {
          const form = akioma.getForm(this);
          form.elements.push(this);
          oParent.prop.fields.push(dynSelectProp);
          break;
        }
        case 'block': {
          const form = akioma.getForm(this);
          form.elements.push(this);
          oParent.prop.fields.push(dynSelectProp);
          break;
        }
        case 'ribbonblock':
        case 'ribbon':
          oParent.prop.fields.push(dynSelectProp);
          break;
      }
    },
    /**
     * Creates a new footer menu structure in dropdown list.
     * @param  {String} structureCode The menu name code.
     * @param  {HTMLNode} element       The dropdown HTML
     * @private
     * @instance
     * @memberOf ak_dynselect
     */
    createMenuLookup: function(structureCode, element) {
      const oSelf = this;
      const aMainMenuOptions = [];
      let result;

      const mainMenu = app.controller.parseProc({
        view: 'menustructure',
        att: { id: structureCode },
        sub: []
      }, oSelf);

      const onMenuLoad = function() {
        mainMenu.scan((itemid, label, icon) => {
          aMainMenuOptions.push({ id: itemid, text: label, img: icon });
        });
        const templateMenu = Handlebars.compile(akioma.handlebarsTemplates.autocompleteSearchBT.templateMenu);
        const oData = { results: aMainMenuOptions };
        result = templateMenu(oData);
        mainMenu.result = result;
        oSelf.menuLookup = mainMenu;
        const select2ResultParent = $(element).parent();
        select2ResultParent.append(result);

        select2ResultParent.on('click', '.footer-add', function() {
          const id = $(this).attr('id');
          oSelf.menuLookup.applyAction(id, oSelf);
          oSelf.select2.select2('close');
        });
      };
      mainMenu.loadMenuElements(onMenuLoad);
    },
    /**
     * Adds SmartMessage validation
     * @private
     * @instance
     * @memberOf ak_dynselect
     */
    addValidation: function() {
      if (this.opt.validation) {
        const oForm = akioma.getForm(this);
        const aValidations = this.opt.validation.split(',');
        for (const v in aValidations) {
          const cInstanceValidation = aValidations[v];
          const aInsValidations = cInstanceValidation.split(':');
          const cValidationType = aInsValidations[0].trim().toLowerCase();
          if (cValidationType == 'required')
            this.opt.required = true;
            // based on form item name and

          oForm.setValidationRule(this.opt.name,
            {
              type: cValidationType,
              msg: aInsValidations[2].trim(),
              val: aInsValidations[1].trim().toLowerCase()
            });
        }
      }
    },
    /**
     * Sets up keypress bindings for defaultActionEvent and popup dialog.
     * @param  {HTMLNode} selector The dynselect html element.
     * @param  {String} keys     The combination of keys.
     * @private
     * @instance
     * @memberOf ak_dynselect
     */
    mousetrapBinding: function(selector, keys) {
      const oSelf = this;
      $(selector).attr('tabindex', 1);
      const mousetrapBinding = Mousetrap(selector[0]);
      switch (keys) {
        case 'ctrl+enter':
          mousetrapBinding.bind('ctrl+enter', e => {
          /**
                   * Code executed on the client-side on ctrl+enter keypress.
                   * @event ak_dynselect#defaultActionEvent
                   */
            app.controller.callAkiomaCode(oSelf, oSelf.opt.defaultActionEvent);
            e.preventDefault();
          });
          break;
        case 'alt+l':
          mousetrapBinding.bind('alt+l', e => {
            $(oSelf.select2).select2('close');
            oSelf.dynSelectControl.launchLookupDialog();
            e.preventDefault();
            e.stopPropagation();
          });
          break;
        default:
          akioma.log.warn('Key secvention not working');
      }

      this.aMouseTrapBindings.push(mousetrapBinding);

    },
    /**
     * Reset keypress bindings for defaultActionEvent and popup dialog.
     * @private
     * @instance
     * @memberOf ak_dynselect
     */
    mousetrapUnbinding: function() {
      for (const i in this.aMouseTrapBindings) {
        const mousetrapBinding = this.aMouseTrapBindings[i];
        mousetrapBinding.reset();
      }
      this.aMouseTrapBindings = [];
    },

    // destroy ***************
    destroy: function() {
      const oSelf = this;
      oSelf.select2.off();
      this.form = null;
      oSelf.select2.parent().find('input.select2-search__field').unbind();
      oSelf.select2.data('select2').$dropdown.find('input.select2-search__field').unbind();
      $(oSelf.select2).select2('close').select2('destroy');

      if (oSelf.businessEntity)
        oSelf.businessEntity.destroy();

      this.mousetrapUnbinding();

      $(document).off('keyup', '.select2-search__field', this._onKeyUp);
    }

  });

})(jQuery, jQuery);
