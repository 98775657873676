(function($) {

  // ***************** chart ******************
  $.extend({
    /**
     * The Akioma Diagram Object
     * @param  {Object} options Rpository Object Attributes
     * @class  ak_diagram
     * @tutorial diagram-desc
     */
    ak_diagram: function(options) {
      const oSelf = this,
        defaults = { page: 0 };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.parent.setOption('title', 'Diagram');

      this.registerDynObject = true;
      function Permission(locked, createEdges, editEdges, editVertices, cloneCells) {
        this.locked = (locked != null) ? locked : false;
        this.createEdges = (createEdges != null) ? createEdges : true;
        this.editEdges = (editEdges != null) ? editEdges : true;
        this.editVertices = (editVertices != null) ? editVertices : true;
        this.cloneCells = (cloneCells != null) ? cloneCells : true;
      }

      Permission.prototype.apply = function(graph) {
        graph.setConnectable(this.createEdges);
        graph.setCellsLocked(this.locked);
      };


      oSelf.aLinkTypes = [];
      oSelf.loadLinkTypes();


      // get parent
      const oParent = this.parent.dhx;
      if (oParent) {
        // Checks if the browser is supported
        if (!mxClient.isBrowserSupported()) {
          // Displays an error message if the browser is not supported.
          mxUtils.error('Browser is not supported!', 200, false);
        } else {

          const container = $(oParent.cell).find('> .dhx_cell_cont_layout')[0];
          $(oParent.cell).find('> .dhx_cell_cont_layout').attr('diagramoverflow', 'true');
          $(oParent.cell).find('> .dhx_cell_cont_layout').attr('tabindex', '1');
          oSelf.htmlContainer = container;
          // Replaces the port image
          mxConnectionHandler.prototype.connectImage = new mxImage('../mxgraph-master/javascript/src/images/connector.gif', 16, 16);
          // mxEvent.disableContextMenu(container);
          oSelf.graph = new mxGraph(container); // Creates the graph inside the given container
          oSelf.graph.setConnectable(true);
          oSelf.graph.setAllowDanglingEdges(false); // Prevents creating links without a target
          oSelf.graph.setDisconnectOnMove(false); // Prevents links to disconnect from source and target

          // Enable tooltips, disables mutligraphs, enable loops
          oSelf.graph.setMultigraph(true);
          oSelf.graph.setAllowLoops(true);

          // Curved edges
          const style = oSelf.graph.getStylesheet().getDefaultEdgeStyle();
          style[mxConstants.STYLE_CURVED] = '1';


          // Enables rubberband selection and key handling
          new mxRubberband(oSelf.graph);
          new mxKeyHandler(oSelf.graph);

          const layout = new mxHierarchicalLayout(oSelf.graph);

          oSelf.graphMouseTrap = new Mousetrap(container);

          // Assigns the delete key
          oSelf.graphMouseTrap.bind('del', () => {
            // if(e.keyCode == 46) {
            if (oSelf.graph.isEnabled()) {
              oSelf.removeEdge = true;
              oSelf.graph.removeCells();
            }
            // }
          });

          $(container).on('focusout', () => {
            oSelf.graph.clearSelection();
          });


          let currentPermission = null;

          const apply = function(permission) {
            oSelf.graph.clearSelection();
            permission.apply(oSelf.graph);
            oSelf.graph.setEnabled(true);
            oSelf.graph.setTooltips(true);

            // Updates the icons on the shapes - rarely
            // needed and very slow for large graphs
            oSelf.graph.refresh();
            currentPermission = permission;
          };
          apply(new Permission());

          // Extends hook functions to use permission object. This could
          // be done by assigning the respective switches (eg.
          // setMovable), but this approach is more flexible, doesn't
          // override any existing behaviour or settings, and allows for
          // dynamic conditions to be used in the functions. See the
          // specification for more functions to extend (eg.
          // isSelectable).
          const oldDisconnectable = oSelf.graph.isCellDisconnectable;
          oSelf.graph.isCellDisconnectable = function() {
            return oldDisconnectable.apply(this, arguments) && currentPermission.editEdges;
          };

          const oldTerminalPointMovable = oSelf.graph.isTerminalPointMovable;
          oSelf.graph.isTerminalPointMovable = function() {
            return oldTerminalPointMovable.apply(this, arguments) && currentPermission.editEdges;
          };

          const oldMovable = oSelf.graph.isCellMovable;
          oSelf.graph.isCellMovable = function() {
            return oldMovable.apply(this, arguments) && currentPermission.editVertices;
          };

          const oldResizable = oSelf.graph.isCellResizable;
          oSelf.graph.isCellResizable = function() {
            return oldResizable.apply(this, arguments) && currentPermission.editVertices;
          };

          oSelf.graph.addListener(mxEvent.CLICK, (sender, evt) => {
            if (evt.properties.cell) {
              const propertyGrid = oSelf.dynObject.getLink('USER1:SOURCE').getFirstChildByType('propertygrid').dynObject;
              if (!isNull(propertyGrid)) {
                const guid = evt.properties.cell.id;
                const dataSource = oSelf.dataSource.dynObject;
                akioma.repository.positionDesignerCursor({
                  dataSource,
                  propertyGrid,
                  guid
                });
              }
            }
            oSelf.htmlContainer.focus();
          });

          oSelf.graph.addListener(mxEvent.CELLS_REMOVED, (sender, evt) => {
            for (const cell of evt.properties.cells) {
              if (!oSelf.preventDelete) {
                if (cell.edge)
                  oSelf.removeLink(cell.source.id, cell.target.id, cell.value);
                else {
                  const designer = oSelf.dynObject.container.getFirstChildByType('designer');
                  designer._removeInstance(cell.id);
                }
              }
            }
            oSelf.removeEdge = false;
          });


          oSelf.graphParent = oSelf.graph.getDefaultParent();
          layout.execute(oSelf.graphParent);

        }

        // set floatingActionButton in panel
        if (this.opt.floatingActionButton) {
          const oFloatingActionTarget = this;
          this.parent.setOption('floatingActionButton', this.opt.floatingActionButton, oFloatingActionTarget);
        }
      }

    }
  });

  $.ak_diagram.prototype = {

    // finish construct **********
    finishConstruct: function() {
      const oSelf = this;
      oSelf.aLinks = [];
      // oSelf.aLinksAdded = [];
      oSelf.aLinksDeleted = [];

      // set title and panelMenu buttons in panel header
      akioma.setPanelHeader(oSelf);
    },

    endConstruct: function() {

    },

    /**
 * Method for populating the diagram
 * @param {object} eObjectMaster Object master
 * @param {object} eObjectInstances Object instances
 * @param {object} eSmartLinks Smart links
 * @param {object} businessEntity The businessEntity used
 * @instance
 * @memberOf ak_diagram
 */
    renderDiagram: function(eObjectMaster, eObjectInstances, eSmartLinks, businessEntity) {
      const oSelf = this;
      oSelf.eObjectMaster = eObjectMaster;
      oSelf.eSmartObjectInstance = eObjectInstances;
      oSelf.eSmartLink = eSmartLinks;
      oSelf.businessEntity = businessEntity;

      oSelf.cContainerObjectMasterGuid = oSelf.eObjectMaster[Object.keys(oSelf.eObjectMaster)[0]].objectmasterguid;

      oSelf.clearDiagram();
      const layout = new mxHierarchicalLayout(oSelf.graph);
      const layout1 = new mxParallelEdgeLayout(oSelf.graph);
      mxConstraintHandler.prototype.pointImage = new mxImage('../mxgraph-master/images/dot.gif', 10, 10);
      oSelf.graph.setConnectable(true);
      oSelf.graph.setPortsEnabled(false);

      oSelf.createObjectInstances();
      oSelf.createContainer();
      oSelf.createLinks();


      oSelf.graph.getModel().beginUpdate();
      layout.execute(oSelf.graphParent);
      layout1.execute(oSelf.graphParent);
      oSelf.graph.getModel().endUpdate();
    },

    /**
 * Method for clearing the diagram
 * @instance
 * @memberOf ak_diagram
 */
    clearDiagram: function() {
      this.preventDelete = true;
      this.graph.removeCells(this.graph.getChildVertices(this.graph.getDefaultParent()));
      this.preventDelete = false;
    },

    /**
 * Method for applying a layout to the diagram
 * @param {string} layoutType Layout type
 * @instance
 * @memberOf ak_diagram
 */
    applyDiagramLayout: function(layoutType) {
      const oSelf = this;
      let layout = null;
      let layout1 = null;
      switch (layoutType) {
        case 'hierarchical':
          layout = new mxHierarchicalLayout(oSelf.graph);
          break;
        case 'parallel':
          layout = new mxParallelEdgeLayout(oSelf.graph);
          break;
        case 'radial':
          layout = new mxRadialTreeLayout(oSelf.graph);
          break;
        case 'horizontal':
          mxCompactTreeLayout.prototype.levelDistance = 30;
          mxCompactTreeLayout.prototype.nodeDistance = 30;
          layout = new mxCompactTreeLayout(oSelf.graph);
          break;

        default:
          layout = new mxHierarchicalLayout(oSelf.graph);
          layout1 = new mxParallelEdgeLayout(oSelf.graph);
          break;
      }

      oSelf.graph.getModel().beginUpdate();
      layout.execute(oSelf.graphParent);
      if (!isNull(layout1))
        layout1.execute(oSelf.graphParent);
      oSelf.graph.getModel().endUpdate();
    },

    /**
 * Method for creating the links in diagram during rendering (init time)
 * @instance
 * @memberOf ak_diagram
 */
    createLinks: function() {
      const oSelf = this;
      oSelf.linkTypes = [];
      oSelf.linkTypesArray = [];
      if (oSelf.eSmartLink) {
        for (const key in oSelf.eSmartLink) {
          if (Object.prototype.hasOwnProperty.call(oSelf.eSmartLink, key)) {
            const smartLink = oSelf.eSmartLink[key];
            const oLink = {
              'containerobjectmasterguid': oSelf.cContainerObjectMasterGuid,
              'sourceobjectinstanceguid': (smartLink.sourceobjectinstanceguid) ? smartLink.sourceobjectinstanceguid : oSelf.containerGuid,
              'targetobjectinstanceguid': (smartLink.targetobjectinstanceguid) ? smartLink.targetobjectinstanceguid : oSelf.containerGuid,
              'sourceobjectname': smartLink.sourceobjectname,
              'targetobjectname': smartLink.targetobjectname,
              'linkname': smartLink.linkname,
              'linktypeguid': smartLink.linktypeguid,
              'id': smartLink.id
            };
            oSelf.createSourceTargetLink(oLink);
          }
        }
      }

      // This prevents adding the mxEvent.CONNECT multiple times
      const connectExists = (oSelf.graph.connectionHandler.eventListeners) ? oSelf.graph.connectionHandler.eventListeners.findIndex(element => element == 'connect') : -1;
      if (connectExists == -1) {
        oSelf.graph.connectionHandler.addListener(mxEvent.CONNECT, (sender, evt) => {

          const edge = evt.getProperty('cell');
          const source = edge.source;
          const target = edge.target;

          if (source && target) {
            if (!oSelf.contentLink)
              oSelf.contentLink = oSelf.createSelectLinkTypes();

            akioma.message({
              type: 'custom',
              title: akioma.tran('messageBox.title.diagramLink', { defaultValue: 'Choose link type' }),
              content: oSelf.contentLink,
              callback: function(result) {
                if (result) {
                  const link = $(oSelf.contentLink).find(':selected');
                  oSelf.graph.model.setValue(edge, link.text());
                  oSelf.addLink(source, target, link.val(), link.text());
                } else
                  oSelf.graph.getModel().remove(edge);
              }
            });
          } else {
            akioma.notification({
              type: 'info',
              text: 'Connect link to a target!'
            });
            oSelf.graph.getModel().remove(edge);
          }

        });
      }

      // This prevents adding the mxEvent.CELL_CONNECTED multiple times
      const cellConnectExists = (oSelf.graph.eventListeners) ? oSelf.graph.eventListeners.findIndex(element => element == 'cellConnected') : -1;
      if (cellConnectExists == -1) {
        oSelf.graph.addListener(mxEvent.CELL_CONNECTED, (sender, evt) => {

          const bSource = evt.properties.source;
          const oOldValue = evt.properties.previous;
          const oNewValue = evt.properties.terminal;
          const oEdge = evt.properties.edge;
          if (oOldValue)
            oSelf.updateLink(bSource, oOldValue, oNewValue, oEdge);

        });
      }
    },

    /**
 * Method for creating a link in diagram during rendering (init time)
 * @param {object} oLink Smart link
 * @instance
 * @memberOf ak_diagram
 */
    createSourceTargetLink: function(oLink) {
      const oSelf = this,
        objVertexes = oSelf.instancesVertex;

      if (objVertexes) {
        let objSource = objVertexes[oLink.sourceobjectinstanceguid];
        if (oLink.sourceobjectinstanceguid == '')
          objSource = objVertexes[oSelf.containerGuid];

        let objTarget = objVertexes[oLink.targetobjectinstanceguid];
        if (oLink.targetobjectinstanceguid == '')
          objTarget = objVertexes[oSelf.containerGuid];

        // Adds cells to the model in a single step
        oSelf.graph.getModel().beginUpdate();

        try {
          oSelf.aLinks.push(oLink);
          if (!oSelf.linkTypes[oLink.linktypeguid]) {
            oSelf.linkTypes[oLink.linktypeguid] = oLink.linkname;
            oSelf.linkTypesArray.push({ 'guid': oLink.linktypeguid, 'name': oLink.linkname });
          }

          if (objSource && objTarget) {
            const v1 = objSource.vertex;
            const v2 = objTarget.vertex;
            oSelf.graph.insertEdge(oSelf.graphParent, null, oLink.linkname, v1, v2);
          }

        } catch (e) {
          akioma.log.error(e);
        } finally {
          // Updates the display
          oSelf.graph.getModel().endUpdate();
        }
      }
    },

    /**
 * Method for creating the object instances in diagram during rendering (init time)
 * @instance
 * @memberOf ak_diagram
 */
    createObjectInstances: function() {
      const oSelf = this;
      oSelf.instancesVertex = [];
      // Adds cells to the model in a single step

      try {

        for (const key in oSelf.eSmartObjectInstance) {
          if (Object.prototype.hasOwnProperty.call(oSelf.eSmartObjectInstance, key)) {
            const objectInstance = oSelf.eSmartObjectInstance[key];
            oSelf.graph.getModel().beginUpdate();

            const cObjectType = oSelf.getObjType(objectInstance.instancetypename);
            const cIconPath = akioma.repository.getObjIcon(cObjectType);
            const style = oSelf.createShapeStyle(cIconPath, objectInstance.instancetypename);
            oSelf.graph.getStylesheet().putCellStyle('shape', style);

            // add shape in diagram
            const v = oSelf.graph.insertVertex(oSelf.graphParent, objectInstance.objectinstanceguid, objectInstance.instancename, 50, 50, 60, 50, 'shape');
            oSelf.instancesVertex[objectInstance.objectinstanceguid] = { obj: objectInstance, vertex: v };

            oSelf.graph.getModel().endUpdate();
          }
        }

      } catch (e) {
        !_isIE && console.error([ 'Error creating instances', this, e.message ]);
      }

    },

    /**
     * Method for creating the container in diagram during rendering (init time)
     * @instance
     * @memberOf ak_diagram
     */
    createContainer: function() {
      const oSelf = this;
      oSelf.containerGuid = oSelf.cContainerObjectMasterGuid;
      oSelf.graph.getModel().beginUpdate();
      const cIconPath = akioma.repository.getObjIcon('container');
      const style = oSelf.createShapeStyle(cIconPath, 'container');
      oSelf.graph.getStylesheet().putCellStyle('shape', style);
      const v = oSelf.graph.insertVertex(oSelf.graphParent, oSelf.containerGuid, '<This Object>', 50, 50, 90, 80, 'shape');
      oSelf.instancesVertex[oSelf.containerGuid] = { obj: '', vertex: v };

      oSelf.graph.getModel().endUpdate();

    },

    saveLinks: function(businessEntity) {
      businessEntity.switchJSDOWorkingRecord('eSmartLink');
    },

    /**
     * Method for adding a new link in the BE store
     * @param {object} source Source mxCell
     * @param {object} target Target mxCell
     * @param {string} linktypeguid Smart link type guid
     * @param {string} linkname Smart link name
     * @instance
     * @memberOf ak_diagram
     */
    addLink: function(source, target, linktypeguid, linkname) {
      // adds a link in the BE store
      const oSelf = this;
      const cLinkGuidUnique = uuid();
      const oLink = {
        'containerobjectmasterguid': oSelf.cContainerObjectMasterGuid,
        'sourceobjectinstanceguid': source.id,
        'targetobjectinstanceguid': target.id,
        'sourceobjectname': source.value,
        'targetobjectname': target.value,
        'linktypeguid': linktypeguid,
        'linkname': linkname,
        'linkguid': cLinkGuidUnique
      };
      oSelf.aLinks.push(oLink);

      oSelf.businessEntity.switchJSDOWorkingRecord('eSmartLink');
      const oLinkStore = oSelf.businessEntity.getStore('eSmartLink');
      const cNextAddedLinkID = oLinkStore.add({
        containerobjectmasterguid: oLink.containerobjectmasterguid,
        sourceobjectinstanceguid: (oLink.sourceobjectinstanceguid == oSelf.containerGuid) ? '' : oLink.sourceobjectinstanceguid,
        targetobjectinstanceguid: (oLink.targetobjectinstanceguid == oSelf.containerGuid) ? '' : oLink.targetobjectinstanceguid,
        linktypeguid: oLink.linktypeguid,
        linkname: oLink.linkname,
        linkguid: cLinkGuidUnique
      });

      oSelf.aLinks[oSelf.aLinks.length - 1].id = cNextAddedLinkID;

    },

    /**
         * Method for removing a link from the BE store
         * @param  {string} source   The source object instance guid of the link to remove
 * @param  {string} target   The target object instance guid of the link to remove
 * @param  {string} linkname The link name
         * @instance
         * @memberOf ak_diagram
         */
    removeLink: function(source, target, linkname) {
      const oLinkStore = this.businessEntity.getStore('eSmartLink');
      const aLinks = oLinkStore.data.pull;
      for (const i in aLinks) {

        // containerguid setup for delete
        let cTargetObjInsGuid = aLinks[i].targetobjectinstanceguid,
          cSourceObjInsGuid = aLinks[i].sourceobjectinstanceguid;

        if (cTargetObjInsGuid == '')
          cTargetObjInsGuid = this.containerGuid;
        if (cSourceObjInsGuid == '')
          cSourceObjInsGuid = this.containerGuid;

        if (source == cSourceObjInsGuid && target == cTargetObjInsGuid && linkname == aLinks[i].linkname) {
          if (this.removeEdge) {
            this.businessEntity.switchJSDOWorkingRecord('eSmartLink');

            if (aLinks[i] && aLinks[i]._id)
              oLinkStore.remove(aLinks[i]._id);


            if (aLinks[i] && aLinks[i].id)
              oLinkStore.remove(aLinks[i].id);

          }
          break;
        }
      }
    },

    updateLink: function(bSource, oOldValue, oNewValue, oEdge) {
      const oSelf = this;
      const oLinkStore = oSelf.businessEntity.getStore('eSmartLink');
      const aLinks = oLinkStore.data.pull;
      const oSource = oEdge.source;
      const oTarget = oEdge.target;

      oOldValue.id = (oOldValue.id == oSelf.containerGuid) ? '' : oOldValue.id;
      oNewValue.id = (oNewValue.id == oSelf.containerGuid) ? '' : oNewValue.id;
      oSource.id = (oSource.id == oSelf.containerGuid) ? '' : oSource.id;
      oTarget.id = (oTarget.id == oSelf.containerGuid) ? '' : oTarget.id;

      for (const i in aLinks) {
        let bUpdated = false;
        const oLink = Object.assign(aLinks[i], {});

        if (bSource) {
          if (oLink.sourceobjectinstanceguid == oOldValue.id && oLink.targetobjectinstanceguid == oTarget.id && oLink.linkname == oEdge.value) {
            oLink.sourceobjectinstanceguid = oNewValue.id;
            bUpdated = true;
          }
        } else if (oLink.sourceobjectinstanceguid == oSource.id && oLink.targetobjectinstanceguid == oOldValue.id && oLink.linkname == oEdge.value) {
          oLink.targetobjectinstanceguid = oNewValue.id;
          bUpdated = true;
        }

        if (bUpdated)
          oLinkStore.update(i, oLink);
      }
    },

    /**
         * Method for adding a new instance object in the diagram
         * @param  {object} newInstanceObject The instance store item
         * @instance
         * @memberOf ak_diagram
         */
    addInstanceObject: function(newInstanceObject) {
      this.graph.getModel().beginUpdate();

      // Icon handling
      const type = this.getObjType(newInstanceObject.instancetypename);
      const iconPath = akioma.repository.getObjIcon(type);
      const style = this.createShapeStyle(iconPath, type);
      this.graph.getStylesheet().putCellStyle('shape', style);

      // Add object
      const v = this.graph.insertVertex(this.graphParent, newInstanceObject.objectinstanceguid, newInstanceObject.instancename, 50, 50, 60, 50, 'shape');
      this.instancesVertex[newInstanceObject.objectinstanceguid] = { obj: newInstanceObject, vertex: v };
      this.graph.getModel().endUpdate();
      this.applyDiagramLayout();
    },

    /**
         * Method for deleting one or more instance objects from the diagram
         * @param  {string[]} instanceObjectGuids Array of instance object guids
         * @instance
         * @memberOf ak_diagram
         */
    deleteInstanceObjects: function(instanceObjectGuids) {
      if (!instanceObjectGuids.length > 0)
        return;

      this.graph.getModel().beginUpdate();

      for (const guid of instanceObjectGuids) {
        const v = this.instancesVertex[guid];
        if (!isNull(v))
          this.graph.removeCells([v.vertex]);
      }

      this.graph.getModel().endUpdate();
      this.applyDiagramLayout();
    },

    /**
         * Method for applying icons to object types
         * @param {string} image Icon
 * @param {string} objType Object type
         * @instance
         * @memberOf ak_diagram
         */
    createShapeStyle: function(image, objType) {
      const style = new Object();

      // image = type.charAt(0).toUpperCase() + type.slice(1);
      style[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_IMAGE;
      style[mxConstants.STYLE_VERTICAL_LABEL_POSITION] = mxConstants.ALIGN_BOTTOM;
      // style[mxConstants.STYLE_PERIMETER] = mxPerimeter[type + "Perimeter"];
      style[mxConstants.STYLE_IMAGE] = image;
      style[mxConstants.STYLE_FONTCOLOR] = '#000000';
      if (akioma.layoutSettings.isThemePlaygroundDark)
        style[mxConstants.STYLE_FONTCOLOR] = 'rgba(255, 255, 255, 0.7)';
      style[mxConstants.STYLE_IMAGE_WIDTH] = '48';
      style[mxConstants.STYLE_IMAGE_HEIGHT] = '48';
      style['customStyle'] = akioma.repository.getObjStyle(objType);
      return style;

    },

    /**
         * Method for loading the existing link types
         * @instance
         * @memberOf ak_diagram
         */
    loadLinkTypes: function() {

      const oSelf = this;
      const cResourceName = 'Consultingwerk.SmartFramework.Repository.Class.LinkTypeBusinessEntity';

      const oBEoptions =
            {
              'att': {
                cacheLimit: 50,
                multiStore: true,
                catalogURI: '',
                dataSource: '',
                entityName: 'eSmartLinkType',
                id: 'offerw45613645_businessEntity',
                identifier: 'selfhdl',
                name: 'businessEntity',
                rowsToBatch: 1000,
                resourceName: cResourceName,
                serviceURI: ''
              }
            };

      const businessEntity = new $['ak_businessEntity2'](oBEoptions);
      businessEntity.aCallback['afterFill'] = function(rows) {

        const oTableItems = rows[0];
        // load initial screen here...
        // create structure for designer for objects and instances
        oSelf.aLinkTypes = [];
        for (const i in oTableItems) {
          const row = oTableItems[i];
          oSelf.aLinkTypes.push({
            guid: row.linktypeguid,
            name: row.linkname
          });
        }

      };
      businessEntity.finishConstruct();
      businessEntity.endConstruct();


    },

    /**
         * Method for creating a Select containing the link types
         * @instance
         * @memberOf ak_diagram
         */
    createSelectLinkTypes: function() {
      const div = document.createElement('div');
      const select = $('<select>').appendTo(div);

      if (this.aLinkTypes.length > 0) {
        $(this.aLinkTypes).each(function() {
          select.append($('<option>').attr('value', this.guid).text(this.name));
        });
      } else {
        console.warn('no link types loaded yet!');
        return;
      }
      return div;
    },
    getObjType: function(cObjType) {
      cObjType = cObjType.toLowerCase();
      switch (cObjType) {
        case 'swatform':
          cObjType = 'form';
          break;
        case 'swattreegrid':
          cObjType = 'treegrid';
          break;
      }

      return cObjType;
    },

    makeAjaxCall: function(container) {
      const oSelf = this;

      oSelf.graph.removeCells(oSelf.graph.getChildVertices(oSelf.graph.getDefaultParent()));

      $.ajax({
        type: 'GET',
        url: `/web/Resource/Consultingwerk.SmartFramework.Repository.Object.ObjectMasterBusinessEntity?akQuery={%22ui_context%22:{%22controlType%22:%22%22,%22container%22:%22%22},%22filters%22:{%22logic%22:%22and%22,%22filters%22:[{%22field%22:%22ObjectName%22,%22operator%22:%22eq%22,%22value%22:%22${container}%22}]}}`,
        data: {},
        cache: false,
        success: function(data) {
          oSelf.renderDiagram(data.dsObjectMaster.eSmartObjectMaster, data.dsObjectMaster.eSmartObjectInstance, data.dsObjectMaster.eSmartLink);
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          akioma.log.info(`Status: ${textStatus}`);
          akioma.log.error(`Error: ${errorThrown}`);
        }
      });
    },

    createBusinessEntity: function() {
      const oSelf = this;

      const cResourceName = 'Consultingwerk.SmartFramework.Repository.Object.ObjectMasterBusinessEntity';
      const oBEoptions = {
        'att': {
          cacheLimit: 50,
          catalogURI: '',
          dataSource: '',
          entityName: 'eSmartObjectMaster',
          id: 'offerw45613645_businessEntity',
          identifier: 'ObjectMasterGuid',
          name: 'businessEntity',
          rowsToBatch: 1000,
          resourceName: cResourceName,
          serviceURI: ''
        }
      };

      const businessEntity = new $['ak_businessEntity'](oBEoptions);
      oSelf.businessEntity = businessEntity;
      businessEntity.finishConstruct();
      businessEntity.endConstruct();


    },

    manageConnectionConstraints: function() {
      // Returns all possible ports for a given terminal
      mxGraph.prototype.getAllConnectionConstraints = function(terminal) {
        if (terminal != null && terminal.shape != null) {
          if (terminal.shape.stencil != null) {
            if (terminal.shape.stencil != null)
              return terminal.shape.stencil.constraints;

          } else if (terminal.shape.constraints != null)
            return terminal.shape.constraints;

        }

        return null;
      };

      // Defines the default constraints for all shapes
      mxShape.prototype.constraints = [
        new mxConnectionConstraint(new mxPoint(0.25, 0), true),
        new mxConnectionConstraint(new mxPoint(0.5, 0), true),
        new mxConnectionConstraint(new mxPoint(0.75, 0), true),
        new mxConnectionConstraint(new mxPoint(0, 0.25), true),
        new mxConnectionConstraint(new mxPoint(0, 0.5), true),
        new mxConnectionConstraint(new mxPoint(0, 0.75), true),
        new mxConnectionConstraint(new mxPoint(1, 0.25), true),
        new mxConnectionConstraint(new mxPoint(1, 0.5), true),
        new mxConnectionConstraint(new mxPoint(1, 0.75), true),
        new mxConnectionConstraint(new mxPoint(0.25, 1), true),
        new mxConnectionConstraint(new mxPoint(0.5, 1), true),
        new mxConnectionConstraint(new mxPoint(0.75, 1), true)
      ];

      // Edges have no connection points
      mxPolyline.prototype.constraints = null;
    },

    destroy: function() {

      this.graphMouseTrap.reset();
      delete this.graphMouseTrap;
    }
  };
})(jQuery, jQuery);
