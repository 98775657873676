//
// Visual Designer

(function($) {
  $.extend({
    /**
     * SwatLayoutDesign Control
     * @class ak_designer
     * @param {Object} options Repository attributes for SwatLayoutDesign.
     * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     * @param {string} options.contextMenu the id of a menuStructure which will be used as a context-menu
     * @param {string} options.titleHeader specifies which panelHeader to use. when empty, then uses the header of its own panel. if "none" then no header at all. if "parent" it uses the header of the parent panel
     * @param {string} options.floatingActionButton the id of a menustructure, which will be rendered as a FAB
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {string} options.panelMenu comma separated list of menu-structures which will be shown as panelHeader-Buttons </br>
     * Can also contain the flag #NoDropDown that specifies that the menu should not be loaded as a dropdown but each menu item should be added in the panel-Header.</br>
     * For example: </br>
     * <code>menuStructSave,menuSettings#NoDropDown,menuLookup</code> </br>
     * The buttons support font icons with the following attributes: </br>
     * 1. Css attributes, defined like this: fa fa-user#color:red </br>
     * 2. Css classes, defined like this: fa fa-user#_style:module_prod
     * 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod </br>
     */
    ak_designer: function(options) {
      const oSelf = this,
        defaults = {
          id: 'design-build-test',
          name: 'design builder'
        };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      this.registerDynObject = true;
      this.useParentDynObjectLink = true;
      this.newInstances = []; // property for storing new replaced instances in memory
      this.newPageInstances = []; // property for storing new page instances in memory
      this.lastSelectedInstance = null;
      this.newPlaceholderInstances = []; // property for storing new replaced instances of type placeholder in memory

      this.tempAttributes = {
        instances: [],
        masters: []
      };

      this.bChangedLayoutOptions = false;

      // attach new html element to parent
      const oParent = this.parent;
      if (oParent) {

        this.props = [];

        this.aPageGuids = [];
        this.aTempPageLabels = [];

        this.cLoadedObjMasterGuid = '';

        // oParent.dhx.attachHTMLString(cFormBuilderHTML);
        const oLayout = oParent.dhx.attachLayout({
          pattern: '1C',
          cells: [{ id: 'a', text: 'Designer build' }]
        });


        $(oLayout.cont).addClass('ak_designer');


        // initialization of container builder here
        this.dhx = new DesignerApp({
          container: oLayout.cells('a'),
          images: '/dhtmlx/dhx5-designer-sources/codebase/dhtmlx/imgs',
          skin: 'material',
          dnd: true,
          self: oSelf,
          base: '/dhtmlx/dhx5-designer-sources/',

          // gets new object properties from object master
          getCustomProperties: this._getNewObjectProperties.bind(this),

          // creates new object instance and remove the existing object instance if present
          onDragCallback: this.onDropEvent.bind(this),

          // gets dragged object type
          getCustomItemType: this.getObjDraggedType.bind(this),

          // event on tree context menu click in designer
          onTreeContextMenuClick: this._customTreeContextMenuClick,

          // event on dblclick in designer preview area
          afterDblClick: this.afterPreviewItemDblClick.bind(this)
        });

        // event triggered when updating the values of the object instance attributes or object master attributes
        this.dhx.getService('UIState').bus.attachEvent('store:onDataChange', oSelf._onPropertyUpdate.bind(this));

        oSelf.attachGraphEditorEvents();

        this.dhx.render();

      }
    }
  });

  // methods for form
  $.ak_designer.prototype = {
    /**
         * Method for applying window resize events
         * @private
         * @instance
         * @memberof ak_designer
         */
    resizeEvents: function() {

      const winControl = this.dynObject.container.controller;
      const oWindow = winControl.dhx;

      oWindow.attachEvent('onBeforeResizeStart', () => {
        if (this.dhx)
          $(this.dhx.root.cell).css('pointer-events', 'none');


        $(document.body).find('.docviewerframe').css('pointer-events', 'none');
        return true;
      });

      winControl.getDescendant('panelset').dhx.attachEvent('onPanelResizeFinish', () => {
        this.dhx.topCell.cells('a').setWidth(this.dhx.root.getWidth());
        this.dhx.topCell.cells('a').setHeight(this.dhx.root.getHeight());
      });

      oWindow.attachEvent('onResizeFinish', () => {
        if (this.dhx)
          $(this.dhx.root.cell).css('pointer-events', 'auto');


        $(document.body).find('.docviewerframe').css('pointer-events', 'auto');
      });

      oWindow.attachEvent('onBeforeMoveStart', () => {
        if (this.dhx)
          $(this.dhx.root.cell).css('pointer-events', 'none');


        $(document.body).find('.docviewerframe').css('pointer-events', 'none');

        return true;
      });
    },

    /**
         * Method for returning the new object type dragged
         * @instance
         * @memberof ak_designer
         * @param {object} sourceObject
         * @param {object} sourceId
         * @returns {string} type of object
         */
    getObjDraggedType: function(sourceObject, sourceId) {
      const oSelf = this;

      const oDraggedObj = sourceObject.akElm.dataSource.getStore(sourceObject.akElm.dataSource.opt.entityName).item(sourceId);

      let cObjType = oSelf.getObjType(oDraggedObj.objecttypename || oDraggedObj.instancetypename);

      if (cObjType == null)
        cObjType = 'abstract';
      return cObjType;
    },
    /**
         * Method called on double click of preview area to open object
         * @private
         * @instance
         * @memberof ak_designer
         * @param {object} item
         */
    afterPreviewItemDblClick(item) {
      if (item.$objtype == 'form') {
        this.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');
        const id = this.businessEntity.getIdFrom('ObjectInstanceGuid', item.guid);
        const rec = this.businessEntity.getStore('eSmartObjectInstance').item(id);

        akioma.globalFormbuilderNextOpen = {
          objectmasterguid: rec.objectmasterguid,
          objectmastername: rec.objectmastername,
          instancetypename: rec.instancetypename
        };

        app.controller.launchContainer({
          proc: 'formDesignerW.r',
          data: true
        });
      }
    },

    /**
         * Saves the current tree's state (expanded nodes).
         */
    saveDesignerTreeState() {
      const dhxTree = this.dhx.root.dataObj.cdata.a.dataObj;
      const rootNode = dhxTree.htmlNode;

      this._treeNodesOpenState = [];

      if (!isNull(rootNode))
        this._recursiveSaveTreeItemState(dhxTree, rootNode);
    },

    /**
         * Recursively saves the open states of a specific node and it's children.
         *
         * @protected
         *
         * @param {Object} dhxTree The dhtmlXTreeObject of the tree.
         * @param {Object} dhxTreeItemObject The dhtmlXTreeItemObject of the tree node for which to recursively save state.
         */
    _recursiveSaveTreeItemState(dhxTree, dhxTreeItemObject) {
      const open = dhxTree._getOpenState(dhxTreeItemObject) === 1;

      this._treeNodesOpenState.push(open);

      dhxTreeItemObject.childNodes.forEach(childItem => {
        this._recursiveSaveTreeItemState(dhxTree, childItem);
      });
    },

    /**
         * Method listener for on drop Event create new object instance and remove the existing object instance if present
         * @param {object} source
         * @param {string} sid
         * @param {object} target
         * @param {string} tid
         * @param {object} target
         * @param {object} tree
         * @returns {void}
         */
    onDropEvent: function(source, sid, target, tid, tree) {
      const oSelf = this;
      const model = oSelf.dhx.getService('UIState');
      const tItem = model.getItem(tid);

      let cRemovedInstanceGuid = null;

      const oDraggedObj = source.akElm.dataSource.dhx.item(sid);

      // check if dragged object is a businessEntityDrop
      const bAddingBE = oSelf._checkRootPageDrop(oDraggedObj, tItem);

      if (bAddingBE) {
        oSelf._addRootPageInstance(oDraggedObj, tItem, tree);
        return false;
      } else {
        const oInstanceStore = oSelf.businessEntity.getStore('eSmartObjectInstance');
        const typeName = oDraggedObj.objecttypename || oDraggedObj.instancetypename;
        const isNavigationType = akioma.repository.LayoutDesigner.navigationReplaceKeepInstanceTypes
          .indexOf(typeName.toLowerCase()) > -1;
        // get sequence number
        let iSequence = oSelf._getObjectSequence(tItem, oDraggedObj);
        let cPageGuidFromParent = '';
        let cUniqueInstanceName = '';

        oSelf.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');

        // if there is an instance with a guid then remove it from store
        // but only if it is not navigation, then keep the existing instance
        if (tItem && !isNavigationType) {
          cRemovedInstanceGuid = tItem.guid;
          oSelf._removeInstance(tItem.guid);
        }

        // for objects dragged from Instance grid pageguid needs to be reset to undefined
        if (oDraggedObj.instancetypename)
          oDraggedObj.pageguid = undefined;

        // get page guid from parent
        if ((oDraggedObj && oDraggedObj.pageguid == undefined) && tItem && tItem.$parent) {
          const tItemParent = model.getItem(tItem.$parent);
          if (tItemParent.config && tItemParent.config.id && oSelf.aPageGuids.indexOf(tItemParent.config.id) != -1)
            cPageGuidFromParent = tItemParent.config.id;

        }

        if (iSequence < 1)
          iSequence = 1;

        // get some unique name from store
        try {
          cUniqueInstanceName = akioma.SearchUniqueInstanceName(oDraggedObj.objectname || oDraggedObj.objectmastername, Object.values(oInstanceStore.data.pull));
        } catch (e) {
          akioma.log.error(e);
        }

        if (cUniqueInstanceName == undefined)
          cUniqueInstanceName = `${oDraggedObj.objectname} ${dhtmlx.uid()}`;

        const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
        const position = (typeName === 'SwatRibbon' || typeName === 'SwatToolbar' ? tItem.position || alphabet[iSequence - 1] : alphabet[iSequence - 1]);
        const cNextAddedInsID = uuid();

        oInstanceStore.add({
          id: cNextAddedInsID,
          instancedescription: '',
          instancename: cUniqueInstanceName,
          containerobjectmasterguid: oSelf.cLoadedObjMasterGuid,
          objectmasterguid: oDraggedObj.objectmasterguid,
          objectsequence: (iSequence + 1),
          layoutposition: position,
          objectmastername: oDraggedObj.objectname || oDraggedObj.objectmastername,
          instancetypename: oDraggedObj.objecttypename || oDraggedObj.instancetypename,
          parentinstanceguid: '',
          pageguid: (oDraggedObj.pageguid || cPageGuidFromParent || '')
        }, cNextAddedInsID);

        oInstanceStore.item(cNextAddedInsID).objectinstanceguid = cNextAddedInsID;

        oSelf._bindAfterCreateOnDropEvent();

        // replace removed guid instance with new created instance
        if (cRemovedInstanceGuid !== '' && !isNavigationType)
          oSelf._replaceLinks(cRemovedInstanceGuid, cNextAddedInsID);

        oSelf.cNextAddedInsID = cNextAddedInsID;

        oSelf.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');

        // fix for annoying working record jsdo bug
        const oInstanceJSDO = oSelf.businessEntity.jsdo[oSelf.businessEntity.entityName];
        oInstanceJSDO._jsdo._buffers[oInstanceJSDO._parent]._setRecord(oInstanceJSDO._jsdo._buffers[oInstanceJSDO._parent]._findFirst());

        // Add in diagram
        const diagram = this.dynObject.container.controller.getDescendant('diagram');
        diagram.addInstanceObject(oInstanceStore.item(cNextAddedInsID));

        return {
          instancename: cUniqueInstanceName,
          guid: cNextAddedInsID
        };
      }

    },

    /**
         * Binds the afterCreate event for the dropped item. Fixing the store ID and setting the cursor.
         *
         * @private
         */
    _bindAfterCreateOnDropEvent() {
      const oSelf = this;

      this.businessEntity.jsdo.subscribe('afterCreate', function onAfterCreate(jsdo, record) {
        jsdo.unsubscribe('afterCreate', onAfterCreate);

        // Bugfix for links. Return if link is passed as record
        if (record.data.LinkGuid)
          return;

        const insGuid = record.data.ObjectInstanceGuid;
        const inMemoryGuid = _.findWhere(oSelf.businessEntity.getStore('eSmartObjectInstance').data.pull, { objectinstanceguid: record.data.ObjectInstanceGuid }).id;

        if (inMemoryGuid && record.data.ObjectInstanceGuid)
          oSelf.saveTempAttributes(inMemoryGuid, record.data.ObjectInstanceGuid);

        // update in memory attribute with the stored attribute
        // reselect object
        oSelf.reseletObject(insGuid);

      });
    },

    /**
         * Reselect an object
         * @instance
         * @memberof ak_designer
         * @param {string} guid
         */
    reseletObject(guid) {
      const model = this.dhx.getService('UIState');
      const item = _.findWhere(model.pull, { guid: guid });
      delete this.props[guid];
      this.cLastSelectGuid = '';
      model._cursor = '';
      model.setCursor(item.$id);
    },

    /**
         * Return the object sequence by specifying the target and source objects
         * @instance
         * @param {object} tItem Target item
         * @param {object} sItem Source item
         * @private
         * @memberOf ak_designer
         * @return {integer} The sequence
         */
    _getObjectSequence: function(tItem, sItem) {
      // get sequence number
      let iSequence = 1;
      const oSelf = this;
      const aSpecials = [ 'swattoolbar', 'toolbar', 'ribbon', 'swatribbon', 'businessentity', 'swatbusinessentity' ];
      const aToolbarRibbons = [ 'swattoolbar', 'toolbar', 'swatribbon', 'ribbon' ];
      const aStoreSpecials = [ 'businessentity', 'swatbusinessentity' ];
      const model = oSelf.dhx.getService('UIState');

      // if the source dragged object is of type toolbar or ribbon
      const typeName = sItem.objecttypename || sItem.instancetypename;
      if (tItem.$parent && aToolbarRibbons.indexOf(typeName.toLowerCase()) == -1) {
        // get all the elements from inside the target parent and omit toolbars, ribbons and businessEntities
        const aInsList = _.chain(model.pull)
          .filter({ '$parent': tItem.$parent })
          .omit(value => {
            if (!isNull(value.config.$orgobjtype))
              return aSpecials.indexOf(value.config.$orgobjtype.toLowerCase()) !== -1;
            else
              return aSpecials.indexOf(value.ui) !== -1;
          }).value();


        iSequence = Object.values(aInsList).indexOf(tItem) + 1;

      } else {
        // get the list of instances that belong the to target parent and omit businessEntities
        const aInsList = Object.values(_.chain(model.pull).filter({ '$parent': tItem.$parent }).omit(value => {
          if (!isNull(value.config.$orgobjtype))
            return aStoreSpecials.indexOf(value.config.$orgobjtype.toLowerCase()) !== -1;
          else
            return aStoreSpecials.indexOf(value.ui) != -1;
        }).value());

        // the same sequence as the current target object
        iSequence = aInsList.indexOf(tItem) + 1;

      }

      return iSequence;
    },
    /**
         * Remove an object instance from Store
         * @param  {string} cInstanceGuid The guid to of the instance to remove
         * @return {void}
         * @memberOf ak_designer
         * @instance
         * @private
         * @return {void}
         */
    _removeInstance: function(cInstanceGuid) {
      const oSelf = this,
        oInstanceStore = oSelf.businessEntity.getStore('eSmartObjectInstance');

      oSelf.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');

      const tDataSourceID = oSelf.businessEntity.getIdFrom('objectinstanceguid', cInstanceGuid);
      const item = oInstanceStore.item(tDataSourceID);

      if (!isNull(item))
        oInstanceStore.remove(tDataSourceID);
    },

    /**
         * Replace links in designer where removed instance guid is found and replaced with new instance guid
         * @param  {string} cRemovedInstanceGuid Guid to be replaced
         * @param  {string} cNewInstanceGuid     Guid to replace with
         * @memberOf ak_designer
         * @instance
         * @private
         * @return {void}
         */
    _replaceLinks: function(cRemovedInstanceGuid, cNewInstanceGuid) {
      // go over each link and check source/target
      // if found replace with newly created guid
      //
      const oSelf = this,
        oLinkStore = oSelf.businessEntity.getStore('eSmartLink'),
        aLinks = oSelf.businessEntity.getStore('eSmartLink').data.pull;

      oSelf.businessEntity.switchJSDOWorkingRecord('eSmartLink');

      for (const l in aLinks) {

        const oLink = Object.assign(aLinks[l], {});

        let bUpdated = false;

        if (oLink.sourceobjectinstanceguid == cRemovedInstanceGuid && cRemovedInstanceGuid != null) {
          oLink.sourceobjectinstanceguid = cNewInstanceGuid;
          bUpdated = true;
        }

        if (oLink.targetobjectinstanceguid == cRemovedInstanceGuid) {
          oLink.targetobjectinstanceguid = cNewInstanceGuid;
          bUpdated = true;
        }


        if (bUpdated)
          oLinkStore.update(l, oLink);


      }

    },
    /**
         * Method used for formatting a new page attribute from instance record
         *
         * @param   {object}  pageItem
         *
         * @return  {object}
         */
    formatPageToProperty: function(pageItem, guid) {
      const keysOfPageIns = Object.keys(pageItem);

      let formattedAttributes = keysOfPageIns.map((key, index) => {

        let type = 'CHARACTER';
        const attrVal = pageItem[key];
        const logicalField = [ 'enableonview', 'enableonmodify', 'enableoncreate' ];
        if (logicalField.indexOf(key) !== -1)
          type = 'LOGICAL';

        if (key === 'pagesequence')
          type = 'INTEGER';


        const obj = {
          _idd: `${index}_${pageItem.pageguid}_${key}`,
          AppliesAtRuntime: true,
          AttributeDesription: '',
          AttributeLabel: key,
          AttributeValue: pageItem[key],
          AttributeValueGuid: null,
          ConstantLevel: '',
          ConstantValue: false,
          ContainerObjectMasterGuid: pageItem['containerobjectmasterguid'],
          DateTimeTzValue: null,
          DateTimeValue: null,
          DateValue: null,
          DecimalValue: 0,
          InheritedFrom: 'Swat',
          Int64Value: 0,
          IntegerValue: 0,
          IsInherited: true,
          label: key,
          LookupType: '',
          LookupValues: '',
          ObjectMasterGuid: '',
          ObjectTypeGuid: pageItem['pageguid'],
          PropertyOrEvent: true,
          PropertyType: type,
          RawValue: null,
          RepositoryType: type,
          TechnicalName: key,
          ...pageItem,
          ObjectInstanceGuid: pageItem['pageguid']
        };

        delete obj._id;
        delete obj.id;


        switch (type) {
          case 'CHARACTER':
            obj.CharacterValue = attrVal;
            break;
          case 'LOGICAL':
            obj.LogicalValue = attrVal;
            break;
          case 'INTEGER':
            obj.IntegerValue = attrVal;
            break;
        }

        if (!key.startsWith('_'))
          return obj;
        return null;
      }).filter(item => {
        if (!isNull(item))
          return item;
      });

      const storeObjs = Object.values(this.dhx.getService('UIState').pull);
      const storePageVal = storeObjs.find(v => v.guid == pageItem.pageguid).config.type;

      formattedAttributes = this.propertyGrid.sortAttributes(formattedAttributes, guid);
      formattedAttributes.unshift({
        AttributeLabel: 'Layout',
        label: 'Layout',
        name: 'Layout',
        _idd: `0_${pageItem['pageguid']}_pagetype`,
        type: 'select',
        value: storePageVal,
        CharacterValue: storePageVal,
        RepositoryType: 'Character',
        IsInherited: false,
        TechnicalName: 'Layout',
        PropertyOrEvent: true,
        AppliesAtRuntime: true,
        ConstantValue: false,
        ...pageItem,
        ObjectInstanceGuid: pageItem['pageguid']
      });


      return formattedAttributes;

    },
    copyAttributes: function(cGuidFrom, cGuidTo) {
      this.props[cGuidTo] = this.props[cGuidFrom].slice();

      const aAttrs = [];
      const aExistingAttrs = this.props[cGuidTo];

      for (const i in aExistingAttrs) {
        if (aExistingAttrs[i].label !== 'LayoutPosition'
                    && aExistingAttrs[i].label !== 'ObjectMasterName'
                    && aExistingAttrs[i].label !== 'name') {

          aAttrs[aExistingAttrs[i].label] = aExistingAttrs[i].original;
          aAttrs[aExistingAttrs[i].label].ObjectInstanceGuid = cGuidTo;
          if (aExistingAttrs[i].label == 'designPlaceholder')
            aAttrs[aExistingAttrs[i].label].LogicalValue = false;

        }
      }

      // this.tempAttributes.instances[cGuidTo] = aAttrs;
      if (isNull(this.tempAttributes.placeholder))
        this.tempAttributes.placeholder = {};
      this.tempAttributes.placeholder[cGuidFrom] = cGuidTo;

      // set attribute values from old instance
      if (this.tempAttributes.instances[cGuidTo] == undefined)
        this.tempAttributes.instances[cGuidTo] = [];
      for (const attrLabel in aAttrs) {
        const attr = aAttrs[attrLabel];
        this.tempAttributes.instances[cGuidTo][attr.AttributeLabel] = attr;
      }

      return this.props[cGuidTo];
    },
    // oLayout.cells("a").attachHTMLString(cFormBuilderHTML);
    // returns custom attributes for drag and dropped new object,
    // create new instance custom attribute loading
    _getNewObjectProperties: function(source, id) {

      // on drop load attribute values for specific new instance
      const deferred = $.Deferred();
      const oSelf = this;


      let guid = '';

      guid = source.akElm.dynObject.getLink('DATA:SRC').controller.dhx.item(id).objectmasterguid;

      // load attributes
      if (guid != '') {
        akioma.RepositoryStructure.fetchMasterDesignAttributes(guid).done(oResult => {
          let aProps = oResult.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue;

          aProps = oSelf.propertyGrid.sortAttributes(aProps, oSelf.cNextAddedInsID);

          const oitm = oSelf.businessEntity.getStore('eSmartObjectInstance').item(oSelf.cNextAddedInsID);

          aProps.unshift({
            AttributeLabel: 'Object Master Name',
            name: 'objectmastername',
            type: 'input',
            label: 'objectmastername',
            RepositoryType: 'Character',
            CharacterValue: oitm.objectmastername,
            ObjectInstanceGuid: oSelf.cNextAddedInsID,
            ObjectMasterGuid: oitm.objectmasterguid,
            openExternal: true,
            readonly: true,
            LogicalValue: false,
            IsInherited: false,
            TechnicalName: 'ObjectMasterName',
            PropertyOrEvent: true,
            AppliesAtRuntime: true,
            ConstantValue: false
          });

          aProps.unshift({
            AttributeLabel: 'name',
            name: 'name',
            label: 'name',
            type: 'input',
            CharacterValue: oitm.instancename,
            RepositoryType: 'Character',
            ObjectInstanceGuid: oSelf.cNextAddedInsID,
            LogicalValue: false,
            IsInherited: false,
            TechnicalName: 'Layout',
            PropertyOrEvent: true,
            AppliesAtRuntime: true,
            ConstantValue: false
          });

          aProps.unshift({
            AttributeLabel: 'LayoutPosition',
            name: 'layoutposition',
            label: 'layoutposition',
            type: 'input',
            RepositoryType: 'Character',
            CharacterValue: oitm.layoutposition,
            ObjectInstanceGuid: oSelf.cNextAddedInsID,
            LogicalValue: false,
            IsInherited: false,
            TechnicalName: 'LayoutPosition',
            PropertyOrEvent: true,
            AppliesAtRuntime: true,
            ConstantValue: false
          });

          aProps = akioma.repository.parseObjectProps(oSelf, oSelf.cNextAddedInsID, aProps);


          deferred.resolve(aProps);
          oSelf.cNextAddedInsID = null;

        });
      } else
        deferred.reject();


      return deferred.promise();
    },
    /**
     * Method for returning Object Master Layout pattern
     * @instance
     * @memberOf ak_designer
     * @returns {string} Layout Pattern
     */
    getMasterLayoutPattern() {
      return this.aTempLayoutOptions[0];
    },
    /**
     * Method for returning Page Layout pattern
     * @param {integer} pageIndex Index of Page
     * @instance
     * @memberOf ak_designer
     * @returns {string} Layout Pattern
     */
    getPageLayoutPattern(pageIndex) {
      return this.aTempLayoutOptions[pageIndex + 1];
    },
    /**
     * Method for returning Page index from given guid
     * @param {string} pageGuid Page guid
     * @instance
     * @memberOf ak_designer
     * @returns {string} Layout Pattern
     */
    getPageIndex(pageGuid) {
      return this.aPageGuids.indexOf(pageGuid);
    },

    /**
     * Called when updating a property value from the properties cell
     * @param  {string} propertyRowKey Property key
     * @param  {object} propertyVal    Property val
     * @instance
     * @memberOf ak_designer
     * @return {void}
     */
    _onPropertyUpdate: function(propertyRowKey, propertyVal) {

      try {
        const oSelf = this;
        const cKey = Object.keys(propertyVal)[0];
        const cValue = propertyVal[cKey];

        const aKeySplit = cKey.split('_');
        let objguid = aKeySplit[1];
        const index = aKeySplit[0];

        const oItemAttr = this.dhx.getService('UIState').pull[propertyRowKey]._custom[index];
        const oItem = oItemAttr.original || oItemAttr;

        // check for layoutOptions changed on pages
        const iIndexPage = oSelf.aPageGuids.indexOf(objguid);
        const iIndexMainTypeAttr = cKey.indexOf('_maintype'),
          iIndexMainNameAttr = cKey.indexOf('_name'),
          iIndexPageTypeAttr = cKey.indexOf('_pagetype');

        // if main Type attribute was update
        if (iIndexMainTypeAttr != -1) {
          // set new temp layoutoptions
          oSelf.aTempLayoutOptions[0] = cValue;
          oItem.IsInherited = false;
          oItem.CharacterValue = oSelf.aTempLayoutOptions.join(',');
          oSelf.oTempLayoutOptionsObj = oItem;
          oSelf.bChangedLayoutOptions = true;
        } else if (iIndexMainNameAttr != -1 && oSelf.cLoadedObjMasterGuid === objguid) {
          // if main Name attribute was update
          oSelf.businessEntity.switchJSDOWorkingRecord('eSmartObjectMaster');
          const oMasterStore = oSelf.businessEntity.getStore('eSmartObjectMaster');
          const tDataSourceID = oSelf.businessEntity.getIdFrom('objectmasterguid', objguid);

          const oExisting = oMasterStore.item(tDataSourceID);
          oExisting.objectname = cValue;

          oMasterStore.update(tDataSourceID, oExisting);
        } else if (iIndexPage != -1 && iIndexPageTypeAttr != -1) { // or if page Type attribute was updated
          // set new temp layoutoptions
          oSelf.aTempLayoutOptions[iIndexPage + 1] = cValue;
          oItem.IsInherited = false;
          oItem.CharacterValue = oSelf.aTempLayoutOptions.join(',');
          oSelf.oTempLayoutOptionsObj = oItem;
          oSelf.bChangedLayoutOptions = true;
        } else if (iIndexPage != -1 && cKey.indexOf('_pagelabel') != -1) { // check for page label attribute changed on pages
          oSelf.businessEntity.switchJSDOWorkingRecord('eSmartPage');
          const oPagesStore = oSelf.businessEntity.getStore('eSmartPage');
          const tDataSourceID = oSelf.businessEntity.getIdFrom('pageguid', oItem.pageguid);

          const oExisting = oPagesStore.item(tDataSourceID);
          oExisting.pagelabel = cValue;

          oPagesStore.update(tDataSourceID, oExisting);
          oSelf.aTempPageLabels[objguid] = cValue;
        } else if (iIndexPage != -1 && cKey.indexOf('_pageenableoncreate') != -1) {
          oSelf.businessEntity.switchJSDOWorkingRecord('eSmartPage');
          const oPagesStore = oSelf.businessEntity.getStore('eSmartPage');
          const tDataSourceID = oSelf.businessEntity.getIdFrom('pageguid', oItem.pageguid);

          const oExisting = oPagesStore.item(tDataSourceID);
          oExisting.enableoncreate = cValue;

          oPagesStore.update(tDataSourceID, oExisting);
          oSelf.aTempPageLabels[objguid] = cValue;
        } else if (iIndexPage != -1 && cKey.indexOf('_pageenableonmodify') != -1) {
          oSelf.businessEntity.switchJSDOWorkingRecord('eSmartPage');
          const oPagesStore = oSelf.businessEntity.getStore('eSmartPage');
          const tDataSourceID = oSelf.businessEntity.getIdFrom('pageguid', oItem.pageguid);

          const oExisting = oPagesStore.item(tDataSourceID);
          oExisting.enableonmodify = cValue;

          oPagesStore.update(tDataSourceID, oExisting);
          oSelf.aTempPageLabels[objguid] = cValue;
        } else if (iIndexPage != -1 && cKey.indexOf('_pageenableonview') != -1) {
          oSelf.businessEntity.switchJSDOWorkingRecord('eSmartPage');
          const oPagesStore = oSelf.businessEntity.getStore('eSmartPage');
          const tDataSourceID = oSelf.businessEntity.getIdFrom('pageguid', oItem.pageguid);

          const oExisting = oPagesStore.item(tDataSourceID);
          oExisting.enableonview = cValue;

          oPagesStore.update(tDataSourceID, oExisting);
          oSelf.aTempPageLabels[objguid] = cValue;
        } else if (iIndexPage != -1 && cKey.indexOf('_pageicon') != -1) {
          oSelf.businessEntity.switchJSDOWorkingRecord('eSmartPage');
          const oPagesStore = oSelf.businessEntity.getStore('eSmartPage');
          const tDataSourceID = oSelf.businessEntity.getIdFrom('pageguid', oItem.pageguid);

          const oExisting = oPagesStore.item(tDataSourceID);
          oExisting.pageicon = cValue;

          oPagesStore.update(tDataSourceID, oExisting);
          oSelf.aTempPageLabels[objguid] = cValue;
        } else if (iIndexPage != -1 && cKey.indexOf('_pagekey') != -1) {
          oSelf.businessEntity.switchJSDOWorkingRecord('eSmartPage');
          const oPagesStore = oSelf.businessEntity.getStore('eSmartPage');
          const tDataSourceID = oSelf.businessEntity.getIdFrom('pageguid', oItem.pageguid);

          const oExisting = oPagesStore.item(tDataSourceID);
          oExisting.pagekey = cValue;

          oPagesStore.update(tDataSourceID, oExisting);
          oSelf.aTempPageLabels[objguid] = cValue;
        } else if (iIndexPage != -1 && cKey.indexOf('_pagesequence') != -1) {
          oSelf.businessEntity.switchJSDOWorkingRecord('eSmartPage');
          const oPagesStore = oSelf.businessEntity.getStore('eSmartPage');
          const tDataSourceID = oSelf.businessEntity.getIdFrom('pageguid', oItem.pageguid);

          const oExisting = oPagesStore.item(tDataSourceID);
          oExisting.pagesequence = parseInt(cValue, 10);

          oPagesStore.update(tDataSourceID, oExisting);
          oSelf.aTempPageLabels[objguid] = cValue;
        } else if (iIndexPage != -1 && cKey.indexOf('_securitytoken') != -1) {
          oSelf.businessEntity.switchJSDOWorkingRecord('eSmartPage');
          const oPagesStore = oSelf.businessEntity.getStore('eSmartPage');
          const tDataSourceID = oSelf.businessEntity.getIdFrom('pageguid', oItem.pageguid);

          const oExisting = oPagesStore.item(tDataSourceID);
          oExisting.securitytoken = cValue;

          oPagesStore.update(tDataSourceID, oExisting);
          oSelf.aTempPageLabels[objguid] = cValue;
        } else if (cKey.indexOf('_layoutposition') != -1) { // check for instance layoutposition attribute
          oSelf.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');
          const oInstanceStore = oSelf.businessEntity.getStore('eSmartObjectInstance');
          const tDataSourceID = oSelf.businessEntity.getIdFrom('objectinstanceguid', objguid);

          const oExisting = oInstanceStore.item(tDataSourceID);
          oExisting.layoutposition = cValue;

          oInstanceStore.update(tDataSourceID, oExisting);
        } else if (cKey.indexOf('_name') != -1) { // check for instance name attribute
          oSelf.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');
          const oInstanceStore = oSelf.businessEntity.getStore('eSmartObjectInstance');
          const tDataSourceID = oSelf.businessEntity.getIdFrom('objectinstanceguid', objguid);

          const oExisting = oInstanceStore.item(tDataSourceID);
          oExisting.instancename = cValue;

          oInstanceStore.update(tDataSourceID, oExisting);
        } else if (oSelf.props[objguid] != undefined && oItem.ObjectInstanceGuid == '' && oItem.ObjectMasterGuid == '') {
          // check if in memory newly created instance
          oItem.IsInherited = false;
          if (oSelf.props[objguid][index].type == 'input')
            oItem.CharacterValue = cValue;
          else if (oSelf.props[objguid][index].type == 'checkbox')
            oItem.LogicalValue = cValue;

          oSelf.props[objguid][index].original = this.dhx.getService('UIState').pull[propertyRowKey]._custom[index].original;

          if (oSelf.tempAttributes.instances[objguid] == undefined)
            oSelf.tempAttributes.instances[objguid] = [];

          oSelf.tempAttributes.instances[objguid][oItem.AttributeLabel] = oItem;
        } else if (oItem.ObjectInstanceGuid == '') {
          // this means it is an object master
          oItem.IsInherited = false;
          if (oSelf.props[oItem.ObjectMasterGuid][index].type == 'input')
            oItem.CharacterValue = cValue;
          else if (oSelf.props[oItem.ObjectMasterGuid][index].type == 'checkbox')
            oItem.LogicalValue = cValue;

          oSelf.props[oItem.ObjectMasterGuid][index].original = this.dhx.getService('UIState').pull[propertyRowKey]._custom[index].original;

          if (oSelf.tempAttributes.masters[oItem.ObjectMasterGuid] == undefined)
            oSelf.tempAttributes.masters[oItem.ObjectMasterGuid] = [];

          oSelf.tempAttributes.masters[oItem.ObjectMasterGuid][oItem.AttributeLabel] = oItem;

        } else { // otherwise it is an instance
          oItem.IsInherited = false;
          if (oItem.RepositoryType.toUpperCase() == 'DECIMAL') {
            if (cValue != null)
              oItem.DecimalValue = parseFloat(cValue);
            else
              oItem.DecimalValue = 0;
          } else if (oItem.RepositoryType.toUpperCase() == 'INTEGER') {
            if (cValue != null)
              oItem.IntegerValue = parseInt(cValue);
            else
              oItem.IntegerValue = 0;
          } else if (oSelf.props[objguid][index].type == 'input')
            oItem.CharacterValue = cValue;
          else if (oSelf.props[objguid][index].type == 'checkbox')
            oItem.LogicalValue = cValue;

          const uiState = this.dhx.getService('UIState');
          const uiStateElm = uiState.pull[propertyRowKey];
          if (!isNull(uiStateElm._custom[index].original))
            oSelf.props[oItem.ObjectInstanceGuid][index].original = uiStateElm._custom[index].original;

          oSelf.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');
          if (oSelf.tempAttributes.placeholder && oSelf.tempAttributes.placeholder[objguid])
            objguid = oSelf.tempAttributes.placeholder[objguid];


          let id = oSelf.businessEntity.getIdFrom('objectinstanceguid', objguid);
          if (!isNull(id)) {
            if (oSelf.tempAttributes.instances[objguid] == undefined)
              oSelf.tempAttributes.instances[objguid] = [];

            oSelf.tempAttributes.instances[objguid][oItem.AttributeLabel] = oItem;

          } else {

            oSelf.businessEntity.switchJSDOWorkingRecord('eSmartObjectMaster');

            id = oSelf.businessEntity.getIdFrom('objectmasterguid', objguid);
            if (!isNull(id)) {
              if (oSelf.tempAttributes.masters[objguid] == undefined)
                oSelf.tempAttributes.masters[objguid] = [];

              oSelf.tempAttributes.masters[objguid][oItem.AttributeLabel] = oItem;
            }
          }
        }

      } catch (e) {
        console.warn(e);
      }


    },
    /**
     * Method for updating Ui State designer props
     *
     * @param   {string}  guid
     * @param   {array}  aProps
     * @instance
     * @memberof ak_designer
     *
     * @return  {boolean}
     */
    updateDesignerProps: function(guid, aProps) {
      const model = this.dhx.getService('UIState');
      const designerEl = _.find(model.pull, item => item.guid == guid);

      // update designer model
      if (!isNull(designerEl)) {
        designerEl._custom = [];
        designerEl._custom = aProps;
        return true;
      }
      return false;
    },
    getObjectByType(objects, type) {
      let searched;
      objects.forEach(obj => {
        const objType = obj.type.toLowerCase();
        if (objType === 'frame' || objType === type)
          searched = obj;

      });
      const objType = searched.type.toLowerCase();
      if (objType === 'frame') {
        const nestedObj = searched.getFirstChildByType(type);
        if (nestedObj.dynObject.type.toLowerCase() === type)
          searched = nestedObj;
      }
      if (!isNull(searched.dynObject)) // ensure dynObject is returned
        searched = searched.dynObject;
      return searched;
    },
    // finish construct **********
    finishConstruct: function() {
      const oSelf = this;

      /**
       * Keeps a stack of expand states for the designer tree grid nodes.
       *
       * @protected
       *
       * @type {Array<boolean>}
       */
      this._treeNodesOpenState = [];

      const aLinks = this.dataSource.dynObject.getLinks('DATA:TARGET');
      const frames = aLinks.filter(linkTarget => linkTarget.type === 'frame').map(frame => frame.controller.promiseFrame);

      $.when.all(frames).then(() => {
        const propertyGrid = this.getObjectByType(aLinks, 'propertygrid');
        oSelf.propertyGrid = propertyGrid.controller;
        oSelf.dhx.propertyGrid = propertyGrid.controller;
      });

      if (this.opt.onInit)
        app.controller.callAkiomaCode(this, this.opt.onInit);


      if (oSelf.opt.BorderTitle) {
        this.parent.dhx.setText(oSelf.opt.BorderTitle);
        this.parent.dhx.showHeader();
      }

      // add window resize events
      this.resizeEvents();

      // define object businessEntity sstore here
      //
      if (oSelf.dataSource) {
        oSelf.businessEntity = oSelf.dataSource;
        oSelf.bSendAllRecords = true;
        oSelf.businessEntity.clientOrderBy.tables = ['eSmartPage'];
        oSelf.businessEntity.clientOrderBy.field = ['PageSequence'];
        oSelf.store = oSelf.dataSource;
      }

      this.dynObject.loadUserProfileSettings();
    },
    /**
     * Check if correct Root or Page container Drop
     * @param  {object} oDraggedObj The dragged object
     * @param  {object} oTargetObj  The target object
     * @return {boolean}
     */
    _checkRootPageDrop: function(oDraggedObj, oTargetObj) {
      const objectTypes = akioma.repository.LayoutDesigner.rootAndPageDropAddsInstanceTypes;
      const isRoot = (this.cLoadedObjMasterGuid === oTargetObj.guid);
      const isPage = (oTargetObj.$objtype == 'page');
      const typeName = oDraggedObj.objecttypename || oDraggedObj.instancetypename;
      const isObjectTypeRootPage = objectTypes.indexOf(typeName.toLowerCase()) > -1;

      return (isRoot || isPage) && isObjectTypeRootPage;
    },
    /**
     * Method for adding a new instance on root or page level
     * @private
     * @instance
     * @memberOf ak_designer
     * @param  {object} oDraggedObj The object that is being dragged.
     * @param  {object} oTargetObj  The object target.
     * @return {boolean}            Returns true on correct businessentity drag&drop to add operation.
     */
    _addRootPageInstance: function(oDraggedObj, oTargetObj) {
      const model = this.dhx.getService('UIState');
      const isRoot = (this.cLoadedObjMasterGuid === oTargetObj.guid);
      const oInstanceStore = this.businessEntity.getStore('eSmartObjectInstance');
      let cUniqueInstanceName;
      try {
        cUniqueInstanceName = akioma.SearchUniqueInstanceName(oDraggedObj.objectname || oDraggedObj.objectmastername, Object.values(oInstanceStore.data.pull));
      } catch (e) {
        akioma.log.error(e);
      }

      if (cUniqueInstanceName == undefined)
        cUniqueInstanceName = oDraggedObj.objectname || `${oDraggedObj.objectmastername} ${dhtmlx.uid()}`;

      const typeName = oDraggedObj.objecttypename || oDraggedObj.instancetypename;
      let cNewObjType = typeName.toLowerCase();

      if (cNewObjType === 'swatribbon')
        cNewObjType = 'ribbon';


      const cNextAddedInsID = uuid();
      const item = {
        id: cNextAddedInsID,
        guid: cNextAddedInsID,
        ui: cNewObjType,
        instancename: cNextAddedInsID,
        $objtype: cNewObjType,
        config: {
          $name: `${cUniqueInstanceName} / ${cNewObjType}`,
          header: cUniqueInstanceName,
          $objicon: akioma.repository.getObjIcon(cNewObjType),
          $objstyle: akioma.repository.getObjStyle(cNewObjType),
          $objtype: cNewObjType,
          $orgobjtype: typeName.toLowerCase(),
          typename: typeName,
          $objname: cUniqueInstanceName
        }
      };

      this.cNextAddedInsID = cNextAddedInsID;

      this.newInstances.push(cNextAddedInsID);

      this.bindSaveClearNewInstances(cNextAddedInsID);

      try {
        // make sure to add to the correct branch
        const aExisting = model.branch[oTargetObj.$id];
        const items = [item].concat(aExisting);

        model._parse_rec(oTargetObj.$id, items);

      } catch (e) {
        console.warn('Adding to tree branch failed!', e);
      }

      let cParentInsGuid = oTargetObj.guid;

      if (isRoot)
        cParentInsGuid = '';

      oInstanceStore.add({
        instancedescription: '',
        objectinstanceguid: cNextAddedInsID,
        instancename: cUniqueInstanceName,
        instancetypename: typeName,
        containerobjectmasterguid: this.cLoadedObjMasterGuid,
        objectmasterguid: oDraggedObj.objectmasterguid,
        objectmastername: oDraggedObj.objectname || oDraggedObj.objectmastername,
        layoutposition: '',
        objectsequence: 1,
        pageguid: cParentInsGuid
      }, cNextAddedInsID);

      this._bindAfterCreateOnDropEvent();

      const UIState = this.dhx.getService('UIState');

      UIState.bus.callEvent('store:onDataRefresh', [{}]);

      // Add in diagram
      const diagram = this.dynObject.container.controller.getDescendant('diagram');
      diagram.addInstanceObject(oInstanceStore.item(cNextAddedInsID));

      model._cursor = item.$id;
      model.bus.callEvent('store:onCursor', [ item.$id, 0, item, true ]);
      model.setCursor(item.$id);

    },
    bindSaveClearNewInstances(instanceGuid) {
      const self = this;
      self.businessEntity.jsdo.subscribe('afterCreate', function onAfterCreate(jsdo, record, success) {
        // clear saved instance guid from memory
        if (success) {
          jsdo.unsubscribe('afterCreate', onAfterCreate);
          const instanceIndex = self.newInstances.indexOf(instanceGuid);
          if (instanceIndex >= 0) {
            self.lastSelectedInstance = null;
            self.newInstances.splice(instanceIndex, 1);
          }
        }
      });
    },
    // promise for on change Event when chaning the Type attribute
    _onPropChange: function(objguid, value, rId) {

      const deferred = $.Deferred();
      const cValue = value;

      // check for layoutOptions changed on pages
      let iNoExtra = 0;
      const oElms = _.filter(this.dhx.getService('UIState').pull, item => item.guid == objguid);
      const modelEl = oElms[0];
      let modelAttr;
      let cKey = '';
      let iIndexMainTypeAttr = -1;
      let iIndexPageTypeAttr = -1;

      if (!modelEl)
        deferred.resolve(1);
      else {
        modelAttr = modelEl._custom.find(v => v.label === rId || v.AttributeLabel === rId || v.name === rId);

        cKey = modelAttr._idd;
        iIndexMainTypeAttr = cKey.indexOf('_maintype');
        iIndexPageTypeAttr = cKey.indexOf('_pagetype');

        if (iIndexMainTypeAttr != -1 || iIndexPageTypeAttr != -1) {

          if (oElms.length >= 1) {
            const aSpecials = [ 'sdo', 'businessentity', 'swatsdo', 'swatbusinessentity', 'swattoolbar', 'toolbar', 'swatribbon', 'ribbon' ];
            let iLastNonEmptyIndex = 0;
            let l = 0;
            let aValidChilds = _.chain(oElms[0].cells).filter(item => {
              l++;
              let bBePlaceholder = false;
              try {
                bBePlaceholder = (item.ui == 'placeholder' && item.config.$orgobjtype.toLowerCase() == 'swatbusinessentity');
              } catch (e) {
                akioma.log.error(e);
              }
              if (item.$objtype && [ 'swattoolbar', 'toolbar', 'swatribbon', 'ribbon', 'swatbusinessentity', 'businessentity' ].indexOf(item.$objtype) != -1)
                l--;
              if (bBePlaceholder)
                l--;

              if (item.ui != 'empty' && (item.$objtype == undefined || aSpecials.indexOf(item.$objtype) != -1 || bBePlaceholder))
                iLastNonEmptyIndex = l;

              return (item.$objtype == undefined || aSpecials.indexOf(item.$objtype) == -1);
            }).value();

            aValidChilds = aValidChilds.splice(0, iLastNonEmptyIndex);

            iNoExtra = parseInt(value.substr(0, 1));


            if (iNoExtra < aValidChilds.length) {
              // display modal which on confirmation will remove instances from store
              //

              akioma.message({
                type: 'confirm',
                title: 'Set Type',
                text: `Are you sure you want to set the Layout Type to '${cValue}' ? This will remove ${aValidChilds.length - iNoExtra} instances.`,
                buttonText: 'Yes',
                callback: function(result) {
                  deferred.resolve(result);
                }
              });

            } else
              deferred.resolve(0);

          } else {
            console.warn('No element found!');
            deferred.resolve(1); // no
          }
        } else
          deferred.resolve(0); // yes

      }

      return deferred.promise();
    },
    /**
     * Method to replace placeholder object from pulldown contextmenu
     * @private
     * @instance
     * @memberOf ak_designer
     * @param {object} item Selected object in designer tree
     */
    _replacePlaceholderContextMenu(item) {
      akioma.repository.replaceDesignerPlaceholder(item.config.typename, item.guid, this);
    },
    /**
     * Method for launching the currently selected record in designer tree Object Master
     * @private
     * @instance
     * @memberOf ak_designer
     */
    _launchMasterContextMenu() {
      // get currently selected tree object
      try {
        const oStore = this.dhx.getService('UIState');
        const currentSelectedIndex = oStore.getCursor();
        const oTreeItem = oStore.getItem(currentSelectedIndex);
        const instanceStore = this.dataSource.getStore('eSmartObjectInstance');

        this.dataSource.switchJSDOWorkingRecord('eSmartObjectInstance');

        const idInsStore = this.dataSource.getIdFrom('objectinstanceguid', oTreeItem.guid);
        const instanceRecord = instanceStore.item(idInsStore);

        // launch in new external screen
        if (!isNull(instanceRecord)) {
          const oPromise = app.controller.launchContainer({ containerName: 'objectdesignerw', allowMultipleInstances: true });
          if (oPromise) {
            oPromise.then(container => {
              const layoutDesignerGrid = container.dynObject.getObject('ObjectMasterGrid');
              container.dynObject.getLink('PRIMARYSDO:TARGET').controller.addAfterFillOnceCallback(() => {
                akioma.loadRepoObjects(layoutDesignerGrid, instanceRecord.objectmastername, instanceRecord.objectmasterguid, instanceRecord.instancetypename.toLowerCase());
              });
            });
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    /**
         * Method for removing the instance from the designer tree context menu option
         * @param {object} item Selected object in designer tree
         * @memberof ak_designer
         * @instance
         * @private
         * @return {string} Empty string for delete treenode and empty to replace with empty tree node.
         */
    _removeInstanceContextMenu(item) {
      const oInstanceStore = this.businessEntity.getStore('eSmartObjectInstance');

      this.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');
      let tDataSourceID = this.businessEntity.getIdFrom('objectinstanceguid', item.guid);
      if (tDataSourceID == undefined)
        tDataSourceID = this.businessEntity.getIdFrom('instancename', item.instancename);

      oInstanceStore.remove(tDataSourceID);

      // Remove from diagram
      let isForm = false;
      if (this.cLoadedObjMasterType !== 'swatform') {
        const diagram = this.dynObject.container.controller.getDescendant('diagram');
        diagram.deleteInstanceObjects([item.guid]);
      } else
        isForm = true;

      const isBeObj = item.$objtype && item.$objtype.indexOf('businessentity') > -1;
      const isRibbonObj = item.$objtype && item.$objtype.indexOf('ribbon') > -1;
      const isToolbarObj = item.$objtype && item.$objtype.indexOf('toolbar') > -1;

      if (isBeObj || isRibbonObj || isToolbarObj || isForm) {
        //  delete should remove the treenode not replace it
        return '';
      }

      return 'empty'; // returns the type to replace with
    },
    /**
         * Method for removing a page from the context menu in designer tree
         * @param {object} item Selected object in designer tree
         * @instance
         * @private
         * @memberof ak_designer
         * @returns {Promise}
         */
    _removePageContextMenu(item) {

      const deferred = $.Deferred();

      akioma.message({
        type: 'confirm',
        title: 'Delete page',
        text: `Are you sure you want to delete "${item.config.$name}" ? `,
        buttonText: 'Yes',
        callback: result => {

          if (result) {
            result = 0;
            const oPageStore = this.businessEntity.getStore('eSmartPage');

            this.businessEntity.switchJSDOWorkingRecord('eSmartPage');
            const tDataSourceID = this.businessEntity.getIdFrom('pageguid', item.guid);
            oPageStore.remove(tDataSourceID);


            const model = this.dhx.getService('UIState');
            const parentItem = model.getItem(item.$parent);
            const index = parentItem.cells.indexOf(item) + 1;

            // remove master layoutoptions pattern for this page
            if (index > -1)
              this._removeMasterLayoutOptions(index);

            // get instances in page, if > 1 then prompt user, are you sure?
            // delete page and instances if yes!
            this.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');
            const oInsStore = this.businessEntity.getStore('eSmartObjectInstance');
            for (const x in item.cells) {
              const it = item.cells[x];
              if (it.guid) {
                const tDataSourceID = this.businessEntity.getIdFrom('objectinstanceguid', it.guid);
                oInsStore.remove(tDataSourceID);
              }
            }

            deferred.resolve(result);
          } else
            return;
        }
      });

      return deferred.promise();
    },
    /**
         * Method for creating a new model instance for the dhx designer tree new page add
         * @param {object} param Options for new page create
         * @param {object} param.pageObject DhtmlxStore record from eSmartPage
         * @param {object} param.parent The container object from dhx designer model
         * @param {string} param.pageguid The newly created page guid
         * @param {string} param.name The name of the newly create page, the label
         * @private
         * @instance
         * @memberof ak_designer
         * @returns {object} oNewPage
         */
    _getNewPageObject({ pageObject, parent, pageguid, name }) {

      const oElm = parent;
      const oCurrPage = pageObject;
      const cNewPageLabel = name;
      const iLastElmSequence = this._getLastElementSequence(oElm);
      const emptyCell = this.getEmptyCell(0);

      const oNewPage = {
        ui: 'layout',
        $objtype: 'page',
        config: {
          $name: `Page ${iLastElmSequence} / ${cNewPageLabel}`,
          id: pageguid,
          type: '1C',
          title: cNewPageLabel,
          $objname: cNewPageLabel,
          $objtype: 'swattab',
          $orgobjtype: 'swattab',
          typename: 'SwatTab',
          $objicon: akioma.repository.getObjIcon('swattab'),
          $objstyle: akioma.repository.getObjStyle('swattab')
        },
        _custom: [
          {
            label: 'Layout',
            _idd: `0_${pageguid}_pagetype`,
            name: 'type',
            options: [ '1C', '2E', '2U', '3E', '3L', '3W', '3J', '3T', '3U', '4H', '4I', '4T', '4U', '4E', '4A', '4W', '4L', '4J', '4F', '4G', '4C', '5H', '5I', '5U', '5E', '5W', '5K', '5S', '5G', '5C', '6H', '6I', '6A', '6C', '6J', '6E', '6W', '7H', '7I' ],
            type: 'select',
            value: '1C',
            original: this.oOriginalMasterLayoutOptions
          },
          {
            name: 'label',
            _idd: `1_${pageguid}_pagelabel`,
            label: 'Label',
            type: 'input',
            value: cNewPageLabel,
            original: oCurrPage
          },
          {
            name: 'pagelabel',
            _idd: `1_${pageguid}_pagelabel`,
            label: 'Label',
            type: 'input',
            value: cNewPageLabel,
            original: oCurrPage
          },
          {
            name: 'enableoncreate',
            _idd: `2_${pageguid}_pageenableoncreate`,
            label: 'EnableOnCreate',
            type: 'checkbox',
            value: oCurrPage.enableoncreate,
            original: oCurrPage
          },
          {
            name: 'enableonmodify',
            _idd: `3_${pageguid}_pageenableonmodify`,
            label: 'EnableOnModify',
            type: 'checkbox',
            value: oCurrPage.enableonmodify,
            original: oCurrPage
          },
          {
            name: 'enableonview',
            _idd: `4_${pageguid}_pageenableonview`,
            label: 'EnableOnView',
            type: 'checkbox',
            value: oCurrPage.enableonview,
            original: oCurrPage
          },
          {
            name: 'pageicon',
            _idd: `5_${pageguid}_pageicon`,
            label: 'PageIcon',
            type: 'input',
            value: oCurrPage.pageicon,
            original: oCurrPage
          },
          {
            name: 'pagekey',
            _idd: `6_${pageguid}_pagekey`,
            label: 'PageKey',
            type: 'input',
            value: oCurrPage.pagekey,
            original: oCurrPage
          },
          {
            name: 'securitytoken',
            _idd: `7_${pageguid}_pagesecuritytoken`,
            label: 'SecurityToken',
            type: 'input',
            value: oCurrPage.securitytoken,
            original: oCurrPage
          },
          {
            name: 'pagesequence',
            _idd: `8_${pageguid}_pagesequence`,
            label: 'PageSequence',
            type: 'input',
            value: oCurrPage.pagesequence,
            original: oCurrPage
          }
        ],
        order: iLastElmSequence,
        guid: pageguid,
        cells: [emptyCell]
      };

      return oNewPage;

    },
    /**
     * Method for returning the last element sequence in designer of a given parent control
     * @param {object} parent Parent object of tree
     * @instance
     * @private
     * @memberof ak_designer
     * @returns {number} The last element sequence
     */
    _getLastElementSequence(parent) {
      let iLastElmSequence = 1;
      if (parent.cells == undefined)
        parent.cells = [];
      if (parent.cells.length > 0)
        iLastElmSequence = parseInt(parent.cells[parent.cells.length - 1].order) + 1;

      return iLastElmSequence;
    },
    /**
     * Method to add a new page in the datastore after modal asking for new page name
     * @param {object} item
     * @param {object} oElm the parent element of the page in tree
     * @private
     * @instance
     * @memberof ak_designer
     */
    _addPageContextMenu(item) {

      akioma.message({
        type: 'input',
        title: 'Add New Page',
        text: 'Page label',
        buttonText: 'Add',
        callback: result => {
          if (result) {
            const cNewPageLabel = result;

            const oStore = this.dhx.getService('UIState');
            const oElm = _.filter(oStore.pull, item => item.$id == oStore.getCursor())[0];
            const iLastElmSequence = this._getLastElementSequence(oElm);
            const pageguid = uuid();
            // now add in dataStore of BE
            const oBE = this.businessEntity;
            const oPagesStore = oBE.getStore('eSmartPage');

            // call getinitialvalues
            const oParameter = {
              plcParameter: {
                TableNames: 'eSmartPage',
                ForeignKeyFields: [''],
                ForeignKeyValues: ['']
              }
            };
            const oReturnData = oBE.jsdo.GetInitialValues (oParameter, false);
            const oInitialValRec = oReturnData.response.plcDataset.dsObjectMaster.eSmartPage[0];
            const aKeys = Object.keys(oInitialValRec).map(x => x.toLowerCase());
            const aVals = Object.values(oInitialValRec);

            oPagesStore.add({
              id: pageguid,
              _id: pageguid,
              containerobjectmasterguid: this.cLoadedObjMasterGuid,
              pagelabel: cNewPageLabel,
              pagesequence: iLastElmSequence
            }, pageguid);

            this.newPageInstances.push(pageguid);

            oPagesStore.item(pageguid).pageguid = pageguid;
            const dhxItemKeys = Object.keys(oPagesStore.item(pageguid));

            // replace the fields that don't already exist from the GetInitialValues response
            for (const j in aKeys) {
              const key = aKeys[j];
              if (dhxItemKeys.indexOf(key) == -1)
                oPagesStore.item(pageguid)[key] = aVals[j];

            }

            const oCurrPage = oPagesStore.item(pageguid);
            const oNewPage = this._getNewPageObject({
              parent: oElm,
              pageObject: oCurrPage,
              pageguid,
              name: cNewPageLabel
            });

            oElm.cells.push(oNewPage);
            this.aPageGuids.push(pageguid);

            // update LayoutOptions on root layout pattern
            this._updateMasterLayoutOptions('1C', this.aPageGuids.length);

            try {
              const model = this.dhx.getService('UIState');

              model._append_rec(item.$id, [oNewPage]);
              model.bus.callEvent('store:onDataRefresh', [{}]);

              model._cursor = '';
              model.setCursor(oNewPage.$id);
              model.bus.callEvent('preview:onTabSelect', [oNewPage.id]);

            } catch (e) {
              console.warn('Adding to tree branch failed!', e);
            }
          } else
            return;
        }
      });
    },
    /**
     * Method for saving the LayoutOptions of the Object Master
     * @instance
     * @private
     * @memberof ak_designer
     */
    _saveMasterLayoutOptions() {
      const designer = this;
      const ObjectMasterGuid = designer.cLoadedObjMasterGuid;

      if (ObjectMasterGuid && designer.oTempLayoutOptionsObj != null && designer.bChangedLayoutOptions) {
        const oMasterItem = [];
        const attrPayload = JSON.parse(JSON.stringify(designer.oTempLayoutOptionsObj));
        attrPayload.AttributeLabel = 'LayoutOptions';
        oMasterItem.push(attrPayload);

        akioma.invokeServerTask({
          name: 'Akioma.Swat.Repository.AttributeValuesTask',
          methodName: 'UpdateObjectMasterDesignAttributes',
          paramObj: {
            plcParameter: { ObjectMasterGuid: ObjectMasterGuid },
            dsDesignAttributeValue: { dsDesignAttributeValue: { eDesignAttributeValue: oMasterItem } }

          }
        }).done(() => {
          designer.aTempLayoutOptionsObj = null;
          designer.bChangedLayoutOptions = false;
          akioma.notification({ type: 'success', text: 'Container builder master attributes have been saved successfuly.', expire: 3000 });
        });
      }
    },
    /**
     * Method for removing layout options
     * @param {number} index Index of pattern
     * @private
     * @instance
     */
    _removeMasterLayoutOptions(index) {
      const model = this.dhx.getService('UIState');
      const rootObj = model.getRoot();
      const MasterLayoutOptionsAttr = rootObj._custom.find(attr => attr.label === 'Layout' || attr.AttributeLabel === 'Layout');
      const LayoutAttr = MasterLayoutOptionsAttr.original || MasterLayoutOptionsAttr;
      this.aTempLayoutOptions.splice(index, 1);
      LayoutAttr.CharacterValue = this.aTempLayoutOptions.join(',');
      this.oTempLayoutOptionsObj = LayoutAttr;
      this.bChangedLayoutOptions = true;
    },
    /**
     * Method for updating Object master container Layout pattern
     * @param {string} pattern The layout pattern eg. 1C, 2U
     * @private
     * @instance
     * @memberof ak_designer
     */
    _updateMasterLayoutOptions(pattern = '1C', index) {
      const model = this.dhx.getService('UIState');
      const rootObj = model.getRoot();
      const MasterLayoutOptionsAttr = rootObj._custom.find(attr => attr.label === 'Layout' || attr.AttributeLabel === 'Layout');
      const LayoutAttr = MasterLayoutOptionsAttr.original || MasterLayoutOptionsAttr;
      if (this.aTempLayoutOptions[index])
        this.aTempLayoutOptions[index] = pattern;
      else
        this.aTempLayoutOptions.push(pattern);
      LayoutAttr.CharacterValue = this.aTempLayoutOptions.join(',');
      this.oTempLayoutOptionsObj = LayoutAttr;
      this.bChangedLayoutOptions = true;
    },
    /**
     * Method called on selection of context menu option from designer tree
     * @param {string} menuid The menu id selected in tree context menu
     * @param {object} item Selected object in tree
     * @instance
     * @private
     * @memberof ak_designer
     */
    _customTreeContextMenuClick: function(menuid, item) {
      const oSelf = this.self;

      // actions based on menuid
      switch (menuid) {
        case 'replace':
          oSelf._replacePlaceholderContextMenu(item);
          return;
        case 'launchMaster':
          oSelf._launchMasterContextMenu();
          return;
        case 'remove':
          return oSelf._removeInstanceContextMenu(item);
        case 'removePage':
          return oSelf._removePageContextMenu(item);
        case 'addpage':
          oSelf._addPageContextMenu(item);
          break;
      }

      return null;
    },
    checkForTempAttributes: function() {
      const oSelf = this;
      let bChanges = false;

      // check master attrs for changes
      for (const ObjectMasterGuid in oSelf.tempAttributes.masters) {
        if (oSelf.tempAttributes.masters[ObjectMasterGuid] != null)
          bChanges = true;

      }

      for (const ObjectInstanceGuid in oSelf.tempAttributes.instances) {
        if (oSelf.tempAttributes.instances[ObjectInstanceGuid] != null)
          bChanges = true;


      }

      return bChanges;
    },
    // save all in memory temporary attributes, will also remove them from memory
    saveAttributes() {
      const oSelf = this;
      const deferred = $.Deferred();
      const deferreds = [];
      let bChanges = false;

      // save object master attributes
      for (const ObjectMasterGuid in oSelf.tempAttributes.masters) {
        if (oSelf.tempAttributes.masters[ObjectMasterGuid] != null) {
          const oMasterItem = [];
          for (const j in oSelf.tempAttributes.masters[ObjectMasterGuid])
            oMasterItem.push(oSelf.tempAttributes.masters[ObjectMasterGuid][j]);

          bChanges = true;
          const updateObjectMasterAttributes = (oSelf, ObjectMasterGuid, deferreds) => {
            const deferredMasterAttributesUpdate = akioma.invokeServerTask({
              name: 'Akioma.Swat.Repository.AttributeValuesTask',
              methodName: 'UpdateObjectMasterDesignAttributes',
              paramObj: {
                plcParameter: { ObjectMasterGuid: ObjectMasterGuid },
                dsDesignAttributeValue: { dsDesignAttributeValue: { eDesignAttributeValue: oMasterItem } }

              }
            }).done(() => {
              oSelf.tempAttributes.masters[ObjectMasterGuid] = null;
            }).fail(() => {
              akioma.notification({ type: 'error', text: 'Could not update object attribute !' });
            });

            deferreds.push(deferredMasterAttributesUpdate);
          };
          updateObjectMasterAttributes(oSelf, ObjectMasterGuid, deferreds);
        }
      }

      // save object instances attributes
      for (const ObjectInstanceGuid in oSelf.tempAttributes.instances) {
        if (oSelf.tempAttributes.instances[ObjectInstanceGuid] != null) {
          const oInsItems = [];
          let bSkipSave = false;
          for (const j in oSelf.tempAttributes.instances[ObjectInstanceGuid]) {
            if (oSelf.tempAttributes.instances[ObjectInstanceGuid][j].ObjectInstanceGuid == '')
              bSkipSave = true;
            oInsItems.push(oSelf.tempAttributes.instances[ObjectInstanceGuid][j]);
          }

          if (bSkipSave == false) {
            bChanges = true;
            const updateObjectInstanceAttributes = (oSelf, ObjectInstanceGuid, oInsItems, deferreds) => {
              const deferredAttributesUpdate = akioma.invokeServerTask({
                name: 'Akioma.Swat.Repository.AttributeValuesTask',
                methodName: 'UpdateObjectInstanceDesignAttributes',
                paramObj: {
                  plcParameter: { ObjectInstanceGuid: ObjectInstanceGuid },
                  dsDesignAttributeValue: { dsDesignAttributeValue: { eDesignAttributeValue: oInsItems } }
                }
              }).done(() => {
                oSelf.tempAttributes.instances[ObjectInstanceGuid] = null;
              })
                .fail(() => {
                  akioma.notification({ type: 'error', text: 'Could not update object attribute !' });
                });
              deferreds.push(deferredAttributesUpdate);
            };
            updateObjectInstanceAttributes(oSelf, ObjectInstanceGuid, oInsItems, deferreds);
          }
        }
      }

      $.when.apply(null, deferreds).done(() => {
        deferred.resolve({ hasChanges: bChanges });
      }).fail(() => {
        deferred.reject();
      });

      return deferred.promise();

    },

    saveTempAttributes: function(cGuid, cObjectInstanceGuid) {
      const oSelf = this;
      let oItems = [];
      if (oSelf.tempAttributes.instances[cGuid])
        oItems = oSelf.tempAttributes.instances[cGuid];
      else
        oItems = oSelf.tempAttributes.masters[cGuid];


      // replace objectinstanceguid with new created guid here

      if (oItems) {
        const aRecords = [];
        for (const j in oItems) {
          oItems[j].ObjectInstanceGuid = cObjectInstanceGuid;
          aRecords.push(Object.assign({}, oItems[j]));
        }

        aRecords.forEach(val => {
          val.LogicalValue = (val.LogicalValue === '' ? false : val.LogicalValue);
        });
        akioma.invokeServerTask({
          name: 'Akioma.Swat.Repository.AttributeValuesTask',
          methodName: 'UpdateObjectInstanceDesignAttributes',
          paramObj: {
            plcParameter: { ObjectInstanceGuid: cObjectInstanceGuid },
            dsDesignAttributeValue: { dsDesignAttributeValue: { eDesignAttributeValue: aRecords } }

          }
        }).done(() => {
          oSelf.tempAttributes.instances[cGuid] = [];
          oSelf.tempAttributes.placeholder = {};
          if (oSelf.propertyGrid)
            oSelf.propertyGrid.getProperties(cGuid);
        })
          .fail(() => {
            akioma.notification({ type: 'error', text: 'Could not update object attribute !' });
          });
      }
    },
    /**
     * Method for setting the designer type
     * @param {string} type Can be instancesdesign or containerdesign
     * @memberOf ak_designer
     * @instance
     * @returns {void}
     */
    setDesignerType: function(type) {
      this.designerType = type;
    },
    /**
     * Method for returning the correct designer type
     * @memberOf ak_designer
     * @instance
     * @returns {string}
     */
    getDesignerType() {
      return this.designerType;
    },

    loadInstancesDesign(cObjMasterName, cObjMasterGuid, cObjMasterType) {
      const oDhxDataSourceObjMaster = this.store.getStore('eSmartObjectMaster').data.pull;
      const oDhxDataSourceObjInstances = this.store.getStore('eSmartObjectInstance').data.pull;

      const akObj = [];

      const aObjs = akObj['objects'] = [];
      const aInstances = akObj['instances'] = [];
      const aInstancesGuids = [];
      const aInstancesMap = {};
      const oSelf = this;
      const designerType = this.getDesignerType();
      oSelf.tempAttributes.instances = [];
      oSelf.tempAttributes.masters = [];

      delete oSelf.cLastSelectGuid;

      oSelf.setActiveMasterProps({
        LoadedObjMasterGuid: cObjMasterGuid,
        LoadedObjMasterName: cObjMasterName,
        LoadedObjMasterType: cObjMasterType
      });

      const objsStore = oDhxDataSourceObjMaster;
      const instsStore = oDhxDataSourceObjInstances;

      oSelf.aTempLayoutOptions = [];
      oSelf.aPageGuids = [];
      oSelf.aTempPageLabels = [];

      // this will contain everything
      let oRoot = {};

      oSelf.getAncestor('panel').dhx.progressOn();


      // add object instaces
      for (const key in instsStore) {
        const oElm = instsStore[key];
        if (aInstances[oElm.containerobjectmasterguid] == undefined)
          aInstances[oElm.containerobjectmasterguid] = [];

        aInstancesGuids.push(oElm.objectinstanceguid);

        const aInstance = {
          parentid: oElm.containerobjectmasterguid,
          parentname: oElm.objectmastername,
          parentinstanceguid: oElm.parentinstanceguid,
          id: oElm.objectinstanceguid,
          objguid: oElm.objectinstanceguid,
          objname: oElm.objectmastername,
          pageguid: oElm.pageguid,
          objtype: oElm.instancetypename,
          typename: oElm.instancetypename,
          objdesc: oElm.instancedescription,
          objseq: oElm.objectsequence,
          insname: oElm.instancename,
          position: oElm.layoutposition,
          order: oElm.objectsequence,
          designPlaceholder: false,
          designPlaceholderOptions: {}
        };

        aInstances[oElm.containerobjectmasterguid].push(aInstance);
        aInstancesMap[oElm.objectinstanceguid] = aInstance;
      }

      // add objects
      for (const key in objsStore) {
        const oElm = objsStore[key];


        if (cObjMasterName == oElm.objectname)
          oRoot = oElm;

        aObjs[oElm.objectmasterguid] = {
          id: oElm.objectmasterguid,
          type: oElm.objecttypename.toLowerCase(),
          name: oElm.objectname.toLowerCase(),
          extension: oElm.objectextension
        };
      }


      // load root and it's children
      let cRootObjType = oRoot.objecttypename.toLowerCase();
      const cRootObjName = oRoot.objectname.toLowerCase();

      const deferreds = [];

      // load attributes objectmaster
      deferreds.push(akioma.RepositoryStructure.fetchMasterDesignAttributes(cObjMasterGuid));

      // load attributes object instances
      deferreds.push(akioma.RepositoryStructure.fetchInstancePlaceholderDesignAttributes(aInstancesGuids));

      $.when.apply(null, deferreds).then((oResultMaster, oResultInstances) => {

        let aPropsMaster = oResultMaster.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue;
        const aPropsInstances = oResultInstances.plcDataset.dsAttributeValue.eSmartAttributeValue;
        let aLayoutPatterns = [];

        for (const p in aPropsMaster)
          aPropsMaster[p].ObjectMasterGuid = cObjMasterGuid;

        // check and setup designer placeholder objects
        for (const p in aPropsInstances) {
          const aAttr = aPropsInstances[p];
          switch (aAttr.AttributeLabel) {
            case 'designPlaceholder':
              if (aAttr.LogicalValue) {
                aInstancesMap[aAttr.ObjectInstanceGuid].orgobjtype = aInstancesMap[aAttr.ObjectInstanceGuid].objtype;
                aInstancesMap[aAttr.ObjectInstanceGuid].objtype = 'placeholder';
              }

              aInstancesMap[aAttr.ObjectInstanceGuid].designPlaceholder = aAttr.LogicalValue;
              break;

            case 'designPlaceholderOptions':
              try {
                aInstancesMap[aAttr.ObjectInstanceGuid].designPlaceholderOptions = JSON.parse(aAttr.CharacterValue);
              } catch (e) {
                aInstancesMap[aAttr.ObjectInstanceGuid].designPlaceholderOptions = {};
              }
              break;
          }
        }

        oSelf.oOriginalMasterLayoutOptions = null;
        aPropsMaster = _.chain(aPropsMaster).sortBy(item => {
          if (item.AttributeLabel == 'LayoutOptions') {
            aLayoutPatterns = item.CharacterValue.split(',');
            oSelf.oOriginalMasterLayoutOptions = item;
          }
          return [ item.ObjectMasterGuid, item.AttributeLabel ].join('_');
        }).reject(item => (item.AttributeLabel == 'LayoutOptions')).value();

        oSelf.aTempLayoutOptions = aLayoutPatterns;

        let aProps = aPropsMaster;
        let iDif = 0;

        cRootObjType = oSelf.getRootObjType(cRootObjType);
        const iLayoutPatterns = 1;
        const iSkip = 1;

        oSelf.props = [];

        // setup root object properties based on designer type opened
        if (cRootObjType == 'layout') {
          if (oSelf.props[cObjMasterGuid] == undefined)
            oSelf.props[cObjMasterGuid] = [];


          aProps = oSelf.propertyGrid.sortAttributes(aProps, cObjMasterGuid);

          aProps.unshift({
            AttributeLabel: 'name',
            name: 'name',
            _idd: `1_${cObjMasterGuid}_name`,
            type: 'input',
            CharacterValue: oSelf.cLoadedObjMasterName,
            RepositoryType: 'Character',
            ObjectMasterGuid: cObjMasterGuid,
            IsInherited: false,
            TechnicalName: 'name',
            PropertyOrEvent: true,
            AppliesAtRuntime: true,
            ConstantValue: false
          });

          if (designerType === 'containerdesign') {
            aProps.unshift({
              AttributeLabel: 'Layout',
              name: 'Layout',
              _idd: `0_${cObjMasterGuid}_maintype`,
              type: 'select',
              value: aLayoutPatterns[0],
              CharacterValue: aLayoutPatterns[0],
              RepositoryType: 'Character',
              ObjectMasterGuid: cObjMasterGuid,
              IsInherited: false,
              TechnicalName: 'Layout',
              PropertyOrEvent: true,
              AppliesAtRuntime: true,
              ConstantValue: false
            });
          }

        }

        for (const a in aProps) {
          if (aProps[a].ObjectMasterGuid != '') {
            // add as object master attribute
            if (oSelf.props[aProps[a].ObjectMasterGuid] == undefined) {
              oSelf.props[aProps[a].ObjectMasterGuid] = [];
              iDif = parseInt(a);
            }
            let logical = false;
            if (aProps[a].RepositoryType.toUpperCase() == 'LOGICAL')
              logical = true;
            let cType = 'input';
            if (logical)
              cType = 'checkbox';
            oSelf.props[aProps[a].ObjectMasterGuid].push({
              label: aProps[a].AttributeLabel,
              type: cType,
              _idd: `${parseInt(a) + iSkip - iDif}_${aProps[a].ObjectMasterGuid}`,
              value: (logical ? aProps[a].LogicalValue : aProps[a].CharacterValue),
              original: aProps[a],
              ...aProps[a]
            });
          }
        }

        // if tabbar or layout root object or just open abstract object
        let oConfig;
        if (cRootObjType == 'layout' || cRootObjType == 'tabbar' || cRootObjType == 'form') {
          oConfig = {
            ui: cRootObjType,
            config: {
              $name: `${cRootObjName} / ${cRootObjType}`,
              header: cRootObjName
            },
            _custom: oSelf.props[cObjMasterGuid],
            guid: cObjMasterGuid,
            cells: []
          };
          if (cRootObjType == 'layout')
            oConfig.config.type = aLayoutPatterns[0] || '1C'; // cLayoutPatterns
          else if (cRootObjType == 'tabbar')
            oConfig.config.active = 'a';
        } else {
          oConfig = {
            ui: 'abstract',
            config: {
              $name: `${cRootObjName} / ${cRootObjType}`,
              header: cRootObjName
            }
          };
        }

        const aPointersCells = [];
        const aPointersPages = [];
        aPointersCells[oRoot.objectmasterguid] = oConfig;

        const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

        const rootObjectType = oRoot.objecttypename.toLowerCase();
        // if form load config recursively
        if (rootObjectType === 'swatform') {
          oConfig.guid = '';
          oConfig = this.formToTreeStruct(oConfig, aInstances[oSelf.cLoadedObjMasterGuid]);
          oConfig.guid = oSelf.cLoadedObjMasterGuid;
        } else { // any other object type load
          // add main object master instances | tabbar with pages
          const objects = oSelf.addMainObjectMasterInstances({ aObjs, aInstances, aPointersCells, oConfig, aLayoutPatterns, iLayoutPatterns, alphabet });
          let iNoOfCells;
          ({ iNoOfCells, oConfig } = objects);

          if (oConfig)
            iNoOfCells = oSelf.getChildrenCells(oConfig.cells);

          const { iNoOfChildsFromPattern } = objects;
          if (oConfig && iNoOfCells < iNoOfChildsFromPattern) {
            let iMissingElms = iNoOfChildsFromPattern - iNoOfCells;
            let panelIndex = 0;
            while (iMissingElms > 0) {
              oConfig.cells.push(this.getEmptyCell(panelIndex));
              panelIndex++;
              iMissingElms--;
            }
          }

          // add instances in pages
          for (const objguid in aObjs) {
            const aSpecificParentInstance = aInstances[objguid];
            for (const e in aSpecificParentInstance) {
              const oIns = aSpecificParentInstance[e];
              let cObjType = oIns.objtype;
              const navTypes = [ 'swatribbon', 'swattoolbar' ];
              if (oIns.objtype === 'placeholder' && !isNull(oIns.orgobjtype) && navTypes.indexOf(oIns.orgobjtype.toLowerCase()) > -1)
                cObjType = oIns.orgobjtype;

              const cObjName = oIns.objname;

              let oConfigCurr;
              if (aPointersCells[oIns.parentid] != undefined)
                oConfigCurr = aPointersCells[oIns.parentid];
              else
                oConfigCurr = oConfig;

              if (aPointersPages[oIns.pageguid])
                oConfigCurr = aPointersPages[oIns.pageguid];

              cObjType = oSelf.getObjType(cObjType);

              // if not in page/ first level only
              if (oIns.pageguid != '' && cObjType != 'sdo' && cObjType != 'businessentity') {
                let oNewObject;
                if (cObjType == 'layout' || cObjType == 'tabbar') {
                  oNewObject = {
                    ui: cObjType,
                    config: {
                      id: oIns.objguid,
                      $name: `${oIns.insname} / ${cObjType}`,
                      type: aLayoutPatterns[iLayoutPatterns] || '1C',
                      header: cObjName,
                      active: 'a'
                    },
                    order: oIns.order,
                    position: oIns.position,
                    cells: []
                  };

                } else if (cObjType == 'ribbon') {
                  oNewObject = {
                    ui: 'ribbon',
                    guid: oIns.objguid,
                    _custom: oSelf.props[oIns.objguid],
                    $objtype: cObjType,
                    order: oIns.order,
                    position: oIns.position,
                    config: {
                      $name: `${oIns.insname} / ${cObjType}`,
                      $objname: oIns.insname,
                      $objtype: cObjType,
                      $orgobjtype: oIns.orgobjtype,
                      typename: oIns.typename,
                      $objicon: akioma.repository.getObjIcon(cObjType),
                      $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                      $objdesc: oIns.objdesc,
                      header: cObjName
                    }
                  };
                } else if (cObjType == 'toolbar' || cObjType == 'swattoolbar') {
                  oNewObject = {
                    ui: cObjType,
                    guid: oIns.objguid,
                    _custom: oSelf.props[oIns.objguid],
                    order: oIns.order,
                    $objtype: cObjType,
                    position: oIns.position,
                    config: {
                      $objname: oIns.insname,
                      $objtype: cObjType,
                      $orgobjtype: oIns.orgobjtype,
                      typename: oIns.typename,
                      $objicon: akioma.repository.getObjIcon(cObjType),
                      $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                      $objdesc: oIns.objdesc,
                      $name: `${oIns.insname} / ${cObjType}`,
                      header: cObjName
                    }
                  };
                } else if (cObjType == 'sdo' || cObjType == 'businessentity' || cObjType == 'swatsdo' || cObjType == 'swatbusinessentity') {
                  oNewObject = {
                    ui: cObjType,
                    pageguid: oIns.pageguid,
                    guid: oIns.objguid,
                    order: oIns.order,
                    $objtype: cObjType,
                    position: oIns.position,
                    _custom: oSelf.props[oIns.objguid],
                    config: {
                      $name: `${oIns.insname}/${cObjType}`,
                      header: cObjName,
                      $objicon: akioma.repository.getObjIcon(cObjType),
                      $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                      $objtype: cObjType,
                      $orgobjtype: oIns.orgobjtype,
                      typename: oIns.typename,
                      $objdesc: oIns.objdesc,
                      $objname: oIns.insname
                    }
                  };

                } else if (cObjType == 'placeholder') {
                  oNewObject = {
                    ui: 'placeholder',
                    pageguid: oIns.pageguid,
                    $objtype: cObjType,

                    order: oIns.order,
                    position: oIns.position,
                    guid: oIns.objguid,
                    _custom: oSelf.props[oIns.objguid],
                    config: {
                      $name: `${oIns.insname} / ${cObjType}`,
                      header: cObjName,
                      $objicon: akioma.repository.getObjIcon(cObjType),
                      $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                      $objtype: cObjType,
                      $objname: oIns.insname,
                      $orgobjtype: oIns.orgobjtype,
                      typename: oIns.typename,
                      $objdesc: oIns.objdesc
                    }
                  };
                } else {
                  oNewObject = {
                    ui: 'abstract',
                    pageguid: oIns.pageguid,
                    $objtype: cObjType,
                    order: oIns.order,
                    position: oIns.position,
                    guid: oIns.objguid,
                    _custom: oSelf.props[oIns.objguid],
                    config: {
                      $name: `${oIns.insname} / ${cObjType}`,
                      header: cObjName,
                      $objicon: akioma.repository.getObjIcon(cObjType),
                      $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                      $objtype: cObjType,
                      $orgobjtype: oIns.orgobjtype,
                      typename: oIns.typename,
                      $objname: oIns.insname,
                      $objdesc: oIns.objdesc
                    }
                  };
                }

                oConfigCurr.cells.push(oNewObject);
                aPointersCells[oIns.objguid] = oConfigCurr.cells[oConfigCurr.cells.indexOf(oNewObject)];
              }

              oConfigCurr.cells = _.sortBy(oConfigCurr.cells, 'position');
            }
          }
        }

        oSelf.getAncestor('panel').dhx.progressOff();

        oSelf.dhx.getService('UIState').reset();

        const m = oSelf.dhx.getService('UIState');
        // load config and expand only root
        m.rootExpandOnly = true;
        oSelf.dhx.loadConfig(oConfig);
        m.rootExpandOnly = false;

        // set selected object root object
        const rootID = m.getRoot().$id;
        m._cursor = '';
        m.setCursor(rootID);
      });
    },
    /**
     * Method for creating nested tree structure for form object type master
     * @param {object} config
     * @param {array} instances
     */
    formToTreeStruct(config, instances) {
      const items = instances.filter(item => item.parentinstanceguid === config.guid || '').sort((a, b) => (a.objseq > b.objseq) ? 1 : -1);

      items.forEach(oIns => {
        const cObjType = oIns.objtype;
        const cObjName = oIns.objname;

        let oNewObject = {
          ui: 'abstract',
          guid: oIns.objguid,
          $objtype: cObjType,
          order: oIns.order,
          position: oIns.position,
          _custom: this.props[oIns.objguid],
          config: {
            $name: `${oIns.insname}/${cObjType}`,
            header: cObjName,
            $objicon: akioma.repository.getObjIcon(cObjType),
            $objstyle: akioma.repository.getObjStyle(oIns.objtype),
            $objtype: cObjType,
            $orgobjtype: oIns.orgobjtype,
            typename: oIns.typename,
            $objdesc: oIns.objdesc,
            $objname: oIns.insname
          },
          cells: []
        };

        const children = instances.filter(item => item.parentinstanceguid === oIns.objguid).sort((a, b) => (a.objseq > b.objseq) ? 1 : -1);
        if (children.length > 0)
          oNewObject = this.formToTreeStruct(oNewObject, instances);

        config.cells.push(oNewObject);

      });

      return config;
    },

    /**
     * add main object master instances | tabbar with pages
     * @param {object} objects
     */
    addMainObjectMasterInstances(objects) {
      const { aObjs, aInstances, aPointersCells, oConfig, aLayoutPatterns, iLayoutPatterns, alphabet } = objects;
      let { oMainTabbar } = objects;
      let iNoOfChildsFromPattern, iNoOfCells;

      let oConfigCurr;
      for (const objguid in aObjs) {
        const aSpecificParentInstance = aInstances[objguid];

        for (const e in aSpecificParentInstance) {

          const oIns = aSpecificParentInstance[e];
          let cObjType = oIns.objtype;
          const navTypes = [ 'swatribbon', 'swattoolbar' ];
          if (oIns.objtype === 'placeholder' && !isNull(oIns.orgobjtype) && navTypes.indexOf(oIns.orgobjtype.toLowerCase()) > -1)
            cObjType = oIns.orgobjtype;

          const cObjName = oIns.objname;

          if (aPointersCells[oIns.parentid] != undefined)
            oConfigCurr = aPointersCells[oIns.parentid];
          else
            oConfigCurr = oConfig;

          cObjType = this.getObjType(cObjType);

          let oNewObject;
          // if not in page / first level only
          if (oIns.pageguid == '') {
            if (cObjType == 'layout' || cObjType == 'tabbar') {
              oNewObject = {
                ui: cObjType,
                guid: oIns.objguid,
                order: oIns.order,
                position: oIns.position,
                config: {
                  id: oIns.objguid,
                  $name: `${oIns.insname}/${cObjType}`,
                  $objname: oIns.insname,
                  $objicon: akioma.repository.getObjIcon(oIns.objtype),
                  $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                  $objtype: cObjType,
                  $orgobjtype: oIns.orgobjtype,
                  typename: oIns.typename,
                  $objdesc: oIns.objdesc,
                  type: aLayoutPatterns[iLayoutPatterns] || '1C',
                  header: cObjName,
                  active: 'a'
                },
                cells: []
              };
            } else if (cObjType == 'ribbon') {
              oNewObject = {
                ui: 'ribbon',
                guid: oIns.objguid,
                order: oIns.order,
                $objtype: cObjType,
                position: ('' || oIns.position),
                _custom: this.props[oIns.objguid],
                config: {
                  $name: `${oIns.insname}/${cObjType}`,
                  $objname: oIns.insname,
                  $objicon: akioma.repository.getObjIcon(cObjType),
                  $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                  $objtype: cObjType,
                  $orgobjtype: oIns.orgobjtype,
                  typename: oIns.typename,
                  $objdesc: oIns.objdesc
                }
              };
            } else if (cObjType == 'toolbar' || cObjType == 'swattoolbar') {
              oNewObject = {
                ui: cObjType,
                guid: oIns.objguid,
                order: oIns.order,
                $objtype: cObjType,
                position: ('' || oIns.position),
                _custom: this.props[oIns.objguid],
                config: {
                  $name: `${oIns.insname}/${cObjType}`,
                  $objname: oIns.insname,
                  $objicon: akioma.repository.getObjIcon(cObjType),
                  $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                  $objtype: cObjType,
                  $orgobjtype: oIns.orgobjtype,
                  typename: oIns.typename,
                  $objdesc: oIns.objdesc
                }
              };
            } else if (cObjType == 'sdo' || cObjType == 'businessentity' || cObjType == 'swatsdo' || cObjType == 'swatbusinessentity') {
              oNewObject = {
                ui: cObjType,
                pageguid: oIns.pageguid,
                guid: oIns.objguid,
                $objtype: cObjType,
                order: oIns.order,
                position: oIns.position,

                _custom: this.props[oIns.objguid],
                config: {
                  $name: `${oIns.insname}/${cObjType}`,
                  header: cObjName,
                  $objname: oIns.insname,
                  $objicon: akioma.repository.getObjIcon(cObjType),
                  $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                  $objtype: cObjType,
                  $orgobjtype: oIns.orgobjtype,
                  typename: oIns.typename,
                  $objdesc: oIns.objdesc
                }
              };

            } else if (cObjType == 'placeholder') {
              oNewObject = {
                ui: 'placeholder',
                pageguid: oIns.pageguid,
                $objtype: cObjType,

                order: oIns.order,
                position: oIns.position,
                guid: oIns.objguid,
                _custom: this.props[oIns.objguid],
                config: {
                  $name: `${oIns.insname} / ${cObjType}`,
                  header: cObjName,
                  $objicon: akioma.repository.getObjIcon(cObjType),
                  $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                  $objtype: cObjType,
                  $objname: oIns.insname,
                  $orgobjtype: oIns.orgobjtype,
                  typename: oIns.typename,
                  $objdesc: oIns.objdesc
                }
              };
            } else {
              oNewObject = {
                ui: 'abstract',
                pageguid: oIns.pageguid,
                guid: oIns.objguid,
                $objtype: cObjType,
                order: oIns.order,
                position: oIns.position,
                _custom: this.props[oIns.objguid],
                config: {
                  $name: `${oIns.insname}/${cObjType}`,
                  header: cObjName,
                  $objicon: akioma.repository.getObjIcon(cObjType),
                  $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                  $objtype: cObjType,
                  $orgobjtype: oIns.orgobjtype,
                  typename: oIns.typename,
                  $objdesc: oIns.objdesc,
                  $objname: oIns.insname
                }
              };
            }

            oNewObject.sequence = oIns.objseq;
            oConfigCurr.cells.push(oNewObject);
            aPointersCells[oIns.objguid] = oConfigCurr.cells[oConfigCurr.cells.indexOf(oNewObject)];

            if (cObjType == 'tabbar')
              oMainTabbar = aPointersCells[oIns.objguid];
          }
        }


        iNoOfChildsFromPattern = Number(oConfig.config.type.substr(0, 1));
        if (oConfigCurr)
          iNoOfCells = this.getChildrenCells(oConfigCurr.cells);

        // set LayoutPosition of instances a-z letters for the position for cells in container builder
        if (oConfigCurr && oConfigCurr.cells) {
          const aFinalCells = [];
          const aExcludedSpecialInstances = this.getChildrenCellsExcluded(oConfigCurr.cells);
          const aNonExcludedSpecialInstances = this.getChildrenCellsExceptExcluded(oConfigCurr.cells);
          const aOrderedByPosInstances = _.sortBy(oConfigCurr.cells, 'position').reverse();

          if (akioma.repository.designerTypes.current === 'containerdesign') {
            let iCells = 0;
            while (iCells < iNoOfChildsFromPattern) {
              const oAlphaElement = _.find(aNonExcludedSpecialInstances, item => item.position == alphabet[iCells]);
              if (oAlphaElement == null)
                aFinalCells.push(this.getEmptyCell(iCells));
              else
                aFinalCells.push(oAlphaElement);

              iCells++;
            }
          }

          if (aOrderedByPosInstances.length > 0) {
            if (aOrderedByPosInstances[0].position > alphabet[iNoOfChildsFromPattern - 1]) {
              akioma.notification({
                type: 'warning',
                text: `Please select a correct layout Type for "${oConfigCurr.config.$name
                }". Found LayoutPosition "${aOrderedByPosInstances[0].position
                }" instead of "${alphabet[iNoOfChildsFromPattern - 1]}".`
              });
            }
          }
          if (akioma.repository.designerTypes.current !== 'containerdesign')
            oConfigCurr.cells = aExcludedSpecialInstances.concat(aNonExcludedSpecialInstances);
          else
            oConfigCurr.cells = aExcludedSpecialInstances.concat(aFinalCells);


        }

      }

      return { oMainTabbar, iNoOfChildsFromPattern, iNoOfCells, oConfig, oConfigCurr };
    },
    /**
     * Method to return an empty cell object
     * @param {number} panelIndex The Index for the panel position, default 0
     * @memberOf ak_designer
     * @instance
     * @returns {object}
     */
    getEmptyCell(panelIndex = 0) {
      const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

      return {
        ui: 'empty',
        config: {
          $objicon: akioma.repository.getObjIcon('empty'),
          $objstyle: akioma.repository.getObjStyle('empty')
        },
        position: alphabet[panelIndex],
        id: alphabet[panelIndex]
      };
    },

    // builds JSON structure for designer preview//
    loadDesignerConfig: function(cObjMasterName, cObjMasterGuid, cObjMasterType) {

      if (akioma.repository.designerTypes.current !== 'containerdesign')
        this.loadInstancesDesign(cObjMasterName, cObjMasterGuid, cObjMasterType);
      else {
        const oDhxDataSourceObjMaster = this.store.getStore('eSmartObjectMaster').data.pull;
        const oDhxDataSourceObjInstances = this.store.getStore('eSmartObjectInstance').data.pull;
        const oDhxDataSourceObjPages = this.store.getStore('eSmartPage').data.pull;

        const akObj = [];

        const aObjs = akObj['objects'] = [];
        const aInstances = akObj['instances'] = [];
        const aPages = akObj['pages'] = [];
        const aInstancesGuids = [];
        const aInstancesMap = {};
        const oSelf = this;
        const designerType = this.getDesignerType();
        this.tempAttributes.instances = [];
        this.tempAttributes.masters = [];
        this.tempAttributes.placeholder = {};
        this.newInstances = [];
        this.newPageInstances = [];
        this.lastSelectedInstance = null;
        this.newPlaceholderInstances = [];

        delete this.cLastSelectGuid;

        this.setActiveMasterProps({
          LoadedObjMasterGuid: cObjMasterGuid,
          LoadedObjMasterName: cObjMasterName,
          LoadedObjMasterType: cObjMasterType
        });

        this.businessEntity.switchJSDOWorkingRecord('eSmartObjectMaster');

        const objsStore = oDhxDataSourceObjMaster;
        const instsStore = oDhxDataSourceObjInstances;
        let pagesStore = oDhxDataSourceObjPages;

        oSelf.aTempLayoutOptions = [];
        oSelf.aPageGuids = [];
        oSelf.aTempPageLabels = [];

        // this will contain everything
        let oRoot = {};

        oSelf.getAncestor('panel').dhx.progressOn();


        // add object instaces
        for (const key in instsStore) {
          const oElm = instsStore[key];
          if (aInstances[oElm.containerobjectmasterguid] == undefined)
            aInstances[oElm.containerobjectmasterguid] = [];

          aInstancesGuids.push(oElm.objectinstanceguid);

          const aInstance = {
            parentid: oElm.containerobjectmasterguid,
            parentname: oElm.objectmastername,
            parentinstanceguid: oElm.parentinstanceguid,
            id: oElm.objectinstanceguid,
            objguid: oElm.objectinstanceguid,
            objname: oElm.objectmastername,
            pageguid: oElm.pageguid,
            objtype: oElm.instancetypename,
            orgobjtype: oElm.instancetypename.toLowerCase(),
            typename: oElm.instancetypename,
            objdesc: oElm.instancedescription,
            objseq: oElm.objectsequence,
            insname: oElm.instancename,
            position: oElm.layoutposition,
            order: oElm.objectsequence,
            designPlaceholder: false,
            designPlaceholderOptions: {}
          };

          aInstances[oElm.containerobjectmasterguid].push(aInstance);
          aInstancesMap[oElm.objectinstanceguid] = aInstance;
        }

        // add object pages
        pagesStore = _.sortBy(pagesStore, 'pagesequence');
        for (const key in pagesStore) {
          const oElm = pagesStore[key];
          aPages.push({
            pageguid: oElm.pageguid,
            icon: oElm.pageicon,
            pagekey: oElm.pagekey,
            label: oElm.pagelabel,
            pagelabel: oElm.pagelabel,
            order: oElm.pagesequence,
            securitytoken: oElm.securitytoken,
            enableonview: oElm.enableonview,
            enableonmodify: oElm.enableonmodify,
            enableoncreate: oElm.enableoncreate
          });
          oSelf.aPageGuids.push(oElm.pageguid);
        }

        // add objects
        for (const key in objsStore) {
          const oElm = objsStore[key];

          if (typeof cObjMasterName === 'string'
           && typeof oElm.objectname === 'string'
           && cObjMasterName.toLowerCase().trim() === oElm.objectname.toLowerCase().trim())
            oRoot = oElm;

          aObjs[oElm.objectmasterguid] = {
            id: oElm.objectmasterguid,
            type: oElm.objecttypename.toLowerCase(),
            name: oElm.objectname.toLowerCase(),
            extension: oElm.objectextension
          };
        }


        // load root and it's children
        let cRootObjType = oRoot.objecttypename.toLowerCase();
        const cRootObjName = oRoot.objectname.toLowerCase();

        const deferreds = [];

        // load attributes objectmaster
        deferreds.push(akioma.RepositoryStructure.fetchMasterDesignAttributes(cObjMasterGuid));

        // load attributes object instances
        deferreds.push(akioma.RepositoryStructure.fetchInstancePlaceholderDesignAttributes(aInstancesGuids));

        $.when.apply(null, deferreds).then((oResultMaster, oResultInstances) => {

          let aPropsMaster = oResultMaster.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue;
          const aPropsInstances = oResultInstances.plcDataset.dsAttributeValue.eSmartAttributeValue;
          let aLayoutPatterns = [];

          for (const p in aPropsMaster)
            aPropsMaster[p].ObjectMasterGuid = cObjMasterGuid;

          // check and setup designer placeholder objects
          for (const p in aPropsInstances) {
            const aAttr = aPropsInstances[p];
            switch (aAttr.AttributeLabel) {
              case 'designPlaceholder':
                if (aAttr.LogicalValue) {
                  aInstancesMap[aAttr.ObjectInstanceGuid].orgobjtype = aInstancesMap[aAttr.ObjectInstanceGuid].objtype;
                  aInstancesMap[aAttr.ObjectInstanceGuid].objtype = 'placeholder';
                }

                aInstancesMap[aAttr.ObjectInstanceGuid].designPlaceholder = aAttr.LogicalValue;
                break;

              case 'designPlaceholderOptions':
                try {
                  aInstancesMap[aAttr.ObjectInstanceGuid].designPlaceholderOptions = JSON.parse(aAttr.CharacterValue);
                } catch (e) {
                  aInstancesMap[aAttr.ObjectInstanceGuid].designPlaceholderOptions = {};
                }
                break;
            }
          }

          oSelf.oOriginalMasterLayoutOptions = null;
          aPropsMaster = _.chain(aPropsMaster).sortBy(item => {
            if (item.AttributeLabel == 'LayoutOptions') {
              aLayoutPatterns = item.CharacterValue.split(',');
              oSelf.oOriginalMasterLayoutOptions = item;
            }
            return [ item.ObjectMasterGuid, item.AttributeLabel ].join('_');
          }).reject(item => (item.AttributeLabel == 'LayoutOptions')).value();

          oSelf.aTempLayoutOptions = aLayoutPatterns;

          let aProps = aPropsMaster;
          let iDif = 0;

          cRootObjType = oSelf.getRootObjType(cRootObjType);
          let iLayoutPatterns = 1;
          const iSkip = 0;

          oSelf.props = [];

          // setup root object properties based on designer type opened
          if (cRootObjType == 'layout') {
            if (oSelf.props[cObjMasterGuid] == undefined)
              oSelf.props[cObjMasterGuid] = [];


            aProps = oSelf.propertyGrid.sortAttributes(aProps, cObjMasterGuid);

            aProps.unshift({
              AttributeLabel: 'name',
              name: 'name',
              _idd: `1_${cObjMasterGuid}_name`,
              type: 'input',
              CharacterValue: oSelf.cLoadedObjMasterName,
              RepositoryType: 'Character',
              ObjectMasterGuid: cObjMasterGuid,
              IsInherited: false,
              TechnicalName: 'name',
              PropertyOrEvent: true,
              AppliesAtRuntime: true,
              ConstantValue: false
            });

            if (designerType === 'containerdesign') {
              aProps.unshift({
                AttributeLabel: 'Layout',
                name: 'Layout',
                _idd: `0_${cObjMasterGuid}_maintype`,
                type: 'select',
                value: aLayoutPatterns[0],
                CharacterValue: aLayoutPatterns[0],
                RepositoryType: 'Character',
                ObjectMasterGuid: cObjMasterGuid,
                IsInherited: false,
                TechnicalName: 'Layout',
                PropertyOrEvent: true,
                AppliesAtRuntime: true,
                ConstantValue: false
              });
            }

          }

          for (const a in aProps) {
            if (aProps[a].ObjectMasterGuid != '') {
              // add as object master attribute
              if (oSelf.props[aProps[a].ObjectMasterGuid] == undefined) {
                oSelf.props[aProps[a].ObjectMasterGuid] = [];
                iDif = parseInt(a);
              }
              let logical = false;
              if (aProps[a].RepositoryType.toUpperCase() == 'LOGICAL')
                logical = true;
              let cType = 'input';
              if (logical)
                cType = 'checkbox';
              oSelf.props[aProps[a].ObjectMasterGuid].push({
                label: aProps[a].AttributeLabel,
                type: cType,
                _idd: `${parseInt(a) + iSkip - iDif}_${aProps[a].ObjectMasterGuid}`,
                value: (logical ? aProps[a].LogicalValue : aProps[a].CharacterValue),
                original: aProps[a],
                ...aProps[a]
              });
            }
          }


          // if tabbar or layout root object or just open abstract object
          let oConfig;
          if (cRootObjType == 'layout' || cRootObjType == 'tabbar') {
            let objTypeIcon = cRootObjType;
            if (cRootObjType == 'layout')
              objTypeIcon = 'container';

            oConfig = {
              ui: cRootObjType,
              config: {
                $name: `${cRootObjName} / ${cRootObjType}`,
                header: cRootObjName,
                $objname: cRootObjName,
                $objtype: cRootObjType,
                $orgobjtype: oRoot.objecttypename.toLowerCase(),
                typename: oRoot.typename,
                $objicon: akioma.repository.getObjIcon(objTypeIcon),
                $objstyle: akioma.repository.getObjStyle(objTypeIcon),
                $objdesc: oRoot.objectdescription
              },
              _custom: oSelf.props[cObjMasterGuid],
              guid: cObjMasterGuid,
              cells: []
            };
            if (cRootObjType == 'layout')
              oConfig.config.type = aLayoutPatterns[0] || '1C'; // cLayoutPatterns
            else if (cRootObjType == 'tabbar')
              oConfig.config.active = 'a';
          } else {
            oConfig = {
              ui: 'abstract',
              config: {
                $name: `${cRootObjName} / ${cRootObjType}`,
                header: cRootObjName,
                $objname: cRootObjName,
                $objtype: cRootObjType,
                $orgobjtype: oRoot.objecttypename.toLowerCase(),
                typename: oRoot.typename,
                $objicon: akioma.repository.getObjIcon(oRoot.objecttypename.toLowerCase()),
                $objstyle: akioma.repository.getObjStyle(oRoot.objecttypename.toLowerCase()),
                $objdesc: oRoot.objectdescription
              }
            };
          }

          const aPointersCells = [];
          aPointersCells[oRoot.objectmasterguid] = oConfig;

          const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
          // add main object master instances | tabbar with pages
          const objects = oSelf.addMainObjectMasterInstances({ aObjs, aInstances, aPointersCells, oConfig, aLayoutPatterns, iLayoutPatterns, alphabet });
          const { oMainTabbar, iNoOfChildsFromPattern } = objects;
          let { iNoOfCells } = objects;

          oConfig = objects.oConfig;
          if (oConfig)
            iNoOfCells = oSelf.getChildrenCells(oConfig.cells);

          if (oConfig && iNoOfCells < iNoOfChildsFromPattern) {
            let iMissingElms = iNoOfChildsFromPattern - iNoOfCells;
            let panelIndex = 0;
            while (iMissingElms > 0) {
              oConfig.cells.push(oSelf.getEmptyCell(panelIndex));
              iMissingElms--;
              panelIndex++;
            }
          }

          // add individual pages in designer
          const aPointersPages = [];
          for (const p in aPages) {
            const oNewPage = {
              ui: 'layout',
              $objtype: 'page',
              order: aPages[p].order,
              config: {
                $name: `Page ${aPages[p].order} / ${aPages[p].label}`,
                id: aPages[p].pageguid,
                type: aLayoutPatterns[iLayoutPatterns] || '1C',
                title: aPages[p].label,
                $objname: aPages[p].label,
                $orgobjtype: 'swattab',
                typename: 'SwatTab',
                $objtype: 'swattab',
                $objicon: akioma.repository.getObjIcon('swattab'),
                $objstyle: akioma.repository.getObjStyle('swattab')
              },
              _custom: [
                {
                  label: 'Layout',
                  _idd: `0_${aPages[p].pageguid}_pagetype`,
                  name: 'type',
                  options: [ '1C', '2E', '2U', '3E', '3L', '3W', '3J', '3T', '3U', '4H', '4I', '4T', '4U', '4E', '4A', '4W', '4L', '4J', '4F', '4G', '4C', '5H', '5I', '5U', '5E', '5W', '5K', '5S', '5G', '5C', '6H', '6I', '6A', '6C', '6J', '6E', '6W', '7H', '7I' ],
                  type: 'select',
                  value: aLayoutPatterns[iLayoutPatterns],
                  original: oSelf.oOriginalMasterLayoutOptions
                },
                {
                  name: 'label',
                  _idd: `1_${aPages[p].pageguid}_pagelabel`,
                  label: 'Label',
                  type: 'input',
                  value: aPages[p].label,
                  original: aPages[p]
                },
                {
                  name: 'pagelabel',
                  _idd: `1_${aPages[p].pageguid}_pagelabel`,
                  label: 'Label',
                  type: 'input',
                  value: aPages[p].label,
                  original: aPages[p]
                },
                {
                  name: 'enableoncreate',
                  _idd: `2_${aPages[p].pageguid}_pageenableoncreate`,
                  label: 'EnableOnCreate',
                  type: 'checkbox',
                  value: aPages[p].enableoncreate,
                  original: aPages[p]
                },
                {
                  name: 'enableonmodify',
                  _idd: `3_${aPages[p].pageguid}_pageenableonmodify`,
                  label: 'EnableOnModify',
                  type: 'checkbox',
                  value: aPages[p].enableonmodify,
                  original: aPages[p]
                },
                {
                  name: 'enableonview',
                  _idd: `4_${aPages[p].pageguid}_pageenableonview`,
                  label: 'EnableOnView',
                  type: 'checkbox',
                  value: aPages[p].enableonview,
                  original: aPages[p]
                },
                {
                  name: 'pageicon',
                  _idd: `5_${aPages[p].pageguid}_pageicon`,
                  label: 'PageIcon',
                  type: 'input',
                  value: aPages[p].icon,
                  original: aPages[p]
                },
                {
                  name: 'pagekey',
                  _idd: `6_${aPages[p].pageguid}_pagekey`,
                  label: 'PageKey',
                  type: 'input',
                  value: aPages[p].pagekey,
                  original: aPages[p]
                },
                {
                  name: 'securitytoken',
                  _idd: `7_${aPages[p].pageguid}_pagesecuritytoken`,
                  label: 'SecurityToken',
                  type: 'input',
                  value: aPages[p].securitytoken,
                  original: aPages[p]
                },
                {
                  name: 'pagesequence',
                  _idd: `8_${aPages[p].pageguid}_pagesequence`,
                  label: 'PageSequence',
                  type: 'input',
                  value: aPages[p].pagesequence,
                  original: aPages[p]
                }
              ],
              guid: aPages[p].pageguid,
              cells: []
            };
            oMainTabbar.cells.push(oNewPage);
            aPointersPages[aPages[p].pageguid] = oMainTabbar.cells[oMainTabbar.cells.indexOf(oNewPage)];
            iLayoutPatterns++;
          }


          // add instances in pages
          for (const objguid in aObjs) {
            const aSpecificParentInstance = aInstances[objguid];
            for (const e in aSpecificParentInstance) {
              const oIns = aSpecificParentInstance[e];
              let cObjType = oIns.objtype;
              const navTypes = [ 'swatribbon', 'swattoolbar' ];
              if (oIns.objtype === 'placeholder' && !isNull(oIns.orgobjtype) && navTypes.indexOf(oIns.orgobjtype.toLowerCase()) > -1)
                cObjType = oIns.orgobjtype;

              const cObjName = oIns.objname;

              let oConfigCurr;
              if (aPointersCells[oIns.parentid] != undefined)
                oConfigCurr = aPointersCells[oIns.parentid];
              else
                oConfigCurr = oConfig;

              if (aPointersPages[oIns.pageguid])
                oConfigCurr = aPointersPages[oIns.pageguid];

              cObjType = oSelf.getObjType(cObjType);

              let oNewObject;
              // if not in page/ first level only
              if (oIns.pageguid != '' && cObjType != 'sdo' && cObjType != 'businessentity') {
                if (cObjType == 'layout' || cObjType == 'tabbar') {
                  oNewObject = {
                    ui: cObjType,
                    config: {
                      id: oIns.objguid,
                      $name: `${oIns.insname} / ${cObjType}`,
                      type: aLayoutPatterns[iLayoutPatterns] || '1C',
                      header: cObjName,
                      active: 'a',
                      $objname: oIns.insname,
                      $objtype: cObjType,
                      $orgobjtype: oIns.orgobjtype,
                      typename: oIns.typename,
                      $objicon: akioma.repository.getObjIcon(cObjType),
                      $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                      $objdesc: oIns.objdesc
                    },
                    order: oIns.order,
                    position: oIns.position,
                    cells: []
                  };

                } else if (cObjType == 'ribbon') {
                  oNewObject = {
                    ui: 'ribbon',
                    guid: oIns.objguid,
                    _custom: oSelf.props[oIns.objguid],
                    $objtype: cObjType,
                    order: oIns.order,
                    position: oIns.position,
                    config: {
                      $name: `${oIns.insname} / ${cObjType}`,
                      $objname: oIns.insname,
                      $objtype: cObjType,
                      $orgobjtype: oIns.orgobjtype,
                      typename: oIns.typename,
                      $objicon: akioma.repository.getObjIcon(cObjType),
                      $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                      $objdesc: oIns.objdesc,
                      header: cObjName
                    }
                  };
                } else if (cObjType == 'toolbar' || cObjType == 'swattoolbar') {
                  oNewObject = {
                    ui: cObjType,
                    guid: oIns.objguid,
                    _custom: oSelf.props[oIns.objguid],
                    order: oIns.order,
                    $objtype: cObjType,
                    position: oIns.position,
                    config: {
                      $name: `${oIns.insname} / ${cObjType}`,
                      $objname: oIns.insname,
                      $objtype: cObjType,
                      $orgobjtype: oIns.orgobjtype,
                      typename: oIns.typename,
                      $objicon: akioma.repository.getObjIcon(cObjType),
                      $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                      $objdesc: oIns.objdesc,
                      header: cObjName
                    }
                  };
                } else if (cObjType == 'sdo' || cObjType == 'businessentity' || cObjType == 'swatsdo' || cObjType == 'swatbusinessentity') {
                  oNewObject = {
                    ui: cObjType,
                    pageguid: oIns.pageguid,
                    guid: oIns.objguid,
                    order: oIns.order,
                    $objtype: cObjType,
                    position: oIns.position,
                    _custom: oSelf.props[oIns.objguid],
                    config: {
                      $name: `${oIns.insname}/${cObjType}`,
                      header: cObjName,
                      $objname: oIns.insname,
                      $orgobjtype: oIns.orgobjtype,
                      typename: oIns.typename,
                      $objtype: cObjType,
                      $objicon: akioma.repository.getObjIcon(cObjType),
                      $objdesc: oIns.objdesc,
                      $objstyle: akioma.repository.getObjStyle(oIns.objtype)
                    }
                  };

                } else if (cObjType == 'placeholder') {
                  oNewObject = {
                    ui: 'placeholder',
                    pageguid: oIns.pageguid,
                    $objtype: cObjType,

                    order: oIns.order,
                    position: oIns.position,
                    guid: oIns.objguid,
                    _custom: oSelf.props[oIns.objguid],
                    config: {
                      $name: `${oIns.insname} / ${cObjType}`,
                      header: cObjName,
                      $objicon: akioma.repository.getObjIcon(cObjType),
                      $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                      $objtype: cObjType,
                      $objname: oIns.insname,
                      $objdesc: oIns.objdesc,
                      $orgobjtype: oIns.orgobjtype,
                      typename: oIns.typename
                    }
                  };
                } else {
                  oNewObject = {
                    ui: 'abstract',
                    pageguid: oIns.pageguid,
                    $objtype: cObjType,
                    order: oIns.order,
                    position: oIns.position,
                    guid: oIns.objguid,
                    _custom: oSelf.props[oIns.objguid],
                    config: {
                      $name: `${oIns.insname} / ${cObjType}`,
                      header: cObjName,
                      $objicon: akioma.repository.getObjIcon(cObjType),
                      $objstyle: akioma.repository.getObjStyle(oIns.objtype),
                      $objtype: cObjType,
                      $orgobjtype: oIns.orgobjtype,
                      typename: oIns.typename,
                      $objdesc: oIns.objdesc,
                      $objname: oIns.insname
                    }
                  };
                }

                oConfigCurr.cells.push(oNewObject);
                aPointersCells[oIns.objguid] = oConfigCurr.cells[oConfigCurr.cells.indexOf(oNewObject)];
              }

              oConfigCurr.cells = _.sortBy(oConfigCurr.cells, 'position');

            }
          }

          // sets correct LayoutPosition for each cell in pages
          if (oMainTabbar) {
            for (const ip in oMainTabbar.cells) {
              const oPage = oMainTabbar.cells[ip];
              const cConfigType = oPage.config.type || '1C';
              const iNoOfChildsFromPattern = Number(cConfigType.substr(0, 1));
              const aNonExcludedSpecialInstances = oSelf.getChildrenCellsExceptExcluded(oPage.cells);
              const aExcludedSpecialInstances = oSelf.getChildrenCellsExcluded(oPage.cells);
              const aOrderedByPosInstances = _.sortBy(oPage.cells, 'position').reverse();

              let iCells = 0;
              const aFinalCells = [];
              while (iCells < iNoOfChildsFromPattern) {
                const oAlphaElement = _.find(aNonExcludedSpecialInstances, item => item.position == alphabet[iCells]);
                if (oAlphaElement == null)
                  aFinalCells.push(oSelf.getEmptyCell(iCells));
                else
                  aFinalCells.push(oAlphaElement);

                iCells++;
              }

              if (aOrderedByPosInstances.length > 0) {
                if (aOrderedByPosInstances[0].position > alphabet[iNoOfChildsFromPattern - 1]) {
                  akioma.notification({
                    type: 'warning',
                    text: `Please select a correct layout Type for "${oPage.config.$name
                    }". Found LayoutPosition "${aOrderedByPosInstances[0].position
                    }" instead of "${alphabet[iNoOfChildsFromPattern - 1]}".`
                  });
                }
              }

              oPage.cells = aExcludedSpecialInstances.concat(aFinalCells);
            }
          }

          oSelf.getAncestor('panel').dhx.progressOff();

          oSelf.dhx.getService('UIState').reset();

          const m = oSelf.dhx.getService('UIState');

          m.previousOpenStates = oSelf._treeNodesOpenState;
          oSelf.dhx.loadConfig(oConfig);

          // set selected object root object
          const rootID = m.getRoot().$id;
          m._cursor = '';
          akioma.repository.setGenericDesignerPropsContext({
            typeOfRecord: 'master',
            dataSource: oSelf.dataSource,
            propertyGrid: oSelf.propertyGrid
          });
          m.setCursor(rootID);
        });
      }
    },
    /**
     * Method for setting designer master opened properties
     * @instance
     * @memberof ak_designer
     * @param {object} opts
     */
    setActiveMasterProps({ LoadedObjMasterGuid, LoadedObjMasterName, LoadedObjMasterType }) {
      this.cLoadedObjMasterGuid = LoadedObjMasterGuid;
      this.cLoadedObjMasterName = LoadedObjMasterName;
      this.cLoadedObjMasterType = LoadedObjMasterType;
    },
    getChildrenCellsExcluded: function(oConf) {
      const aSkipObjNames = [ 'swatbusinessentity', 'swatribbon', 'ribbon', 'swattoolbar', 'toolbar' ];
      return _.filter(oConf, item => {
        const bBePlaceholder = (item.ui == 'placeholder' && item.config.$orgobjtype.toLowerCase() == 'swatbusinessentity');

        return (aSkipObjNames.indexOf(item.ui) > -1 || bBePlaceholder);
      });
    },
    getChildrenCellsExceptExcluded: function(oConf) {
      const aSkipObjNames = [ 'swatbusinessentity', 'swatribbon', 'ribbon', 'swattoolbar', 'toolbar' ];
      return _.filter(oConf, item => {
        const bBePlaceholder = (item.ui == 'placeholder' && item.config.$orgobjtype.toLowerCase() == 'swatbusinessentity');

        return (aSkipObjNames.indexOf(item.ui) == -1 && !bBePlaceholder);
      });
    },
    getChildrenCells: function(oConf) {
      const aSkipObjNames = [ 'swatbusinessentity', 'swatribbon', 'ribbon', 'swattoolbar', 'toolbar' ];
      const iLength = _.filter(oConf, item => {
        const bBePlaceholder = (item.ui == 'placeholder' && item.config.$orgobjtype.toLowerCase() == 'swatbusinessentity');
        return (aSkipObjNames.indexOf(item.ui) == -1 && !bBePlaceholder);
      }).length;

      return iLength;
    },

    // get the root objtype, usually layout
    getRootObjType: function(cObjType) {
      switch (cObjType) {
        case 'swatform':
          cObjType = 'form';
          break;
        default:
          cObjType = 'layout';
      }

      return cObjType;
    },

    // returns object type based on repository names// use for children not for root
    getObjType: function(cObjType) {
      cObjType = cObjType.toLowerCase();
      switch (cObjType) {
        case 'swatbusinessentity':
          cObjType = 'swatbusinessentity';
          break;
        case 'swatribbon':
          cObjType = 'ribbon';
          break;
        case 'swattabbar':
          cObjType = 'tabbar';
          break;
        case 'swatform':
        case 'swatfilterform':
        case 'swatattributeform':
          cObjType = 'form';
          break;
        case 'swattreegrid':
          cObjType = 'treegrid';
          break;
        case 'swatakdataview':
          cObjType = 'dataview';
          break;
        case 'swatitimage':
          cObjType = 'image';
          break;
        case 'swatittext':
          cObjType = 'text';
          break;
        case 'swatittoolbar':
          cObjType = 'toolbar';
          break;
      }

      return cObjType;
    },

    /**
     * Attaches graph editor events to sync between graph editor and designer
     */
    attachGraphEditorEvents: function() {
      const service = this.dhx.getService('UIState').bus;

      service.attachEvent('store:onDataLoad', this.refreshGraphEditor.bind(this));
      service.attachEvent('store:onDataChange', this.refreshGraphEditor.bind(this));
      service.attachEvent('store:onDataRefresh', this.refreshGraphEditor.bind(this));
      service.attachEvent('store:onUndo', this.refreshGraphEditor.bind(this));
      service.attachEvent('store:onCursor', this.setGraphEditorCursor.bind(this));
    },

    /**
     * Refreshes the graph editor
     */
    refreshGraphEditor: function() {
      const graphEditor = this.getGraphEditor();
      if (!graphEditor) return;

      // the refresh graph editor can be called multiple
      if (this._refreshGraphEditorTimeout) {
        clearTimeout(this._refreshGraphEditorTimeout);
        delete this._refreshGraphEditorTimeout;
      }

      this._refreshGraphEditorTimeout = setTimeout(() => {
        const masters = this.store.getStore('eSmartObjectMaster').data.pull;
        const instances = this.store.getStore('eSmartObjectInstance').data.pull;
        const pages = this.store.getStore('eSmartPage').data.pull;

        graphEditor.setState({
          master: Object.keys(masters).map(key => masters[key])[0],
          instances: Object.keys(instances).map(key => instances[key]),
          pages: Object.keys(pages).map(key => pages[key]),
          layout: this.aTempLayoutOptions
        });

        delete this._refreshGraphEditorTimeout;
      }, 50);
    },

    /**
     * Sets the graph editor cursor
     */
    setGraphEditorCursor: function(id, old, data) {
      if (!data)
        return;

      const graphEditor = this.getGraphEditor();
      if (!graphEditor)
        return;

      if (data.$objtype === 'page') {
        graphEditor.setState({
          selectedGuid: data.guid,
          pageGuid: data.guid
        });
      } else if (data.pageguid) {
        graphEditor.setState({
          selectedGuid: data.guid,
          pageGuid: data.pageguid
        });
      } else if (data.$parent) {
        const store = this.dhx.getService('UIState').pull;
        const parent = store[data.$parent];

        if (parent && parent.$objtype === 'page') {
          graphEditor.setState({
            selectedGuid: data.guid,
            pageGuid: parent.guid
          });
        } else
          graphEditor.setState({ selectedGuid: data.guid });
      } else
        graphEditor.setState({ selectedGuid: data.guid });
    },

    /**
     * Gets the graph editor control
     */
    getGraphEditor: function() {
      return (this.graphEditor || (this.graphEditor = this.getAncestor('window').getDescendant('graphEditor')));
    },

    // destroy
    destroy: function() {
      // this.dhx.unload();
      delete this.graphEditor;
    }

  };
})(jQuery, jQuery);
