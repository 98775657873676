// ************************* ribbonselect ********************************
$.extend({
  ak_ribbonselect: function(options) {
    const defaults = {
      id: '',
      title: '',
      disabled: false,
      image: '',
      image2: ''
    };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    this.registerDynObject = true;
    this.useParentDynObjectLink = true;

    // get new element
    const cName = this.opt.name;
    let cImage = this.opt.image;

    const iPos = this.parent.opt.pos + 1;
    this.parent.opt.pos = iPos;

    cImage = (cImage == '') ? null : cImage;

    this.getAncestor('ribbon').dhx._items[this.opt.name].conf.iconset = 'awesome';

    this.parent.prop.fields.push({
      'width': 200,
      'id': cName,
      'mode': this.opt.mode,
      'text': '',
      'img': cImage,
      'disable': false,
      'isbig': false,
      'type': 'buttonSelect'
    });
  }
});


// methods for ribbon select
$.ak_ribbonselect.prototype = {

  // finalize construct *************
  finishConstruct: function() {

    // check visible attribute for select ribbon
    try {
      if (this.opt.hidden)
        this.getAncestor('ribbon').dhx.hide(this.opt.id);
    } catch (e) {
      akioma.log.error(`could not hide ribbon ${this.opt.type}`, e);
    }

    try {
      if (this.opt.disabled)
        this.getAncestor('ribbon').dhx.disable(this.opt.id);
    } catch (e) {
      akioma.log.error(`could not disable ribbon ${this.opt.type}`, e);
    }

    this.parent.parent.aItems.push(this.opt.id);

  }
};
