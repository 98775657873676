
(function($) {

  // ******************* data grid ************************
  $.extend({
    /**
     * SwatProperty Control
     * @class ak_propertyGrid
     * @augments ak_global
     * @param {Object} options Repository attributes for SwatGrid.
     * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     * @param {string} options.contextMenu the id of a menuStructure which will be used as a context-menu
     * @param {string} options.titleHeader specifies which panelHeader to use. when empty, then uses the header of its own panel. if "none" then no header at all. if "parent" it uses the header of the parent panel
     * @param {string} options.floatingActionButton the id of a menustructure, which will be rendered as a FAB
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {string} options.panelMenu comma separated list of menu-structures which will be shown as panelHeader-Buttons. </br>
     * Can also contain the flag #NoDropDown that specifies that the menu should not be loaded as a dropdown but each menu item should be added in the panel-Header. </br>
     * For example: </br>
     * <code>menuStructSave,menuSettings#NoDropDown,menuLookup</code>
     * @param {string} options.zappingContainer container used for displaying in browsing/zapping
     * @param {string} options.SUBTYPE
     * @param {string} options.EntityName The Name of the Business Entity used by the component
     * @param {number} options.maxColumnCharacters limits the maximum number of displayed characters for grid-columns. Usefull if there are columns that could contain large strings, which would cause the rows to be too high in multiline-mode
     * @param {string} options.updateRecordContainer name of repository object to be called for updating records
     * @param {string} options.showGridFilter controls which filter-controls are shown for a grid.&#10;all,none,column-only
     * @param {boolean} options.batchingMode if true, then the grid will use batching mode
     * @param {boolean} options.ENABLED WidgetAttributes Enabled
     * @param {string} options.addRecordContainer container for adding new records
     * @param {string} options.copyRecordContainer optional name of repository object to use when copying a record. If not specified, updateContainer will be used
     * @param {string} options.EventRowChosen client side code executed when a row is chosen (selected by the user to perform an action on it), e.g. by double click
     * @param {boolean} options.filterOnLeave if true, then filter will automatically applied when leaving a filter-field
     * @param {string} options.EventBeforeFetch code to be executed before fetching data from the backend
     * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
     * @param {string} options.EventRowSelected client side code executed when a row is selected (becomes current row in a set of rows), e.g. by single click
     * @param {string} options.resourceName
     * @param {string} options.FolderWindowToLaunch If Dynamics is running, this property specifies a window to launch upon the occurence of toolbar events "View", "Copy", "Modify" or "Add".
     * @param {string} options.RecordCreate Axilon: Neuanlage-Dialog für komplexe Daten mit mehreren eindeutigen Schlüsselfeldern
     * @param {string} options.ToolBarKey Key of the used toolbar
     * @param {boolean} options.keepSelection Auto select previously selected grid row if the row is found, searched in the ak_businessEntity, by the identifier attribute. Default is true.
     * @fires ak_propertygrid#EventBeforeFetch
     * @fires ak_propertygrid#EventRowSelected
     * @fires ak_propertygrid#EventRowChosen
     * @fires ak_propertygrid#EventOnInitialize
     * @fires ak_propertygrid#EventOnContextMenuOpen
     */
    ak_propertygrid: function(options) {
      akioma.BaseDataGrid.call(this, options);
      const oSelf = this,
        defaults = { rowHeight: 23 };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;
      this.options = options;

      this.columns = [];

      this.aFtrHdls = [];
      this.aSortPhrases = [];
      this.aPromises = [];

      this.oKeepSelectionData = null;
      this.updatedProperties = [];
      this.cellEdited = {};
      this._events = {};

      this.parent.setOption('title', oSelf.opt.BorderTitle || 'Attributes');

      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      // initialize tab
      this.formViews = [ 'form', 'fieldset', 'block' ];
      if ($.inArray(this.parent.view, this.formViews) !== -1) {
        const formGrid = { type: 'container', name: `formGrid_${this.opt.name}`, inputWidth: 800, inputHeight: 150, position: 'label-top' };
        this.parent.prop.fields.push(formGrid);
      } else {
        const oParent = this.parent.dhx;
        if (oParent)
          this.attachGridToParent(oParent);
        else
          !_isIE && console.error(`No valid parent for datagrid ${this.opt.name}`);
      }
    }
  });

  // methods for data grid
  Object.assign($.ak_propertygrid.prototype, akioma.BaseDataGrid.prototype, {

    // finish construct *****************
    finishConstruct: function() {
      if ($.inArray(this.parent.view, this.formViews) !== -1) {
        const formParent = akioma.getForm(this);
        const oParent = formParent.dhx.getContainer(`formGrid_${this.opt.name}`);

        this.calculateWidth_inForm(this, oParent);

        this.attachGridToParent(oParent);

        if (this.opt.useBusinessEntity) {
          try {
            let cResourceName = '';
            if (this.opt.resourceName)
              cResourceName = this.opt.resourceName;
            else
              cResourceName = (this.opt.typeRange == 'pos.ofr.' ? 'Akioma.Crm.Offer.OfferStructEntity' : 'Akioma.Crm.Product.ProductStructEntity');

            const oBEoptions = {
              cacheLimit: 50,
              catalogURI: '',
              dataSource: '',
              entityName: (this.opt.entityName || 'eStruct'),
              extKey: 'selfhdl',
              foreignKey: 'ownerhdl',
              id: 'offerw45613645_businessEntity',
              identifier: 'selfhdl',
              name: 'businessEntity',
              rowsToBatch: 1000,
              resourceName: cResourceName,
              serviceURI: '',
              /**
               * Code executed on the client-side before a data fetch request.
               * @event ak_datagrid#EventBeforeFetch
               * @type {object}
               */
              onBeforeFetch: (this.opt.onBeforeFetch) ? this.opt.onBeforeFetch : '',
              maxColumnCharacters: (this.opt.maxColumnCharacters ? this.opt.maxColumnCharacters : '')
            };

            const oNew = app.controller.parseProc({
              view: 'businessEntity',
              att: oBEoptions,
              sub: []
            }, this);

            // crete DATA:LINK between grid and businessEntity, for grid2 in form
            if ($.inArray(this.parent.view, this.formViews) !== -1) {
              this.dataSource = oNew;
              const cUniqueLinkName = this.opt.entityName + dhtmlx.uid();
              this.dynObjName = cUniqueLinkName;
              this.dataSource.dynObjName = cUniqueLinkName;
              if (this.opt.entityName) {
                app.messenger.subscribe(this.dataSource.dynObject, `DATA:SRC:${cUniqueLinkName}`);
                app.messenger.subscribe(this.dynObject, `DATA:TRG:${cUniqueLinkName}`);
              }
            }
          } catch (oErr) {
            akioma.log.error('Error initializing BE', oErr);
          }
        }
      }

      const oSelf = this,
        oGrid = this.dhx,
        gridDiv = oGrid.entBox;

      oSelf.FilterManager = new akioma.FilterManager(oSelf);
      oSelf.bBound = false;

      oGrid.init();
      oSelf.propStore = new dhtmlXDataStore();
      oSelf.dc = new dataConnector();
      oSelf.dc.init(oSelf.propStore);
      oGrid.sync(oSelf.propStore);

      if (this.parent.view == 'panel') {
        // set title and panelMenu buttons in panel header
        akioma.setPanelHeader(oSelf);
      }

      if (oSelf.akId)
        $(gridDiv).attr('akId', oSelf.akId);


      // set akstyle in grid
      $(gridDiv).attr('akstyle', oSelf.opt.customStyle);

      // batching records in grid
      if (this.opt.batchingMode) {
        this.dataSource.batchMode = true;
        oGrid.akSmartBatch = true;
        oGrid.aAkBatchedRows = [];
        oSelf.dataSource.stop = true; // stop open query temporary

        const queryJSDO = function() {
          const promiseCount = oSelf.dataSource.jsdo.count({ plcParameter: { akQuery: oSelf.dataSource.urlQuery.akQuery } }, true).deferred;
          promiseCount.then(({ request }) => {
            const iNoRec = request.response.piNumRecs;
            oSelf.dataSource.noOfRecords = iNoRec;
            oSelf.dataSource.stop = false;
            oSelf.dataSource.setBatch(0, 50);
            oSelf.dataSource.openQuery({});
          });
        };

        if (oSelf.dataSource.jsdo == undefined)
          oSelf.dataSource.addAfterCatalogAdd(queryJSDO);
        else
          queryJSDO();

      }

      oGrid.setEditable(true);

      // set multiSelect mode
      if (oSelf.opt.multiSelect)
        oGrid.enableMultiselect(oSelf.opt.multiSelect);
      else
        oGrid.enableMultiselect(false);


      // add margin to grid table
      if (oSelf.opt.floatingActionButton)
        $($(gridDiv).find('table')[1]).css('margin-bottom', '50px');


      if (oSelf.opt.contextMenu !== undefined && oSelf.opt.contextMenu !== '') {
        const cPointer = `${oSelf.akId}-${oSelf.opt.contextMenu}${dhtmlx.uid()}`;
        this.contextMenuObject = new akioma.GridContextMenu(oSelf, cPointer);
      }

      // get column settings and set multiline
      const oColSettings = UserProfile.loadGridLocalProfileData(oSelf);
      if (oColSettings && oColSettings.multiline != undefined)
        oSelf.bMultiline = oColSettings.multiline;

      oGrid.enableMultiline(oSelf.bMultiline);

      if (oSelf.aPromises.length > 0) {
        if (oSelf.parent)
          oSelf.parent.dhx.progressOn();

        const promises = [];
        for (let i = 0; i < oSelf.aPromises.length; i++)
          promises.push(oSelf.aPromises[i]);

        Promise.all(promises).then(() => {
          oSelf.syncSource();
          if (oSelf.parent)
            oSelf.parent.dhx.progressOff();
        });
      }

      if (oSelf.bFirstGridLoad) {
        // load grid column settings
        if (oColSettings)
          akioma.applyGridColumnSettings(oSelf, oColSettings);
        oSelf.bFirstGridLoad = false;
      }

      // init multiselect and dynSelect filters
      oSelf.aMultiSelectStatus = {
        aSelectedValues: [],
        aSelectedValuesKeys: [],
        aSelectedComboResult: []
      };
      oSelf.aDynSelectStatus = {
        aSelectedItems: [],
        aSelectedValues: []
      };

      const aSelectedValues = oSelf.aMultiSelectStatus.aSelectedValues; // values of multiselect filter
      const aSelectedValuesKeys = oSelf.aMultiSelectStatus.aSelectedValuesKeys; // keys of multiselect filter
      const aSelectedComboResult = oSelf.aMultiSelectStatus.aSelectedComboResult;

      for (const i in oSelf.aMultiSelectFilters) {
        const cCurMultiFilterColName = oSelf.aMultiSelectFilters[i].colname;
        aSelectedValues[cCurMultiFilterColName] = [];
        aSelectedValuesKeys[cCurMultiFilterColName] = [];
        aSelectedComboResult[cCurMultiFilterColName] = []; // will be used for value display in combo multifilter

        const oMultiFilterElm = oGrid.getFilterElement(oSelf.aMultiSelectFilters[i].index);
        oSelf.aMultiSelectFilters[i].combo = oMultiFilterElm;
        oMultiFilterElm.stopSelectionOfOptions = true;
        $(oMultiFilterElm.DOMelem_input).attr('readonly', 'readonly');

        const aTextInputs = [];
        oMultiFilterElm.attachEvent('onCheck', (value, state) => {
          const cMultiFilterElmVal = oMultiFilterElm.getOption(value).text_input;
          aTextInputs.push(oMultiFilterElm.getOption(value).text_input);
          if (state) {

            aSelectedComboResult[cCurMultiFilterColName].push(cMultiFilterElmVal.split(' / ')[1]);
            aSelectedValuesKeys[cCurMultiFilterColName].push(cMultiFilterElmVal);
            aSelectedValues[cCurMultiFilterColName].push(value);
          } else {
            aSelectedValuesKeys[cCurMultiFilterColName].splice(aSelectedValuesKeys[cCurMultiFilterColName].indexOf(cMultiFilterElmVal), 1);
            aSelectedValues[cCurMultiFilterColName].splice(aSelectedValues[cCurMultiFilterColName].indexOf(value), 1);
            aSelectedComboResult[cCurMultiFilterColName].splice(aSelectedValuesKeys[cCurMultiFilterColName].indexOf(cMultiFilterElmVal.split(' / ')[1]), 1);
          }
          oMultiFilterElm.cont.value = aSelectedValues[cCurMultiFilterColName].join('|');

          $(oMultiFilterElm.DOMelem_input).attr('placeholder', aSelectedComboResult[cCurMultiFilterColName].join(', '));
        });
      }

      // focus in posFrame form, first enabled field
      (function(oSelf) {
        oSelf.filterPrevValues = [];
        $(oSelf.dhx.hdr).find('.hdrcell.filter input').on('focusin', e => {
          try {
            oSelf.dhx.setActive(true);
            if (oSelf.opt.filterOnLeave) {
              const bActiveWindow = oSelf.dynObject.container.controller.dhx.isOnTop();
              if (bActiveWindow) {
                // check if input from grid header is in focus
                if ($.contains(oSelf.dhx.hdr, e.currentTarget)) {
                  const filterAkId = $(e.currentTarget).closest('td').attr('akId'); // akId is a unique identifier for filter input
                  oSelf.filterPrevValues[filterAkId] = $(e.currentTarget).val();
                }
              }
            }
          } catch (e) {
            akioma.log.error('Error saving posframe form values', e);
          }
        });

        $(oSelf.dhx.hdr).find('.hdrcell.filter input').on('focusout', e => {
          try {
            oSelf.dhx.setActive(false);
            if (oSelf.opt.filterOnLeave) {
              const bActiveWindow = oSelf.dynObject.container.controller.dhx.isOnTop();
              if (bActiveWindow) {
                // check if input from grid header is in focus
                if ($.contains(oSelf.dhx.hdr, e.currentTarget)) {
                  const filterAkId = $(e.currentTarget).closest('td').attr('akId'); // akId is a unique identifier for filter input
                  if (oSelf.filterPrevValues[filterAkId] != $(e.currentTarget).val())
                    oSelf.FilterGo();
                }
              }
            }
          } catch (e) {
            akioma.log.error('Error saving posframe form values', e);
          }
        });
      })(oSelf);

      // add akId to Grid's columns filter and set cursor for sortable columns
      const headerRows = $(oGrid.entBox).find('.xhdr').find('tr');
      const labels = $(headerRows[1]).find('td'); // get labels from header
      const filterInput = $(headerRows[2]).find('td'); // get all filter inputs

      for (let i = 0; i < filterInput.length; i++) {
        const columnLabel = oSelf.aAkId[i];
        $(filterInput[i]).attr('akId', `${oSelf.akId}-${columnLabel}`);
      }

      for (let i = 0; i < labels.length; i++) {
        const sortType = oSelf.aColSort[i];

        if (sortType == 'server')
          $(labels[i]).children().attr('cursor', 'server');
        else
          $(labels[i]).children().attr('cursor', 'na');
      }

      const $GridHdrTr = $(oGrid.entBox).find('.xhdr table tr:last-of-type');
      oSelf.gridHdrFilterDOM = $GridHdrTr;
      oSelf.bGridFilterOpened = false;
      oSelf.bHdrVisible = false;

      const $hdr = $(oSelf.dhx.entBox).find('.xhdr');

      // show hide easyquery filter icon// extendedColumnFilters
      let bShowExtendedColumnFilters = false;

      bShowExtendedColumnFilters = (oSelf.opt.extendedColumnFilters || app.sessionData.extendedColumnFilters || bShowExtendedColumnFilters);

      if (oSelf.opt.showGridFilter != 'none' && oSelf.opt.showGridFilter != 'column-only') {

        oSelf.cSearchInputID = `grid-eq-header-panels${dhtmlx.uid()}`;

        let cExtra = '';
        if (bShowExtendedColumnFilters)
          cExtra = '<i class="fa fa-filter filterforgridcolhdr"></i>';

        $(`<div id="${oSelf.cSearchInputID}" aktype="grid-eq-header-panels" style="height:40px;width: 100%;float:left;clear:both;"></div>${cExtra}`).insertBefore($hdr);

        const oLayout = new dhtmlXLayoutObject({
          parent: oSelf.cSearchInputID,
          pattern: '1C'
        });

        oLayout.cells('a').hideHeader();
        oSelf.buildGridSearchInput(oLayout.cells('a'));

        oLayout.cells('a').setWidth($(`#${oSelf.cSearchInputID}`).width());
      } else if (oSelf.opt.showGridFilter != 'none' && bShowExtendedColumnFilters) // attach only filter icon, no input search
        $('<i class="fa fa-filter filterforgridcolhdr"></i>').insertBefore($hdr);

      oGrid.attachEvent('onSyncApply', () => {
        if (oSelf.oSortState) {
          const sortstate = oSelf.oSortState;
          oGrid.setSortImgState(sortstate.state, sortstate.iCol, sortstate.order);
        } else
          oGrid.setSortImgState(false);

      });

      if ($.inArray(oSelf.parent.view, oSelf.formViews) !== -1) {
        oSelf.dataSource.bAfterAddCatalog.done(() => {
          oSelf.getFilterList();
          let height;
          const hdrPanel = $(oSelf.dhx.entBox).find('div[aktype="grid-eq-header-panels"]');

          if (oSelf.opt.rows)
            height = oSelf.opt.rows * oSelf.opt.rowHeight + $(oSelf.dhx.hdrBox).height();
          else
            height = 150 + $(oSelf.dhx.hdrBox).height();

          if (hdrPanel.length > 0)
            height += $(hdrPanel).height();
          $(oSelf.dhx.entBox).height(height);
        });
      }

      const query = EQ.client.getQuery();
      oSelf.prevSavedQuery = []; // used for columns filters state control
      oSelf.aGridColumnOperators = [];

      // .xhdr tr:nth-child(3) td > div,
      $(oSelf.dhx.entBox).find('.xhdr tr:nth-child(4) td').css('pointer-events', 'none');

      const $headerLabels = $(oSelf.dhx.entBox).find('.xhdr tr:nth-child(3) td');
      const $headerEQFilterInfo = $(oSelf.dhx.entBox).find('.xhdr tr:nth-child(4)');
      const $paramCondBtn = $('.eqjs-qp-condition-button-parameterization').parent();
      const $addPredicateCondBtn = $('.eqjs-qp-condition-button-addPredicate').parent();
      const $addConditionCondBtn = $('.eqjs-qp-condition-button-addCondition').parent();
      const $filterGridIcon = $(oSelf.dhx.entBox).find('.filterforgridcolhdr');
      let lastHoveredCol = null;

      // this sends changes made to backend query, from each column column
      // also builds information in grid header using a Handlebars template
      oSelf.updateBusinessEntityFilter = function() {
        oSelf.dataSource.query.clearAll();
        const oEQuery = EQ.client.getQuery().query.root;
        const source = '{{#each filters}}' +
                        '{{#if this.enabled}}' +
                          '<div class="filters-grid-hdr">' +
                        '{{else}}' +
                          '<div class="filters-grid-hdr disabled">' +
                        '{{/if}}' +
                        '{{#ifSecondOrMore @index}}' +
                          '<span>{{this.linktype}} </span>' +
                        '{{/ifSecondOrMore}}' +
                        '<span>{{this.operator}}</span>' +
                        '{{#if this.dateFormat}}' +
                            '<span> {{this.dateFormat}}</span>' +
                        '{{else}}' +
                          '<span> {{this.value}}</span>' +
                        '{{/if}}' +
                        '</div>' +
                        '{{else}}' +
                          ' <span class="empty">None</span>' +
                      '{{/each}}';


        let bAtLeastOneColHasMoreThanTwo = false;
        for (const i in oSelf.aGridFilterFields) {
          const aColumnSpecificFilters = oSelf.aGridFilterFields[i];

          if (aColumnSpecificFilters.length == 0)
            $(oGrid.entBox).find(`.${i}-cell`).empty();

          if (aColumnSpecificFilters.length >= 2)
            bAtLeastOneColHasMoreThanTwo = true;

          for (let j = 0; j < aColumnSpecificFilters.length; j++) {
            const oColumnFilter = aColumnSpecificFilters[j];
            const cColID = oColumnFilter.attr;
            const cOperator = oColumnFilter.operator;
            const cColVal = oColumnFilter.value;
            const bEnabled = oColumnFilter.enabled;

            let cLinkType;
            if (oEQuery.linkType)
              cLinkType = oEQuery.linkType.toLowerCase();

            if (oColumnFilter.value.length > 0 && bEnabled) {

              // var cSubOperator = cOperator;
              if (cLinkType) {

                if (cLinkType == 'any')
                  cLinkType = 'or';
                else
                  cLinkType = 'and';
              }
              if (cLinkType && oSelf.cColumnIdCurrentlyFiltered == cColID.substr(cColID.indexOf('.') + 1, cColID.length))
                oSelf.dataSource.query.setSubOperator(cColID.substr(cColID.indexOf('.') + 1, cColID.length), oSelf.aGridColumnOperators[oSelf.cColumnIdCurrentlyFiltered]);

              oSelf.dataSource.query.addCondition(cColID.substr(cColID.indexOf('.') + 1, cColID.length), cOperator, cColVal);
            }
            const template = Handlebars.compile(source);

            const oData = { filters: aColumnSpecificFilters };
            template(oData);
          }
        }

        // check if we should show column filter info
        if (bAtLeastOneColHasMoreThanTwo)
          $headerEQFilterInfo.show();
        else
          $headerEQFilterInfo.hide();


        oSelf._syncFilters();

        // check if changes have been made to query and only trigger the query changes then
        const condClone = $.extend(true, {}, oEQuery);
        for (const i in condClone.conditions)
          delete condClone.conditions[i].blockId;

        if (oSelf.prevSavedQuery[oSelf.cColumnIdCurrentlyFiltered] !== JSON.stringify(condClone)) {
          if (oSelf.popupform) {
            const cFullTextSearch = oSelf.popupform.getItemValue('searchInput');
            if (cFullTextSearch.length > 0)
              oSelf.dataSource.query.setFullTextSearch(cFullTextSearch);
          }
          oSelf._renderMultiSelectStateAfterFill(); // show multiselect checkboxes
          oSelf.dataSource.openQuery();
        }

        oSelf.prevSavedQuery[oSelf.cColumnIdCurrentlyFiltered] = JSON.stringify(condClone);
      };

      $headerLabels.find('.filterforgridcolhdr').on('click', function() {
        $(this).closest('td').trigger('dblclick');
      });

      $headerEQFilterInfo.hide();
      $filterGridIcon.on('click', () => {
        let cColumnId = oSelf.dhx.getColumnId(lastHoveredCol);
        const iColIndex = oSelf._initColIds.indexOf(cColumnId);
        const oFilterElm = oGrid.getFilterElement(iColIndex);

        let $td;
        if (oFilterElm.tagName == undefined)
          $td = $(oFilterElm.cont).closest('td');
        else
          $td = $(oFilterElm).closest('td');

        let cColFilterValue = oSelf.dhx.getFilterElement(iColIndex).value;
        let cExistingFilterOp = null;

        // check type of existing input filter
        if (cColFilterValue && cColFilterValue.length > 0) {
          const count = (cColFilterValue.match(/\*/g) || []).length;

          if (count == 1 && (cColFilterValue.indexOf('*') == cColFilterValue.length - 1))
            cExistingFilterOp = 'startswith';
          else if (count == 1 && (cColFilterValue.indexOf('*') == 0))
            cExistingFilterOp = 'endswith';
          else if (count == 2)
            cExistingFilterOp = 'contains';
          else if (count == 0)
            cExistingFilterOp = 'eq';
        }

        // stop filter if nonFilterable Column type
        if (oSelf.dataSource && oSelf.dataSource.aNonFilterableFields.indexOf(cColumnId) != -1)
          return false;

        oSelf.cColumnIdCurrentlyFiltered = cColumnId;

        // if grid EQ is not opened yet and use column filters
        if (!oSelf.bGridFilterOpened && oSelf.opt.useColumnFilterScreens) {
          akioma.gridFilterFields(oSelf, $td, 'popup');
          oSelf.bGridFilterOpened = true;
        } else if (akioma.gridFilterFieldsPopup) {
          const elm = $td;
          const $table = elm.closest('table');
          akioma.gridFilterFieldsPopup.p.style.top = `${$table.offset().top + elm.parent().parent().height()}px`;
          akioma.gridFilterFieldsPopup.p.style.left = `${elm.offset().left - 20}px`;
          akioma.gridFilterFieldsPopup.p.style.display = 'block';
          $(akioma.gridFilterFieldsPopup.p).find('.dhx_popup_arrow').find('.dhx_popup_arrow').css({ 'margin-left': '-39%' });
        }

        // if a EQ filter is already initialized
        if (oSelf.bGridFilterOpened) {
          cColumnId = oSelf.dhx.getColumnId($td.index());
          if (oSelf.aGridFilterFields[cColumnId] == undefined) {
            oSelf.aGridFilterFields[cColumnId] = [];

            EQ.client.defaultQuery.query.root.linkType = 'Any';
            EQ.client.refreshWidgets(true);

            // adds a new column filter if none has been defined already
            const id = `${oSelf.queryWindow.EntityName}.${cColumnId}`;
            const cDefaultOperator = akioma.getDefaultEQOperator(id);
            const cColumnDataType = oSelf.getEQDataType(`${oSelf.queryWindow.EntityName}.${cColumnId}`);

            if (cColumnDataType == 'Date' || cColumnDataType == 'Time') {
              const oFirstDefCol = { attr: `${oSelf.queryWindow.EntityName}.${cColumnId}`, operator: 'gte', value: new String(), enabled: true },
                oSecondDefCol = { attr: `${oSelf.queryWindow.EntityName}.${cColumnId}`, operator: 'lte', value: new String(), enabled: true };

              oSelf.aGridFilterFields[cColumnId].push(oFirstDefCol);
              oSelf.aGridFilterFields[cColumnId].push(oSecondDefCol);
            } else {
              let oColumnField;
              if (cExistingFilterOp == null)
                oColumnField = { attr: `${oSelf.queryWindow.EntityName}.${cColumnId}`, operator: cDefaultOperator, value: new String(), enabled: true };
              else {
                if (cExistingFilterOp != 'matches')
                  cColFilterValue = cColFilterValue.replace(/\*/g, '');

                oColumnField = { attr: `${oSelf.queryWindow.EntityName}.${cColumnId}`, operator: cExistingFilterOp, value: cColFilterValue, enabled: true };
              }
              oSelf.aGridFilterFields[cColumnId].push(oColumnField);
            }

          }
          // now clear EQ and add all the filters for the current column // this are saved on every value update in callbackOnValueUpdate
          // also reset linkType because clear will set default as 'Any'
          oSelf.bSkipValueUpdates = true;
          const cPrevEQLinkType = EQ.client.defaultQuery.query.root.linkType;
          query.clear();
          EQ.client.defaultQuery.query.root.linkType = cPrevEQLinkType;

          oSelf.bSkipValueUpdates = false;
          // if we have filters for the current column id add them to EQ filter
          if (oSelf.aGridFilterFields[cColumnId].length > 0) {
            oSelf.bSkipValueUpdates = true;
            for (let i = 0; i < oSelf.aGridFilterFields[cColumnId].length; i++) {
              const oColumnField = oSelf.aGridFilterFields[cColumnId][i];
              query.addSimpleCondition(oColumnField);
            }
            oSelf.bSkipValueUpdates = false;

            // now hide all conditional extra buttons
            $paramCondBtn.hide();
            $addPredicateCondBtn.hide();
            $addConditionCondBtn.hide();
          }

          const cCurrentSelectedColOperator = oSelf.aGridColumnOperators[oSelf.cColumnIdCurrentlyFiltered];
          const oEQuery = EQ.client.getQuery().query.root;
          if (cCurrentSelectedColOperator) {
            if (cCurrentSelectedColOperator == 'and')
              oEQuery.linkType = 'All';
            else
              oEQuery.linkType = 'Any';
            EQ.client.refreshWidgets();

          }
        }
      }).on('mouseenter', function() {
        $(this).show();
      });

      $headerLabels
        .hover(function() {
          const iLeft = $(this).position().left + $(this).width();

          lastHoveredCol = $(this).index();
          $filterGridIcon.css({
            top: ((oSelf.opt.showGridFilter != 'none' && oSelf.opt.showGridFilter != 'column-only') ? '65px' : '20px'),
            zIndex: 9999,
            left: `${iLeft - 10}px`,
            display: 'block'
          });

        }, () => {
          $filterGridIcon.css({ display: 'none' });
        });

      // on header filter focus bring parent window on top
      $(oSelf.dhx.hdr).find('input').focus(() => {
        try {
          oSelf.dynObject.container.controller.dhx.bringToTop();
        } catch (e) {
          akioma.log.error(e);
        }
      });

      this.oHdrBoxMouseTrap = Mousetrap(oSelf.dhx.hdrBox);

      this.oHdrBoxMouseTrap.bind('f2', () => {
        oSelf.FilterClear();
      });
      this.oHdrBoxMouseTrap.bind('shift+f2', () => {
        oSelf.FilterClear();
        oSelf.FilterGo();
      });

      // init event
      if (this.opt.EventOnInitialize) {
        /**
         * Client side code to run when Container has been initialized
         * @event ak_datagrid#EventOnInitialize
         * @type {object}
         */
        app.controller.callAkiomaCode(this, this.opt.EventOnInitialize);
      }
    },


    syncSource: function() {
      const oSelf = this;
      const oSource = oSelf.dataSource.dhx;
      const oGrid = oSelf.dhx;
      oGrid.init();
      oGrid.BE = oSource;
      oGrid.sync(oSource);

    },

    attachGridToParent: function(parent) {
      const oSelf = this;
      const oParent = parent;

      // set akId
      this.akId = ((this.opt.akId) ? this.opt.akId : this.opt.name);
      // set title in panel
      this.opt.title = akioma.tran(`${this.opt.name}._title`, { defaultValue: this.opt.title });

      // set floatingActionButton in panel
      if (this.opt.floatingActionButton) {
        const oFloatingActionTarget = this;
        this.parent.setOption('floatingActionButton', this.opt.floatingActionButton, oFloatingActionTarget);
      }

      let oGrid;
      if ($.inArray(this.parent.view, this.formViews) !== -1)
        oGrid = new dhtmlXGridObject(parent);
      else {
        // -> bind grid to layout
        if (oParent.getAttachedObject())
          oParent.showView('alternative');
        oGrid = oParent.attachGrid();
      }
      if (this.opt.batchingMode)
        oGrid.enableSmartRendering(true);


      oGrid.setImagePath(oDhx.imagePath);
      oGrid.setSkin(oDhx.skin);

      oGrid.i18n.decimal_separator = ',';
      oGrid.i18n.group_separator = '.';
      oGrid.comboValues = {};
      oGrid.calFormats = {};

      oGrid.i18n.paging = {
        results: 'Datensätze',
        records: 'Datensätze von ',
        to: ' bis ',
        page: 'Seite ',
        perpage: 'Sätze pro Seite',
        first: 'Zur ersten Seite',
        previous: 'vorherige Seite',
        found: 'Datensätze gefunden',
        next: 'Nächste Seite',
        last: 'Zur letzten Seite',
        of: ' von ',
        notfound: 'Keine Datensätze gefunden'
      };

      // get column definitions
      const aCells = [],
        aHead = [],
        aHeadAlign = [],
        aFilter = [],
        aEQFilter = [],
        aWidth = [],
        aAlign = [],
        aDataTypes = [],
        aColTypes = [],
        aColUISort = [],
        aColSort = [],
        aFolder = [],
        aExtendedFormat = [],
        aAkId = [];
      oSelf.aMultiSelectFilters = [];
      oSelf.aDynSelectFilters = [];
      oSelf.aDateRangeFilters = [];

      let oCol;
      for (const i in this.options.sub) {
        oCol = this.options.sub[i];
        if (oCol.view == 'propertygridcol' || oCol.view == 'datagridcol') {

          oCol.att.label = akioma.tran(`${oCol.att.gridName}.${oCol.att.dataField}`, { defaultValue: oCol.att.label });

          if (oCol.att.colType == 'ch') {
            const aLogicalTypes = [ 'empty', 'true', 'false', 'null', 'filterTrue', 'filterFalse' ];
            const extendedFormat = {
              'empty': { icon: 'fal fa-square' },
              'true': { icon: 'fal fa-check-square' },
              'false': { icon: 'fal fa-square' },
              'null': { icon: 'fal fa-question-square' },
              'filterTrue': { icon: 'fal fa-check-square' },
              'filterFalse': { icon: 'fal fa-times-square' }
            };

            if (oCol.att.extendedFormat) {
              const customIcons = oCol.att.extendedFormat.split('|');
              for (let iCount = 0; iCount < aLogicalTypes.length; iCount++) {
                if (customIcons[iCount]) {
                  const iconObject = akioma.addIcon(customIcons[iCount]);
                  extendedFormat[aLogicalTypes[iCount]].icon = (iconObject.icon) ? iconObject.icon : extendedFormat[aLogicalTypes[iCount]].icon;
                  extendedFormat[aLogicalTypes[iCount]].css = iconObject.attributes;
                }
              }
            }
            aExtendedFormat[i] = extendedFormat;
          }

          if (oCol.att.colType == 'ron' || oCol.att.colType == 'edn') {

            this.numberFormat = this.opt.numberFormat || app.sessionData.globalNumericFormat || akioma.globalData.globalNumericFormat;
            if (oCol.att.isCurrency)
              this.numberFormat = `€ ${this.numberFormat}`;

            switch (oCol.att.dataType.toLowerCase()) {
              case 'integer': {
                const groupSep = app.sessionData.globalNumericGroupSep || akioma.globalData.globalNumericFormat.groupSep;
                this.numberFormat = this.numberFormat.substring(0, this.numberFormat.indexOf('.'));
                oGrid.setNumberFormat(this.numberFormat, parseInt(i), '', groupSep);
                break;
              }
              case 'decimal': {
                const groupSep = app.sessionData.globalNumericGroupSep || akioma.globalData.globalNumericFormat.groupSep;
                const decSep = app.sessionData.globalNumericDecSep || akioma.globalData.globalNumericFormat.decSep;
                oGrid.setNumberFormat(this.numberFormat, parseInt(i), decSep, groupSep);
                break;
              }
            }
          }

          aHead.push((oCol.att.label) ? oCol.att.label : '');
          aHeadAlign.push('text-align: center;');
          aFilter.push((oCol.att.filter) ? oCol.att.filter : '');

          aEQFilter.push(`<div class="${oCol.att.dataField.toLowerCase()}-cell"></div>`);

          aWidth.push((oCol.att.width) ? parseInt(oCol.att.width) : '');
          aAlign.push((oCol.att.align) ? oCol.att.align : '');
          aColTypes.push((oCol.att.colType) ? oCol.att.colType : '');
          aColSort.push((oCol.att.sortable) ? 'server' : 'na');
          aColUISort.push(oCol.att.sortableType);
          aCells.push((oCol.att.dataField) ? oCol.att.dataField : '');
          aDataTypes.push((oCol.att.dataType) ? oCol.att.dataType : 'character');
          aFolder.push(oCol.att.folderWindow);
          aAkId.push((oCol.att.akId) ? oCol.att.akId : oCol.att.fieldName);


          switch (oCol.att.dataType) {
            case 'date':
              oGrid.calFormats[oCol.att.dataField] = '%Y-%m-%d';
              break;
            case 'datetime':
            case 'datetime-tz':
              oGrid.calFormats[oCol.att.dataField] = '%Y-%m-%dT%H:%i:%s.%u%P';
              break;
          }

          if (oCol.att.colType == 'clist') {
            oGrid.registerCList(i, {
              text: oCol.att.comboText,
              value: oCol.att.comboValues
            });
          }
        }
      }

      oSelf.aAkId = aAkId;
      oSelf.aColSort = aColSort;
      oSelf.akEvent = {};
      oSelf.aFilter = aFilter;
      oSelf._initColIds = aCells;
      oSelf.bMultiline = true;
      oSelf.flagColumnValuesF = []; // this is a flag for filter in column header, will be used to refresh using context menu
      oSelf.bFirstGridLoad = true;
      oSelf.aRefreshColIDs = []; // to apply refresh context menu on column combo dyn
      oSelf.aExtendedFormat = aExtendedFormat;
      oGrid.setHeader(aHead, null, aHeadAlign);

      if (oSelf.opt.showGridFilter != 'none')
        oGrid.attachHeader(aFilter);

      oGrid.setInitWidths(aWidth.join(','));
      oGrid.setColAlign(aAlign.join(','));
      oGrid.setColTypes(aColTypes.join(','));
      if (oSelf.opt.filterMode !== 'client')
        oGrid.setColSorting(aColSort.join(','));
      else
        oGrid.setColSorting(aColUISort.join(','));
      oGrid.setColumnIds(aCells.join(','));
      oGrid.enableKeyboardSupport(true);
      oGrid.setDateFormat('%Y-%m-%dT%H:%i:%s.%u%P');
      oGrid.enableAutoHeight(false);
      oGrid.enableAutoWidth(false);
      oGrid.enableDragAndDrop(true);
      oGrid.enableColumnMove(true);
      oGrid.enableHeaderMenu(true);

      this.aGridFilterFields = []; // this array keeps the osiv filters

      this.aSortPhraes = []; // this array keeps the sortPhrases

      // @todo remove hardcoded value
      if (oSelf.opt.name == 'MainInsAttributePropertyGrid')
        oGrid.enableDragAndDrop(false);

      const oChooseWin = this.getAncestor('popup');
      if (oChooseWin) {
        oGrid.enableEditEvents(false, false, false);
        oGrid.setEditable(false);
        oGrid.attachEvent('onRowDblClicked', (rowId, colNr) => oSelf.rowDblClick(rowId, colNr));
      } else {
        oGrid.enableEditEvents(false, true, true); /* set editmode */
        oGrid.enableLightMouseNavigation(false);
      }

      oGrid.enableEditTabOnly(true);
      oGrid.setSerializationLevel(true, false, false, true, true, false);

      oGrid.attachEvent('onMouseOver', (id, ind) => oSelf.mouseOver(id, ind));
      oGrid.attachEvent('onEditCell', (iStage, rId, cIndex, nValue, oValue) => oSelf.editCell(iStage, rId, cIndex, nValue, oValue));
      oGrid.attachEvent('onCheck', (rId, cIndex, nValue) => oSelf.cellChanged(rId, cIndex, nValue));
      oGrid.attachEvent('onDragIn', (cSrcId, cTrgId, sGrid, tGrid) => oSelf.dragIn(cSrcId, cTrgId, sGrid, tGrid));
      oGrid.attachEvent('onDragOut', (cSrcId, cTrgId, sGrid, tGrid) => oSelf.dragOut(cSrcId, cTrgId, sGrid, tGrid));

      if (oSelf.opt.filterMode !== 'client')
        oGrid.attachEvent('onFilterStart', (aFiltIdx, aFiltVal) => oSelf.filterStart(aFiltIdx, aFiltVal));
      oGrid.attachEvent('onRowSelect', (rowId, colNr) => oSelf.rowSelect(rowId, colNr));
      oGrid.attachEvent('onCollectValues', colNr => oSelf.collectValues(colNr));
      oGrid.attachEvent('onBeforePageChanged', (index, count) => oSelf.pageChanged('before', index, count));
      oGrid.attachEvent('onPageChanged', (index, first, last) => oSelf.pageChanged('after', index, first, last));
      if (oSelf.opt.filterMode !== 'client') {
        oGrid.attachEvent('onBeforeSorting', (col, type, direction) => {
          const cColID = oSelf.dhx.getColumnId(col);

          if (oSelf.dataSource.aNonSortableFields.indexOf(cColID) == -1)
            return oSelf.sorting(col, type, direction);
        });
      }

      let cPrevColSortID = '';
      oSelf.bFlagMoveC = true;
      oGrid.attachEvent('onBeforeCMove', () => {
        if (oSelf.oSortState)
          cPrevColSortID = oGrid.getColumnId(oSelf.oSortState.iCol);

        return true;
      });

      oGrid.attachEvent('onAfterCMove', () => {
        // when moving grid column, reset sort state to correct col index
        if (oSelf.oSortState != undefined && oSelf.bFlagMoveC) {

          const sortInd = oGrid.getColIndexById(cPrevColSortID);
          if (sortInd) {

            oSelf.oSortState.iCol = sortInd;
            oGrid.setSortImgState(oSelf.oSortState.state, oSelf.oSortState.iCol, oSelf.oSortState.order);
          }
        }
        UserProfile.saveGridLocalProfileData(oSelf, akioma.getGridColumnSettings(oSelf));
      });
      oGrid.attachEvent('onResizeEnd', () => {
        UserProfile.saveGridLocalProfileData(oSelf, akioma.getGridColumnSettings(oSelf));
      });

      if (oSelf.opt.onRowChosen != undefined) {
        oGrid.attachEvent('onRowDblClicked', () => {
          /**
           * Client side code executed when a row is chosen (selected by the user to perform an action on it), e.g. by double click
           * @event ak_datagrid#EventRowChosen
           * @type {object}
           */
          app.controller.callAkiomaCode(oSelf, oSelf.opt.onRowChosen);

          return true;
        });
      }

      oGrid.attachEvent('onKeyPress', function(code, cFlag, sFlag, ev) {
        if (code == 83 && cFlag && sFlag) {
          dhtmlx.modalbox({
            title: 'set column-order/width',
            text: 'Are you sure you want to Change the repository-settings for this grid?<br/> This will affect Default for ALL users!',
            buttons: [ 'Yes', 'No' ],
            callback: function(result) {
              if (result == 0)
                oSelf.saveColumnsOrder();

            }
          });

          return false;
        }

        // on enter trigger onRowChosen event
        if (ev.altKey && code == 76)
          akioma.repository.replaceAttributeValue(this);

        if (code == 13) {
          /**
           * Client side code executed when a row is chosen (selected by the user to perform an action on it), e.g. by pressing enter
           * @event ak_datagrid#EventRowChosen
           * @type {object}
           */
          if (oSelf.opt.onRowChosen)
            app.controller.callAkiomaCode(oSelf, oSelf.opt.onRowChosen);
        }
        return true;
      });

      oGrid.akElm = this;

      oSelf._registerFilterEnterKeypress();

      $.extend(this, {
        dhx: oGrid,
        cols: {
          cells: aCells,
          dataTypes: aDataTypes,
          folder: aFolder,
          width: aWidth
        },
        actCol: -1,
        security: {},
        filter: {},
        sort: {}
      });

    },

    updateProperties: function() {
      const oSelf = this;
      const oInsStore = oSelf.dataSource.getStore(oSelf.opt.entityName);
      const oCurrRec = oInsStore.item(oInsStore.getCursor());


      if (oCurrRec) {
        const cObjectInstanceGuid = oCurrRec[oSelf.opt.identifier];
        this.setProperties(cObjectInstanceGuid);
      }
    },
    /**
     * Method for enabling events only filtering in property grid
     * @instance
     * @memberof ak_propertyGrid
     */
    setFilterEventsOnly() {
      this._filterEvents = true;
    },
    /**
     * Method for filtering only event types if filtering is set
     * @instance
     * @memberof ak_propertyGrid
     */
    filterEventsOnly() {
      this.dhx.filterByAll();
      if (this._filterEvents)
        this.dhx.filterBy(this.dhx.getColIndexById('propertyorevent'), row => row === false);

    },

    dataAvailable: function() {
      const oSelf = this;
      let dataSource = oSelf.dataSource;
      if (oSelf.dataSource.dataSource) {
        const targetObj = oSelf.dataSource.dataSource.dynObject.getLink('DATA:TARGET');
        if (targetObj.type === 'designer' || targetObj.type === 'graphEditor')
          dataSource = oSelf.dataSource.dataSource;
      }
      const oStore = dataSource.getStore(dataSource.entityName);
      const item = oStore.item(oStore.getCursor());

      if (item) {
        let guid = item[dataSource.opt.identifier];
        // in case of instance replace, check for new instance and switch to load attributes from Master
        const designer = oSelf.dynObject.container.getFirstChildByType('designer') || oSelf.dynObject.container.getFirstChildByType('graphEditor');
        if (designer) {
          const placeholderInstance = designer.newPlaceholderInstances.find(item => item.newInstanceGuid === guid);

          if (placeholderInstance)
            guid = placeholderInstance.oldInstanceGuid;
          else if (designer.newPageInstances.indexOf(guid) >= 0) {
            designer.lastSelectedInstance = guid;
            guid = akioma.repository.LayoutDesigner.mockPageGuid; // from base mock page
            oSelf.opt.entityName = 'eSmartPage';
          } else if (designer.newInstances.indexOf(guid) >= 0) {
            designer.lastSelectedInstance = guid;
            guid = item['objectmasterguid'];
            oSelf.opt.getValuesMethodName = 'GetObjectMasterDesignAttributes';
            oSelf.opt.entityName = 'eSmartObjectMaster';
            oSelf.opt.onAfterGetValues = '$ akioma.repository.onAfterGetValuesInsProps(self)';
          }
        }

        oSelf.getProperties(guid);
      } else
        oSelf._callEvents('NoDataAvailable');

    },

    stringToBoolean: function(string) {
      string = String(string);
      switch (string.toLowerCase().trim()) {
        case 'true': case 'yes': case '1': return true;
        case 'false': case 'no': case '0': case null: return false;
        default: return Boolean(string);
      }
    },
    /**
     * Method for saving given record guid properties
     * @memberOf ak_propertyGrid
     * @instance
     * @param {string} guid
     */
    setProperties: function(guid) {
      const oSelf = this;
      let oInsVals = [];

      // onBeforeSetValues and the returned data will be used for calling the BT method
      if (oSelf.opt.onBeforeSetValues) {
        try {
          const self = oSelf.dynObject;
          if (oSelf.opt.onBeforeSetValues.substr(1))
            oInsVals = akioma.swat.evaluateCode({ code: oSelf.opt.onBeforeSetValues.substr(1), dynObj: self });
        } catch (e) {
          akioma.notification({ type: 'error', text: `Error when calling onBeforeSetValues method call: ${oSelf.opt.onBeforeSetValues}` });
        }
      }

      if (oInsVals.length > 0) {
        akioma.invokeServerTask({
          name: oSelf.opt.resourceName,
          methodName: oSelf.opt.setValuesMethodName,
          paramObj: {
            plcParameter: { // the backend ignores additional properties, so we can send all of them to cover all cases
              ObjectTypeGuid: guid, // UpdateObjectTypeDesignAttributes
              ObjectMasterGuid: guid, // UpdateObjectMasterDesignAttributes
              ObjectInstanceGuid: guid // UpdateObjectInstanceDesignAttributes
            },
            dsDesignAttributeValue: { dsDesignAttributeValue: { eDesignAttributeValue: oInsVals } }
          },
          showWaitCursor: true,
          uiContext: oSelf.getAncestor('panel').dynObject
        }).done(() => {
          const oDesigner = oSelf.dynObject.container.getFirstChildByType('designer');
          oDesigner.reseletObject(guid);

          oSelf.dc.updatedRows = [];
        }).fail(() => {
          akioma.notification({ type: 'error', text: 'Could not update object attribute !' });
        });
      }

    },

    replaceTagSafe: function(tag) {
      const tagsToReplace = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;'
      };
      return tagsToReplace[tag] || tag;
    },

    replaceTagUnsafe: function(tag) {
      const tagsToReplace = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>'
      };
      return tagsToReplace[tag] || tag;
    },

    safe_tags_replace: function(str) {
      const oSelf = this;
      return str.replace(/[&<>]/g, oSelf.replaceTagSafe);
    },

    unsafe_tags_replace: function(str) {
      const oSelf = this;
      return str.replace(/[&<>]/g, oSelf.replaceTagUnsafe);
    },
    /**
     * Method for attaching events on propertygrid
     * @param {string} name Name of event
     * @param {function} fn Listener
     * @memberof ak_propertyGrid
     * @private
     */
    attachEvent(name, fn) {
      if (!this._events[name])
        this._events[name] = [];
      this._events[name].push(fn);
    },
    /**
     * Method for calling the events with the given name
     * @param {string} name Name of event
     * @memberof ak_propertyGrid
     * @private
     */
    _callEvents(name) {
      const events = this._events[name];
      if (events) {
        events.forEach(ev => ev());
        delete this._events[name];
      }
    },
    /**
     * Method for getting the property values for a given record guid
     * @param {string} guid Record guid
     * @instance
     * @memberOf ak_propertyGrid
     * @returns {Promise}
     */
    getProperties: function(guid) {
      const oSelf = this;

      const propsPromise = new Promise((resolve, reject) => {
        if (guid === '')
          reject();
        else {
          try {

            oSelf.dc.updatedRows = [];
            const designer = this.dynObject.container.getFirstChildByType('designer');
            const skip = (!isNull(designer) && designer.propertyDesignOpened === 'pages');

            let activeEntity = this.dataSource.entityName;
            if (this.dataSource.dataSource)
              activeEntity = this.dataSource.dataSource.entityName;
            // for pages type
            if (activeEntity === 'eSmartPage') {
              oSelf.propStore.clearAll();
              const oPagesStore = oSelf.dataSource.dataSource.getStore('eSmartPage');

              // handling for new pages fetch
              if (!isNull(designer) && designer.newPageInstances.indexOf(designer.lastSelectedInstance) >= 0
                  && guid === akioma.repository.LayoutDesigner.mockPageGuid)
                guid = designer.lastSelectedInstance;

              const id = oSelf.dataSource.dataSource.getIdFrom('pageguid', guid);
              const pageObj = oPagesStore.item(id);
              const formattedPageAttrs = designer.formatPageToProperty(pageObj, guid);
              this.akEvent.resGetProps = { dsDesignAttributeValue: { dsDesignAttributeValue: { eDesignAttributeValue: formattedPageAttrs } } };
              this.dhx.filterByAll();
              if (!isNull(this.opt.onAfterGetValues))
                app.controller.callAkiomaCode(this, this.opt.onAfterGetValues);

              oSelf.filterEventsOnly();
              resolve(formattedPageAttrs);
              oSelf._callEvents('AttributesFetch');

            } else if (!skip) {
              oSelf.propStore.clearAll();
              let designerLastSelectedInstance = null;
              if (!isNull(designer))
                designerLastSelectedInstance = designer.lastSelectedInstance;
              const graphEditor = this.dynObject.container.getFirstChildByType('graphEditor');
              let graphLastSelectedInstance = null;
              if (graphEditor)
                graphLastSelectedInstance = graphEditor.lastSelectedInstance;

              akioma.invokeServerTask({
                name: oSelf.opt.resourceName,
                methodName: oSelf.opt.getValuesMethodName,
                paramObj: {
                  plcParameter: {
                    ObjectTypeGuid: guid,
                    ObjectMasterGuid: guid,
                    ObjectInstanceGuids: guid
                  }
                },
                showWaitCursor: true,
                uiContext: oSelf.getAncestor('panel').dynObject
              }).done(oResult => {
                // in case of instance replace, load attributes for master but save them as if they are instance attributes
                if (!isNull(designer)) {
                  const instanceStore = designer.dataSource.getStore('eSmartObjectInstance');
                  const item = Object.values(instanceStore.data.pull).find(obj => obj.objectmasterguid === guid && designerLastSelectedInstance === obj.objectinstanceguid);

                  const placeholderInstance = designer.newPlaceholderInstances.find(item => item.oldInstanceGuid === guid);
                  if (placeholderInstance)
                    guid = placeholderInstance.newInstanceGuid;
                  else if (item && item.objectmasterguid === guid)
                    guid = item.objectinstanceguid;
                }

                if (!isNull(graphEditor) && !isNull(graphEditor.dataSource)) {
                  const instanceStore = graphEditor.dataSource.getStore('eSmartObjectInstance');
                  const item = Object.values(instanceStore.data.pull).find(obj => obj.objectmasterguid === guid && graphLastSelectedInstance === obj.objectinstanceguid);
                  if (item && item.objectmasterguid === guid)
                    guid = item.objectinstanceguid;
                }

                if (oResult.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue)
                  oResult.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue.map(v => v.ObjectInstanceGuid = guid);

                const resolveData = () => {
                  let aProps = oResult.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue;
                  if (aProps && aProps.length > 0) {
                    aProps = this.setInherited(aProps, true);
                    aProps = this.sortAttributes(aProps, guid);
                    aProps = this.addAditionalProperties(aProps, designer, guid);
                  }
                  if (!isNull(designer)) {
                    aProps = akioma.repository.parseObjectProps(designer, guid, aProps);
                    designer.updateDesignerProps(guid, aProps);
                  }

                  this.akEvent.resGetProps = { dsDesignAttributeValue: { dsDesignAttributeValue: { eDesignAttributeValue: aProps } } };

                  if (!isNull(designer))
                    designer.cLastSelectGuid = guid;


                  this.dhx.filterByAll();
                  if (!isNull(this.opt.onAfterGetValues))
                    app.controller.callAkiomaCode(this, this.opt.onAfterGetValues);

                  oSelf.filterEventsOnly();
                  resolve(aProps);
                  oSelf._callEvents('AttributesFetch');
                };

                if (designer && designer.dataSource) {
                  designer.dataSource.callAfterPendingRequest(() => {
                    resolveData();
                  });
                } else
                  resolveData();

              });
            }
          } catch (e) {
            console.error(e);
          }
        }

      });
      this.getAttributesPromise = propsPromise;
      return propsPromise;
    },

    /**
     * Add aditional properties
     * @memberof ak_propertygrid
     * @instance
     * @param {Array} aProps
     * @param {object} designer
     * @param {string} guid
     * @returns {Array}
     */
    addAditionalProperties(aProps, designer, guid) {

      try {
        const designerType = (!isNull(designer) ? designer.getDesignerType() : 'formbuilder');
        const oSelf = this;

        let dataSource = oSelf.dataSource;
        if (oSelf.dataSource.dataSource) {
          const targetObj = oSelf.dataSource.dataSource.dynObject.getLink('DATA:TARGET');
          if (targetObj.type === 'designer' || targetObj.type === 'graphEditor')
            dataSource = oSelf.dataSource.dataSource;
        }

        dataSource.switchJSDOWorkingRecord('eSmartObjectInstance');
        let id = dataSource.getIdFrom('objectinstanceguid', guid);
        let typeOfRecord = 'instances';
        let oStore = dataSource.getStore('eSmartObjectInstance');

        if (id === undefined) {
          dataSource.switchJSDOWorkingRecord('eSmartPage');
          id = dataSource.getIdFrom('pageguid', guid);
          typeOfRecord = 'pages';
          this.propertyDesignOpened = 'pages';
        }
        if (id === undefined) {
          dataSource.switchJSDOWorkingRecord('eSmartObjectMaster');
          id = dataSource.getIdFrom('objectmasterguid', guid);
          typeOfRecord = 'master';
          oStore = dataSource.getStore('eSmartObjectMaster');
        }
        if (id === undefined) {
          dataSource.switchJSDOWorkingRecord('eSmartObjectType');
          id = dataSource.getIdFrom('objecttypeguid', guid);
          typeOfRecord = 'type';
          oStore = dataSource.getStore('eSmartObjectType');
        }

        const oitm = oStore.item(id);
        switch (typeOfRecord) {
          case 'instances':
            aProps.unshift({
              AttributeLabel: 'ObjectMasterName',
              name: 'objectmastername',
              type: 'input',
              CharacterValue: oitm.objectmastername,
              ObjectMasterGuid: oitm.objectmasterguid,
              ObjectInstanceGuid: guid,
              ObjectType: oitm.instancetypename,
              openExternal: true,
              readonly: true,
              RepositoryType: 'Character',
              LogicalValue: false,
              IsInherited: false,
              TechnicalName: 'ObjectMasterName',
              PropertyOrEvent: true,
              AppliesAtRuntime: true,
              ConstantValue: false
            });

            aProps.unshift({
              AttributeLabel: 'name',
              name: 'name',
              type: 'input',
              CharacterValue: oitm.instancename,
              ObjectInstanceGuid: guid,
              RepositoryType: 'Character',
              LogicalValue: false,
              IsInherited: false,
              TechnicalName: 'name',
              PropertyOrEvent: true,
              AppliesAtRuntime: true,
              ConstantValue: false
            });

            if (designerType === 'containerdesign') {
              aProps.unshift({
                AttributeLabel: 'LayoutPosition',
                name: 'layoutposition',
                type: 'input',
                CharacterValue: oitm.layoutposition,
                ObjectInstanceGuid: guid,
                RepositoryType: 'Character',
                LogicalValue: false,
                IsInherited: false,
                TechnicalName: 'LayoutPosition',
                PropertyOrEvent: true,
                AppliesAtRuntime: true,
                ConstantValue: false
              });
            }
            break;
          case 'master':
            aProps.unshift({
              AttributeLabel: 'name',
              name: 'objectmastername',
              type: 'input',
              RepositoryType: 'Character',
              CharacterValue: oitm.objectname,
              ObjectInstanceGuid: guid,
              ObjectMasterGuid: oitm.objectmasterguid,
              openExternal: true,
              readonly: true,
              LogicalValue: false,
              IsInherited: false,
              TechnicalName: 'ObjectMasterName',
              PropertyOrEvent: true,
              AppliesAtRuntime: true,
              ConstantValue: false
            });

            if (designerType === 'containerdesign') {
              aProps.unshift({
                AttributeLabel: 'Layout',
                name: 'layout',
                type: 'input',
                RepositoryType: 'Character',
                CharacterValue: '' || designer.aTempLayoutOptions[0],
                ObjectInstanceGuid: guid,
                ObjectMasterGuid: oitm.objectmasterguid,
                openExternal: true,
                readonly: true,
                LogicalValue: false,
                IsInherited: false,
                TechnicalName: 'Layout',
                PropertyOrEvent: true,
                AppliesAtRuntime: true,
                ConstantValue: false
              });
            }
            break;
        }
      } catch (e) {
        console.error(e);
      }

      return aProps;
    },

    /**
     * Method used to sort attributes of a propertyGrid
     * @memberof ak_propertygrid
     * @instance
     * @param {Array} aProps array of attributes
     * @param {string} guid
     * @returns {Array}
     */
    sortAttributes(aProps, guid) {
      return _.sortBy(aProps, item => {
        item.ObjectInstanceGuid = guid;
        return [ item.ObjectInstanceGuid, item.AttributeLabel ].join('_').toLowerCase();
      });
    },

    /**
     * Method used to set isInherited attribute
     * @memberof ak_propertygrid
     * @instance
     * @param {Array} aProps array of attributes
     * @param {Boolean} value
     * @returns {Array}
     */
    setInherited(aProps, value) {
      aProps.map(attribute => {
        if (attribute.InheritedFrom !== '<Object Instance>')
          attribute.IsInherited = value;
      });
      return aProps;
    },

    parseAttributes: function(oResult) {

      this.propStore.clearAll();
      this.akEvent.resGetProps = oResult;
      this.dhx.filterByAll();
      // call onAfterGetValues
      app.controller.callAkiomaCode(this, this.opt.onAfterGetValues);
    },

    // this update filters stored per column skipped when adding a new filter
    fnAfterChangedCallback: function(oSelf) {
      const oGrid = oSelf.dhx;

      // sync values from EQ column popup for multiselect combo
      if (oSelf._checkIfMultiSelectFilter(oSelf.cColumnIdCurrentlyFiltered) && $(akioma.gridFilterFieldsPopup.p).css('display') == 'block' && !oSelf.bSkipValueUpdates) {
        const oHdrElm = oSelf.dhx.getFilterElement(oSelf._initColIds.indexOf(oSelf.cColumnIdCurrentlyFiltered));
        if (oSelf.aGridFilterFields[oSelf.cColumnIdCurrentlyFiltered] != undefined) {
          const EQconditions = EQ.client.getQuery().query.root.conditions;

          const aResVals = [];
          const aResKeys = [];
          const aResComboRes = [];
          for (const q in EQconditions) {

            aResVals.push(EQconditions[q].expressions[1].value);
            aResKeys.push(EQconditions[q].expressions[1].text);
            aResComboRes.push(EQconditions[q].expressions[1].text.split(' / ')[1]);
          }
          oSelf.filter[oSelf.cColumnIdCurrentlyFiltered] = aResVals;

          oHdrElm.cont.value = aResVals.join('|');
          $(oHdrElm.DOMelem_input).attr('placeholder', aResComboRes.join(', '));

          if (oSelf.aMultiSelectStatus.aSelectedValuesKeys && oSelf.aMultiSelectStatus.aSelectedValuesKeys[oSelf.cColumnIdCurrentlyFiltered]) {
            oSelf.aMultiSelectStatus.aSelectedComboResult[oSelf.cColumnIdCurrentlyFiltered] = aResComboRes;
            oSelf.aMultiSelectStatus.aSelectedValuesKeys[oSelf.cColumnIdCurrentlyFiltered] = aResKeys;
          }

          if (oSelf.aMultiSelectStatus.aSelectedValues && oSelf.aMultiSelectStatus.aSelectedValues[oSelf.cColumnIdCurrentlyFiltered])
            oSelf.aMultiSelectStatus.aSelectedValues[oSelf.cColumnIdCurrentlyFiltered] = aResVals;
        }
      }

      if (!oSelf.bSkipValueUpdates) {
        // for each condition
        oSelf.dataSource.query.clearAll();
        const oEQuery = EQ.client.getQuery().query.root;
        const aConditions = oEQuery.conditions;
        let cLinkType = oEQuery.linkType.toLowerCase();

        if (cLinkType == 'any')
          cLinkType = 'or';
        else
          cLinkType = 'and';

        if (oSelf.cColumnIdCurrentlyFiltered != undefined) {
          const iColIndex = oSelf._initColIds.indexOf(oSelf.cColumnIdCurrentlyFiltered);

          oSelf.aGridFilterFields[oSelf.cColumnIdCurrentlyFiltered] = [];
          oSelf.aGridColumnOperators[oSelf.cColumnIdCurrentlyFiltered] = cLinkType || 'Any';
          for (let i = 0; i < aConditions.length; i++) {

            if (aConditions[i].expressions.length == 2) {
              let cColVal = aConditions[i].expressions[1].value;
              const cColID = aConditions[i].expressions[0].id;
              const cOperator = aConditions[i].operatorID;
              try {
                let cDateFormated;
                const cColDataType = oSelf.getEQDataType(cColID);
                if (cColDataType == 'Date') {
                  const oColDate = EQ.client.getQuery().model.getDateOrMacroDateValue(cColVal);
                  cColVal = moment(oColDate).format('DD-MM-YYYY');
                  cDateFormated = moment(oColDate).format('YYYY.MM.DD');
                } else if (cColDataType == 'Time') {
                  const oColDate = EQ.client.getQuery().model.getTimeOrMacroTimeValue(cColVal);
                  cColVal = moment(oColDate).format('DD-MM-YYYY HH:mm');
                  cDateFormated = moment(oColDate).format('YYYY.MM.DD HH:mm');
                }

                // only for the first condition do we display the value in the column input field also
                // this update grid header filter input value
                if (i == 0 && cColVal.length > 0) {
                  const oFilterElm = oSelf.dhx.getFilterElement(iColIndex);
                  let cNewVal = cColVal;
                  if (cOperator == 'startswith')
                    cNewVal = `${cNewVal}*`;
                  else if (cOperator == 'endswith')
                    cNewVal = `*${cNewVal}`;
                  else if (cOperator == 'contains')
                    cNewVal = `*${cNewVal}*`;

                  oFilterElm.value = cNewVal;

                  if (!aConditions[i].enabled)
                    $(oFilterElm).attr('disabled', 'disabled');
                  else
                    $(oFilterElm).removeAttr('disabled');
                }
                oSelf.aGridFilterFields[oSelf.cColumnIdCurrentlyFiltered][i] = { attr: cColID, operator: cOperator, value: new String(cColVal), enabled: aConditions[i].enabled, linktype: cLinkType, dateFormat: cDateFormated };
              } catch (e) {
                akioma.log.error(e);
              }
            }
          }

          // if no conditions clear grid header filters
          if (aConditions.length == 0) {
            const oFilterField = oGrid.getFilterElement(oGrid.getColIndexById(oSelf.cColumnIdCurrentlyFiltered));
            $(oFilterField).val('');
            $(oFilterField).removeAttr('disabled');
            oSelf.filter[oSelf.cColumnIdCurrentlyFiltered] = '';
          }

          oSelf.updateBusinessEntityFilter();
        }
      }
    },

    // gets EQ dataType of a field
    getEQDataType: function(cField) {
      const aEQAttrs = EQ.client.getQuery().model.model.rootEntity.subEntities[0].attributes;
      for (let i = 0; i < aEQAttrs.length; i++) {
        if (aEQAttrs[i].id == cField)
          return aEQAttrs[i].dataType;
      }
    },

    // deprecated
    toggleGridFilterHead: function() {
      const oSelf = this;
      oSelf.bHdrVisible = !oSelf.bHdrVisible;

      if (oSelf.bHdrVisible) {
        oSelf.gridHdrFilterDOM.show();

        const $hdr = $(oSelf.dhx.entBox).find('.xhdr');

        $('<div id="grid-eq-header-panels" style="height:40px;width: 100%;float:left;clear:both;"></div><i class="fa fa-icon"></i>').insertBefore($hdr);

        const oLayout = new dhtmlXLayoutObject({
          parent: 'grid-eq-header-panels',
          pattern: '1C'
        });

        oLayout.cells('a').hideHeader();
        oSelf.buildGridSearchInput(oLayout.cells('a'));
      } else {
        oSelf.gridHdrFilterDOM.hide();
        $('#grid-eq-filter, #grid-eq-header-panels').remove();

        oSelf.bGridFilterOpened = false;
        akioma.gridFilterFieldsPopup.unload();
        delete oSelf.queryWindow;
      }

      oSelf.dhx.hdr.parentElement.style.height = oSelf.dhx.hdr.style.height; // update header height
    },

    calculateWidth_inForm: function(oSelf, oParent) {
      $(oParent).closest('.dhxform_control').css('position', 'relative');
      // fix get parent of cell width and set that as  width
      let iWidth = 800;
      try {
        iWidth = parseInt(oSelf.getAncestor('panelset').dhx.cells(oSelf.getAncestor('panel').opt.layout).getWidth() - 50);
      } catch (e) {
        akioma.log.error('Could not get parent cell for size of grid2 in form');
      }
      $(oParent).closest('.dhxform_control').width(iWidth);
      return iWidth;
    },

    buildGridSearchInput: function(cell) {
      const oSelf = this;
      if (oSelf.opt.resultListMenuCode)
        oSelf.createMenuLookup(oSelf.opt.resultListMenuCode);

      const myForm = cell.attachForm([{ type: 'input', name: 'searchInput', label: '', value: '' }]);
      oSelf.gridSearchInputPopup = myPop;

      myForm.attachEvent('onFocus', () => { });

      oSelf.popupform = myForm;
      const oGrid = oSelf.dhx;
      oSelf.dhx.attachEvent('onSetSizes', () => {
        $(oGrid.entBox).find('.objbox').height($(oGrid.entBox).find('.objbox').height() - 43);

        if ($.inArray(oSelf.parent.view, oSelf.formViews) !== -1) {
          const formParent = akioma.getForm(oSelf);
          const oParent = formParent.dhx.getContainer(`formGrid_${oSelf.opt.name}`);

          const iWidth = oSelf.calculateWidth_inForm(oSelf, oParent);
          $(oGrid.entBox).width(iWidth);
        }
      });

      myForm.attachEvent('onKeyUp', () => {});


      let inputTimeout = null;

      (function(oSelf, myForm) {
        myForm.attachEvent('onInputChange', () => {
          const text = myForm.getItemValue('searchInput');
          if (inputTimeout)
            clearTimeout(inputTimeout);
          inputTimeout = setTimeout(() => {
            oSelf.dataSource.setNamedQueryParam('AutoCompleteSearch', 'SearchKey', text, 'character');
            oSelf.dataSource.openQuery({});
          }, 400);
        });
      })(oSelf, myForm);
    },

    // deprecated
    autoCompleteSearchPopup: function(cInput) {
      const oGrid = this.dhx;
      const oSelf = this;
      if (cInput.length > 0) {
        oSelf.getAncestor('panel').dhx.progressOn();
        akioma.invokeServerTask({
          name: 'Akioma.Swat.AutoCompleteSearchBT',
          methodName: 'GetSpecificMatchingResultSet',
          paramObj: {
            plcParameter: {
              name: 'Akioma.Swat.AutoCompleteSearchBT',
              key: cInput,
              pos: ''
            }
          }
        }).done(odata => {
          function keysToLowerCase(obj) {
            const newObj = {};
            for (const i in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, i))
                newObj[i.toLocaleLowerCase()] = obj[i];
            }
            return newObj;
          }

          oGrid.clearAll();
          for (const i in odata.dsStamm.dsStamm.eStamm) {
            odata.dsStamm.dsStamm.eStamm[i].id = i;
            odata.dsStamm.dsStamm.eStamm[i] = keysToLowerCase(odata.dsStamm.dsStamm.eStamm[i]);
          }
          oSelf.dataSource.dhx.parse({ data: odata.dsStamm.dsStamm.eStamm });
          oSelf.getAncestor('panel').dhx.progressOff();
        });
      } else
        oSelf.dataSource.openQuery();

    },

    // deprecated
    autoCompleteSearch: function(cInput) {
      const serviceURI = window.location.origin,
        jsdoSettings = {
          serviceURI: serviceURI,
          catalogURIs: `${serviceURI}/web/Catalog/Akioma.Swat.AutoCompleteSearchBT`,
          authenticationModel: progress.data.Session.AUTH_TYPE_FORM
        },
        oSelf = this;

      const onAfterAddCatalog = res => {
        // check for error type object
        if (akioma.isObjOfTypeError(res)) {
          akioma.log.error(res);
          return;
        }

        const dsTasks = new progress.data.JSDO ({ name: 'Akioma.Swat.AutoCompleteSearchBT' });

        // call param with input search term
        const oParameter = {
          plcParameter: {
            name: 'omniSearch',
            key: cInput,
            pos: ''
          }
        };

        // Perform the asynchronous call
        // true = asynchronously
        const call = dsTasks.GetMatchingResultSet (oParameter, true).deferred;
        call.done ((method, jsdo, success) => {
          // Retrieve JavaScript object from ABL serializable parameter
          const oOutput = success.response;
          const templateResults = Handlebars.compile(akioma.handlebarsTemplates.autocompleteSearchBT.templateResults);
          const oData = { results: oOutput.dsAutoCompleteSearch.autoCompleteSearch.searchResult };

          let result = templateResults(oData);
          if (oSelf.menuLookup)
            result += oSelf.menuLookup.result;
          oSelf.gridSearchInputPopup.attachHTML(result);

          $('.footer-add').on('click', function() {
            const id = $(this).attr('id');
            oSelf.menuLookup.applyAction(id, oSelf);
            oSelf.gridSearchInputPopup.hide();
            $('#grid-eq-filter, #grid-eq-header-panels').remove();
            return true;
          });

          const oWindow = oSelf.dynObject.container.controller;
          const mousetrap = oWindow.oMouseTrap;

          let $current;
          $('.searchItem:first-child').addClass('selected');
          $('.searchItem').mouseover(function() {
            $('.searchItem').removeClass('selected');
            $current = $(this);
            $current.addClass('selected');
          });
          $('.searchItem').mouseout(() => {
            $current.removeClass('selected');
          });

          mousetrap.bind([ 'up', 'down' ], event => {
            const $items = $('.searchItem'),
              $selected = $items.filter('.selected');

            event = event || window.event;
            switch (event.keyCode) {
              case 38: // up
                if (!$selected.length || $selected.is(':first-child'))
                  $current = $items.last();
                else
                  $current = $selected.prev();
                break;
              case 40: // down
                if (!$selected.length || $selected.is(':last-child'))
                  $current = $items.eq(0);
                else
                  $current = $selected.next();
                break;
            }

            const elemPos = isInViewport('.results-container', $current);
            const scrol_pos = $('.results-container').scrollTop();
            switch (elemPos) {
              case 0:
                break;
              case 1:
                $('.results-container').scrollTop(scrol_pos - 150);
                break;
              case -1:
                $('.results-container').scrollTop(scrol_pos + 150);
                break;
            }

            $items.removeClass('selected');
            $current.addClass('selected');
          });

          mousetrap.bind('enter', () => {
            oSelf.popupform.setItemValue('searchInput', $current.context.textContent);
          });

        }); // call.done

        function isInViewport(container, element) {
          // returns
          //      0 - when element is in viewport
          //      1 - when element is up
          //     -1 - when element is down

          const win = $(container);
          const viewport = {
            top: win.scrollTop(),
            left: win.scrollLeft()
          };
          viewport.right = viewport.left + win.width();
          viewport.bottom = viewport.top + win.height();

          const bounds = element.offset();
          bounds.right = bounds.left + element.outerWidth();
          bounds.bottom = bounds.top + element.outerHeight();

          if (viewport.bottom < bounds.top)
            return -1;
          if (viewport.top > bounds.bottom)
            return 1;
          if (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom))
            return 0;
        }

      }; // addCatalog.done

      // add catalog first
      akioma.restSession.addCatalog(jsdoSettings.catalogURIs).then(res => {
        onAfterAddCatalog(res);
      }).catch(res => {
        onAfterAddCatalog(res);
      });
      // after addCatalog perform a business task method call
    },

    endConstruct: function() {
      const oSelf = this,
        oGrid = this.dhx;

      // apply context menu on combo dyns
      for (const i in oSelf.aRefreshColIDs) {
        (function createMenu(i) {
          const cColID = oSelf.aRefreshColIDs[i].dataField,
            iColNr = oSelf.aRefreshColIDs[i].iColNr,
            cName = oSelf.aRefreshColIDs[i].cName,
            cObjName = oSelf.aRefreshColIDs[i].cObjName,
            iColIndex = oGrid.getColIndexById(cColID),
            filterElm = oGrid.getFilterElement(iColIndex);

          // add combobox refresh entries menu
          const oReloadMenu = new dhtmlXMenuObject({
            parent: $(filterElm).parent()[0],
            context: true
          });

          oReloadMenu.addNewChild(oReloadMenu.topId, 1, 'clear', 'Clear cache', false);
          oReloadMenu.addNewChild(oReloadMenu.topId, 2, 'reload', 'Refresh', false);
          oReloadMenu.attachEvent('onClick', id => {
            if (id == 'clear') {
              // remove values from storage
              localStorage && localStorage.removeItem(`ak_combogrid_${cObjName}`);
            } else if (id == 'reload') {
              localStorage.removeItem(`ak_combogrid_${cObjName}`);
              oSelf._loadFilterDynComboEntries(cName, cObjName, iColNr);
            }
          });
        })(i);

      }
      oSelf.aRefreshColIDs = [];

      const oNewKey = akioma.aLoadedTGF[`ak_${oSelf.view}_${oSelf.opt.name}`];
      if (oNewKey == undefined)
        akioma.aLoadedTGF[`ak_${oSelf.view}_${oSelf.opt.name}`] = [];
      akioma.aLoadedTGF[`ak_${oSelf.view}_${oSelf.opt.name}`].push(oSelf);
    },

    // calls businessTask to save column order
    saveColumnsOrder: function() {
      const oSelf = this;
      const aColIds = oSelf._initColIds;

      const oParam = {
        plcParameter: { Value: oSelf.opt._ObjectName },
        dsGridColumnOrderMaintenance: { eGridColumnOrderMaintenance: [] }
      };

      const aGridColumns = oParam.dsGridColumnOrderMaintenance.eGridColumnOrderMaintenance;

      let iExtraRow = 0;

      if (aColIds.indexOf('rowmod') > -1)
        iExtraRow = 1;

      for (const i in aColIds) {
        if (aColIds[i].toLowerCase() != 'rowmod') {
          aGridColumns.push({
            ColumnName: aColIds[i],
            ColumnOrder: oSelf.dhx.getColIndexById(aColIds[i]) + 1 - iExtraRow,
            ColumnWidth: oSelf.dhx.getColWidth(oSelf.dhx.getColIndexById(aColIds[i]))
          });
        }
      }

      akioma.invokeServerTask({
        name: 'Akioma.Swat.Repository.RuntimeMaintenance',
        methodName: 'SetGridColumnProperties',
        paramObj: oParam
      }).done(() => {
        akioma.notification({ type: 'info', text: `New columns order for "${oSelf.opt.name}" has been saved successfully!` });
      });
    },

    // calls dataSource BE count method for returning the number of records in businessEntity
    countRecords: function() {
      const oSelf = this;
      try {
        const promiseCount = oSelf.dataSource.jsdo.count({ plcParameter: { akQuery: oSelf.dataSource.urlQuery.akQuery } }, true).deferred;
        promiseCount.done((method, jsdo, success) => {
          const iNoRec = success.response.piNumRecs;
          if (parseInt(iNoRec) == 9999)
            akioma.notification({ type: 'info', text: 'Das Zählen dauerte zu lange und wurde abgebrochen.' });
          else
            akioma.notification({ type: 'info', text: `${iNoRec} records in "${oSelf.opt.title}".` });
        });
      } catch (e) {
        if (oSelf.dataSource.opt.name)
          akioma.notification({ type: 'error', text: `Could not return number of records for ${oSelf.dataSource.opt.name}` });
        else
          akioma.log.error('Error returning number of records for BusinessEntity', e);
      }
    },

    createMenuLookup: function(structureCode) {
      const oSelf = this;
      const aMainMenuOptions = [];
      let result;

      const mainMenu = app.controller.parseProc({
        view: 'menustructure',
        att: { id: structureCode },
        sub: []
      }, oSelf);

      const onfinish = function() {
        mainMenu.scan((itemid, label, icon) => {
          aMainMenuOptions.push({ id: itemid, text: label, img: icon });
        });
        const templateMenu = Handlebars.compile(akioma.handlebarsTemplates.autocompleteSearchBT.templateMenu);
        const oData = { results: aMainMenuOptions };
        result = templateMenu(oData);
        mainMenu.result = result;
        oSelf.menuLookup = mainMenu;
      };
      mainMenu.loadMenuElements(onfinish);
    },


    // save profile ********************
    saveProfile: function(cType) {
      const oSelf = this,
        aField = [],
        aWidth = [],
        iCols = this.dhx.getColumnsNum();

      if (cType == 'clear') {
        // clear profile
      } else {
        for (let i = 0; i < iCols; i++) {
          aField.push(this.dhx.getColumnId(i));
          aWidth.push(this.dhx.getColWidth(i));
        }
      }

      $.ajax({
        type: 'POST',
        url: '/akioma/saveGridProfile.p',
        dataType: 'json',
        data: `Proc=${this.opt.gridName}&Container=${this.dynObject.container.name}&Fields=${aField.join(',')}&Width=${aWidth.join(',')}`,
        success: function() {},
        error: function(xhr, textStatus, errorThrown) {
          akioma.log.error(`Error getting data from ${oSelf.opt.SDO}: ${textStatus} -> ${errorThrown}`);
        }
      });
    },

    // sorting *********************
    sorting: function(iCol, cType, cDir) {
      const oGrid = this.dhx,
        cName = oGrid.getColumnId(iCol),
        oSelf = this;

      // check for old
      if (this.sort.col != undefined && this.sort.col == iCol) {
        // change direction
        cDir = (this.sort.dir == 'asc') ? 'desc' : 'asc';
      }

      // save sort
      this.sort = {
        col: iCol,
        dir: cDir,
        name: cName
      };

      this.dataSource.query.setSorting([{ field: cName, direction: cDir }]);
      let cColSortPhrase = this.getColSortPhrase(cName);
      if (cColSortPhrase) {
        if (cColSortPhrase.indexOf('$Order') == -1)
          cColSortPhrase += '$Order';

        cColSortPhrase = cColSortPhrase.replace('$Order', ((oSelf.sort.dir == 'asc') ? '' : oSelf.sort.dir));
        this.dataSource.query.setStringSorting(cColSortPhrase);
        this.dataSource.query.buildQuery();
      }

      this._renderMultiSelectStateAfterFill(); // show multiselect checkboxes
      this.dataSource.openQuery({});

      // save sort arrow to apply after grid loads
      this.oSortState = {
        state: true,
        iCol: iCol,
        order: cDir
      };

      this.dataSource.oSortState = this.sort;

      return false;
    },

    getColSortPhrase: function(cName) {
      return this.aSortPhrases[cName];
    },

    // collect values (filter) ************
    collectValues: function(colNr) {
      const oSelf = this,
        oGrid = this.dhx;
      // get correct column id
      if (this._initColIds)
        colNr = this.dhx.getColIndexById(this._initColIds[colNr]);

      // check for logical datatype
      if (this.cols.dataTypes[colNr] == 'logical')
        return [ 'yes', 'no' ];

      // check for combo
      let cName, oCol;
      if (oGrid.cellType[colNr].indexOf('combo') == 0) {
        if ((oSelf.flagColumnValuesF[colNr] == undefined || oSelf.flagColumnValuesF[colNr] == false) && oSelf.flagColumnValuesF[colNr] != true) {
          cName = oGrid.columnIds[colNr];

          // check for col
          oCol = $.grep(this.childs, oElm => (oElm.opt.dataField == cName));
          if (oCol.length < 0) {
            akioma.message({ type: 'alert-error', title: 'Getting combo values', text: `Column in datagrid for '${cName}' not available!` });
            return null;
          }
          const cObjName = oCol[0].opt.comboName;

          // dyncombo - add to refresh column id array, to apply context menu refresh later
          if (oCol[0].opt.dyncombo) {
            oSelf.aRefreshColIDs.push({
              dataField: oCol[0].opt.dataField,
              cName: cName,
              cObjName: cObjName,
              colNr: colNr
            });
          }

          // check for localStorage
          const cData = (localStorage ? localStorage.getItem(`ak_combogrid_${cObjName}`) : null);
          if (cData && app.sessionData.useComboCache)
            oGrid.comboValues[cName] = JSON.parse(cData);
          else {
            // load from JSDO
            if (oCol[0].opt.ListItemPairs && oCol[0].opt.ListItemPairs[0] == '$' && !oCol[0].bCollectStop) {
              LoadDataHelper.loadData(oCol[0].opt.ListItemPairs, oCol[0], data => {
                const aData = data,
                  aOpt = [];

                for (let i = 0; i < aData.length; i++) {
                  aOpt.push({
                    value: aData[i].selfhdl,
                    text: aData[i].selfdesc
                  });
                }

                if (aOpt.length > 0) {
                  oGrid.comboValues[cName] = aOpt;
                  // save combo values in storage
                  localStorage && localStorage.setItem(`ak_combogrid_${cObjName}`, JSON.stringify(aOpt));

                  oCol[0].bCollectStop = true;
                  oGrid.collectValues(colNr);
                }
              });
            } else if (oCol[0].opt.dyncombo && oCol[0].opt.ListItemPairs == undefined) // check for dyn combo
              oSelf._loadFilterDynComboEntries(cName, cObjName);

            else if (oCol[0].businessEntity && oCol[0].rows) {
              const oData = [];
              for (let i = 0; i < oCol[0].rows.length; i++) {
                try {
                  oData.push({
                    value: oCol[0].rows[i][oCol[0].opt.dynSelect.lookupKeyValueColumn],
                    text: oCol[0].rows[i][oCol[0].opt.dynSelect.lookupKeyField].toString()
                  });
                } catch (e) {
                  akioma.log.error(e);
                }
              }
              oGrid.comboValues[cName] = oData;
            } else if (oCol[0].opt.ListItemPairs == undefined && (oCol[0].opt.dyncombo == undefined || !oCol[0].opt.dyncombo) && oCol[0].opt.comboText && oCol[0].opt.comboValues) {
              // static combo
              const oData = [],
                oComboVal = oCol[0].opt.comboValues.split(','),
                oComboText = oCol[0].opt.comboText.split(',');
              for (const i in oComboVal) {
                oData.push({
                  value: oComboVal[i],
                  text: oComboText[i]
                });
              }
              oGrid.comboValues[cName] = oData;
            } else
              oGrid.comboValues[cName] = [];

            if (oCol[0].bCollectStop)
              oCol[0].bCollectStop = false;
          }
        }

        const aOptions = [];
        for (const i in oGrid.comboValues[cName]) {
          if (!isValidHdl(oGrid.comboValues[cName][i].value)) // there is no sense in using a handle as a literal key
            oGrid.comboValues[cName][i].text = akioma.tran(`${this.opt.name}.${cName}_Entries.${oGrid.comboValues[cName][i].value}`, { defaultValue: oGrid.comboValues[cName][i].text });

          if (oCol[0].opt.filter == '#multiselect_filter' || (oCol[0].opt.filter == '#dynselect_filter'))
            aOptions.push([ oGrid.comboValues[cName][i].value, oGrid.comboValues[cName][i].text ]);
          else
            aOptions.push(oGrid.comboValues[cName][i].text);
        }

        oSelf.flagColumnValuesF[colNr] = false;
        return aOptions;
      }

      return true;
    },

    // loads dyn combo filter options
    _loadFilterDynComboEntries: function() {
      return false;
    },

    // get grid col *******************
    getComboValues: function(cName) {
      const aRet = [],
        oCombo = this.dhx.comboValues[cName];

      if (oCombo) {
        for (const i in oCombo)
          aRet.push(`${oCombo[i].value}|${oCombo[i].text}`);
      }

      return aRet.join(';');
    },

    // get grid col *******************
    getGridCol: function(cName) {
      // get column
      const oElm = $.grep(this.childs, oCol => oCol.opt.dataField == cName);
      return oElm ? oElm[0] : null;
    },

    // get field value *******************
    getFieldValue: function(cName) {
      // get grid and handle
      const oGrid = this.dhx;
      const cHdl = oGrid.getSelectedRowId();

      let cValue;
      if (this.opt.multiSelect == false) {
        const iCol = oGrid.getColIndexById(cName);
        cValue = oGrid.cellById(cHdl, iCol).getValue();
      } else {
        const aIds = cHdl.split(',');
        const aResult = [];
        if (cName == undefined)
          cName = 'selfhdl';


        for (const i in aIds) {
          const id = aIds[i];
          const oItem = this.dataSource.dhx.item(id);

          if (oItem)
            aResult.push(oItem[cName.toLowerCase()]);
        }

        cValue = aResult.join(',');
      }

      return cValue;
    },

    // set field value ********************
    setFieldValue: function(cName, cValue) {

      // get grid and handle
      const oGrid = this.dhx,
        cHdl = oGrid.getSelectedRowId(),
        iCol = oGrid.getColIndexById(cName),
        oCell = oGrid.cellById(cHdl, iCol);

      // set value
      if (oCell)
        oCell.setValue(cValue);
      else
        !_isIE && console.error([ 'setFieldValue: no cell available', cName, cValue, cHdl, iCol ]);
    },

    // get lookup key
    getLookupKey: function(cRow, iCol) {
      const oGrid = this.dhx,
        cName = oGrid.getColumnId(iCol),
        oCol = this.getGridCol(cName);

      return oCol.getLookupKey(cRow);
    },

    // page changed
    pageChanged: function() {
      return true;
    },

    // mouse over
    mouseOver: function(id, ind) {
      const oGrid = this.dhx;
      const cellGrid = oGrid.cellById(id, ind);
      if ($(cellGrid.cell).hasClass('anonymizedCell'))
        cellGrid.setAttribute('title', 'Access denied');
      return true;
    },

    // start lookup ***************************
    startLookup: function(iCol, cType) {
      const cName = this.dhx.getColumnId(iCol),
        oCol = this.getGridCol(cName);

      this.prop.colid = iCol;

      // check for lookup and get parameter for lookup
      if (oCol && oCol.opt.lookup_objectName) {
        // call dialog
        app.controller.launchContainer({
          async: true,
          proc: 'popup.r',
          para: `RunFile=${oCol.opt.lookup_lookupDialog}&FieldName=${oCol.opt.dataField}&TargetId=${this.opt.id},${cType}`,
          self: oCol,
          data: true
        });
      }
    },

    // get value from server ******************
    getValueFromServer: function(cValue, oSelf) {
      // now get appropriate column
      const iCol = oSelf.cell.cellIndex;

      // get lookup
      const oCol = this.childs[iCol];
      if (oCol) {
        // run method to get value from server
        oCol.getValueFromServer(cValue, oSelf, 'cell');
      }
    },

    // set filter fields in header ***********
    setFilterFields: function(aFilterFields) {
      const aTypes = this.cols.dataTypes;

      this.filter = {};

      for (const i in aFilterFields) {
        const oFilter = aFilterFields[i];
        const cField = oFilter.fieldname.toLowerCase();
        const iNum = $.inArray(cField, this._initColIds);
        // now it has to be a valid filter
        if (iNum > -1) {
          const oHeaderElm = this.dhx.getFilterElement(iNum);
          if (oHeaderElm) {
            switch (aTypes[iNum]) {
              case 'combo': { // check for value
                const aValues = this.dhx.comboValues[cField];
                for (const i in aValues) {
                  if (aValues[i].value == oFilter.value)
                    oHeaderElm.dataset.selectVal = aValues[i].text;
                }
                const shortKey = (aValues[i].text.split(' / ')[1]) ? aValues[i].text.split(' / ')[1] : aValues[i].text;
                const oSelectedItem = {
                  'id': aValues[i].value,
                  'key': aValues[i].text,
                  'shortKey': shortKey
                };
                this.setDynSelectFilters(cField, oSelectedItem);
                break;
              }
              case 'logical':
                oHeaderElm.value = oFilter.value;
                if (this.aFilter[iNum] == '#logical_filter') {
                  const oSelfCol = this.childs[iNum];
                  const aExtendedFormat = this.aExtendedFormat[iNum];

                  let cNewClass, cNewCss, cNewState;
                  switch (oFilter.value) {
                    case '':
                      cNewClass = aExtendedFormat['empty'].icon;
                      cNewCss = aExtendedFormat['empty'].css;
                      cNewState = '';
                      break;
                    case 'yes':
                      cNewClass = aExtendedFormat['filterTrue'].icon;
                      cNewCss = aExtendedFormat['filterTrue'].css;
                      cNewState = 'yes';
                      break;
                    case 'no':
                      cNewClass = aExtendedFormat['filterFalse'].icon;
                      cNewCss = aExtendedFormat['filterFalse'].css;
                      cNewState = 'no';
                      break;
                  }

                  $(oHeaderElm.firstChild).removeClass().addClass(cNewClass);
                  oHeaderElm.firstChild.style.cssText = cNewCss;
                  oSelfCol.cLogicalState = cNewState;
                  oHeaderElm.value = cNewState;
                }
                break;
              case 'lookup': {
                const oData = app.controller.callServerMethod('stubs/hdl2keyanddesc.p',
                  [
                    { type: 'iCHAR', value: oFilter.value },
                    { type: 'oCHAR', name: 'key' },
                    { type: 'oCHAR', name: 'desc' }
                  ]);

                oHeaderElm.valhdl = oFilter.value;
                oHeaderElm.value = oData.key;
                break;
              }
              case 'logicalpic':
                oHeaderElm.setComboValue(oFilter.value);
                break;
              default:
                oHeaderElm.value = oFilter.value;
                break;
            }
            this.filter[cField] = oFilter.value;

            const bCheckCorrectBEGINS = (this.filter[cField].split('*').length > 1 && oFilter.operator == 'BEGINS');
            if (oFilter.operator && !bCheckCorrectBEGINS)
              this.filter[cField] += `|${oFilter.operator}|${this.getGridCol(cField).opt.dataType}`;

          }
        }
      }
    },

    getFiltersBT: function() {
      const promise = $.Deferred();
      promise.resolve();
      return promise;
    },

    // get list of filters from server **********
    getFilterList: function() {
      const oSelf = this;

      // check for filter combo in toolbar
      const oFilter = this.dynObject.getLink('FILTER:SOURCE');
      if (!oFilter || oFilter.length == 0) {
        if (oSelf.dataSource._pendingrequest == false)
          oSelf.FilterGo({ filterGo: true });
        return;
      }

      let oCombo = oFilter.getField('tbfilterChooseFilter');
      if (!oCombo)
        return;
      oCombo = oCombo.controller;
      const oToolbar = oCombo.parent;

      // load from JSDO
      if (this.opt.ListItemPairs && this.opt.ListItemPairs[0] == '$') {
        LoadDataHelper.loadData(this.opt.ListItemPairs, this, data => {
          const aOptions = [];
          for (const i in data) {
            aOptions.push({
              hdl: data[i].selfhdl,
              value: data[i].selfdesc,
              image: null
            });
          }
          oCombo.setComboOptions(aOptions);

          oSelf.prop.filter = data;
        });
      } else { // get name of sdo
        const cSDO = this.dataSource.opt.SDO;

        // stop datasource openQuery until filters load
        oSelf.dataSource.stop = true;

        $.ajax({
          type: 'POST',
          url: '/akioma/getdata.xml',
          data: `Action=getFilterList&SDO=${cSDO}&Grid=${this.opt.gridName}`,
          dataType: 'json',
          success: function(data) {
            let defaultOption;
            const aOptions = [];

            for (const i in data.filter) {
              aOptions.push({
                hdl: data.filter[i].hdl,
                value: data.filter[i].desc,
                image: null
              });
              if (data.filter[i].isdefault)
                defaultOption = data.filter[i];
            }
            oCombo.setComboOptions(aOptions);

            oSelf.prop.filter = data.filter;

            // set default
            if (defaultOption) {
              const oDhxTool = oToolbar.dhx;

              oDhxTool.setListOptionSelected('tbfilterChooseFilter', defaultOption.hdl);
              oDhxTool.setItemText('tbfilterChooseFilter', defaultOption.desc);

              oSelf.setFilterFields(defaultOption.definition);

              // set rowstobatch
              oSelf.rowsToBatch(defaultOption.rowsToBatch);
              oDhxTool.setListOptionSelected('rowsToBatch', defaultOption.rowsToBatch);
              oDhxTool.setItemText('rowsToBatch', defaultOption.rowsToBatch);

              // set sorting
              if (defaultOption.sort) {
                oSelf.sort = {
                  col: oSelf.dhx.getColIndexById(defaultOption.sort.split(',')[0]),
                  name: defaultOption.sort.split(',')[0],
                  dir: defaultOption.sort.split(',')[1]
                };
                oSelf.dhx.setSortImgState(true, oSelf.sort.col, oSelf.sort.dir);
              }

              // write them to source
              oSelf.dataSource.setFilter(oSelf.filter);
            }
            // now get data after applying filters
            oSelf.dataSource.stop = false;
            oSelf.FilterGo({ filterGo: true });
          },
          error: function(xhr, textStatus, errorThrown) {
            akioma.log.error(`Error getting data from ${oSelf.opt.SDO}: ${textStatus} -> ${errorThrown}`);
          }
        });
      }
    },

    // filter start ****************************
    filterStart: function(aFiltIdx, aFiltVal) {
      const aTypes = this.cols.dataTypes,
        aFilter = this.aFilter,
        oFilter = {},
        oGrid = this.dhx;

      for (const i in aFiltIdx) {
        const iGridCol = aFiltIdx[i];
        const cName = oGrid.getColumnId(iGridCol);
        const iCol = $.inArray(cName, this.cols.cells);

        if (aTypes[iCol] == 'multicombo')
          oFilter[cName] = aFiltVal[i].split('|');
        else if (aFilter[iCol] == '#dynselect_filter')
          oFilter[cName] = aFiltVal[i].split('|');
        else
          oFilter[cName] = (aFiltVal[i] == '') ? '' : aFiltVal[i];
      }
      this.filter = oFilter;

      // prevent going to server
      return false;
    },

    // filter for lookup ***************************
    filterLookup: function(iIndex, cValue, lSave) {
      if (cValue) {
        const cName = this.dhx.getColumnId(iIndex);
        const oCol = this.getGridCol(cName);
        if (!oCol) {
          dhtmlx.message({
            type: 'alert-error',
            title: 'Lookup-Filter',
            text: `Error getting column for filter:<br />${iIndex}: ${cName}`
          });
          return;
        }

        const oData = app.controller.callServerMethod('stubs/getLookupResults.p',
          [
            { type: 'iCHAR', value: `${oCol.opt.lookup_tableName}.${oCol.opt.lookup_extKey}@${oCol.opt.lookup_objectName}` },
            { type: 'iCHAR', value: cValue },
            { type: 'iCHAR', value: '' },
            { type: 'oCHAR', name: 'hdl' },
            { type: 'oCHAR', name: 'desc' },
            { type: 'oCHAR', name: 'status' }
          ]);

        if (lSave) {
          this.dataSource.setFieldValue({ name: cName, value: oData.hdl });
          this.dataSource.setFieldValue({ name: oCol.opt.lookup_showKey, value: cValue });
        }
        return oData.hdl == '?' ? null : oData.hdl;
      } else
        return;
    },

    // go with filter ******************************
    FilterGo: function() {
      const oSelf = this;
      const oOpen = {
        filterGo: true,
        caller: this
      };

      if (this.sort) {
        oOpen.sort = this.sort.name;
        oOpen.dir = this.sort.dir;
      }

      delete this.dataSource.oNamedQuery;

      if (this.dhx.filters) {
        $.each(this.dhx.filters, function() {
          this[0].blur();
        });

        // refresh filter values
        this.dhx.filterByAll();
      }

      if (this.queryWindow)
        this.queryWindow.updateAkFilter(this.dataSource.query);


      // write them to source
      this._syncFilters();

      if (oSelf.popupform) {
        const cFullTextSearch = this.popupform.getItemValue('searchInput');
        if (cFullTextSearch.length > 0)
          this.dataSource.query.setFullTextSearch(cFullTextSearch);
      }

      // tell source to refresh
      if (this.opt.batchingMode)
        oSelf.dataSource.setBatch(0, 50);

      this.dataSource.openQuery(oOpen);

      this._renderMultiSelectStateAfterFill(); // show multiselect checkboxes

      // for batching mode when active, after fill get total number of records
      if (this.opt.batchingMode) {
        this.dataSource.addAfterFillOnceCallback(() => {
          const promiseCount = oSelf.dataSource.jsdo.count({ plcParameter: { akQuery: oSelf.dataSource.urlQuery.akQuery } }, true).deferred;
          promiseCount.done((method, jsdo, success) => {
            const iNoRec = success.response.piNumRecs;
            oSelf.dataSource.noOfRecords = iNoRec;
            oSelf.dataSource.setBatch(0, 50);
            oSelf.dataSource.stop = false;

            oSelf.dataSource.openQuery({});
            oSelf._renderMultiSelectStateAfterFill();
          });
        });
      }
    },

    removeClear_dynSelect: function(elem) {
      if ($(elem).find('li.select2-selection__choice').length == 0)
        $(elem).find('span.select2-selection__clear').remove();
    },

    removeEmptyTag_dynSelect: function(elem) {
      // removes empty tags and inline search bugs in dynSelect
      const tag = $(elem).find('li.select2-selection__choice[title=""] , li.select2-selection__choice:not([title])');
      if (tag.length > 0)
        tag.remove();
      if ($(elem.parentElement).find('select option:first').text() == '')
        $(elem.parentElement).find('select option:first').remove();
    },

    setDynSelectFilters: function(filterName, oSelectedItem) {

      function addFilter(list, filterName, filterObj) {
        if (list[filterName] == undefined)
          list[filterName] = [];
        list[filterName].push(filterObj);
      }

      const oSelf = this;
      addFilter(oSelf.aDynSelectStatus.aSelectedValues, filterName, oSelectedItem.id);
      addFilter(oSelf.aDynSelectStatus.aSelectedItems, filterName, oSelectedItem);
    },

    _renderMultiSelectStateAfterFill: function() {
      let $select2Elm, oSelfCol;
      const oSelf = this;
      this.dataSource.aCallback['afterFill'] = function() {
        for (const i in oSelf.aMultiSelectFilters) {
          const oCurrentFilter = oSelf.aMultiSelectFilters[i];
          const aSelectedValues = oCurrentFilter.combo.cont.value.split('|');
          for (const j in aSelectedValues) {
            const iInd = oCurrentFilter.combo.getIndexByValue(aSelectedValues[j]);
            oCurrentFilter.combo.setChecked(iInd, true);
          }
        }

        if (oSelf.aDynSelectStatus.aSelectedItems.length == 0) {
          oSelf.aDynSelectFilters.forEach(entry => {
            oSelfCol = oSelf.childs[entry.index];
            $select2Elm = $(`#${entry.id}`);
            if (oSelfCol.opt.dynSelect.multiple)
              $select2Elm.find('option[value=""]').remove();
            oSelf.removeClear_dynSelect($select2Elm[0].nextSibling);
          });
        }

        // reapply dynSelect filters
        for (const i in oSelf.aDynSelectStatus.aSelectedItems) {
          const oCurrentFilter = oSelf.aDynSelectStatus.aSelectedItems[i];
          const oCurrentValues = oSelf.aDynSelectStatus.aSelectedValues[i];
          oSelf.aDynSelectFilters.forEach(entry => {
            if (entry.colname == i) {
              $select2Elm = $(`#${entry.id}`);
              oSelfCol = oSelf.childs[entry.index];
            }
          });

          if (oSelfCol.businessEntity)
            $($select2Elm).find('option').remove();

          oCurrentFilter.forEach(entry => {
            if (oSelfCol.businessEntity) {
              const newOption = new Option(entry.desc, entry.id);
              $(newOption).data('custom', entry.desc);
              $(newOption).data('bGrid', true);
              $select2Elm.append(newOption);
            } else {
              const elem = $select2Elm.find(`option:contains("${entry.id},${entry.listItemDesc}")`);
              if (oSelfCol.opt.dynSelect.tags && elem.length == 0) {
                const newOption = new Option(entry.listItemKey, entry.id);
                $(newOption).data('custom', entry.listItemText);
                $select2Elm.append(newOption);
              } else {
                $(elem).val(entry.id);
                $(elem).data('custom', entry.listItemText);
              }
            }
          });

          $select2Elm.find('option[value=""]').remove();
          $select2Elm.val(oCurrentValues);
          $select2Elm.trigger('change'); // Notify any JS components that the value changed
          $select2Elm.data('bClose', false);
          oSelf.removeClear_dynSelect($select2Elm);
        }
      };
    },

    _checkIfMultiSelectFilter: function(cColName) {
      for (const i in this.aMultiSelectFilters) {
        if (this.aMultiSelectFilters[i].colname == cColName)
          return true;
      }
      return false;
    },

    _checkIfDynSelectFilter: function(cColName) {
      for (const i in this.aDynSelectFilters) {
        if (this.aDynSelectFilters[i].colname == cColName)
          return true;
      }
      return false;
    },

    _checkIfDateRangeFilter: function(cColName) {
      for (const i in this.aDateRangeFilters) {
        if (this.aDateRangeFilters[i].colname == cColName)
          return true;
      }
      return false;
    },

    _syncFilters: function() {
      const oSelf = this;
      const oHdrFilters = this.filter;
      const akFilters = oSelf.dataSource.query.aFilters;

      oSelf.dataSource.query.mainOperator = 'and';

      for (const i in oHdrFilters) {
        const bMultiSelectFilter = oSelf._checkIfMultiSelectFilter(i);
        const bDynSelectFilter = oSelf._checkIfDynSelectFilter(i);
        const bDateRangeFilter = oSelf._checkIfDateRangeFilter(i);

        const oHdrElm = oSelf.dhx.getFilterElement(oSelf._initColIds.indexOf(i));

        // updates filter grid hdr field values

        const cHdrFilterVal = oHdrFilters[i];
        const bEnabled = ($(oHdrElm).attr('disabled') != 'disabled');


        // for multi select combo
        if (bMultiSelectFilter || bDynSelectFilter) {
          akFilters[i] = [];
          oSelf.aGridFilterFields[i] = [];
          oSelf.dataSource.query.setSubOperator(i, 'or');
          for (const j in cHdrFilterVal) {
            if (cHdrFilterVal[j] != '') {
              oSelf.aGridFilterFields[i].push({ attr: `${oSelf.dataSource.entityName}.${i}`, operator: 'eq', value: cHdrFilterVal[j], enabled: true });
              akFilters[i].push({ field: i, operator: 'eq', value: cHdrFilterVal[j] });
            }

          }

          // for normal select inputs here
        } else if (cHdrFilterVal != undefined && cHdrFilterVal != '' && bEnabled) {
          const value = cHdrFilterVal.replace(/\*/g, '');
          const count = (cHdrFilterVal.match(/\*/g) || []).length;
          let cOp;
          if (count == 1 && cHdrFilterVal.indexOf('*') == cHdrFilterVal.length - 1)
            cOp = 'startswith';
          else if (count == 1 && cHdrFilterVal.indexOf('*') == 0)
            cOp = 'endswith';
          else if (count == 2 && cHdrFilterVal[0] == '*' && cHdrFilterVal[cHdrFilterVal.length - 1] == '*')
            cOp = 'contains';
          else if (count >= 2)
            cOp = 'matches';
          else if (count == 0)
            cOp = 'eq';

          // check for filterOp gridcol attribute for predefined filter operator
          let cColOp = this.columns[i].opt.filterOp;
          if (cColOp && cColOp != '') {
            if (cColOp.substr(0, 1) == '$') {
              try {
                cColOp = cColOp.substr(1);

                // remove semicolon at the end to prevent bug
                if (cColOp.indexOf(';') != -1)
                  cColOp = cColOp.replace(/;/g, '');


                const self = this.columns[i];
                // here, self is a controller, not a dynObject
                cColOp = akioma.swat.evaluateCode({ code: `(${cColOp.substr(1)})`, controller: self, dynObj: self });
              } catch (e) {
                akioma.notification({ type: 'error', text: `Error executing filterOp attribute method for column "${i}".` });
              }
            }
            cOp = cColOp;
          }


          if (akFilters[i] == undefined)
            akFilters[i] = [];
          if (oSelf.aGridFilterFields[i] == undefined)
            oSelf.aGridFilterFields[i] = [];

          // depending on operator send specific value
          let cHdrVal = value;
          if (cOp == 'matches')
            cHdrVal = cHdrFilterVal;
          else
            cHdrVal = value;

          let aValues;
          if (bDateRangeFilter) {
            aValues = cHdrVal.split('|');
            akFilters[i] = [];
          }

          if (akFilters[i][0] == undefined) {
            oSelf.aGridFilterFields[i] = [];
            akFilters[i] = [];

            if (bDateRangeFilter) {
              const cLeftCal = aValues[0];
              if (cLeftCal) {
                oSelf.aGridFilterFields[i].push({ attr: `${oSelf.dataSource.entityName}.${i}`, operator: 'gt', value: cLeftCal, enabled: true });
                akFilters[i].push({ field: i, operator: 'gt', value: cLeftCal });
              }
              const cRightCal = aValues[1];
              if (cRightCal) {
                oSelf.aGridFilterFields[i].push({ attr: `${oSelf.dataSource.entityName}.${i}`, operator: 'lt', value: cRightCal, enabled: true });
                akFilters[i].push({ field: i, operator: 'lt', value: cRightCal });
              }
            } else {
              oSelf.aGridFilterFields[i].push({ attr: `${oSelf.dataSource.entityName}.${i}`, operator: cOp, value: cHdrVal, enabled: true });
              akFilters[i].push({ field: i, operator: cOp, value: cHdrVal });
            }

          } else {
            akFilters[i][0].operator = cOp;
            akFilters[i][0].value = cHdrVal;
            if (oSelf.aGridFilterFields[i][0]) {
              oSelf.aGridFilterFields[i][0].operator = cOp;
              oSelf.aGridFilterFields[i][0].value = cHdrVal;
            }
          }
          // when removing content of input filter in grid header clean up filters
        } else if (cHdrFilterVal != undefined && cHdrFilterVal == '' && oSelf.aGridFilterFields[i]) {
          if (oSelf.aGridFilterFields[i].length <= 1) {
            oSelf.aGridFilterFields[i] = [];
            akFilters[i] = [];
          } else if (oSelf.aGridFilterFields[i].length >= 2) {
            oSelf.aGridFilterFields[i].splice(0, 1);

            const cOrgVal = oSelf.aGridFilterFields[i][0].value;
            const cNewOp = oSelf.aGridFilterFields[i][0].operator;
            if (cNewOp == 'startswith')
              oHdrElm.value = `${cOrgVal}*`;
            else if (cNewOp == 'endswith')
              oHdrElm.value = `*${cOrgVal}`;
            else if (cNewOp == 'contains')
              oHdrElm.value = cOrgVal;
            else if (cNewOp == 'matches')
              oHdrElm.value = cHdrFilterVal;
            else if (cNewOp == 'equals')
              oHdrElm.value = cOrgVal;

            this.filter[i] = oHdrElm.value;
            if (!oSelf.aGridFilterFields[i][0].enabled)
              $(oHdrElm).attr('disabled', 'disabled');
            else
              $(oHdrElm).removeAttr('disabled');
          }
        }
      }
      oSelf.dataSource.query.buildQuery();
    },

    // clear filters *********************************
    FilterClear: function() {
      this.filter = {};
      const aCells = this.dhx.columnIds;
      for (const i in aCells) { // empty all filter fields
        const oGrid = this.dhx;
        const oFilter = oGrid.getFilterElement(i);
        if (oFilter) {
          if (this.aFilter[i] == '#logical_filter') {
            const oSelfCol = this.childs[i];
            const aExtendedFormat = this.aExtendedFormat[i];
            $(oFilter.firstChild).removeClass().addClass(aExtendedFormat['empty'].icon);
            oFilter.firstChild.style.cssText = aExtendedFormat['empty'].css;
            oSelfCol.cLogicalState = '';
            oFilter.value = '';
            this.filter[aCells[i]] = '';
          }
          if (oFilter.type == 'select-multiple' || oFilter.type == 'select-one') {
            $(oFilter).val('').trigger('change');
            const cCurMultiFilterColName = this.aDynSelectFilters[i].colname;
            this.aDynSelectStatus.aSelectedValues[cCurMultiFilterColName] = [];
            this.aDynSelectStatus.aSelectedItems[cCurMultiFilterColName] = [];
            const joinedVals = this.aDynSelectStatus.aSelectedValues[cCurMultiFilterColName].join('|');
            oFilter.dataset.selectVal = joinedVals;
          } else {
            if (Object.prototype.hasOwnProperty.call(oFilter, 'cont')) oFilter.unSelectOption(); // reset combo filters
            oFilter.value = '';
            this.filter[aCells[i]] = '';
          }
        }
      }
    },

    // get filter array ******************
    getFilterArray: function(plIncludeDetails) {
      const oFilter = this.filter,
        aFields = [],
        aValues = [],
        aOperators = [];

      if (plIncludeDetails == null)
        plIncludeDetails = false;

      for (const i in oFilter) {
        if (oFilter[i]) {
          if (oFilter[i].split) {
            const aValue = oFilter[i].split('|');

            aFields.push(i);
            if (plIncludeDetails == false)
              aValues.push(aValue[0]);
            else
              aValues.push(oFilter[i]);

            if (aValue.length > 1)
              aOperators.push(aValue[1]);
            else if (aValue[0].substr(0, 1) == '*')
              aOperators.push('MATCHES');
            else if (aValue[0].substr(aValue[0].length - 1, 1) == '*' && aValue[0].split('*').length == 1)// last char and no other
              aOperators.push('BEGINS');
            else if (aValue[0].indexOf('*') > 0)
              aOperators.push('MATCHES');
            else
              aOperators.push('=');
          }
        }
      }

      return {
        fields: aFields,
        values: aValues,
        operators: aOperators
      };
    },

    useFilterBT: function(cFilterHdl) {
      const aFilterDefinitions = this.prop.filterDefinitions,
        aFilters = this.prop.filter,
        oOpen = { filterGo: 1 };

      // clear filter fields before
      this.FilterClear();

      this.cSelectedFilterHdl = cFilterHdl;

      // now set filterfields in header
      aFilterDefinitions.forEach(() => {
        if (aFilterDefinitions[cFilterHdl]) {
          this.setFilterFields(aFilterDefinitions[cFilterHdl]);

          try {
            if (aFilters[cFilterHdl]) {
              const oFilterElm = this.dynObject.getLink('FILTER:SOURCE'),
                oCombo = oFilterElm ? oFilterElm.getField('rowsToBatch') : null,
                oRowsToBatch = oCombo ? oCombo.controller : null;
              oRowsToBatch.setFieldValue(aFilters[cFilterHdl].RowsToBatch);
            }
          } catch (e) {
            akioma.log.error('not found', e);
          }

          // save sort arrow to apply after grid loads
          this.oSortState = {
            state: true,
            iCol: this.sort.col,
            order: this.sort.dir
          };
        }
      });

      this.dataSource.setFilter(this.filter);
      this.dataSource.openQuery(oOpen);
    },

    // use filter ************************
    useFilter: function(oElm) {
      const oFilter = this.prop.filter,
        oOpen = { filterGo: 1 };

      // clear filter fields before
      this.FilterClear();

      this.aDynSelectStatus.aSelectedItems = [];
      this.aDynSelectStatus.aSelectedValues = [];


      // now set filterfields in header
      for (const i in oFilter) {
        if (oElm.clickId && oFilter[i].hdl == oElm.clickId) {
          this.setFilterFields(oFilter[i].definition);

          try {
            const oFilterElm = this.dynObject.getLink('FILTER:SOURCE'),
              oCombo = oFilterElm ? oFilterElm.getField('rowsToBatch') : null,
              oRowsToBatch = oCombo ? oCombo.controller : null;

            oRowsToBatch && oRowsToBatch.setFieldValue(oFilter[i].rowsToBatch);
            this.rowsToBatch(oFilter[i].rowsToBatch);
          } catch (e) {
            akioma.log.error('not found', e);
          }

          if (oFilter[i].sort) {

            this.sort = {
              col: this.dhx.getColIndexById(oFilter[i].sort.split(',')[0]),
              name: oFilter[i].sort.split(',')[0],
              dir: oFilter[i].sort.split(',')[1]
            };
            if (this.sort) {
              oOpen.sort = this.sort.name;
              oOpen.dir = this.sort.dir;

            }
            // save sort arrow to apply after grid loads
            this.oSortState = {
              state: true,
              iCol: this.sort.col,
              order: this.sort.dir
            };

          } else
            delete this.oSortState;

        }
      }

      this.dataSource.setFilter(this.filter);
      this.dataSource.openQuery(oOpen);
      this._renderMultiSelectStateAfterFill();
    },

    addFilterBTPromise: function() {
      const oSelf = this,
        oSource = this.dataSource,
        oFilter = this.getFilterArray(),
        deferred = $.Deferred();

      const oBox = dhtmlx.modalbox({
        title: 'Neuer Filter',
        text: 'Name für Filter: <input type=\'text\' class=\'w4-inputField\' />',
        buttons: [ 'Ok', 'Abbruch' ],
        callback: function(result) {
          if (result == 0) {
            const cName = $(oBox).find('input').val();
            if (!cName)
              return;
            const oData = {
              SDO: oSource.opt.SDO, // 'offerd',
              Grid: oSelf.opt.gridName, // 'offer_largeb',
              Name: cName, // 'APoTest2',
              Fields: oFilter.fields.join(','), // 'selfno,selfdesc',
              Values: oFilter.values.join('|'), // 'ak*|test*',
              Operators: oFilter.operators.join(','), // 'MATCHES,MATCHES',
              Sorting: oSelf.sort.name ? `${oSelf.sort.name},${oSelf.sort.dir}` : '' // 'datecreated,des',
            };
            oSelf.opt.rowsToBatch && (oData.rows = oSelf.opt.rowsToBatch);
            akioma.invokeServerTask({
              name: 'Akioma.Swat.FilterBT',
              methodName: 'CreateFilter',
              paramObj: { plcParameter: oData }
            }).done(oResult => {
              oSelf.aFtrHdls.push(oResult.plcParameter.FilterHdl);
              if (oSelf.panelHdrContextMenu)
                oSelf.panelHdrContextMenu.addNewSibling(null, oResult.plcParameter.FilterHdl, oResult.plcParameter.Name);

              akioma.notification({ type: 'info', text: `Adding new filter "${oResult.plcParameter.Name}" was successful.` });
              deferred.resolve(oResult);
              // add new filter next in header menu
            }).fail(() => {
              akioma.notification({ type: 'error', text: `Could not add new filter "${cName}".` });
              deferred.reject();
            });
          }
        }
      });

      return deferred.promise();
    },
    // add from businessTask new filter filter
    addFilterBT: function() {
      const oSelf = this,
        oFilter = this.getFilterArray();

      const promiseAddFilter = oSelf.addFilterBTPromise();
      promiseAddFilter.done(oResult => {
        oSelf.aFtrHdls.push(oResult.plcParameter.FilterHdl);
        if (oSelf.panelHdrContextMenu)
          oSelf.panelHdrContextMenu.addRadioButton('child', oSelf.panelHdrContextMenuParentID, oSelf.aFtrHdls.length - 1, oResult.plcParameter.FilterHdl, oResult.plcParameter.Name, 'filtersgroup', false);

        // add new filter
        oSelf.prop.filter.push({
          FilterHdl: oResult.plcParameter.FilterHdl,
          Description: oResult.plcParameter.Name

        });

        // add the filter definitions
        const aDefFields = oFilter.fields;
        const aDefValues = oFilter.values;
        const aDefOperators = oFilter.operators;
        if (aDefFields.length > 0) {
          oSelf.prop.filterDefinitions[oResult.plcParameter.FilterHdl] = [];
          for (const i in aDefFields) {
            oSelf.prop.filterDefinitions[oResult.plcParameter.FilterHdl].push({
              fieldname: aDefFields[i],
              operator: aDefOperators[i],
              value: aDefValues[i]
            });
          }
        }
      });
    },

    // add new filter (save) *******************
    filterAdd: function(oElm) {
      const oSelf = this,
        oToolbar = oElm.caller,
        oSource = this.dataSource,
        oFilter = this.getFilterArray();

      const oBox = dhtmlx.modalbox({
        title: 'Neuer Filter',
        text: 'Name für Filter: <input type=\'text\' class=\'w4-inputField\' />',
        buttons: [ 'Ok', 'Abbruch' ],
        callback: function(result) {
          if (result == 0) {
            const cName = $(oBox).find('input').val();
            if (!cName)
              return;

            const oData = {
              action: 'addFilter',
              SDO: oSource.opt.SDO,
              grid: oSelf.opt.gridName,
              Name: cName,
              Fields: oFilter.fields.join(','),
              Values: oFilter.values.join('|'),
              Operators: oFilter.operators.join(','),
              Sorting: oSelf.sort.name ? `${oSelf.sort.name},${oSelf.sort.dir}` : ''
            };

            oSelf.opt.rowsToBatch && (oData.rows = oSelf.opt.rowsToBatch);

            // add filter
            $.ajax({
              type: 'POST',
              async: false,
              url: '/akioma/getdata.xml',
              data: oData,
              dataType: 'json',
              success: function(data) {
                // validate data
                if (!data.status || !data.addFilter || !data.filterhdl)
                  return;


                oSelf.prop.filter.push(app.grid.convertFilter(oSelf, {
                  definition: [],
                  desc: data.addFilter,
                  hdl: data.filterhdl,
                  isclear: false,
                  isdefault: false,
                  issystem: false
                }));

                // add new combooption
                if (oToolbar) {
                  const oDhxTool = oToolbar.dhx;
                  if (oToolbar) {
                    oDhxTool.addListOption('tbfilterChooseFilter', data.filterhdl, oSelf.prop.filter.length, 'button', data.addFilter, '');
                    oDhxTool.setListOptionSelected('tbfilterChooseFilter', data.filterhdl);
                    oDhxTool.setItemText('tbfilterChooseFilter', data.addFilter);
                  }
                }
              },
              error: function(xhr, textStatus, errorThrown) {
                akioma.log.error(`Error getting data for filter ${oSource.opt.SDO}: ${textStatus} -> ${errorThrown}`);
              }
            });
          }
        }
      });
    },

    // filter save in businessTask
    saveFilterBT: function() {
      const oSelf = this,
        oSource = this.dataSource;

      const cFilter = this.cSelectedFilterHdl;
      // get selected filter
      if (cFilter) {
        const oFilter = oSelf.getFilterArray(),
          oData = {
            SDO: oSource.opt.SDO,
            Grid: oSelf.opt.gridName,
            FilterHdl: cFilter,
            Fields: oFilter.fields.join(','),
            Values: oFilter.values.join('|'),
            Operators: oFilter.operators.join(','),
            Sorting: oSelf.sort.name ? `${oSelf.sort.name},${oSelf.sort.dir}` : ''
          };

        oSelf.opt.rowsToBatch && (oData.rows = oSelf.opt.rowsToBatch);

        akioma.invokeServerTask({
          name: 'Akioma.Swat.FilterBT',
          methodName: 'UpdateFilter',
          paramObj: { plcParameter: oData }
        }).done(() => {
          // add the filter definitions
          const aDefFields = oFilter.fields;
          const aDefValues = oFilter.values;
          const aDefOperators = oFilter.operators;
          if (aDefFields.length > 0) {
            oSelf.prop.filterDefinitions[cFilter] = [];
            for (const i in aDefFields) {
              oSelf.prop.filterDefinitions[cFilter].push({
                fieldname: aDefFields[i],
                operator: aDefOperators[i],
                value: aDefValues[i]
              });
            }
          }
          akioma.notification({ type: 'info', text: 'Selected filter saved.' });
          // update filter
        });
      }
    },

    // filter save **************************
    filterSave: function(oElm) {
      // get button and toolbar
      const oToolbar = oElm.caller,
        oSource = this.dataSource,
        oSelf = this;

      // get actual filter handle
      const oDhxTool = oToolbar.dhx;

      const cFilter = oDhxTool.getListOptionSelected('tbfilterChooseFilter');
      if (cFilter) {
        const oFilter = this.getFilterArray(),
          oData = {
            action: 'setFilter',
            SDO: oSource.opt.SDO,
            grid: this.opt.gridName,
            Filter: cFilter,
            Fields: oFilter.fields.join(','),
            Values: oFilter.values.join('|'),
            Operators: oFilter.operators.join(','),
            Sorting: this.sort.name ? `${this.sort.name},${this.sort.dir}` : ''
          };

        oSelf.opt.rowsToBatch && (oData.rows = oSelf.opt.rowsToBatch);

        // save filter
        $.ajax({
          type: 'POST',
          url: '/akioma/getdata.xml',
          data: oData,
          dataType: 'json',
          success: function(data) {
            if (data.ok) {
              const oDef = $.grep(oSelf.prop.filter, oElm => oElm.hdl == cFilter);
              if (oDef[0]) {
                const oFilter = oDef[0];
                oFilter.definition = [];
                app.grid.convertFilter(oSelf, oFilter);
                oFilter.sort = oData.Sorting;
              }
            }
          },
          error: function(xhr, textStatus, errorThrown) {
            akioma.log.error(`Error getting data for filter ${oSource.opt.SDO}: ${textStatus} -> ${errorThrown}`);
          }
        });
      }
    },

    promiseRemoveFilterBT: function() {
      const oSelf = this,
        deferred = $.Deferred();

      // get selected filter
      const cFilter = this.cSelectedFilterHdl;
      if (cFilter) {
        akioma.message({
          type: 'confirm',
          title: 'Datensatz löschen',
          text: 'Soll der Filter wirklich gelöscht werden?',
          callback: function(result) {
            const oData = { FilterHdl: cFilter };
            if (result) {
              akioma.invokeServerTask({
                name: 'Akioma.Swat.FilterBT',
                methodName: 'DeleteFilter',
                paramObj: { plcParameter: oData }
              }).done(oResult => {
                if (cFilter)
                  oSelf.panelHdrContextMenu.removeItem(cFilter);
                deferred.resolve(oResult);
                akioma.notification({ type: 'info', text: 'Selected Filter Removed.' });
                // delete filter
              }).fail(() => {
                deferred.reject();
                akioma.notification({ type: 'error', text: 'Could not remove selected filter.' });
              });
            }

          }
        });

      }


      return deferred.promise();
    },

    // remove filter using BT
    removeFilterBT: function() {
      const oSelf = this;
      const promiseRemoveFilter = oSelf.promiseRemoveFilterBT();
      promiseRemoveFilter.done(() => {
        const cFilter = oSelf.cSelectedFilterHdl;
        if (cFilter)
          oSelf.panelHdrContextMenu.removeItem(cFilter);
      });
    },

    // filter delete **************************
    filterDelete: function(oElm) {
      const oSelf = this;
      // get button and toolbar
      const oToolbar = oElm.caller;
      const oSource = this.dataSource;

      // get actual filter handle
      const oDhxTool = oToolbar.dhx;
      const cFilter = oDhxTool.getListOptionSelected('tbfilterChooseFilter');
      if (cFilter) {
        akioma.message({
          type: 'confirm',
          title: 'Datensatz löschen',
          text: 'Soll der Filter wirklich gelöscht werden?',
          callback: function(result) {
            if (result) { // save filter
              $.ajax({
                type: 'POST',
                async: false,
                url: '/akioma/getdata.xml',
                data: `${'Action=deleteFilter'
                  + '&SDO='}${oSource.opt.SDO
                }&grid=${oSelf.opt.gridName
                }&Filter=${cFilter}`,
                dataType: 'json',
                success: function() {
                  // remove filter view and definition
                  oDhxTool.removeListOption('tbfilterChooseFilter', cFilter);
                  let iNum;
                  for (iNum in oSelf.prop.filter) {
                    // do nothing
                  }
                  if (iNum > -1)
                    oSelf.prop.filter.splice(iNum, 1);

                  // select first filter
                  if (oSelf.prop.filter.length > 0) {
                    oDhxTool.setListOptionSelected('tbfilterChooseFilter', oSelf.prop.filter[0].hdl);
                    oDhxTool.setItemText('tbfilterChooseFilter', oSelf.prop.filter[0].desc);
                  }
                },
                error: function(xhr, textStatus, errorThrown) {
                  akioma.log.error(`Error getting data for filter ${oSource.opt.SDO}: ${textStatus} -> ${errorThrown}`);
                }
              });
            }
          }
        });
      }
    },

    // setts currently selected filter
    setDefFilterBT: function() {
      const oSelf = this,
        oSource = this.dataSource;
      const cFilter = this.cSelectedFilterHdl;

      if (cFilter) {
        //  {SDO: 'offerd', Grid: 'offer_largeb', FilterHdl: '<FilterHdl_from_create>'}
        const oData = {
          SDO: oSource.opt.SDO,
          Grid: oSelf.opt.gridName,
          FilterHdl: cFilter
        };
        return akioma.invokeServerTask({
          name: 'Akioma.Swat.FilterBT',
          methodName: 'SetDefaultFilter',
          paramObj: { plcParameter: oData }
        }).done(() => {
          // set filter as default filter
          akioma.notification({ type: 'info', text: 'Selected filters has been set as default filter.' });
        });
      }
    },

    // filter standard ***********************
    filterStandard: function(oElm) {
      // get button and toolbar
      const oToolbar = oElm.caller,
        oSource = this.dataSource,
        oDhxTool = oToolbar.dhx,
        oSelf = this;

      const cFilter = oDhxTool.getListOptionSelected('tbfilterChooseFilter');

      if (cFilter) {
        // save filter
        $.ajax({
          type: 'POST',
          async: false,
          url: '/akioma/getdata.xml',
          data: {
            Action: 'defaultFilter',
            SDO: oSource.opt.SDO,
            grid: this.opt.gridName,
            Filter: cFilter
          },
          dataType: 'json',
          success: function(data) {
            if (data.ok) {
              const oDef = $.grep(oSelf.prop.filter, oElm => {
                if (oElm.isdefault) {
                  oElm.isdefault = false;
                  oElm.desc = oElm.desc.substring(2);
                  oDhxTool.setListOptionText('tbfilterChooseFilter', oElm.hdl, oElm.desc);
                }
                return oElm.hdl == cFilter;
              });
              if (oDef[0]) {
                oDef[0].isdefault = true;
                oDef[0].desc = `# ${oDef[0].desc}`;
                oDhxTool.setListOptionText('tbfilterChooseFilter', cFilter, oDef[0].desc);
                oDhxTool.setItemText('tbfilterChooseFilter', oDef[0].desc);
              }
            }
          },
          error: function(xhr, textStatus, errorThrown) {
            akioma.log.error(`Error getting data for filter ${oSource.opt.SDO}: ${textStatus} -> ${errorThrown}`);
          }
        });
      }
    },

    // setts currently selected filter
    promiseRenameFilterBT: function(cText) {
      const oSelf = this,
        deferred = $.Deferred();

      // get selected filter here
      const cFilter = this.cSelectedFilterHdl;
      // get button and toolbar

      if (cFilter) {
        const oBox = dhtmlx.modalbox({
          title: 'Filter umbenennen',
          text: `Name für Filter: <input type='text' value='${cText || oSelf.panelHdrContextMenu.getItemText(cFilter)}' class='w4-inputField' />`,
          buttons: [ 'Ok', 'Abbruch' ],
          callback: function(result) {
            const cName = $(oBox).find('input').val();
            if (result != 0 || !cName)
              return;
            if (cFilter) {
              // save filter
              const oData = { FilterHdl: cFilter, Name: cName };
              akioma.invokeServerTask({
                name: 'Akioma.Swat.FilterBT',
                methodName: 'RenameFilter',
                paramObj: { plcParameter: oData }
              }).done(oResult => {
                if (oSelf.panelHdrContextMenu)
                  oSelf.panelHdrContextMenu.setItemText(cFilter, oResult.plcParameter.Name);
                deferred.resolve(oResult);
                akioma.notification({ type: 'info', text: 'Filter has been renamed successfully.' });
                // rename filter
              }).fail(() => {
                deferred.reject();
                akioma.notification({ type: 'info', text: 'Error renaming slected filter.' });
              });
            }
          }
        });
      }

      return deferred.promise();
    },

    // setts currently selected filter
    renameFilterBT: function() {
      const oSelf = this;

      // get selected filter here
      const cFilter = this.cSelectedFilterHdl;
      // get button and toolbar

      if (cFilter) {
        const promiseRenameFilter = oSelf.promiseRenameFilterBT();
        promiseRenameFilter.done(oResult => {
          oSelf.panelHdrContextMenu.setItemText(cFilter, oResult.plcParameter.Name);
        });
      }
    },

    // filter rename ************************** deprecated
    filterRename: function(oElm) {
      const oSelf = this;

      // get button and toolbar
      const oBox = dhtmlx.modalbox({
        title: 'Filter umbenennen',
        text: 'Name für Filter: <input type=\'text\' class=\'w4-inputField\' />',
        buttons: [ 'Ok', 'Abbruch' ],
        callback: function(result) {
          const cName = $(oBox).find('input').val();
          if (result != 0 || !cName)
            return;

          const oToolbar = oElm.caller,
            oSource = oSelf.dataSource,
            oDhxTool = oToolbar.dhx,
            cFilter = oDhxTool.getListOptionSelected('tbfilterChooseFilter');

          if (cFilter) {
            // save filter
            $.ajax({
              type: 'POST',
              async: false,
              url: '/akioma/getdata.xml',
              data: `${'Action=renameFilter'
                + '&SDO='}${oSource.opt.SDO
              }&grid=${oSelf.opt.gridName
              }&Filter=${cFilter
              }&Name=${cName}`,
              dataType: 'json',
              success: function(data) {
                if (data.ok == true) {
                  const oFilters = oSelf.prop.filter;
                  let defaultOpt = false;
                  for (const i in oFilters) {
                    if (oFilters[i].hdl == cFilter)
                      defaultOpt = oFilters[i].isdefault;

                  }
                  // if cText from default
                  if (defaultOpt)
                    oDhxTool.setListOptionText('tbfilterChooseFilter', cFilter, `# ${cName}`);
                  else
                    oDhxTool.setListOptionText('tbfilterChooseFilter', cFilter, cName);

                  // if iText from default
                  if (defaultOpt)
                    oDhxTool.setItemText('tbfilterChooseFilter', `# ${cName}`);
                  else
                    oDhxTool.setItemText('tbfilterChooseFilter', cName);

                }
              },
              error: function(xhr, textStatus, errorThrown) {
                akioma.log.error(`Error getting data for filter ${oSource.opt.SDO}: ${textStatus} -> ${errorThrown}`);
              }
            });
          }
        }
      });
    },

    // filter info **************************
    WindowFilter: function() {},

    // edit cell *****************************
    editCell: function(iStage, rId, iCol, nValue, oValue) {
      const gridCell = this.dhx.cells(rId, iCol);
      const bCheckBoxEdit = (iStage == 1 && gridCell.isCheckbox());
      if (bCheckBoxEdit)
        nValue = false;

      if (iStage === 1 && bCheckBoxEdit) {
        if (!bCheckBoxEdit)
          nValue = false;
        else
          nValue = true;

      }
      this.dynObject.akEvent = {
        iStage,
        rId,
        iCol,
        nValue: gridCell.getValue(),
        oValue
      };

      const column = this.childs[iCol];

      if (iStage == 2)
        this.cellEdited = null;
      else {
        this.cellEdited = {
          id: rId,
          index: iCol
        };
      }

      let isValidCellUpdate = true;
      if (column.opt.EventAkValidate) {
        /**
         * Client side code executed cell value is changed
         * @event ak_datagrid#EventAkValidate
         * @type {object}
         */
        isValidCellUpdate = callReturnAkiomaCode(this.dynObject, column.opt.EventAkValidate);
      }

      return isValidCellUpdate;
    },

    // cell checked
    cellChanged: function(rId, iIndex, nValue) {
      // select row first
      this.dhx.selectRowById(rId, false, false);
      const label = this.dhx.getColumnId(iIndex);
      const newval = Object.assign({}, this.propStore.item(rId));
      newval[label] = nValue;
      this.propStore.update(rId, newval);
      return true;
    },

    // delete record
    DeleteDB: function() {
      const oSrc = this.dataSource,
        oGrid = this.dhx;

      akioma.message({
        type: 'confirm',
        title: 'Datensatz löschen',
        text: 'Sollen die Daten wirklich gelöscht werden?',
        callback: function(result) { // set
          if (result) {
            oSrc.setChanged(true, 'deleted');

            const cId = oGrid.getSelectedRowId();

            // update record sends record to server to execute delete
            oSrc.updateRecord({});

            oSrc.dhx.remove(cId);
            oGrid.deleteRow(cId);
          }
        }
      });
    },

    // row select ******************************
    rowSelect: function(cRowId, cIndex) {
      const oSelf = this;

      $('div.dhtmlxcalendar_in_input').css('display', 'none');

      // set active panel state
      try {
        const oParentPanel = this.getAncestor('panel');
        if (oParentPanel != null)
          oParentPanel.setActivePanelState();
      } catch (e) {
        akioma.log.error(e);
      }

      if (this.contextMenuId) {
        const oGrid = this.dhx;
        const oCell = oGrid.cells(cRowId, cIndex);
        let iExtra = 0;

        // if in form
        if (oSelf.cSearchInputID)
          iExtra += $(`#${oSelf.cSearchInputID}`).height();


        const iTop = $(oGrid.hdr).height() + iExtra + $(oCell.cell).parent()[0].offsetTop + ($(oCell.cell).parent().height() / 2) - $(oCell.cell).closest('.objbox').scrollTop();
        $(`#${this.contextMenuId}`).attr('rowid', cRowId).css({ top: iTop });
      }

      // save Selection if required
      if (oSelf.dataSource && oSelf.opt.keepSelection) {

        const cRows = this.dhx.getSelectedRowId();
        const aRowIds = cRows.split(',');

        const aVals = [];
        let value = '';

        for (const r in aRowIds) {
          if (this.dataSource.view == 'businessEntity2')
            return;
          const cFieldVal = this.dataSource.getFieldFromId(oSelf.dataSource.opt.identifier, aRowIds[r]);
          aVals.push(cFieldVal);
        }
        value = aVals.join(',');

        if (value != '')
          this.setKeepSelection({ identifier: oSelf.dataSource.opt.identifier, value: value });

      }

      if (this.opt.name == 'tranListB') {
        const cTranType = this.dataSource.dynObject.getValue('reltype');
        if (cTranType == 'Desc') {
          this.dynObject.container.getObject2('itTextBase').controller.parent.dhx.showView('dummy');
          this.dynObject.container.getObject2('itTextTarget').controller.parent.dhx.showView('dummy');
        } else {
          this.dynObject.container.getObject2('itTextBase').controller.parent.dhx.showView('def');
          this.dynObject.container.getObject2('itTextTarget').controller.parent.dhx.showView('def');
        }
      }

      const cObjectID = this.opt.name + this.opt.linkid;
      app.messenger.publish({
        link: {
          LinkName: 'DISPLAY',
          LinkType: 'TRG',
          ObjName: cObjectID
        },
        method: 'dataAvailable',
        caller: this
      });


      if (oSelf.opt.EventRowSelected) {
        /**
         * Client side code executed when a row is selected (selected by the user to perform an action on it), e.g. by single click
         * @event ak_datagrid#EventRowSelected
         * @type {object}
         */
        app.controller.callAkiomaCode(oSelf, oSelf.opt.EventRowSelected);
      }

      return true;
    },
    /**
     * Method for setting the selection details
     * @private
     * @instance
     * @memberOf ak_datagrid2
     * @param {object} oObj
     */
    setKeepSelection: function(oObj) {
      this.oKeepSelectionData = oObj;
    },
    /**
     * Method used for setting the keepSelection data after a refresh of data
     * @private
     * @instance
     * @memberOf ak_datagrid2
     * @param {object} oObj
     * @return {void}
     */
    keepSelection: function() {
      if (this.oKeepSelectionData) {
        const oSettings = this.oKeepSelectionData;
        const identifier = oSettings.identifier;
        const value = oSettings.value;
        const aVals = value.split(',');
        for (const v in aVals) {
          const cId = this.dataSource.getIdFrom(identifier, aVals[v]);


          if (cId)
            this.dhx.selectRowById(cId, true, true, true);

        }
      }
    },


    // showfolder ******************************
    showFolder: function(cRowId, cIndex) {

      // check for folder
      const cFolder = this.cols.folder[cIndex];
      if (cFolder) {

        // get column
        const oCol = this.childs[cIndex];
        if (oCol) {

          // call dialog
          app.controller.launchContainer({
            proc: 'launchContainer.r',
            para: `RunFile=${cFolder}&SelfHdl=${cRowId}&Page=0,1`,
            data: true,
            extLink: cRowId,
            self: this
          });
        }
      }
    },

    // row dblclick *******************************
    rowDblClick: function(iRow) {
      const oSource = this.dataSource.dhx,
        oItem = oSource.item(iRow),
        cRowId = oItem[this.dataSource.opt.identifier];

      // set selected index in datasource
      this.dataSource.setIndex(iRow);

      // check if we are in popup
      const oPopup = this.getAncestor('popup');
      if (oPopup && oPopup.transferField) {

        // get target
        oPopup.transferField({
          dataSource: this.dataSource,
          rowId: cRowId
        });

        dhtmlx.delay(() => {
          oPopup.close();
        });
        return true;
      }

      this.fileOpen();

      return true;
    },

    // open file ****************************
    fileOpen: function() {
      // get actual element
      const oGrid = this.dhx;
      const cRowId = oGrid.getSelectedRowId();

      let cHdl = '';

      // if rowid is valid -> call routine
      if (cRowId) {
        const oSource = this.dataSource.dhx;
        const oItem = oSource.item(cRowId);
        cHdl = oItem[this.dataSource.opt.identifier];

        // check if we are in popup
        const oPopup = this.getAncestor('popup');
        if (oPopup && oPopup.transferField) {

          // get target
          oPopup.transferField({
            dataSource: this.dataSource,
            rowId: cRowId
          });

          dhtmlx.delay(() => {
            oPopup.close();
          });
          return true;
        }

        // check if we have to run a program
        const cAction = this.opt.action;
        if (cAction == 'RUN') {
          // activate window
          app.controller.launchContainer({
            self: this,
            proc: this.opt.actionLink,
            para: `SelfHdl=${cHdl}&Page=0,1`,
            extLink: cHdl,
            data: true
          });
        }
      }
    },

    // add row ****************************
    recordAdd: function() {
      const oGrid = this.dhx;

      // add new record in datasource
      this.dataSource.addRecord({});

      // get actual record
      const cRow = `${this.dataSource.dhx.getCursor()}`;
      oGrid.selectRowById(cRow, false, true);

      // open cells for all create fields
      $.each(this.childs, function(i) {
        oGrid.setCellExcellType(cRow, i, this.opt.createType);
      });

      // get in edit mode
      oGrid.selectCell(cRow, 0);
      oGrid.editCell();
    },

    // save row *******************************
    recordSave: function() {
      // stop edit mode
      this.dhx.editStop();

      // send update to datasource
      this.dataSource.updateRecord({});
    },

    // copy row **********************************
    Copy: function() {
      // get selected element
      const oGrid = this.dhx;
      const cRowId = oGrid.getSelectedRowId();

      // if valid rowid -> save it for copy
      if (cRowId)
        this.copyGrid = cRowId;

    },

    // paste row **********************************
    Paste: function() {

      // check if we do have a copied row
      const cCopyId = this.copyGrid;
      if (cCopyId) {

        // copy row contents to new selected row
        // get selected element
        const oGrid = this.dhx;
        const cRowId = oGrid.getSelectedRowId();

        const oSrc = this.dataSource;
        if (oSrc) {
          const aException = new Array();

          // get exceptions -> copy only fields which are readable
          oGrid.forEachCell(cRowId, (cell, index) => {
            if (oGrid.getColType(index) == 'ron')
              aException.push();
          });
          oSrc.copyRecord(cRowId);
          oSrc.copyContent(cCopyId, `${cRowId}Upd`);

        }
      }
    },

    // add record ********************
    fileAddBrowser: function() {

      // check if we have to call a dialog
      if (this.opt.recordCreate) {
        // call dialog
        app.controller.launchContainer({
          proc: `${this.opt.recordCreate}.r`,
          data: true,
          self: this
        });
      } else { // if not -> add row
        // check if we have to run a program
        const cAction = this.opt.action;
        if (cAction == 'RUN') {

          // activate window
          app.controller.launchContainer({
            self: this,
            proc: this.opt.actionLink,
            para: 'SelfHdl=&Page=0,1',
            add: true
          });
        }
      }

    },

    // drag in ***********************
    dragIn: function() {
      return true;
    },

    // drag in ***********************
    dragOut: function() {
      return true;
    },

    // rows to batch
    rowsToBatch: function(oElm) {
      const rows = (typeof oElm == 'object') ? oElm.clickId : oElm;
      this.opt.rowsToBatch = rows;
    },

    // destroy *********************************
    destroy: function() {
      if (this.dhx.gridCalendar) {
        this.dhx.gridCalendar.unload();
        this.dhx.gridCalendar = null;
      }
      Mousetrap.unbind(`enter.${this.opt.id}`);
      this.oHdrBoxMouseTrap.reset();
      delete this.oHdrBoxMouseTrap;

      if (this.dhx.destructor)
        this.dhx.destructor();
    }
  });
})(jQuery, jQuery);
