// global context variables
/* eslint-disable no-unused-vars */
const ws = null;
const Socket = null;
const cURL = `ws://${app.sessionData.serverURL}:5050`;
/* eslint-enable no-unused-vars */

launchByHdl = function(cHdl) {
  app.controller.launchContainer({
    proc: 'launchContainer.r',
    para: `SelfHdl=${cHdl}&Page=*`,
    data: true,
    extLink: cHdl,
    self: this
  });
};

akioma.messaging.sendRecord = function(self) {
  const oTree = self.container.caller.container.getObject('itTree'),
    oForm = self.getLink('TOOLBAR:TARGET'),
    oSDO = self.container.caller.parent.getLink('PRIMARYSDO:TARGET');

  oForm.controller.dhx._updateValues();

  let cRecHdl = oSDO.getValue('selfhdl');
  if (isValidObj(oTree))
    cRecHdl += `,${oTree.getValue('selfhdl')}`;

  app.controller.callServerMethod('stubs/sendRecord.p',
    [
      { type: 'iCHAR', value: cRecHdl },
      { type: 'iTT', value: oForm.getAllValues() },
      { type: 'oCHAR', name: 'cStatus' }
    ]);

};

akioma.messaging.checkConnectionStatus = function() {
  if (akioma.connectionBtn && akioma.socketConnection.isConnected())
    $('.system-header .connectionBtn').css('background-color', 'green');
  else
    $('.system-header .connectionBtn').css('background-color', 'red');
};

akioma.messaging.bindEvents = function() {

  if (!akioma.socketConnection.exists())
    return;

  akioma.socket.on('updateMessage', data => {
    akioma.NotificationMessage.updateVueNotification(data);
  });

  akioma.socket.on('removeMessage', data => {
    akioma.NotificationMessage.removeVueNotification(data.id);
  });

  akioma.socket.on('showMessage', data => {
    akioma.showMessage(data);
  });

  akioma.socket.on('openContainer', function(data) {
    let cParamHdl, cCustomer, cContact, cContactHdl,
      lAutoAdd = false,
      cForeignKeyProvider = '';

    if (data.params && data.params.selfHdl)
      data.selfHdl = data.params.selfHdl;

    if (data.container == '')
      launchByHdl(data.selfHdl);
    else {
      if (data.container == 'offerNewG')
        data.container = 'OfferCreateDialog';
      if (data.container == 'OfferCreateDialog') {
        if (data.params) {
          cCustomer = data.params.customer;
          cContact = data.params.contact;
          if (data.params.contactHdl && data.params.contactHdl != 'null')
            cContactHdl = data.params.contactHdl;
        }
        if (isValidHdl(cContactHdl))
          cParamHdl = cContactHdl;
        else if (cContact)
          cParamHdl = cContact;
        else if (cCustomer)
          cParamHdl = cCustomer;
        else
          cParamHdl = '';

        lAutoAdd = true;
        cForeignKeyProvider = `$ [{name: 'SourceHdl', value:'${cParamHdl}'}]`;
      }
      app.controller.launchContainer({
        proc: `${data.container.toLowerCase()}.r`,
        para: `SelfHdl=${data.selfHdl}&Page=0,1,2`,
        self: this,
        extLink: data.selfHdl,
        allowMultipleInstances: true,
        data: true,
        containerUserData: data.params,
        autoAdd: lAutoAdd,
        foreignKeyProvider: cForeignKeyProvider
      });
    }
  });

  akioma.socket.on('setUIvalue', data => {
    akioma.config.setUIvalue(data.container, data.control, data.value);
  });

  akioma.socket.on('applyUIattribute', data => {
    switch (data.attribute.toLowerCase()) {
      case 'visible':
        if (data.value == 'false')
          akioma.config.hideControl(data.container, data.control);
        else
          akioma.config.showControl(data.container, data.control);
        break;
      case 'selected':
        akioma.config.setSelected(data.container, data.control, data.option, data.value);
        break;
      case 'mandatory':
        akioma.config.setMandatory(data.container, data.control, data.value);
        break;
      case 'notetext':
        akioma.config.setNote(data.container, data.control, data.value);
        break;
    }
  });

  akioma.socket.on('renderContainer', data => {
    const oDialog = app.controller.parseProc(data.codeToRender, oDhxTree);
    akioma.config.instance = oDialog.dynObject;
  });
};

akioma.showMessage = function(opts, cType) {

  cType = cType || opts.showType || opts.type || 'info';

  opts.vue = true; // show vue notification instead of dhtmlx one

  opts.type = cType;
  opts.lifetime = opts.lifetime || 10000;
  opts.expire = opts.expire || 10000;

  akioma.notification(opts);
};

akioma.messaging.info = function(cMessage) {

  akioma.notification({ type: 'info', text: cMessage, lifetime: -1 });
};

akioma.messaging.error = function(cMessage) {
  cMessage = `${cMessage}<br/><br/><a target='blank' href='/doc/Angebote/72/2011/1173EAK08.doc'>Open document</a>`;

  akioma.notification({ type: 'error', text: cMessage, lifetime: -1 });
};

akioma.messaging.documentInfo = function(cTop, cDoc, cDocDesc, cBottom) {
  let cMessage = cTop;
  const cTarget = `blank${Math.ceil(Math.random() * 1000)}`;
  cMessage += `<br/><a target='${cTarget}' href='${cDoc}'>${cDocDesc}</a>`;
  if (cBottom != '')
    cMessage += `<br/>${cBottom}`;

  akioma.notification({ type: 'info', text: cMessage, lifetime: -1 });
};

akioma.launchContainer = function(cName, cHdl) {
  return app.controller.launchContainer({
    containerName: cName,
    repositionTo: cHdl,
    fetchOnInit: true
  });

};

akioma.showServerMessage = function(pcMsg) {
  try {
    const cDelim = String.fromCharCode(4);

    const cErrorInfo = pcMsg.split(cDelim);
    let cMsg = '';
    let cValue = '';
    if (cErrorInfo[0].indexOf('^') > 0) {
      cMsg = cErrorInfo[0].split('^');
      cValue = cMsg[3];
    } else
      cValue = cErrorInfo[0];

    akioma.message({
      type: 'alert-error',
      title: akioma.tran('msg.serverFeedback', { defaultValue: 'Server-Rückmeldung' }),
      text: akioma.tran(`msg.${cErrorInfo[1]}`, { defaultValue: cValue })
    });
  } catch (err) {
    akioma.log.error(`error displaying error: ${err.description}  ${pcMsg}`);
  }
};
