const originalMethod = eXcell_co.prototype.setValue;

eXcell_co.prototype.setValue = function(val) {
  if (val == null)
    val = '';

  originalMethod.bind(this)(val);
};

dhtmlXForm.prototype.items.checkbox.doAddLabel = function(item, data) {

  const t = document.createElement('DIV');
  t.className = `dhxform_label ${data.labelAlign}`;

  if (data.wrap == true) t.style.whiteSpace = 'normal';

  if (item._ll)
    item.insertBefore(t, item.firstChild);
  else
    item.appendChild(t);


  if (typeof (data.tooltip) != 'undefined') t.title = data.tooltip;

  t.innerHTML = `${'<div class=\'dhxform_label_nav_link\' ' +
			'onfocus=\'if(this.parentNode.parentNode._updateImgNode)this.parentNode.parentNode._updateImgNode(this.parentNode.parentNode,true);this.parentNode.parentNode._doOnFocus(this.parentNode.parentNode);\' ' +
			'onblur=\'if(this.parentNode.parentNode._updateImgNode)this.parentNode.parentNode._updateImgNode(this.parentNode.parentNode,false);this.parentNode.parentNode._doOnBlur(this.parentNode.parentNode);\' ' +
			'onkeypress=\'var e=event||window.arguments[0];if(e.keyCode==32||e.charCode==32){e.cancelBubble=true;if(e.preventDefault)e.preventDefault();else e.returnValue=false;_dhxForm_doClick(this,"mousedown");return false;}\' ' +
			'onkeyup=\'var e=event||window.arguments[0];this.parentNode.parentNode._doOnKeyUpDown("onKeyUp",e);\' ' +
			'onkeydown=\'var e=event||window.arguments[0];this.parentNode.parentNode._doOnKeyUpDown("onKeyDown",e);\' '}${
    window.dhx4.isIPad ? 'ontouchstart=\'var e=event;e.preventDefault();_dhxForm_doClick(this,"mousedown");\' ' : ''
  }role='link'>${data.label}${data.info ? '<span class=\'dhxform_info\'></span>' : ''}${item._required ? '<span class=\'dhxform_item_required\'>*</span>' : ''}</div>`;

  // AKIOMA update, removed inline fixed width
  // if (!isNaN(data.labelWidth)) t.firstChild.style.width = parseInt(data.labelWidth)+"px";
  // if (!isNaN(data.labelHeight)) t.firstChild.style.height = parseInt(data.labelHeight)+"px";

  if (!isNaN(data.labelLeft)) t.style.left = `${parseInt(data.labelLeft)}px`;
  if (!isNaN(data.labelTop)) t.style.top = `${parseInt(data.labelTop)}px`;
};

dhtmlXForm.prototype.showInfoButton = function(name) {
  const fieldDiv = akioma.getDhxFormElement(this, name);
  const headerDiv = $(fieldDiv).find('.dhxform_label')[0];
  const $label = $(fieldDiv).find('label');
  let innerHtml = $label.html();

  if (!innerHtml.includes('<span class="dhxform_info"></span>')) {
    innerHtml += '<span class="dhxform_info"></span>';
    $label.html(innerHtml);
    headerDiv.onclick = function(event) {
      const target = event.target || event.srcElement;
      if (typeof (target.className) != 'undefined' && target.className == 'dhxform_info') {
        this.parentNode.callEvent('onInfo', [ this.parentNode._idd, event ]);
        event.cancelBubble = true;
        if (event.preventDefault) event.preventDefault(); else event.returnValue = false;
        return false;
      }
    };
  }
};

dhtmlXForm.prototype.hideInfoButton = function(name) {
  const fieldDiv = akioma.getDhxFormElement(this, name);
  const headerDiv = $(fieldDiv).find('.dhxform_label')[0];
  const $label = $(fieldDiv).find('label');
  let innerHtml = $label.html();

  if (innerHtml.includes('<span class="dhxform_info"></span>')) {
    innerHtml = innerHtml.replace('<span class="dhxform_info"></span>', '');
    $label.html(innerHtml);
    headerDiv.onclick = null;
  }
};

dhtmlXForm.prototype.items.checkbox.enable = function(item) {
  if (String(item.className).search('disabled') >= 0) item.className = String(item.className).replace(/disabled/gi, '');
  item._enabled = true;
  item.childNodes[item._ll ? 0 : 1].childNodes[0].removeAttribute('disabled');
  this.doCheckValue(item);
};
dhtmlXForm.prototype.getFormData = function(p0, only_fields) {
  const r = {};
  try {
    for (const a in this.itemPull) {
      const i = this.itemPull[a]._idd;
      const t = this.itemPull[a]._type;
      if (t == 'ch')
        r[i] = this.getItemValue(i);

      if (t == 'ra' && !r[this.itemPull[a]._group]) r[this.itemPull[a]._group] = this.getCheckedValue(this.itemPull[a]._group);
      if (t in { se: 1, ta: 1, pw: 1, hd: 1, tp: 1, fl: 1, calendar: 1, combo: 1, editor: 1, colorpicker: 1, fileSelector: 1, iconpicker: 1 }) r[i] = this.getItemValue(i, p0);
      // check for custom cell
      if (this[`getFormData_${t}`]) r[i] = this[`getFormData_${t}`](i);
      // merge with files/uploader
      if (t == 'up') {
        const r0 = this.getItemValue(i);
        for (const a0 in r0) r[a0] = r0[a0];
      }
      //
      if (this.itemPull[a]._list) {
        for (let q = 0; q < this.itemPull[a]._list.length; q++) {
          const k = this.itemPull[a]._list[q].getFormData(p0, only_fields);
          for (const b in k) r[b] = k[b];
        }
      }
    }
    // collecr hId userdata
    if (!only_fields && this.hId && this._userdata[this.hId]) {
      for (const a in this._userdata[this.hId])
        if (!r[a]) r[a] = this._userdata[this.hId][a];

    }
  } catch (e) {
    akioma.log.error('Error in getFormData: ', e);
  }

  return r;
};

const ExtendedItemsInputRenderer = dhtmlXForm.prototype.items.input.render;
dhtmlXForm.prototype.items.input.render = function(item, data) {
  try {
    if (data.userdata)
      item.akElm = $.getObjectByName({ id: data.userdata.id });
  } catch (e) {
    akioma.log.warn(e);
  }
  return ExtendedItemsInputRenderer.call(this, item, data);
};

const ExtendedItemsPasswordRenderer = dhtmlXForm.prototype.items.password.render;
dhtmlXForm.prototype.items.password.render = function(item, data) {
  try {
    item.akElm = $.getObjectByName({ id: data.userdata.id });
  } catch (e) {
    akioma.log.warn(e);
  }
  return ExtendedItemsPasswordRenderer.call(this, item, data);
};

dhtmlXForm.prototype.items.input.getValue = function(item) {
  // update value if item have focus
  let f = item.getForm();
  if (f._formLS && f._formLS[item._idd] != null) this.updateValue(item);
  f = null;
  // in case of autocomplete search no akElm.
  if (item.akElm) {
    const akEvent = item.akElm.dynObject.akEvent;
    if (akEvent && akEvent.currentValue === null && item._value === '') return null;
  }
  // str/null only
  return (typeof (item._value) != 'undefined' ? item._value : '');
};

// var ExtendedItemsInputSetValue = dhtmlXForm.prototype.items.input.setValue;
dhtmlXForm.prototype.items.input.setValue = function(item, value) {
  try {
    if (value && item) {
      if (String(value).length > 0)
        $(item).addClass('active');
    }
  } catch (e) {
    akioma.log.error(e);
  }

  if (item.akElm && item.akElm.dynObject && (!item.akElm.dynObject.akEvent || value != item.akElm.dynObject.akEvent.currentValue)) {
    const akEvent = {};
    akEvent.currentValue = value;
    akEvent.currentValueNative = value;
    akEvent.lastValue = item.akElm.dynObject.akEvent ? item.akElm.dynObject.akEvent.currentValue : undefined;
    item.akElm.dynObject.akEvent = akEvent;
  }

  item._value = (typeof (value) != 'undefined' ? value : '');
  let v;
  if (item._value === null)
    v = null;
  else
    v = (String(item._value) || '');
  let k = item.childNodes[item._ll ? 1 : 0].childNodes[0];

  // check if formatting available
  if (item._df != null) v = window.dhx4.template._getFmtValue(v, item._df);

  if (k.value != v) {
    k.value = v;
    // auto unmask input with extendedFormat on blur
    if (item.akElm && item.akElm.opt.extendedFormat)
      $(item).find('input').trigger('blur');
    item.getForm()._ccReload(item._idd, v);
  }

  k = null;

  if (item.akElm && item.akElm.dhx && item.akElm.form.akElm.bFocused && item.akElm.dynObject.akEvent.lastValue !== undefined)
    this._validate(item);
};

const ExtendedItemsInputValidate = dhtmlXForm.prototype.items.input._validate;
dhtmlXForm.prototype.items.input._validate = function(item) {
  if (item.akElm) {
    const oForm = item.akElm.form.akElm;
    const fieldEnabled = oForm.getFormFieldEnabled(item.akElm.opt.name);
    if (!fieldEnabled)
      return true;


    const fieldHidden = oForm.getFieldIsHidden(item.akElm.opt.name);
    if (fieldHidden)
      return true;

  }

  let resRequired = true;
  if (item._validate) {
    for (let q = 0; q < item._validate.length; q++) {
      const v = `is${item._validate[q]}`;
      const val = item._value;
      if ((val == null || val.length == 0) && v == 'isNotEmpty' && item._type != 'container' && item.isAutofill !== true) {
        // field required and empty
        resRequired = false;
      }
    }
  }

  let res = true;
  if (item.akElm) {
    const oForm = akioma.getForm(item.akElm);
    if ((oForm.dataSource && oForm.dataSource.bDelete))
      return true;
    res = oForm.checkSmartMessageValidation(item.akElm.opt.name);
  } else
    res = ExtendedItemsInputValidate.call(this, item);


  const oForm = item.getForm();
  const oSelf = item.akElm;
  const cFieldName = oSelf.opt.name;
  const cFieldId = oSelf.opt.id;

  // turn on/off error state in form
  const bValid = (res && resRequired && oSelf.bValid);

  if (!bValid) {
    oForm.akElm._setFormFieldError(cFieldName, { hasError: true });

    // add dirty errors state
    if (!oForm.akElm.oVuexState.attributes.hasErrors)
      oForm.akElm._dispatch('incrementHasErrors', 1);
    oForm.akElm._dispatch('setHasErrors', true);
  } else {
    // remove dirty errors state
    const fieldsWithErrorsNo = $(oForm.cont).find('.validate_error').length;
    const bSame = ($(oForm.cont).find('.validate_error').length == 0 || $(oForm.cont).find('.validate_error')[0] == item);
    if (oForm.akElm.oVuexState.children[cFieldId].hasError && fieldsWithErrorsNo <= 1 && bSame)
      oForm.akElm._dispatch('decrementHasErrors', 1);


    oForm.akElm._setFormFieldError(cFieldName, {
      hasError: false,
      errorMsg: ''
    });
  }

  return bValid;
};

// Handling for combo validation
const ExtendedItemsComboRenderer = dhtmlXForm.prototype.items.combo.render;
dhtmlXForm.prototype.items.combo.render = function(item, data) {
  try {
    if (data.userdata) {
      item.akElm = $.getObjectByName({ id: data.userdata.id });
      item.comboObject = this;
    }
  } catch (e) {
    akioma.log.warn(e);
  }
  return ExtendedItemsComboRenderer.call(this, item, data);
};

const ExtendedItemsComboSetValue = dhtmlXForm.prototype.items.combo.setValue;
dhtmlXForm.prototype.items.combo.setValue = function(item, value) {
  try {
    if (value && item) {
      if (String(value).length > 0)
        $(item).addClass('active');
    }
  } catch (e) {
    akioma.log.error(e);
  }

  ExtendedItemsComboSetValue.call(this, item, value);
  if (item.akElm && item.akElm.dhx && item.akElm.form.akElm.bFocused)
    this._validate(item);
};

const ExtendedItemsComboValidate = dhtmlXForm.prototype.items.combo._validate;
dhtmlXForm.prototype.items.combo._validate = function(item) {
  if (item.akElm) {
    const oForm = item.akElm.form.akElm;
    const fieldEnabled = oForm.getFormFieldEnabled(item.akElm.opt.name);
    if (!fieldEnabled)
      return true;


    const fieldHidden = oForm.getFieldIsHidden(item.akElm.opt.name);
    if (fieldHidden)
      return true;

  }

  let resRequired = true;
  if (item._validate) {
    for (let q = 0; q < item._validate.length; q++) {
      const v = `is${item._validate[q]}`;
      const val = item._combo.getComboText();
      if ((val == null || val.length == 0) && v == 'isNotEmpty' && item._type != 'container') {
        // field required and empty
        resRequired = false;
      }
    }
  }

  let res = true;
  if (item.akElm) {
    const oForm = akioma.getForm(item.akElm);
    res = oForm.checkSmartMessageValidation(item.akElm.opt.name);
  } else
    res = (ExtendedItemsComboValidate) ? ExtendedItemsComboValidate.call(this, item) : false;


  const oForm = item.getForm();
  const oSelf = item.akElm;
  const cFieldName = oSelf.opt.name;
  const cFieldId = oSelf.opt.id;

  // turn on/off error state in form
  const bValid = (res && resRequired);

  if (!bValid) {
    oForm.akElm._setFormFieldError(cFieldName, { hasError: true });

    // add dirty errors state
    if (!oForm.akElm.oVuexState.attributes.hasErrors)
      oForm.akElm._dispatch('incrementHasErrors', 1);
    oForm.akElm._dispatch('setHasErrors', true);

  } else {
    // remove dirty errors state
    const fieldsWithErrorsNo = $(oForm.cont).find('.validate_error').length;
    const bSame = ($(oForm.cont).find('.validate_error').length == 0 || $(oForm.cont).find('.validate_error')[0] == item);
    // remove dirty errors state
    if (oForm.akElm.oVuexState.children[cFieldId].hasError && fieldsWithErrorsNo <= 1 && bSame)
      oForm.akElm._dispatch('decrementHasErrors', 1);


    oForm.akElm._setFormFieldError(cFieldName, {
      hasError: false,
      errorMsg: ''
    });

  }

  return (res && resRequired);
};

const ExtendedItemsCalendarValidate = dhtmlXForm.prototype.items.calendar._validate;
dhtmlXForm.prototype.items.calendar = {
  render: function(g, l) {
    const c = this;

    const oSelf = $.getObjectByName({ id: l.userdata.id });
    g._self = oSelf;

    g.akElm = oSelf;

    g._type = 'calendar';
    g._enabled = true;
    const m = navigator.userAgent;
    const a = (m.indexOf('MSIE 6.0') >= 0 || m.indexOf('MSIE 7.0') >= 0 || m.indexOf('MSIE 8.0') >= 0);

    if (l.inputWidth)
      l.inputWidth = l.inputWidth - 25;

    this.doAddLabel(g, l);
    this.doAddInput(g, l, 'INPUT', 'TEXT', true, true, 'dhxform_textarea calendar');
    this.doAttachChangeLS(g);

    let button = document.createElement('button');
    button.style.width = '25px';
    button.style.height = '25px';
    button.style.position = 'absolute';
    button.className = 'btn_calendar_custom';
    g.childNodes[g._ll ? 1 : 0].appendChild(button);
    button.id = `btn_${l.uid}`;

    if (a) {
      g.childNodes[g._ll ? 1 : 0].childNodes[0].onfocus2 = g.childNodes[g._ll ? 1 : 0].childNodes[0].onfocus;
      g.childNodes[g._ll ? 1 : 0].childNodes[0].onfocus = function() {
        if (this._skipOnFocus == true) {
          this._skipOnFocus = false;
          return;
        }
        this.onfocus2(...arguments);
      };
    }
    g.childNodes[g._ll ? 1 : 0].childNodes[0]._idd = g._idd;
    g.childNodes[g._ll ? 1 : 0].childNodes[0].onblur = function() {
      let n = this.parentNode.parentNode;
      if (n._c.base._formMouseDown) {
        n._c.base._formMouseDown = false;
        this._skipOnFocus = true;
        this.focus();
        n = null;
        return true;
      }
      let o = n.getForm();
      o._ccDeactivate(n._idd);
      if (o.live_validate)
        c._validate(n);


      let cValue, nValue;
      if (g.checkEvent('onBeforeChange')) {
        cValue = new Date();
        if (g._c._nullDate)
          cValue = (g._c._origVal == null) ? g._c._origVal : g._c._lastDate || g._c._origVal;
        else
          cValue = g._c._lastDate || g._c._activeDate;

        nValue = (g._c._nullDate) ? g._c._origVal : g._c._activeDate;
        if (g.callEvent('onBeforeChange', [ g._idd, cValue, nValue ]) !== true)
          return false;

      }

      o.callEvent('onBlur', [n._idd]);
      o.callEvent('onChange', [ g._idd, cValue, nValue ]);

      o = n = null;
    };

    g._f = (l.dateFormat || null);
    g._f0 = (l.serverDateFormat || g._f);
    let h = g.getForm();

    g._c = new dhtmlXCalendarObject({ input: g.childNodes[g._ll ? 1 : 0].childNodes[0].id, button: button.id }, l.skin || h.skin || 'dhx_skyblue');

    g._c._nullInInput = true;
    g._c.enableListener(g.childNodes[g._ll ? 1 : 0].childNodes[0]);
    if (g._f != null)
      g._c.setDateFormat(g._f);

    if (!window.dhx4.s2b(l.enableTime))
      g._c.hideTime();

    if (window.dhx4.s2b(l.showWeekNumbers))
      g._c.showWeekNumbers();

    if (!isNaN(l.weekStart))
      g._c.setWeekStartDay(l.weekStart);

    if (typeof (l.calendarPosition) != 'undefined')
      g._c.setPosition(l.calendarPosition);

    if (l.minutesInterval != null)
      g._c.setMinutesInterval(l.minutesInterval);

    g._c._itemIdd = g._idd;

    g._c.attachEvent('onShow', function() {
      this.setSensitiveRange(g.akElm.oDate.cMinDate, g.akElm.oDate.cMaxDate);
    });

    g._c.attachEvent('onHide', function() {
      const n = g._c._activeDate;

      if (g.checkEvent('onBeforeChange')) {
        const nValue = (g._c._nullDate) ? '' : n;
        if (g.callEvent('onBeforeChange', [ g._idd, g._c._lastDate || g._value, nValue ]) !== true)
          return false;

      }

      // only set value if not in readonly state
      if ($(g).find('input').attr('readonly') == undefined) {
        g._tempValue = g._value = n;
        c.setValue(g, n, false);
        g.callEvent('onChange', [ this._itemIdd, g._value, g._c._lastDate ]);
      }

      if (g.checkEvent('onBlur')) {
        if (g.callEvent('onBlur', [g._idd]) !== true)
          return false;

      }

    });

    g._c.attachEvent('onClick', () => {
      g._tempValue = null;
    });
    if (a) {
      g._c.base.onmousedown = function() {
        this._formMouseDown = true;
        return false;
      };
    }

    button = null;

    const node = g.childNodes[g._ll ? 1 : 0].childNodes[0];
    $(node).inputmask('datetime', {
      inputFormat: g._self.oDate.cInputMaskFormat,
      max: g._self.oDate.cMaxDate,
      min: g._self.oDate.cMinDate
    });
    g._inputmask = node.inputmask;

    this.setValue(g, l.value);

    h = null;
    return this;
  }, getCalendar: function(a) {
    return a._c;
  }, setSkin: function(a, c) {
    a._c.setSkin(c);
  }, setValue: function(c, g, a) {

    if (!g || g == null || typeof (g) == 'undefined' || g == '') {
      c._value = null;
      c.childNodes[c._ll ? 1 : 0].childNodes[0].value = '';
    } else {
      c._value = (g instanceof Date ? g : c._c._strToDate(g, c._f0 || c._c._dateFormat));
      c.childNodes[c._ll ? 1 : 0].childNodes[0].value = c._c._dateToStr(c._value, c._f || c._c._dateFormat);
      c._c._nullDate = false;
    }

    if (a !== false)
      c._c.setDate(c._value);


    if (c._self && c._self.dynObject && (!c._self.dynObject.akEvent || c._value != c._self.dynObject.akEvent.currentValue)) {
      const akEvent = {};
      akEvent.currentValue = c._value;
      akEvent.currentValueNative = c._value;
      akEvent.lastValue = c._self.dynObject.akEvent ? c._self.dynObject.akEvent.currentValue : undefined;
      c._self.dynObject.akEvent = akEvent;

    }

    let dateValue;
    if (g instanceof Date)
      dateValue = g;
    else
      dateValue = (g || '').toString();

    if (dateValue !== '') {
      const dateFormat = akioma.date.convertInputMaskToMoment(c._self.oDate.cInputMaskFormat);
      dateValue = moment(dateValue).format(dateFormat);
      c._inputmask.setValue(dateValue);
    }

    const form = c.getForm();
    if (form && form.akElm && form.akElm.bFocused && form.akElm.stopValidation !== true)
      if (c.getForm()['live_validate']) this._validate(c);


  }, getValue: function(c, a) {
    a = true;
    const g = c._c.getDate();
    if (a === true && g == null) {
      if (c._self && c._self.dynObject && c._self.dynObject.akEvent)
        return (c._self.dynObject.akEvent.currentValue == null ? null : '');

      return null;
    }
    return (a === true ? c._c._dateToStr(g, c._f0 || c._c._dateFormat) : g);

  }, setDateFormat: function(c, a, g) {
    c._f = a;
    c._f0 = (g || c._f);
    c._c.setDateFormat(c._f);
    this.setValue(c, this.getValue(c));
  },
  // required for setting it as required calendar
  _getItemNode: function(item) {
    return item;
  },

  // validates calendar inputs
  _validate: function(item) {
    const value = $(item).find('input').val();
    const cFormat = item._f;
    let resRequired = true;
    let res = true;
    let bValid = true;

    if (item.akElm) {
      const oForm = item.akElm.form.akElm;
      const fieldEnabled = oForm.getFormFieldEnabled(item.akElm.opt.name);
      if (!fieldEnabled)
        return true;


      const fieldHidden = oForm.getFieldIsHidden(item.akElm.opt.name);
      if (fieldHidden)
        return true;

    }

    // check for required attribute
    if (item._validate) {
      for (let q = 0; q < item._validate.length; q++) {
        const v = `is${item._validate[q]}`;

        if ((value == null || value.length == 0) && v == 'isNotEmpty' && item._type != 'container') {
          // field required and empty
          resRequired = false;
        }
      }
    }
    let oForm = akioma.getForm(item._self);

    if (item._self) {
      if ((oForm.dataSource && oForm.dataSource.bDelete))
        return true;

      res = oForm.checkSmartMessageValidation(item._self.opt.name);
    } else
      res = ExtendedItemsCalendarValidate.call(this, item);


    // in case there is no value then date is valid if also passed the smartmessage
    if (value == '' || value == null)
      res = true;
    else {
      // bugfix for months
      const iYearIndex = cFormat.indexOf('%Y');
      let iMonthIndex = cFormat.indexOf('%m');
      if (iYearIndex < iMonthIndex)
        iMonthIndex = iMonthIndex + 2;
      const cMonth = value.substring(iMonthIndex, iMonthIndex + 2);
      const iMonth = Number(cMonth);
      bValid = (iMonth && iMonth < 13) ? true : false;

      if (bValid) {
        const cDate = item._c._strToDate(value, cFormat);
        if (cDate == 'Invalid Date')
          bValid = false;
      }
    }

    oForm = item.getForm();
    const oSelf = item.akElm;
    const cFieldName = item.akElm.opt.name;
    const cFieldId = oSelf.opt.id;

    if (!bValid) {
      // Autofill missing date parts with current date
      const oNewDate = akioma.date.fillDate(item.akElm.oDate.cInputMaskFormat, value);
      item._value = oNewDate;
      $(item).find('input')[0].value = item._c._dateToStr(item._value, item._f || item._c._dateFormat);
      item._c._lastDate = item._c._activeDate;
      item._c._updateFromInput($(item).find('input')[0]);
      res = true;


    } else if (!res || !resRequired) {
      // set vuex error state and message
      oForm.akElm._setFormFieldError(cFieldName, { hasError: true });

      // add dirty errors state
      if (!oForm.akElm.oVuexState.attributes.hasErrors)
        oForm.akElm._dispatch('incrementHasErrors', 1);
      oForm.akElm._dispatch('setHasErrors', true);
    } else {
      // remove dirty errors state
      const fieldsWithErrorsNo = $(oForm.cont).find('.validate_error').length;
      const bSame = ($(oForm.cont).find('.validate_error').length == 0 || $(oForm.cont).find('.validate_error')[0] == item);
      // remove dirty errors state
      if (oForm.akElm.oVuexState.children[cFieldId].hasError && fieldsWithErrorsNo <= 1 && bSame)
        oForm.akElm._dispatch('decrementHasErrors', 1);


      // set vuex error state and message
      oForm.akElm._setFormFieldError(cFieldName, {
        hasError: false,
        errorMsg: ''
      });
    }

    return (res && resRequired);

  },
  destruct: function(a) {
    a._inputmask.remove();
    // a._c.disableListener(a.childNodes[a._ll ? 1 : 0].childNodes[0]);
    a._c.unload();
    a._c = null;
    try {
      delete a._c;
    } catch (c) {
      akioma.log.warn(c);
    }
    a._f = null;
    try {
      delete a._f;
    } catch (c) {
      akioma.log.warn(c);
    }
    a._f0 = null;
    try {
      delete a._f0;
    } catch (c) {
      akioma.log.warn(c);
    }
    a.childNodes[a._ll ? 1 : 0].childNodes[0]._idd = null;
    a.childNodes[a._ll ? 1 : 0].childNodes[0].onblur = null;
    this.d2(a);
    a = null;
  }, checkEnteredValue: function(a) {
    this.setValue(a, a._c.getDate());
  }
};

(function() {
  for (const c in { doAddLabel: 1, doAddInput: 1, doUnloadNestedLists: 1, setText: 1, getText: 1, enable: 1, disable: 1, isEnabled: 1, setWidth: 1, setReadonly: 1, isReadonly: 1, setFocus: 1, getInput: 1 })
    dhtmlXForm.prototype.items.calendar[c] = dhtmlXForm.prototype.items.input[c];

})();
dhtmlXForm.prototype.items.calendar.doAttachChangeLS = dhtmlXForm.prototype.items.select.doAttachChangeLS;
dhtmlXForm.prototype.items.calendar.d2 = dhtmlXForm.prototype.items.input.destruct;
dhtmlXForm.prototype.getCalendar = function(a) {
  return this.doWithItem(a, 'getCalendar');
};
dhtmlXForm.prototype.setCalendarDateFormat = function(c, a, g) {
  this.doWithItem(c, 'setDateFormat', a, g);
};

/* -----------------------------------------------------------------------------*/
dhtmlXCalendarObject.prototype.loadUserLanguage = function(y, newDateFormat) {
  if (!this.langData[y])
    return;

  this.lang = y;
  this.setWeekStartDay(this.langData[this.lang].weekstart);
  if (!newDateFormat)
    this.setDateFormat(window.dhx.dateFormat[window.dhx.dateLang] || '%Y-%m-%d');

  if (this.msCont) {
    let x = 0;
    for (let v = 0; v < this.msCont.childNodes.length; v++) {
      for (let a = 0; a < this.msCont.childNodes[v].childNodes.length; a++)
        this.msCont.childNodes[v].childNodes[a].innerHTML = this.langData[this.lang].monthesSNames[x++];

    }
  }
};

// extended dhtmlx form to click form button programatically
dhtmlXForm.prototype.clickButton = function(name) {
  _dhxForm_doClick(this.getInput(name).childNodes[0].childNodes[0], [ 'mousedown', 'mouseup' ]);
};

dhtmlXGridObject.prototype.setHeaderFilterStatus = function(bEnabled) {

  let nodeList;
  for (let iCount = 0; iCount < this.hdr.rows[2].cells.length; iCount++) {
    const oFilterElement = this.getFilterElement(iCount);
    if (!oFilterElement)
      continue;

    switch (oFilterElement.tagName) {
      case 'INPUT':
        nodeList = oFilterElement.parentNode.querySelectorAll('input');
        break;
      case 'SELECT':
        nodeList = oFilterElement.parentNode.querySelectorAll('span.select2-selection');
        break;
    }

    nodeList.forEach(node => {
      if (bEnabled) {
        if (node.className.includes('filter-grid-header-disabled-input'))
          node.className = node.className.replace('filter-grid-header-disabled-input', '');
      } else if (!node.className.includes('filter-grid-header-disabled-input'))
        node.className = `filter-grid-header-disabled-input ${node.className}`;

      node.className = node.className.trim();
    });

    const oFilterParentNode = this.hdr.rows[2].parentNode;
    if (oFilterParentNode) {
      if (bEnabled) {
        if (oFilterParentNode.className.includes('filter-grid-header-disabled-header'))
          oFilterParentNode.className = oFilterParentNode.className.replace('filter-grid-header-disabled-header', '');
      } else if (!oFilterParentNode.className.includes('filter-grid-header-disabled-header'))
        oFilterParentNode.className += ' filter-grid-header-disabled-header';

      oFilterParentNode.className = oFilterParentNode.className.trim();
    }
  }
};

dhtmlXRibbon.prototype.enableAllItems = function() {
  for (const item in this._items)
    this.enable(this._items[item].id);
};
