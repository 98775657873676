// ************************* toolbarselect ********************************
$.extend({
  ak_toolbarselect: function(options) {
    const defaults = {
      id: '',
      title: '',
      disabled: false,
      image: '',
      image2: ''
    };

    this.opt = $.extend({}, defaults, options.att);
    this.opt.children = options.sub;
    this.parent = options.parent;

    this.registerDynObject = true;
    this.useParentDynObjectLink = true;

    // get new element
    const cId = this.opt.id;
    const sequence = this.opt.sequence;
    const cName = this.opt.name;
    let cImage = this.opt.image;

    const iPos = this.parent.opt.pos + 1;
    this.parent.opt.pos = iPos;

    cImage = (cImage == '') ? null : cImage;

    const oOpts = [];
    if (this.opt.children) {
      for (const oOption of this.opt.children)
        oOpts.push([ oOption.att.name, 'obj', oOption.att.text, oOption.att.image, oOption.att.image ]);
    }

    this.parent.dhx.addButtonSelect(cId, sequence, cName, oOpts, cImage, cImage);
  }
});


// methods for toolbar select
$.ak_toolbarselect.prototype = {

  // finalize construct *************
  finishConstruct: function() {
    // check for dyncombo entry
    if (this.opt.objectName || this.opt.ListItemPairs)
      app.toolbar.getDynCombo.apply(this);

    const oParent = this.parent;
    if (oParent.toolbarMenuID && this.opt.name == 'tbfilterChooseFilter') {
      const oFilter = this.dynObject.getLink('FILTER:TARGET');
      oFilter.controller.getFilterList();
    }

    this.parent.aToolbarItems.push(this.opt.name);
  },

  // click *****************
  click: function(cId) {
    const oToolbarMenuStructure = this.parent.toolbarMenuStructure;

    if (oToolbarMenuStructure != undefined) {
      const oMenuItem = oToolbarMenuStructure.getMenuItemByCode(cId);
      const oMenuAction = oToolbarMenuStructure.aMenuActions[oMenuItem.id];
      if (oMenuAction) {
        this.opt.actiontype = oMenuAction.ActionType;
        this.opt.action = oMenuAction.Action;
        this.opt.category = oMenuAction.Category;
        this.opt.logicalObject = oMenuAction.CallObject;
      }

    }

    // call action
    app.controller.callAction({
      ActionType: this.opt.actiontype,
      Action: this.opt.action,
      Category: this.opt.category,
      RunPar: this.opt.RunPar,
      CallObject: this.opt.logicalObject,
      caller: this,
      launchedFrom: this,
      click: cId
    },
    this.parent);

    if (this.opt.selectEvent)
      app.controller.callAkiomaCode(this, this.opt.selectEvent);

    return true;

  }
};
