/**
 * SwatGraphEditor Control
 * @class ak_graphEditor
 * @param {Object} options Repository attributes for SwatGraphEditor.
 */
$.ak_graphEditor = class {

  /**
   * Constructor
   */
  constructor(opts) {
    const defaults = {};

    this.opt = Object.assign({}, defaults, opts.att);
    this.parent = opts.parent;
    this.view = opts.view;
    this.registerDynObject = true;
    this.newInstances = [];
    this.newPageInstances = [];
    this.lastSelectedInstance = null;
    this.newPlaceholderInstances = [];

    // cannot use cell because contains header
    const container = this.parent.dhx.cell.getElementsByClassName('dhx_cell_cont_layout')[0];
    const app = new akioma.GraphEditor(container);
    this.dhx = app;
  }

  /**
   * Finish construct hook method
   */
  finishConstruct() {
    const dataSource = this.dynObject.getLink('DATA:SRC');
    if (dataSource)
      this.dataSource = dataSource.controller;


    if (this.opt.BorderTitle) {
      this.parent.dhx.setText(this.opt.BorderTitle);
      this.parent.dhx.showHeader();
    }

    this.attachResizeEvents();
    this.attachDataSourceEvents();
    this.attachUpdateVisibilityEvents();

    const self = this;
    if (self.dataSource) {
      self.dataSource.addAfterCatalogAdd(() => {
        const onAfterCreate = function(jsdo, record, success) {
          // clear saved instance guid from memory
          if (success) {
            jsdo.unsubscribe('afterCreate', onAfterCreate);
            self.newInstances = [];
            self.lastSelectedInstance = null;
          }
        };
        self.dataSource.jsdo.subscribe('afterCreate', onAfterCreate);
      });
    }

    if (this.opt.onInit) {

      // why doesn't callAkiomaCode support self, oSelf and eventSource?
      // next portion declared variables required in the callAkiomaCode context
      /* eslint-disable no-unused-vars */
      const self = this.dynObject;
      const oSelf = this;
      const eventSource = akioma.swat.SwatFactory.createSwatObject(this);
      /* eslint-enable */

      app.controller.callAkiomaCode(this, this.opt.onInit);
    }
  }

  /**
   * Export image used for preview
   * @returns The SVG image string
   */
  exportImage() {
    return this.dhx.exportImage();
  }

  /**
   * Attaches resize events
   */
  attachResizeEvents() {
    let controller = this.parent;

    while (controller && controller.view !== 'window') {
      if (controller.view === 'panelset') {
        controller.dhx.attachEvent('onExpand', this.onResize.bind(this));
        controller.dhx.attachEvent('onCollapse', this.onResize.bind(this));
        controller.dhx.attachEvent('onResizeFinish', this.onResize.bind(this));
        controller.dhx.attachEvent('onPanelResizeFinish', this.onResize.bind(this));
      }

      controller = controller.parent;
    }
  }

  /**
   * Attaches data source events
   */
  attachDataSourceEvents() {
    if (!this.dataSource)
      return;

    this.dhx.addEventListener('selectcell', evt => {
      const guid = evt.detail.guid;
      const source = evt.detail.source;

      switch (source) {
        case 'master':
          this.dataSource.opt.entityName = 'eSmartObjectMaster';
          this.dataSource.opt.identifier = 'objectmasterguid';
          break;

        case 'instance':
          this.dataSource.opt.entityName = 'eSmartObjectInstance';
          this.dataSource.opt.identifier = 'objectinstanceguid';
          break;

        case 'page':
          this.dataSource.opt.entityName = 'eSmartPage';
          this.dataSource.opt.identifier = 'pageguid';
          break;
      }

      const store = this.dataSource.getStore(this.dataSource.opt.entityName);
      if (store) {

        const rec = Object.keys(store.data.pull).map(key => store.data.pull[key]).find(rec => rec[this.dataSource.opt.identifier] === guid);
        if (rec) {

          if (store.getCursor() !== rec.id)
            store.setCursor(rec.id);
        }
      }
    });
  }

  /**
   * Attaches update visibility events
   */
  attachUpdateVisibilityEvents() {
    let controller = this.parent;

    this.isVisible = false;

    while (controller && controller.view !== 'window') {
      switch (controller.view) {
        case 'tabbar':
          controller.dhx.attachEvent('onSelect', this.updateVisibilityHandler.bind(this));
          break;

        case 'panelset':
          controller.dhx.attachEvent('onCollapse', this.updateVisibilityHandler.bind(this));
          controller.dhx.attachEvent('onExpand', this.updateVisibilityHandler.bind(this));
          break;
      }

      controller = controller.parent;
    }

    // cancel dhtmlx drag and drop when graph editor is not collapsed
    this.getGrid().dhx.attachEvent('onBeforeDrag', () => {
      if (this.isVisible)
        return false;
      else
        return true;
    });

    this.updateVisibilityHandler();
  }

  /**
   * Update visibility event handler
   */
  updateVisibilityHandler() {
    const lastIsVisible = this.isVisible;
    this.setVisible();

    if (this.isVisible !== lastIsVisible) {
      if (this.isVisible) {
        if (this.onShow) this.onShow();
      } else if (this.onHide) this.onHide();
    }
  }

  /**
   * Sets the object isVisible property
   */
  setVisible() {
    let controller = this.parent;

    this.isVisible = false;

    while (controller && controller.view !== 'window') {
      switch (controller.view) {
        case 'tab':
          if (!controller.dhx.isActive())
            return;
          break;

        case 'panel':
          if (controller.dhx.isCollapsed())
            return;
          break;
      }

      controller = controller.parent;
    }

    this.isVisible = true;
  }

  /**
   * Data available event hook
   */
  dataAvailable() {
    if (this.dataTimeout) {
      clearTimeout(this.dataTimeout);
      this.dataTimeout = null;
    }

    this.dataTimeout = setTimeout(() => {
      this.refreshData();
      this.dataTimeout = null;
    }, 50); // there are multiple dataAvailable event calls
  }

  /**
   * Refreshes the graph state data
   */
  refreshData() {
    const masterStore = this.dataSource.getStore('eSmartObjectMaster');
    const instanceStore = this.dataSource.getStore('eSmartObjectInstance');
    const pageStore = this.dataSource.getStore('eSmartPage');
    const currStore = this.dataSource.getStore(this.dataSource.opt.entityName);
    const currItem = currStore.item(currStore.getCursor());

    this.setState({
      master: masterStore ? Object.keys(masterStore.data.pull).map(key => masterStore.data.pull[key])[0] : {},
      instances: instanceStore ? Object.keys(instanceStore.data.pull).map(key => instanceStore.data.pull[key]) : [],
      pages: pageStore ? Object.keys(pageStore.data.pull).map(key => pageStore.data.pull[key]) : [],
      selectedGuid: currStore && currItem ? currItem[this.dataSource.opt.identifier] : null
    });
  }

  /**
   * Set graph editor state
   */
  setState(data) {
    this.dhx.setState(data);

    // update visibility on first load
    if (data.master && this.dhx.state.master)
      this.updateVisibilityHandler();
  }

  /**
   * Refreshes the graph editor
   */
  refresh() {
    this.dhx.refresh();
  }

  /**
   * Show event handler
   */
  onShow() {
    this.enableDragAndDrop();
  }

  /**
   * Hide event handler
   */
  onHide() {
    this.disableDragAndDrop();
  }

  /**
   * Resize event handler
   */
  onResize() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = null;
    }

    this.resizeTimeout = setTimeout(() => {
      if (this.dhx.container.offsetWidth > 0 && this.dhx.container.offsetHeight > 0)
        this.dhx.refresh();

      this.resizeTimeout = null;
    }, 100); // there are multiple resize event calls
  }

  /**
   * Enables drag and drop from the object grid to the graph editor
   */
  enableDragAndDrop() {
    this.dhx.enableDragAndDrop(
      this.getGrid().dhx.entBox,
      this.getDragTarget,
      this.createDragEl
    );
  }

  /**
   * Disable drag and drop for the graph editor
   */
  disableDragAndDrop() {
    this.dhx.disableDragAndDrop();
  }

  /**
   * Get drag target for drag and drop
   */
  getDragTarget(target) {
    let el = target;

    while (el && el.nodeName.toLowerCase() !== 'tr')
      el = el.parentElement;

    if (!el)
      return { target: null, data: null };

    return { target: el, data: el._attrs };
  }

  /**
   * Create drag element for drag and drop
   */
  createDragEl(target, data, evt) {
    let dragEl;
    let offsetX, offsetY;

    // todo: move style to css file
    if (data) {
      dragEl = $(`<p class="akDnD">${data.objectname}<br>(${data.objecttypename})</p>`).css({
        textAlign: 'center',
        maxWidth: '160px',
        padding: '10px',
        margin: '0',
        backgroundColor: '#f5fca4',
        color: 'black',
        fontFamily: 'roboto, arial, helvetica',
        fontSize: '14px',
        fontWeight: 'bold',
        border: 'dashed black 2px',
        position: 'absolute',
        zIndex: '99999',
        left: '-1000px',
        top: '-1000px',
        boxShadow: '0 0 15px black'
      })[0];
    }

    document.body.append(dragEl);

    const rect = evt.target.getBoundingClientRect();

    if (dragEl) {
      offsetX = (evt.pageX - rect.left) / rect.width * dragEl.offsetWidth;
      offsetY = (evt.pageY - rect.top) / rect.height * dragEl.offsetHeight;
    }

    return { dragEl, offsetX, offsetY };
  }

  getGrid() {
    return this.grid || (this.grid = (this.dynObject.container || this.dynObject.topScreen).getObject('ObjectMasterGrid').controller);
  }

  /**
   * Method for removing the graph editor
   */
  destroy() {
    this.dhx.destroy();
    this.dhx = null;
  }
};
