// ********************* calendar ********************
/**
   * SwatDatePicker Control
   * @class ak_calendar
   * @param {Object} options Repository attributes for SwatDatePicker.
   * @param {boolean} options.CanSort Set to FALSE if this field should not be used to sort the data object query.
   * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
   * @param {string} options.align alignment of column. can be left, right or centered
   * @param {string} options.HELP WidgetAttributes Help
   * @param {number} options.ROW Row position.
   * @param {boolean} options.Mandatory WidgetAttributes Mandatory
   * @param {string} options.VisualizationType WidgetAttributes VisualizationType
   * @param {string} options.LABEL WidgetAttributes Label
   * @param {string} options.EventEntryType Language of "entry" trigger
   * @param {number} options.HEIGHT-CHARS Height in characters. This may currently be used when rendering some objects. There is no get function, use getHeight to retrieve the realized value from an object.
   * @param {boolean} options.VISIBLE WidgetAttributes Visible
   * @param {boolean} options.CHECKED
   * @param {string} options.TemplateFile The relative path and filename of the static object used as the template at design time
   * @param {string} options.InitialValue WidgetAttributes InitialValue
   * @param {string} options.EventEntry Event which should run on entry of a field
   * @param {string} options.LIST-ITEMS
   * @param {boolean} options.CanFilter Set to FALSE if this field should not be used to filter the data object query.
   * @param {string} options.ViewAs The 'ViewAs' definition  of the selection.&#10;- combo-box,radio-set,selection-list OR browse &#10;- Uses colon as separator to define SUB-TYPE for combo-box or &#10;horizontal/vertical radio-set,
   * @param {string} options.FolderWindowToLaunch If Dynamics is running, this property specifies a window to launch upon the occurence of toolbar events "View", "Copy", "Modify" or "Add".
   * @param {string} options.EventLeaveType Language, the event is written in.
   * @param {string} options.TableName WidgetAttributes TableName
   * @param {string} options.FORMAT WidgetAttributes Format
   * @param {string} options.LIST-ITEM-PAIRS
   * @param {string} options.EventAkValidate Client side validation code (JavaScript)
   * @param {string} options.eventOnInfoSelected Executed on info click
   * @param {boolean} options.infoButton Show/hide info icon
   * @param {string} options.DATA-TYPE WidgetAttributes Data-Type
   * @param {string} options.Validation Validation-Rules, e.g. ValidInteger,ValidEMail...
   * @param {string} options.FilterOperator
   * @param {boolean} options.LABELS If false then NO-LABEL is used.  This attribute applies to most field level widgets and frames
   * @param {string} options.FieldName The name of the associated SDO field this SmartDataField maps to. This is usually 'set' from the containing SmartDataViewer.
   * @param {boolean} options.ENABLED WidgetAttributes Enabled
   * @param {boolean} options.CreateField
   * @param {string} options.EventLeave Event when leaving a field
   * @param {string} options.SUBTYPE
   * @param {boolean} options.MULTIPLE
   * @param {string} options.Width A widget's width. The unit of measurement is determined by another&#10;parameter.
   * @param {number} options.COLUMN Column position. This may currently be used when rendering some objects. There is no getColumns function, use getCol to retrieve the realized value from an object.
   * @param {string} options.EventClick Client side validation code (JavaScript)
   * @param {string} options.filter
   * @param {string} options.extendedFormat Defines a custom date/datetime format for the field. More details can be found on the Confluence page: https://help.build.one/display/AKDOC/Date+and+Datetime+fields+formatting
   * @fires ak_eventOnInfoSelected
   * @fires ak_dynselect#EventAkValidate
   */
$.extend({
  ak_calendar: function(options) {

    akioma.BaseFormDataField.call(this, options);

    const oSelf = this,
      defaults = {};

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
    this.view = options.view;

    this.registerDynObject = true;
    this.registerVuexWatcher = true;
    this.useParentDynObjectLink = true;

    if (this.opt.validation) {
      const oForm = akioma.getForm(this);
      const aValidations = this.opt.validation.split(',');
      for (const v in aValidations) {
        const cInstanceValidation = aValidations[v];
        const aInsValidations = cInstanceValidation.split(':');
        const cValidationType = aInsValidations[0].trim().toLowerCase();
        if (cValidationType == 'required')
          this.opt.required = true;
          // based on form item name and

        oForm.setValidationRule(this.opt.name,
          {
            type: cValidationType,
            msg: aInsValidations[2].trim(),
            val: aInsValidations[1].trim().toLowerCase()
          });
      }

    }

    // get parent
    const oParent = this.parent;

    if (oParent) {
      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      const lTime = this.opt.dataType == ('datetime-tz' || 'datetime');

      oSelf.oDate = {};
      oSelf.oDate.cServerFormat = (lTime) ? '%Y-%m-%dT%H:%i:%s.%u%P' : '%Y-%m-%d';
      oSelf.oDate.cFormat = '%Y-%m-%d';
      if (oSelf.opt.extendedFormat)
        oSelf.oDate.cFormat = oSelf.opt.extendedFormat;
      else
        oSelf.oDate.cFormat = (lTime) ? app.sessionData.globalDateTimeFormat || window.dhx.dateTimeFormat[window.dhx.dateLang] : app.sessionData.globalDateFormat || window.dhx.dateFormat[window.dhx.dateLang];

      oSelf.oDate.cInputMaskFormat = akioma.date.convertDateFormat(oSelf.oDate.cFormat);
      oSelf.oDate.cMaxDate = akioma.date.getMax_MinDate(oSelf.oDate.cInputMaskFormat, true);
      oSelf.oDate.cMinDate = akioma.date.getMax_MinDate(oSelf.oDate.cInputMaskFormat);


      this.opt.label = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });

      oParent.prop.fields.push({
        type: 'calendar',
        required: (this.opt.required) ? this.opt.required : false,
        mandatory: (this.opt.required) ? this.opt.required : false,
        inputTop: parseInt(this.opt.top),
        inputLeft: parseInt(this.opt.left),
        inputWidth: parseInt(this.opt.width),
        label: this.opt.label ? this.opt.label : '',
        labelTop: parseInt(this.opt.top),
        labelLeft: oParent.labelLeft(this),
        name: this.opt.name,
        // disabled:         this.opt.disabled,
        readonly: this.opt.disabled,
        enabled: !this.opt.disabled,
        className: 'w4-formField w4-inputField calendarinput active',
        value: (this.opt.value) ? this.opt.value : '',
        dateFormat: oSelf.oDate.cFormat,
        serverDateFormat: oSelf.oDate.cServerFormat,
        position: 'label-top',
        enableTime: lTime,
        hasError: false,
        info: !!this.opt.infoButton,
        errorMsg: '',
        userdata: { id: this.opt.id }
      });

      // append to elements in form
      if (this.parent.view == 'form')
        this.parent.elements.push(this);

    }
  }
});

// methods for form
Object.assign($.ak_calendar.prototype, akioma.BaseFormDataField.prototype, {
  componentOptions: function() {
    const oSelf = this;
    return {
      watch: {
        'getters.getFormFieldState': {
          fn: function(newValue, oldValue) {
            oSelf._hasFormFieldChangesWatcher(newValue, oldValue);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldEnabled': {
          fn: function(bEnabled) {
            oSelf._enabledFormFieldWatcher(bEnabled);
          },
          params: [this.opt.id]
        },
        'getters.getCustomStates': {
          fn: function(customStates) {
            oSelf._customStatesWatcher(customStates);
          },
          params: [this.opt.id],
          watchOptions: { deep: true }
        },
        'getters.getFormFieldMandatory': {
          fn: function(bMandatory) {
            oSelf._mandatoryFormFieldWatcher(bMandatory);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldError': {
          fn: function(newValue, oldValue) {
            oSelf._errorFormFieldWatcher(newValue, oldValue);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldErrorMsg': {
          fn: function(cErrorMsg) {
            oSelf._errorFormFieldMsgWatcher(cErrorMsg);
          },
          params: [this.opt.id]
        }
      }
    };
  },
  _enabledFormFieldWatcher: function(bEnabled) {
    const oSelf = this;
    const oForm = akioma.getForm(oSelf);
    const cName = oSelf.opt.name;

    if (oForm.dhx) {
      if (bEnabled)
        oForm.setFormFieldReadonly(cName, false);
      else
        oForm.setFormFieldReadonly(cName, true);

    }
  },
  _mandatoryFormFieldWatcher: function(bMandatory) {
    const oSelf = this;
    const oForm = akioma.getForm(oSelf);
    const cName = oSelf.opt.name;

    if (oForm && oForm.dhx) {
      if (typeof (oSelf.setRequired) == 'function')
        oSelf.setRequired(bMandatory);

      oForm.dhx.setRequired(cName, bMandatory);
    }
  },
  /**
     * Watcher for the form error message
     */
  _errorFormFieldMsgWatcher: function(newValue) {

    const oSelf = this;
    const cName = oSelf.opt.name;
    const oForm = akioma.getForm(oSelf);
    const oFormField = akioma.getDhxFormElement(oForm.dhx, cName);

    // setup error message under input
    if (oFormField) {
      const oFormCtrl = $(oFormField).find('> .dhxform_control');
      if (oFormCtrl.find('.validation-error-smartmessage').length == 0)
        oFormCtrl.append(`<span class="validation-error-smartmessage">${newValue}</span>`);
      else
        oFormCtrl.find('.validation-error-smartmessage').text(newValue);
    }

  },

  /**
   * Set field as required.
   * @memberof ak_calendar
   * @instance
   * @param {Boolean} bRequired
   */
  setRequired: function(bRequired) {
    const oSelf = this;
    const oInput = $(oSelf.domTag).parent()[0];
    (bRequired) ? $(oInput).addClass('akMandatory akCalendarMandatory') : $(oInput).removeClass('akMandatory akCalendarMandatory');
  },

  // finish construct **********
  finishConstruct: function() {
    // get field
    const oSelf = this;
    this.form = akioma.getForm(this).dhx;
    this.dhx = this.form.getCalendar(this.opt.name);
    this.dhx.oElement = $(this.form.getInput(this.opt.name))[0];
    const parentForm = akioma.getForm(this);
    const formAkId = parentForm.akId;

    let $itemcont;
    try {
      $itemcont = $(this.dhx.oElement).parent().parent();
    } catch (e) {
      akioma.log.error(`could not get field cont for : ${this.opt.name}`);
    }

    const oCalendarSelector = $(this.form.getInput(this.opt.name)).parent();
    $(oCalendarSelector).attr('akId', `${formAkId}-${this.opt.name}`); // set akId in calendar
    $(oCalendarSelector).attr('akstyle', this.opt.customStyle); // set akstyle in calendar


    this.$domElement = $(oCalendarSelector).parent();
    this._setAutocomplete(oCalendarSelector);
    this.setResponsiveSizes();

    if (this.opt.initial) {
      this.setValue(this.opt.initial);
      if (this.opt.initial.length > 0)

        $itemcont.addClass('active');


    }

    this.dhx.attachEvent('onClick', () => {
      $itemcont.addClass('active');
    });


    const oCal = this.form.getCalendar(this.opt.name);


    const oCalInput = this.form.getInput(oSelf.opt.name);

    $(oCalInput).on('change', function() {
      const val = $(this).val();

      if (val.length > 0)
        $itemcont.addClass('active');
      else
        $itemcont.removeClass('active');
    });

    if (this.opt.required)
      $(oCalInput).parent().addClass('akMandatory akCalendarMandatory');


    oCal.loadUserLanguage = dhtmlXCalendarObject.prototype.loadUserLanguage;
    oCal.loadUserLanguage('de', oCal._dateFormat);

    this.domTag = $(oCalendarSelector).children()[0];
    this._setTabIndex(this.domTag);
  },

  eventFocus: function(cVal) {
    const formDiv = this.form.cont.children[0];
    $(formDiv).children().removeClass('focusedinp');
    const $itemcont = $(this.form.getInput(this.opt.name)).parent().parent();

    const bEnabled = this.form.akElm._getters('getFormFieldEnabled', this.opt.id);
    if (bEnabled) {
      $itemcont.addClass('focusedinp');
      cVal = $(this.form.getInput(this.opt.name)).val();
      if (cVal.length > 0)
        $itemcont.addClass('active');
    }


  },
  eventBlur: function() {
    const $itemcont = $(this.form.getInput(this.opt.name)).parent().parent();
    const cVal = $(this.form.getInput(this.opt.name)).val();

    if (cVal.length == 0)
      $itemcont.removeClass('active');
    $itemcont.removeClass('focusedinp');
  },

  eventLeave: function() {
    if (this.opt.leaveEvent)
      app.controller.callAkiomaCode(this, this.opt.leaveEvent);
  },
  // event leave *******************
  eventOnValueChanged: function(cValue, nValue) {
    const cCurrentValue = this.getValue();

    const event = {};

    if (this.dynObject.akEvent) {
      if (this.dynObject.akEvent.currentValue && nValue) {
        event.lastValue = this.dynObject.akEvent.currentValue.getTime() != nValue.getTime()
          ? this.dynObject.akEvent.currentValue
          : this.dynObject.akEvent.lastValue;
      } else {
        event.lastValue = (this.dynObject.akEvent.currentValue != nValue)
          ? this.dynObject.akEvent.currentValue
          : this.dynObject.akEvent.lastValue;
      }

      // Special handling for null
      if (this.dynObject.akEvent.currentValue == null && nValue == '')
        event.lastValue = this.dynObject.akEvent.lastValue;
    } else
      event.lastValue = (cCurrentValue) ? cValue : this.dhx._activeDate;

    event.currentValue = (cCurrentValue) ? nValue : cCurrentValue;
    event.currentValueNative = event.currentValue;
    this.dynObject.akEvent = event;

  },
  cursorChange: function() {
    const $itemcont = $(this.form.getInput(this.opt.name)).parent().parent();
    const cValue = this.form.getItemValue(this.opt.name);

    if (isNull(cValue) || cValue.length == 0)
      $itemcont.removeClass('active');
    else
      $itemcont.addClass('active');
  },

  // get value *****************
  getValue: function() {
    return this.form.getItemValue(this.opt.name);
  },

  /**
     * Setting the value of the calendar
     * @memberof ak_calendar
     * @instance
     * @param {Object} cValue
     * @returns {any}
     */
  setValue: function(oValue) {
    const value = this.form.setItemValue(this.opt.name, oValue);

    /**
       * Code executed on the client side for validation
       * @event ak_calendar#EventAkValidate
       * @type {object}
       */
    if (this.opt.validateEvent)
      app.controller.callAkiomaCode(this, this.opt.validateEvent);

    return value;
  },

  /**
     * Gets the formatted value of a date.
     * @param {Object} oDate Date object
     * @return {String} The formatted value of the date
     * @instance
     * @memberOf ak_calendar
    */
  getFormattedDate: function(oDate) {
    return this.dhx.getFormatedDate('', oDate);
  },

  /**
     * Gets the unformatted value (date object) of a formatted value.
     * @param {String} cFormattedDate Formatted date string
     * @return {Object} The date object
     * @instance
     * @memberOf ak_calendar
    */
  getUnformattedDate: function(cFormattedDate) {
    return this.dhx._strToDate(cFormattedDate);
  },

  destroy: function() {
    const oCalInput = this.form.getInput(this.opt.name);
    const oCalendarObj = this.form.getCalendar(this.opt.name);

    $(oCalInput).off('change');

    oCalendarObj.unload();

  }
});
