function dataConnector() {
  this.object = null;
  this.updatedRows = []; // ids of updated rows
  this.autoUpdate = true;
  this.updateMode = 'cell';
  this._headers = null;
  this._payload = null;
  this._waitMode = 0;
  this._in_progress = {};// ?
  this._invalid = {};
  this.mandatoryFields = [];
  this.messages = [];

  this.styles = {
    updated: 'font-weight:bold;',
    inserted: 'font-weight:bold;',
    deleted: 'text-decoration : line-through;',
    invalid: 'background-color:FFE0E0;',
    invalid_cell: 'border-bottom:2px solid red;',
    error: 'color:red;',
    clear: 'font-weight:normal;text-decoration:none;'
  };

  this.enableUTFencoding(true);
  dhx4._eventable(this);

  return this;
}

dataConnector.prototype = {
  enableUTFencoding: function(mode) {
    this._utf = dhx4.s2b(mode);
  },

  setUpdated: function(rowId, state, mode) {
    if (this._silent_mode) return;
    const ind = this.findRow(rowId);

    // handling to ensure consistent state naming
    mode = this.obj.data._old_names[mode] || mode || 'updated';
    if (mode !== 'inserted' && mode !== 'updated' && mode !== 'deleted')
      akioma.log.warn(`Unknown data mode '${mode}'!`);

    const oldState = this.getState(rowId);
    if (oldState === 'inserted' && mode === 'deleted')
      state = false;

    const existing = this.obj.getUserData(rowId, this.action_param);
    if (existing && mode == 'updated') mode = existing;
    if (state) {
      this.set_invalid(rowId, false); // clear previous error flag
      this.updatedRows[ind] = rowId;
      this.obj.setUserData(rowId, this.action_param, mode);
      if (this._in_progress[rowId])
        this._in_progress[rowId] = 'wait';
    } else if (!this.is_invalid(rowId)) {
      this.updatedRows.splice(ind, 1);
      this.obj.setUserData(rowId, this.action_param, '');
    }

    // clear changed flag
    if (!state)
      this._clearUpdateFlag(rowId);

    this.markRow(rowId, state, mode);
  },
  _clearUpdateFlag: function() {},
  markRow: function(id, state, mode) {
    let str = '';
    const invalid = this.is_invalid(id);
    if (invalid) {
      str = this.styles[invalid];
      state = true;
    }
    if (this.callEvent('onRowMark', [ id, state, mode, invalid ])) {
      // default logic
      str = this.styles[state ? mode : 'clear'] + str;

      this.obj[this._methods[0]](id, str);

      if (invalid && invalid.details) {
        str += this.styles[`${invalid}_cell`];
        for (let i = 0; i < invalid.details.length; i++) {
          if (invalid.details[i])
            this.obj[this._methods[1]](id, i, str);
        }
      }
    }
  },
  getState: function(id) {
    return this.obj.getUserData(id, this.action_param);
  },
  setState: function(id, mode) {
    this.obj.setUserData(id, this.action_param, mode);
  },
  is_invalid: function(id) {
    return this._invalid[id];
  },
  set_invalid: function(id, mode, details) {
    if (details) {
      mode = {
        value: mode, details: details, toString: function() {
          return this.value.toString();
        }
      };
    }
    this._invalid[id] = mode;
  },

  checkBeforeUpdate: function() {
    return true;
  },

  sendData: function(rowId) {
    if (this._waitMode && (this.obj.mytype == 'tree' || this.obj._h2)) return;
    if (this.obj.editStop) this.obj.editStop();


    if (typeof rowId == 'undefined' || this._tSend) return this.sendAllData();
    if (this._in_progress[rowId]) return false;

    this.messages = [];
    if (this.getState(rowId) !== 'deleted')
      if (!this.checkBeforeUpdate(rowId) && this.callEvent('onValidationError', [ rowId, this.messages ])) return false;

    this._beforeSendData(this._getRowData(rowId), rowId);
  },
  _beforeSendData: function(data, rowId) {
    if (!this.callEvent('onBeforeUpdate', [ rowId, this.getState(rowId), data ])) return false;
    this._sendData(data, rowId);
  },

  _getAllData: function() {
    const out = {};
    let has_one = false;
    for (let i = 0; i < this.updatedRows.length; i++) {
      const id = this.updatedRows[i];
      if (this._in_progress[id] || this.is_invalid(id)) continue;
      if (!this.callEvent('onBeforeUpdate', [ id, this.getState(id), this._getRowData(id) ])) continue;
      out[id] = this._getRowData(id, id + this.post_delim);
      has_one = true;
      this._in_progress[id] = (new Date()).valueOf();
    }
    return has_one ? out : null;
  },

  findRow: function(pattern) {
    let i = 0;
    for (i = 0; i < this.updatedRows.length; i++)
      if (pattern == this.updatedRows[i]) break;
    return i;
  },

  init: function(anObj) {
    this.obj = anObj;
    if (anObj._dp_init)
      anObj._dp_init(this);
    if (this.connector_init)
      anObj._dataprocessor = this;
  },
  sendAllData: function() {
    if (!this.updatedRows.length) return;

    this.messages = []; let valid = true;
    for (let i = 0; i < this.updatedRows.length; i++) {
      if (this.getState(this.updatedRows[i]) !== 'deleted')
        valid &= this.checkBeforeUpdate(this.updatedRows[i]);
    }

    if (!valid && !this.callEvent('onValidationError', [ '', this.messages ])) return false;

    if (this._tSend)
      this._sendData(this._getAllData());
    else {
      this.callEvent('onBeforeSaveChanges', []);
      for (let i = 0; i < this.updatedRows.length; i++) {
        if (!this._in_progress[this.updatedRows[i]]) {
          if (this.is_invalid(this.updatedRows[i])) continue;
          if (this.beforeSendDataAll == undefined)
            this._beforeSendData(this._getRowData(this.updatedRows[i]), this.updatedRows[i]);
          else {
            const aStates = [];
            const aRowsData = [];
            const aRowsIDs = [];
            const cState = this.getState(this.updatedRows[i]);
            aRowsIDs.push(this.updatedRows[i]);
            aStates.push(cState);
            aRowsData.push(this._getRowData(this.updatedRows[i]));

            this.callEvent('onBeforeUpdate', [ aRowsIDs, aStates, aRowsData ]);
          }

          if (this._waitMode && (this.obj.mytype == 'tree' || this.obj._h2)) return; // block send all for tree
        }
      }
    }

  }
};
