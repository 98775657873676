/**
 * The Notification Message object, created for handling the Notifications (Non-modal) top right messages
 * @class NotificationMessage
 * @namespace akioma
 */

akioma.NotificationMessage = class {

  static vueInstanceArray = [];

  /**
     * Displays a notification message to the user
     * @memberOf uiMessage
     * @param {object} opts
     */
  static message(opts) {

    let msgId;

    // check for lifetime -> change to expire
    if (!opts.expire && opts.lifetime)
      opts.expire = opts.lifetime;

    if (opts.expire == undefined)
      opts.expire = this.expiredef || 10000;

    if (opts.expire == 0)
      opts.expire = -1;

    let type = 'error';
    if (opts.type !== undefined)
      type = opts.type.toLowerCase();

    // show notification based on type
    switch (type) {
      case 'information':
      case 'info':
      case 'question':
        msgId = this.showInfo(opts);
        break;
      case 'warning':
      case 'warn':
        msgId = this.showWarning(opts);
        break;
      case 'success':
        msgId = this.showSuccess(opts);
        break;
      case 'error':
      case 'alert':
        msgId = this.showError(opts);
        break;
      case 'link':
        msgId = this.showLink(opts);
        break;
      default:
        msgId = this.showError(opts);
    }

    return msgId;
  }

  /**
   * Method for showing link type notification messages
   * @param  {object} opts
   * @example
   * akioma.NotificationMessage.showLink({ message: 'Hello from Akioma!', link: 'link-here' });
   * @memberOf uiMessage
   * @return {void}
   */
  static showLink(opts) {

    opts.text = this.buildLinkMessage(opts.message, opts.linkValue, opts.linkDesc, opts.bottom, opts.options);

    return this.showNotification(opts);
  }

  /**
   * Build Link message used for 'link' type
   * @param  {string} cMessage
   * @param  {string} cLinkValue
   * @param  {string} cLinkDesc
   * @param  {string} cBottom
   * @param  {string} cOptions
   * @memberOf uiMessage
   * @return {string} The html string of the notification
   */
  static buildLinkMessage(cMessage = '', cLinkValue = '', cLinkDesc = '', cBottom = '', cOptions = '') {
    let cLink = '',
      cClick = '',
      cTarget = '';

    cLink = `javascript: akioma.NotificationMessage.runLink( "${cLinkValue}", "${cLinkDesc}", "${cOptions}" ); `;

    if (_isIE)
      cClick = `${cLink} event.cancelBubble = true; return false;`;
    else if (_isFF)
      cClick = `${cLink} arguments[0].cancelBubble = true; return false;`;
    else
      cClick = `${cLink} arguments[0].cancelBubble = true; return false;`;

    if (cMessage != '')
      cMessage += '<br/>';

    cLink = '';
    cTarget = '_blank';

    cMessage += `<a href='${cLink}' onclick='${cClick}' target='${cTarget}'>${cLinkDesc}</a>`;

    if (cBottom != '')
      cMessage += `<br/>${cBottom}`;

    return cMessage;
  }

  /**
     * Method used for opening the link created using akioma.notification
     * @param  {string} cLink
     * @param  {string} cLinkDesc
     * @param  {string} cOptions
     * @memberOf uiMessage
     * @private
     * @return {void}
     */
  static runLink(cLink, cLinkDesc, cOptions) {

    // hide the current open box
    dhtmlx.message.hide('openingBox');

    if (cLink.substring(0, 1) === '$')
      app.controller.callAkiomaCode (cOptions, cLink);
    else {
      app.controller.launchContainer({
        proc: 'launchContainer.r',
        para: `SelfHdl=${cLink}&Page=0,1`,
        data: true,
        extLink: cLink,
        self: this
      });
    }
  }

  /**
     * Method for showing info Success notification messages
     * @param  {object} opt List of possible options
     * @param {object} opt.moretext The text to add inside the resulting window, eg. error callstack
     * @param {string} opt.text Text used in notification popup
     * @param {string} opt.expire 0 if you want it to never expire or number of miliseconds, default is 10 seconds if not specified
     * @example
     * akioma.NotificationMessage.showInfo({ text: 'Hello from Akioma!' });
     * @memberOf uiMessage
     * @return {void}
     */
  static showSuccess(opt) {
    let cText = opt.text;

    // check for lifetime -> change to expire
    if (!opt.expire && opt.lifetime)
      opt.expire = opt.lifetime;

    if (opt.expire == undefined)
      opt.expire = this.expiredef || 10000;

    if (opt.expire == 0)
      opt.expire = -1;

    opt.text = replaceNewLines(opt.text);

    let uniqueMoreID;
    if (opt.moretext && !opt.linkValue) {
      uniqueMoreID = `morebtn-${dhtmlx.uid()}`;
      cText = `<p>${opt.text}</p><a href="#" id="${uniqueMoreID}">More</a>`;
    }

    opt.text = cText;
    opt.lifetime = opt.lifetime ? opt.lifetime : this.expiredef; // keep for old support
    opt.expire = opt.expire ? opt.expire : this.expiredef;
    opt.type = 'success';

    const msgId = this.showNotification(opt);

    if (opt.moretext && uniqueMoreID)
      this.attachMoreWindow(opt, uniqueMoreID);

    return msgId;
  }

  /**
     * Method for showing info type notification messages
     * @param  {object} opt List of possible options
     * @param {object} opt.moretext The text to add inside the resulting window, eg. error callstack
     * @param {string} opt.text Text used in notification popup
     * @param {string} opt.expire 0 if you want it to never expire or number of miliseconds, default is 10seconds if not specified
     * @example
     * akioma.NotificationMessage.showInfo({ text: 'Hello from Akioma!' });
     * @memberOf uiMessage
     * @return {void}
     */
  static showInfo(opt) {
    let cText = opt.text;

    // check for lifetime -> change to expire
    if (!opt.expire && opt.lifetime)
      opt.expire = opt.lifetime;

    if (opt.expire == undefined)
      opt.expire = this.expiredef || 10000;

    if (opt.expire == 0)
      opt.expire = -1;

    opt.text = replaceNewLines(opt.text);

    let uniqueMoreID;
    if (opt.moretext && !opt.linkValue) {
      uniqueMoreID = `morebtn-${dhtmlx.uid()}`;
      cText = `<p>${opt.text}</p><a href="#" id="${uniqueMoreID}">More</a>`;
    }

    opt.text = cText;
    opt.lifetime = opt.lifetime ? opt.lifetime : this.expiredef; // keep for old support
    opt.expire = opt.expire ? opt.expire : this.expiredef;
    opt.type = 'info';

    const msgId = this.showNotification(opt);

    if (opt.moretext && uniqueMoreID)
      this.attachMoreWindow(opt, uniqueMoreID);

    return msgId;
  }

  /**
     * uiMessage more notification type window, sets up event listener used when clicking on more button in nofification
     * @param  {object} opt
     * @param {object} opt.moretext The text to add inside the resulting window, eg. error callstack
     * @param  {string} uniqueMoreID The name of the DOM element, more button in notification
     * @private
     * @memberOf uiMessage
     * @return {void}              [description]
     */
  static attachMoreWindow(opt, uniqueMoreID) {
    $(`#${uniqueMoreID}`).on('click', () => {
      akioma.createModalWindows();

      const win = akioma.oWindowsModals.createWindow({
        id: dhtmlx.uid(),
        caption: akioma.tran('messageBox.title.more', { defaultValue: 'More information' }),
        width: 600,
        height: 500
      });
      win.button('park').hide();
      win.button('minmax').hide();
      win.centerOnScreen();
      win.bringToTop();
      win.setModal(true);

      // attach html content
      // console.log(opt.moretext);
      $(win.cell).find('.dhx_cell_cont_wins').css('overflow', 'auto');
      win.attachHTMLString(`<div style="color:#B22556;word-break:break-word;padding: 20px;white-space: pre;">${opt.moretext}</div>`);
    });
  }

  /**
     * Display notification of type error
     * @param  {object} opt List of possible options
     * @param {object} opt.moretext The text to add inside the resulting window, eg. error callstack
     * @param {string} opt.text Text used in notification popup
     * @param {string} opt.expire 0 if you want it to never expire or number of miliseconds, default is 10seconds if not specified
     * @example
     * // display an error that will show up for 3 seconds  and include the more button for error info
     * akioma.NotificationMessage.showError({ text: 'Something bad happend!' , moretext: JSON.stringify(request.response, null, 4), expire: 3000 });
     * @memberOf uiMessage
     * @return {void}
     */
  static showError(opt) {
    let cText = opt.text;

    // check for lifetime -> change to expire
    if (!opt.expire && opt.lifetime)
      opt.expire = opt.lifetime;

    if (opt.expire == undefined)
      opt.expire = this.expiredef || 10000;

    if (opt.expire == 0)
      opt.expire = -1;

    opt.text = replaceNewLines(opt.text);

    let uniqueMoreID;
    if (opt.moretext && !opt.linkValue) {
      uniqueMoreID = `morebtn-${dhtmlx.uid()}`;
      cText = `<p>${opt.text}</p><a href="#" id="${uniqueMoreID}">More</a>`;
    }

    opt.text = cText;
    opt.lifetime = opt.lifetime ? opt.lifetime : this.expiredef; // keep for old support
    opt.expire = opt.expire ? opt.expire : this.expiredef;
    opt.type = 'error';

    const msgId = this.showNotification(opt);

    if (opt.moretext && uniqueMoreID)
      akioma.NotificationMessage.attachMoreWindow(opt, uniqueMoreID);

    return msgId;
  }

  /**
     * Method for clearing all visibile notifications
     * @memberOf uiMessage
     * @return {void}
     */
  static clearAllMessages() {
    for (const i in dhtmlx.message.pull)
      dhtmlx.message.hide(i);
  }

  /**
     * Display notification of type Warning
     * @param  {object} opt List of possible options
     * @param {string} opt.text Text used in notification popup
     * @param {string} opt.expire 0 if you want it to never expire or number of miliseconds, default is 10seconds if not specified
     * @example
     * akioma.NotificationMessage.showWarning({ text: 'Something bad happend!', expire: 3000 });
     * @memberOf uiMessage
     * @return {void}
     */
  static showWarning(opt) {
    // check for lifetime -> change to expire
    if (!opt.expire && opt.lifetime)
      opt.expire = opt.lifetime;

    if (opt.expire == undefined)
      opt.expire = this.expiredef || 10000;

    if (opt.expire == 0)
      opt.expire = -1;

    opt.text = replaceNewLines(opt.text);

    opt.lifetime = opt.lifetime ? opt.lifetime : this.expiredef; // keep for old support
    opt.expire = opt.expire ? opt.expire : this.expiredef;
    opt.type = 'warning';

    return this.showNotification(opt);
  }

  /**
     * Fetches the message text, type, details etc. for the message number.
     * Note that the function is async and returns a jQuery promise (see example).
     * @param {string} msgGroup The message group
     * @param {number} msgNum The message number
     * @example
     * akioma.NotificationMessage.getMessage("OpenEdge", 1).done(function(result) {console.log(result)});
     * @memberOf uiMessage
     * @return {promise} Returns a jQuery promise
     */
  static getMessage(msgGroup, msgNum) {

    return akioma.SmartMessage.getMessage(msgGroup, msgNum);
  }


  /**
     * Displays a message box with the text, title, type etc. for a message number
     * @param {string} msgGroup The message group
     * @param {number} msgNum The message number
     * @param {object} opts Additional akioma.message options or callback function
     * @example
     * akioma.NotificationMessage.displayMessage("OpenEdge", 1, function(result) {console.log("Done")});
     * @memberOf uiMessage
     * @return {promise} Returns a jQuery promise
     */
  static displayMessage(msgGroup, msgNum, opts) {

    opts = opts || {};

    const ret = akioma.NotificationMessage.getMessage(msgGroup, msgNum);

    ret.done(function(result) {
      const msg = { text: result.MessageText };

      if (opts.showMessageCode === false)
        msg.text = msg.text.replace(/ *\([^)]*\) */g, '');

      switch (result.MessageType.toLowerCase()) {
        case 'information':
          msg.type = 'information';
          break;

        case 'error':
          msg.type = 'error';
          break;

        case 'warning':
          msg.type = 'warning';
          break;

        case 'success':
          msg.type = 'success';
          break;
      }

      if (typeof opts === 'function')
        msg.callback = opts;
      else
        Object.assign(msg, opts);

      this.showNotification(msg);
    });

    return ret;
  }

  /**
     * Dhtmlx notification type messages
     * @memberof uiMessage
     * @param {object} opts
     */
  static showNotification(opts) {

    opts.text = replaceNewLines(opts.text);

    if (opts.callback)
      opts.callback();

    if (opts.vue)
      return this.vueNotification(opts);
    else {
      let oMsgId = dhtmlx.message(opts); // dhtmlx returns either integer id or div; for id toString

      if (typeof (oMsgId) === 'number')
        oMsgId = oMsgId.toString();

      return oMsgId;
    }
  }

  /**
   * Remove VUE notification type message by id
   * @memberof uiMessage
   * @param {string} id Id of message to be removed from store
   */
  static removeVueNotification(id) {

    if (!id)
      return;

    const $msg = $(document).find(`#${id}`);
    if (!$msg)
      return;

    const msgNode = $msg[0];
    if (!msgNode)
      return;

    const opts = { id: id, hidden: true };
    akioma.VuexStore.dispatch('notificationMessage/updateMessage', opts).then(() => {
      window.setTimeout(() => {
        const index = this.vueInstanceArray.findIndex(elem => elem.id === id);

        if (index < 0)
          return;

        this.vueInstanceArray[index].instance.unmount();
        this.vueInstanceArray.splice(index, 1);

        akioma.VuexStore.dispatch('notificationMessage/removeMessageById', id);
        msgNode.parentNode.removeChild(msgNode);
      }, 2000);
    });
  }

  /**
   * Update VUE notification type message
   * @memberof uiMessage
   * @param {object} opts
   */
  static updateVueNotification(opts) {

    if (!opts.id)
      return;

    const msg = akioma.VuexStore.getters['notificationMessage/getMessageById'](opts.id);
    if (!msg) {
      akioma.notification({ type: 'error', text: 'Message with given id does not exists.' });
      return;
    }

    switch (opts.type) {
      case 'information':
      case 'question':
        opts.type = 'info';
        break;
      case 'warn':
        opts.type = 'warning';
        break;
      case 'alert':
        opts.type = 'error';
    }

    opts.text1 && (opts.text = opts.text1);
    opts.text2 && (opts.text += ` ${opts.text2}`);
    opts.doc && (opts.text += `<br/><a target='blank' href='${opts.doc}'>${opts.docDesc}</a>`);

    opts.text = replaceNewLines(opts.text);

    let uniqueMoreID = '';

    if (opts.type === 'link')
      opts.text = this.buildLinkMessage(opts.message, opts.linkValue, opts.linkDesc, opts.bottom, opts.options);
    else if (opts.linkValue) opts.text += `<br/><a target='blank' href='${opts.linkValue}'>${opts.linkDesc}</a>`;
    else if (opts.moretext && opts.type !== 'warning' && opts.type !== 'link') {
      uniqueMoreID = `morebtn-${dhtmlx.uid()}`;
      opts.text = `<p>${opts.text}</p><a href="#" id="${uniqueMoreID}">More</a>`;
    }

    opts.text = (opts.title ? (`<b>${opts.title}</b><br>${opts.text}`) : opts.text);

    if (opts.expire) {
      const oldTimeout = akioma.VuexStore.getters['notificationMessage/timeout'](opts.id);
      if (oldTimeout)
        clearTimeout(oldTimeout);

      if (opts.expire > 0) {
        const timeout = window.setTimeout(() => {
          this.removeVueNotification(opts.id);
        }, opts.expire);

        opts.timeout = timeout;
      } else
        opts.timeout = null;
    }

    akioma.VuexStore.dispatch('notificationMessage/updateMessage', opts).then(() => {
      if (opts.moretext && uniqueMoreID)
        this.attachMoreWindow(opts, uniqueMoreID);
    });
  }

  /**
   * VUE notification type messages
   * @memberof uiMessage
   * @param {object} opts
   */
  static vueNotification(opts) {

    if (opts.id) {
      const msg = akioma.VuexStore.getters['notificationMessage/getMessageById'](opts.id);

      if (msg) {
        akioma.notification({ type: 'error', text: 'Message with given id already exists.' });
        return;
      }
    }

    opts.text1 && (opts.text = opts.text1);
    opts.text2 && (opts.text += ` ${opts.text2}`);
    opts.doc && (opts.text += `<br/><a target='blank' href='${opts.doc}'>${opts.docDesc}</a>`);

    if (opts.type !== 'link' && opts.linkValue)
      opts.text += `<br/><a target='blank' href='${opts.linkValue}'>${opts.linkDesc}</a>`;

    opts.text = (opts.title ? (`<b>${opts.title}</b><br>${opts.text}`) : opts.text);
    opts.text = replaceNewLines(opts.text);

    if (opts.callback)
      opts.callback();

    opts.html = '<div id=\'&1\'></div>';
    dhtmlx.message(opts);

    opts.id = opts.id.toString();

    try {
      if (opts.expire > 0) {
        const timeout = window.setTimeout(() => {
          this.removeVueNotification(opts.id);
        }, opts.expire);

        opts.timeout = timeout;
      }

      akioma.VuexStore.dispatch('notificationMessage/addMessage',
        {
          id: opts.id,
          type: opts.type,
          text: opts.text,
          timeout: opts.timeout,
          hidden: false
        });

      const $msg = $(document).find(`#${opts.id}`);
      const msgNode = $msg[0];

      const vueInstance = new akioma.VueInstancesFactory('NotificationMessage', { id: opts.id });
      vueInstance.mount(msgNode);

      this.vueInstanceArray.push({ id: opts.id, instance: vueInstance });
      $msg.on('click', () => {
        this.removeVueNotification(msgNode.id);
      });

      return opts.id;
    } catch (e) {
      console.error(e);
    }
  }
};

// def property
akioma.NotificationMessage.expiredef = 10000;
