/**
 * Class responsible for handling the designer Form Attributes
 * @class AttributeForm
 */
akioma.repository.AttributeForm = class {
  /**
   * Method used for init configuration setup
   */
  static init() {
    this.supportedFormAttributesObjectTypes = [ 'datafield', 'select', 'window', 'page', 'tabbar', 'grid', 'form', 'toolbar', 'ribbon', 'businessentity' ]; // all the others will load empty frame
  }

  /**
   * Method executed before a fetch on the businessEntity positioning the AttributeForm
   * @param {ak_businessEntity} attributeMasterBE
   */
  static onBeforeFetchObjectMasterEvent(attributeMasterBE) {
    let parentSourceActiveEntity = attributeMasterBE.controller.dataSource.opt.entityName;
    const oStoreActive = attributeMasterBE.controller.dataSource.getStore(parentSourceActiveEntity);
    if (oStoreActive) {
      const activeRecord = oStoreActive.item(oStoreActive.getCursor());
      const beQueryObj = attributeMasterBE.controller.getQuery();

      // in case new instance replace was performed, use object master record for fetching the attribute form data
      const designerObj = this.getDesignerFromContainer(attributeMasterBE);
      let placeholderInstance;
      let oldPlaceholderGuid;
      // special handling only for layoutdesigner
      if (designerObj && designerObj.view === 'designer') {
        const isNewInstanceRecordReplaced = activeRecord && designerObj.newInstances.indexOf(activeRecord.objectinstanceguid) >= 0;
        placeholderInstance = designerObj.newPlaceholderInstances.find(item => item.newInstanceGuid === activeRecord.objectinstanceguid);

        if (placeholderInstance)
          oldPlaceholderGuid = placeholderInstance.oldInstanceGuid;
        else if (isNewInstanceRecordReplaced)
          parentSourceActiveEntity = 'eSmartObjectMaster';

      }

      if (designerObj && designerObj.view === 'graphEditor') {
        const isNewInstanceRecordReplaced = activeRecord && designerObj.newInstances.indexOf(activeRecord.objectinstanceguid) >= 0;

        if (isNewInstanceRecordReplaced)
          parentSourceActiveEntity = 'eSmartObjectMaster';
      }

      let newPageGuid;
      if (designerObj && activeRecord && designerObj.newPageInstances.indexOf(activeRecord.pageguid) >= 0) {
        parentSourceActiveEntity = 'eSmartPage';
        newPageGuid = akioma.repository.LayoutDesigner.mockPageGuid;
      }

      // filter by active record and by active entity?
      if (activeRecord) {
        beQueryObj.clearAll();
        if (parentSourceActiveEntity === 'eSmartObjectInstance')
          beQueryObj.addUniqueCondition('objectinstanceguid', 'eq', oldPlaceholderGuid || activeRecord.objectinstanceguid);
        else if (parentSourceActiveEntity === 'eSmartObjectMaster')
          beQueryObj.addUniqueCondition('objectmasterguid', 'eq', activeRecord.objectmasterguid);
        else if (parentSourceActiveEntity === 'eSmartPage')
          beQueryObj.addUniqueCondition('pageguid', 'eq', newPageGuid || activeRecord.pageguid);

        attributeMasterBE.controller.setTableRefFilter(parentSourceActiveEntity);
      }
    } else
      akioma.log.warn('No store found for ', parentSourceActiveEntity, attributeMasterBE);


  }

  /**
   * Method for setting attribute form field value from attribute properties
   * @param {ak_form} attributeForm
   * @param {string} attributeValue Value of attribute
   * @param {string} fieldName Name of form field
   * @param {string} attributeValue Field value
   *
   */
  static setAttributeFormFieldValue(attributeForm, fieldName, attributeValue) {
    try {
      const field = attributeForm.getObject(fieldName);

      if (field) {
        if (field.type === 'dynselect') {
          if (isNull(attributeValue))
            field.controller.setValue('id', '');
          else {
            if (field.controller.listItemPairs) {
              const optionsList = field.controller.listItemsArray;
              const option = optionsList.find(obj => obj.value === attributeValue);
              attributeValue = [{ listItemKey: attributeValue, listItemDesc: option ? option.text : '' }];
            }

            field.controller.ignoreValidateEvent = true;
            field.controller.dynSelectControl.positionToRecord(attributeValue).then(() => {
              field.controller.ignoreValidateEvent = false;
            });
          }
        } else {
          field.controller.ignoreValidateEvent = true;
          field.setValue(attributeValue);
          field.controller.ignoreValidateEvent = false;
        }
      }

      akioma.log.info('Updated form field value', attributeValue);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Method for returning designer object inside given object container
   * @param {dynObject} repositoryObject
   * @returns {ak_designer|ak_formbuilder} designer object from container
   */
  static getDesignerFromContainer(repositoryObject) {
    return repositoryObject.container.getFirstChildByType('designer') || repositoryObject.container.getFirstChildByType('formbuilder') || repositoryObject.container.getFirstChildByType('graphEditor');
  }

  /**
   * Method for getting the attribute value
   * @param {ak_form} attributeForm Attribute form
   * @param {string} attributeLabel Attribute label
   */
  static getAttributeValue(attributeForm, attributeLabel) {
    const propStore = attributeForm.controller.getAncestor('panelSwitcher').getAncestor('frame').dynObject.getFirstChildByType('propertygrid').propStore.data.pull;
    const attributeObj = propStore[attributeLabel];
    const valueFieldName = (`${attributeObj.repositorytype}value`).toLowerCase();
    const attributeValue = attributeObj ? attributeObj[valueFieldName] : null; // get attribute value for field named

    return attributeValue;
  }

  /**
   * Method for setting the form field value from the repository attribute value
   * @param {ak_form} attributeForm The attribute form control
   * @param {string} attributeLabel The label of the repository attribute
   * @param {string} formFieldName The name of the form field
   */
  static setFormDesignerAttribute(attributeForm, attributeLabel, formFieldName) {
    const attributeValue = this.getAttributeValue(attributeForm, attributeLabel);
    this.setAttributeFormFieldValue(attributeForm, formFieldName, attributeValue);
  }

  /**
   * Method for setting template parts of a form field value in a repository attribute at the given index
   * @param {ak_form} attributeForm
   * @param {string} attributeLabel
   * @param {string} formFieldName
   * @param {integer} index
   * @param {string} delimiter
   */
  static setFormDesignerAttributePart(attributeForm, attributeLabel, formFieldName, index = 0, delimiter = '|') {
    let attributeValue = this.getAttributeValue(attributeForm, attributeLabel); // get attribute value for field named
    if (attributeValue)
      attributeValue = attributeValue.split(delimiter)[index].replaceAll('\'', '');

    this.setAttributeFormFieldValue(attributeForm, formFieldName, attributeValue);
  }

  /**
   * Method for setting template parts of a form field value in a repository attribute at the given indexes
   * @param {ak_form} attributeForm The attribute form object
   * @param {string} attributeLabel The repository attribute label
   * @param {string} formFieldName The name of the form field
   * @param {array} indexes Indexes to replace the found occurences in template attribute type
   * @param {string} delimiter Default is '|' for multiple values separator
   */
  static setFormDesignerAttributeParts(attributeForm, attributeLabel, formFieldName, indexes, delimiter = '|') {
    let attributeValue = this.getAttributeValue(attributeForm, attributeLabel); // get attribute value for field named
    if (attributeValue) {
      const finalVal = [];
      for (let x = 0; x < indexes.length; x++) {
        const index = indexes[x];
        const templateFieldsVal = attributeValue.split('#')[0] || '';
        const val = templateFieldsVal.split(delimiter)[index];
        if (val)
          finalVal.push(val);
      }
      const field = attributeForm.getObject(formFieldName);
      attributeValue = finalVal.join(delimiter);

      if (finalVal.length > 0) {
        field.controller.setValue({
          id: attributeValue,
          desc: attributeValue,
          [formFieldName]: attributeValue
        });
      }
    }
  }

  /**
   * Method for clearing the attribute forms has changes flag for each panelswitcher child
   * @param {ak_panelSwitcher} panelSwitcher
   */
  static clearHasChangesAttributeForms(panelSwitcher) {
    const formList = panelSwitcher.getAllChildrenByType('form');
    formList.forEach(form => form.clearHasChanges());
  }

  /**
   * Method for getting object type from dataSource record
   * @param {object} record
   * @returns {string} Object type
   */
  static getObjectTypeFromRepositoryRecord(record) {
    const objectTypeName = (record.objecttypename || record.instancetypename || '').toLowerCase();
    if (objectTypeName)
      return objectTypeName;

    if (record.pageguid)
      return 'swatpage';

  }

  /**
   * Method for returning active entity form from the given panelSwitcher object
   * @param {ak_panelSwitcher} panelSwitcher
   */
  static getActiveEntityForm(panelSwitcher) {
    const currentView = panelSwitcher.getCurrentView();
    if (currentView === 'def')
      return null;
    return panelSwitcher.dynObject.getObject(currentView).getFirstChildByType('form');
  }

  /**
   * Method called on a AttributeForm object to set the selected instance attribute values in form
   * @param {ak_form} attributeForm
   */
  static async onFormAfterDisplay(attributeForm) {

    // wait for attributes to load
    const panelSwitcherFrame = attributeForm.getFirstParentByType('frame').dynObject;
    const frame = attributeForm.getFirstParentByType('frame');
    const parentView = frame.inParentView;
    const panelSwitch = attributeForm.getFirstParentByType('panelSwitcher');
    const propertyGrid = panelSwitcherFrame.parent.getFirstParentByType('frame').dynObject.getFirstChildByType('propertygrid');
    await propertyGrid.getAttributesPromise;

    if (panelSwitch) {
      const currentView = panelSwitch.getCurrentView();
      if (currentView !== parentView)
        return;

    }
    try {

      // set master entityName for newly created instances
      const designerObj = this.getDesignerFromContainer(attributeForm);
      let newInstanceEntityName;

      if (designerObj && (designerObj.view === 'graphEditor' || designerObj.view === 'designer') && designerObj.dataSource) {
        const instanceStore = designerObj.dataSource.getStore('eSmartObjectInstance');
        const isNewInstanceRecordReplaced = instanceStore && designerObj.newInstances.indexOf(instanceStore.getCursor()) >= 0;

        if (isNewInstanceRecordReplaced)
          newInstanceEntityName = 'eSmartObjectMaster';
      }

      // set attribute value for selected instance in form
      const activeEntity = newInstanceEntityName || this.getActiveEntityForm(panelSwitch).opt.entityName;
      const activeStore = attributeForm.controller.dataSource.getStore(activeEntity);
      const selectedItem = activeStore.item(activeStore.first());
      const objectTypeName = this.getObjectTypeFromRepositoryRecord(selectedItem);

      // set form field values from attributes, currently selected object type
      switch (objectTypeName) {
        case 'swatpage': {
          // set attrs
          const designer = this.getDesignerFromContainer(attributeForm);
          const pageIndex = designer.getPageIndex(selectedItem.pageguid);
          const attributeValueLayout = designer.getPageLayoutPattern(pageIndex);
          attributeForm.getObject('layout').setValue({ selfkey: attributeValueLayout, layout: attributeValueLayout });
          break;
        }
        case 'swattabbar': {
          // set attrs
          const attributeValueVis = this.getAttributeValue(attributeForm, 'TabVisualization');
          this.setAttributeFormFieldValue(attributeForm, 'TabVisualization', attributeValueVis.toUpperCase());

          const attributeValueLayout = this.getAttributeValue(attributeForm, 'viewTemplate');
          this.setAttributeFormFieldValue(attributeForm, 'viewTemplate', attributeValueLayout);
          break;
        }
        case 'swatwindow': {
          // const attributeValueLayout = this.getAttributeValue(attributeForm, 'Layout');
          const designer = this.getDesignerFromContainer(attributeForm);
          const attributeValueLayout = designer.getMasterLayoutPattern();
          attributeForm.getObject('layout').setValue({ selfkey: attributeValueLayout, layout: attributeValueLayout });
          this.setFormDesignerAttribute(attributeForm, 'TITLE', 'TITLE');
          this.setFormDesignerAttribute(attributeForm, 'titleShort', 'titleShort');
          this.setFormDesignerAttribute(attributeForm, 'TITLE', 'TITLE');
          this.setFormDesignerAttribute(attributeForm, 'icon', 'icon');
          this.setFormDesignerAttribute(attributeForm, 'hasErrorsStyle', 'hasErrorsStyle');
          this.setFormDesignerAttribute(attributeForm, 'hasChangesStyle', 'hasChangesStyle');
          this.setFormDesignerAttribute(attributeForm, 'hasChangesTrackingEnabled', 'hasChangesTracking');
          break;
        }
        case 'swatselect': {
          // set fields for swat select object type
          this.setFormDesignerAttribute(attributeForm, 'Template', 'templateselect');

          const attributeValueBEName = this.getAttributeValue(attributeForm, 'EntityName');
          attributeForm.getObject('EntityName').setValue({ entityname: attributeValueBEName });

          const attributeValueBETable = this.getAttributeValue(attributeForm, 'EntityTable');
          attributeForm.getObject('EntityTable').setValue({ entitytable: attributeValueBETable });

          this.setFormDesignerAttributeParts(attributeForm, 'templateOptions', 'resultsetfields', [ 1, 2 ]);
          break;
        }
        case 'swatdatafield': {
          // set fields for swat datafield object type
          const attributeValueDataType = this.getAttributeValue(attributeForm, 'DATA-TYPE');
          this.setAttributeFormFieldValue(attributeForm, 'DATA-TYPE', attributeValueDataType.toUpperCase());

          this.setFormDesignerAttribute(attributeForm, 'ENABLED', 'ENABLED');
          this.setFormDesignerAttribute(attributeForm, 'Mandatory', 'Mandatory');

          const attributeValueEventAkValidate = this.getAttributeValue(attributeForm, 'EventAkValidate');
          attributeForm.getObject('EventAkValidate').setValue({ eventakvalidate: attributeValueEventAkValidate });

          const attributeValueEventClick = this.getAttributeValue(attributeForm, 'EventClick');
          attributeForm.getObject('EventClick').setValue({ eventclick: attributeValueEventClick });

          const attributeValueEventLeave = this.getAttributeValue(attributeForm, 'EventLeave');
          attributeForm.getObject('EventLeave').setValue({ eventleave: attributeValueEventLeave });

          this.setFormDesignerAttribute(attributeForm, 'InitialValue', 'InitialValue');
          this.setFormDesignerAttribute(attributeForm, 'LABEL', 'LABEL');

          const attributeValueVisualizationType = this.getAttributeValue(attributeForm, 'VisualizationType');
          this.setAttributeFormFieldValue(attributeForm, 'VisualizationType', attributeValueVisualizationType.toUpperCase());

          break;
        }

        case 'swatgrid': {
          const attributeValuePanelMenu = this.getAttributeValue(attributeForm, 'panelMenu');
          attributeForm.getObject('panelMenu').setValue({ panelMenu: attributeValuePanelMenu });

          const attributeValueContextMenu = this.getAttributeValue(attributeForm, 'contextMenu');
          attributeForm.getObject('contextMenu').setValue({ contextMenu: attributeValueContextMenu });

          const attributeValueFloatingActionButton = this.getAttributeValue(attributeForm, 'floatingActionButton');
          attributeForm.getObject('floatingActionButton').setValue({ floatingActionButton: attributeValueFloatingActionButton });

          const attributeValueToolBarKey = this.getAttributeValue(attributeForm, 'ToolBarKey');
          attributeForm.getObject('ToolBarKey').setValue({ ToolBarKey: attributeValueToolBarKey });

          break;
        }

        case 'swatform': {
          const attributeValuePanelMenu = this.getAttributeValue(attributeForm, 'panelMenu');
          attributeForm.getObject('panelMenu').setValue({ panelMenu: attributeValuePanelMenu });

          const attributeValueContextMenu = this.getAttributeValue(attributeForm, 'contextMenu');
          attributeForm.getObject('contextMenu').setValue({ contextMenu: attributeValueContextMenu });

          const attributeValueFloatingActionButton = this.getAttributeValue(attributeForm, 'floatingActionButton');
          attributeForm.getObject('floatingActionButton').setValue({ floatingActionButton: attributeValueFloatingActionButton });

          break;
        }

        case 'swattoolbar': {
          const attributeValueToolBarKey = this.getAttributeValue(attributeForm, 'ToolBarKey');
          attributeForm.getObject('ToolBarKey').setValue({ ToolBarKey: attributeValueToolBarKey });

          const attributeValueLabelDisplay = this.getAttributeValue(attributeForm, 'labelDisplay');
          this.setAttributeFormFieldValue(attributeForm, 'labelDisplay', attributeValueLabelDisplay);

          break;
        }

        case 'swatribbon': {
          const attributeValueToolBarKey = this.getAttributeValue(attributeForm, 'ToolBarKey');
          attributeForm.getObject('ToolBarKey').setValue({ ToolBarKey: attributeValueToolBarKey });

          const attributeValueLabelDisplay = this.getAttributeValue(attributeForm, 'labelDisplay');
          this.setAttributeFormFieldValue(attributeForm, 'labelDisplay', attributeValueLabelDisplay);

          break;
        }

        case 'swatbusinessentity': {
          this.setFormDesignerAttribute(attributeForm, 'SUBTYPE', 'SUBTYPE');

          this.setFormDesignerAttribute(attributeForm, 'ForeignFields', 'ForeignFields');

          const attributeValueResourceName = this.getAttributeValue(attributeForm, 'resourceName');
          attributeForm.getObject('resourceName').setValue({ resourcename: attributeValueResourceName });

          const attributeValueEntityName = this.getAttributeValue(attributeForm, 'EntityName');
          attributeForm.getObject('EntityName').setValue({ entityname: attributeValueEntityName });

          break;
        }
      }
    } catch (e) {
      akioma.log.error(e);
    }
  }

  /**
   * Method called on a data available event of the Form Attributes dataSource
   * @param {ak_businessEntity} businessEntity
   */
  static onFormMasterEntityDataAvailable(businessEntity) {
    try {
      // switch view based on object type
      const activeStore = businessEntity.controller.dataSource.getStore(businessEntity.controller.dataSource.opt.entityName);
      const selectedRecord = activeStore.item(activeStore.getCursor());
      if (selectedRecord) {
        let objType = this.getObjectTypeFromRepositoryRecord(selectedRecord);
        objType = objType.toLowerCase().replace('swat', '');
        const panelSwitcher = businessEntity.getFirstParentByType('frame').dynObject.getFirstChildByType('panelSwitcher');
        const dataSourceEntity = businessEntity.controller.dataSource.opt.entityName;

        // temporary restrictions to prevent error display
        if (this.supportedFormAttributesObjectTypes.includes(objType)) {
          panelSwitcher.switchView(`${objType}DTAFrame`).then(res => {
            try {
            // if no frame then it's still loading frame
              if (!res)
                return;
              const formEntity = res.getDescendant('form');
              if (formEntity) {
              // switch it to the correct entity, for the entity form
                if (formEntity.opt.entityName !== dataSourceEntity) {
                  formEntity.unbindDataSource();
                  formEntity.opt.entityName = dataSourceEntity;
                  formEntity.bindDataSource();
                }

                // show/hide fieldset based on selected entity record master/instance
                this.hideContextBlock(formEntity);

                // sets the attribute values on the attribute form, calls onFormAfterDisplay
                const forms = res.getAllChildrenByType('form');
                if (forms.length >= 1) {
                  const form = forms[forms.length - 1];
                  if (form) {
                    form.dataSource.callAfterPendingRequest(() => {
                      this.onFormAfterDisplay(form.dynObject);
                    });
                  } else
                    akioma.log.error('No attributes form available in the attributes frame!');
                }
              }
            } catch (e) {
              console.error(e);
            }
          });
        } else {
          panelSwitcher.switchView('emptyDTAFrame');
          akioma.log.warn(`${objType} is not yet supported!`);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Method for hiding specific active entity blocks in attribute form
   * @param {ak_form} formAttr
   */
  static hideContextBlock(formAttr) {
    const entityName = formAttr.opt.entityName;

    switch (entityName) {
      case 'eSmartObjectMaster':
        formAttr.showFormField('blockmaster');
        formAttr.hideFormField('blockinstance');
        formAttr.hideFormField('blockpage');
        break;
      case 'eSmartObjectInstance':
        formAttr.showFormField('blockinstance');
        formAttr.hideFormField('blockmaster');
        formAttr.hideFormField('blockpage');
        break;
      case 'eSmartPage':
        formAttr.showFormField('blockpage');
        formAttr.hideFormField('blockmaster');
        formAttr.hideFormField('blockinstance');
        break;
    }
  }

  /**
   * Method executed on a before fetch event for SwatCode type dynselects, filters only for code
   * @param {ak_dynselect} dynSelect
   */
  static onBeforeCodeFetch(dynSelect) {
    const query = dynSelect.controller.businessEntity.getQuery();
    const swatCodeTypeGuid = 'dee40d25-b37c-68bb-7a14-a001d4acb8c3';
    query.addUniqueCondition('objecttypeguid', 'eq', swatCodeTypeGuid);
  }

  /**
   * Method executed on a selection change in one of the swat code dynselects
   * @param {ak_dynselect} dynSelect
   */
  static onCodeSelect(dynSelect) {
    return new Promise(resolve => {
      const lastSelectedPayload = dynSelect.controller.getSelectionPayload();

      if (lastSelectedPayload._newTag) {
        dynSelect.setValue({ [dynSelect.controller.opt.name]: dynSelect.akEvent.currentValue.id });
        resolve();
      } else {
        const selectedRecord = dynSelect.controller.getSelectedRecord();
        const masterDesignAttributesPromise = akioma.RepositoryStructure.fetchMasterDesignAttributes(selectedRecord.objectmasterguid);
        let value;
        masterDesignAttributesPromise.done(data => {
          value = akioma.repository.getDesignAttributeValue(data, 'EventCode');
          // set value with value from attribute
          dynSelect.setValue({ [dynSelect.controller.opt.name]: value });
          resolve();
        });
      }
    });
  }

  /**
   * Method for parsing Attribute values based on form field type
   * @param {string} attributeName Name of attribute
   * @param {ak_object} field Form field
   * @param {string} nValue Value of field
   */
  static parseFieldTypeValue(attributeName, field, nValue) {
    const panelSwitcherFrame = field.getFirstParentByType('frame').dynObject;
    const propertyGrid = panelSwitcherFrame.parent.getFirstParentByType('frame').dynObject.getFirstChildByType('propertygrid');
    const delimiter = '|';
    const el = propertyGrid.propStore.data.pull[attributeName];

    // for icon picker set templateOptions part
    if (field.attributes.name === 'templateiconpicker') {
      const templateParts = el.charactervalue.split(delimiter);
      if (nValue > '')
        nValue = `'${nValue}'`;
      templateParts[0] = nValue;
      el.charactervalue = templateParts.join(delimiter);
      el.attributevalue = templateParts.join(delimiter);
      nValue = templateParts.join(delimiter);
    }

    // for result set templateOptions part
    if (field.attributes.name === 'resultsetfields') {
      const templateParts = el.charactervalue.split(delimiter);
      const tempVal = nValue.split('#')[0];
      const valueParts = tempVal.split(delimiter);

      if (valueParts[0])
        templateParts[1] = valueParts[0];
      else
        templateParts.splice(1, 1);

      if (valueParts[1])
        templateParts[2] = valueParts[1];
      else
        templateParts.splice(2, 1);

      nValue = templateParts.join(delimiter);
    }

    // for template extra options set templateOptions ending part
    if (field.attributes.name === 'templateoptions') {
      const fieldsVal = el.charactervalue.split('#')[0] || '';
      nValue = fieldsVal + nValue;
    }

    return nValue;
  }

  /**
   * Method to return attribute name from form field name
   * @param {string} formFieldName Name of form field
   */
  static getAttrNameFromFieldName(formFieldName) {
    const formFieldNameLowerCase = formFieldName.toLowerCase();
    switch (formFieldNameLowerCase) {
      case 'instancename':
      case 'objectname':
        formFieldName = 'name';
        break;
      case 'templateselect':
        formFieldName = 'Template';
        break;
      case 'resultsetfields':
      case 'templateiconpicker':
      case 'templateoptions':
        formFieldName = 'templateOptions';
        break;
      case 'layout':
        formFieldName = 'Layout';
        break;
    }

    return formFieldName;
  }

  /**
   * Method called when a form field is changed to update attributes in store
   * @param {ak_object} field Form field
   */
  static async onFormFieldValueChanged(field) {
    // for swat code type automatically call onCodeChange
    if (field.type === 'dynselect' && field.controller.opt.name.toLowerCase().includes('event'))
      await this.onCodeSelect(field);

    // set the value of the attribute from the form field value
    let nValue;
    if (field.type === 'dynselect')
      nValue = field.akEvent.currentValue.id;
    else
      nValue = field.akEvent.currentValue;

    const formFieldName = field.attributes._InstanceName;
    const panelSwitcherFrame = field.getFirstParentByType('frame').dynObject;
    const propertyGrid = panelSwitcherFrame.parent.getFirstParentByType('frame').dynObject.getFirstChildByType('propertygrid');
    const instanceAttributes = ['name'];
    const masterAttributes = ['layout'];
    const pageAttributes = [ 'pagelabel', 'pagekey', 'pageicon' ];
    const attributeName = this.getAttrNameFromFieldName(formFieldName);

    if (isNull(propertyGrid.propStore.data.pull[attributeName])) {
      akioma.log.warn('Attribute not found', attributeName, nValue);
      return;
    }

    // get value to be set based on form field type
    nValue = this.parseFieldTypeValue(attributeName, field, nValue);

    // find record in propstore and update with new value from AttributeForm
    if (!instanceAttributes.includes(attributeName) && !masterAttributes.includes(attributeName) && !pageAttributes.includes(attributeName)) {
      const el = propertyGrid.propStore.data.pull[attributeName];
      el.charactervalue = nValue;
      el.attributevalue = nValue;
      el.isinherited = false;
      propertyGrid.propStore.update(attributeName, el);
    }

    const designer = this.getDesignerFromContainer(field);
    akioma.repository.updateDesignerProperty(designer, propertyGrid, attributeName, nValue);

    akioma.log.info('validateEvent, value of form field', nValue);
  }
};

/**
 * Method for setting filter Events only on a propertyGrid
 * @param {ak_propertyGrid} propertyGrid
 */
akioma.repository.enableEventsOnlyPropertyGrid = function(propertyGrid) {
  propertyGrid.controller.setFilterEventsOnly();
};

akioma.repository.AttributeForm.init();
