/**
 * Helper function for testing if a value is undefined or null.
 * @param {*} value Value to test for nullity.
 * @return {boolean}
 */
function isNull(value) {
  return typeof (value) === 'undefined' || value === null;
}

/**
 * Helper method for capitalizing first letter of string
 * @param {string} string Some string
 * @returns {string}
 */
// global context function
// eslint-disable-next-line no-unused-vars
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Helper function for replacing all occurences in a string
 * As of August 2020 a new string function has been added 'replaceAll'
 * Due to backwards compatibility we need to use a less efficient function
 * where the new function is not available
 * @param {string} string       The string containing where we want to do the replace
 * @param {string} searchValue  The substring we want to replace
 * @param {string} replaceValue The substring we want to add in its place
 * @return {string}
 */
// global context function
// eslint-disable-next-line no-unused-vars
function replaceAllOccurencesInString(text, searchValue, replaceValue) {
  if (!isNull(text.replaceAll))
    return text.replaceAll(searchValue, replaceValue);
  else
    return text.split(searchValue).join(replaceValue);
}

/**
 * Helper function used for executing javascript code on the UI. Returns the code result.
 * @param  {object} context       The controller/caller object
 * @param  {string} method        The string starting with $ and then calling the method
 * @param  {object} params        Additional parameters
 * @return {*}
 */
// global context function
// eslint-disable-next-line no-unused-vars
function callReturnAkiomaCode(context, method, params) {
  let eventSource = null;
  let eventOrigin = null;
  let self = null;
  let oSelf = null;
  let result = null;

  if (method.substr(0, 1) == '$')
    method = method.substr(1);

  if (method.indexOf('self') !== -1)
    // variable is required for the context of the following eval
    // eslint-disable-next-line no-unused-vars
    self = context.dynObject ? context.dynObject : context;

  if (method.indexOf('oSelf') !== -1)
    // variable is required for the context of the following eval
    // eslint-disable-next-line no-unused-vars
    oSelf = context.controller ? context.controller : context;

  if (method.indexOf('eventSource') !== -1) {
    // variable is required for the context of the following eval
    const objController = context.controller ? context.controller : context;
    // eslint-disable-next-line no-unused-vars
    eventSource = akioma.swat.SwatFactory.createSwatObject(objController);
  }

  if (method.indexOf('eventOrigin') !== -1)
    // variable is required for the context of the following eval
    // eslint-disable-next-line no-unused-vars
    eventOrigin = context.dynObject ? context.dynObject : context;

  try {
    result = eval(method, params);

  } catch (e) {
    if (akioma.ignoreClientLogicErrors)
      console.warn(`client-logic error: ${e.message}`);
    else
      akioma.notification({ type: 'error', text: `Error executing client-logic: ${e.message}` });
    console.warn(e);
  }

  return result;
}

/**
 * Generic function for choosing and assigning a file to a field
 * @param {object} self object containing the field
 * @param {string} fileType type of files that will be displayed
 * @param {string} fieldname field that will be assigned with the selection
 */
akioma.swat.chooseAndAssignFile = function(self, fileType, fieldname) {
  app.controller.launchContainer({
    containerName: 'chooseFileG',
    data: true,
    self: self,
    customData: {
      callback: `$ akioma.swat.assignValueToField(self.container.caller, '${fieldname}', self.container.getObject('dFileList').getValue('clientfilename')); self.container.controller.close();`,
      groupHdl: fileType
    }
  });
};

/**
 * Helper function to assign values to a field
 * @param {object} self object containing the field
 * @param {string} fieldname name of the field
 * @param {any} value value to be assigned
 * @param {boolean} setHasChanges set hasChanges state to field
 */
akioma.swat.assignValueToField = function(self, fieldname, value, setHasChanges = true) {
  if (!value) {
    akioma.notification({ type: 'error', text: 'Datei nicht ausgewählt' });
    return;
  }

  let container = self.container;
  if (isNull(container) && self.form.akElm)
    container = self.form.akElm.dynObject.container;

  let field = container.getObject(fieldname);
  field = field.controller ? field.controller : field;
  field.setValue(value);

  if (setHasChanges) {
    const form = field.getAncestor('form');
    form.setFieldHasChanges(field.opt.name, true);
  }
};

/**
 * Helper function to assign values to a form field
 * @param {object} field form field
 * @param {boolean} setHasChanges set hasChanges state to field
 * @param {boolean} updateDatasource update field also in form data source
 */
akioma.swat.setFormFieldValue = (field, value, setHasChanges = true, updateDatasource = true) => {
  field = isNull(field.controller) ? field : field.controller;

  let form = field.dynObject.getParentOfType('form');
  if (isNull(form)) akioma.log.error('Field is not a form field!');
  form = form.controller;

  field.setValue(value);
  if (setHasChanges) form.setFieldHasChanges(field.opt.name, true);
  if (updateDatasource) form.dataSource.setFieldValue({ name: 'SecurityItemDescription', value: '' });
};

/**
 * Helper function to hide a form field based on a security token
 * @param {object} self object containing the field
 * @param {object} field form field
 * @param {string} token security token
 */
akioma.swat.hideFormField = (self, field, token) => {
  if (!securityIsRestricted(token))
    self.hideFormField(field);
};

/**
 * Replace new lines
 *
 * @static
 * @param {string} text
 * @returns {string}
 */
// global context function
// eslint-disable-next-line no-unused-vars
function replaceNewLines(text) {
  return (text && text.replace) ? text.replace(/(?:\r\n|\r|\n|\04)/g, '<br />') : text;
}

/**
 * Method used for splitting a css string. Returns a key-value array with
 * key beeing the css property and the value beeing the value of the css property.
 * @param {string} rules
 * @returns {Array}
 * @static
 */
// global context function
// eslint-disable-next-line no-unused-vars
function splitCssString(rules) {
  const styleArray = rules.split(';');
  const cssRules = [];

  styleArray.forEach(element => {
    element = element.split(':');
    if (element[0])
      cssRules[element[0]] = element[1];
  });

  return cssRules;
}

/**
 * Helper function for code evaluation
 * @param {string} code code to be evaluated, represented as string
 * @param {object} controller
 * @param {object} dynObj
 * @param {object} launchedFrom
 * @param {boolean} catchError if error handling should be performed
 * @returns {Array}
 */
akioma.swat.evaluateCode = function({ code, controller, dynObj, launchedFrom, catchError = false }) {
  let self = null;
  let oSelf = null;
  let eventSource = null;
  let eventOrigin = null;

  if (dynObj === undefined && controller && controller.dynObject)
    dynObj = controller.dynObject;
  if (controller === undefined && dynObj && dynObj.controller)
    controller = dynObj.controller;

  if (code.indexOf('self') !== -1)
    // variable is required for the context of the following eval
    // eslint-disable-next-line no-unused-vars
    self = dynObj;

  if (code.indexOf('oSelf') !== -1)
    // variable is required for the context of the following eval
    // eslint-disable-next-line no-unused-vars
    oSelf = controller;

  if (code.indexOf('eventSource') !== -1)
    // variable is required for the context of the following eval
    // eslint-disable-next-line no-unused-vars
    eventSource = akioma.swat.SwatFactory.createSwatObject(controller);

  if (code.indexOf('eventOrigin') !== -1)
    // variable is required for the context of the following eval
    // eslint-disable-next-line no-unused-vars
    eventOrigin = (isNull(launchedFrom)) ? dynObj : launchedFrom.dynObject || launchedFrom;

  try {
    const evalResult = eval(code);
    return evalResult;
  } catch (e) {
    if (!catchError)
      throw e;
    else {
      akioma.log.error([ 'Error executing akioma code', controller, code, e ]);
      if (akioma.ignoreClientLogicErrors)
        akioma.log.error(`client-logic error: ${code}`);
      else
        akioma.notification({ text: `Error executing client-logic: ${code}`, type: 'error' });
    }
  }
};

akioma.swat.canCancelRequest = function() {
  if (!akioma.applicationSettings.businessEntity || !akioma.applicationSettings.businessEntity.cancelRequest) return true;
  if (akioma.applicationSettings.businessEntity.cancelRequest === 'yes') return true;
  else
    return false;
};

/**
 * Checks the default message display style (modal/nonmodal).
 * If we have MessageBoxDefaultDisplayStyle defined check that. If not, returns false (notification) by default.
 * @return {boolean}
 */
akioma.swat.isDefaultMessageBoxStyleModal = function() {
  let bModal = false;
  if (akioma.applicationSettings.MessageBoxDefaultDisplayStyle)
    bModal = akioma.applicationSettings.MessageBoxDefaultDisplayStyle.toLowerCase() == 'modal';

  return bModal;
};
