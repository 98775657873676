/**
 * Class for handling Corticon.js integration
 *
 * @class
 */
class Corticon {

  /**
   * Initializes the Corticon static class
   * @public
   * @static
   * @memberOf Corticon
   */
  static init() {

    // Disables the rules execution. If not set then uses the SkipRuleFlowExecution in the eSwatSessionContext
    Corticon.disabled = false;

    // Forces services to reload
    Corticon.forceLoadServices = false;

    // Map of loaded services
    Corticon._services = {};

    // set up custom logger
    Corticon.debug = false;
    Corticon._logger = akioma.log.getLogger('Corticon');
  }

  /**
   * Method for loading, caching and returning Corticon.js service
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} screen The screen dynObject
   */
  static _getService(screen) {

    const rulesSettings = screen.controller.getRulesSettings();
    const ruleFlow = rulesSettings.behaviour.RuleFlow;

    const deferred = $.Deferred();

    if (Corticon._services[ruleFlow] && Corticon._services[ruleFlow].then)
      return Corticon._services[ruleFlow];

    else
    if (Corticon._services[ruleFlow] && !Corticon.forceLoadServices)
      deferred.resolve(Corticon._services[ruleFlow]);

    else {
      const serviceUrl = Corticon._getServiceUrl(ruleFlow);
      Corticon._logger.info(`Loading Corticon service ${ruleFlow} (${serviceUrl})`);

      $.ajax({
        url: serviceUrl,
        dataType: 'script',
        async: true
      }).complete(() => {
        if (window.corticonEngine) {
          Corticon._services[ruleFlow] = window.corticonEngine;
          window.corticonEngine = null;

          deferred.resolve(Corticon._services[ruleFlow]);
        } else
          deferred.reject(new Error(`Failed to load Corticon service '${ruleFlow}'.`));

      });

      Corticon._services[ruleFlow] = deferred.promise();
    }

    return deferred.promise();
  }

  /**
   * Method for getting the url of a Corticon.js service
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} path The Corticon.js ruleFlow path
   */
  static _getServiceUrl(path) {
    return `${path}/browser/decisionServiceBundle.js`;
  }

  /**
   * Method for executing Corticon.js serice
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} payload Service execution payload
   * @param {Object} configuration Service execution settings
   * @param {Object} service The Corticon.js service that will be executed
   */
  static _runDecisionService(payload, configuration = {}, service = window.corticonEngine) {
    // create copy as we need the original payload for comparison
    const payloadString = JSON.stringify(payload);
    const payloadCopy = JSON.parse(payloadString);

    if (Corticon.debug) console.time('corticon.service.execution');
    const result = service.execute(payloadCopy, configuration);
    if (Corticon.debug) console.timeEnd('corticon.service.execution');
    if (result.status == 'success')
      return result;
    else {
      throw `${`There was an error executing the rules for service '${service._name}' [${service._url}]!` +
        '\nPayload: '}${JSON.stringify(payload, null, 2)
      }\nResult: ${JSON.stringify(result, null, 2)}`;
    }
  }

  /**
   * Method for generating the Corticon.js service payload of a window
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} screen The source screen from where the payload will be generated
   * @param {Object} rulesSettings The screen's Corticon settings (behaviour and metadata)
   * @param {Object} evt The event object.
   */
  static _getPayload(screen, rulesSettings, evt) {

    const payload = {
      __metadataRoot: {},
      Objects: []
    };

    payload.Objects = rulesSettings.metadata.EntityList.map(name => {
      const prop = Corticon._initializeEntity(name, rulesSettings);

      if (name.startsWith('Session'))
        Object.assign(prop, Corticon._getSessionPayload(name));

      else
      if (name.startsWith('_'))
        Object.assign(prop, Corticon._getCustomDataPayload(name, screen, evt));

      else
        Object.assign(prop, Corticon._getEntityPayload(name, screen, rulesSettings));

      return prop;
    });

    return payload;
  }

  static _getEntityPayload(name, screen) {

    const detail = Corticon._extractDetailsFromEntityName(name);
    const controller = Corticon._getDetailController(detail, screen);

    if (controller) {
      switch (detail.objectType) {
        case 'Container':
          return Corticon._getContainerPayload(controller);
        case 'Datasources':
          return Corticon._getDatasourcePayload(controller);
        case 'Forms':
          return Corticon._getFormPayload(controller);
        case 'Pages':
          return Corticon._getPagePayload(controller, detail.objectName);
        case 'Ribbons':
          return Corticon._getRibbonPayload(controller);
      }
    }
  }

  static _getSessionPayload(name) {
    const context = name.split('.')[1];

    if (akioma.sessionContext[context])
      return akioma.sessionContext[context];

    return {};
  }

  static _getCustomDataPayload(name, screen, evt) {

    const payload = {};

    switch (name) {
      case '_CustomData.CustomProperties':
        payload.eventSource = evt.eventSource;
        payload.windowName = screen.topRuleScreen.name;
        break;

      default:
        break;
    }

    return payload;
  }

  static _getContainerPayload(controller) {

    const payload = {};

    payload.title = (controller.parent ? controller.parent.opt.title : null);

    return payload;
  }

  static _getDatasourcePayload(datasource) {

    const payload = { isAvailable: null };
    const record = datasource.getSelectedRecord();

    if (datasource._rulesDataLoaded) {

      payload.isAvailable = false;

      if (record) {
        Object.assign(payload, Corticon._getDatasourceFieldValues(datasource, record));
        payload.isAvailable = true;
      }
    }

    return payload;
  }

  static _getDatasourceFieldValues(datasource, record) {

    const payload = {};

    if (!datasource._fieldNames) {

      const entityName = datasource.dynObject.attributes.entityName;
      const schema = datasource.jsdo[entityName].getSchema();

      datasource._fieldNames = schema.filter(schema => !schema.name.startsWith('_')).map(schema => schema.name);
    }

    datasource._fieldNames.forEach(field => {
      payload[field] = record[field.toLowerCase()];
    });

    return payload;
  }

  static _getFormPayload(form) {

    const payload = {};

    payload.title = form.parent.opt.title;
    payload.isEnabled = !form.dhx.isLocked(); // technical debt ticket opened to replace dhx.isLocked()

    const drill = parent => {
      parent.childs.forEach(child => {
        if (child.view !== 'block' && child.view !== 'menustructure') {

          const name = child.opt.InstanceName;
          const key = name.toLowerCase();

          if (child.view !== 'fieldset')
            payload[`${name}__value`] = Corticon._getFieldValue(child.dynObject);

          // the label is not set, currently, because
          // 1. there is no use case
          // 2. there might be a performance impact given there could be hundreds or even thousands of fields in a vocabulary
          // payload[name + '__label'] = form.dhx.getItemLabel(key);

          payload[`${name}__isVisible`] = form.getFieldIsVisible(key);
          payload[`${name}__isMandatory`] = form.getFieldIsRequired(key);
          payload[`${name}__hasChanges`] = form.getFieldHasChanges(key);

          if (!form._rulesIsEnabled)
            payload[`${name}__isEnabled`] = form.getFieldIsEnabled(key);

        }

        if (child.view === 'fieldset' || child.view === 'block')
          drill(child);
      });
    };

    drill(form);

    if (form._rulesIsEnabled)
      Object.assign(payload, form._rulesIsEnabled);


    return payload;
  }

  static _getPagePayload(tabbar, key) {

    const payload = {};

    // payload.label = tabbar.label;
    payload.isEnabled = tabbar.isPageEnabled(key);
    payload.isVisible = tabbar.isPageVisible(key);

    return payload;
  }

  static _getRibbonPayload(ribbon) {

    const payload = {};

    ribbon.getAllChildrenByType('ribbonblock').forEach(block => {
      Object.assign(payload, Corticon._getMenuItemPayloadProperties(ribbon, block));
    });

    ribbon.getAllChildrenByType('ribbonbutton').forEach(button => {
      Object.assign(payload, Corticon._getMenuItemPayloadProperties(ribbon, button));
    });

    ribbon.getAllChildrenByType('ribboncombo').forEach(combo => {
      Object.assign(payload, Corticon._getMenuItemPayloadProperties(ribbon, combo));
    });

    return payload;
  }

  /**
   * Method for generating the payload properties of a menu item control
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} screenControl Screen control containing item
   * @param {Object} item Menu item for which to generate payload
   */
  static _getMenuItemPayloadProperties(screenControl, item) {
    const controller = isNull(item.controller) ? item : item.controller;
    const itemPayload = {};

    const id = controller.opt.id;
    itemPayload[`${id}__label`] = isNull(controller.opt.label) ? '' : controller.opt.label;
    itemPayload[`${id}__isVisible`] = screenControl.isVisible(id);
    itemPayload[`${id}__isEnabled`] = screenControl.isEnabled(id);

    return itemPayload;
  }

  /**
   * Method for fetching current value of a field
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} field The form field
   */
  static _getFieldValue(field) {
    let value = null;

    if (field.type === 'dynselect') {
      value = field.controller.getValue('id');

      if (!isNull(value))
        value = value.toString();
    } else {
      value = field.getValue();

      if (field.type === 'calendar') {
        if (typeof value === 'string') {
          if (value === '')
            value = null;
          else {
            value = new Date(value);
            if (!isNaN(value.getTime()))
              value = value.toISOString();
            else
              value = null;
          }
        }
      } else
      if (field.attributes.type === 'checkbox') {
        if (typeof value === 'string')
          value = (value === 'yes' || value === 'true');
      }
    }

    if (value instanceof Date) {

      // toISOString() uses utc timezone which could be different than system timezone
      // and could change the time and date.
      // the code below corrects for the timezone different
      // so the date and time will not be changed.
      if (!isNaN(value.getTime())) {
        value = new Date(value.getTime() - value.getTimezoneOffset() * 60000);
        value = value.toISOString();
      } else
        value = null;

    }

    return value;
  }

  /**
   * Method for detecting and applying changes from Corticon.js service
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} screen The screen dynObject
   * @param {Object} payload The payload sent to the Corticon.js service
   * @param {Object} response The request returned by the Corticon.js service
   * @param {Object} limit Optionally limit the changes to the contents of a frame
   */
  static _applyChanges(screen, payload, response, limit = null) {
    const payloadObjects = payload.Objects;
    const responseObjects = response.Objects;

    for (const index in responseObjects) {
      const payloadData = payloadObjects[index];
      const responseData = responseObjects[index];

      const entityName = responseData.__metadata['#id'];
      const entityType = responseData.__metadata['#type'];
      if (entityName.startsWith('Session.')) continue;

      if (entityType.startsWith('_CustomData.')) {
        switch (entityType.split('.')[1]) {
          case 'CustomStates':
            Corticon._applyCustomStates(screen, responseData, limit);
            break;
          case 'QueryFilters': {
            Corticon._applyQueryFilters(screen, responseData, limit);
            break;
          }
        }
      } else
        Corticon._applyScreenComponentChanges(screen, payloadData, responseData, limit, entityName);
    }
  }

  /**
   * Method for processing a custom state operation entry
   * @param {Object} screen The screen for which the service is executed
   * @param {Object} responseData The currently processed CustomStates entity entry
   */
  static _applyCustomStates(screen, responseData) {
    const objectName = responseData['object'];
    const subobjectName = responseData['subobject'];
    const stateName = responseData['name'];
    const bubbleUp = responseData['bubbleUp'];
    const entityName = `${objectName}:${subobjectName}:${stateName}`;
    if (isNull(objectName) || isNull(stateName)) return;

    let dynObject = Corticon._getObject(screen, objectName);
    if (isNull(dynObject)) return;

    if (subobjectName) {
      dynObject = dynObject.getObject(subobjectName);
      if (isNull(dynObject)) return;
    }

    dynObject.controller.setCustomState(stateName, { bubbleUp: !isNull(bubbleUp) ? bubbleUp : true });

    delete screen._prevCustomStateEntities[entityName];
    screen._currCustomStateEntities[entityName] = {
      controller: dynObject.controller,
      state: stateName
    };
  }

  /**
   * Method for processing a query filter entry
   * @param {Object} screen The screen dynObject
   * @param {Object} responseData The currently processed QueryFilters entity entry
   */
  static _applyQueryFilters(screen, responseData) {
    const objectName = responseData['object'];
    const subobjectName = responseData['subobject'];
    const entityName = `${objectName}:${subobjectName}`;
    const field = responseData['field'];
    const operator = responseData['operator'];
    const value = responseData['value'];
    if (isNull(objectName) || isNull(subobjectName) || isNull(field) || isNull(operator) || isNull(value)) return;

    const form = Corticon._getObject(screen, objectName);
    if (isNull(form)) return;

    const dynSelect = form.getObject(subobjectName);
    if (isNull(dynSelect)) return;

    const query = dynSelect.controller.businessEntity.getQuery();

    if (screen._prevFilterEntities[entityName] || !screen._currFilterEntities[entityName])
      query.clearAll();

    if (operator === 'in') {
      query.setSubOperator(field, 'or');

      let list = [];

      if (value.indexOf('|') !== -1)
        list = value.split('|');
      else
        list = value.split(',');

      list.forEach(val => {
        query.addCondition(field, 'eq', val);
      });
    } else
      query.addUniqueCondition(field, operator, value);


    delete screen._prevFilterEntities[entityName];
    screen._currFilterEntities[entityName] = dynSelect;
  }

  /**
   * Method for applying changes to a screen component from its response entity
   * @param {Object} window The window for which the service is executed
   * @param {Object} payload The payload sent to the Corticon.js service
   * @param {Object} response The request returned by the Corticon.js service
   * @param {Object} limit Optionally limit the changes to the contents of a frame
   * @param {String} entityName Name of the entity which will be processed
   */
  static _applyScreenComponentChanges(window, payloadData, responseData, limit, entityName) {
    const isValidLimit = !isNull(limit);
    const entityDefinition = Corticon._extractDetailsFromEntityName(entityName);
    const container = Corticon._getContainer(window, entityDefinition.containerName);
    if (isNull(container)) return;

    let applyChanges = true;
    switch (entityDefinition.objectType) {
      case 'Container':
        applyChanges = isNull(limit);
        break;
      case 'Pages': {
        let tabbar = container.getFirstChildByType('tabbar');
        applyChanges = !isNull(tabbar);
        if (applyChanges) {
          tabbar = tabbar.dynObject;
          if (isValidLimit)
            applyChanges = Corticon._isChild(limit, tabbar);
        }
        if (applyChanges) {
          const tabbarController = tabbar.controller;
          if (payloadData['isEnabled'] !== responseData['isEnabled']) {
            if (responseData['isEnabled']) tabbarController.enablePage(entityDefinition.objectName);
            else tabbarController.disablePage(entityDefinition.objectName);
          }
          if (payloadData['isVisible'] !== responseData['isVisible']) {
            if (responseData['isVisible']) tabbarController.showPage(entityDefinition.objectName);
            else tabbarController.hidePage(entityDefinition.objectName);
          }
        }
        break;
      }
      case 'Forms': {
        const form = container.getObject(entityDefinition.objectName);
        applyChanges = !isNull(form);
        if (applyChanges && isValidLimit)
          applyChanges = Corticon._isChild(limit, form);
        if (applyChanges)
          this._applyFormChanges(form.controller, payloadData, responseData);
        break;
      }
      case 'Ribbons':
      case 'Toolbars': {
        const ribbon = container.getObject(entityDefinition.objectName);
        applyChanges = !isNull(ribbon);
        if (applyChanges && isValidLimit)
          applyChanges = Corticon._isChild(limit, ribbon);
        if (applyChanges)
          this._applyMenuItemChanges(ribbon.controller, payloadData, responseData);
        break;
      }
      case 'Datasources':
        break;
    }
  }

  /**
   * Method for extracting entity details from its names
   * (container name, object type, object name)
   * @private
   * @static
   * @memberOf Corticon
   * @param {String} name The name of an entity
   * @returns {Object} Object containing scren details about the entity (containerName, objectType, objectName)
   */
  static _extractDetailsFromEntityName(name) {
    const entityNameParts = name.split('.');
    return {
      containerName: entityNameParts[0],
      objectType: (entityNameParts[0] == entityNameParts[1]) ? 'Container' : entityNameParts[1],
      objectName: entityNameParts[entityNameParts.length - 1]
    };
  }

  static _getDetailController(detail, screen) {
    const container = detail.objectType === 'Container' || screen.topRuleScreen.name === detail.containerName ? screen.topRuleScreen : screen.topRuleScreen.getObject(detail.containerName);
    if (!container) return null;

    const dynObject = detail.objectType === 'Pages' || container.name === detail.objectName ? container : container.getObject(detail.objectName);
    if (!dynObject) return null;

    const controller = detail.objectType === 'Pages' ? container.getFirstChildByType('tabbar') : dynObject.controller;
    return controller;
  }

  /**
   * Method for getting the container (window, frame) by name relative to a given window
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} window The window where the search will be made
   * @param {String} name Name of the container
   * @returns {Object} Container with the specified name or null if not found
   */
  static _getContainer(window, name) {
    let container = window;
    if (name !== window.name) {
      container = container.getObject(name);
      if (!isNull(container)) {
        container = container.getFirstChildByType('frame');
        if (!isNull(container))
          container = container.dynObject;
      }
    }
    return container;
  }

  /**
   * Check if a given control is a direct/indirect child of a frame
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} parent The parent frame
   * @param {Object} child The control that will be checked
   */
  static _isChild(parent, child) {
    const parentId = parent.attributes.id;
    const childTopRuleScreenId = child.topRuleScreen.attributes.id;
    let parentLookup = child.screen;
    while (parentLookup.attributes.id !== parentId && parentLookup.attributes.id !== childTopRuleScreenId) {
      if (parentLookup.attributes.id == parentLookup.screen.attributes.id)
        parentLookup = parentLookup.parent;
      else
        parentLookup = parentLookup.screen;
    }
    return parentLookup.attributes.id == parentId;
  }

  /**
   * Method for detecting and applying form changes from Corticon.js service
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} window The window for which the service is executed
   * @param {Object} payloadData The payload sent to the Corticon.js service
   * @param {Object} responseData The request returned by the Corticon.js service
   */
  static _applyMenuItemChanges(control, payloadData, responseData) {
    for (const key in responseData) {
      // skip reserved property '__metadata'
      if (key == '__metadata') continue;

      const payloadValue = payloadData[key];
      const responseValue = responseData[key];

      // currently corticon converts numbers to strings and there might be other cases
      // so the comparison should allow for casting.
      if (payloadValue != responseValue) {
        switch (key) {
          default: {
            const keyParts = key.split('__');
            const name = keyParts[0];
            const property = keyParts[1];
            switch (property) {
              case 'isVisible':

                // there's cases where ribbon buttons were not created depending on the logged in user permissions
                // and showing, hiding, enabling, disabling or setting their text will throw an error.
                // note that doing the same in form fields will not throw an error.
                // another option is to check if the child exists but this search is more work and could effect performance.
                try {
                  if (responseValue) control.showItem(name);
                  else control.hideItem(name);
                } catch (err) {
                  console.error(err);
                }
                break;
              case 'isEnabled':
                try {
                  if (responseValue) control.enableItem(name);
                  else control.disableItem(name);
                } catch (err) {
                  console.error(err);
                }
                break;
              case 'label':
                try {
                  control.setItemText(name, responseValue);
                } catch (err) {
                  console.error(err);
                }
                break;
            }
            break;
          }
        }
      }
    }
  }

  /**
   * Method for detecting and applying form changes from Corticon.js service
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} window The window for which the service is executed
   * @param {Object} payloadData The payload sent to the Corticon.js service
   * @param {Object} responseData The request returned by the Corticon.js service
   */
  static _applyFormChanges(form, payloadData, responseData) {

    const payloadIsEnabled = payloadData.isEnabled;
    const responseIsEnabled = responseData.isEnabled;

    if (!isNull(responseIsEnabled)) {
      if (!form._rulesIsEnabled) {
        form._rulesIsEnabled = Object.keys(payloadData).filter(key => key.endsWith('__isEnabled')).reduce((ret, key) => {
          ret[key] = payloadData[key];
          return ret;
        }, {});
      }

      form._rulesIsEnabled.isEnabled = responseData.isEnabled;
    }

    for (const key in responseData) {

      // skip reserved property '__metadata'
      if (key === '__metadata') continue;

      let payloadValue = payloadData[key];
      let responseValue = responseData[key];

      const keyParts = key.split('__');
      const name = keyParts[0].toLowerCase();
      const property = keyParts.length > 1 ? keyParts[1] : undefined;

      if (property === 'isEnabled') {
        if (!isNull(payloadIsEnabled)) {
          if (!isNull(payloadValue))
            payloadValue = payloadValue && payloadIsEnabled;
          else
            payloadValue = payloadIsEnabled;
        }

        if (!isNull(responseIsEnabled)) {
          if (!isNull(responseValue))
            responseValue = responseValue && responseIsEnabled;
          else
            responseValue = responseIsEnabled;

          form._rulesIsEnabled[key] = responseData[key];
        }
      }

      // currently corticon converts numbers to strings and there might be other cases
      // so the comparison should allow for casting.
      if (payloadValue == responseValue) continue;

      switch (key) {
        case 'title':
          form.parent.setOption('title', responseValue);
          break;
        case 'isEnabled':
          break;
        default: {
          switch (property) {
            case 'isVisible':
              if (!isNull(responseValue)) {
                if (responseValue) form.showFormField(name);
                else form.hideFormField(name);
              }
              break;
            case 'isEnabled':
              if (!isNull(responseValue)) {
                if (responseValue) form.enableFormField(name);
                else form.disableFormField(name);
              }
              break;
            case 'isMandatory':
              if (!isNull(responseValue))
                form.setRequired(name, responseValue);

              break;
            case 'label':
              form.setLabel(name, responseValue);

              break;
            case 'value': {
              const field = form.dynObject.getObject(name);
              if (field) {
                if (field.type === 'dynselect') {
                  if (isNull(responseValue))
                    field.controller.setValue('id', '');
                  else
                    field.controller.dynSelectControl.positionToRecord(responseValue);
                } else
                  field.setValue(responseValue);
              }
            }
              break;
          }
          break;
        }
      }
    }
  }

  /**
   * Method for displaying messages from Corticon.js
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} window The window for which the service is executed
   * @param [{Object}] screens The screens dynObject array
   * @param {Object} response The request returned by the Corticon.js service
   */
  static _displayMessages(screen, screens, response) {

    const topRuleScreen = screen.topRuleScreen;

    const messages = response.Objects.filter(obj => obj['__metadata']['#type'] === '_CustomData.Messages').filter(message => !!message.message || !!message.messageGroup && !!message.messageNumber);
    messages.forEach(message => {

      const dynObject = Corticon._getObject(screen, message.entity);
      if (!dynObject) return;

      const controller = dynObject.controller;
      if (controller && controller.addPanelMessage && controller.clearPanelMessages) {

        // the window rules can have messages for any frames
        let screen = dynObject.directRuleScreen;

        // the message can only be in the screen or topRuleScreen
        // unless the screen for the messages has no rules
        if (screens.indexOf(screen) === -1) {
          if (screen.controller.hasRules())
            return;
          else
            screen = topRuleScreen;
        }

        const entity = dynObject.name;

        // if there were previous messages then we need to clear first
        // if it's the first time we are adding a message then we also need to clear
        if (screen._prevMessageEntities[entity] || !screen._currMessageEntities[entity])
          controller.clearPanelMessages();

        Corticon._displayMessage(screen, controller, message);

        delete screen._prevMessageEntities[entity];
        screen._currMessageEntities[entity] = true;
      } else {

        const entity = topRuleScreen.name;
        if (topRuleScreen._prevMessageEntities[entity] || !topRuleScreen._currMessageEntities[entity]) {

          for (const id in window.dhtmlx.message.pull)
            window.dhtmlx.message.hide(id);

        }

        Corticon._displayMessage(topRuleScreen, null, message);

        delete topRuleScreen._prevMessageEntities[entity];
        topRuleScreen._currMessageEntities[entity] = true;
      }
    });
  }

  /**
   * Method for displaying a message
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} screen The screen dynObject
   * @param {Object} form The form to add panel messages or null for notifications
   * @param {Object} message The Corticon.js message object
   */
  static _displayMessage(screen, form, message) {

    if (!isNull(message.messageGroup) && !isNull(message.messageNumber)) {

      const messageCounter = screen._messageCounter;

      akioma.swat.Message.getMessageNum(message.messageGroup, message.messageNumber).then(message => {

        if (messageCounter !== screen._messageCounter)
          return;

        message.MessageText = akioma.SmartMessage.replaceEmptyPlaceholders(message.MessageText);

        const content = {
          type: message.MessageType,
          text: message.MessageText
        };

        if (form)
          form.addPanelMessage(content);
        else
          akioma.notification(content);
      });
    } else {

      const content = {
        type: message.type,
        text: message.message
      };

      if (form)
        form.addPanelMessage(content);
      else
        akioma.notification(content);
    }
  }

  /**
   * Method for clearing previous messages
   * @private
   * @static
   * @memberOf Corticon
   * @param [{Object}] screens The screen dynObject array
   */
  static _clearPrevMessages(screens) {
    screens.forEach(screen => {
      Object.keys(screen._prevMessageEntities).forEach(entity => {

        const dynObject = Corticon._getObject(screen, entity);
        if (!dynObject) return;

        const controller = dynObject.controller;
        if (controller && controller.addPanelMessage && controller.clearPanelMessages)

          controller.clearPanelMessages();
        else {

          for (const id in window.dhtmlx.message.pull)
            window.dhtmlx.message.hide(id);

        }
      });

      screen._prevMessageEntities = screen._currMessageEntities;
    });
  }

  /**
   * Method for reseting the previous and current messages before running the service.
   * @private
   * @static
   * @memberOf Corticon
   * @param [{Object}] screens The screen dynObject array
   */
  static _resetMessages(screens) {
    screens.forEach(screen => {
      screen._prevMessageEntities = screen._prevMessageEntities || {};
      screen._currMessageEntities = {};

      screen._messageCounter = screen._messageCounter || 0;
      screen._messageCounter++;
    });
  }

  /**
   * Method for clearing the previous filters
   * @private
   * @static
   * @memberOf Corticon
   * @param [{Object}] screens The screen dynObject array
   */
  static _clearPrevFilters(screens) {
    screens.forEach(screen => {
      Object.keys(screen._prevFilterEntities).forEach(entity => {
        const dynSelect = screen._prevFilterEntities[entity];
        dynSelect.controller.businessEntity.getQuery().clearAll();
      });

      screen._prevFilterEntities = screen._currFilterEntities;
    });
  }

  /**
   * Method for reseting the previous and current filters before running the service.
   * @private
   * @static
   * @memberOf Corticon
   * @param [{Object}] screens The screen dynObject array
   */
  static _resetFilters(screens) {
    screens.forEach(screen => {
      screen._prevFilterEntities = screen._prevFilterEntities || {};
      screen._currFilterEntities = {};
    });
  }

  /**
   * Method for clearing the previous custom states
   * @private
   * @static
   * @memberOf Corticon
   * @param [{Object}] screens The screen dynObject array
   */
  static _clearPrevCustomStates(screens) {
    screens.forEach(screen => {
      Object.keys(screen._prevCustomStateEntities).forEach(entity => {
        const {
          controller,
          state
        } = screen._prevCustomStateEntities[entity];
        controller.clearCustomState(state);
      });

      screen._prevCustomStateEntities = screen._currCustomStateEntities;
    });
  }

  /**
   * Method for reseting the previous and current custom states before running the service.
   * @private
   * @static
   * @memberOf Corticon
   * @param [{Object}] screens The screen dynObject array
   */
  static _resetCustomStates(screens) {
    screens.forEach(screen => {
      screen._prevCustomStateEntities = screen._prevCustomStateEntities || {};
      screen._currCustomStateEntities = {};
    });
  }

  /**
   * Method for creating Corticon entity payload
   * @private
   * @static
   * @memberof Corticon
   * @param {String} name Name of the entity
   * @param {Object} settings The screen's Corticon settings
   * @returns {Object} An object containing an empty Corticon.js entity or null if entity details were not valid
   */
  static _initializeEntity(name, settings) {
    let type = name;
    switch (settings.metadata.vocabularyType) {
      case 'Flattened':
        type = replaceAllOccurencesInString(type, '.', '__');
        break;
      case 'Domains':
        break;
    }

    // check rule metadata for entity name and return null if not found
    if (settings.metadata.EntityMap[type]) {
      return {
        __metadata: {
          '#type': type,
          '#id': name
        }
      };
    } else {
      akioma.log.info(`Tried to initialize unknown entity '${type}', skipped it...`);
      return null;
    }
  }

  /**
   * Default method used to call rules. Also used when setting RulesBehaviour on field level
   * @public
   * @static
   * @memberOf Corticon
   * @param {Object} evt The event object.
   * @param {Object} screen The screen to execute rules.
   * @param {Object} topScreen The top screen to execute rules.
   */
  static _callDefaultRules(evt, screen, topRuleScreen) {
    if (screen.controller.hasRules())
      Corticon._callRulesWhenReady(screen, evt);
    else
    if (topRuleScreen && topRuleScreen !== screen && topRuleScreen.controller.hasRules())
      Corticon._callRulesWhenReady(topRuleScreen, evt);
  }

  /**
   * The main callRules method used to execute screen rules
   * @public
   * @static
   * @memberOf Corticon
   * @param {Object} screen The screen to execute rules.
   * @param {Object} evt The event object.
   */
  static callRules(screen, evt = {}) {

    const disabled = (!isNull(Corticon.disabled) ? Corticon.disabled :
      akioma.getSessionProperty('SkipRuleFlowExecution', 'eSwatSessionContext'));

    if (disabled)
      return;

    // there are cases where frames show up multiple times
    // this code normalizes that we only use the top level frame
    if (screen !== screen.directRuleScreen)
      screen = screen.directRuleScreen;

    const topRuleScreen = screen.topRuleScreen;

    // rule behaviour can be set on the field level; check that one first; otherwise, check on the frame/window level
    const oField = evt.eventObject || null;

    switch (evt.eventName) {

      case 'onKeyUp':
        if (oField && oField.hasKeyUpRules())
          Corticon._callDefaultRules(evt, screen, topRuleScreen);
        else

        if (screen.controller.hasKeyUpRules())
          Corticon._callRulesWhenReady(screen, evt);

        else
        if (topRuleScreen && topRuleScreen !== screen && topRuleScreen.controller.hasKeyUpRules())
          Corticon._callRulesWhenReady(topRuleScreen, evt);

        break;

      case 'onLeave':
        if (oField && oField.hasLeaveRules())
          Corticon._callDefaultRules(evt, screen, topRuleScreen);
        else

        if (screen.controller.hasLeaveRules())
          Corticon._callRulesWhenReady(screen, evt);

        else
        if (topRuleScreen && topRuleScreen !== screen && topRuleScreen.controller.hasLeaveRules())
          Corticon._callRulesWhenReady(topRuleScreen, evt);

        break;

      case 'onChange':
        if (oField && oField.hasChangeRules())
          Corticon._callDefaultRules(evt, screen, topRuleScreen);
        else

        if (screen.controller && screen.controller.hasChangeRules())
          Corticon._callRulesWhenReady(screen, evt);

        else
        if (topRuleScreen && topRuleScreen !== screen && topRuleScreen.controller.hasChangeRules())
          Corticon._callRulesWhenReady(topRuleScreen, evt);

        break;

      case 'onDataAvailable':
        if (screen.controller.hasDataAvailableRules())
          Corticon._callRulesWhenReady(screen, evt);

        else
        if (topRuleScreen && topRuleScreen !== screen && topRuleScreen.controller.hasDataAvailableRules())
          Corticon._callRulesWhenReady(topRuleScreen, evt);

        break;

      default:
        Corticon._callDefaultRules(evt, screen, topRuleScreen);
        break;
    }
  }

  /**
   * Call rules method when the screen is ready
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} screen The screen to execute rules.
   * @param {Object} evt The event object.
   */
  static _callRulesWhenReady(screen, evt) {
    Corticon._logger.info(`Trigger Corticon rules ${evt.eventName} of ${evt.eventEntity}`);

    // we can no longer rely just on events because there's new cases where onDisplay fired before onInitialize
    // to deal with these cases, events fired before the rules are ready (including service loaded) are treated like the onInitialize event
    if (evt.eventName === 'onInitialize' || !screen._rulesReadyPromise || screen._rulesReadyPromise.state() !== 'resolved') {

      if (!screen._rulesReadyPromise) {
        screen._rulesReadyPromise = Corticon._whenReady(screen).then(() => {

          Corticon._callRules(screen, evt);
        });
      }
    } else {

      if (screen._rulesTimeout) {
        clearTimeout(screen._rulesTimeout);
        delete screen._rulesTimeout;
      }

      screen._rulesTimeout = setTimeout(() => {
        Corticon._callRules(screen, evt);
        delete screen._rulesTimeout;
      }, 50);
    }
  }

  /**
   * Returns a promise when the screen is ready
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} screen The screen to check
   */
  static _whenReady(screen) {

    // this method is called after a short delay
    // and there can be a cases where the window has just been closed
    if (!screen || !screen.controller)
      return;

    const promises = Corticon._getAllRepoPromises(screen.controller);
    const deferred = $.Deferred();

    const servicePromise = Corticon._getService(screen);
    if (servicePromise.state() !== 'resolved') promises.push(servicePromise);

    setTimeout(() => {
      if (promises.length > 0) {
        $.when(...promises).then(() => {
          Corticon._whenReady(screen).then(() => {
            deferred.resolve();
          });
        });
      } else
        deferred.resolve();

    }, 50);

    return deferred.promise();
  }

  /**
   * Recursive method for getting all the repository promises
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} controller The controller to drill
   */
  static _getAllRepoPromises(controller) {

    const promises = [];

    switch (controller.view) {
      case 'frame':
        if (controller.promiseFrame && controller.promiseFrame.state() !== 'resolved')
          promises.push(controller.promiseFrame);
        break;

      case 'tab':
        if (controller.promiseTab && controller.promiseTab.state() !== 'resolved')
          promises.push(controller.promiseTab);
        break;
    }

    if (controller.childs && controller.childs.length > 0) {

      if (controller.view === 'tabbar')
        promises.push(...Corticon._getAllRepoPromises(controller.childs[controller.currentPageNum() - 1]));
      else {
        controller.childs
          .filter(child => child.view === 'frame' || child.view === 'tabbar' || child.view === 'panelset' || child.view === 'panel')
          .forEach(child => promises.push(...Corticon._getAllRepoPromises(child)));
      }
    }

    return promises;
  }

  /**
   * Call rules method on the screen and window synchronously to run code before and after.
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} screen The screen to execute rules.
   * @param {Object} evt The event object.
   */
  static _callRules(screen, evt) {

    // this method is called after a short delay
    // and there can be a cases where the window has just been closed
    if (!screen || !screen.controller)
      return;

    const topRuleScreen = screen.topRuleScreen;

    const screens = [];

    if (topRuleScreen && topRuleScreen !== screen)
      screens.push(topRuleScreen);

    if (screen)
      screens.push(screen);

    Corticon._resetCustomStates(screens);
    Corticon._resetMessages(screens);
    Corticon._resetFilters(screens);

    if (topRuleScreen && topRuleScreen !== screen && topRuleScreen.controller.hasRules() && Corticon._getService(topRuleScreen).state() === 'resolved')
      Corticon._executeRules(topRuleScreen, screens, evt);

    if (screen)
      Corticon._executeRules(screen, screens, evt);

    Corticon._clearPrevCustomStates(screens);
    Corticon._clearPrevMessages(screens);
    Corticon._clearPrevFilters(screens);
  }

  /**
   * Method for executing Corticon.js rule service for a screen
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} screen The screen for which the service is executed
   * @param [{Object}] screens The screen dynObject array
   */
  static _executeRules(screen, screens, evt) {

    const configuration = {
      logLevel: 0,
      inputMetadata: true,
      customFunctions: [{ 'getDSOFieldValue': Corticon.getDSOFieldValue }],
      ruleMessages: { logRuleMessages: Corticon.debug }
    };

    try {
      // initialize screen.akEvent.rulesExecution
      if (isNull(screen.akEvent)) screen.akEvent = {};
      screen.akEvent.rulesExecution = {};

      // get window rules settings and service
      const rulesSettings = screen.controller.getRulesSettings();

      // the service is already available at this point so the code is synchronous
      let service = null;
      Corticon._getService(screen).then(result => {
        service = result;
      });

      // if for some reason we got here and the service is not available then leave.
      if (!service) {
        console.error(`Corticon decision service for ${screen.name} not loaded. Aborting Corticon rules execution.`);
        return;
      }

      // get payload
      const payload = Corticon._getPayload(screen, rulesSettings, evt);
      screen.akEvent.rulesExecution.payload = payload;

      Corticon._logger.info(`Executing Corticon rules for ${screen.name}`);

      // execute rules
      if (rulesSettings.behaviour.EventBeforeRuleExecution) app.controller.callAkiomaCode(screen, rulesSettings.behaviour.EventBeforeRuleExecution);
      const result = Corticon._runDecisionService(payload, configuration, service, screen);

      Corticon._logger.info('Payload', Corticon._getMetaDataById(payload));
      Corticon._logger.info('Result diff', Corticon._getMetaDataDiff(payload, result));

      screen.akEvent.rulesExecution.result = result;
      if (rulesSettings.behaviour.EventAfterRuleExecution) app.controller.callAkiomaCode(screen, rulesSettings.behaviour.EventAfterRuleExecution);

      Corticon._applyChanges(screen, payload, result);
      if (rulesSettings.behaviour.EventAfterRuleApply) app.controller.callAkiomaCode(screen, rulesSettings.behaviour.EventAfterRuleApply);

      Corticon._displayMessages(screen, screens, result);
      if (rulesSettings.behaviour.EventAfterMessagesDisplay) app.controller.callAkiomaCode(screen, rulesSettings.behaviour.EventAfterMessagesDisplay);
    } catch (exception) {
      console.error(exception);
      akioma.notification({
        type: 'error',
        text: 'Error running Corticon integration!',
        moretext: exception
      });
    }
  }

  static _getMetaDataDiff(payload, result) {

    payload = Corticon._getMetaDataById(payload);
    result = Corticon._getMetaDataById(result);

    const diff = {};

    for (const entity in result) {

      const resultObj = result[entity];
      const payloadObj = payload[entity];

      if (!payloadObj)
        diff[entity] = Object.assign({}, result[entity]);

      else {
        for (const prop in resultObj) {

          const resultProp = resultObj[prop];
          const payloadProp = payloadObj[prop];

          if (typeof resultProp === 'string' && resultProp.match(/\d{4}-[01]\d-[0-3]\d/) ||
            typeof payloadProp === 'string' && payloadProp.match(/\d{4}-[01]\d-[0-3]\d/)) {

            if (!!resultProp !== !!payloadProp) {
              if (!diff[entity]) diff[entity] = {};
              diff[entity][prop] = resultObj[prop];
            }
          } else
          if (resultProp != payloadProp) {
            if (!diff[entity]) diff[entity] = {};
            diff[entity][prop] = resultObj[prop];
          }
        }
      }
    }

    return diff;
  }

  static _getMetaDataById(metadata) {
    return metadata.Objects.reduce((ret, obj) => {
      const key = obj['__metadata']['#id'];
      const val = Object.assign({}, obj);
      delete val['__metadata'];

      ret[key] = val;
      return ret;
    }, {});
  }

  /**
   * Gets an object by name.
   * @private
   * @static
   * @memberOf Corticon
   * @param {Object} screen The screen dynObject
   * @param {string} name The object name
   * @returns {Object} The dynObject for the object
   */
  static _getObject(screen, name) {

    if (!name) return null;
    name = name.split('.');

    const container = screen.name === name[0] ? screen : screen.getObject(name[0]);
    if (!container) return null;

    if (name.length < 2)
      return container;

    return container.getObject(name[name.length - 1]);
  }

  /**
   * Gets an entity from a given payload
   * @public
   * @static
   * @memberOf Corticon
   * @param {Object} payload The payload
   * @param {string} entityName The entity name
   * @returns {Object} The entity object from the payload or undefined if not found
   */
  static getEntityFromPayload(payload, entityName) {
    return payload.Objects.find(object => object['__metadata']['#type'] == entityName);
  }

  /**
   * Gets the 'CustomScreenProperties' entity from a given payload
   * @public
   * @static
   * @memberOf Corticon
   * @param {Object} payload The payload
   * @returns {Object} The entity object from the payload or undefined if not found
   */
  static getCustomScreenPropertiesFromPayload(payload) {
    return Corticon.getEntityFromPayload(payload, '_CustomData.CustomScreenProperties');
  }
}

Corticon.init();
