
/**
 * Adapter for Mockup DataStore for usage in an akioma.businessEntity
 * @class
 */
class MockupDataAdapter extends akioma.DataAdapter {

  /**
   * Constructor of the Mockup Data Adapter.
   *
   */
  constructor(options) {
    super();

    /**
     * Underlying MockupData fetch url
     *
     * @private
     *
     * @property {string}
     */
    this._fetchUrl = options.fetchUrl;

    /**
     * Underlying Mockup DataStore
     *
     * @private
     *
     * @property {akioma.MockupHttpClient}
     */
    this._client = new akioma.MockupHttpClient({ fetchUrl: this._fetchUrl });

    /**
     * Logger for this mockup adapter.
     *
     * @private
     *
     * @property {akioma.Logger}
     */
    this._log = akioma.log.getLogger('MockupDataAdapter');
  }

  /**
   * Fill data from the data store.
   *
   * @returns {Promise}
   */
  fill() {

    if (!this._fetchUrl) {
      akioma.notification({ type: 'warning', text: 'MockupData endpoint is missing!' });
      const errorObj = { message: 'MockupData endpoint is missing!' };
      this._emitEvent('afterFill', errorObj, false);
      return;
    }

    const fillPromise = this._client.fetch(); // Return find promise results for fillPromise

    fillPromise.then(records => {
      this._emitEvent('afterFill', records);
    }).fail(error => {
      this._emitEvent('afterFill', error, false);
    });

    return fillPromise;
  }

  /**
   * Accept data changes.
   */
  acceptChanges() {
    // NOT IN USE
  }

  /**
   * Reject changes.
   *
   * @returns {Promise}
   */
  rejectChanges() {
    // NOT IN USE
  }
}

akioma.MockupDataAdapter = MockupDataAdapter;
